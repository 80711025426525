import * as contracts from "../contracts/_contracts";

export class Site implements contracts.ISite {
    DivisionalAccountGroupId: string;
    ClientName: string;
    SiteStreet: string;
    SiteCity: string;
    SiteName: string;
    SiteStateProvince: string;
    SitePostalCode: string;
    SiteCountry: string;
    AssetCount: contracts.ISiteMapInfo;
    TotalAsssetsToBeMapped: number;
    TotalAssets: number;
    TotalDataSources: number;
    SiteGraph: string;
    CanReadWrite: boolean;
    ContextPointId: number;
    ContextPointName: string;
    ContextPointStatusType: number;
    ContextPointStatusTypePrettyName: string;
    ContextPointType: number;
    ContextPointTypePrettyName: string;
    ContextPointSubTypeId: number;
    ContextPointSubTypeName: string;
    ContextPointSourceType: number;
    ContextPointSourceTypePrettyName: string;
    CreatedOn: Date;
    RetiredOn: Date;
    ModifiedOn: Date;
    ModifiedBy: string;
    ModifiedByName: string;
    ContextPointIdentifier: string;
    ContextPointIdentifierParent: string;
    constructor(site?: contracts.ISite) {
        if (site) {
            this.DivisionalAccountGroupId = site.DivisionalAccountGroupId;
            this.ClientName = site.ClientName;
            this.SiteStreet = site.SiteStreet;
            this.SiteCity = site.SiteCity;
            this.SiteName  = site.SiteName;
            this.SiteStateProvince = site.SiteStateProvince;
            this.SitePostalCode = site.SitePostalCode;
            this.SiteCountry = site.SiteCountry;
            this.AssetCount = new SiteMapInfo();
            this.TotalAsssetsToBeMapped = site.TotalAsssetsToBeMapped;
            this.TotalAssets = site.TotalAssets;
            this.TotalDataSources = site.TotalDataSources;
            this.SiteGraph = site.SiteGraph;
            this.CanReadWrite = site.CanReadWrite;
            this.ContextPointId = site.ContextPointId;
            this.ContextPointName = site.ContextPointName;
            this.ContextPointStatusType = site.ContextPointStatusType;
            this.ContextPointStatusTypePrettyName = site.ContextPointStatusTypePrettyName;
            this.ContextPointType = site.ContextPointType;
            this.ContextPointTypePrettyName = site.ContextPointTypePrettyName;
            this.ContextPointSubTypeId = site.ContextPointSubTypeId;
            this.ContextPointSubTypeName = site.ContextPointSubTypeName;
            this.ContextPointSourceType = site.ContextPointSourceType;
            this.ContextPointSourceTypePrettyName = site.ContextPointSourceTypePrettyName;
            this.CreatedOn = site.CreatedOn;
            this.RetiredOn = site.RetiredOn;
            this.ModifiedOn = site.ModifiedOn;
            this.ModifiedBy = site.ModifiedBy;
            this.ModifiedByName = site.ModifiedByName;
            this.ContextPointIdentifier = site.ContextPointIdentifier;
            this.ContextPointIdentifierParent = site.ContextPointIdentifierParent;
        }
    }
}

export class SiteMapInfo implements contracts.ISiteMapInfo {
    TotalAssets: number;
    TotalAsssetsToBeMapped: number;
    TotalDataSources: number;
    TotalDataSourcesToBeMapped: number
    constructor(siteMapInfo?: contracts.ISiteMapInfo) {
        if (siteMapInfo) {
            this.TotalAssets = siteMapInfo.TotalAssets;
            this.TotalAsssetsToBeMapped = siteMapInfo.TotalAsssetsToBeMapped;
            this.TotalDataSources = siteMapInfo.TotalDataSources;
            this.TotalDataSourcesToBeMapped = siteMapInfo.TotalDataSourcesToBeMapped
        }
    }
}
