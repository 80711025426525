import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DividerModule } from '../divider/divider.module';
import { FormsModule } from '@angular/forms';
import { DateInputsModule, DatePickerModule, TimePickerModule} from '@progress/kendo-angular-dateinputs';
import { CustomDatePickerComponent } from './custom-date-picker.component';
import { TranslateLoader, TranslateModule, TranslateParser } from '@ngx-translate/core';
import { createTranslateLoader } from '../../app.module';
import { HttpClient } from '@angular/common/http';
import { TranslateICUParser } from '@pawelalikowski/ngx-translate-parser-plural-select';
import { ButtonModule } from '../button/button.module';

@NgModule({
    imports: [
        CommonModule,
        DividerModule,
        FormsModule,
        DatePickerModule,
        TimePickerModule,
        DateInputsModule,
        ButtonModule,
        TranslateModule.forChild({
            loader: {
              provide: TranslateLoader,
              useFactory: createTranslateLoader,
              deps: [HttpClient],
            },
            parser: {
              provide: TranslateParser,
              useClass: TranslateICUParser
            }
          }),
    ],
    declarations: [
        CustomDatePickerComponent
    ],
    exports: [
        CustomDatePickerComponent
    ],
    providers: [
    ]
    
})

export class  CustomDatePickerModule { }
