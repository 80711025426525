import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import { takeUntil } from 'rxjs/operators';
import { AppEnvConfig } from '../../app.env.config';
import { LocalCacheService } from '../../core/local-cache/local-cache.service';
import { ISavingCategoryTypes } from '../../shared-action-log/contracts/IActionLog';
import type { IAppliedFilter, IChild } from '../../shared-financial-impact/_contracts/IFIAppliedFilterDetails';
import { CurrencyDetails } from '../../shared/models/CurrencyDetails';
import { IAdminCommonConfiguration } from '../contracts/IAdminCommonConfiguration';
import { ITotalValue } from '../contracts/IAggregatedValue';
import { IAutomatedTVDSavings } from '../contracts/IAutomatedTVDSavings';
import { IDivisions } from '../contracts/IDivisions';
import { IEroiTypes } from '../contracts/IEroiTypes';
import { IEroiUnitMappings } from '../contracts/IEroiUnitMappings';
import { IValueFilters } from '../contracts/IFinancialImpactFilters';
import { AggregatedValueDelivered } from '../contracts/IProgressBar';
import { IValueChannel } from '../contracts/IValueChannel';
import { EROIValueType, EroiValueTypeEnum, UOMOptions, ValueChannels, quickFilterList } from '../enum/constants';
import { EroiCategory } from '../models/EroiCategory';
import { CacheService } from './CacheService';
import { SharedFIRefreshConfig } from './shared-fi-refresh.service.config';
import { GlobalSearchFiltersService } from '../../shared-ui-refresh/global-search-filters/services/global-search-filters.service';

@Injectable()
export class SharedFIRefreshService {
    private configEnv: any;
    public contextPoint: number;
    public jsonServerUrl: string;
    public dagType: string;
    public checkMaxDags: string;
    public currencySettingChanged = new Subject<any>();
    $currencySettingChanged = this.currencySettingChanged.asObservable();
    public adminSettingChanged = new Subject<any>();
    $adminSettingChanged = this.adminSettingChanged.asObservable();
    private _filteredSite = new BehaviorSubject<any>(null);

    // Private subject to handle cancelling
    private cancelPendingRequests = new Subject<void>();

    // Observable stream
    public _selectedSites$ = this._filteredSite.asObservable();
    _currencySettingType = new BehaviorSubject<
        { widgetId: number, type: 'Personal' | 'Corporate' }>(null);

    public divisionFilterChanges = new Subject<any>();

    $divisionFilterChanges = this.divisionFilterChanges.asObservable();
    public url: string;
    isLoading = false;

    private _filteredDivisions = new BehaviorSubject<string[]>(null);
    // Observable stream
    public _selectedDivisions$ = this._filteredDivisions.asObservable();

    private _financialValueFilters = new BehaviorSubject<IValueFilters>({});
    public appliedValueFilters$ = this._financialValueFilters.asObservable();

    private _cvocEvocFiltersUpdates = new BehaviorSubject<boolean>(false);
    public cvocEvocFilterUpdated = this._cvocEvocFiltersUpdates.asObservable();

    private _isParentFiltersLoadedUpdates = new BehaviorSubject<boolean>(false);
    public isParentFiltersLoaded$ = this._isParentFiltersLoadedUpdates.asObservable();

    public _uomAndCurrencydropdownValue = new BehaviorSubject<any>(null);
    public dropdownValue$ = this._uomAndCurrencydropdownValue.asObservable();


    constructor(
        private SharedFinancialImpactConfig: SharedFIRefreshConfig,
        private httpClient: HttpClient, private config: AppEnvConfig,
        private cache: LocalCacheService,
        private pageCacheService: CacheService,
        private translate: TranslateService,
        private handleMultipleRequestsService: GlobalSearchFiltersService) {
        this.configEnv = this.SharedFinancialImpactConfig.getEnvironment();
        this.configEnv.apiServerUrl = config.getEnv('apiServerUrl');
        this.configEnv.jsonServerUrl = config.getEnv('jsonServerUrl');
        this.configEnv.apiServerUrlAuth = config.getEnv('apiServerUrl');
        this.configEnv.apiWaterServerUrl = config.getEnv('apiWaterServerUrl');
        this.configEnv.valueAggrServiceApiUrl = config.getEnv('valueAggrServiceApiUrl');
        this.configEnv.contextPoint = 0;
        this.dagType = 'NalcoWater/';
        this.checkMaxDags = '/true/';
        const adrumService = 'FinancialImpact';
        this.cache.storeData('adrumService', adrumService, 'local');
        this.pageCacheService.clear();
    }

    public getSavingCategoryTypes(): Observable<ISavingCategoryTypes[]> {
        const cacheKey = `[${SharedFIRefreshService.name}].[getSavingCategoryTypes]`;
        const savingCategoryTypesCache: ISavingCategoryTypes[] = this.getCache(cacheKey);
        if (savingCategoryTypesCache) {
            return Observable.of(savingCategoryTypesCache);
        }

        const url = `${this.configEnv.valueAggrServiceApiUrl}${this.configEnv.apiUrl.getSavingCategoryTypes}`;
        return this.httpClient.get(url).map((response: ISavingCategoryTypes[]) => {
            this.setCache(cacheKey, response);
            return response;
        });
    }

    public getEroiTypes(): Observable<IEroiTypes[]> {
        const cacheKey = `[${SharedFIRefreshService.name}].[getEroiTypes]`;
        const eRoiTypesCache: IEroiTypes[] = this.getCache(cacheKey);
        if (eRoiTypesCache) {
            return Observable.of(eRoiTypesCache);
        }

        const url = `${this.configEnv.valueAggrServiceApiUrl}${this.configEnv.apiUrl.getEroiTypes}`;
        return this.httpClient.get(url).map((response: IEroiTypes[]) => {
            this.setCache(cacheKey, response);
            return response;
        });
    }

    public getEroiUnitMappings(eroiTypes: IEroiTypes[]): IEroiUnitMappings[] {
        const eroiUnitMappings: IEroiUnitMappings[] = [];

        eroiTypes.forEach(eroiType => {
            eroiType.DimensionalUnits.forEach(dU => {
                dU.SubUnits.forEach(sU => {
                    eroiUnitMappings.push({
                        displayUnit: dU.NalcoNumericsUnitOrSpecies + '.' + sU.UnitName,
                        unitSymbol: sU.Symbol,
                        eroiTypeId: eroiType.ERoiTypeId
                    })
                })
            })
        });

        return eroiUnitMappings;
    }

    public getUnitSymbol(displayUnitSymbols: IEroiUnitMappings[], eroiTypeId: number, displayUnit: string) {
      const formattedDisplayUnit = this.removeWhiteSpaceAndTabs(displayUnit).toLowerCase();
      // Split the displayUnit into parts, and get the last part after the split
      const displayUnitParts = formattedDisplayUnit.split('.');
      const lastPart = displayUnitParts[displayUnitParts.length - 1];  // Get the last part
      const matchingUnit = displayUnitSymbols.filter(unit => unit.eroiTypeId === eroiTypeId && this.removeWhiteSpaceAndTabs(unit.displayUnit).toLowerCase().split('.').pop() === lastPart);
      const unitSymbol = matchingUnit ? matchingUnit.map(unit => unit.unitSymbol) : '';
      return unitSymbol;
  }

    public getSoldTosBySiteContextPointId(siteID): Observable<any> {
        this.url = `${this.configEnv.apiServerUrlAuth}${this.configEnv.apiUrl.getSoldTosBySiteContextPointId}/${siteID}`;
        return this.httpClient.get(this.url)
            .map((response: any) => response);
    }


    public getProjectDetailOverview(projectID: string): Observable<any> {
        this.url = `${this.configEnv.apiWaterServerUrl}${this.configEnv.apiUrl.getProjectDetailOverview}${projectID}`;
        return this.httpClient.get(this.url)
            .map((response: any) => response);
    }

    public getCurrencyData(): Observable<CurrencyDetails[]> {
        const cacheKey = `[${SharedFIRefreshService.name}].[getCurrencyData]`;
        const allCurrenciesCache: CurrencyDetails[] = this.getCache(cacheKey);
        if (allCurrenciesCache) {
            return Observable.of(allCurrenciesCache);
        }

        const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getCurrencies}`;
        return this.httpClient.get(url).map((response: CurrencyDetails[]) => {
            this.setCache(cacheKey, response);
            return response;
        });
    }

    /**
     * @description Gets Aggregated value for TVD, Pipeline and Potential value and its corresponding value category breakdown
     * @returns returns aggregated values
     */
    public getAggregatedValueDelivered(): Observable<AggregatedValueDelivered> {
        const url = `${this.configEnv.apiWaterServerUrl}${this.configEnv.apiUrl.getAggregatedValueDelivered}`;
        return this.httpClient.post(url, {}).map((response: AggregatedValueDelivered) => {
            return response;
        });
    }

    public calculateNoOfDays(data) {
        const date = new Date(data.ModifiedOn);
        const currentDate = new Date();
        const days = Math.floor((currentDate.getTime() - date.getTime()) / 1000 / 60 / 60 / 24);
        return days === 1 ? `${days} day ago on` : `${days} days ago on`;
    }

    public getSelectedDivisions() {
        return this._filteredDivisions.value;
    }

    public setSelectedDivisions(division: string[]) {
        this._filteredDivisions.next(division);
    }

    public setCurrencyAndUOMValues(value) {
        this._uomAndCurrencydropdownValue.next(value);
    }

    public getAdminSettings(corporateId: number, contextPointId?: number): Observable<IAdminCommonConfiguration> {
        let url: string;
        if (contextPointId) {
            url = `${this.configEnv.valueAggrServiceApiUrl}${this.configEnv.apiUrl.getAdminSettings + '/' + corporateId + '?contextPointId=' + contextPointId}`;
        } else {
            url = `${this.configEnv.valueAggrServiceApiUrl}${this.configEnv.apiUrl.getAdminSettings + '/' + corporateId}`;
        }
        return this.httpClient.get<IAdminCommonConfiguration>(url, {}).map(response => {
            return response;
        });
    }

    public updateAdminConfiguration(obj: any, corporateAccountId: number, contextPointId = undefined): Observable<any> {
        let url: string;
        url = `${this.configEnv.valueAggrServiceApiUrl}${this.configEnv.apiUrl.getAdminSettings}`;
        const query = new URLSearchParams();
        query.set('corporateId', corporateAccountId.toString());
        const body = JSON.stringify(obj);
        //  const url = 'https://oip-plt-wps-webapi-001-dq-dev.azurewebsites.net/financialimpact/api/adminconfigurations/2118807';
        return this.httpClient.put(url, body).map((response: any) => response);
    }

    public getDefaultAdminConfigJSON(): Observable<any> {
        const cacheKey = `[${SharedFIRefreshService.name}].[getDefaultAdminConfigJSON]`;
        const defaultAdminConfigCache: any = this.getCache(cacheKey);
        if (defaultAdminConfigCache) {
            return Observable.of(defaultAdminConfigCache);
        }

        return this.httpClient.get('./assets/data/fi-refresh-admin-default.json').map((response: any) => {
            this.setCache(cacheKey, response);
            return response;
        });
    }

    public getAllValueChannels(): Observable<IValueChannel[]> {
        const cacheKey = `[${SharedFIRefreshService.name}].[getAllValueChannels]`;
        const valueChannelsCache: IValueChannel[] = this.getCache(cacheKey);
        if (valueChannelsCache) {
            return Observable.of(valueChannelsCache);
        }

        const url = `${this.configEnv.valueAggrServiceApiUrl}${this.configEnv.apiUrl.getAllValueChannels}`;
        return this.httpClient.get(url, {}).map((response: IValueChannel[]) => {
            this.setCache(cacheKey, response);
            return response;
        });
    }

    public getDivisionalAccounts(): Observable<IDivisions[]> {
        const cacheKey = `[${SharedFIRefreshService.name}].[getDivisionalAccounts]`;
        const divisionalAccountsCache: IDivisions[] = this.getCache(cacheKey);
        if (divisionalAccountsCache) {
            return Observable.of(divisionalAccountsCache);
        }

        const url = `${this.configEnv.valueAggrServiceApiUrl}${this.configEnv.apiUrl.getDivisionalAccounts}`;
        return this.httpClient.get(url).map((response: IDivisions[]) => {
            this.setCache(cacheKey, response);

            return response;
        });
    }

    set selectedValueFilters(value: IValueFilters) {
        this._financialValueFilters.next(value);
    }

    get selectedValueFilters(): IValueFilters {
        return this._financialValueFilters.getValue();
    }

    set isParentFiltersLoaded(value: boolean) {
        this._isParentFiltersLoadedUpdates.next(value);
    }

    get isParentFiltersLoaded(): boolean {
        return this._isParentFiltersLoadedUpdates.getValue();
    }

    set sendCvocEvocFilterUpdate(value: boolean) {
        this._cvocEvocFiltersUpdates.next(value);
    }

    public getValues(valueFilters: IValueFilters): Observable<ITotalValue> {
        let contextPointId = [];
        let modifiedFilter = JSON.parse(JSON.stringify(valueFilters));
        contextPointId.push(modifiedFilter.ContextPointId)
        modifiedFilter.ContextPointId = contextPointId;
        const url = `${this.configEnv.valueAggrServiceApiUrl}${this.configEnv.apiUrl.getValues}`;
        const debounceMs = 500;
        const instanceId = 'Get Values';
        return this.handleMultipleRequestsService.cancelMultipleRequestByInstanceId<any>(url, 'POST', modifiedFilter, instanceId, debounceMs); 
    }

    /**
    * @description retrieves project data by ID.
    * @param {number} financialImpactProjectId - The ID of the fi project.
    * @param {string[]} unitOfMeasure - Array of UOM strings.
    * @param {string} currencyCodeId - Currency code ID.
    * @returns {Observable<any>} An Observable emitting the response data.
    */
    public getProjectById(financialImpactProjectId: number, unitOfMeasure: string[], currencyCodeId: string): Observable<any> {

        const url = `${this.configEnv.valueAggrServiceApiUrl}${this.configEnv.apiUrl.getProjectById}/${financialImpactProjectId}?unitStandard=${unitOfMeasure}&currencyCodeId=${currencyCodeId}`;
        return this.httpClient.get(url).map((response: any) => response);
    }

    public getEroiCategoryIconUrl(eroiCategoryValueId: string): string {
        const eRoiCategory = this.getEroiCategories()
            .find(e => e.value === eroiCategoryValueId);

        return eRoiCategory ? eRoiCategory.iconUrl : '';
    }

    public getEroiCategories(): EroiCategory[] {
        const eRoiValueTypes = this.getEroiValueTypeArray();

        const eRoiCategories = EROIValueType.map(item => {
            const eRoiValueType = eRoiValueTypes.find(e => e.value.toString() === item.value)

            return new EroiCategory(item.text,
                this.translate.instant(item.text),
                item.type,
                item.value,
                `/assets/images/eROI-icons/${item.value}.svg`,
                eRoiValueType.key,
                `/assets/images/eROI-icons/${item.value}`);
        });

        return eRoiCategories;
    }

    public getL1EroiCategories(): EroiCategory[] {
        const eRoiValueTypes = this.getEroiValueTypeArray();

        const eRoiCategories = EROIValueType.map(item => {
            const eRoiValueType = eRoiValueTypes.find(e => e.value.toString() === item.value)

            return new EroiCategory(item.text,
                this.translate.instant(item.text),
                item.type,
                item.value,
                `/assets/images/eROI-L1-icons/${item.value}.svg`,
                eRoiValueType.key,
                `/assets/images/eROI-L1-icons/${item.value}`);
        });

        return eRoiCategories;
    }

    public getEroiValueTypeArray(): { key: string, value: number }[] {
        const enumArray = Object.keys(EroiValueTypeEnum)
            .filter(key => !isNaN(Number(EroiValueTypeEnum[key])))
            .map(key => {
                return {
                    key: key,
                    value: EroiValueTypeEnum[key]
                };
            });

        return enumArray;
    }

    private getCache<T>(key: string): T {
        return this.pageCacheService.get(key);
    }

    private setCache<T>(key: string, value: T) {
        return this.pageCacheService.set(key, value);
    }

    private removeWhiteSpaceAndTabs(value: string): string {
        return value ? value.replace(/\t/g, '').trim() : '';
    }

    /**
     * @description Method to get filter deatils
     * @param filterDetails cvoc filter selection
     * @returns filter data for export excel header
     */
    public getSiteFilterDetails(filterDetails: IAppliedFilter): string[] {
        const filteredString = [];
        const siteLevelFilter = filterDetails ? filterDetails : JSON.parse(this.cache.fetchData('appliedFIfilterDetails', 'local'));
        if (siteLevelFilter && siteLevelFilter.contextPointId != null) {
            if (siteLevelFilter.filterObject && siteLevelFilter.filterObject.parentId !== '') {
                filteredString.push('CVOC');
                filteredString.push(siteLevelFilter.filterObject.parentName + this.getChildrenDetails(siteLevelFilter.filterObject.children));
            } else {
                filteredString.push('EVOC');
                filteredString.push((this.getChildrenDetails(siteLevelFilter.filterObject.children)).substr(3));
            }
        }
        return filteredString;
    }

    /**
     * @description Method to format child filters names
     * @param children child filter details
     * @returns return child filters names
     */
    private getChildrenDetails(children: IChild[]): string {
        let childString = '';
        children.forEach((child: IChild) => {
            if (child && child.contextPointName) {
                childString += ' / ' + child.contextPointName;
            }
        });
        return childString;
    }

    /**
     * @description Method to compute quick filters
     * @param quickFilters quick filters object
     * @returns formatted quick filter object
     */
    public getQuickFilterDetails(quickFilters: IValueFilters, isForSSDExportCSV?: boolean): string[] {
        const quickFiltersValues = [
            this.getTimeRange(quickFilters.StartDate, quickFilters.EndDate),
            this.getDivision(quickFilters.DivisionalAccounts),
            this.getValueChannel(quickFilters.ValueChannel),
            this.getSelectedSavingCategoryTypes(quickFilters.AnnualSavingsTypes),
            quickFilters.CurrencyCodeId,
            this.getUnitStandard(quickFilters.UnitStandard)
        ];
        const quickFilterString = [];
        if (isForSSDExportCSV) {
            quickFilterList.forEach((filterType, index) => {
                quickFilterString.push({ [filterType]: quickFiltersValues[index] });
            });
        }
        else {
            quickFilterList.forEach((filterType, index) => {
                quickFilterString.push([filterType, quickFiltersValues[index]]);
            });
        }
        return quickFilterString;
    }

    /**
     * @description Method to format time range
     * @param StartDate Filter start date
     * @param EndDate Filter end date
     * @returns formatted time range
     */
    private getTimeRange(StartDate: string, EndDate: string): string {
        return this.formatDate(StartDate) + ' to ' + this.formatDate(EndDate);
    }

    /**
     * @description Method to format dates
     * @param StartDate Filter start date
     * @param EndDate Filter end date
     * @returns formatted time range (MM/DD/YYYY)
     */
    private formatDate(date: string): string {
        const dateArr = date.split('-');
        const formattedDate = `${dateArr[1]}/${dateArr[2]}/${dateArr[0]}`;
        return formattedDate;
    }

    /**
     * @description Method to map selected value channel name
     * @param valueChannel value channel id
     * @returns selected value channel name
     */
    private getValueChannel(valueChannel: number): string {
        let selectedVC = this.computeSelectedValueChannelIds(valueChannel);
        let selectedVCNames: string;
        if (selectedVC.length === ValueChannels.length - 1) {
            selectedVCNames = 'All';
        } else {
            selectedVCNames = selectedVC
                .map(vc => {
                    const channel = ValueChannels.find(channel => channel.Id === vc);
                    return channel ? channel.Name : null;
                })
                .filter(name => name)
                .join(', ');
        }
        return selectedVCNames
    }

    /**
     * @description Method to get the selected value channels
     * @param num sum of value channel ids
     * @returns selected value channels array
     */
    private computeSelectedValueChannelIds(num: number): number[] {
        const valueChannels = [];
        let index = 0;
        while (num > 0) {
            if (num & 1) {
                valueChannels.push(2 ** index);
            }
            num >>= 1;
            index++;
        }
        return valueChannels;
    }

    /**
    * @description Method to map selected Annual savings type name
    * @param Annual savings type id
    * @returns selected Annual savings type
    */
    private getSelectedSavingCategoryTypes(AnnualSavings: number[]): string {
        const savings: string[] = [];
        let annualSavingTypesOption: ISavingCategoryTypes[] = [];
        const annualSavingTypes: Observable<ISavingCategoryTypes[]> = this.getSavingCategoryTypes();
        annualSavingTypes.subscribe((response: ISavingCategoryTypes[]) =>
            annualSavingTypesOption = response
        );

        if ((AnnualSavings.length === annualSavingTypesOption.length) || (!AnnualSavings.length)) {
            savings.push('All');
        } else {
            AnnualSavings.forEach((type: number) => {
                savings.push(annualSavingTypesOption.find((item: ISavingCategoryTypes) => item.SavingCategoryTypeId === Number(type)).Description)
            });
        }
        return savings.join(', ');
    }

    /**
     * @description Method to fetch Division names
     * @param arr filter object Division values in array format
     * @returns formatted division filters
     */
    private getDivision(arr: string[]): string {
        const div: string[] = [];
        if (arr.length === 0) {
            div.push('All')
        } else {
            if (arr.includes('TC'))
                div.push('Textile Care')
            if (arr.includes('FB'))
                div.push('Food & Beverage')
            if (arr.includes('WT'))
                div.push('Nalco Water')
        }
        return div.join(', ');
    }

    /**
     * @description Method to get the unit standard name
     * @param unit unit ID
     * @returns unit standard name
     */
    private getUnitStandard(unit: number): string {

        return this.translate.instant(UOMOptions.find(type => Number(type.value) === unit).text)
    }
    /**
     * @description Method to compute file name
     * @param filterDetails filter details
     * @returns filter selection name
     */
    public getCorporatNameOrEvocLastNode(filterDetails: IAppliedFilter = null): string {
        let appliedFilter = JSON.parse(this.cache.fetchData('appliedfilterDetails', 'local'));
        appliedFilter = appliedFilter ? appliedFilter : JSON.parse(this.cache.fetchData('appliedSSDfilterDetails', 'local'));

        if ((appliedFilter && appliedFilter.filterObject) || (filterDetails && filterDetails.filterObject)) {
            const fobj = filterDetails ? filterDetails.filterObject : appliedFilter.filterObject;
            if (fobj.parentId !== '') {
                return fobj.parentName;
            } else {
                if (fobj.children.length) {
                    return fobj.children[fobj.children.length - 1].contextPointName;
                }
            }
        }
        return '';
    }
    /**
     * @description method is used to fetch Automated TVD savings based on filters selected
     * @param valueFilters IValueFilters
     * @returns returns List of Automated TVD savings
     */
    public getAutomatedTvdSavings(valueFilters: IValueFilters, instanceId: string): Observable<IAutomatedTVDSavings[]> {
        const { UnitStandard, ValueChannel, CorporateCpId, ...requiredFilters } = valueFilters;
        const revisedFilters = { ...requiredFilters, UnitsOfMeasure: valueFilters.UnitStandard };
        const url = `${this.configEnv.valueAggrServiceApiUrl}${this.configEnv.apiUrl.getAutomatedTVDValueList}`;
        const debounceMs = 400;
        return this.handleMultipleRequestsService.cancelMultipleRequestByInstanceId<any>(url, 'POST', revisedFilters, instanceId, debounceMs);
    }
}
