import { Injectable } from '@angular/core';
import { ENVIRONMENTS, APP_CONFIG } from '../../app.config';

@Injectable()
export class SharedRoConfig {

    private configMock: any = { 
        environment: ENVIRONMENTS.mock,
        baseUrl: '',
        apiUrl: {
            getRoOptimizerAssets: 'api/v1/RoOptimizer/GetRoOptimizerAssets',
            getRoOptimizerScenarioInfo: 'api/v1/RoOptimizer/GetRoOptimizerScenarioInfo',
            getDimensionalUsageKeys: 'GeneralCollection?documentName=DimensionalUsageKeys.Map',
            getUnitsByDimensionalKey: 'LoadUnitsSymbolByDimensionalKey',
            getConversionByUnitType: 'ConversionByUnitType',
            getChargeBalance: 'api/v1/RoOptimizer/RoChargeBalance',
            getDosageAndRecommendationInfo: 'api/v1/RoOptimizer/RoSolve',
            saveScenario: 'api/v1/RoOptimizer/SaveRoOptimizerScenarioInfo'
        }
    };
    private configProd: any = { 
        environment: ENVIRONMENTS.prod,
        baseUrl: '',
        apiUrl: {
            getRoOptimizerAssets: 'api/v1/RoOptimizer/GetRoOptimizerAssets',
            getRoOptimizerScenarioInfo: 'api/v1/RoOptimizer/GetRoOptimizerScenarioInfo',
            getDimensionalUsageKeys: 'GeneralCollection?documentName=DimensionalUsageKeys.Map',
            getUnitsByDimensionalKey: 'LoadUnitsSymbolByDimensionalKey',
            getConversionByUnitType: 'ConversionByUnitType',
            getChargeBalance: 'api/v1/RoOptimizer/RoChargeBalance',
            getDosageAndRecommendationInfo: 'api/v1/RoOptimizer/RoSolve',
            saveScenario: 'api/v1/RoOptimizer/SaveRoOptimizerScenarioInfo'
        }
    };

    private _environment: string = APP_CONFIG.ENVIRONMENT;

    constructor() {
        // void
    }

    public getEnvironment(): any {
        switch (this._environment) {
            case ENVIRONMENTS.mock:
                return this.configMock;
            case ENVIRONMENTS.dev:
                return this.configProd;
            case ENVIRONMENTS.test:
                return this.configProd;
            case ENVIRONMENTS.stg:
                return this.configProd;
            case ENVIRONMENTS.prod:
                return this.configProd;
            default:
                return null;
        }
    }

    public setEnvironment(environment: string): any {
        this._environment = environment;
    }

}
