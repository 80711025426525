import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../../../core/user/user.service';
//import { Subscription } from 'rxjs/Subscription';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, Subscription, forkJoin } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { AssetDetailsService } from '../../../../asset-details/_services/asset-details.service';
import { LocalCacheService } from '../../../../core/local-cache/local-cache.service';
import { LoggerService } from '../../../../core/logger/global-logger.service';
import { ParameterList } from '../../../../data-visualization/_models/ParameterList';
import { Parameter } from '../../../../data-visualization/_models/parameter';
import { DataVisualizationService } from '../../../../data-visualization/_services/data-visualization.service';
import { Dashboard } from '../../../../service-summary/_models/Dashboard';
import { IData, IFilterEvent, ISite } from '../../../../shared-financial-impact/_contracts/IFIAppliedFilterDetails';
import { ActiveFilterTab, CorporateAccountName, ServiceEnabledName, ServiceType, ServiceTypeIds } from '../../../../shared/_enum/constants';
import { ErrorHandling } from '../../../../shared/components/error-handling/error-handling';
import { Site } from '../../../../water-flow-intelligence/_models/Site';
import { EvocCvocFilterService } from '../../../services/evoc-cvoc-filter.service';
import { SharedService } from '../../../services/shared-services.service';
import { SiteService } from '../../../services/site.service';
import { ISubheader } from '../../sub-header/sub-header.modal';

@Component({
  selector: 'nalco-sites-filter',
  templateUrl: './sites-filter.component.html',
  styleUrls: ['./sites-filter.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SitesFilterComponent implements OnInit, OnChanges {
  @ViewChild('filterSitesModal', { static: true }) filterSitesModal: NgbModal;
  @Output('onFiltersInit') onFiltersInit = new EventEmitter<any>();
  @Output('onFiltersChange') onFiltersChange = new EventEmitter<any>();
  @Output('onFiltersSubmit') onFiltersSubmit = new EventEmitter<any>();
  @Output('onSiteSelect') onSiteSelect = new EventEmitter<any>();
  @Output('onCancel') onCancel = new EventEmitter<any>();
  @Output() selectedSiteEvent = new EventEmitter<any>();
  @Output() onActiveFilterTabChange: EventEmitter<string> = new EventEmitter<string>();
  @Input('territoriesData') territoriesData;
  @Input('countryData') countryData;
  @Input() businessUnit?= 'NalcoWater';
  @Input() disableEVOC?= false;
  @Input() isSSD?= false;
  @Input() isMyDocument?= false;
  @Input() isFI?= false;
  @Input() isSiteEnabled? = false;
  @Input() serviceID?= 0;
  @Input() siteID = [];
  @Input() isQueryParams?: boolean = false;
  @Input() dataInput?: any;
  @Input() value?: any;
  @Input() serviceEnabledName: string; // can fetch corporate list on the basis of service
  @Input() multiselectDropdownFlag: boolean;
  @Input() corpAccList: Array<{ AccountId: number, AccountName: string }> = [];
  @Input() customerNameLoader = false;
  @Input() filterObj?
  @Input() showFilterLoader = true;
  @Input() isDeepLink?: boolean;
  evocCacheKey: string;
  evocCacheBackup = [];
  clearAllClicked = false;

  isDisable: boolean = false;
  selectedSiteOnInit = [];
  txtQueryChanged: Subject<string> = new Subject<string>();
  pageIndex = 1;
  siteSearchText = '';
  searchValue = '';
  SiteDropdownData = [];
  siteData: Array<Site> = [];
  siteTab = false;
  selectedSiteInfo: any;
  sitesLoading = false;
  isClear = false;
  isError = false;
  errorInfo: any;
  selectedCorporateAccountDetails: any;
  depValue = false;
  tog = false;
  partnerDivisonSiteSearchDisabled = false;
  fromInit = true;
  parameterShowLoader = false;
  parameterData: Array<Parameter> = [];
  filters: any = [];
  sourceSamplePointDisplayData = [];
  SystemDropdownDataDisplay = [];
  assetDataDropdownDisplay = [];
  portDataDropdownDisplay = [];
  selectedParameterTypeOnInit = [];
  parameterlist: ParameterList;
  selectedSiteforAvailParams = '';
  selectedParameters = [];
  modalRef: any;
  disableFlag = false;
  showLoader = false;
  isCvoc = false;
  clearNow = true;
  components = [];
  siteName: string;
  siteCpId = 0;
  hideSaveReportButton = false;
  loaderaccountdetails: string;
  isClearAllSelected: boolean = false;
  isOnlySiteSelectionCVOC = false;
  paramsObj: any;
  hideTab = false;

  private breadcrumbServiceText = 'Service Summary';
  public breadCrumbData: any = [
    { text: this.breadcrumbServiceText, link: 'service-summary' },
    { text: 'Enterprise Overview', link: 'service-summary' }
  ];
  subheaderData: ISubheader = {
    leftSection: {
      type: 'breadcrumb',
      data: this.breadCrumbData,
      className: 'col-lg-6'
    },
    middleSection: {
      type: '',
      data: [],
      className: ''
    },
    rightSection: {
      type: '',
      data: [],
      className: ''
    }
  };

  public getServiceType = ServiceType;
  private subscriptions: Subscription[] = [];
  @Input() filterObjectFlag = false;
  getDashboardData: Dashboard;
  public activeTabID = 'ngb-tab-0';
  @Input() filterObject: any = {
    'parentId': '',
    'parentName': '',
    'children': []
  };
  public siteObject: any = {
    'siteId': '',
    'siteName': '',
    'displayName': '',
    'CorporateAccountName': ''
  }; //To store the updated site details
  public SSDSiteDetails: any = {}; //Access to local storage to get n set site details
  passSite: any;
  public accountDetails: any = {
    'data': [],
    'index': ''
  };
  public accountchildrenDetails = [];
  title = '';
  tree: any;
  shboardObject = new Dashboard();
  public accountDetailsChild: any = {
    'data': [],
    'index': ''
  };
  private appliedFilters: any = {
    'parentId': '',
    'parentName': '',
    'children': []
  };
  get appliedFiltersAccess(){
    return this.appliedFilters;
  }
  private appliedFiltersBackup: string = '';
  // any = {
  //   'parentId': '',
  //   'parentName': '',
  //   'children': []
  // };

  private _clearData: boolean = true;
  get clearDataAccess(){
    return this._clearData;
  }

  isInternal: boolean;
  isSSDTab: boolean;
  isFITab: boolean;
  isSiteTabEnabled: boolean;
  isSiteSelected: boolean = false;
  public isEVOCFilter: string;
  public selectedContextPoints = [];
  errorHandling = new ErrorHandling(this.modalService, this.translate);

  isStreetAccountUser: boolean;
  ServiceType = ServiceType.ServiceSummary;
  callClear: boolean;
  siteDisplayName: string = ''; //The string is used to bind the site name in the site filter dropdown
  isSiteCache: boolean = false; // To check whether the site details present in the local cache
  loader = false;
  isClearAllVisible = false;
  public FISitedetails: IFilterEvent;
  public siteTabSiteDetails: IFilterEvent;
  //appliedWQStreetSiteFilter: IStreetSiteFilter | null = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private sharedService: SharedService,
    private translate: TranslateService,
    private localCacheService: LocalCacheService,
    private logger: LoggerService,
    private userService: UserService,
    private siteService: SiteService,
    private dataVisualizationService: DataVisualizationService,
    private evocCvocFilterService: EvocCvocFilterService,
    private assetDetailsService: AssetDetailsService) {
    this.isInternal = userService.isInternalUser();
    this.isStreetAccountUser = userService.isStreetAccountUser();
    //this code is used to find the water flow intelligence component:
    //User Story 305710: WFI || FED || CVOC || Add CVOC filter on WFI Enterprise Page.

  }

  ngOnInit() {
    this.isOnlySiteSelectionCVOC = this.serviceEnabledName === ServiceEnabledName.ASSET_DETAILS || this.serviceEnabledName === ServiceEnabledName.HEAT_SURVEYS;
    let details = null;
    let appliedFIFilters = this.userService.getFIFilterDetails();
    this.FISitedetails = this.userService.getFISiteFilterDetails();
    let SSDFilterDetails = this.userService.getSSDFilterDetails();
    this.SSDSiteDetails = this.userService.getSSDSiteDetails(); //Get site details from the local cache
    const appliedsiteTabSiteDetails = this.userService.getSiteTabSiteFilterDetails();
    this.siteTabSiteDetails = this.userService.getSiteTabSiteDetails();
    this.selectedCorporateAccountDetails = {};
    // this.appliedWQStreetSiteFilter = this.userService.getWQStreetFilterDetails();
    this.hideTabBasedOnAccess();
    if (this.serviceEnabledName === ServiceEnabledName.ASSET_DETAILS) {
      this.filterObjectFlag = true;
      this.updateSiteDataByURL();
    }
    let userSetting = JSON.parse(this.localCacheService.fetchData("UserSettings"));
    if (userSetting.IsSingleSiteUser || (!this.SSDSiteDetails && userSetting.IsStreetAccountUser)) {
      this.showDefaultSite();
    }

    if (SSDFilterDetails && (this.isSSD || this.isOnlySiteSelectionCVOC)) {
      details = SSDFilterDetails;
    }
    else if (appliedFIFilters && this.isFI) {
      details = appliedFIFilters;
    } else if (appliedsiteTabSiteDetails && this.isSiteEnabled) {
      details = appliedsiteTabSiteDetails;
    }
    // else if (this.appliedWQStreetSiteFilter && this.serviceEnabledName === ServiceEnabledName.WATER_QUALITY) {
    //   details = {
    //     contextPointId: this.appliedWQStreetSiteFilter.ContextPointId,
    //     filterObject: {
    //       parentId: this.appliedWQStreetSiteFilter.CorpAccountId,
    //       parentName: this.appliedWQStreetSiteFilter.SiteName,
    //       children: [{ contextPointId: this.appliedWQStreetSiteFilter.ContextPointId }]
    //     },
    //     filterObjectFlag: true
    //   };
    // }
    else {
      details = this.userService.getFilterDetails();
    }
    this.isCvoc = true;
    if (this.isDeepLink && this.filterObj) {
      details = {
        contextPointId: this.filterObj.ParentId,
        filterObject: this.filterObj,
        filterObjectFlag: true
      }
    }
    //this.setState(ComponentState.ready);
    if (details) {
      this.filterObject = details.filterObject;
      this.filterObjectFlag = details.filterObjectFlag;

      //check if CVOC is selected or EVOC
      if (this.filterObject.children.length > 0 && this.filterObject.parentId == '' && this.filterObject.parentName == '') {
        this.activeTabID = 'ngb-tab-1';
      }
    }
    let childrenfilterdetails = JSON.parse(this.localCacheService.fetchData('childrenfilterDetails', 'local'));
    this.accountchildrenDetails = childrenfilterdetails ? childrenfilterdetails : [];

    // If site details are present in cache update the site name, siteObject and isSiteCache
    if ((this.SSDSiteDetails) && (this.isSSD || this.isFI || this.isSiteEnabled || this.isOnlySiteSelectionCVOC)) {
      this.isClear = true;
      // this.filterObjectFlag = true;
      this.siteTab = (this.SSDSiteDetails.CorpAccountId === null) ? true : false;
      this.siteDisplayName = this.SSDSiteDetails.DisplayName;
      this.isSiteCache = true;
      this.isClearAllVisible = true;
      this.updateSiteObject(this.SSDSiteDetails.ContextPointId, this.SSDSiteDetails.SiteName, this.SSDSiteDetails.DisplayName, this.SSDSiteDetails.CorporateAccountName);
    }
    else if (this.isOnlySiteSelectionCVOC) {
      this.filterObjectFlag = false;
    }
    if (this.disableEVOC) {
      this.isInternal = false;
    }
    if (this.isQueryParams && this.isSSD) {
      this.filterObjectFlag = true;
      this.route.queryParams.subscribe(params => {
        if (Object.keys(params).length !== 0) {
          this.paramsObj = { ...params };
          for (let [key] of Object.entries(this.paramsObj)) {
            this.paramsObj[key] = this.paramsObj[key].trim()
          }
        }
      });
      if (!this.paramsObj.pdfGeneration) {
        this.getsitesByCorporate([parseInt(this.paramsObj.siteId)])
      }
    }
    if (this.isSSD) {
      this.isSSDTab = true;
      this.title = this.isMyDocument? this.translate.instant('COMMON.CUSTOMER_DIVISION') : this.translate.instant('COMMON.PARTNER_DIVISION');
    }else if (this.isOnlySiteSelectionCVOC) {
      this.isDisable = true;
      this.title = this.translate.instant('COMMON.SITES');
    } else {
      this.title = this.translate.instant('COMMON.CUSTOMER_DIVISION');
      this.isCvoc = true;
    }
    if (this.isFI) {
      this.isFITab = true;
    }
    if (this.isSiteEnabled) {
      this.isSiteTabEnabled = true;
    }
    if (ServiceTypeIds.includes(this.serviceID)) {
      this.isDisable = true;
    }
    this.sitesLoading = false;
    // check if the user doesn't have Nalco (2000,1) access, load the top 50 sites in site search on initial load
    //let userSetting = JSON.parse(this.localCacheService.fetchData("UserSettings"));

    if (!userSetting.HasNalcoAccesUser) {
      this.sitesLoading = true;
      this.getSites('');
    }
    this.txtQueryChanged
      .pipe(debounceTime(1400) // wait 1.4 sec after the last event before emitting last event
      ,distinctUntilChanged() // only emit if value is different from previous value
      ,filter(searchString => searchString !== null))
      .subscribe(searchString => {
        // Call your function which calls API or do anything you would like do after a lag of 1 sec
        if (this.appliedFilters.parentId != '') {
          this.getSitesForFilter(searchString, this.pageIndex);
        }
        else {
          this.getSites(searchString, true);
        }
      });
    this.updateAccountSiteFilterBy()
  }

  updateAccountSiteFilterBy() {
    this.localCacheService.clearData('ecolab.envision_myDocumentscvocFilterDetails')
    if (this.filterObject.parentId != '' && this.filterObject.parentId != null && this.siteObject.siteId == '' && !this.isQueryParams) {
      this.localCacheService.storeData('ecolab.envision_myDocumentscvocFilterDetails', JSON.stringify(this.filterObject), "local");
    }
    else {
      this.localCacheService.storeData('ecolab.envision_myDocumentscvocFilterDetails', JSON.stringify(this.siteObject), "local");
    }
  }

  ngDoCheck() {
    this.updateAccountSiteFilterBy()
  }

  ngOnChanges(changes: SimpleChanges) {
    this.isSSD = this.isMyDocument ? this.isMyDocument : this.isSSD;
    if (this.isDeepLink) {
      this.filterObject = this.filterObj;
    }
  }
  hideTabBasedOnAccess() {
    if ((this.isSSD || this.isFI || this.isSiteEnabled) && this.isStreetAccountUser) {
      this.hideTab = true;
    }
  }

  showDefaultSite() {
    const data = {
      'Dag': 'All',
      'PageIndex': 1,
      'PageSize': 50,
      'SearchString': null
    };
    this.sharedService.getsites(data).subscribe(
      response => {
        let siteData = [];
        if (response && response.length > 0) {
          response.map(site => siteData.push(site));
        }
        this.onSiteSelect.emit({ ...siteData[0], CorporateAccountName: siteData[0].CorpAccountName });
      });
  }

  loadSiteParameters($event) {
    this.selectedSiteInfo = $event;
    this.isDisable = false;
    //this.selectedSiteOnInit = [];
    this.isClearAllVisible = true;
    if ($event == undefined) {
      if (this.isOnlySiteSelectionCVOC || (this.serviceID == ServiceType.FinacialImpact && !this.selectedSiteInfo)) {
        this.isDisable = true;
      } else {
        this.siteDisplayName = '';
      }
      this.isClearAllVisible = false;
      this.siteTab = false;
      this.SiteDropdownData.map(element => {
        element.checked = false;
      });
    } else {
      this.siteTab = true;
      this.SiteDropdownData.map(element => {
        if (element.id.ContextPointId !== $event.ContextPointId) {
          element.checked = false;
        }
      });
      this.siteData.forEach(x => {
        if (x.ContextPointId === $event.ContextPointId) {
          if (this.isSSD) {
            this.localCacheService.storeData('SSDSiteDetails', JSON.stringify(x), 'session');
          }
          else if (this.isFI) {
            this.localCacheService.storeData('FISiteDetails', JSON.stringify(x), 'session');
          } else if (this.isSiteEnabled) {
            this.localCacheService.storeData('SiteTabSiteDetails', JSON.stringify(x), 'session');
          }
        }
      });
    }
  }

  loadParameters(event) {
    this.isClearAllSelected = false;
    if (this.appliedFilters.parentId == '' && this.isOnlySiteSelectionCVOC) {
      this.loadSiteParameters(event);
      return;
    }
    this.isClear = false;
    this.isDisable = false;
    this.selectedSiteOnInit = [];
    this.isClearAllVisible = true;
    if (event === undefined) {
      if (this.isOnlySiteSelectionCVOC) {
        this.isDisable = true;
      } else {
        this.siteDisplayName = '';
        if (this.isSSD && this.selectedCorporateAccountDetails && this.selectedCorporateAccountDetails.parentName && this.selectedCorporateAccountDetails.parentName.toUpperCase() === CorporateAccountName.UNALIGNED_SITE && !this.siteDisplayName) {
          this.isDisable = true;
          this.showAlertMessage();
        }
      }
      this.isSiteSelected = false;
      this.isClearAllVisible = false;
      this.localCacheService.clearData('StreetAccountSelectedSite');
      this.selectedSiteforAvailParams = '';
      //removed the checkbox from selected Sites
      this.SiteDropdownData.map(element => {
        element.checked = false;
      });
    } else {
      this.SiteDropdownData.map(element => {
        if (element.id.ContextPointId !== event.ContextPointId) {
          element.checked = false;
        }
      });
      if (this.isSSD && this.selectedCorporateAccountDetails && this.selectedCorporateAccountDetails.parentName && this.selectedCorporateAccountDetails.parentName.toUpperCase() === CorporateAccountName.UNALIGNED_SITE) {
        this.hideAlertMessage();
      }
      this.passSite = event;
      this.passSite.CorpAccountId = this.appliedFilters.parentId;
      this.passSite.CorporateAccountName = this.appliedFilters.parentName;
      this.isSiteSelected = true;
    }
  }

  sortArray(dataList) {
    dataList.sort(function (a, b) {
      const x = a.display;
      const y = b.display;
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
    return dataList;
  }

  fetchMore() {
    this.pageIndex++;
    if (this.siteTab || (this.appliedFilters.parentId == '' && this.isOnlySiteSelectionCVOC)) {
      this.getSites(this.searchValue, true, this.pageIndex);
    }
    else {
      this.getSitesForFilter(this.searchValue, this.pageIndex);
    }
  }

  filterSiteText(event) {
    this.searchValue = event
    this.txtQueryChanged.next(event);
  }

  appliedFiltersChange(event) {
    Object.assign(this.appliedFilters, event);
  }

  filterObjChange(event) {
    this.isSiteSelected = false;
    this.localCacheService.clearData('StreetAccountSelectedSite');

    this.pageIndex = 1;
    Object.assign(this.appliedFilters, event);
    let filters: Array<any> = this.appliedFilters.children;
    const index = filters.length;
    this._clearData = false;
    if (this.isOnlySiteSelectionCVOC) {
      this.isDisable = true;
      if (index > 0) {
        this.siteDisplayName = "";
        this.isSiteCache = false;
        this.isClear = true;
      } else {
        this.SSDSiteDetails = this.userService.getSSDSiteDetails();
        this.FISitedetails = this.userService.getFISiteFilterDetails();
        this.siteTabSiteDetails = this.userService.getSiteTabSiteDetails();
        if (this.SSDSiteDetails && this.isSSD) {
          this.isDisable = false;
          this.isClearAllVisible = true;
          this.isSiteCache = true;
          this.siteDisplayName = this.SSDSiteDetails.DisplayName;
        }
        else if (this.FISitedetails && this.isFI) {
          this.isDisable = false;
          this.isClearAllVisible = true;
          this.isSiteCache = true;
          this.siteDisplayName = this.FISitedetails.DisplayName;
        } else if (this.siteTabSiteDetails && this.isSiteEnabled) {
          this.isDisable = false;
          this.isClearAllVisible = true;
          this.isSiteCache = true;
          this.siteDisplayName = this.siteTabSiteDetails.DisplayName;
        }
      }
      this.SiteDropdownData = [];
    }
    if (index > 0 && index < 3 && this.multiselectDropdownFlag) {
      this.getCustomerDivisionChildren(filters[index - 1], index);
    } else if (index > 0) {
      this.getCustomerDivisionChild(filters[index - 1].contextPointId, this.businessUnit, index);
    }
  }

  getServiceSites(data: IData): Observable<ISite[]> {
    if (this.serviceID === ServiceType.FinacialImpact) {
      data['ServiceTypeId'] = this.serviceID;
      return this.sharedService.getServiceEnabledSites(data);
    }
    else {
      return this.sharedService.getsites(data);
    }
  }

  getSites(searchString: string, isSearchedText?: boolean, pageIndex?: number) {
    this.sitesLoading = true;
    if (pageIndex === undefined || pageIndex === null) {
      pageIndex = 1;
      this.pageIndex = 1;
    }
    const data = {
      'Dag': 'All',
      'PageIndex': pageIndex,
      'PageSize': 25,
      'SearchString': searchString
    };
    if (pageIndex === 1) {

      this.SiteDropdownData = [];
      this.siteData = [];
    }

    this.getServiceSites(data).subscribe(
      (response: ISite[]) => {
        let siteInfo = JSON.parse(JSON.stringify(response));
        if (response && response.length > 0) {
          response.map((site: ISite) => this.siteData.push(site));
          // this.siteData = JSON.parse(JSON.stringify(response));
        }

        for (let i = 0; i < siteInfo.length; i++) {
          let displayName = '';
          if (siteInfo[i].Street) {
            displayName = siteInfo[i].SiteName + ' - ' +
              siteInfo[i].CustomerLocation + ' - ' + siteInfo[i].Street;
          } else {
            displayName = siteInfo[i].SiteName + ' - ' +
              siteInfo[i].CustomerLocation;
          }

          let id = {
            "ContextPointId": siteInfo[i].ContextPointId,
            "CorpAccountId": siteInfo[i].CorpAccountId,
            'CorporateAccountName': siteInfo[i].CorpAccountName,
            "SiteName": siteInfo[i].SiteName
          };
          // if (this.siteTab && this.serviceEnabledName === "WQ") {
          //   if (siteInfo[i].CorpAccountName.toLowerCase() === "unaligned") {
          //     this.SiteDropdownData.push({ id, display: displayName.toUpperCase() });
          //   }
          // } else {
          //   this.SiteDropdownData.push({ id, display: displayName.toUpperCase() });
          // }
          this.SiteDropdownData.push({ id, display: displayName.toUpperCase() });
        }


        this.SiteDropdownData.sort(function (a, b) {
          const x = a.display;
          const y = b.display;
          return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });

        this.sitesLoading = false;
        if (!this.localCacheService.fetchData('StreetAccountSelectedSite')) {
          this.depValue = true;
        } else {
          this.depValue = false;
        }
      },
      error => {
        this.isError = true;
        this.errorInfo = error;
        this.tog = false;
        if (500 <= error.status && error.status < 600) {
        } else if (error.status === 417) {
          this.router.navigate(['/access-denied']);
        } else if (error.status === 404) {
          this.router.navigate(['/**']);
        } else {
          this.errorHandling.showErrorPopup(error);
        }
        //this.setState(ComponentState.error);
        this.sitesLoading = false;
        this.showLoader = false;
      }
    );
  }

  geoSalesFilterChange(event) {
    this.appliedFilters.parentId = '';
    this.appliedFilters.parentName = '';
    this.appliedFilters.children = event.children;
    this.isDisable = false;
    if (this.serviceID == ServiceType.FinacialImpact && this.appliedFilters.children && !this.appliedFilters.children.length) {
      this.isDisable = true;
    }
  }

  saveSitesFilter() {
    if (this.siteTab) {
      this.onSiteSelect.emit(this.selectedSiteInfo);
    }
    else {
      this.onFiltersChange.emit(this.appliedFilters);
    }
  }

  notifyActiveTab(tabId: string) {
    let activeTab = '';
    switch (tabId) {
      case "ngb-tab-0": activeTab = ActiveFilterTab.CustomerDivision;
        break;
      case "ngb-tab-1": activeTab = ActiveFilterTab.GeoAndSalesRegion;
        break;
      case "ngb-tab-2": activeTab = ActiveFilterTab.Sites;
        break;
      default: break;
    }
    this.onActiveFilterTabChange.emit(activeTab);
  }

  tabChange(event) {
    this.ngOnDestroy();
    this.notifyActiveTab(event.nextId);
    if (event.nextId == "ngb-tab-0" && event.activeId == "ngb-tab-2") {
      this.siteTab = false
    }
    if (event.nextId == "ngb-tab-2" && event.activeId == "ngb-tab-0") {
      this.isSiteSelected = false
      this.siteTab = true;
      this.SSDSiteDetails = this.userService.getSSDSiteDetails();
      this.FISitedetails = this.userService.getFISiteFilterDetails();
      this.siteTabSiteDetails = this.userService.getSiteTabSiteDetails();
      // const appliedWQStreetFilter = this.userService.getWQStreetFilterDetails();
      if (this.SSDSiteDetails && this.isSSD) {
        this.isSiteCache = true;
        this.siteDisplayName = this.SSDSiteDetails.DisplayName;
      }
      else if (this.FISitedetails && this.isFI) {
        this.isSiteCache = true;
        this.siteDisplayName = this.FISitedetails.DisplayName;
      } else if (this.siteTabSiteDetails && this.isSiteEnabled) {
        this.isSiteCache = true;
        this.siteDisplayName = this.siteTabSiteDetails.DisplayName;
      }
      // else if (appliedWQStreetFilter && this.serviceEnabledName === ServiceEnabledName.WATER_QUALITY) {
      //   this.isSiteCache = true;
      //   this.siteDisplayName = appliedWQStreetFilter.DisplayName;
      // }
      else {
        this.isSiteCache = false;
        this.siteDisplayName = "";
      }
    }
    this.partnerDivisonSiteSearchDisabled = true;
    this.selectedSiteOnInit = [];
    this.SiteDropdownData = [];
    this.appliedFilters = { parentId: '', parentName: '', children: [] };
    this.isClear = true;
    this.callClear = true;
    this._clearData = true;
    this.isEVOCFilter = '';
  }

  getSitesForFilter(searchString: string, pageIndex?: number) {
    this.sitesLoading = true;
    this.siteID = [];
    if (this.appliedFilters.children.length > 0) {
      this.filters = this.appliedFilters.children;
    }
    else if (this.appliedFilters.children.length == 0) {
      this.filters.push(this.appliedFilters.parentId);
    }
    const index = this.filters.length;
    if (this.appliedFilters.children.length > 0) {
      let childrenLength = this.appliedFilters.children.length;
      if (Array.isArray(this.filters[childrenLength - 1])) {
        this.filters[childrenLength - 1].forEach(item => this.siteID.push(item.contextPointId));
      } else {
        this.siteID.push(this.filters[index - 1].contextPointId);
      }
    }
    else if (this.appliedFilters.children.length == 0) {
      this.siteID.push(this.appliedFilters.parentId);
    }
    if (pageIndex === 1) {
      this.SiteDropdownData = [];
      this.siteData = [];
    }

    if (index > 0) {
      this.dataVisualizationService.getSiteDetailsWithPaging(this.siteID, pageIndex, 50, searchString).subscribe(
        response => {
          let siteInfo = JSON.parse(JSON.stringify(response));
          if (response && response.length > 0) {
            response.map(site => this.siteData.push(site));
          }
          for (let i = 0; i < siteInfo.length; i++) {
            let displayName = '';
            if (siteInfo[i].Street) {
              displayName = siteInfo[i].SiteName + ' - ' +
                siteInfo[i].CustomerLocation + ' - ' + siteInfo[i].Street;
            } else {
              displayName = siteInfo[i].SiteName + ' - ' +
                siteInfo[i].CustomerLocation;
            }
            let id = {};
            id = { "ContextPointId": siteInfo[i].ContextPointId, "CorpAccountId": siteInfo[i].CorpAccountId, "SiteName": siteInfo[i].SiteName };
            this.SiteDropdownData.push({ id, display: displayName.toUpperCase() });
          }
          this.SiteDropdownData.sort(function (a, b) {
            const x = a.display;
            const y = b.display;
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
          });

          this.sitesLoading = false;
        },
        error => {
          this.isError = true;
          this.errorInfo = error;
          this.tog = false;
          if (500 <= error.status && error.status < 600) {
          } else if (error.status === 417) {
            this.router.navigate(['/access-denied']);
          } else if (error.status === 404) {
            this.router.navigate(['/**']);
          } else {
            this.errorHandling.showErrorPopup(error);
          }
          //this.setState(ComponentState.error);
          this.sitesLoading = false;
          this.showLoader = false;
        }
      );
    }
  }

  applySiteFilters() {
    if (this.isSiteSelected) {
      Object.assign(this.filterObject, this.appliedFilters);
      this.filterObjectFlag = this.filterObject.parentId !== '';

      let effectiveFilter = this.filterObject.parentId;
      if (this.filterObject.children.length > 0) {
        let lastChildIndex = this.filterObject.children.length - 1;
        let lastChild = this.filterObject.children[lastChildIndex];
        effectiveFilter = lastChild.contextPointId;
        this.filterObjectFlag = true;

      }

      let updatedSetting = {
        contextPointId: effectiveFilter,
        filterObject: this.filterObject,
        filterObjectFlag: this.filterObjectFlag
      };

      let updatedSiteSetting = {
        siteId: this.passSite.ContextPointId,
        siteName: this.passSite.SiteName,
        displayName: this.passSite.DisplayName,
        CorporateAccountName: this.passSite.CorporateAccountName
      };

      this.userService.saveSSDFilterDetails(updatedSetting);
      this.userService.saveFilterDetails(updatedSetting);
      this.userService.saveSiteTabSiteFilterDetails(updatedSetting);
      this.localCacheService.storeData('multiselectfilterDetails', JSON.stringify(updatedSetting), 'local');
      this.userService.saveSSDSiteDetails(this.passSite);
      this.onSiteSelect.emit(this.passSite);
      this.isSiteCache = true;
      this.updateSiteObject(updatedSiteSetting.siteId, updatedSiteSetting.siteName, updatedSiteSetting.displayName, updatedSiteSetting.CorporateAccountName)
      this.siteDisplayName = this.siteObject.displayName;
      this.isQueryParams = false;
      this.siteTab = false;
      this.evocCvocFilterService.setFilterData(updatedSetting);
      this.isCvoc = true;
      // this.userService.clearWQStreetFilterDetails();
    } else if (this.siteTab) {
      let updatedSetting = {
        contextPointId: this.selectedSiteInfo.ContextPointId,
        filterObject: { parentId: this.selectedSiteInfo.CorpAccountId, parentName: this.selectedSiteInfo.SiteName, children: [{ contextPointId: this.selectedSiteInfo.ContextPointId }] },//revisit for children
        filterObjectFlag: true
      };
      this.filterObjectFlag = true;
      let passSite = {
        ContextPointId: this.selectedSiteInfo.ContextPointId,
        CorpAccountId: null,
        CorporateAccountName: this.selectedSiteInfo.CorporateAccountName,
        SiteName: this.selectedSiteInfo.SiteName,
        DisplayName: this.selectedSiteInfo.SiteName
      };
      this.filterObjectFlag = this.isOnlySiteSelectionCVOC ? this.selectedSiteInfo.SiteName !== '' : this.filterObjectFlag;
      // if (this.selectedSiteInfo.CorporateAccountName.toLowerCase() === 'unaligned'
      //   && this.serviceEnabledName === ServiceEnabledName.WATER_QUALITY) {
      //   this.filterObjectFlag = true;
      // }
      this.siteDisplayName = this.selectedSiteInfo.SiteName;
      this.userService.saveSSDSiteDetails(passSite);
      this.userService.saveFISiteDetails(passSite);
      this.userService.saveSiteTabSiteDetails(passSite);
      this.updateSiteObject(passSite.ContextPointId, passSite.SiteName, passSite.DisplayName, passSite.CorporateAccountName);
      this.onSiteSelect.emit(this.selectedSiteInfo);
      this.isSiteCache = true;
      this.isQueryParams = false;
      this.filterObject = updatedSetting.filterObject;
      this.userService.saveSSDFilterDetails(updatedSetting);
      this.userService.saveFIFilterDetails(updatedSetting);
      this.userService.saveFilterDetails(updatedSetting);
      this.userService.saveSiteTabSiteFilterDetails(updatedSetting);
      // if (this.selectedSiteInfo.CorporateAccountName
      //   && this.selectedSiteInfo.CorporateAccountName.toLowerCase() === 'unaligned') {
      //   this.userService.saveWQStreetFilterDetails({
      //     ContextPointId: this.selectedSiteInfo.ContextPointId,
      //     CorpAccountId: this.selectedSiteInfo.CorpAccountId,
      //     CorporateAccountName: this.selectedSiteInfo.CorporateAccountName,
      //     SiteName: this.selectedSiteInfo.SiteName,
      //     DisplayName: this.selectedSiteInfo.DisplayName
      //   });
      // } else {
      //   this.userService.clearWQStreetFilterDetails();
      // }
      this.localCacheService.storeData('multiselectfilterDetails', JSON.stringify(updatedSetting), 'local');
      this.evocCvocFilterService.setFilterData(updatedSetting);
      this.userService.clearFilterDetails();
      this.localCacheService.clearData('multiselectfilterDetails', 'local');
      this.isCvoc = false;
    } else {
      Object.assign(this.filterObject, this.appliedFilters);
      this.filterObjectFlag = this.filterObject.parentId !== '';

      let effectiveFilter = this.filterObject.parentId;
      if (this.filterObject.children.length > 0 && !this.multiselectDropdownFlag) {
        let lastChildIndex = this.filterObject.children.length - 1;
        let lastChild = this.filterObject.children[lastChildIndex];
        effectiveFilter = lastChild.contextPointId;
        this.filterObjectFlag = true;

      } else if (this.filterObject.children.length > 0 && this.multiselectDropdownFlag) {
        effectiveFilter = this.filterObject.parentId;
        this.filterObjectFlag = true;
      }

      let updatedSetting = {
        contextPointId: effectiveFilter,
        filterObject: this.filterObject,
        filterObjectFlag: this.filterObjectFlag
      };
      this.userService.saveSSDFilterDetails(updatedSetting);
      this.userService.saveFIFilterDetails(updatedSetting);
      this.userService.saveFilterDetails(updatedSetting);
      this.userService.saveSiteTabSiteFilterDetails(updatedSetting);
      this.localCacheService.storeData('multiselectfilterDetails', JSON.stringify(updatedSetting), 'local');
      this.localCacheService.storeData('corporateId',updatedSetting.contextPointId,'local');
      this.SSDSiteDetails = this.userService.getSSDSiteDetails();
      if (this.SSDSiteDetails && (this.isSSD || this.isFI || this.isSiteEnabled || this.isOnlySiteSelectionCVOC)) {
        this.isSiteCache = true;
        if (this.siteDisplayName === this.SSDSiteDetails.DisplayName) {
          this.updateSiteObject(this.SSDSiteDetails.ContextPointId, this.SSDSiteDetails.SiteName, this.SSDSiteDetails.DisplayName, this.SSDSiteDetails.CorporateAccountName);
          this.updateSiteInfo();
        } else {
          this.onFiltersSubmit.emit(this.filterObject);
          this.clearSiteCache();
        }
      } else {
        // It will be changed while working on caching
        this.localCacheService.storeData('WFICorporateAccount', JSON.stringify(this.filterObject.parentId));
        this.loader = false;
        if (this.isSiteEnabled) {
          this.localCacheService.clearData('SiteTabSiteDetails',"local");
          this.localCacheService.clearData('SiteTabSiteFilterDetails',"local");
        }
        if (this.multiselectDropdownFlag && this.filterObject.parentId == '' && this.serviceEnabledName == 'WFI') {
          this.filterObject.parentId = this.corpAccList[0].AccountId;
          this.filterObject.parentName = this.corpAccList[0].AccountName;
          this.filterObjectFlag = true;
          updatedSetting = {
            contextPointId: this.filterObject.parentId,
            filterObject: this.filterObject,
            filterObjectFlag: this.filterObjectFlag
          }
          this.userService.saveSSDFilterDetails(updatedSetting);
          this.userService.saveFilterDetails(updatedSetting);
          this.userService.saveSiteTabSiteFilterDetails(updatedSetting);
          this.localCacheService.storeData('multiselectfilterDetails', JSON.stringify(updatedSetting), 'local');
        }
        this.onFiltersSubmit.emit(this.filterObject);
        this.clearSiteCache();
      }
      this.isQueryParams = false;
      this.siteService.filterData.emit(this.filterObject);
      this.evocCvocFilterService.setFilterData(updatedSetting);
      this.isCvoc = true;
      this.appliedFiltersBackup = '';
      this.evocCacheBackup = [];
      // this.userService.clearWQStreetFilterDetails();
    }
    this.ngOnDestroy();
  }

  onAccountSelected(acctDetail: any) {
    this.loader = true;
    this.isSiteSelected = false;
    this.partnerDivisonSiteSearchDisabled = false;
    this.siteTab = false;
    this.isClear = true;
    this.SSDSiteDetails = this.userService.getSSDSiteDetails();
    this.isClearAllVisible = true;
    if (this.isOnlySiteSelectionCVOC) {
      this.isDisable = true;
    }
    if (this.serviceEnabledName === ServiceEnabledName.WATER_QUALITY && acctDetail.accId) {
      this.isDisable = false;
    }
    if (this.SSDSiteDetails && (this.isSSD || this.isFI || this.isSiteEnabled || this.isOnlySiteSelectionCVOC)) {
      if (acctDetail.accId === this.SSDSiteDetails.CorpAccountId) {
        this.siteDisplayName = this.SSDSiteDetails.DisplayName;
        this.isSiteCache = true;
        this.updateSiteObject(this.SSDSiteDetails.ContextPointId, this.SSDSiteDetails.SiteName, this.SSDSiteDetails.DisplayName, this.SSDSiteDetails.CorporateAccountName);
        if (this.SSDSiteDetails && this.isOnlySiteSelectionCVOC) {
          this.isDisable = false;
        }
      } else {
        if (this.isOnlySiteSelectionCVOC) {
          this.isSiteCache = false;
          this.siteDisplayName = "";
          this.isDisable = true;
        } else {
          this.clearSiteCache();
        }
      }
    }
    this.appliedFilters.parentName = acctDetail.accName;
    this.appliedFilters.parentId = acctDetail.accId;
    this.selectedCorporateAccountDetails = this.appliedFilters;
    this.getCustomerDivisionData(this.appliedFilters.parentId, this.businessUnit);
    this.pageIndex = 1;
    this.onFiltersInit.emit(this.appliedFilters);
    if (ServiceTypeIds.includes(this.serviceID)) {
      this.isDisable = false;
    }
    // this.clearAllClicked = false;
    if (this.isSSD) {
      if (this.selectedCorporateAccountDetails && this.selectedCorporateAccountDetails.parentName && this.selectedCorporateAccountDetails.parentName.toUpperCase() === CorporateAccountName.UNALIGNED_SITE && !this.siteDisplayName) {
        this.isDisable = true;
        this.showAlertMessage();
      }
      else {
        this.hideAlertMessage();
      }
    }
  }

  showAlertMessage() {
    const alertMsgPopup = document.getElementById('alert-msg');
    alertMsgPopup.textContent = this.translate.instant('COMMON.MANDATORY_SITE_SELECTION');
  }

  hideAlertMessage() {
    const alertMsgPopup = document.getElementById('alert-msg');
    alertMsgPopup.textContent = '';
  }

  onAccountCleared() {
    this.loader = false;
    this.ngOnDestroy();
    this.appliedFilters = { parentId: '', parentName: '', children: [] };
    this.isSiteSelected = false;
    this.selectedSiteOnInit = [];
    this.SiteDropdownData = [];
    this.partnerDivisonSiteSearchDisabled = true;
    this.accountDetails = { data: [], index: '' };
    this.accountDetailsChild = { data: [], index: '' };
    this.accountchildrenDetails = [];
    this.localCacheService.storeData('childrenfilterDetails', JSON.stringify(this.accountchildrenDetails), 'local');
    if (ServiceTypeIds.includes(this.serviceID)) {
      this.isDisable = true;
      if (this.isSSD && this.selectedCorporateAccountDetails && this.selectedCorporateAccountDetails.parentName && this.selectedCorporateAccountDetails.parentName.toUpperCase() === CorporateAccountName.UNALIGNED_SITE) {
        this.hideAlertMessage();
      }
    }
    if (this.isOnlySiteSelectionCVOC) {
      this.siteTab = true;
      this.isClear = true;
      this.siteDisplayName = "";
      this.isDisable = true;
      this.isClearAllVisible = false;
      this.selectedCorporateAccountDetails = this.appliedFilters;
    }
  }

  // Shared Filter Component code Start
  showFilters() {
    // select the Tab based on the filter selection
    this.isClearAllSelected = false;
    this.SSDSiteDetails = this.userService.getSSDSiteDetails();
    this.siteTabSiteDetails =this.userService.getSiteTabSiteDetails();
    if (this.filterObject.children.length > 0 && this.filterObject.parentId == '' && this.filterObject.parentName == '') {
      this.activeTabID = 'ngb-tab-1';
    }else if( 
      this.isSSD && 
      (this.siteTab && this.SSDSiteDetails && this.SSDSiteDetails.CorporateAccountName !== null) || 
      (this.isQueryParams && this.paramsObj && this.paramsObj.siteId)){
          this.activeTabID = 'ngb-tab-2';
          this.isClear = true;
          this.siteTab = (this.SSDSiteDetails.CorpAccountId === null) ? true : false;
          this.siteDisplayName = this.SSDSiteDetails.DisplayName;
          this.isSiteCache = true;
          this.isClearAllVisible = true;
          this.updateSiteObject(
            this.SSDSiteDetails.ContextPointId, 
            this.SSDSiteDetails.SiteName, 
            this.SSDSiteDetails.DisplayName, 
            this.SSDSiteDetails.CorporateAccountName);
    } else if(this.siteTab && this.isSiteEnabled && this.siteTabSiteDetails && this.siteTabSiteDetails.CorporateAccountName !== null){
      this.activeTabID = 'ngb-tab-2';
    }
    else {
      this.activeTabID = 'ngb-tab-0';
    }
    this.notifyActiveTab(this.activeTabID);
    this.modalService.open(this.filterSitesModal, { backdrop: 'static', keyboard: false })
      .result.then(() => { this.applySiteFilters(); }, () => { });

    this.countryData = '';
    this.territoriesData = '';
    //this.appliedWQStreetSiteFilter = this.userService.getWQStreetFilterDetails();
    if ((this.isCvoc && !this.isOnlySiteSelectionCVOC) || (this.isCvoc && this.isOnlySiteSelectionCVOC && this.SSDSiteDetails && this.SSDSiteDetails.CorpAccountId !== null)) {
      this.isEVOCFilter = this.filterObject.parentName;
    }
    else {
      this.isEVOCFilter = '';
    }
  }
  //child array
  getchildLoader(newItem: any) {
    this.loader = newItem;
  }
  // Api Call to fetch the Customer details(DagCorporateAccountChildren)
  selectedSite() {
    if (this.isOnlySiteSelectionCVOC && this.appliedFilters.parentId == '') {
      this.selectedSiteTab();
      return;
    }
    if (this.pageIndex == 1) {
      this.siteID = [];
      this.isClear = true;
      this.getSitesForFilter('', this.pageIndex);
    }
    if (this.localCacheService.fetchData('StreetAccountSelectedSite')) {
      const prevSite = JSON.parse(this.localCacheService.fetchData('StreetAccountSelectedSite'));
      let displayName = '';
      if (prevSite.Street) {
        displayName = (prevSite.SiteName + ' - ' +
          prevSite.CustomerLocation + ' - ' + prevSite.Street).toUpperCase();
      } else {
        displayName = (prevSite.SiteName + ' - ' +
          prevSite.CustomerLocation).toUpperCase();
      }
      const index = this.SiteDropdownData.findIndex(x => x.display === displayName && x.id === prevSite.ContextPointId);
      if (index >= 0) {
        this.SiteDropdownData.splice(index, 1);
        this.SiteDropdownData.splice(0, 0, { id: prevSite.ContextPointId, display: displayName, checked: true });
      }
    }
  }

  selectedSiteTab() {
    if (this.localCacheService.fetchData('StreetAccountSelectedSite')) {
      const prevSite = JSON.parse(this.localCacheService.fetchData('StreetAccountSelectedSite'));
      let displayName = '';
      if (prevSite.Street) {
        displayName = (prevSite.SiteName + ' - ' +
          prevSite.CustomerLocation + ' - ' + prevSite.Street).toUpperCase();
      } else {
        displayName = (prevSite.SiteName + ' - ' +
          prevSite.CustomerLocation).toUpperCase();
      }
      const index = this.SiteDropdownData.findIndex(x => x.display === displayName && x.id === prevSite.ContextPointId);
      if (index >= 0) {
        this.SiteDropdownData.splice(index, 1);
        this.SiteDropdownData.splice(0, 0, { id: prevSite.ContextPointId, display: displayName, checked: true });
      }
    } else if (this.serviceEnabledName === 'Heat_Surveys' && this.SSDSiteDetails === null) {
      this.getSites('', true);
    }
  }

  // Update site object
  updateSiteObject(siteId, siteName, displayName, corporateAccountName) {
    this.siteObject = {
      'siteId': siteId,
      'siteName': siteName,
      'displayName': displayName,
      'CorporateAccountName': corporateAccountName
    };
  }

  // Update Site Info
  updateSiteInfo() {
    let siteInfo = {
      ContextPointId: this.SSDSiteDetails.ContextPointId,
      CorpAccountId: this.SSDSiteDetails.CorpAccountId,
      CorporateAccountName: this.SSDSiteDetails.CorporateAccountName,
      SiteName: this.SSDSiteDetails.SiteName,
      DisplayName: this.SSDSiteDetails.DisplayName
    };
    this.onSiteSelect.emit(siteInfo);
  }

  // Clear Site Cache
  clearSiteCache() {
    this.isSiteCache = false;
    this.siteDisplayName = "";
    this.updateSiteObject('', '', '', '');
    this.localCacheService.clearData('SSDSiteDetails', 'local');
    this.localCacheService.clearData('SiteTabSiteDetails', 'local');
  }

  getCustomerDivisionData(id: number, filter: string) {
    let IdFilterKey = id.toString() + '_EVOC';
    let fetchData = this.localCacheService.fetchData(IdFilterKey);
    let Evocdetails = this.userService.getFilterDetails();
    let forkJoinInputs = [];

    if (fetchData) {
      this.accountDetails = { data: [], index: '' };
      //this.appliedFilters.children= Evocdetails.filterObject.children;
      this.accountDetails.data = JSON.parse(fetchData);
      this.accountDetails.index = 0;
      this.accountchildrenDetails[0] = JSON.parse(fetchData);
      this.localCacheService.storeData('childrenfilterDetails', JSON.stringify(this.accountchildrenDetails), 'local');
      if (this.accountDetails.data.length == 0) {
        this.loader = false;
      }
      if (Evocdetails) {
        let getEvocArrayData = Evocdetails.filterObject;
        this.appliedFilters.children = Evocdetails.filterObject.children;

        if (!this.multiselectDropdownFlag) {
          if (getEvocArrayData && getEvocArrayData.children) {
            this.selectedContextPoints = getEvocArrayData.children.map(item => item.contextPointId);
          }

          if (this.selectedContextPoints) {
            this.selectedContextPoints.map((item, index) => {
              setTimeout(() => {
                this.getCustomerDivisionChild(item, filter, index + 1);
              }, 50);
            })
          }
        } else {
          if (id == getEvocArrayData.parentId) {
            if (getEvocArrayData && getEvocArrayData.children) {
              this.selectedContextPoints = getEvocArrayData.children.map((item, index) => {
                if (index < 2 && Array.isArray(item)) {
                  return item.map(element => element);
                } else
                  return item.contextPointId;
              });
            }
            if (this.selectedContextPoints) {
              this.selectedContextPoints.map((item, index) => {
                if (index < 2 && Array.isArray(item)) {
                  const ids = [];
                  item.forEach(item => ids.push(JSON.stringify(item.contextPointId)));
                  forkJoinInputs.push(this.sharedService.getCustomerDivisionChildren(ids));
                }
              });
              if (forkJoinInputs.length > 0) {
                forkJoin(forkJoinInputs).subscribe(response => {
                  this.loader = true;
                  response.forEach((element, index) => {
                    setTimeout(() => {
                      this.accountDetailsChild = { data: [], index: '' };
                      this.accountDetailsChild.data = element;
                      this.accountDetailsChild.index = index + 1;
                      this.accountchildrenDetails[index + 1] = element;
                      this.localCacheService.storeData('childrenfilterDetails', JSON.stringify(this.accountchildrenDetails), 'local');
                    }, 100);
                  });
                  this.selectedContextPoints.forEach((item, index) => {
                    if (index > 1) {
                      setTimeout(() => this.getCustomerDivisionChild(item, filter, index + 1), 1000);
                    }
                  });
                });
              }
            }
          }
        }
      }
    }

    else {
      this.subscriptions.push(this.sharedService.getCustomerDivisionData(id, filter).subscribe(response => {
        this.accountDetails = { data: [], index: '' };
        this.accountDetails.data = response;
        this.accountDetails.index = 0;
        this.accountchildrenDetails[0] = response;
        this.localCacheService.storeData('childrenfilterDetails', JSON.stringify(this.accountchildrenDetails), 'local');
        this.localCacheService.storeData(IdFilterKey, JSON.stringify(response));
        if (this.accountDetails.data.length > 0) {
          let account = this.accountDetails.data[0];
          this.appliedFilters.parentId = account.ParentId;
          this.appliedFilters.parentName = account.ParentName;
        }
        if (this.accountDetails.data.length == 0) {
          this.loader = false;
        }

      }, error => {

        this.translate.get('COMMON.GENERIC_ERROR_MESSAGE').subscribe(
          res => {
            this.logger.info({ message: res, object: error }, false, true);
          }
        );

        if (error.status === 417) {
          this.router.navigate(['/access-denied']);
        } else if (error.status === 404) {
          this.router.navigate(['/**']);
        } else {
          this.errorHandling.showErrorPopup(error);
        }

      }
      ));
    }
  }

  // Api Call to fetch the Customer details children (DagParentEdgeChildren)
  getCustomerDivisionChild(id: number, filter: string, index) {
    this.loader = true;
    if (!this.isOnlySiteSelectionCVOC) {
      this.siteDisplayName = "";
    }
    let IdFilterKey = id.toString() + '_EVOC';
    let fetchData = this.localCacheService.fetchData(IdFilterKey);
    if (fetchData) {
      this.accountDetailsChild = { data: [], index: '' };
      this.accountDetailsChild.data = JSON.parse(fetchData);
      this.accountDetailsChild.index = index;
      this.loader = false;
    } else {
      this.subscriptions.push(this.sharedService.getCustomerDivisionChild(id, filter).subscribe(response => {
        this.accountDetailsChild = { data: [], index: '' };
        this.accountDetailsChild.data = response;
        this.accountDetailsChild.index = index;
        this.accountchildrenDetails[index] = response;
        this.localCacheService.storeData('childrenfilterDetails', JSON.stringify(this.accountchildrenDetails), 'local');
        this.localCacheService.storeData(IdFilterKey, JSON.stringify(response));
        this.loader = false;
      }, error => {
        this.translate.get('COMMON.GENERIC_ERROR_MESSAGE').subscribe(
          result => {
            this.logger.info({ message: result, object: error }, false, true);
          }
        );

        if (error.status === 417) {
          this.router.navigate(['/access-denied']);
        } else if (error.status === 404) {
          this.router.navigate(['/**']);
        } else {
          this.errorHandling.showErrorPopup(error);
        }
      }
      ));
    }
  }

  clearAll(event) {
    this.clearAllClicked = true;
    if (this.multiselectDropdownFlag && this.appliedFiltersBackup == '') {
      this.appliedFiltersBackup = JSON.stringify(event);
    }
    event.children = [];
    event.parentId = '';
    event.parentName = '';
    if (this.serviceID == ServiceType.FinacialImpact) {
      this.isDisable = true;
    }
    Object.assign(this.appliedFilters, event);
  }

  dismissSiteFilters() {
    if (this.multiselectDropdownFlag && this.clearAllClicked) {
      let updatedSetting = {
        contextPointId: JSON.parse(this.appliedFiltersBackup).parentId,
        filterObject: JSON.parse(this.appliedFiltersBackup),
        filterObjectFlag: true
      };
      this.userService.saveFilterDetails(updatedSetting);
      this.localCacheService.storeData('multiselectfilterDetails', JSON.stringify(updatedSetting), 'local');
      this.localCacheService.storeData(this.evocCacheKey, this.evocCacheBackup);
      this.clearAllClicked = false;
      this.appliedFiltersBackup = '';
      this.evocCacheBackup = [];
    }
    if (this.serviceEnabledName === 'Heat_Surveys') {
      this.appliedFilters = {
        'parentId': '',
        'parentName': '',
        'children': []
      }
      this.selectedCorporateAccountDetails = this.appliedFilters;
    }
  }

  // Shared Filter Component code end

  clearDetails(data) {
    let localKeys = Object.keys(this.localCacheService._store);
    let evocKeys = []
    if(this.isSSD){
      this.hideAlertMessage();
    }
    localKeys.map(element => {
      if (element.indexOf('_EVOC') > 0) {
        //removing name space keyword = "ecolab.envision_" which is getting appended in local cache service
        element = element.replace('ecolab.envision_', '');
        evocKeys.push({ element });
      }
    });
    //  this.evocCacheBackup != null || this.evocCacheBackup.length == 0
    if (this.multiselectDropdownFlag && (this.evocCacheBackup == null || this.evocCacheBackup.length == 0)) {
      this.userService.clearFilterDetails();
      this.localCacheService.clearData('multiselectfilterDetails', 'local');
      let corporateAccountId = JSON.parse(this.appliedFiltersBackup).parentId;
      evocKeys.map(element => element.element.includes(corporateAccountId) ? this.evocCacheKey = element.element : '');
      this.evocCacheBackup = this.localCacheService.fetchData(this.evocCacheKey);
    }

    //remove the selected corporate accounts from EVOC
    evocKeys.map(element => {
      this.localCacheService.clearData(element.element);
    })

    this.ngOnDestroy();
    if (this.callClear) {
      this.callClear = false
    } else {
      this.callClear = true;
    }
    if (data) {
      this.isSiteSelected = false;
      this.selectedSiteOnInit = [];
      this.SiteDropdownData = [];
      this.accountDetails = { data: [], index: '' };
      this.isClear = true;
      this.partnerDivisonSiteSearchDisabled = true
      this.localCacheService.clearData('StreetAccountSelectedSite');
      this.siteDisplayName = "";
      this._clearData = true;
      if (ServiceTypeIds.includes(this.serviceID)) {
        this.isDisable = true;
      }
      if (this.isOnlySiteSelectionCVOC) {
        this.isDisable = true;
        this.appliedFilters = { parentId: '', parentName: '', children: [] };
        this.isClearAllVisible = false;
        this.isClearAllSelected = true;
      } else {
        if (!this.isSSD) {
          this.localCacheService.clearData('SSDSiteDetails', 'local');
        }
      }
    }
  }

  getCustomerDivisionChildren(filters: any[], index) {
    const ids = [];
    this.loader = true;
    filters.forEach(item => ids.push(JSON.stringify(item.contextPointId)));
    this.subscriptions.push(this.sharedService.getCustomerDivisionChildren(ids).subscribe(response => {
      this.accountDetailsChild = { data: [], index: '' };
      this.accountDetailsChild.data = response;
      this.accountDetailsChild.index = index;
      this.accountchildrenDetails[index] = response;
      this.localCacheService.storeData('childrenfilterDetails', JSON.stringify(this.accountchildrenDetails), 'local');
      this.loader = false;
    }, error => {
      this.translate.get('COMMON.GENERIC_ERROR_MESSAGE').subscribe(
        res => {
          this.logger.info({ message: res, object: error }, false, true);
        }
      );
      if (error.status === 417) {
        this.router.navigate(['/access-denied']);
      } else if (error.status === 404) {
        this.router.navigate(['/**']);
      } else {
        this.errorHandling.showErrorPopup(error);
      }
      this.loader = false;
    }));
  }

  ngOnDestroy() {
    this.searchValue = '';
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  updateSiteDataByURL() {
    this.subscriptions.push(this.assetDetailsService.$siteURLChange.subscribe((siteDataURL) => {
      if (siteDataURL != null && siteDataURL != 'NotFound') {
        this.SSDSiteDetails = siteDataURL;
        this.filterObjectFlag = true;
        this.isDisable = true;
        this.isClear = true;
        this.siteTab = (this.SSDSiteDetails.CorpAccountId === null) ? true : false;
        this.siteDisplayName = this.SSDSiteDetails.DisplayName;
        this.isSiteCache = false;
        this.isClearAllVisible = true;
        this.updateSiteObject(this.SSDSiteDetails.ContextPointId, this.SSDSiteDetails.SiteName, this.SSDSiteDetails.DisplayName, this.SSDSiteDetails.CorporateAccountName);
        this.userService.saveSSDSiteDetails(siteDataURL);
        let updatedFilterSetting = {
          contextPointId: this.SSDSiteDetails.ContextPointId,
          filterObject: { parentId: this.SSDSiteDetails.CorpAccountId, parentName: this.SSDSiteDetails.SiteName, children: [] },
          filterObjectFlag: true
        };
        this.filterObject = updatedFilterSetting.filterObject;
        this.userService.saveSSDFilterDetails(updatedFilterSetting);
        this.localCacheService.clearData('systemAsset', 'local');
        this.onSiteSelect.emit(siteDataURL);
      }
      else if ((siteDataURL != null && siteDataURL == 'NotFound')) {
        this.filterObject = {
          'parentId': '',
          'parentName': '',
          'children': []
        };
        this.updateSiteObject('', '', '', '');
        this.siteDisplayName = "";
        this.filterObjectFlag = false;
        this.localCacheService.clearData('SSDSiteDetails', 'local');
        this.localCacheService.clearData('appliedfilterDetails', 'local');
        this.localCacheService.clearData('appliedSSDfilterDetails', 'local');
        this.localCacheService.clearData('systemAsset', 'local');
        this.isClearAllVisible = false;
        this.onSiteSelect.emit(null);
        this.showFilters();
      }
    }));
  }
  getsitesByCorporate(corpIds: Array<number>) {
    this.sharedService.getSitesForCorporates(corpIds).subscribe(
      response => {
        if (response && response.length > 0) {
          // the below condition is for PDF deep link when it has site id in the query parameter
          if (this.paramsObj.siteId !== -1 && this.paramsObj.viewId !== undefined) {
            const sitesByCorporate = JSON.parse(JSON.stringify(response));
            const selectedSite = sitesByCorporate.filter(site => site.ContextPointId === parseInt(this.paramsObj.siteId));
            if (selectedSite.length >= 1) {
              let obj = [{
                AccountId: this.siteCpId,
                AccountName: selectedSite[0].SiteName
              }]
              this.dataInput = obj;
            }
          }
        }
        error => {
          if (500 <= error.status && error.status < 600) {
          } else if (error.status === 417) {
            this.router.navigate(['/access-denied']);
          } else if (error.status === 404) {
            this.router.navigate(['/**']);
          } else {
            this.errorHandling.showErrorPopup(error);
          }
        }
      })
  }
  displayContextPointName(children: any[]): boolean {
    return children && children.length > 0 && children.some(child => child.hasOwnProperty('contextPointName'));
}
}
