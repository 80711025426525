import * as contracts from '../_contracts/ITourConfiguration';

export class Mapping implements contracts.IMapping {
  MeasurementInstanceId: number;
  SequenceNumber: number;

  constructor(mapping?: contracts.IMapping) {
    if (mapping) {
      this.MeasurementInstanceId = mapping.MeasurementInstanceId;
      this.SequenceNumber = mapping.SequenceNumber;
    }
  }
}

export class TourConfiguration implements contracts.ITourConfiguration {
  id: string;
  PartitionBy: number;
  SiteCpId: number;
  Name: string;
  IsVisibleToCustomer: boolean;
  IsActive: boolean;
  Mapping: Array<Mapping> = [];
  CreatedOn: string;
  CreatedBy: number;
  ModifiedOn: string;
  ModifiedBy: number;
  IsSelected: boolean;
  MeasurementInstanceId: number;
  mappingLookup?: any = {}; // UI reference

  constructor(tourConfig?: contracts.ITourConfiguration) {
    if (tourConfig) {
      this.id = tourConfig.id;
      this.PartitionBy = tourConfig.PartitionBy;
      this.SiteCpId = tourConfig.SiteCpId;
      this.Name = tourConfig.Name;
      this.IsVisibleToCustomer = tourConfig.IsVisibleToCustomer;
      this.IsActive = tourConfig.IsActive;
      if (tourConfig.Mapping) {
        tourConfig.Mapping.forEach(val => {
          var newMapping = new Mapping(val);
          this.Mapping.push(newMapping);
          this.mappingLookup[newMapping.MeasurementInstanceId] = newMapping
        });
      }
      this.CreatedOn = tourConfig.CreatedOn;
      this.CreatedBy = tourConfig.CreatedBy;
      this.ModifiedOn = tourConfig.ModifiedOn;
      this.ModifiedBy = tourConfig.ModifiedBy;
      this.IsSelected = true;
    }
  }
}

export class SamplePointsAndMeasurements implements contracts.ISamplePointsAndMeasurements {
  ContextPointId: number;
  MeasurementName: string;
  ContextPointTypePrettyName?: string;
  ParentContextPointId?: number;
  OriginType?: number;
  IsParent: boolean;
  SamplePointName?: string;
  MeasurementInstanceId?: number;
  childIds?: number[] = [];
  isNonConfiguredCalculatedMsr?: boolean;
  MeasurementInstanceCalculationMaps?: any[];
  calculatedParentIds?: any

  constructor(samplePoint?: contracts.ISamplePointsAndMeasurements) {
      if (samplePoint) {
          this.ContextPointId = samplePoint.ContextPointId;
          this.MeasurementName = samplePoint.MeasurementName;
          this.ContextPointTypePrettyName = samplePoint.ContextPointTypePrettyName;
          this.ParentContextPointId = samplePoint.ParentContextPointId;
          this.OriginType = samplePoint.OriginType;
          this.IsParent = samplePoint.IsParent;
          this.SamplePointName = samplePoint.SamplePointName;
          this.MeasurementInstanceId = samplePoint.MeasurementInstanceId;
      }
  }
}

export class Configurations implements contracts.IConfigurations {
  ConfigName: string;
  ConfigId: string;
  SamplePoints: Array<SamplePoints>;
  Mapping?: any;

  constructor(config?: contracts.IConfigurations) {
    this.ConfigName = config.ConfigName;
    this.ConfigId = config.ConfigId;
    this.SamplePoints = config.SamplePoints;
  }
}

export class SamplePoints implements contracts.ISamplePoints {
  ContextPointId: number;
  ParentConfigId: number;
  Name: string;
  SequenceNumber: number;
  Measurements: Array<Measurements>;

  constructor(samplePoints?: contracts.ISamplePoints) {
    this.ContextPointId = samplePoints.ContextPointId;
    this.ParentConfigId = samplePoints.ParentConfigId;
    this.Name = samplePoints.Name;
    this.SequenceNumber = samplePoints.SequenceNumber;
    this.Measurements = samplePoints.Measurements;
  }
}

export class Measurements implements contracts.IMeasurements {
  MeasurementInstanceId: number;
  Name: string;
  SequenceNumber: number;
  OriginType?: number;
  SamplePointName?: string;

  constructor(measurements?: contracts.IMeasurements) {
    this.MeasurementInstanceId = measurements.MeasurementInstanceId;
    this.Name = measurements.Name;
    this.SequenceNumber = measurements.SequenceNumber;
    this.OriginType = measurements.OriginType;
    this.SamplePointName = measurements.SamplePointName;
  }
}
