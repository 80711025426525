// angular modules packages
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

// custom modules components
import { AppEnvConfig } from '../../app.env.config';
import { DataVisualizationServiceConfig } from './data-visualization.service.config';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DataVisualizationService extends BehaviorSubject<GridDataResult> {

    // declare all the variables
    private configEnv: any;
    public url: string;
    public contextPoint: number;
    public jsonServerUrl: string;
    public dagType: string;
    public checkMaxDags: string;

    // constructor
    constructor(
        private dataVisualizationServiceConfig: DataVisualizationServiceConfig,
        private httpClient: HttpClient,
        private config: AppEnvConfig) {

        super(null);
        this.configEnv = dataVisualizationServiceConfig.getEnvironment();
        this.configEnv.apiServerUrl = config.getEnv('apiServerUrl');
        this.configEnv.jsonServerUrl = config.getEnv('jsonServerUrl');
        this.configEnv.apiServerUrlAuth = config.getEnv('apiServerUrl');
        this.configEnv.contextPoint = 0;
        this.dagType = 'NalcoWater/';
        this.checkMaxDags = '/true/';

    }

    public getsites(dropDownPaging): Observable<any> {
        const url = this.configEnv.apiServerUrl + this.configEnv.apiUrl.getsites;
        const body = JSON.stringify(dropDownPaging);
        return this.httpClient.post(url, body).map(response => {
            try {
                return response;
            } catch (e) {
                return response;
            }
        });
    }

    public getSelectedParameterDetail(paramIds: any): Observable<any> {
        const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getSelectedParameterDetail);

        return this.httpClient.post(url, paramIds).map(response => {
            try {
                return response;
            } catch (e) {
                return response;
            }
        });
    }

    public getParameters(paramTypeIds: number, queryParams?: string): Observable<any> {
        let url = '';
        if(this.configEnv.apiUrl.getParameters === "assets/sample/parameter.json/") {
            url = this.parse(this.configEnv.apiUrl.getParameters);
        } else {
            url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getParameters + paramTypeIds.toString());
        }
        if (queryParams) {
            url += queryParams;
        }
        return this.httpClient.get(url).map(response => {
            try {
                return response;
            } catch (e) {
                return response;
            }
        });
    }

    public getSiteDetails(siteId): Observable<any> {
        const url = this.configEnv.apiServerUrl + this.configEnv.apiUrl.getSiteDetails;
        const body = JSON.stringify(siteId);
        return this.httpClient.post(url, body).map(response => {
            try {
                return response;
            } catch (e) {
                return response;
            }
        });
    }

    public getSiteDetailsWithPaging(siteId, pageIndex, pageSize, searchString): Observable<any> {
        let url = '';
        if (searchString != '')
        { 
            url = this.configEnv.apiServerUrl + this.configEnv.apiUrl.getSiteDetailsWithPaging+ '/'+pageIndex+'/'+ pageSize+ '?searchString='+searchString;
        }
        else
        {
            url = this.configEnv.apiServerUrl + this.configEnv.apiUrl.getSiteDetailsWithPaging+ '/'+pageIndex+'/'+ pageSize;
        }
        const body = JSON.stringify(siteId);
        return this.httpClient.post(url, body).map(response => {
            try {
                return response;
            } catch (e) {
                return response;
            }
        });
    }

    public getSourceSamplePoint(dagId: number): Observable<any> {
        const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getsiteAssetsMapData + dagId.toString());
        return this.httpClient.get(url).map(response => {
            try {
                return response;
            } catch (e) {
                return response;
            }
        });
    }


    public getsystems(dagId: number): Observable<any> {
        const siteCanReadWrite = false;
        const addTags = false;
        const addProperties = false;
        const addMeasurements = true;
        const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getSiteDagChildren,
            [dagId.toString(), siteCanReadWrite.toString(), addTags.toString(),
            addProperties.toString(), addMeasurements.toString()]);
        return this.httpClient.get(url).map(response => {
            try {
                return response;
            } catch (e) {
                return response;
            }
        });
    }

    public getGraphData(graphParam: any): Observable<any> {
        const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getGraphCsvData);

        return this.httpClient.post(url, graphParam).map(response => {
            try {
                return response;
            } catch (e) {
                return response;
            }
        });
    }

    public getSaveFavData(): Observable<any> {
        const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getSaveFavData);

        return this.httpClient.get(url).map(response => {
            try {
                return response;
            } catch (e) {
                return response;
            }
        });
    }

    public setSaveFavData(graphParam: any): Observable<any> {
        const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.setSaveFavData);

        return this.httpClient.put(url, graphParam).map(response => {
            try {
                return response;
            } catch (e) {
                return response;
            }
        });
    }

    public getShareFavorite(userList: any): Observable<any> {
        const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getShareFavorite);
        return this.httpClient.post(url, userList).map(response => {
            try {
                return response;
            } catch (e) {
                return response;
            }
        });
    }

    public getUpsertShareFavorite(userIds:any): Observable<any> {
        const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.upsertShareFavorite);
        return this.httpClient.post(url, userIds).map(response => {
            try {
                return response;
            } catch (e) {
                return response;
            }
        });
    }

    public fetchFavData(favId: string): Observable<any> {
        const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.fetchFavData + favId);

        return this.httpClient.get(url).map(response => {
            try {
                return response;
            } catch (e) {
                return response;
            }
        });
    }

    public getGeneralConfigurations(generalConfigDocName: any): Observable<any> {
        const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getGeneralConfigurations
            + '?documentName=' + generalConfigDocName);
        return this.httpClient.get(url).map(response => {
            try {
                return response;
            } catch (e) {
                return response;
            }
        });
    }

    public setAnnotation(data: any, startOn, endBefore): Observable<any> {
        const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.setAnnotation
            + 'startOn=' + startOn + '&endOn=' + endBefore);

        return this.httpClient.put(url, data).map(response => {
            try {
                return response;
            } catch (e) {
                return response;
            }
        });
    }

    public getCSVData(data): Observable<any>{

        const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getGraphCsvData );
        return this.httpClient.post(url, data).map(response => {
            try {
                return response;
            } catch (e) {
                return response;
            }
        });
    }

    public getAnnotations(data: Array<number> = [], startOn, endBefore): Observable<any> {
        const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getAnnotation
            + 'startOn=' + startOn + '&endOn=' + endBefore);

        return this.httpClient.post(url, data).map(response => {
            try {
                return response;
            } catch (e) {
                return response;
            }
        });
    }


    private parse(url: string, params: string[] = []): string {
        let result: string = url;
        if (params.length > 0) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            result = url.replace(/\{(\d+)\}/g, function (match: string, current: string): string {
                return params[parseInt(current, 10)] !== undefined ? params[parseInt(current, 10)] : '';
            });
        }
        return result;
    }

    _toggleSettingType = new BehaviorSubject<{type: true | false}>(null);

    setToggleSettingType(data: {type: true | false}) {
        this._toggleSettingType.next(data);
      }

    get toggleSettingType() {
        return this._toggleSettingType.asObservable();
      }

}
