<div class="recipient-details-container">
  <div class="modal-header pt-4 pl-4 mb-0 border-0">
    <h3 class="modal-title">
      <span>{{modalText}}</span>
    </h3>
    <button type="button" *ngIf="closeIcon" class="close" aria-label="Close" (click)="closePopupModal()">
      <img src="../../../../assets/images/modal-close-icon.svg" alt="close" />
    </button>
  </div>
  <div class="modal-body table-wrapper">
    <div class="table-responsive table-content">

        <table class="table">
            <thead>
                <tr>
                    <th scope="col">
                      <p class="recipient-details" translate>MY_DOCUMENTS.RECIPIENT_POPUP.NAME</p>
                    </th>
                    <th scope="col">
                      <p class="recipient-details" translate>MY_DOCUMENTS.RECIPIENT_POPUP.EMAILADDRESS</p>
                  </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of recipients; let i = index">
                  <td class="recipient-name">
                      {{ item.RecipientName }}
                  </td>
                  <td class="recipient-emailaddress">
                    {{ item.DeliveryAddress }}
                  </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
</div>
  
