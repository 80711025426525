import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateParser } from '@ngx-translate/core';
import { TranslateICUParser } from '@pawelalikowski/ngx-translate-parser-plural-select';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LoaderComponent } from './components/loader/loader.component';
import { HumbergurMenuComponent } from './components/navigation/humbergur-menu/humbergur-menu.component';
import { LeftMenuItemComponent } from './components/navigation/left-menu-item/left-menu-item.component';
import { LeftMenuSubmenuItemComponent } from './components/navigation/left-menu-submenu-item/left-menu-submenu-item.component';
import { LeftMenuComponent } from './components/navigation/left-menu/left-menu.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { SubHeaderLeftSectionComponent } from './components/sub-header/left-section/left-section.component';
import { SubHeaderMiddleSectionComponent } from './components/sub-header/middle-section/middle-section.component';
import { SubHeaderRightSectionComponent } from './components/sub-header/right-section/right-section.component';
import { SubHeaderComponent } from './components/sub-header/sub-header.component';
import { LooperService } from './services/looper.service';
import { SharedMainService } from './services/shared-main.service';
import { SharedMainConfig } from './services/shared-main.service.config';
// for new breadcrumb
 import { BreadcrumbModule } from '../shared-ui-refresh/breadcrumb/breadcrumb.module';
//import { BreadcrumbModule } from '../breadcrumb/breadcrumb.module';
import { NotificationModule } from '../notification/notification.module';
import { MenuModule } from '../menu/menu.module';
import { MatIconModule } from '@angular/material/icon';
import { GlobalSearchDropdownComponent as DROPDOWN} from '../dropdown/globalsearchdropdown.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input'
import { SearchModule } from '../search/globalsearch.component.module';
@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    RouterModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule.forChild({
      parser: {
        provide: TranslateParser,
        useClass: TranslateICUParser
      }
    }),
    // for new breadcrumb
    BreadcrumbModule,
    // the following breadcrumb module will be removed in future, once the new breadcrumb module with new UX is available.
   // BreadcrumbModule,
    NotificationModule,
    MenuModule,
    SearchModule,


  ],
  declarations: [
    FooterComponent,
    HeaderComponent,
    LoaderComponent,
    NavigationComponent,
    LeftMenuSubmenuItemComponent,
    LeftMenuItemComponent,
    LeftMenuComponent,
    HumbergurMenuComponent,
    SubHeaderComponent,
    SubHeaderRightSectionComponent,
    SubHeaderMiddleSectionComponent,
    SubHeaderLeftSectionComponent,
    DropdownComponent,
    DROPDOWN
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    LoaderComponent,
    NavigationComponent,
    LeftMenuSubmenuItemComponent,
    LeftMenuItemComponent,
    LeftMenuComponent,
    HumbergurMenuComponent,
    SubHeaderComponent,
    SubHeaderRightSectionComponent,
    SubHeaderMiddleSectionComponent,
    SubHeaderLeftSectionComponent,
    DropdownComponent,
    DROPDOWN    
  ],
  providers: [LooperService, SharedMainConfig, SharedMainService,]
})
export class SharedMainModule {
  private static count = 0;
  static forRoot(): ModuleWithProviders<SharedMainModule> {
    return {
      ngModule: SharedMainModule,
      providers: [],
    };
  }
}
