import { Component, OnInit, EventEmitter, Output, ViewEncapsulation, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { SharedOmniService } from '../../../shared-omni/services/shared-omni.service';
import { AssetDataStoreService } from '../../../asset-tree/_services/asset-tree-app-data.service';
import { Subscription } from 'rxjs';
import { LocalCacheService } from '../../../core/local-cache/local-cache.service';

@Component({
  selector: 'nalco-maximize-dialog',
  templateUrl: './maximize-dialog.component.html',
  styleUrls: ['./maximize-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MaximizeDialogComponent implements OnInit,OnDestroy {

  @Output('cancelModal') cancelModal = new EventEmitter<any>();
  getDiagnosticsDetail: any;
  getFilterSelected: any;
  loading: any;
  disablebtn: any;
  activeAlarms: Array<any> = [];
  historicalAlarms: Array<any> = [];
  pills: Array<any> = [
    { id: 1, name: this.translate.instant('OMNI.PERFORMANCE.ACTIVE') },
    { id: 2, name: this.translate.instant('OMNI.PERFORMANCE.HISTORICAL') }
  ];
  diagnosticPills = this.pills;
  defaultPrimaryValue = [1];
  subscription: Subscription;
  dropdownSelected: any;

  constructor(public activeModal: NgbActiveModal,
    private storeAssetData: AssetDataStoreService,
    private translate: TranslateService,
    private sharedOmniService: SharedOmniService,
    private localCacheService: LocalCacheService,) { }

  ngOnInit() {
    this.getDiagnosticsDetail = this.storeAssetData.get('DiagnosticDetails');
    this.getFilterSelected = this.storeAssetData.get('DiagnosticFilter');
    this.filterData();
    this.dropdownSelected = this.localCacheService.fetchData('serviceType');
    this.subscription = this.sharedOmniService.filterPillChangeEvnt.subscribe(x => {
      if(x !== null && x.response) {
        this.getDiagnosticsDetail = this.storeAssetData.get('DiagnosticDetails');
        this.getFilterSelected = this.storeAssetData.get('DiagnosticFilter');
        this.filterData();
      }
    })
  }

  filterData() {
    this.activeAlarms = [];
    this.historicalAlarms = [];
    if (this.getDiagnosticsDetail.ActiveAlarms !== null) {
      this.getDiagnosticsDetail.ActiveAlarms.forEach(item => {
        const activeTable = {
          AlarmDiscription: item.AlarmDiscription,
          OnDateTime: item.OnDate + ' ' + item.OnTime
        }
        this.activeAlarms.push(activeTable);
      });
    }
    if (this.getDiagnosticsDetail.HistoricalAlarms !== null) {
    this.getDiagnosticsDetail.HistoricalAlarms.forEach(item => {
      const historicalTable = {
        AlarmDiscription: item.AlarmDiscription,
        OnDateTime: item.OnDate + ' ' + item.OnTime,
        OffDateTime: item.OffDate + ' ' + item.OffTime
      }
      this.historicalAlarms.push(historicalTable);
    });
  }
  this.loading = false;
  }

  diagnosticPillChanged($event) {
    this.getFilterSelected = $event.primary[0];
    let hasRecords = $event.primary[0] == 1 ? this.getDiagnosticsDetail.ActiveAlarms : this.getDiagnosticsDetail.HistoricalAlarms;
    if(this.dropdownSelected > -1 && hasRecords == null) {
      this.loading = true;
      let obj = {};
      obj['filterChanged'] = $event.primary[0];
      obj['response'] = false;
      this.sharedOmniService.changeFilterPill(obj);
    }  
  }

  cancelAddEditModal() {
    this.cancelModal.emit({});
    this.activeModal.close();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
