import { Injectable } from '@angular/core';
import { ENVIRONMENTS, APP_CONFIG } from '../../app.config';

@Injectable()
export class AssetDetailsServiceConfig {

    private configMock: any = { // MOCK - local json settings
        environment: ENVIRONMENTS.mock,
        baseUrl: '',
        apiUrl: {
            getSystemOverallCount: 'api/v1/WaterQualityV1Controller/CategoryOverallCount', //'SystemOverallCount',
            getSitePerformanceList: 'api/v1/WaterQualityV1Controller/CategoryOverallSitePerformance', //'OverallSitePerformance',
            getActionLogMetrics: 'api/v1/Recommendations/SiteRecommendationCounts',
            getSiteSystemPerformances: 'api/v1/WaterQualityV1Controller/SiteSystemPerformancesByServiceCategory',// 'SiteSystemPerformances',
            getParameterTrendChart: 'api/v1/WaterQualityController/SiteTrend',
            getSiteSystemAssetMeasurements: 'api/v1/WaterQualityV1Controller/SiteSystemAssetMeasurementsByServiceCategory',
            getCoordinatePhosphateChart: 'api/v1/WaterQualityController/WaterQualityScatterData',
            serviceEnabled: 'api/v1/ServiceSummaryDashboard/GetServiceTypeIdByContextPointId',
            getAssetSystemGroupCollection: '/api/v1/Sites/{0}/{1}/SiteDagChildren/{2}',
            getSpecificSite: 'ContextPoint/{0}',
            getIsBuckets: 'api/v1/Sites/AssetDetailsSettings',
            getChartTemplates:"api/v1/DataVisualization/DataVizChartTemplates/{0}/{1}"

        }
    };
    
    private configProd: any = { // PRODUCTION - PRODUCTION ENVIRONMENT SETTINGS
        environment: ENVIRONMENTS.prod,
        baseUrl: '',
        apiUrl: {
            getSystemOverallCount: 'api/v1/WaterQualityV1Controller/CategoryOverallCount', //'SystemOverallCount',
            getSitePerformanceList: 'api/v1/WaterQualityV1Controller/CategoryOverallSitePerformance', //'OverallSitePerformance',
            getActionLogMetrics: 'api/v1/Recommendations/SiteRecommendationCounts',
            getSiteSystemPerformances: 'api/v1/WaterQualityV1Controller/SiteSystemPerformancesByServiceCategory',// 'SiteSystemPerformances',
            getParameterTrendChart: 'api/v1/WaterQualityController/SiteTrend',
            getSiteSystemAssetMeasurements: 'api/v1/WaterQualityV1Controller/SiteSystemAssetMeasurementsByServiceCategory',
            getCoordinatePhosphateChart: 'api/v1/WaterQualityController/WaterQualityScatterData',
            serviceEnabled: 'api/v1/ServiceSummaryDashboard/GetServiceTypeIdByContextPointId',
            getAssetSystemGroupCollection: '/api/v1/Sites/{0}/{1}/SiteDagChildren/{2}',
            getSpecificSite: 'ContextPoint/{0}',
            getIsBuckets: 'api/v1/Sites/AssetDetailsSettings',
            getChartTemplates:"api/v1/DataVisualization/DataVizChartTemplates/{0}/{1}"
        }
    };

    private _environment: string = APP_CONFIG.ENVIRONMENT;

    constructor() {
        // void
    }
    public getEnvironment(): any {
        switch (this._environment) {
            case ENVIRONMENTS.mock:
                return this.configMock;
            case ENVIRONMENTS.dev:
                return this.configProd;
            case ENVIRONMENTS.test:
                return this.configProd;
            case ENVIRONMENTS.stg:
                return this.configProd;
            case ENVIRONMENTS.prod:
                return this.configProd;
            default:
                return null;
        }
    }
    public setEnvironment(environment: string): any {
        this._environment = environment;
    }

}
