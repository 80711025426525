<div class="modal-header pt-4 pl-4 mb-0 border-0">
  <h3 class="modal-title">
    <span [ngClass]=" (alertIcon == true || alertIcon == undefined) ? 'iconwater-alert-yellow' : ''"></span>
    <span>{{modalText}}</span>
  </h3>
  <button type="button" *ngIf="closeIcon" class="close" aria-label="Close" (click)="disablebtn? (closeAsCancel ? cancelAddEditModal() : activeModal.close()): closeAddEditModal();">
    <img src="../../../../assets/images/modal-close-icon.svg" alt="close" />
  </button>
</div>
<p class="revert mb-0" [innerHtml]="modcontent"></p>
<div class="modal-footer mt-0">
  <button type="button" class="btn btn-secondary" [ngClass]="[localDriverBtn ===true  ? 'display-none'  : 'display-block']" (click)="cancelAddEditModal()">{{disablebtn == true ? ('COMMON.CANCEL'| translate) : ('COMMON.CLOSE'| translate)}}</button>
  <button type="button" class="btn btn-primary" (click)="submitAddEditModal()">{{actionBtn? actionBtn : ('COMMON.OK' | translate)}}</button>
</div>
