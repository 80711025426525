import { IProjectDetails, IProjectSite } from '../contracts/_contracts';
import { ProjectFinancials } from './ProjectFinancials';

export class ProjectDetails implements IProjectDetails {
    CorporateCpId: 0;
    CorporateCpName: ' ';
    SiteId: 0;
    SiteName: ' ';
    DatasourceContextPointId: 0;
    ProjectName: ' ';
    ProjectStage: ' ';
    StageDays: ' ';
    ProjectId: ' ';
    AccountNumber: ' ';
    MdmNumber: ' ';
    City: ' ';
    StateProvince: ' ';
    Country: ' ';
    SignOffDate: ' ';
    ProjectTitle: ' ';
    Deliverable: ' ';
    AccountName: ' ';
    Currency: ' ';
    TypeFrom: ' ';
    RepFirstName: ' ';
    RepLastName: ' ';
    RepEmail: ' ';
    ResCustomerFirstName: ' ';
    ResCustomerLastName: ' ';
    ResCustomerEmail: ' ';
    CorporateAccountCode: ' ';
    CorporateAccountName: ' ';
    ProjectType: ' ';
    CurrentStatus: ' ';
    ProjectBenefits: ' ';
    ProjectIdentifiedDate: ' ';
    CustomerApprovalDate: ' ';
    PlannedStartDate: ' ';
    ActualStartDate: ' ';
    PlannedImplementationDate: ' ';
    ActualImplementationDate: ' ';
    PaybackPeriod: 0;
    RoiPercent: 0;
    CompletedActivity: ' ';
    PlannedActivity: ' ';
    ResourcesNeeded: ' ';
    CommentsLastModified: ' ';
    CreatedOn: ' ';
    CreatedBy: ' ';
    ModifiedOn: ' ';
    ModifiedBy: ' ';
    ProjectFinancials?: Array<ProjectFinancials> = [];
    Sites: Array<ProjectSite> = [];
    constructor(projectDetails?: ProjectDetails) {
        if (projectDetails) {
            this.CorporateCpId = projectDetails.CorporateCpId;
            this.CorporateCpName = projectDetails.CorporateCpName;
            this.SiteId = projectDetails.SiteId;
            this.SiteName = projectDetails.SiteName;
            this.DatasourceContextPointId = projectDetails.DatasourceContextPointId;
            this.ProjectName = projectDetails.ProjectName;
            this.ProjectStage = projectDetails.ProjectStage;
            this.ProjectId = projectDetails.ProjectId;
            this.StageDays = projectDetails.StageDays;
            this.AccountNumber = projectDetails.AccountNumber;
            this.MdmNumber = projectDetails.MdmNumber;
            this.City = projectDetails.City;
            this.StateProvince = projectDetails.StateProvince;
            this.Country = projectDetails.Country;
            this.SignOffDate = projectDetails.SignOffDate;
            this.ProjectTitle = projectDetails.ProjectTitle;
            this.Deliverable = projectDetails.Deliverable;
            this.AccountName = projectDetails.AccountName;
            this.Currency = projectDetails.Currency;
            this.TypeFrom = projectDetails.TypeFrom;
            this.RepFirstName = projectDetails.RepFirstName;
            this.RepLastName = projectDetails.RepLastName;
            this.RepEmail = projectDetails.RepEmail;
            this.ResCustomerFirstName = projectDetails.ResCustomerFirstName;
            this.ResCustomerLastName = projectDetails.ResCustomerLastName;
            this.ResCustomerEmail = projectDetails.ResCustomerEmail;
            this.CorporateAccountCode = projectDetails.CorporateAccountCode;
            this.CorporateAccountName = projectDetails.CorporateAccountName;
            this.ProjectType = projectDetails.ProjectType;
            this.CurrentStatus = projectDetails.CurrentStatus;
            this.ProjectBenefits = projectDetails.ProjectBenefits;
            this.ProjectIdentifiedDate = projectDetails.ProjectIdentifiedDate;
            this.CustomerApprovalDate = projectDetails.CustomerApprovalDate;
            this.PlannedStartDate = projectDetails.PlannedStartDate;
            this.ActualStartDate = projectDetails.ActualStartDate;
            this.PlannedImplementationDate = projectDetails.PlannedImplementationDate;
            this.ActualImplementationDate = projectDetails.ActualImplementationDate;
            this.PaybackPeriod = projectDetails.PaybackPeriod;
            this.RoiPercent = projectDetails.RoiPercent;
            this.CompletedActivity = projectDetails.CompletedActivity;
            this.PlannedActivity = projectDetails.PlannedActivity;
            this.ResourcesNeeded = projectDetails.ResourcesNeeded;
            this.CommentsLastModified = projectDetails.CommentsLastModified;
            this.CreatedOn = projectDetails.CreatedOn;
            this.CreatedBy = projectDetails.CreatedBy;
            this.ModifiedOn = projectDetails.ModifiedOn;
            this.ModifiedBy = projectDetails.ModifiedBy;
            if (projectDetails.ProjectFinancials) {
                projectDetails.ProjectFinancials.forEach(projectfinancial => {
                    this.ProjectFinancials.push(new ProjectFinancials(projectfinancial));
                });
            }
            if (projectDetails.Sites) {
                projectDetails.Sites.forEach(projectSite => {
                    this.Sites.push(new ProjectSite(projectSite));
                });
            }
        }
    }
}


export class ProjectSite implements IProjectSite {
    ContextPointId: 0;
    DagId: 0;
    DagName: '';
    SiteName: ' ';
    City: ' ';
    StateProvince: ' ';
    Street: ' ';
    CustomerLocation: ' ';
    ContextPointName: '';
    Country: '';
    CountryCodeIso3: '';
    CorpAccountId: '';
    CorpAccountName: '';
    Message: '';
    SiteNameEnglish: '';
    FriendlyName: '';
    PostalCode: '';
    Latitude: 0;
    Longitude: 0;
    ModifiedBy: 0;
    ModifiedOn: '';
    CdmSiteNo: '';
    Elevation: '';
    CoordinateType: '';
    CoordinateModifiedOn: '';


    // SoldToShipToCpId: 0;
    // SoldToShipTo: ' ';

    constructor(sites?: ProjectSite) {
        if (sites) {
            this.ContextPointId = sites.ContextPointId;
            this.DagId = sites.DagId;
            this.DagName = sites.DagName;
            this.SiteName = sites.SiteName;
            this.City = sites.City;
            this.StateProvince = sites.StateProvince;
            this.Street = sites.Street;
            this.CustomerLocation = sites.CustomerLocation;
            this.ContextPointName = sites.ContextPointName;
            this.Country = sites.Country;
            this.CountryCodeIso3 = sites.CountryCodeIso3;
            this.CorpAccountId = sites.CorpAccountId;
            this.CorpAccountName =  sites.CorpAccountName;
            this.Message = sites.Message;
            this.SiteNameEnglish = sites.SiteNameEnglish;
            this.FriendlyName = sites.FriendlyName;
            this.PostalCode = sites.PostalCode;
            this.Latitude = sites.Latitude;
            this.Longitude = sites.Longitude;
            this.ModifiedBy = sites.ModifiedBy;
            this.ModifiedOn = sites.ModifiedOn;
            this.CdmSiteNo = sites.CdmSiteNo;
            this.Elevation = sites.Elevation;
            this.CoordinateType = sites.CoordinateType;
            this.CoordinateModifiedOn = sites.CoordinateModifiedOn;
            // this.SoldToShipToCpId = sites.SoldToShipToCpId;
            // this.SoldToShipTo = sites.SoldToShipTo;
        }
    }
}

