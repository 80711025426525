<!-- this is the temproray fix for the sub header for time being  -->
<header class="sub-header" [ngClass]="{ 'fixed-position': !isHomePage }">
  <div class="row container-fluid main-content-collapse-view" [ngClass]="isMobile && isHomePage ? 'mobile-home-page' : isMobile? 'main-content-collapse-view' : isHomePage? '' : isExpand? 'main-content-expand' : 'main-content-collapse'"
  [ngClass]="(leftSection?.headerClassName) ? (leftSection?.headerClassName) : 'white-header-background'">
    <!-- [ngClass]="isMobile? 'main-content-collapse-view' : isExpand? 'main-content-expand' : 'main-content-collapse'" -->
    <nalco-subheader-left-section [ngClass]='leftSection?.className' (btnClick)="btnClicked($event);" [details]="leftSection"></nalco-subheader-left-section>
    <nalco-subheader-middle-section [ngClass]='middleSection?.className' (btnClick)="btnClicked($event);" [details]="middleSection"></nalco-subheader-middle-section>
    <nalco-subheader-right-section [ngClass]='rightSection?.className' (ddChange)="ddChange($event)"  (btnClick)="btnClicked($event);"
      [details]="rightSection"></nalco-subheader-right-section>
      <!-- <h3 style="position: absolute; right: 8%;">
        <iframe  src='../../../../assets/Training Videos/Asset_Tree_Overview/launch.html'  [width]="320" height="568" >Training videos</iframe>
      </h3> -->
  </div>

</header>
