<li class="nav-item slds-nav-vertical__item nav-item-container cPRTL_SideNav_MenuItem_TopLevel Home d-sm-none d-md-block"
    id="Home" tabindex="0">
    <!-- <ng-template #tooltipContent>
                <p class="nav-tooltip-content" translate>COMMON.HOME</p>
              </ng-template> -->
    <a class="slds-nav-vertical__action nav-link text-center" placement="right" routerLinkactive="active" routerLink="/home"
        placement="right" >
        <span class="slds-icon_container slds-line-height_reset icon-icon_home"></span>
    </a>
</li>

<h3 class="float-left" *ngIf="details?.type=='title'">
    {{details?.data}}
</h3>

<!-- <nalco-breadcrumb *ngIf="details?.type == 'breadcrumb'"></nalco-breadcrumb> -->
<nalco-breadcrumb *ngIf="details?.type=='breadcrumb'" [data]="details.data"></nalco-breadcrumb>
<span class="float-left ml-2" *ngIf="details?.type == 'button'">
    <a [ngClass]="details?.data[0]?.class" (click)="btnClickInSubhead(details?.data[0]?.value)">{{details?.data[0]?.text}}</a>
</span>