import * as contracts from '../_contracts/IInventory';


export class Calibration implements contracts.ICalibration{
    cpId?: number;
    source?: string;
    ContextType?: string = '';
    measureId?: number;
    instance?: number;
    value?: number;
    valueText?: string = '';
    measureDate?: string = '';
    earliestDate?: string = '';
    stuiTypeId?: number;
    stuiId?: number;
    dagId?: number;
    siteId?: number;
    assetId?: number;
    corporateAccountId?: number;
    dataSource?: string = '';
    contextSubType?: string = '';
    contextParentType?: string = '';
    contextParentSubType?: string = '';
    dataSourceId?: number;
    IsScaled?: boolean;
    AdditionalData?: AdditionalData = new AdditionalData();
    constructor(calb?: contracts.ICalibration){
        Object.assign(this, calb);
    }
}
export class AdditionalData implements contracts.IAdditionalData{
    IncomingValue?: string = '';
    IncomingUnit?: string = '';
    IncomingAnalysis?: string = '';
    IncomingUnitAbbreviation?: string = '';
    TestNumber?: string = '';
    Qualifier?: string = '';
    constructor(ad?: contracts.IAdditionalData){
        Object.assign(this, ad);
    }
}