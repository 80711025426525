export const DonutChartConfig = {
  verticalAlign: 'middle',
  floating: true,
  align: 'center',
  credits: {
    enabled: false
  },
  exporting: {
    enabled: false
  },
  plotOptions: {
    pie: {
      shadow: false,
      borderWidth: 0, // overriding to remove the white border after every portion. defaul is 1
      size: 700,
      dataLabels: {
        enabled: true,
        distance: -50,
        style: {
          fontWeight: 'bold',
          color: 'white',
          textShadow: '0px 1px 2px black'
        }
      }
    }
  },
  startAngle: 0,
  greyColor: '#e5e8e7',
  states: {
    hover: {
      shadow: false,
      enabled: false
    }
  },
  size: '100%',
  showInLegend: false,
  dataLabels: {
    enabled: false
  },
  perShapeGradient: {
    x1: 0,
    y1: 0,
    x2: 1,
    y2: 0
  }
};
