export class EroiCategory {
    name: string;
    nameTranslated: string;
    type: string;
    value: string;
    typeName: string;
    iconUrl: string;
    iconUrlWithoutExtension: string;

    constructor(name: string, nameTranslated: string, type: string, value: string, iconUrl: string, typeName: string, iconUrlWithoutExtension: string) {
        this.name = name;
        this.nameTranslated = nameTranslated;
        this.type = type;
        this.value = value;
        this.iconUrl = iconUrl;
        this.typeName = typeName
        this.iconUrlWithoutExtension = iconUrlWithoutExtension;
    }
}