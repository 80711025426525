import { AssetLimits } from '../../shared-limit-config/_models/AssetLimits';
import { OfflineConfiguration, OperationalSchedule } from '../../shared-operating-schedule/_models/OperationalSchedule';
import { TimeZoneCdm } from "../../shared/models/Asset";
import * as contracts from '../_contracts/IAsset';

export class AssetCollection implements contracts.IAssetCollection {
    DagContextPointId?: number = 0;
    SiteName?: string = '';
    Assets?: Array<Asset> = [];
    Systems?: Array<Asset> = [];
    SystemProperties?: Array<SystemPropertyCollection> = [];
    DataSources?: Array<DataSource> = [];
    MeasurementContextPointMaps?: Array<MeasurementContextPointMap> = [];
    constructor(asset?: contracts.IAssetCollection) {
        if (asset) {
            this.DagContextPointId = asset.DagContextPointId;
            this.SiteName = asset.SiteName;
            if (asset.Assets && asset.Assets.length) {
                asset.Assets.forEach(asset => {
                    this.Assets.push(new Asset(asset));
                });
            }
            this.SiteName = asset.SiteName;
            if (asset.SystemProperties && asset.SystemProperties.length) {
                asset.SystemProperties.forEach(sysproperty => {
                    this.SystemProperties.push(new SystemPropertyCollection(sysproperty));
                });
            }
            if (asset.Systems && asset.Systems.length) {
                asset.Systems.forEach(system => {
                    this.Systems.push(new Asset(system));
                });
            }
            this.DataSources = asset.DataSources ? asset.DataSources : [];
            this.MeasurementContextPointMaps = asset.MeasurementContextPointMaps ? asset.MeasurementContextPointMaps : [];
        }
    }
}

export class Asset implements contracts.IAsset {
    DagContextPoint?: TreeContextPoint = new TreeContextPoint();
    ContextPointIdParent?: number = 0;
    ContextPointIdentifier?: string = '';
    ContextPointIdentifierParent?: string = '';
    OrderInTree?: number = 0;
    IsDeleted?: boolean;
    CanReadWrite?: boolean;
    IsMapped?: boolean;
    MeasurementInstanceIds?: Array<number> = [];
    ContextPointSourceType?: string = '';
    ContextPointSourceTypePrettyName?: string = '';
    CreatedOn?: string = '';
    RetiredOn?: string = '';
    ModifiedOn?: string = '';
    ModifiedBy?: number = 0;
    Source?: string = '';
    SourceSystemCode?: string = '';
    DataIntgExecId?: number = 0;
    DagId?: number = 0;
    ContextPointId?: number = 0;
    ContextPointName?: string = '';
    ContextPointStatusType?: number = 0;
    ContextPointStatusTypePrettyName?: string = '';
    ContextPointType?: number = 0;
    ContextPointTypePrettyName?: string = '';
    ContextPointSubTypeId?: number = 0;
    ContextPointSubTypeName?: string = '';
    Children?: Array<ContextPoint> = [];
    DatasourceMeasurementMaps?: Array<DatasourceMeasurementMap> = [];
    MeasurementInstances?: Array<MeasurementInstance> = [];
    AreaContextPoint?: TreeContextPoint = new TreeContextPoint();
    SystemContextPoint?: TreeContextPoint = new TreeContextPoint();
    Ports?: Array<TreeContextPoint> = [];
    MeasurementPoints?: Array<TreeContextPoint> = [];
    SubAssets?: Array<Asset> = [];
    AssetProperties?: AssetProperty = new AssetProperty();
    CategoryId?: number = 0;
    CategoryName?: string = '';
    SubCategoryId?: number = 0;
    SubCategoryName?: string = '';
    AssetLimitAlignmentStatusColor: string = '';
    // Inventory
    AssetConfig?: AssetConfig = new AssetConfig();

    //computed at UI
    SubAssetsNames?: Array<any> = [];
    contextPointList: Array<number> = [];
    wqAssetTypeFlag: boolean;
    allowUnmapDatasource?: boolean;

    // Asset By category
    AssetCpId?: number = 0;
    AssetType?: string = '';
    AssetStatusTypeId?: number = 0;
    AssetName?: string = '';
    AreaTypeId?: number = 0;
    AreaType?: string = '';
    AreaTypeName?: string = '';
    SystemId?: number = 0;
    SystemName?: string = '';
    IsAssetMapped?: boolean = false;
    PortCpId?: number = 0;
    PortName?: string = '';
    PortTypeId?: number = 0;
    PortSubTypeId?: number = 0;
    MeasurementPointCpId?: number = 0;
    MeasurementPointName?: string = '';
    MeasurementPointTypeId?: number = 0;
    MeasurementPointSubTypeId?: number = 0;
    MeasurementPointSubTypeName?: string = '';
    AssetSubTypeId?: number = 0;
    AssetSubTypeName?: string = '';
    DatasourceCpId?: number = 0;
    DatasourceName?: string = '';
    DatasourceTypeId?: number = 0;
    DatasourceTypeName?: string = '';
    DatasourceSubTypeId?: number = 0;
    DatasourceSubTypeName?: string = '';
    Measurements?: string = '';
    MeasurementCount?: number = 0;
    IsVisibleInHarvester?: boolean;
    IsWaterWatchCreatedAsset?: boolean = false;

    //System Details grid
    DagDescendantCpId?: number = 0;
    DagDescendantName?: string = '';
    DagDescendantTypeId?: number = 0;
    DagDescendantSubTypeId?: number = 0;
    DagDescendantSubTypeName?: string = '';
    GroupCpId?: number = 0;
    GroupName?: string = '';
    IsLast6MonthActive?: number = 0;
    MeasurementCreatedOn?: string;
    SourceApplication?: number;
    OriginType?: number;

    constructor(a?: contracts.IAsset) {
        if (a) {
            this.DagContextPoint = a.DagContextPoint;
            this.ContextPointIdParent = a.ContextPointIdParent;
            this.ContextPointIdentifier = a.ContextPointIdentifier;
            this.ContextPointIdentifierParent = a.ContextPointIdentifierParent;
            this.OrderInTree = a.OrderInTree;
            this.IsDeleted = a.IsDeleted;
            this.CanReadWrite = a.CanReadWrite;
            this.IsMapped = a.IsMapped;
            this.ContextPointSourceType = a.ContextPointSourceType;
            this.ContextPointSourceTypePrettyName = a.ContextPointSourceTypePrettyName;
            this.CreatedOn = a.CreatedOn;
            this.RetiredOn = a.RetiredOn;
            this.ModifiedOn = a.ModifiedOn;
            this.ModifiedBy = a.ModifiedBy;
            this.Source = a.Source;
            this.SourceSystemCode = a.SourceSystemCode;
            this.DataIntgExecId = a.DataIntgExecId;
            this.DagId = a.DagId;
            this.ContextPointId = a.ContextPointId;
            this.ContextPointName = a.ContextPointName;
            this.ContextPointStatusType = a.ContextPointStatusType;
            this.ContextPointStatusTypePrettyName = a.ContextPointStatusTypePrettyName;
            this.ContextPointType = a.ContextPointType;
            this.ContextPointTypePrettyName = a.ContextPointTypePrettyName;
            this.ContextPointSubTypeId = a.ContextPointSubTypeId;
            this.ContextPointSubTypeName = a.ContextPointSubTypeName;
            this.AssetLimitAlignmentStatusColor = a.AssetLimitAlignmentStatusColor;
            this.contextPointList.push(a.ContextPointId);
            this.allowUnmapDatasource = a.allowUnmapDatasource;
            this.MeasurementCreatedOn = a.MeasurementCreatedOn;
            if (a.MeasurementInstanceIds && a.MeasurementInstanceIds.length) {
                let an = [];
                a.MeasurementInstanceIds.forEach(e => {
                    an.push(e);
                });
                this.MeasurementInstanceIds = an;
            }
            this.AreaContextPoint = new TreeContextPoint(a.AreaContextPoint);
            this.SystemContextPoint = new TreeContextPoint(a.SystemContextPoint);
            this.AssetProperties = new AssetProperty(a.AssetProperties);
            //inventory
            this.AssetConfig = new AssetConfig(a.AssetConfig);
            if (a.SubAssets && a.SubAssets.length) {
                let temp = []
                a.SubAssets.forEach(sa => {
                    this.SubAssets.push(new Asset(sa));
                    temp.push(sa.ContextPointName);
                });
                this.SubAssetsNames = temp;
            }
            if (a.Ports && a.Ports.length) {
                a.Ports.forEach(p => {
                    this.contextPointList.push(p.ContextPointId);
                    this.Ports.push(new TreeContextPoint(p));
                });
            }
            if (a.MeasurementPoints && a.MeasurementPoints.length) {
                a.MeasurementPoints.forEach(p => {
                    this.contextPointList.push(p.ContextPointId);
                    this.MeasurementPoints.push(new TreeContextPoint(p));
                });
            }

            if (a.DatasourceMeasurementMaps && a.DatasourceMeasurementMaps.length) {
                a.DatasourceMeasurementMaps.forEach(dsmm => {
                    this.DatasourceMeasurementMaps.push(new DatasourceMeasurementMap(dsmm));
                });
            }
            if (a.Children && a.Children.length) {
                a.Children.forEach(c => {
                    this.Children.push(new ContextPoint(c));
                });
            }
            if (a.MeasurementInstances && a.MeasurementInstances.length) {
                a.MeasurementInstances.forEach(mi => {
                    this.MeasurementInstances.push(new MeasurementInstance(mi));
                });
            }
            this.CategoryId = a.CategoryId;
            this.CategoryName = a.CategoryName;
            this.SubCategoryId = a.SubCategoryId;
            this.SubCategoryName = a.SubCategoryName;

            //Asset by category
            this.AssetCpId = a.AssetCpId;
            this.AssetName = a.AssetName;
            this.AssetStatusTypeId = a.AssetStatusTypeId;
            this.AreaTypeId = a.GroupId;
            this.AreaType = a.GroupName;
            this.AreaTypeName = a.AreaTypeName;
            this.SystemId = a.SystemId;
            this.SystemName = a.SystemName;
            this.PortCpId = a.PortCpId;
            this.PortName = a.PortName;
            this.PortTypeId = a.PortTypeId;
            this.PortSubTypeId = a.PortSubTypeId;
            this.MeasurementPointCpId = a.MeasurementPointCpId;
            this.MeasurementPointName = a.MeasurementPointName;
            this.MeasurementPointTypeId = a.MeasurementPointTypeId;
            this.MeasurementPointSubTypeId = a.MeasurementPointSubTypeId;
            this.MeasurementPointSubTypeName = a.MeasurementPointSubTypeName;
            this.IsAssetMapped = a.IsAssetMapped;
            this.AssetSubTypeId = a.AssetSubTypeId
            this.AssetSubTypeName = a.AssetSubTypeName
            this.DatasourceCpId = a.DatasourceCpId;
            this.DatasourceName = a.DatasourceName;
            this.DatasourceTypeId = a.DatasourceTypeId;
            this.DatasourceTypeName = a.DatasourceTypeName;
            this.DatasourceSubTypeId = a.DatasourceSubTypeId;
            this.DatasourceSubTypeName = a.DatasourceSubTypeName;
            this.Measurements = a.Measurements;
            this.MeasurementCount = a.MeasurementCount;
            this.IsVisibleInHarvester = a.IsVisibleInHarvester;
            this.IsWaterWatchCreatedAsset = a.IsWaterWatchCreatedAsset;
            this.SourceApplication = a.SourceApplication;
            this.OriginType = a.OriginType;

            //System Details
            this.DagDescendantCpId = a.DagDescendantCpId;
            this.DagDescendantName = a.DagDescendantName;
            this.DagDescendantTypeId = a.DagDescendantTypeId;
            this.DagDescendantSubTypeId = a.DagDescendantSubTypeId;
            this.DagDescendantSubTypeName = a.DagDescendantSubTypeName;
            this.GroupCpId = a.GroupCpId;
            this.GroupName = a.GroupName;
            this.IsLast6MonthActive = a.IsLast6MonthActive && a.IsLast6MonthActive === 1 ? 1 : 0;
        }
    }
}

export class SystemPropertyCollection implements contracts.ISystemPropertyCollection {
    Id?: string = '';
    ContextPointId?: number = 0;
    ContextPointName?: string = '';
    ModifiedOn?: string = '';
    ModifiedBy?: number = 0;
    SystemPropertyValues?: any = {};
    constructor(sp?: contracts.ISystemPropertyCollection) {
        if (sp) {
            this.Id = sp.Id;
            this.ContextPointId = sp.ContextPointId;
            this.ContextPointName = sp.ContextPointName;
            this.ModifiedOn = sp.ModifiedOn;
            this.ModifiedBy = sp.ModifiedBy;
            this.SystemPropertyValues = sp.SystemPropertyValues;
        }
    }
}
export class MeasurementContextPointMap implements contracts.IMeasurementContextPointMap {
    FromMeasurementInstance?: MeasurementInstanceLite = new MeasurementInstanceLite();
    ToMeasurementInstance?: MeasurementInstanceLite = new MeasurementInstanceLite();
    MeasurementMapAdditionalInfo?: MeasurementMapAdditionalInfo = new MeasurementMapAdditionalInfo();
    MeasurementMappingDeleteType?: string = '';
    IsHavingMeasurementValue?: boolean;
    ModelNumber?: string = '';
    FromMeasuremntInstanceId?: number = 0;
    StartOn?: string = '';
    EndOn?: string = '';
    ToMeasuremntInstanceId?: number = 0;
    Unit?: string = '';
    SubUnit?: string = '';
    ModifiedOn?: string = '';
    ModifiedBy?: number = 0;
    IsLast6MonthActive?: number = 0;

    constructor(msr?: contracts.IMeasurementContextPointMap) {
        this.FromMeasurementInstance = msr.FromMeasurementInstance;
        this.ToMeasurementInstance = msr.ToMeasurementInstance;
        this.MeasurementMapAdditionalInfo = new MeasurementMapAdditionalInfo(msr.MeasurementMapAdditionalInfo);
        this.MeasurementMappingDeleteType = msr.MeasurementMappingDeleteType;
        this.IsHavingMeasurementValue = msr.IsHavingMeasurementValue;
        this.ModelNumber = msr.ModelNumber;
        this.FromMeasuremntInstanceId = msr.FromMeasuremntInstanceId;
        this.ToMeasuremntInstanceId = msr.ToMeasuremntInstanceId;
        this.StartOn = msr.StartOn;
        this.EndOn = msr.EndOn;
        this.Unit = msr.Unit;
        this.SubUnit = msr.SubUnit;
        this.ModifiedBy = msr.ModifiedBy;
        this.ModifiedOn = msr.ModifiedOn;
        this.IsLast6MonthActive = msr.IsLast6MonthActive && msr.IsLast6MonthActive === 1 ? 1 : 0;
    }
}
export class MeasurementInstanceLite implements contracts.IMeasurementInstanceLite {
    Measurement?: MeasurementLite = new MeasurementLite();
    MeasurementInstanceId?: number = 0;
    ContextPointId?: number = 0;
    MeasurementSource?: string = '';
    StuiTypeId?: number = 0;
    StuiId?: number = 0;
    StuiName?: string = '';
    IncomingInstance?: string = '';
    InstanceId?: number = 0;
    DisplayName?: string = '';
    MeasurementId?: number = 0;
    MeasurementName?: string = '';
    ContextPointTypeId?: number = 0;
    ContextPointSubTypeId?: number = 0;
    SourceId?: number = 0;
    IsCalculated?: boolean;
    CreatedBy?: number = 0;
    IsNgcAnalogInput?: boolean;
    SourceApplication?: number = 0;
    OriginType ?: number = 0;
}
export class MeasurementLite implements contracts.IMeasurementLite {
    MeasurementId?: number = 0;
    MeasurementName?: string = '';
    NalcoNumericsUnitOrSpecies?: string = '';
    StuiTypeId?: number = 0;
    NeedsMeasurementContext?: boolean;
}
export class MeasurementMapAdditionalInfo implements contracts.IMeasurementMapAdditionalInfo {
    DependentMeasurementDisplayName: string;
    MeasurementInstanceScale?: MeasurementInstanceScale = new MeasurementInstanceScale();
    CalculationType?: number = 1;
    IsColdPath?: boolean = false;
    HasDependentMeasurement?: boolean = false;
    IsRtd?: boolean = false;
    DataCaptureUnit?: string;
    SamplePointId?: number;
    SamplePointName?: string;

    constructor(MeasurementMapAdditionalInfo?: contracts.IMeasurementMapAdditionalInfo) {
        if (MeasurementMapAdditionalInfo) {
            this.DependentMeasurementDisplayName = MeasurementMapAdditionalInfo.DependentMeasurementDisplayName ? MeasurementMapAdditionalInfo.DependentMeasurementDisplayName : '';
            this.MeasurementInstanceScale = new MeasurementInstanceScale(MeasurementMapAdditionalInfo.MeasurementInstanceScale);
            this.CalculationType = MeasurementMapAdditionalInfo.CalculationType ? MeasurementMapAdditionalInfo.CalculationType : 1;
            this.IsColdPath = MeasurementMapAdditionalInfo.IsColdPath ? MeasurementMapAdditionalInfo.IsColdPath : false;
            this.HasDependentMeasurement = MeasurementMapAdditionalInfo.HasDependentMeasurement ? MeasurementMapAdditionalInfo.HasDependentMeasurement : false;
            this.IsRtd = MeasurementMapAdditionalInfo.IsRtd ? MeasurementMapAdditionalInfo.IsRtd : false;
            this.DataCaptureUnit = MeasurementMapAdditionalInfo.DataCaptureUnit;
            this.SamplePointId = MeasurementMapAdditionalInfo.SamplePointId;
            this.SamplePointName = MeasurementMapAdditionalInfo.SamplePointName;
        }
    }

}

export class DataSource implements contracts.IDataSource {
    DataSourceTypeAlias?: string = '';
    DataSourceFriendlyName?: string = '';
    Children?: Array<ContextPoint> = [];
    ModelNumber?: string = '';
    SerialNumber?: string = '';
    SoldTo?: string = '';
    ShipTo?: string = '';
    SystemName?: string = '';
    CustomerName?: string = '';
    LegacyId?: string = '';
    ReadyLabel?: string = '';
    ReportedOrigin?: string = '';
    DataSourceModifiedBy?: number = 0;
    DataSourceModifiedOn?: string = '';
    ContextPointSourceType?: string = '';
    ContextPointSourceTypePrettyName?: string = '';
    CreatedOn?: string = '';
    RetiredOn?: string = '';
    ModifiedOn?: string = '';
    ModifiedBy?: number = 0;
    Source?: string = '';
    SourceSystemCode?: string = '';
    DataIntgExecId?: number = 0;
    DagId?: number = 0;
    ContextPointId?: number = 0;
    ContextPointName?: string = '';
    ContextPointStatusType?: string = '';
    ContextPointStatusTypePrettyName?: string = '';
    ContextPointType?: string = '';
    ContextPointTypePrettyName?: string = '';
    ContextPointSubTypeId?: number = 0;
    ContextPointSubTypeName?: string = '';
    DataTimeZone?: TimeZoneCdm = new TimeZoneCdm();
    DeviceTimeZone?: TimeZoneCdm = new TimeZoneCdm();
    DataTimeZoneId?: number = 0;
    DeviceTimeZoneId?: number = 0;
    SourceIdentifier?: string = '';
    IsLast6MonthActive?: number = 0;
    IsInventoryDS?: boolean;


    constructor(DataSource?: contracts.IDataSource) {
        Object.assign(this, DataSource);
    }
}
export class RelationshipMasterData implements contracts.IRelationshipMasterData {
    ContextPointId?: number = 0;
    ContextPointTypeId?: number = 0;
    ContextPointSubTypeId?: number = 0;
    Name?: string = '';
    ParentContextPointId?: number = 0;
    ParentContextPointSubTypeId?: number = 0;
    IsSelected?: boolean;
    constructor(relationship?: contracts.IRelationshipMasterData) {
        if (relationship) {
            this.ContextPointId = relationship.ContextPointId;
            this.ContextPointTypeId = relationship.ContextPointTypeId;
            this.ContextPointSubTypeId = relationship.ContextPointSubTypeId;
            this.Name = relationship.Name;
            this.ParentContextPointId = relationship.ParentContextPointId;
            this.ParentContextPointSubTypeId = relationship.ParentContextPointSubTypeId;
            this.IsSelected = relationship.IsSelected;
        }
    }
}

export class AssetProperty implements contracts.IAssetProperty {
    AtbAssetProperty?: AtbAssetProperty = new AtbAssetProperty();
    FEDLimitInstances?: FEDLimitInstances = new FEDLimitInstances();
    WaterSafetyCorpLimits?: FEDLimitInstances = new FEDLimitInstances();
    OmniDesignProperties?: OmniDesignProperties = new OmniDesignProperties();
    OmniRelationshipProperties?: OmniRelationshipProperties = new OmniRelationshipProperties();
    AssetLimits?: AssetLimits = new AssetLimits();
    OfflineConfiguration?: OfflineConfiguration = new OfflineConfiguration();

    constructor(ap?: contracts.IAssetProperty) {
        if (ap) {
            this.AtbAssetProperty = new AtbAssetProperty(ap.AtbAssetProperty);
            this.FEDLimitInstances = new FEDLimitInstances(ap.FEDLimitInstances);
            this.WaterSafetyCorpLimits = new FEDLimitInstances(ap.WaterSafetyCorpLimits);
            this.OmniDesignProperties = new OmniDesignProperties(ap.OmniDesignProperties);
            this.OmniRelationshipProperties = new OmniRelationshipProperties(ap.OmniRelationshipProperties);
            this.AssetLimits = new AssetLimits(ap.AssetLimits);
            this.OfflineConfiguration = new OfflineConfiguration(ap.OfflineConfiguration);
        }
    }
}

export class DatasourceMeasurementMap implements contracts.IDatasourceMeasurementMap {
    FromMeasurementInstanceId?: number = 0;
    ToMeasurementInstanceId?: number = 0;
    MeasurementId?: number = 0;
    MeasurementName?: string = '';
    InstanceId?: number = 0;
    IncomingInstance?: string = '';
    ToDisplayName?: string = '';
    IsDeleted?: boolean;
    DeletedReason?: string = '';
    Boiler?: number = 0;
    CoolingWater?: number = 0;
    DissolvedAirFlotation?: number = 0;
    Membranes?: number = 0;
    WasteWater?: number = 0;
    StuiTypeId?: number = 0;
    StuiId?: number = 0;
    StuiName?: string = '';
    IsMapped?: boolean;
    ToContextPointId?: number = 0;
    ToMeasurementSource?: string = '';
    FromContextPointId?: number = 0;
    FromContextPointName?: string = '';
    FromMeasurementSource?: string = '';
    FromStuiResourceName?: string = '';
    FromMeasureResourceName?: string = '';
    constructor(dsmm?: contracts.IDatasourceMeasurementMap) {
        if (dsmm) {
            this.FromMeasurementInstanceId = dsmm.FromMeasurementInstanceId;
            this.ToMeasurementInstanceId = dsmm.ToMeasurementInstanceId;
            this.MeasurementId = dsmm.MeasurementId;
            this.MeasurementName = dsmm.MeasurementName;
            this.InstanceId = dsmm.InstanceId;
            this.IncomingInstance = dsmm.IncomingInstance;
            this.ToDisplayName = dsmm.ToDisplayName;
            this.IsDeleted = dsmm.IsDeleted;
            this.DeletedReason = dsmm.DeletedReason;
            this.Boiler = dsmm.Boiler;
            this.CoolingWater = dsmm.CoolingWater;
            this.DissolvedAirFlotation = dsmm.DissolvedAirFlotation;
            this.Membranes = dsmm.Membranes;
            this.WasteWater = dsmm.WasteWater;
            this.StuiTypeId = dsmm.StuiTypeId;
            this.StuiId = dsmm.StuiId;
            this.StuiName = dsmm.StuiName;
            this.IsMapped = dsmm.IsMapped;
            this.ToContextPointId = dsmm.ToContextPointId;
            this.ToMeasurementSource = dsmm.ToMeasurementSource;
            this.FromContextPointId = dsmm.FromContextPointId;
            this.FromContextPointName = dsmm.FromContextPointName;
            this.FromMeasurementSource = dsmm.FromMeasurementSource;
            this.FromStuiResourceName = dsmm.FromStuiResourceName;
            this.FromMeasureResourceName = dsmm.FromMeasureResourceName;
        }
    }
}

export class Site implements contracts.ISite {
    DagName?: string = '';
    Country?: string = '';
    CountryCodeIso3?: string = '';
    CorpAccountId?: number = 0;
    CorpAccountName?: string = '';
    Message?: string = '';
    CustomerLocation?: string = '';
    Children?: Array<contracts.IContextPoint> = [];
    SiteName?: string = '';
    SiteNameEnglish?: string = '';
    FriendlyName?: string = '';
    Street?: string = '';
    City?: string = '';
    StateProvince?: string = '';
    PostalCode?: string = '';
    Latitude?: number = 0;
    Longitude?: number = 0;
    CdmSiteNo?: string = '';
    Elevation?: number = 0;
    CoordinateType?: number = 0;
    CoordinateModifiedOn?: string = '';
    SiteModifiedBy?: number = 0;
    SiteModifiedOn?: string = '';
    ContextPointSourceType?: string = '';
    ContextPointSourceTypePrettyName?: string = '';
    CreatedOn?: string = '';
    RetiredOn?: string = '';
    ModifiedOn?: string = '';
    ModifiedBy?: number = 0;
    Source?: string = '';
    SourceSystemCode?: string = '';
    DataIntgExecId?: number = 0;
    DagId?: number = 0;
    ContextPointId?: number = 0;
    ContextPointName?: string = '';
    ContextPointStatusType?: string = '';
    ContextPointStatusTypePrettyName?: string = '';
    ContextPointType?: string = '';
    ContextPointTypePrettyName?: string = '';
    ContextPointSubTypeId?: number = 0;
    ContextPointSubTypeName?: string = '';

    constructor(s?: contracts.ISite) {
        if (s) {
            this.DagName = s.DagName;
            this.Country = s.Country;
            this.CountryCodeIso3 = s.CountryCodeIso3;
            this.CorpAccountId = s.CorpAccountId;
            this.CorpAccountName = s.CorpAccountName;
            this.Message = s.Message;
            this.CustomerLocation = s.CustomerLocation;
            this.SiteName = s.SiteName;
            this.SiteNameEnglish = s.SiteNameEnglish;
            this.FriendlyName = s.FriendlyName;
            this.Street = s.Street;
            this.City = s.City;
            this.StateProvince = s.StateProvince;
            this.PostalCode = s.PostalCode;
            this.Latitude = s.Latitude;
            this.Longitude = s.Longitude;
            this.CdmSiteNo = s.CdmSiteNo;
            this.Elevation = s.Elevation;
            this.CoordinateType = s.CoordinateType;
            this.CoordinateModifiedOn = s.CoordinateModifiedOn;
            this.SiteModifiedBy = s.SiteModifiedBy;
            this.SiteModifiedOn = s.SiteModifiedOn;
            this.ContextPointSourceType = s.ContextPointSourceType;
            this.ContextPointSourceTypePrettyName = s.ContextPointSourceTypePrettyName;
            this.CreatedOn = s.CreatedOn;
            this.RetiredOn = s.RetiredOn;
            this.ModifiedOn = s.ModifiedOn;
            this.ModifiedBy = s.ModifiedBy;
            this.Source = s.Source;
            this.SourceSystemCode = s.SourceSystemCode;
            this.DataIntgExecId = s.DataIntgExecId;
            this.DagId = s.DagId;
            this.ContextPointId = s.ContextPointId;
            this.ContextPointName = s.ContextPointName;
            this.ContextPointStatusType = s.ContextPointStatusType;
            this.ContextPointStatusTypePrettyName = s.ContextPointStatusTypePrettyName;
            this.ContextPointType = s.ContextPointType;
            this.ContextPointTypePrettyName = s.ContextPointTypePrettyName;
            this.ContextPointSubTypeId = s.ContextPointSubTypeId;
            this.ContextPointSubTypeName = s.ContextPointSubTypeName;
            if (s.Children && s.Children.length) {
                s.Children.forEach(e => {
                    this.Children.push(new ContextPoint(e));
                });
            }
        }
    }
}

export class ContextPointEntityBase {
    ContextPointId?: number = 0;
    ContextPointName?: string = '';
    ContextPointStatusType?: string = '';
    ContextPointStatusTypePrettyName?: string = '';
    ContextPointType?: string = '';
    ContextPointTypePrettyName?: string = '';
    ContextPointSubTypeId?: number = 0;
    ContextPointSubTypeName?: string = '';

    constructor(CPEB?: ContextPointEntityBase) {
        if (CPEB) {
            this.ContextPointId = CPEB.ContextPointId;
            this.ContextPointName = CPEB.ContextPointName;
            this.ContextPointStatusType = CPEB.ContextPointStatusType;
            this.ContextPointStatusTypePrettyName = CPEB.ContextPointStatusTypePrettyName;
            this.ContextPointType = CPEB.ContextPointType;
            this.ContextPointTypePrettyName = CPEB.ContextPointTypePrettyName;
            this.ContextPointSubTypeId = CPEB.ContextPointSubTypeId;
            this.ContextPointSubTypeName = CPEB.ContextPointSubTypeName;
        }
    }
}

export class ContextPointEntity extends ContextPointEntityBase {
    ContextPointSourceType?: string = '';
    ContextPointSourceTypePrettyName?: string = '';
    CreatedOn?: string = '';
    RetiredOn?: string = '';
    ModifiedOn?: string = '';
    ModifiedBy?: number = 0;
    Source?: string = '';
    SourceIdentifier?: string = '';
    SourceSystemCode?: string = '';
    DataIntgExecId?: number = 0;
    DagId?: number = 0;
    constructor(CPE?: ContextPointEntity) {
        super(CPE);
        if (CPE) {
            this.ContextPointSourceType = CPE.ContextPointSourceType;
            this.ContextPointSourceTypePrettyName = CPE.ContextPointSourceTypePrettyName;
            this.CreatedOn = CPE.CreatedOn;
            this.RetiredOn = CPE.RetiredOn;
            this.ModifiedOn = CPE.ModifiedOn;
            this.ModifiedBy = CPE.ModifiedBy;
            this.Source = CPE.Source;
            this.SourceIdentifier = CPE.SourceIdentifier;
            this.SourceSystemCode = CPE.SourceSystemCode;
            this.DataIntgExecId = CPE.DataIntgExecId;
            this.DagId = CPE.DagId;
        }
    }
}

export class ContextPoint extends ContextPointEntity implements contracts.IContextPoint {
    ContextPointId?: number = 0;
    ContextPointName?: string = '';
    ContextPointType?: string = '';
    ContextPointTypePrettyName?: string = '';
    ContextPointSubTypeId?: number = 0;
    ContextPointSubTypeName?: string = '';
    ContextPointSourceType?: string = '';
    ContextPointSourceTypePrettyName?: string = '';
    ContextPointStatusType?: string = '';
    ContextPointStatusTypePrettyName?: string = '';
    CreatedOn?: string = '';
    RetiredOn?: string = '';
    ModifiedOn?: string = '';
    ModifiedBy?: number = 0;
    Source?: string = '';
    SourceIdentifier?: string = '';
    Children?: Array<contracts.IContextPoint> = [];

    constructor(c?: ContextPoint) {
        super(c);
        if (c) {
            this.ContextPointId = c.ContextPointId;
            this.ContextPointName = c.ContextPointName;
            this.ContextPointType = c.ContextPointType;
            this.ContextPointTypePrettyName = c.ContextPointTypePrettyName;
            this.ContextPointSubTypeId = c.ContextPointSubTypeId;
            this.ContextPointSubTypeName = c.ContextPointSubTypeName;
            this.ContextPointSourceType = c.ContextPointSourceType;
            this.ContextPointSourceTypePrettyName = c.ContextPointSourceTypePrettyName;
            this.ContextPointStatusType = c.ContextPointStatusType;
            this.ContextPointStatusTypePrettyName = c.ContextPointStatusTypePrettyName;
            this.CreatedOn = c.CreatedOn;
            this.RetiredOn = c.RetiredOn;
            this.ModifiedOn = c.ModifiedOn;
            this.ModifiedBy = c.ModifiedBy;
            this.Source = c.Source;
            this.SourceIdentifier = c.SourceIdentifier;
            if (c.Children && c.Children.length) {
                c.Children.forEach(e => {
                    c.Children.push(new ContextPoint(e));
                });
            }
        }
    }
}

export class FEDLimit implements contracts.IFEDLimit {
    Source?: string = '';
    SortOrder?: number = 0;
    Edit: boolean = false;
    DefinitionId?: string = '';
    LimitTypeId?: number = 0;
    LimitTypName?: string = '';
    LowLowLowLimit?: number = 0;
    LowLowLimit?: number = 0;
    LowLimit?: number = 0;
    HighLimit?: number = 0;
    MinLowLimit?: number = 0;
    MaxHighLimit?: number = 0;
    HighHighLimit?: number = 0;
    HighHighHighLimit?: number = 0;
    MeasurementFrequency?: number = 0;
    Target?: number = 0;
    SetPoint?: number = 0;
    Enabled?: boolean;
    AssetSubTypeId?: number = 0;
    AssetSubTypeName?: string = '';
    AssetClassificationId?: number = 0;
    AssetClassificationName?: string = '';
    MeasurementId?: number = 0;
    MeasurementName?: string = '';
    StuiTypeId?: number = 0;
    StuiTypeName?: string = '';
    EntryUnit?: string = '';
    EntrySubunit?: string = '';
    DisplayName?: string = '';
    PropertyKey?: string = '';
    SystemTypeId?: number = 0;
    SystemSubTypeId?: number = 0;
    SystemSubTypeName?: string = '';
    AssetContextPointId?: number = 0;
    StuiInfo?: Array<StuiInfo> = [];
    Ports?: Array<Port> = [];
    Conditions?: Array<Condition> = [];

    constructor(fedlimit?: contracts.IFEDLimit) {
        if (fedlimit) {
            this.DefinitionId = fedlimit.DefinitionId;
            this.LimitTypeId = fedlimit.LimitTypeId;
            this.LimitTypName = fedlimit.LimitTypName;
            this.LowLowLowLimit = fedlimit.LowLowLowLimit;
            this.LowLowLimit = fedlimit.LowLowLimit;
            this.LowLimit = fedlimit.LowLimit;
            this.HighLimit = fedlimit.HighLimit;
            this.MinLowLimit = fedlimit.MinLowLimit;
            this.MaxHighLimit = fedlimit.MaxHighLimit;
            this.HighHighLimit = fedlimit.HighHighLimit;
            this.HighHighHighLimit = fedlimit.HighHighHighLimit;
            this.MeasurementFrequency = fedlimit.MeasurementFrequency;
            this.Target = fedlimit.Target;
            this.SetPoint = fedlimit.SetPoint;
            this.Enabled = fedlimit.Enabled;
            this.AssetSubTypeId = fedlimit.AssetSubTypeId;
            this.AssetSubTypeName = fedlimit.AssetSubTypeName;
            this.AssetClassificationId = fedlimit.AssetClassificationId;
            this.AssetClassificationName = fedlimit.AssetClassificationName;
            this.MeasurementId = fedlimit.MeasurementId;
            this.MeasurementName = fedlimit.MeasurementName;
            this.StuiTypeId = fedlimit.StuiTypeId;
            this.StuiTypeName = fedlimit.StuiTypeName;
            this.EntryUnit = fedlimit.EntryUnit;
            this.EntrySubunit = fedlimit.EntrySubunit;
            this.DisplayName = fedlimit.DisplayName;
            this.PropertyKey = fedlimit.PropertyKey;
            this.SystemTypeId = fedlimit.SystemTypeId;
            this.SystemSubTypeId = fedlimit.SystemSubTypeId;
            this.SystemSubTypeName = fedlimit.SystemSubTypeName;
            this.AssetContextPointId = fedlimit.AssetContextPointId;
            if (fedlimit.StuiInfo && fedlimit.StuiInfo.length) {
                fedlimit.StuiInfo.forEach(info => {
                    this.StuiInfo.push(new StuiInfo(info));
                });
            }
            if (fedlimit.Ports && fedlimit.Ports.length) {
                fedlimit.Ports.forEach(p => {
                    this.Ports.push(new Port(p));
                });
            }
            if (fedlimit.Conditions && fedlimit.Conditions.length) {
                fedlimit.Conditions.forEach(c => {
                    this.Conditions.push(new Condition(c));
                });
            }

        }
    }
}

export class OmniAssetDesignSettings implements contracts.IOmniAssetDesignSettings {
    OmniAssetDesignProperties?: Array<OmniAssetDesignProperties> = [];
    constructor(OmniDesign?: contracts.IOmniAssetDesignSettings) {
        if (OmniDesign && OmniDesign.OmniAssetDesignProperties && OmniDesign.OmniAssetDesignProperties.length) {
            OmniDesign.OmniAssetDesignProperties.forEach(i => {
                this.OmniAssetDesignProperties.push(new OmniAssetDesignProperties(i));
            });
        }
    }
}

export class FlowMeter implements contracts.IFlowMeter {
    FlowMeterInfos?: Array<FlowMeterInfo> = [];
    FlowMeterId?: number = 0;
    Manufacturer?: string = '';
    ModelNumber?: string = '';
    constructor(fw?: contracts.IFlowMeter) {
        if (fw) {
            this.FlowMeterId = fw.FlowMeterId;
            this.Manufacturer = fw.Manufacturer;
            this.ModelNumber = fw.ModelNumber;
            if (fw.FlowMeterInfos && fw.FlowMeterInfos.length) {
                fw.FlowMeterInfos.forEach(info => {
                    this.FlowMeterInfos.push(new FlowMeterInfo(info));
                });
            }
        }
    }
}

export class StuiInfo implements contracts.IStuiInfo {
    StuiId?: number = 0;
    StuiName?: string = '';
    constructor(si?: contracts.IStuiInfo) {
        if (si) {
            this.StuiId = si.StuiId;
            this.StuiName = si.StuiName;
        }
    }
}

export class Port implements contracts.IPort {
    PortSubtypeId?: number = 0;
    PortSubtypeName?: string = '';
    constructor(port?: contracts.IPort) {
        if (port) {
            this.PortSubtypeId = port.PortSubtypeId;
            this.PortSubtypeName = port.PortSubtypeName;
        }
    }
}

export class Condition implements contracts.ICondition {
    SourceType?: string = '';
    DataSourceGroup?: Array<string> = [];
    constructor(condition?: contracts.ICondition) {
        if (condition) {
            this.SourceType = condition.SourceType;
            let t = [];
            if (condition.DataSourceGroup && condition.DataSourceGroup.length) {
                this.DataSourceGroup.forEach(i => {
                    t.push(i);
                });
            }
            this.DataSourceGroup = t;
        }
    }
}

export class TreeContextPoint implements contracts.ITreeContextPoint {
    OrderInTree?: number = 0;
    IsDeleted?: boolean;
    ContextPointIdParent?: number = 0;
    ContextPointIdentifier?: string = '';
    ContextPointIdentifierParent?: string = '';
    DatasourceMeasurementMaps?: Array<DatasourceMeasurementMap> = [];
    CanReadWrite?: boolean;
    IsMapped?: boolean;
    Children?: Array<ContextPoint> = [];
    MeasurementInstances?: Array<MeasurementInstance> = [];
    MeasurementInstanceIds?: Array<number> = [];
    ContextPointSourceType?: string = '';
    ContextPointSourceTypePrettyName?: string = '';
    CreatedOn?: string = '';
    RetiredOn?: string = '';
    ModifiedOn?: string = '';
    ModifiedBy?: number = 0;
    Source?: string = '';
    SourceSystemCode?: string = '';
    DataIntgExecId?: number = 0;
    DagId?: number = 0;
    ContextPointId?: number = 0;
    ContextPointName?: string = '';
    ContextPointStatusType?: number = 0;
    ContextPointStatusTypePrettyName?: string = '';
    ContextPointType?: number = 0;
    ContextPointTypePrettyName?: string = '';
    PortSubTypeId?: number = 0;
    ContextPointSubTypeId?: number = 0;
    ContextPointSubTypeName?: string = '';
    // Computed at UI
    // id?: number = 0;
    // display?: string = '';
    // value?: string = '';
    // checked?: boolean;

    constructor(tcp?: contracts.ITreeContextPoint) {
        if (tcp) {
            this.OrderInTree = tcp.OrderInTree;
            this.IsDeleted = tcp.IsDeleted;
            this.ContextPointIdParent = tcp.ContextPointIdParent;
            this.ContextPointIdentifier = tcp.ContextPointIdentifier;
            this.ContextPointIdentifierParent = tcp.ContextPointIdentifierParent;
            this.DatasourceMeasurementMaps = tcp.DatasourceMeasurementMaps;
            this.CanReadWrite = tcp.CanReadWrite;
            this.IsMapped = tcp.IsMapped;
            this.ContextPointSourceType = tcp.ContextPointSourceType;
            this.ContextPointSourceTypePrettyName = tcp.ContextPointSourceTypePrettyName;
            this.CreatedOn = tcp.CreatedOn;
            this.RetiredOn = tcp.RetiredOn;
            this.ModifiedOn = tcp.ModifiedOn;
            this.ModifiedBy = tcp.ModifiedBy;
            this.Source = tcp.Source;
            this.SourceSystemCode = tcp.SourceSystemCode;
            this.DataIntgExecId = tcp.DataIntgExecId;
            this.DagId = tcp.DagId;
            this.ContextPointId = tcp.ContextPointId;
            this.ContextPointName = tcp.ContextPointName;
            this.ContextPointStatusType = tcp.ContextPointStatusType;
            this.ContextPointStatusTypePrettyName = tcp.ContextPointStatusTypePrettyName;
            this.ContextPointType = tcp.ContextPointType;
            this.ContextPointTypePrettyName = tcp.ContextPointTypePrettyName;
            this.PortSubTypeId = tcp.PortSubTypeId;
            this.ContextPointSubTypeId = tcp.ContextPointSubTypeId;
            this.ContextPointSubTypeName = tcp.ContextPointSubTypeName;
            if (tcp.Children && tcp.Children.length) {
                tcp.Children.forEach(cp => {
                    this.Children.push(new ContextPoint(cp));
                });
            }
            if (tcp.MeasurementInstances && tcp.MeasurementInstances.length) {
                tcp.MeasurementInstances.forEach(mi => {
                    this.MeasurementInstances.push(new MeasurementInstance(mi));
                });
            }
            if (this.MeasurementInstanceIds && this.MeasurementInstanceIds.length) {
                let a = [];
                tcp.MeasurementInstanceIds.forEach(el => {
                    a.push(el);
                });
                this.MeasurementInstanceIds = a;
            }
            // this.id = tcp.ContextPointId;
            // this.display = tcp.ContextPointName;
            // this.value = tcp.ContextPointName;
        }
    }
}

export class MeasurementInstance implements contracts.IMeasurementInstance {
    FromMeasurementInstanceId?: number = 0;
    MeasurementInstanceScaleId?: number = 0;
    MeasurementNameTranslated?: string = '';
    NalcoNumericsUnitOrSpecies?: string = '';
    StuiName?: string = '';
    StuiResourceName?: string = '';
    MeasureResourceName?: string = '';
    OptionGroupId?: number = 0;
    UnitLabel?: string = '';
    UnitLabelPositionTypeId?: number = 0;
    UnitLabelResourceName?: string = '';
    FlowMeterInfo?: FlowMeter;
    MeasurementInstanceScales?: Array<MeasurementInstanceScale> = [];
    ActiveMappedMeasurementInstance?: MeasurementInstance;
    ActiveMappedMeasurementContextPoint?: Array<ContextPoint> = [];
    Site?: Site;
    StartDate?: string = '';
    EndDate?: string = '';
    EarliestAvailableMappingDateTime?: string = '';
    MeasurementInstanceId?: number = 0;
    ContextPointId?: number = 0;
    MeasurementSource?: string = '';
    StuiTypeId?: number = 0;
    StuiId?: number = 0;
    IncomingInstance?: string = '';
    InstanceId?: number = 0;
    DisplayName?: string = '';
    MeasurementId?: number = 0;
    MeasurementName?: string = '';
    ContextPointTypeId?: number = 0;
    ContextPointSubTypeId?: number = 0;
    MeasurementSourceOptions?: Array<{ text: string; value: number, isSelected: boolean }> = [];
    constructor(mi?: contracts.IMeasurementInstance) {
        if (mi) {
            this.FromMeasurementInstanceId = mi.FromMeasurementInstanceId;
            this.MeasurementInstanceScaleId = mi.MeasurementInstanceScaleId;
            this.MeasurementNameTranslated = mi.MeasurementNameTranslated;
            this.NalcoNumericsUnitOrSpecies = mi.NalcoNumericsUnitOrSpecies;
            this.StuiName = mi.StuiName;
            this.StuiResourceName = mi.StuiResourceName;
            this.MeasureResourceName = mi.MeasureResourceName;
            this.OptionGroupId = mi.OptionGroupId;
            this.UnitLabel = mi.UnitLabel;
            this.UnitLabelPositionTypeId = mi.UnitLabelPositionTypeId;
            this.UnitLabelResourceName = mi.UnitLabelResourceName;
            this.StartDate = mi.StartDate;
            this.EndDate = mi.EndDate;
            this.EarliestAvailableMappingDateTime = mi.EarliestAvailableMappingDateTime;
            this.MeasurementInstanceId = mi.MeasurementInstanceId;
            this.ContextPointId = mi.ContextPointId;
            this.MeasurementSource = mi.MeasurementSource;
            this.StuiTypeId = mi.StuiTypeId;
            this.StuiId = mi.StuiId;
            this.IncomingInstance = mi.IncomingInstance;
            this.InstanceId = mi.InstanceId;
            this.DisplayName = mi.DisplayName;
            this.MeasurementId = mi.MeasurementId;
            this.MeasurementName = mi.MeasurementName;
            this.ContextPointTypeId = mi.ContextPointTypeId;
            this.ContextPointSubTypeId = mi.ContextPointSubTypeId;
            this.MeasurementSourceOptions = mi.MeasurementSourceOptions;
            this.Site = new Site(mi.Site);
            this.FlowMeterInfo = new FlowMeter(mi.FlowMeterInfo);
            this.ActiveMappedMeasurementInstance = new MeasurementInstance(mi.ActiveMappedMeasurementInstance);
            if (mi.ActiveMappedMeasurementContextPoint && mi.ActiveMappedMeasurementContextPoint.length) {
                mi.ActiveMappedMeasurementContextPoint.forEach(el => {
                    this.ActiveMappedMeasurementContextPoint.push(new ContextPoint(el));
                });
            }
            if (mi.MeasurementInstanceScales && mi.MeasurementInstanceScales.length) {
                mi.MeasurementInstanceScales.forEach(el => {
                    this.MeasurementInstanceScales.push(new MeasurementInstanceScale(el));
                });
            }
        }
    }

}

export class MeasurementInstanceScale implements contracts.IMeasurementInstanceScale {
    MeasurementInstanceId?: number = 0;
    StartDate?: string = '';
    EndDate?: string = '';
    LowValue?: number = 0;
    HighValue?: number = 0;
    InputMin?: number = 0;
    InputMax?: number = 0;
    Offset?: number = 0;
    CreatedBy?: number = 0;
    CreatedDate?: string = '';
    ModifiedBy?: number = 0;
    ModifiedDate?: string = '';
    MeasurementInstanceScaleId?: number = 0;
    UnitUsageSubUnitId?: number = 0;
    IsScaled?: boolean;
    ValueUnitSubUnitName?: string = '';
    InputUnitSubUnitName?: string = '';
    //Computed on UI
    DateError?: string = '';
    UIStartDate?: Date;
    IsChecked?: boolean;
    CurrentScaleDate?: string = "";
    ScaleValueChangeMsg?: string = "";
    IsDisabled?: boolean;
    ScaleLowValue?: number = 0;
    ScaleHighValue?: number = 0;
    IsActive?: boolean = true;
    IsModbusInput?:boolean = false;

    constructor(mis?: contracts.IMeasurementInstanceScale) {
        if (mis) {
            this.MeasurementInstanceId = mis.MeasurementInstanceId;
            this.StartDate = mis.StartDate;
            this.EndDate = mis.EndDate;
            this.LowValue = mis.LowValue;
            this.HighValue = mis.HighValue;
            this.InputMin = mis.InputMin;
            this.InputMax = mis.InputMax;
            this.Offset = mis.Offset;
            this.CreatedBy = mis.CreatedBy;
            this.CreatedDate = mis.CreatedDate;
            this.ModifiedBy = mis.ModifiedBy;
            this.ModifiedDate = mis.ModifiedDate;
            this.MeasurementInstanceScaleId = mis.MeasurementInstanceScaleId;
            this.UnitUsageSubUnitId = mis.UnitUsageSubUnitId;
            this.IsScaled = mis.IsScaled;
            this.ValueUnitSubUnitName = mis.ValueUnitSubUnitName;
            this.InputUnitSubUnitName = mis.InputUnitSubUnitName;
            this.IsActive = mis.IsActive;
            this.IsModbusInput = mis.IsModbusInput
        }
    }
}

export class AtbAssetProperty implements contracts.IAtbAssetProperty {
    Id?: string = '';
    ContextPointId?: number = 0;
    ContextPointSubTypeId?: number = 0;
    ConfigurationStatus?: string = '';
    OperationalStatusId?: number = 0;
    OperationalStatus?: string = '';
    ManagedStatusId?: number = -1;
    ManagedStatus?: string = '';
    PHControlMethodId?: number = -1;
    PHControlMethod?: string = '';
    BiocideFeedMethodId?: number = -1;
    BiocideFeedMethod?: string = '';
    BoilerClassificationId?: number = -1;
    BoilerClassificationName?: string = '';
    ModifiedOn?: string = '';
    ModifiedBy?: number = 0;
    AssetPropertyValues?: any;
    OperationSchedule?: OperationalSchedule = null;

    constructor(assetProp?: contracts.IAtbAssetProperty) {
        if (assetProp) {
            this.Id = assetProp.Id;
            this.ContextPointId = assetProp.ContextPointId;
            this.ContextPointSubTypeId = assetProp.ContextPointSubTypeId;
            this.ConfigurationStatus = assetProp.ConfigurationStatus;
            this.OperationalStatusId = assetProp.OperationalStatusId;
            this.OperationalStatus = assetProp.OperationalStatus;
            this.ManagedStatusId = assetProp.ManagedStatusId;
            this.ManagedStatus = assetProp.ManagedStatus;
            this.PHControlMethodId = assetProp.PHControlMethodId;
            this.PHControlMethod = assetProp.PHControlMethod;
            this.BiocideFeedMethodId = assetProp.BiocideFeedMethodId;
            this.BiocideFeedMethod = assetProp.BiocideFeedMethod;
            this.BoilerClassificationId = assetProp.BoilerClassificationId;
            this.BoilerClassificationName = assetProp.BoilerClassificationName;
            this.ModifiedOn = assetProp.ModifiedOn;
            this.ModifiedBy = assetProp.ModifiedBy;
            this.AssetPropertyValues = assetProp.AssetPropertyValues;
            this.OperationSchedule = assetProp.OperationSchedule;
        }
    }
}

export class FEDLimitInstances implements contracts.IFEDLimitInstances {
    Id?: string = '';
    ServiceTypeId?: number = 0;
    ParentId?: number = 0;
    ParentTypeId?: number = 0;
    ParentSubTypeId?: number = 0;
    ContextPointId?: number = 0;
    ContextPointTypeId?: number = 0;
    ContextPointTypeName?: string = '';
    ContextPointSubTypeId?: number = 0;
    DocumentTypeId?: number = 0;
    DocumentSubTypeId?: number = 0;
    DocumentSubTypeName?: string = '';
    HideAsset?: boolean;
    IsEnabled?: boolean;
    VersionNo?: string = '';
    ModifiedDate?: string = '';
    ModifiedOn?: string = '';
    ModifiedBy?: number = 0;
    CreatedOn?: string = '';
    IsDirty?: boolean;
    Limits?: Array<FEDLimit> = [];

    constructor(fli?: contracts.IFEDLimitInstances) {
        if (fli) {
            this.Id = fli.Id;
            this.ServiceTypeId = fli.ServiceTypeId;
            this.ParentId = fli.ParentId;
            this.ParentTypeId = fli.ParentTypeId;
            this.ParentSubTypeId = fli.ParentSubTypeId;
            this.ContextPointId = fli.ContextPointId;
            this.ContextPointTypeId = fli.ContextPointTypeId;
            this.ContextPointTypeName = fli.ContextPointTypeName;
            this.ContextPointSubTypeId = fli.ContextPointSubTypeId;
            this.DocumentTypeId = fli.DocumentTypeId;
            this.DocumentSubTypeId = fli.DocumentSubTypeId;
            this.DocumentSubTypeName = fli.DocumentSubTypeName;
            this.HideAsset = fli.HideAsset;
            this.IsEnabled = fli.IsEnabled;
            this.VersionNo = fli.VersionNo;
            this.ModifiedDate = fli.ModifiedDate;
            this.ModifiedOn = fli.ModifiedOn;
            this.ModifiedBy = fli.ModifiedBy;
            this.CreatedOn = fli.CreatedOn;
            this.IsDirty = fli.IsDirty;
            if (fli.Limits && fli.Limits.length) {
                fli.Limits.forEach(el => {
                    this.Limits.push(new FEDLimit(el));
                });
            }
        }
    }
}

export class OmniAssetDesignProperties implements contracts.IOmniAssetDesignProperties {

    PropertyKey?: string = '';
    DimensionalUsageKey?: string = '';
    DesignValue?: number = 0;
    DesignValueText?: string = '';
    ProcessSideValue?: number = 0;
    ProcessSideValueText?: string = '';
    CoolingWaterSide?: string = '';
    UnitcontrolText?: string = '';

    constructor(omniDP?: contracts.IOmniAssetDesignProperties) {
        if (omniDP) {
            this.PropertyKey = omniDP.PropertyKey;
            this.DimensionalUsageKey = omniDP.DimensionalUsageKey;
            this.DesignValue = omniDP.DesignValue;
            this.DesignValueText = omniDP.DesignValueText;
            this.ProcessSideValue = omniDP.ProcessSideValue;
            this.ProcessSideValueText = omniDP.ProcessSideValueText;
            this.CoolingWaterSide = omniDP.CoolingWaterSide;
            this.UnitcontrolText = omniDP.UnitcontrolText;
        }
    }

}

export class OmniRelationshipProperties implements contracts.IOmniRelationshipProperties {
    ChildContextPointIds?: Array<number> = [];
    RelationshipMasterData?: Array<RelationshipMasterData> = [];

    constructor(omniRP?: contracts.IOmniRelationshipProperties) {
        if (omniRP) {
            let a = [];
            if (omniRP.ChildContextPointIds && omniRP.ChildContextPointIds.length) {
                omniRP.ChildContextPointIds.forEach(el => {
                    a.push(el);
                });
            }
            this.ChildContextPointIds = a;
            if (omniRP.RelationshipMasterData && omniRP.RelationshipMasterData.length) {
                omniRP.RelationshipMasterData.forEach(el => {
                    this.RelationshipMasterData.push(new RelationshipMasterData(el));
                });
            }
        }
    }
}

export class OmniDesignProperties implements contracts.IOmniDesignProperties {
    Id?: string = '';
    ServiceTypeId?: number = 0;
    ParentId?: number = 0;
    ParentTypeId?: number = 0;
    ParentSubTypeId?: number = 0;
    ContextPointId?: number = 0;
    ContextPointTypeId?: number = 0;
    ContextPointSubTypeId?: number = 0;
    DocumentTypeId?: number = 0;
    DocumentSubTypeId?: number = 0;
    DocumentSubTypeName?: string = '';
    HideAsset?: boolean;
    IsEnabled?: boolean;
    VersionNo?: string = '';
    ModifiedDate?: string = '';
    ModifiedOn?: string = '';
    ModifiedBy?: number = 0;
    CreatedOn?: string = '';
    CoolingWater?: string = '';
    Current?: string = '';
    DesignDataFrequency?: string = '';
    Settings?: OmniAssetDesignSettings;

    constructor(odp?: contracts.IOmniDesignProperties) {
        if (odp) {
            this.Id = odp.Id;
            this.ServiceTypeId = odp.ServiceTypeId;
            this.ParentId = odp.ParentId;
            this.ParentTypeId = odp.ParentTypeId;
            this.ParentSubTypeId = odp.ParentSubTypeId;
            this.ContextPointId = odp.ContextPointId;
            this.ContextPointTypeId = odp.ContextPointTypeId;
            this.ContextPointSubTypeId = odp.ContextPointSubTypeId;
            this.DocumentTypeId = odp.DocumentTypeId;
            this.DocumentSubTypeId = odp.DocumentSubTypeId;
            this.DocumentSubTypeName = odp.DocumentSubTypeName;
            this.HideAsset = odp.HideAsset;
            this.IsEnabled = odp.IsEnabled;
            this.VersionNo = odp.VersionNo;
            this.ModifiedDate = odp.ModifiedDate;
            this.ModifiedOn = odp.ModifiedOn;
            this.ModifiedBy = odp.ModifiedBy;
            this.CreatedOn = odp.CreatedOn;
            this.CoolingWater = odp.CoolingWater;
            this.Current = odp.Current;
            this.DesignDataFrequency = odp.DesignDataFrequency;
            this.Settings = new OmniAssetDesignSettings(odp.Settings);
        }
    }
}

export class FlowMeterInfo implements contracts.IFlowMeterInfo {
    MeasurementInstanceId?: number = 0;
    FlowMeter?: FlowMeter;
    TemperatureSubUnit?: string = '';
    UnitSymbol?: string = '';
    FlowMeterInfoId?: number = 0;
    FlowMeterId?: number = 0;
    FlowMeterType?: string = '';
    FlowMeterTypeToString?: string = '';
    PipeSize?: string = '';
    Code?: string = '';
    Kfactor?: number = 0;
    KfactorUnit?: string = '';
    KfactorEditType?: string = '';
    DdlRange?: string = '';
    DisplayName?: string = '';
    HighValueMin?: number = 0;
    HighValueMax?: number = 0;
    LowValue?: number = 0;
    HighValue?: number = 0;
    InputMin?: number = 0;
    InputMax?: number = 0;
    Offset?: number = 0;

    constructor(fmi?: contracts.IFlowMeterInfo) {
        if (fmi) {
            this.MeasurementInstanceId = fmi.MeasurementInstanceId;
            this.FlowMeter = new FlowMeter(fmi.FlowMeter);
            this.TemperatureSubUnit = fmi.TemperatureSubUnit;
            this.UnitSymbol = fmi.UnitSymbol;
            this.FlowMeterInfoId = fmi.FlowMeterInfoId;
            this.FlowMeterId = fmi.FlowMeterId;
            this.FlowMeterType = fmi.FlowMeterType;
            this.FlowMeterTypeToString = fmi.FlowMeterTypeToString;
            this.PipeSize = fmi.PipeSize;
            this.Code = fmi.Code;
            this.Kfactor = fmi.Kfactor;
            this.KfactorUnit = fmi.KfactorUnit;
            this.KfactorEditType = fmi.KfactorEditType;
            this.DdlRange = fmi.DdlRange;
            this.DisplayName = fmi.DisplayName;
            this.HighValueMin = fmi.HighValueMin;
            this.HighValueMax = fmi.HighValueMax;
            this.LowValue = fmi.LowValue;
            this.HighValue = fmi.HighValue;
            this.InputMin = fmi.InputMin;
            this.InputMax = fmi.InputMax;
            this.Offset = fmi.Offset;
        }
    }
}

export class ContextPointBase implements contracts.ContextPointBase {

    ContextPointId?: number = 0;
    ContextPointName?: string = '';
    ContextPointStatusType?: number = 0;
    ContextPointStatusTypePrettyName?: string = '';
    ContextPointType?: number = 0;
    ContextPointTypePrettyName?: string = '';
    ContextPointSubTypeId?: number = 0;
    ContextPointSubTypeName?: string = '';
    ParentId?: number = 0;
    ParentName?: string = '';
    ParentType?: number = 0;
    ParentTypePrettyName?: string = '';
    ParentSubTypeId?: number = 0;
    ParentSubTypeName?: string = '';
    // Computed at UI
    id?: number = 0;
    display?: string = '';
    value?: string = '';
    checked?: boolean;

    constructor(cpb?: contracts.ContextPointBase) {
        if (cpb) {
            this.ContextPointId = cpb.ContextPointId;
            this.ContextPointName = cpb.ContextPointName;
            this.ContextPointStatusType = cpb.ContextPointStatusType;
            this.ContextPointStatusTypePrettyName = cpb.ContextPointStatusTypePrettyName;
            this.ContextPointType = cpb.ContextPointType;
            this.ContextPointTypePrettyName = cpb.ContextPointTypePrettyName;
            this.ContextPointSubTypeId = cpb.ContextPointSubTypeId;
            this.ContextPointSubTypeName = cpb.ContextPointSubTypeName;
            this.ParentId = cpb.ParentId;
            this.ParentName = cpb.ParentName;
            this.ParentType = cpb.ParentType;
            this.ParentTypePrettyName = cpb.ParentTypePrettyName;
            this.ParentSubTypeId = cpb.ParentSubTypeId;
            this.ParentSubTypeName = cpb.ParentSubTypeName;
            this.id = cpb.ContextPointId;
            this.display = cpb.ContextPointName;
            this.value = cpb.ContextPointName;
        }
    }

}


export class AssetConfig implements contracts.IAssetConfig {
    DataSourceMappingDetails?: Array<DataSourceMappingDetails> = [];
    Identifiers?: Array<Identifier> = [];
    Aliases?: Array<Identifier> = [];
    EffectiveDate?: string = '';
    ContextPointId?: number;
    ContextPointTypeId?: number;
    ContextPointSubTypeId?: number;
    SalesAccount?: SalesAccount = new SalesAccount();
    Product?: Product = new Product();
    Geometry?: Geometry = new Geometry();
    TankDetails?: TankDetails = new TankDetails();
    Name?: string = '';
    Type?: string;
    constructor(as?: contracts.IAssetConfig) {
        Object.assign(this, as);
    }
}
export class SalesAccount implements contracts.ISalesAccount {
    SoldTo?: string = '';
    ShipTo?: string = '';
    constructor(isa?: contracts.ISalesAccount) {
        Object.assign(this, isa);
    }
}
export class Product implements contracts.IProduct {
    Identifiers?: Array<Identifier> = [];
    USDensity?: number = 0;
    PackageDetails?: string = '';
    ProductState?: string = '';
    constructor(p?: contracts.IProduct) {
        Object.assign(this, p);
    }
}
export class Identifier implements contracts.IIdentifier {
    Domain?: string = '';
    Value?: string = '';
    constructor(i?: contracts.IIdentifier) {
        Object.assign(this, i);
    }
}
export class Geometry implements contracts.IGeometry {
    Name?: string = '';
    Volume?: number = 0;
    Area?: number = 0;
    RatedCapacity?: number = 0;
    MaxLevel?: number = 0;
    DimensionsUoM?: string = '';
    VolumeUoM?: string = '';
    InventoryType?: string = '';
    Height?: number = 0;
    Width?: number = 0;
    Depth?: number = 0;
    Diameter?: number = 0;
    MinorRadius?: number = 0;
    constructor(g?: contracts.IGeometry) {
        Object.assign(this, g);
    }
}
export class TankDetails implements contracts.ITankDetails {
    Model?: string = '';
    Material?: string = '';
    MeaZero?: number = 0;
    MeaMin?: number = 0;
    MeaMax?: number = 0;
    CouplingDistance?: number = 0;
    Max?: number = 0;
    Min?: number = 0;
    TankDistUoM?: string = '';
    TankType?: number;
    TankLevels?: TankLevels = new TankLevels();
    constructor(td?: contracts.ITankDetails) {
        Object.assign(this, td);
    }
}
export class Probe implements contracts.IProbe {
    ProbeId?: number = 0;
    Type?: string = '';
    MeaMin?: number = 0;
    MeaMax?: number = 0;
    DistMin?: number = 0;
    DistMax?: number = 0;
    BlockingDist?: number = 0;
    ProbeDistUoM?: string = '';
    PartNumber?: string = '';
    useCustomMinMax?: number = 0;
    constructor(p?: contracts.IProbe) {
        Object.assign(this, p);
    }
}
export class TankLevels implements contracts.ITankLevels {
    OrderLevel?: number = 0;
    SafetyLevel?: number = 0;
    constructor(tl?: contracts.ITankLevels) {
        Object.assign(this, tl);
    }
}
export class InventoryData implements contracts.IInventoryData {
    InventoryConfig?: Array<InventoryConfig> = [];
    InventoryPackageCodes?: Array<InventoryPackageCodes> = [];
    TankInventoryMasterList?: Array<TankInventoryMasterList> = [];
    TankMaterialMasterList?: Array<TankMaterialMasterList> = [];
    TankGeometriesList?: Array<TankGeometriesList> = [];
    TankTypeMasterList?: Array<TankTypeMasterList> = [];
    constructor(id?: contracts.IInventoryData) {
        Object.assign(this, id);
    }
}
export class InventoryConfig implements contracts.IInventoryConfig {
    ContextPointTypeId?: number = 0;
    ContextPointSubTypeId?: number = 0;
    InventoryProperties?: Array<InventoryProperties> = [];
    constructor(ic?: contracts.IInventoryConfig) {
        Object.assign(this, ic);
    }
}

export class InventoryPackageCodes implements contracts.IInventoryPackageCodes {
    PackageCodeId?: number;
    PackageCode?: string = '';
    IsEnable?: boolean;
    Description?: string = '';
    // Computed at UI
    id?: number = 0;
    display?: string = '';
    value?: string = '';
    checked?: boolean;
    constructor(ipc?: contracts.IInventoryPackageCodes) {
        if (ipc) {
            this.PackageCodeId = ipc.PackageCodeId;
            this.PackageCode = ipc.PackageCode;
            this.IsEnable = ipc.IsEnable;
            this.Description = ipc.Description;
            this.id = ipc.PackageCodeId;
            this.display = ipc.PackageCode;
            this.value = ipc.PackageCode;
        }
    }
}
export class TankInventoryMasterList implements contracts.ITankInventoryMasterList {
    TankInventoryName?: string = '';
    TankInventoryValue?: string = '';
    constructor(til?: contracts.ITankInventoryMasterList) {
        Object.assign(this, til);
    }
}
export class TankMaterialMasterList implements contracts.ITankMaterialMasterList {
    Material?: string = '';
    constructor(tml?: contracts.ITankMaterialMasterList) {
        Object.assign(this, tml);
    }
}
export class TankGeometriesList implements contracts.ITankGeometriesList {
    Name?: string = '';
    constructor(tgl?: contracts.ITankGeometriesList) {
        Object.assign(this, tgl);
    }
}
export class TankTypeMasterList implements contracts.ITankTypeMasterList {
    TankTypeId?: number = 0;
    TankTypeName?: string = '';
    ValueId?: number;
    PropertyId?: number;
    ContextPointSubTypeId?: number;
    SAPMaterialCode?: Array<SAPMaterialCode> = [];
    constructor(ttl?: contracts.ITankTypeMasterList) {
        Object.assign(this, ttl);
    }
}
export class SAPMaterialCode implements contracts.ISAPMaterialCode {
    code?: string;
    constructor(smp?: contracts.ISAPMaterialCode) {
        Object.assign(this, smp);
    }
}
export class InventoryProperties implements contracts.IInventoryProperties {
    TankTypeId?: number = 0;
    TankTypeName?: string = '';
    Material?: string = '';
    Status?: boolean;
    Geometry?: Geometry = new Geometry();
    constructor(ip?: contracts.IInventoryProperties) {
        Object.assign(this, ip);
    }
}
export class TankSerialNumber implements contracts.ITankSerialNumber {
    ContextPointId?: number;
    EquipmentId?: string;
    TankSerialNumber?: string;
    SoldTo?: string;
    ShipTo?: string;
    ProductName?: string;
    Description?: string;
    PackageCode?: string;
    SourceSystem?: string;
    SAPDescription?: string;
    ContainerDescription?: string;
    Size?: string;
    MaterialCode?: string;
    ContextPointTypeId?: number;
    ContextPointSubTypeId?: number;
    ProductDbId?: number;
    ProductDensity?: number;
    TankTypeId?: number;
    CreatedOn?: string;
    ChangedOn?: string;
    Last_Update_Date?: string;
    constructor(tsnd?: contracts.ITankSerialNumber) {
        Object.assign(this, tsnd);
    }
}
export class ProductDetails implements contracts.IProductsDetails {
    ProductDbId?: number;
    ProductDensity?: number;
    ProductName?: string;
    // Computed at UI
    id?: number = 0;
    display?: string = '';
    value?: string = '';
    checked?: boolean;
    IsSolid?: boolean;
    constructor(pd?: contracts.IProductsDetails) {
        if (pd) {
            this.ProductDbId = pd.ProductDbId;
            this.ProductDensity = pd.ProductDensity;
            this.ProductName = pd.ProductName;
            this.id = pd.ProductDbId;
            this.display = pd.ProductName;
            this.value = pd.ProductName;
            this.IsSolid = pd.IsSolid;
        }
    }
}
export class soldToShipTo implements contracts.IsoldToShipTo {
    Domain?: string;
    Value?: string;
    constructor(ss?: contracts.IsoldToShipTo) {
        Object.assign(this, ss);
    }
}

export class MeasurementCalculations implements contracts.IMeasurementCalculations {
    CalculationMeasurementId?: string;
    MeasurementName?: string;
    Formula?: string;
    Status?: string;

    constructor(calculation?: contracts.IMeasurementCalculations) {
        this.CalculationMeasurementId = calculation.CalculationMeasurementId;
        this.MeasurementName = calculation.CalculationName;
        this.Formula = calculation.Formula;
        this.Status = calculation.IsConfigured ? 'Configured' : 'Not Configured';
    }
}

export class MeasurementCalculationsDetails implements contracts.IMeasurementCalculationsDetails {
    VariableName?: string;
    Description?: string;
    DataSource?: string;
    Measurement?: string;
    UoM?: string;
    // Source?: string;

    constructor(variableDetails?: contracts.IMeasurementCalculationsDetails) {
        this.VariableName = variableDetails.UoM !== null ? variableDetails.VariableName + ' - (' + variableDetails.UoM + ')' : variableDetails.VariableName;
        this.Description = variableDetails.Description;
        this.DataSource = 'Select Data Source'; // needs to be changed once api is ready
        this.Measurement = 'Select Measurement'; // needs to be changed once api is ready
        this.UoM = variableDetails.UoM;
        // this.Source = variableDetails.Source;
    }
}

export class MapMeasurement {
    DagContextPointId?: number;
    CategoryId?: number;
    ContextPointType?: string;
    AssetContextPointSubTypeId?: number;
    AssetContextPointSubTypeName?: string;
    AssetContextPointTypePrettyName?: string;
    AssetContextPointId?: number;
    AssetContextPointName?: string;
    MeasurementContextPointMaps: Array<MeasurementContextPointMaps> = [];
    AssetConfig?: {};

    constructor(measurement?: contracts.IMapMeasurement) {
        this.DagContextPointId = 0;
        this.CategoryId = 0;
        this.ContextPointType = '';
        this.AssetContextPointSubTypeId = 0;
        this.AssetContextPointSubTypeName = '';
        this.AssetContextPointTypePrettyName = '';
        this.AssetContextPointId = 0;
        this.AssetContextPointName = '';
    }
}

export class MeasurementContextPointMaps {
    FromMeasurementInstanceId: number;
    ToMeasurementInstanceId: number;
    ToMeasurementId: number;
    ToMeasurementName: string;
    ToStuiTypeId: number;
    ToStuiId: number;
    ToContextPointId: number;
    ToIncomingInstance: string;
    ToMeasurementSource: string;
    ToSourceId: number;
    ToSourceIdToSave?: number;
    ToMeasurementSourceToSave?: string;
    ToContextPointTypeId?: number;
    ToContextPointSubTypeId?: number;
    ToDisplayName?: string;
    ToUom: string;

    constructor(measurement?: contracts.IMeasurementContextPointMaps) {
        this.FromMeasurementInstanceId = measurement.FromMeasurementInstanceId;
        this.ToMeasurementInstanceId = measurement.ToMeasurementInstanceId;
        this.ToMeasurementId = measurement.ToMeasurementId;
        this.ToMeasurementName = measurement.ToMeasurementName;
        this.ToContextPointId = measurement.ToContextPointId;
        this.ToIncomingInstance = measurement.ToIncomingInstance;
        this.ToSourceId = measurement.ToSourceId;
        this.ToMeasurementSource = measurement.ToMeasurementSource;
        this.ToSourceIdToSave = measurement.ToSourceIdToSave;
        this.ToMeasurementSourceToSave = measurement.ToMeasurementSourceToSave;
        this.ToContextPointTypeId = measurement.ToContextPointTypeId;
        this.ToContextPointSubTypeId = measurement.ToContextPointSubTypeId;
        this.ToDisplayName = measurement.ToDisplayName;
    }
}

export class DataSourceMappingDetails implements contracts.IDataSourceMappingDetails {
    ContextPointId?: number;
    ContextPointType?: string;
    ContextPointSubType?: string;
    ToMeasurementInstanceId?: number;
    Identifiers?: Array<Identifier> = [];
    DataSourceReadingsDetail?: DataSourceReadingsDetail = new DataSourceReadingsDetail();
    Probe?: Probe = new Probe();

    constructor(dataSourceMapping?: contracts.IDataSourceMappingDetails) {
        if (dataSourceMapping) {
            this.ContextPointId = dataSourceMapping.ContextPointId;
            this.ContextPointType = dataSourceMapping.ContextPointType;
            this.ContextPointSubType = dataSourceMapping.ContextPointSubType;
            this.ToMeasurementInstanceId = dataSourceMapping.ToMeasurementInstanceId;
            if (dataSourceMapping.Identifiers) {
                dataSourceMapping.Identifiers.forEach(identifier => {
                    this.Identifiers.push(new Identifier(identifier));
                });
            }
            this.DataSourceReadingsDetail = new DataSourceReadingsDetail(dataSourceMapping.DataSourceReadingsDetail)
        }
    }
}

export class DataSourceReadingsDetail implements contracts.IDataSourceReadingsDetail {
    TagName?: string = '';
    SourceUoM?: string = '';
    AssetReadingsDetails?: Array<AssetReadingsDetails> = []
    SourceMeasurement?: string = '';

    constructor(dataSourceReading?: contracts.IDataSourceReadingsDetail) {
        if (dataSourceReading) {
            this.TagName = dataSourceReading.TagName;
            this.SourceUoM = dataSourceReading.SourceUoM;
            this.SourceMeasurement = dataSourceReading.SourceMeasurement;

            if (dataSourceReading.AssetReadingsDetails) {
                dataSourceReading.AssetReadingsDetails.forEach(reading => {
                    this.AssetReadingsDetails.push(new AssetReadingsDetails(reading));
                });
            }
        }
    }
}

export class AssetReadingsDetails implements contracts.IAssetReadingsDetails {
    DesiredCalculation?: string;
    DesiredMeasurement?: string;

    constructor(assetReadingDetail?: contracts.IAssetReadingsDetails) {
        if (assetReadingDetail) {
            this.DesiredCalculation = assetReadingDetail.DesiredCalculation;
            this.DesiredMeasurement = assetReadingDetail.DesiredMeasurement;
        }
    }
}

export class AssetOperationalStatus implements contracts.IAssetOperationalStatus {
    DagContextPointId?: number;
    SiteName?: string;
    AtbAssetProperty?: AtbAssetProperty = new AtbAssetProperty();
    OfflineConfiguration?: OfflineConfiguration = new OfflineConfiguration();

    constructor(as?: contracts.IAssetOperationalStatus) {
        if (as) {
            this.DagContextPointId = as.DagContextPointId;
            this.SiteName = as.SiteName;
            this.AtbAssetProperty = new AtbAssetProperty(as.AtbAssetProperty);
            this.OfflineConfiguration = new OfflineConfiguration(as.OfflineConfiguration);
        }
    }
}