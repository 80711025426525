<div class="config-popup" [style.width.px]="width" [style.margin-left.rem]="left">
  <div class="modal-header">
    <h4 class="modal-title" translate>{{HeaderTitle}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
      <img src="../../../../assets/images/modal-close-icon.svg" alt="close" />
    </button>
  </div>
  <div class="mb-0 delete-content">
    <nalco-loader [loader]="isLoading" [transparentBG]="true" [fitToScreen]="true"></nalco-loader>
    <div class="account-name modal-header">
      <span> {{'CONTROLLER_MANAGEMENT.SERIAL_NO' | translate}} : {{SerialNo}} </span> <br/>
      <span> {{'CONTROLLER_MANAGEMENT.LAST_FILE_RECEIVED' | translate}} : {{LastDataReceived | date : "yyyy-MM-dd h:mm:ss"}}</span>
    </div>
   </div>
       <div class="mb-0 delete-content">
        <div class="account-name modal-header">
          {{'CONTROLLER_MANAGEMENT.PARAMETER_NAME' | translate}} : {{ParameterName}}&nbsp;{{displayUnit}}
          <span *ngIf="isChartPlotted">&nbsp;&nbsp;&nbsp;&nbsp; {{StartOn}} to {{EndBefore}}
            &nbsp;&nbsp;&nbsp;&nbsp;</span>
        <span *ngIf="isChartPlotted">
          <span  translate>CONTROLLER_MANAGEMENT.VIEWEDBY</span>
          <span>{{duration}}&nbsp;Days </span>&nbsp;&nbsp;&nbsp;&nbsp;
        <span class="k-icon k-i-calendar"></span><a href="javascript:void(0)" (click)="viewDateFilters(content)"  translate>CONTROLLER_MANAGEMENT.CUSTOME_DATES</a>
       </span> 
   </div>
   </div>
    <ng-template #content let-modal>
    <img src="../../../../assets/images/modal-close-icon.svg" 
      style=" width: 2%; position: absolute; margin-left: 33rem;margin-top: 0.5rem;" 
      (click)="close()" alt="close" />
    <div class="mb-3 select-filter ">
    <div class="filter-accordion">
    <div class="range-content">
      <div class="selectFilterContent p-3">
        <form style="width: 67rem !important; margin-top: 1rem;">
          <div class="row form-inline mx-auto">
            <div class="col-lg-6 col-sm-12 custom">
              <custom-date-range-dropdown (updateDate)='updateDates($event)' [service]="'GF'"
                [customLabelValue]="'CONTROLLER_MANAGEMENT.VIEW_BY'" [defaultSelection]="'4'">
              </custom-date-range-dropdown>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
    <div class="modal-footer">
      <button type="button"  (click)="applyDateFilter()"  class="btn btn-primary">{{'CONTROLLER_MANAGEMENT.APPLY'| translate}}</button>
    </div>
  </ng-template> 
   <div class="table-wrapper" #accountResults infiniteScroll [infiniteScrollContainer]="accountResults"
    [infiniteScrollDistance]="1"  [infiniteScrollThrottle]="500"   [scrollWindow]="false">
      <div class="trendChart" id="TrendChart">
        <div id="chart-System-health" width="100vw" height="95%" style='min-height:auto'></div>
      </div>
    </div>
</div>
