export const ROTabs = ['OperatingParameter', 'WaterChemistry', 'DosageAndRecommendation'];

export const Units = ['SI', 'US'];

export const WaterAnalysisType = 'WaterAnalysisType';

export const WaterChemistry = 'WaterChemistry';

export const WaterChemistryTypes = ['RawWater', 'Concentrate'];

export const RecoveryUnits = ['percent', 'ratio'];

export const TemperatureUnits = ['kelvin', 'Fahrenheit', 'Celsius'];

export const WaterAnalysisTypes = ['Brackish', 'Seawater', '2nd Pass Seawater'];

export const pHAcids = ['HCl (31%)', 'H2SO4 (98%)'];

export const pHBases = ['NaOH (50%)'];

export const ChargeBalanceInput = [
    {
        name: "pH",
        type: "System.Double",
    },
    {
        name: "Temperature",
        type: "Nalco.Numerics.SI.Temperature",
    },
    {
        name: "FlowRate",
        type: "Ecolab.Modeling.Species.VolumeRate",
    },

];

export const ConversionSource = 'Species';

export const SaveSuccessResponse = ['OK', 'Created'];

export const VALID = 'VALID';

export const ChartDataNames = ['Untreated Values', 'Treated Values'];

export const BarColor = ['#EEAF00', '#5EB6E4'];

export const LabelFormat = '{value}%';

export const ToolTipColors = ['white', 'black'];

export const CalculatedParameters = [
    {
        wcType: 'RawWater',
        Parameters: ['PermeateFlow','ConcentrateFlow']
    },
    {
        wcType: 'Concentrate',
        Parameters: ['PermeateFlow','RawWaterFlow']
    }    
]