
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { AppEnvConfig } from '../../../app.env.config';
import { UnitUsageType } from '../_enum/constants';
import { WaterFlowIntelligenceAdminServiceConfig } from './water-flow-intelligence-admin.service.config';
declare var ADRUM: any;

@Injectable()
export class WaterFlowIntelligenceAdminService extends BehaviorSubject<GridDataResult> {
  public loading: boolean;
  private configEnv: any;
  private _refreshSubject = new Subject<any>();
  url: string;
  contextPoint: number;
  dagType: string;
  checkMaxDags: string;
  contextPointId: number;
  flowUnits;
  volumeUnits;
  temperatureUnits;
  constructor(
    private WaterFlowIntelligenceAdminServiceConfig: WaterFlowIntelligenceAdminServiceConfig,
    private httpClient: HttpClient,
    private config: AppEnvConfig,
  ) {
    super(null);
    this.configEnv = WaterFlowIntelligenceAdminServiceConfig.getEnvironment();
    this.configEnv.apiServerUrl = config.getEnv('apiServerUrl');
    this.configEnv.apiWaterServerUrl = config.getEnv('apiWaterServerUrl');
    this.configEnv.apiServerUrlAuth = config.getEnv('apiServerUrl');
    this.configEnv.contextPoint = 0;
    this.dagType = 'NalcoWater/';
    this.checkMaxDags = '/false/';
  }

  public getCalculationDays(): Observable<any> {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getRecalculationDays);
    return this.httpClient.get(url).map(response => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }

  public getSensorMappingData(macAddress): Observable<any> {

    const url = this.parse(this.configEnv.apiWaterServerUrl + this.configEnv.apiUrl.getSensorMapping,
      [macAddress.toString()]);
    return this.httpClient.get(url).map(response => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }

  public getSiteSensorMaps(sensorId: number): Observable<any> {
    const url = this.parse(this.configEnv.apiWaterServerUrl + this.configEnv.apiUrl.getSiteSensorMaps + sensorId.toString());
    return this.httpClient.get(url).map(response => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }

  public getsites(dropDownPaging): Observable<any> {
    const url = this.configEnv.apiServerUrl + this.configEnv.apiUrl.getsites;
    const body = JSON.stringify(dropDownPaging);
    return this.httpClient.post(url, body).map(response => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }

  public getFlowMetersData(): Observable<any> {

    const url = this.parse(this.configEnv.apiWaterServerUrl + this.configEnv.apiUrl.getFlowMeters);
    return this.httpClient.get(url).map(response => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }

  public saveSensorMappingForm(formData: any): Observable<any> {
    const url = this.parse(this.configEnv.apiWaterServerUrl + this.configEnv.apiUrl.saveSensorMappingForm);
    const body = JSON.stringify(formData);

    return this.httpClient.post(url, body).map(response => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });

  }

  public postConversionByUnitType(obj): Observable<any> {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.postConversionByUnitType);
    const body = JSON.stringify(obj);

    return this.httpClient.post(url, body).map(response => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }

  public getUnitUsageById(unitType: number): Observable<any> {
    this.url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getUnitUsageById + unitType.toString());
    return this.httpClient.get(this.url).map((response: any) => {
      if (unitType === UnitUsageType.Flow) {
        this.flowUnits = response;
      } else if (unitType === UnitUsageType.Volume) {
        this.volumeUnits = response;
      } else if (unitType === UnitUsageType.Temperature) {
        this.temperatureUnits = response;
      }
    });
  }

  private parse(url: string, params: string[] = []): string {
    let result: string = url;
    if (params.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      result = url.replace(/\{(\d+)\}/g, function (match: string, current: string): string {
        return params[parseInt(current, 10)] !== undefined ? params[parseInt(current, 10)] : '';
      });
    }
    return result;
  }

}


