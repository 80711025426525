import { NgModule } from "@angular/core";
import { BreadcrumbComponent } from './breadcrumb.component';
import { SubheaderHandlerService } from "../../core/nav/subheader-handler.service";
import { CommonModule } from "@angular/common";
import { NgbModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
    imports: [
        CommonModule,
        NgbTooltipModule
    ],
    declarations: [BreadcrumbComponent],
    exports: [BreadcrumbComponent],
    providers: [ SubheaderHandlerService]
  })
  export class BreadcrumbModule { }