import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { LocalCacheService } from '../../../core/local-cache/local-cache.service';
import { SystemAssuranceAdminService } from '../../../system-assurance-admin/_services/system-assurance-admin.service';
import { IDAGSites } from '../../contracts/IDAGSites';
import { SiteService } from '../../services/site.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'nalco-auto-complete-dropdown',
  templateUrl: './auto-complete-dropdown.component.html',
  styleUrls: ['./auto-complete-dropdown.component.scss'],
})
export class AutoCompleteDropdownComponent implements OnInit {
  @Output() ddChange = new EventEmitter<IDAGSites>(); // Emits the events when the dropdown options are selected
  @Output() resetData = new EventEmitter<boolean>(); // Emits the event when cross in the search bar is clicked, search bar is 
  //made empty or search is enabled(used specifically for - when a site is removed partially)  
  private _clearNow = false;
  get clearNow() {
    return this._clearNow;
  }
  private _defaultDisplay = '';
  get defaultDisplay() {
    return this._defaultDisplay;
  }

  @Input()
  set clearNow(value) {
    if (value == true) {
      this.siteName = '';
      this.queryField.setValue([]);
      this.siteService.setFilterSite(null);
    }
    this._clearNow = value != null ? value : false;
  }

  @Input()
  set defaultDisplay(value) {
    if (value) {
      this._defaultDisplay = value;
      this.siteName = value;
    }
  }
  @Input()
  originService: string;
  @Input() searchId = 0;
  @Input() crossIconFlag: boolean = false;
  @Input() leaveSite: boolean = false;
  @Input() showSiteName: boolean = true;

  public serviceName: any;
  public siteName: string;
  public data: Array<IDAGSites>;
  public isLoading = false;
  public queryField: UntypedFormControl = new UntypedFormControl();
  public noRecordsMsg = false;
  public loaderFlag: boolean = false;
  public caMsg = false;
  public searchText = '';
  @ViewChild('inputRef', { static: false }) inputRef;
  constructor(
    @Inject(SiteService)
    private siteService: SiteService,
    private localCacheService: LocalCacheService,
    private systemAssuranceAdminService: SystemAssuranceAdminService
  ) { }

  ngOnInit() {
    if (window.location.href.includes("omni"))
      this.serviceName = "omni";
    this.getSearchResults();
    this.siteName = this.defaultDisplay;
    this.systemAssuranceAdminService.$leaveSite.subscribe(leaveSite => {
      if (leaveSite) {
        this.data = [];
        this.siteName = '';
        this.queryField.setValue([]);
        if (this.searchText != '') {
          this.searchText = '';
        }
      }
    });
  }

  getSearchResults() {
    this.queryField.valueChanges.pipe(
      debounceTime(700)
      ,distinctUntilChanged())
      .subscribe(queryField => {
        if (queryField.length > 2) {// eslint-disable-next-line no-debugger
          this.loaderFlag = true;
          if (this.crossIconFlag)
            this.resetData.emit(true);
          this.siteService.getSitesList(queryField, this.originService, this.searchId).subscribe(sites => {
            if (this.serviceName == "omni") {
              //checking CA filter selection
              let caFlagCheck = this.localCacheService.fetchData("corpFlag");
              if (caFlagCheck == 1) {
                let fetchedCorporateAccount = this.localCacheService.fetchData("baseCorporateAccount");
                let filterdArray = sites.filter(e => e.CorpAccountId == fetchedCorporateAccount)
                if (sites.length <= 0) {
                  this.noRecordsMsg = true;
                } else if (sites.length > 0 && (filterdArray.length === 0)) {
                  this.caMsg = true;
                } else {
                  this.noRecordsMsg = false;
                }
                this.loaderFlag = false;
                return filterdArray;

              } else {
                this.data = sites;
                if (this.data.length === 0) {
                  this.noRecordsMsg = true;
                } else {
                  this.noRecordsMsg = false;
                }
                this.loaderFlag = false;
                return this.data;
              }

            } else {
              this.data = sites;
              if (this.data.length === 0) {
                this.noRecordsMsg = true;
              } else {
                this.noRecordsMsg = false;
              }
              this.loaderFlag = false;
              return this.data;
            }
          });
        } else {
          this.loaderFlag = false;
          this.noRecordsMsg = false;
          return this.data = [];
        }
      });
  }

  changeInDropdown(site: IDAGSites) {
    this.data = [];
    this.siteName = site.SiteName;
    this.ddChange.emit(site);
    if (!this.showSiteName) {
      this.queryField.setValue([]);
    }
    this.siteService.setFilterSite(site);
  }

  onClickOutside(event) {
    if (event && event['value'] === true) {
      this.noRecordsMsg = false;
      this.caMsg = false;
      this.data = [];
    }
  }

  onSearchChange(searchValue: string) {
    this.searchText = searchValue;
    if (searchValue === '') {
      this.siteName = searchValue;
      this.resetData.emit(true);
    }
  }

  clearSearchData() {
    if (this.crossIconFlag) {
      this.resetData.emit(true);
    } else {
      if (this.searchText != '') {
        this.searchText = '';
      }
      this.inputRef.nativeElement.value = '';
      this.siteName = '';
    }
  }
}
