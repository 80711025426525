import { ISalesRegionDistrictTerritories } from '../contracts/ISalesRegionDistrictTerritories';

export class SalesRegionDistrictTerritories implements ISalesRegionDistrictTerritories {
    ParentId: number;
    ParentName: string;
    ParentType: number;
    ParentTypePrettyName: string;
    ParentSubTypeId: number;
    ParentSubTypeName: string;
    ContextPointId: number;
    ContextPointName: string;
    ContextPointStatusType: number;
    ContextPointStatusTypePrettyName: string;
    ContextPointType: number;
    ContextPointTypePrettyName: string;
    ContextPointSubTypeId: number;
    ContextPointSubTypeName: string;

    constructor(SalesList: ISalesRegionDistrictTerritories) {
        this.ParentId = SalesList.ParentId;
        this.ParentName = SalesList.ParentName;
        this.ParentType = SalesList.ParentType;
        this.ParentTypePrettyName = SalesList.ParentTypePrettyName;
        this.ParentSubTypeId = SalesList.ParentSubTypeId;
        this.ParentSubTypeName = SalesList.ParentSubTypeName;
        this.ContextPointId = SalesList.ContextPointId;
        this.ContextPointName = SalesList.ContextPointName;
        this.ContextPointStatusType = SalesList.ContextPointStatusType;
        this.ContextPointStatusTypePrettyName = SalesList.ContextPointStatusTypePrettyName;
        this.ContextPointType = SalesList.ContextPointType;
        this.ContextPointTypePrettyName = SalesList.ContextPointTypePrettyName;
        this.ContextPointSubTypeId = SalesList.ContextPointSubTypeId;
        this.ContextPointSubTypeName = SalesList.ContextPointSubTypeName;
    }
}
