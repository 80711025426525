import { Injectable } from '@angular/core';
import { LocalCacheService } from '../../../core/local-cache/local-cache.service';
import { UserService } from '../../user/user.service';

@Injectable()
export class AuthorizationService {

  constructor(private localCacheService: LocalCacheService, private userService: UserService) {}

  isAuthorized(allowedRoles: string[]): boolean {

    if (allowedRoles == null || allowedRoles.length === 0) {
      return true;
    }

    let userSettings = JSON.parse(this.localCacheService.fetchData('UserSettings'));

    // If no user settings are available, return false value.
    if (!userSettings) {
      // console.log('Invalid userSettings');
      userSettings = this.userService.getUserSettings(); // Bug 238088 :- To get the USer Settings from Session Storage
    }

    if (userSettings) {
      let userPermissions = [];

      userSettings.Permissions.forEach(function (s) {
        userPermissions.push(s.PermissionId.toString());
        // console.log(s);
      })

      // If user role is in the list of allowed roles, return true value
      let userPermissionsValues = Object.values(userPermissions);

      for (const value of userPermissionsValues) {
        if (allowedRoles.includes(value)){
          return true;
        }
      }
    } else {
      return false;
    }
  }
}
