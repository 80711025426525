import { Injectable } from '@angular/core';
import { ENVIRONMENTS, APP_CONFIG } from '../../app.config';

@Injectable()
export class ControllerManagementServiceConfig {
  private configMock: any = {// MOCK - local json settings
    environment: ENVIRONMENTS.mock,
    baseUrl: '',
    apiUrl: {
      getsites: 'SitePagingByUser',
      getProvisionedControllers: 'api/v1/ControllerRepository/ProvisionedControllersBySiteId',
      getProvisionedControllersBySerialNumber:'api/v1/ControllerRepository/ProvisionedControllersBySerialNumber',
      getControllerTypesBySiteId : 'api/v1/ControllerRepository/ControllerTypesBySiteId',
      getUnProvisionedControllers :'api/v1/ControllerRepository/GetUnProvisionedControllersBySiteId',
      downloadConfiguration : 'api/v1/ControllerRepository/downloadConfiguration',
      getRHMIUri: 'api/v1/ControllerRepository/GetControllerRemoteHMIUri',
      getGeneratedPassword: 'api/v1/PasswordOfTheDay/GeneratePassword',
      getFileConfigurations:'api/v1/ControllerRepository/GetProvisionedConfigurationFilesBySerialNumber',
      downloadProvisionedConfigurationFile : 'api/v1/ControllerRepository/DownloadProvisionedConfigurationFile',
      getControllerSoftwareVersions : 'api/v1/ControllerRepository/GetControllerSoftwareVersions',
      getActiveControllerList : 'api/v1/SUU/GetActiveControllerList',
      getTargetVersionList : 'api/v1/SUU/GetTargetVersionList',
      downloadControllerSoftwareVersionsFile : 'api/v1/ControllerRepository/DownloadControllerSoftwareVersionsFile',
      upgradeController : 'api/v1/SUU/UpgradeController',
      getSystemHealthParameters : 'api/v1/ControllerRepository/GetSystemHealthParameters',
      getParameterTrendData: 'api/v1/ControllerRepository/GetParameterTrendData',
      getGatewayStatisticsParameters : 'api/v1/ControllerRepository/GetGatewayStatisticsParameters',
      getDataDownloadForContextPoint: 'api/v1/ContextPoints/DataDownloadForContextPoint',
      getAlarmLists: 'api/v1/ControllerRepository/GetAlarmLists',
      getHardwareDetails : 'api/v1/ControllerRepository/GetHardwareDetails',
      getDataSourceSerialNumbers : 'api/v1/ControllerRepository/GetDataSourceSerialNumbers',
      getDataAggregatorReport : 'api/v1/SUU/GetDataAggregatorReport',
      downloadReleaseNoteForSoftwareVersion : "api/v1/ControllerRepository/DownloadReleaseNoteForSoftwareVersion",
      getSoftwareUpgradeReport : 'api/v1/SUU/GetSoftwareUpgradeReport',
      getCassadyVersionMapping : 'api/v1/ControllerRepository/GetCassadyVersionMapping'
    },
  };

  private configProd: any = {// PRODUCTION - PRODUCTION ENVIRONMENT SETTINGS
    environment: ENVIRONMENTS.prod,
    baseUrl: '',
    apiUrl: {
      getsites: 'SitePagingByUser',
      getProvisionedControllers:'api/v1/ControllerRepository/ProvisionedControllersBySiteId',
      getProvisionedControllersBySerialNumber:'api/v1/ControllerRepository/ProvisionedControllersBySerialNumber',
      getControllerTypesBySiteId : 'api/v1/ControllerRepository/ControllerTypesBySiteId',
      getUnProvisionedControllers :'api/v1/ControllerRepository/GetUnProvisionedControllersBySiteId',
      downloadConfiguration : 'api/v1/ControllerRepository/downloadConfiguration',
      getRHMIUri: 'api/v1/ControllerRepository/GetControllerRemoteHMIUri',
      getGeneratedPassword: 'api/v1/PasswordOfTheDay/GeneratePassword',
      getFileConfigurations:'api/v1/ControllerRepository/GetProvisionedConfigurationFilesBySerialNumber',
      downloadProvisionedConfigurationFile : 'api/v1/ControllerRepository/DownloadProvisionedConfigurationFile',
      getControllerSoftwareVersions : 'api/v1/ControllerRepository/GetControllerSoftwareVersions',
      getActiveControllerList : 'api/v1/SUU/GetActiveControllerList',
      getTargetVersionList : 'api/v1/SUU/GetTargetVersionList',
      downloadControllerSoftwareVersionsFile : 'api/v1/ControllerRepository/DownloadControllerSoftwareVersionsFile',
      upgradeController : 'api/v1/SUU/UpgradeController',
      getSystemHealthParameters : 'api/v1/ControllerRepository/GetSystemHealthParameters',
      getParameterTrendData: 'api/v1/ControllerRepository/GetParameterTrendData',
      getGatewayStatisticsParameters : 'api/v1/ControllerRepository/GetGatewayStatisticsParameters',
      getDataDownloadForContextPoint: 'api/v1/ContextPoints/DataDownloadForContextPoint',
      getHardwareDetails : 'api/v1/ControllerRepository/GetHardwareDetails',
      getAlarmLists: 'api/v1/ControllerRepository/GetAlarmLists',
      getDataSourceSerialNumbers : 'api/v1/ControllerRepository/GetDataSourceSerialNumbers',
      getDataAggregatorReport : 'api/v1/SUU/GetDataAggregatorReport',
      downloadReleaseNoteForSoftwareVersion : "api/v1/ControllerRepository/DownloadReleaseNoteForSoftwareVersion",
      getSoftwareUpgradeReport : 'api/v1/SUU/GetSoftwareUpgradeReport',
      getCassadyVersionMapping : 'api/v1/ControllerRepository/GetCassadyVersionMapping'
    },
  };

  private _environment: string = APP_CONFIG.ENVIRONMENT;

  constructor() {
    // void
  }

  public getEnvironment(): any {
    switch (this._environment) {
      case ENVIRONMENTS.mock:
        return this.configMock;
      case ENVIRONMENTS.dev:
        return this.configProd;
      case ENVIRONMENTS.test:
        return this.configProd;
      case ENVIRONMENTS.stg:
        return this.configProd;
      case ENVIRONMENTS.prod:
        return this.configProd;
      default:
        return null;
    }
  }

  public setEnvironment(environment: string): any {
    this._environment = environment;
  }
}




