<div class="row mx-auto time-data">
        <span  class="validation-message" *ngIf="invalidTimeRangeMsg">{{invalidTimeRangeMsg}}</span>
        <div class="select-Range col-lg-3 col-xs-12 col-sm-12 px-0">
                <label>{{'DATA_VISUALIZATION.TIME_RANGE.SELECT_TIME_RANGE' | translate}}</label>
                <nalco-select-date-range [isSelected]="selectedTimeRange" [service]="service" (updateDateData)="dateUpdate($event)"
                        (updateDate)="dataRangeDropDownChange($event)"></nalco-select-date-range>
        </div>
        <div class="custome-Frame col-lg-9 px-3">
                <p [ngClass]="customDatetext ? 'd-inline-block' : ''">{{'DATA_VISUALIZATION.TIME_RANGE.CUSTOM_TIME_FRAME' | translate}}</p>
                <p *ngIf="customDatetext" class="display-text d-inline-block">{{'DATA_VISUALIZATION.TIME_RANGE.CUSTOM_TIME_MESSAGE' | translate}}</p>
                <div class="custom col-lg-6 col-sm-12 col-xs-12 col-md-12 px-0">

                        <span class="position-relative pr-3 col-sm-6 col-xs-6 col-md-6 px-0">
                                <p class="start-label">{{'DATA_VISUALIZATION.TIME_RANGE.START_DATE' | translate}}</p>
                                <kendo-datepicker placeholder="*" [(value)]="sDate" [format]="'MM/dd/yyyy'" #element3
                                        placement='bottom'
                                        [ngbTooltip]="((element3N?.offsetWidth + 1) < element3N?.scrollWidth) ? (sDate | date: 'MM/dd/yyyy') : '' "
                                        (valueChange)="startNEndDate('startDate', $event);"
                                        [popupSettings]="{popupClass: 'popupClass', appendTo: 'root'  }">
                                        <kendo-datepicker-messages
                                                today="{{'DATA_VISUALIZATION.TIME_RANGE.TODAY' | translate}}">
                                        </kendo-datepicker-messages>
                                </kendo-datepicker>
                        </span>

                        <span class="position-relative col-sm-6 col-xs-6 col-md-6 px-0">
                                <nalco-dropdown [ddData]="selectStartTimeData"
                                        (ddChange)="startNEndTime($event, 'startTime')">
                                </nalco-dropdown>
                                <p class="dataText">{{'DATA_VISUALIZATION.TIME_RANGE.START_OF_HOUR' | translate}}</p>
                        </span>
                </div>
                <div class="custom col-lg-6 col-sm-12 col-xs-12 col-md-12 px-0">
                        <span class="position-relative pr-3 col-sm-6 col-xs-6 col-md-6 px-0">
                                <p class="start-label">{{'DATA_VISUALIZATION.TIME_RANGE.END_DATE' | translate}}</p>

                                <kendo-datepicker placeholder="*" [format]="'MM/dd/yyyy'" [(value)]="eDate" #element4
                                        placement='bottom'
                                        [ngbTooltip]="((element4N?.offsetWidth + 1) < element4N?.scrollWidth) ? (eDate | date: 'MM/dd/yyyy') : '' "
                                        [popupSettings]="{popupClass: 'popupClass', appendTo: 'root'  }"
                                        (valueChange)="startNEndDate('endDate', $event);">
                                        <kendo-datepicker-messages
                                                today="{{'DATA_VISUALIZATION.TIME_RANGE.TODAY' | translate}}">
                                        </kendo-datepicker-messages>
                                </kendo-datepicker>
                        </span>
                        <span class="position-relative col-sm-6 col-xs-6 col-md-6 px-0">
                                <nalco-dropdown [ddData]="selectEndTimeData"
                                        (ddChange)="startNEndTime($event, 'endTime')"></nalco-dropdown>
                                <p class="dataText">{{'DATA_VISUALIZATION.TIME_RANGE.END_OF_HOUR' | translate}}</p>
                        </span>
                </div>
        </div>
</div>