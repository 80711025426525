<div class="global-error-page">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="error-code">{{statusCode}}</div>
        <div class="error-main-msg">{{ 'COMMON.SOMETHING_WENT_WRONG' | translate }}</div>
        <div class="details-msg" [innerHTML]="statusCodeMsg | translate"></div>
        <div  *ngIf="isRefreshShow" class="details-msg top-margine-msg" >
        <a (click)="reloadPage()" class="fw-bold cursor-pointer" >
          {{ 'COMMON.REFRESH_MESSAGE_LINK' | translate }}</a>
          {{ 'COMMON.REFRESH_MESSAGE_1' | translate }}</div>
          <div *ngIf="!isRefreshShow" class="details-msg top-margine-msg" >
              {{ 'COMMON.REFRESH_MESSAGE_2' | translate }}</div>
        <div class="d-flex justify-content-center">
          <div class="mx-2">
            <nalco-button [buttonData]="buttonSecondary" (click)="backToHomePage()"></nalco-button>
          </div>
          <div class="mx-2">
            <nalco-button [buttonData]="buttonPrimaryLeft" (click)="backToPreviousPage()"></nalco-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>