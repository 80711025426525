import { Component, OnInit, Input, Output, EventEmitter, ApplicationRef, ChangeDetectorRef } from '@angular/core';
import { NgbModal, NgbActiveModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/delay';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'nalco-configuration-popup',
  templateUrl: './configuration-popup.component.html',
  styleUrls: ['./configuration-popup.component.scss']
})
export class ConfigurationPopupComponent implements OnInit {
  @Input() alertIcon: boolean = false;
  @Input() modalText: string; // Defines the title of the modal
  @Input() modcontent: string; // Defines the content of the modal
  @Input() contentLink?: boolean = false; //
  @Input() disablebtn: boolean; // Defines the enable/disable of the button
  @Input() closeIcon?: boolean = false; // Defines the close icon
  @Input() closeAsCancel?: boolean = false; // Defines if the close icon button behave like the cancel button cancellation
  @Output('cancelModal') cancelModal = new EventEmitter<any>(); // Emits the cancel event of the modal
  @Output('submitModal') submitModal = new EventEmitter<any>();
  @Output() deleteModal = new EventEmitter<any>(); // Emits the delete event of the modal
  @Input() cancelBtn: boolean;
  @Input() submitBtn: string; // Defines the content of the button
  @Input() closeBtn: string; // Defines the content of the button

  faqURL = 'https://ecolab.sharepoint.com.mcas.ms/sites/CORE-Digital/SitePages/ECOLAB3D%20Inventory%20FAQs.aspx';
  treeURL = 'https://ecolab.sharepoint.com.mcas.ms/sites/CORE-Digital/Shared%20Documents/Forms/KCLibrary.aspx?id=%2Fsites%2FCORE%2DDigital%2FShared%20Documents%2FInventory%20Decision%20Trees%2Epdf&parent=%2Fsites%2FCORE%2DDigital%2FShared%20Documents';
  
  constructor(public activeModal: NgbActiveModal,
    private translate: TranslateService) {}

  ngOnInit() {}
  
  closeConfigModal() {
    this.cancelModal.emit(false);
    this.activeModal.close();
  }
  proceedModal() {
    this.submitModal.emit({});
    this.activeModal.close();
  }
  delete() {
    this.deleteModal.emit({});
    this.activeModal.close();
  }
  openDocument(data) {
    if(data == 'tree'){
      window.open(this.treeURL);
    } else if(data == 'faq'){
      window.open(this.faqURL);
    }
  }
}
