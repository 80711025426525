import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs';
import { ControllerManagementService } from '../../../controller-management/_services/controller-management.service';
import { LocalCacheService } from '../../../core/local-cache/local-cache.service';
import { ErrorHandling } from '../error-handling/error-handling';
import { XdComponentState } from '../xd-container/xd-component';

@Component({
  selector: 'nalco-alarm-list',
  templateUrl: './alarm-list.component.html',
  styleUrls: ['./alarm-list.component.scss']
})
export class AlarmListComponent extends XdComponentState implements OnInit {
  @Input() SerialNo = '';
  @Input() LastDataReceived;
  @Input() ControllerCpId = '';
  isLoading: boolean = false;
  subscription: Subscription;
  errorHandling = new ErrorHandling(this.modalService, this.translate);
  errorInfo: any;
  isError = false;
  alarmList = [];
  viewData: GridDataResult;
  modalRef: any;

  constructor(private translate: TranslateService,
    public activeModal: NgbActiveModal,
    public controllerManagementService: ControllerManagementService,
    private localCacheService: LocalCacheService,
    private modalService: NgbModal,
    private router: Router) {
    super()
  }

  ngOnInit() {
    this.getAlarmLists();
  }

  getAlarmLists() {
    this.isLoading = true;
    this.controllerManagementService.getAlarmLists(this.ControllerCpId).subscribe(response => {
      if (response) {
        this.alarmList.push(response);
        this.viewData = {
          data: this.alarmList[0],
          total: this.alarmList[0].length,
        };
        this.isLoading = false;
      }
    }, error => {
      this.isLoading = false;
      this.errorInfo = error;
      if (500 <= error.status && error.status < 600) {
      } else if (error.status === 417) {
        this.router.navigate(['/access-denied']);
      } else if (error.status === 404) {
        this.router.navigate(['/**']);
      } else {
        this.errorHandling.showErrorPopup(error);
      }
    });
  }
}
