<div class="user-profile-outer-div">
    <div class="inner-div" [ngClass]="[isHomePage ? '' : 'front_width']">
        <div class="front">
            <div class="front__bkg-photo" [ngClass]="[isHomePage ? 'home-card-border' : '']"></div>
            <div class="close-user-profile" *ngIf="!isHomePage">&times;</div>
            <div class="user-heading" *ngIf="isHomePage">
                <a class="home-icon-anchor">
                    <img class="icon-css" src="./../../../assets/images/menus/user-profile-icon.svg">
                  </a> User Profile</div>
            <div class="front__face-photo" [ngClass]="[isHomePage ? 'home-profile-img' : '']">{{initials}}</div>
            <div class="front__text" [ngClass]="[isHomePage ? 'home-front-text' : 'user-front-text']">
                <h3 class="front__text-header">{{user.FirstName}} {{user.LastName}}</h3>
                <p class="front__text-para">----</p>
                <span class="front__text-hover">{{user.EmailAddress}}</span>
                <div class="profile-divider">
                    <nalco-divider [color]="dividerCss.color" [length]="dividerCss.maxLength" [isHorizontal]="true">
                    </nalco-divider>
                </div>
                <div class="h6 mt-4" (click)="logout()">Logout</div>
            </div>
        </div>
    </div>
</div>