import * as contracts from "../contracts/_contracts";
import { Asset } from "./Asset";

export class Unit implements contracts.IUnit {
    id: string;
    name: string;
    sortOrder?: number;
    type?: string;
    action: string;
    assets: Asset[];
    isdeleted?: boolean;
    isCollapsed? = false;

    constructor(unit?: contracts.IUnit) {
        if (unit) {
            this.id = unit.id;
            this.name = unit.name;
            this.sortOrder = unit.sortOrder;
            this.type = unit.type;
            this.action = unit.action;
            this.isdeleted = unit.isdeleted;
            if (unit.assets) {
                this.assets = new Array<Asset>();
                for (let asset of unit.assets) {
                    this.assets.push(new Asset(asset));
                }
            }
        }
    }
}