<div ngbDropdown class="d-inline-block dropdown more-option" #myDrop="ngbDropdown">
  <div class="dropdown-content">
    <button class="btn drop-btn" id="dropdownBasic1" ngbDropdownToggle [disabled]="disableMoreOption">
      <span class="ico-more"></span>
    </button>
    <div class="more-options-list" ngbDropdownMenu aria-labelledby="dropdownBasic1">
      <button *ngIf='showAddToReport' class="dropdown-item" translate>COMMON.ADD_TO_REPORT</button>
      <button *ngIf='showExportAsPrintChart' class="dropdown-item" (click)="exportAsPrintChart()" translate>COMMON.PRINT_CHART</button>
      <button *ngIf='showExportAsImage' class="dropdown-item" (click)="exportAsImage(myDrop)" translate>COMMON.EXPORT_IMAGE</button>
      <button *ngIf='showExportAsCsv' class="dropdown-item" (click)="handleExportAsCsvClick()" translate>COMMON.EXPORT_CSV</button>
      <button *ngIf='showExportAllAsCsv' class="dropdown-item" (click)="handleExportAllAsCsvClick()" translate> COMMON.EXPORT_ALL_TEST_CSV</button>
      <button *ngIf='showExportAsPDF' class="dropdown-item" (click)="exportAsPDF($event)" translate>COMMON.EXPORT_PDF</button>
      <button *ngIf='showAuditDetails' class="dropdown-item" (click)="handleExportAsAuditClick()" translate>COMMON.VIEW_ADUIT</button>
      <button *ngIf='showMaximize' class="dropdown-item" (click)="handleMaximizeClick()" translate>COMMON.MAXIMIZE</button>
      <button *ngIf='showAccountDetails' class="dropdown-item" (click)="handleExportAsAccountClick()" translate>ASSET_HARVESTER.HEADER.ACCOUNT_DETAILS</button>
      <button *ngIf='showDownloadConfig' class="dropdown-item" (click)="handleDownloadConfigurationClick()" translate>CONTROLLER_MANAGEMENT.DOWNLOAD_CONFIGURATION</button>
      <button *ngIf='showSystemHealth' class="dropdown-item" (click)="handleSystemHealthClick()" translate>CONTROLLER_MANAGEMENT.SYSTEM_HEALTH</button>
      <button *ngIf='showGatewayStatistics' class="dropdown-item" (click)="handleGatewayStatisticsClick()" translate>CONTROLLER_MANAGEMENT.GATEWAY_STATISTICS</button>
      <button *ngIf='showDataDownload' class="dropdown-item" (click)="handleDataDownloadClick()" translate>CONTROLLER_MANAGEMENT.DATA_DOWNLOAD</button>
      <button *ngIf='showHardwareDetails' class="dropdown-item" (click)="handleHardwareDetailsClick()" translate>CONTROLLER_MANAGEMENT.HARDWARE_DETAILS.HARDWARE_DETAILS</button>
      <button *ngIf='showAlarmNotification' class="dropdown-item" (click)="handleAlarmNotificationClick()" translate>CONTROLLER_MANAGEMENT.ALARM_NOTIFICATION</button>
      <button *ngIf='showAlarmList' class="dropdown-item" (click)="handleAlarmListClick()" translate>CONTROLLER_MANAGEMENT.SHOW_ALARM_List</button>
      <!-- <button *ngIf='showExportAsImage' class="dropdown-item" (click)="exportAsImage(myDrop)">Export As Image</button>
      <button *ngIf='showExportAsCsv' class="dropdown-item" (click)="handleExportAsCsvClick()">Export As CSV</button>
      <button *ngIf='showExportAllAsCsv' class="dropdown-item" (click)="handleExportAllAsCsvClick()">Export All Test Results to CSV</button>
      <button *ngIf='showExportAsPDF' class="dropdown-item" (click)="exportAsPDF($event)">Export As PDF</button>
      <button *ngIf='showAuditDetails' class="dropdown-item" (click)="handleExportAsAuditClick()">View Audit History</button> -->
    </div>
  </div>
</div>
<!-- <angular2csv [data]="exportData" filename="angularFileName" [options]="options"></angular2csv> -->
