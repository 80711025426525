import { IProjectFinancials } from '../contracts/_contracts';

export class ProjectFinancials implements IProjectFinancials {
    EroiValueType: ' ';
    AnnualSavings: 0;
    CapitalInvestment: 0;
    ExpenseInvestment: 0;
    Units: ' ';
    UnitSymbol: '';
    UnitAmount: 0;
    Status: ' ';

    constructor(projectFinancials?: ProjectFinancials) {
        if (projectFinancials) {
            this.EroiValueType = projectFinancials.EroiValueType;
            this.AnnualSavings = projectFinancials.AnnualSavings;
            this.CapitalInvestment = projectFinancials.CapitalInvestment;
            this.ExpenseInvestment = projectFinancials.ExpenseInvestment;
            this.Units = projectFinancials.Units;
            this.UnitSymbol = projectFinancials.UnitSymbol;
            this.UnitAmount = projectFinancials.UnitAmount;
            this.Status = projectFinancials.Status;
        }
    }
}
