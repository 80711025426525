<section class="filter-wrapper" click-outside (clickOutside)="onClickOutside($event)">
  <div class="keyword-wrapper"  [ngbTooltip]="showSiteName ? siteName : ''">
    <input [formControl]="queryField" #inputRef  type="text" id="keyword" [value]="showSiteName ? siteName : ''" [placeholder]="'COMMON.SEARCH_SITE' | translate" (input)="onSearchChange(inputRef.value)"
      [readonly]="loaderFlag && crossIconFlag" [ngClass]="{'searchTextDisabled': loaderFlag == true && crossIconFlag == true, 'textPadding': crossIconFlag == true }" [autocomplete]='crossIconFlag ? "off" : ""' autofocus/>
    <label class="ico-nav-search search-icon" [ngClass]="loaderFlag && crossIconFlag ? 'searchTextDisabled' : ''">
    </label>
    <span class="c-three-dots-loader" *ngIf="loaderFlag"  style="position: absolute;right: 15px;top: 20px;"></span>
    <div *ngIf="searchText != ''"[hidden]="!crossIconFlag || loaderFlag" (click)="clearSearchData()">
      <span class="ico-exit-round-edge cross-icon" ></span>
    </div>
  </div>
  <div>
    <ul class="filter-select" *ngIf="data?.length> 0">
      <li class="filter-select-list" *ngFor="let dataItem of data" [value]="dataItem.DagId" (click)="changeInDropdown(dataItem); ">
        <!-- if FriendlyName is null and SiteNameEnglish is null, default to this template -->
        <ng-template [ngIf]="!dataItem.FriendlyName && !dataItem.SiteNameEnglish">
          <div *ngIf="dataItem.Country === 'US'">
            {{dataItem.SiteName}}, {{dataItem.City}}, {{dataItem.StateProvince}}, {{dataItem.Street}}
          </div>
          <div *ngIf="dataItem.Country !== 'US'">
            {{dataItem.SiteName}}, {{dataItem.City}}, {{dataItem.Country}}, {{dataItem.Street}}
          </div>
        </ng-template>
        <!-- if FriendlyName is available, use this template -->
        <ng-template [ngIf]="dataItem.FriendlyName">
          <div *ngIf="dataItem.Country === 'US'">
            {{dataItem.FriendlyName}}, {{dataItem.City}}, {{dataItem.StateProvince}}, {{dataItem.Street}}
          </div>
          <div *ngIf="dataItem.Country !== 'US'">
            {{dataItem.FriendlyName}}, {{dataItem.City}}, {{dataItem.Country}}, {{dataItem.Street}}
          </div>
        </ng-template>

        <!-- if FriendlyName is not available and SiteNameEnglish is available, use this template -->
        <ng-template [ngIf]="dataItem.SiteNameEnglish && !dataItem.FriendlyName">
          <div *ngIf="dataItem.Country === 'US'">
            {{dataItem.SiteNameEnglish}}, {{dataItem.City}}, {{dataItem.StateProvince}}, {{dataItem.Street}}
          </div>
          <div *ngIf="dataItem.Country !== 'US'">
            {{dataItem.SiteNameEnglish}}, {{dataItem.City}}, {{dataItem.Country}}, {{dataItem.Street}}
          </div>
        </ng-template>

      </li>
    </ul>
    <div *ngIf="isLoading ">
      <nalco-loader [loader]=true></nalco-loader>
    </div>
    <div *ngIf="noRecordsMsg " class="noRecords ">
      <h3 translate>COMMON.NO_RESULTS_FOUND</h3>
    </div>
    <div *ngIf="caMsg " class="caMismatch ">
      <h3 translate>COMMON.CA_MISMATCH</h3>
    </div>
  </div>
</section>