export const StackChartConfig = {
    chart: {
        type: 'column',
        height: 644,
        style: {
            fontFamily: 'Interstate-Regular'
        }
    },
    title: {
        text: ''
    },
    tickColor: '#e2e2e2',
    lineColor: '#e2e2e2',
    labels: {
        style: {
            color: '#616365',
            fontSize: '10px'
        },
    },
    legend: {
        enabled: false
    },
    tooltip: {
        backgroundColor: 'black',
        borderColor: '000000',
        borderRadius: 5,
        useHTML: true,
        headerFormat: '<p class="tool-tip-header">{point.x}</p>',
        pointFormat: '<span class="tooltip-content">{point.y} %</span>'
    },
    plotOptions: {
        column: {
            stacking: 'normal',
            dataLabels: {
                enabled: false,
            },
            pointWidth: 20
        }
    },
    credits: {
        enabled: false
    },
    colors: [{
        linearGradient: {
            x1: 1,
            y1: 0,
            x2: 1,
            y2: 1
        },
        stops: [
            [0, ' #3FB47B '],
            [1, '#66B44A'],
        ]
    }, {
        linearGradient: {
            x1: 1,
            y1: 0,
            x2: 1,
            y2: 1
        },
        stops: [
            [0, '#E00034'], [1, '#E00069'],
        ]
    }, {
        linearGradient: {
            x1: 1,
            y1: 0,
            x2: 1,
            y2: 1
        },
        stops: [
            [0, '#FDD82B'],
            [1, '#FDB82B'],
        ]
    },
    ]
};
