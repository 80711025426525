<div class="consent-page">
    <div class="consent-content">
        <div class="title">
            DIGITAL PROGRAM GENERAL TERMS AND CONDITIONS
        </div>
        <div style="
        padding-top: 9pt;
        padding-left: 5pt;
        text-indent: 0pt;
        text-align: justify;
      ">
            <span>The Ecolab Digital Program(s) and the software, websites, mobile
                applications, or other interactive properties through which the Ecolab
                Digital Program is delivered (collectively, the “Program,” as further
                defined below) are made available by Ecolab Inc. or one or more of its
                affiliates (“Ecolab” also referred to using “us,” “we,” and similar
                terms), subject to these Digital Program General Terms and Conditions
                (the “Digital T&amp;C”), including Ecolab’s Data Processing Agreement
                incorporated herein by reference and available at https://</span>www.ecolab.com/ecolab3dtandc (the
            “DPA,” and together with the Digital
            T&amp;C, these “Terms”). These Terms are entered into by and among you, an
            individual accessing the Program hereby representing that you are, at all
            times during your use of and access to the Program, an Authorized User of
            Customer (each term as defined herein) (“you”), the Customer, and Ecolab
            (each a “Party” and collectively, the “Parties”). In consideration of the
            mutual covenants and agreements herein contained and for other good and
            valuable consideration, the receipt and sufficiency of which are hereby
            acknowledged, the Parties agree as follows:
        </div>
        <div style="
        padding-top: 10pt;
        padding-left: 5pt;
        text-indent: 0pt;
        text-align: justify;
      ">
            All terms and conditions contained in any prior or subsequent oral or
            written communication between you and/or Customer, on one hand, and
            Ecolab, on the other hand, including, without limitation, terms and
            conditions contained in any purchase order which are different from or in
            addition to the terms set forth in these Terms, are hereby expressly
            rejected and shall not be binding on Ecolab, and Ecolab hereby objects
            thereto. Any action by you or the Customer in furtherance of a purchase of
            Ecolab Products (defined herein) and/or the Program shall constitute
            acceptance of these Terms. No addition to, or alteration or modification
            of, these Terms shall be valid unless made in a writing signed by an
            authorized representative of each Party specifically referring to these
            Terms.
        </div>
        <div style="
        padding-top: 10pt;
        padding-left: 5pt;
        text-indent: 0pt;
        text-align: justify;
      ">
            Notwithstanding the foregoing, these Terms shall not supersede and shall
            instead supplement any Ecolab agreement providing for Ecolab’s provision
            of the Program and/or Products previously signed by both Customer and
            Ecolab and currently in effect (each, an “Ecolab Standard Agreement”). The
            terms of such Ecolab Standard Agreement expressly covering the Program
            and/or Products shall control to the extent inconsistent herewith.
        </div>
        <div style="
        padding-top: 9pt;
        padding-left: 5pt;
        text-indent: 0pt;
        text-align: justify;
      ">
            These Terms apply solely to the Program, except to the extent expressly
            set forth herein. By accessing and/or using the Program, you hereby
            represent and warrant to Ecolab that you are duly authorized to enter into
            these Terms on your own behalf and on behalf of Customer, and further that
            you and the Customer agree to be bound by these Terms. You represent and
            warrant that you have the right, authority and capacity to accept and
            agree to these Terms on your behalf and on behalf of the Customer. Ecolab
            may update these Terms from time to time. Ecolab may make modifications to
            any Program in its discretion, provided that such modifications, if made
            during the term of an Ecolab Standard Agreement, do not materially
            adversely affect the functionality of Program.
        </div>
        <ol id="l1">
            <li data-list-text="1.">
                <div style="
            padding-top: 10pt;
            padding-left: 64pt;
            text-indent: -36pt;
            text-align: justify;
          ">
                    <span><u><b>Select Definitions</b></u></span>. Capitalized terms shall have the definition set forth
                    herein.
                    Cognate terms relating to data privacy which are statutorily defined
                    may be interpreted the same as defined herein. Capitalized terms used
                    herein, without definition, shall have the same meanings as provided
                    in an applicable Ecolab Standard Agreement. Capitalized terms include:
                </div>
                <ol id="l2">
                    <li data-list-text="a.">
                        <div style="
                padding-top: 10pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            “Affiliate” means an entity that owns or controls, is owned or
                            controlled by, or is or under common control or ownership with a
                            Party, where control is defined as the possession, directly or
                            indirectly, of the power to direct or cause the direction of the
                            management and policies of an entity, whether through ownership of
                            voting securities, by contract or otherwise.
                        </div>
                    </li>
                    <li data-list-text="b.">
                        <div style="
                padding-top: 10pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            “Applicable Law” means any and all laws, ordinances, rules,
                            directives, administrative circulars, supervisory guidance, and
                            regulations of any kind whatsoever of any governmental authority
                            within the jurisdictions applicable to a Party, which shall
                            include but not be limited to any applicable data privacy and
                            security regulations in the United States and European Economic
                            Area and applicable Export Laws.
                        </div>
                    </li>
                    <li data-list-text="c.">
                        <div style="
                padding-top: 9pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            &quot;Authorized User” means any individual authorized to use the
                            Program pursuant to these Terms, as an officer, director,
                            employee, contingent worker, independent contractor, agent or
                            other Person designated by Customer, including you, provided that
                            any corresponding fees for each such Authorized User’s access and
                            use of the Program are paid to Ecolab pursuant to the terms of any
                            Ecolab Standard Agreement or these Terms.
                        </div>
                    </li>
                    <li data-list-text="d.">
                        <div style="
                padding-top: 4pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            “Customer” means that Person affiliated with you that you
                            represent and/or have agency to act on behalf of, which shall be
                            bound by these Terms with Ecolab, and any of its Affiliates
                            designated by Customer which are approved by Ecolab (which
                            Affiliate has agreed to be bound by these Terms) and which have
                            paid all applicable fees for use of the Program pursuant to the
                            Ecolab Standard Agreement or these Terms.
                        </div>
                    </li>
                    <li data-list-text="e.">
                        <div style="
                padding-top: 9pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            “Customer Data” means any and all data, information and/or
                            materials provided or made available by or on behalf of Customer
                            to Ecolab for use in connection with Program or otherwise relating
                            to any Ecolab Standard Agreement.
                        </div>
                    </li>
                    <li data-list-text="f.">
                        <div style="
                padding-top: 10pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            “Derived Data” means (i) Customer Data that has been processed,
                            anonymized, aggregated and/or manipulated by or on behalf of
                            Ecolab to such a degree that it cannot be identified by visual
                            inspection as originating directly from Customer Data; and (ii)
                            any general information or insight that is derived by or on behalf
                            of Ecolab in connection with the Program or any Ecolab Standard
                            Agreement.
                        </div>
                    </li>
                    <li data-list-text="g.">
                        <div style="
                padding-top: 9pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            “Documentation” means all formal documentation and materials
                            published or provided in writing by Ecolab related to the
                            Software, including user manuals, help files and any other
                            instructions, specifications, documents, and materials that
                            describe the functionality, installation, testing, operation, use,
                            maintenance, support, technical features, or requirements of the
                            Software.
                        </div>
                    </li>
                    <li data-list-text="h.">
                        <div style="
                padding-top: 9pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            “Effective Date” shall mean the date you first accessed or used
                            the Program on behalf of the Customer.
                        </div>
                    </li>
                    <li data-list-text="i.">
                        <div style="
                padding-top: 10pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            “Intellectual Property Rights” means any and all intellectual
                            property rights under statutory law or common law throughout the
                            world, including, without limitation, all: (i) patent rights
                            (including patent applications and disclosures); (ii) registered
                            and unregistered copyrights (including rights in software,
                            including in source code and object code); (iii) registered and
                            unregistered trademark and tradename rights; and (iv) trade secret
                            rights.
                        </div>
                    </li>
                    <li data-list-text="j.">
                        <div style="
                padding-top: 9pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            “Intended Purposes” means only the purposes of the Software,
                            Products or Services expressly set forth in any Documentation or
                            any Ecolab Standard Agreement.
                        </div>
                    </li>
                    <li data-list-text="k.">
                        <div style="
                padding-top: 9pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            “Licensed Locations” means the licensed Customer facility
                            locations, to the extent identified in any agreement including any
                            Ecolab Standard Agreement.
                        </div>
                    </li>
                    <li data-list-text="l.">
                        <div style="
                padding-top: 10pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            “Person” means an individual, sole proprietorship, a corporation,
                            a partnership, a limited liability company or partnership, an
                            association, any national, federal, state, provincial,
                            territorial, municipal or local government, and any court,
                            department, tribunal, commission, governmental or regulatory
                            agency or authority exercising executive, legislative, judicial,
                            regulatory, administrative or other similar functions, whether
                            domestic or foreign, a trust, or any other entity or organization.
                        </div>
                    </li>
                    <li data-list-text="m.">
                        <div style="
                padding-top: 9pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            “Product(s)” means any tangible item manufactured by Ecolab or a
                            third party (and licensed through Ecolab) that is purchased,
                            provided, or utilized as a part of a Program.
                        </div>
                    </li>
                    <li data-list-text="n.">
                        <div style="
                padding-top: 9pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            “Program” or “Digital Program” means the program service offering
                            of Ecolab which Ecolab has agreed to sell or license to the
                            Customer, which may include Software, Documentation, Products
                            and/or Services.
                        </div>
                    </li>
                    <li data-list-text="o.">
                        <div style="
                padding-top: 10pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            “Personal Data” means data that may qualify as personal data or
                            personally identifiable information within the meaning of privacy
                            laws applicable to Customer and/or to Ecolab entities during the
                            Term hereof including, but not limited to, the definitions in the
                            DPA.
                        </div>
                    </li>
                    <li data-list-text="p.">
                        <div style="
                padding-top: 9pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            “Service(s)” means support or other work performed by Ecolab for
                            Customer, as may be further defined in any Ecolab Standard
                            Agreement.
                        </div>
                    </li>
                    <li data-list-text="q.">
                        <div style="
                padding-top: 10pt;
                padding-left: 81pt;
                text-indent: -17pt;
                text-align: left;
              ">
                            “Software” means the software program(s) owned by or licensed
                            through Ecolab, and any
                        </div>
                        <div style="
                padding-top: 4pt;
                padding-left: 82pt;
                text-indent: 0pt;
                text-align: justify;
              ">
                            updates, upgrades, enhancements, releases, improvements, and any
                            other adaptations or modifications made to such software programs
                            that are delivered by Ecolab to Customer as more fully described
                            in Documentation and any Ecolab Standard Agreement. Ecolab is not
                            obligated to update, upgrade, enhance or improve Software.
                        </div>
                    </li>
                </ol>
            </li>
            <li data-list-text="2.">
                <div style="
            padding-top: 9pt;
            padding-left: 63pt;
            text-indent: -35pt;
            text-align: left;
          ">
                    <span><u><b>Program License and Account Creation.</b></u></span>
                </div>
                <ol id="l3">
                    <li data-list-text="a.">
                        <div style="
                padding-top: 10pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            <u>Program License</u>. Subject to the terms and conditions of any
                            Ecolab Standard Agreement and during the Term (as defined herein),
                            Ecolab grants Customer a non-exclusive, limited, nontransferable,
                            non-assignable, non-sublicensable, revocable license to access and
                            use the Software, Documentation, Products and/or Services solely
                            for Customer’s internal business purposes in accordance with the
                            Intended Purposes at the Licensed Locations (the “License”). For
                            the avoidance of doubt, where a Program or Ecolab Standard
                            Agreement uses a similar or cognate term to “license,” such as
                            “subscription,” the Customer shall be deemed to have the same
                            rights, with the same restrictions, as granted under these Terms
                            with regard to the Software, Documentation, Products and/or
                            Services for that particular Program.
                        </div>
                    </li>
                    <li data-list-text="b.">
                        <div style="
                padding-top: 10pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            <u>Visitors and Account Creation</u>. You may be able to visit
                            certain public sections of the Program, including public sections
                            of a website associated with a Program, without identifying
                            yourself or providing personal information. In order to access
                            some or all features of a Program, you are required to register
                            with the Program as an Authorized User and select a username and
                            password (create an “Account”). By creating and or registering for
                            an Account, you represent and warrant that all information you
                            submit is truthful and accurate and that you have the right to
                            submit the same on behalf of the Customer as an Authorized User.
                            In addition to providing information on yourself as directed by
                            the Customer, you may request to establish additional Authorized
                            Users of the Program on behalf of the Customer, provided the
                            Customer has authorized the same, by providing information on
                            those Authorized Users directly to Ecolab, or you may direct each
                            Authorized User to the Program to create their own Account. If you
                            provide information on Authorized Users directly to Ecolab, you
                            hereby represent to Ecolab that you have all necessary rights,
                            authorizations, and consents with respect to the information you
                            submit about Authorized Users and the Customer (“Authorized User
                            Data”), and you and the Customer shall be responsible for the
                            actions of all such Authorized Users on the Program. All Account
                            information and any Authorized User Data provided to Ecolab must
                            be true, accurate, current, and complete at all times. Authorized
                            Users are responsible for updating Authorized User Data as
                            necessary and in a timely manner to ensure its accuracy and
                            completeness. By providing your Account information and Authorized
                            User Data to Ecolab, you hereby authorize Ecolab and its
                            Affiliates, contractors, and suppliers to use, copy, sublicense
                            and disclose all Account information and Authorized User Data
                        </div>
                        <div style="padding-left: 82pt; text-indent: 0pt; text-align: justify">
                            (i) as part of providing the Program as described in these Terms,
                            (ii) to the extent required pursuant to applicable federal, state
                            or local law or a valid order issued by a court or governmental
                            agency of competent jurisdiction (a “Legal Order”), (iii) as
                            necessary to enforce these Terms and/or to protect any of Ecolab’s
                            or other parties’ legal rights, (iv) in an aggregated form which
                            does not include identifying information, and (v) as permitted by
                            the DPA or an associated Program Privacy Notice (if applicable).
                        </div>
                    </li>
                    <li data-list-text="c.">
                        <div style="
                padding-top: 10pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            <u>Authorized User Accounts</u>. You may not have more than one
                            (1) Account without Ecolab’s prior written permission. You must
                            access the Program and non-public portions of the Program only
                            through your Account. The security and confidentiality of your
                            Account username and password is your sole responsibility. You
                            shall ensure that no unauthorized Person shall have access to your
                            Account, username, or password and shall immediately notify Ecolab
                            of any unauthorized access or use of which you become aware. You
                            may not transfer your Account to anyone without the express prior
                            written consent of Ecolab. You agree to bear all responsibility
                            and liability for all actions under or through your Account as
                            well as all fees and/or charges incurred from the use of your
                            Account, whether authorized or unauthorized.
                        </div>
                    </li>
                    <li data-list-text="d.">
                        <div style="
                padding-top: 10pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            <u>Authorized User Content</u>. The Program may contain Authorized
                            User Data, which includes content and materials you generate
                            and/or provide, including but not limited to content that may be
                            considered Customer Data (“User Content”). User Content is the
                            sole responsibility of the
                        </div>
                        <div style="
                padding-top: 4pt;
                padding-left: 82pt;
                text-indent: 0pt;
                text-align: justify;
              ">
                            Authorized User that submitted that User Content. Ecolab does not
                            control User Content generated or provided by Authorized Users and
                            is under no circumstances responsible or liable in any way for any
                            User Content that is made available through the Program. Ecolab
                            does not endorse any User Content, and does not warrant or
                            guarantee the integrity, truthfulness, accuracy, or quality of any
                            User Content. You and Customer agree that Ecolab is not liable for
                            any loss or damage of any kind resulting from availability,
                            access, and/or use of any User Content. You further agree that the
                            User Content that you may generate or provide through the Program
                            shall be truthful and not misleading. You and Customer hereby
                            grant to Ecolab all rights to User Content consistent with the
                            rights generally granted to Customer Data. You hereby represent
                            that you have obtained any necessary rights, authorizations, and
                            consents from Customer and any applicable third-parties with
                            respect to your submitted User Content, including, without
                            limitation, to grant the rights in this Section 2(d) to Ecolab.
                            You and Customer agree not to submit User Content that: (i) may
                            create or does create a risk of harm, loss, physical or mental
                            injury, emotional distress, death, disability, disfigurement, or
                            physical or mental illness to you, or to any other Person; (ii)
                            may or does create a risk of any other loss or damage to any
                            Person or property;
                        </div>
                        <div style="padding-left: 82pt; text-indent: 0pt; text-align: justify">
                            (iii) seeks to harm or exploit children by exposing them to
                            inappropriate content, asking for personally identifiable details
                            or otherwise; (iv) may constitute or contribute to a crime or
                            tort;
                        </div>
                        <div style="padding-left: 82pt; text-indent: 0pt; text-align: justify">
                            (v) contains any information or content that we deem to be
                            unlawful, harmful, abusive, racially or ethnically offensive,
                            defamatory, infringing, invasive of personal privacy or publicity
                            rights, harassing, humiliating to other people (publicly or
                            otherwise), libelous, threatening, profane, or otherwise
                            objectionable; (vi) contains any information or content that is
                            illegal (including, without limitation, any message, data, image
                            or program that would violate the property rights of others,
                            including unauthorized copyrighted text, images or programs, trade
                            secrets, or other confidential proprietary information, and
                            trademarks or service marks used in an infringing fashion); (vii)
                            contains any information or content that you do not have a right
                            to make available under any law or under contractual or fiduciary
                            relationships; or (viii) contains any information or content that
                            you know is not correct and current. You and Customer agree that
                            any User Content that you upload does not and will not violate
                            third-party rights of any kind, including without limitation any
                            Intellectual Property Rights or rights of privacy. Ecolab reserves
                            the right, but is not obligated, to reject and/or remove any User
                            Content that Ecolab believes, in its sole discretion, violates
                            these provisions. You understand that publishing your User Content
                            on the Service is not a substitute for registering it with the
                            U.S. Copyright Office or any other rights organization.
                        </div>
                    </li>
                </ol>
            </li>
            <li data-list-text="3.">
                <div style="
            padding-top: 10pt;
            padding-left: 63pt;
            text-indent: -35pt;
            text-align: left;
          ">
                    <span><u><b>Customer Systems, Authorized User Credentials, Customer Data
                                and Export Law Compliance.</b></u></span>
                </div>
                <ol id="l4">
                    <li data-list-text="a.">
                        <div style="
                padding-top: 9pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            <u>Customer Systems</u>. You agree that Customer shall have sole
                            responsibility for procuring the hardware and internet
                            connectivity in order to access the Program as well as for
                            complying with Ecolab’s technical requirements to upload Customer
                            Data. Ecolab shall not be responsible or liable in any manner for
                            any equipment provided by Customer or any third party, and
                            Customer shall be the sole responsible Party to support and
                            maintain its own equipment. The Ecolab Program may not be
                            compatible with Customer or third-party equipment and/or internet
                            connections. Customer acknowledges and agrees that unless as
                            provided for in writing, Ecolab is not required to maintain or
                            repair any Customer or third-party equipment or modify the Ecolab
                            Program or delivery of the Program to make a Program compatible
                            with Customer’s equipment or third-party equipment. You agree that
                            Customer shall at all times during the Term: (a) set up, maintain
                            and operate in good repair all information technology
                            infrastructure, including computers, software, hardware,
                            databases, electronic systems (including database management
                            systems), and networks, whether operated directly by Customer or
                            through the use of third-party services (“Customer Systems”) on or
                            through which the Services are accessed or used; (b) provide all
                            cooperation and assistance as Ecolab may reasonably request to
                            enable Ecolab to exercise its rights and perform its obligations
                            under and in connection with these Terms and any Ecolab Standard
                            Agreement; and (c) ensure use of the Program is only for
                            Customer&#39;s own environment and in accordance with Applicable
                            Laws, Documentation, and other applicable requirements, including
                            Customer&#39;s obligations in the DPA. Customer shall employ all
                            physical, administrative, and technical controls, screening and
                            security procedures, and other safeguards necessary to, in
                            accordance with these Terms: (a) securely
                        </div>
                        <div style="
                padding-top: 4pt;
                padding-left: 82pt;
                text-indent: 0pt;
                text-align: justify;
              ">
                            administer the distribution and use of the Program, and protect
                            against any unauthorized access to or use of the Program; and (b)
                            control the content and use of Customer Data, including the
                            uploading or other provision of appropriate Customer Data for
                            processing to the Program. Ecolab is not responsible for the
                            security of the Customer’s network, hardware and IT systems,
                            including without limitation, any possible, suspected or actual
                            breach of Customer’s physical or IT security defenses and
                            resultant disclosure of any data of Customer or its personnel.
                            Customer shall have sole responsibility for any security
                            procedures reasonably required to protect access to its hardware,
                            systems and Customer Data in connection with its use of Program
                            and/or the Software, Documentation, Products and Services. You
                            represent and warrant that any electronic communications Ecolab
                            receives under usernames of Authorized Users associated with
                            Customer, including you, have been sent by Customer. Ecolab has
                            the right at any time to terminate or suspend access to any
                            Authorized User or to Customer if Ecolab believes in good faith
                            that such termination or suspension is necessary to preserve the
                            security, integrity, or accessibility of Program or Ecolab’s
                            network. Customer agrees to indemnify and hold Ecolab and its
                            affiliates harmless from any claim, including attorney’s fees and
                            costs related to the foregoing.
                        </div>
                        <div style="text-indent: 0pt; text-align: left"><br /></div>
                    </li>
                    <li data-list-text="b.">
                        <div style="
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            <u>Authorized User Credentials</u>. Customer will ensure the
                            security of all passwords and usernames used by Customer personnel
                            to use any Software, Documentation, Product and/or Service.
                            Customer is responsible for access control maintenance (including
                            access termination) in connection with its use of the Software,
                            Documentation, Product and/or Service. Customer will notify Ecolab
                            promptly if Customer becomes aware of, or suspect, any breach of
                            security or unauthorized access to or use of the Software,
                            Documentation, Product and/or Service or of any account used to
                            access the Software, Documentation, Product and/or Service or
                            unauthorized access to passwords or usernames.
                        </div>
                    </li>
                    <li data-list-text="c.">
                        <div style="
                padding-top: 10pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            <u>Customer Data</u>. Customer is solely responsible for ensuring
                            that Customer Data does not infringe on any intellectual property
                            right, violate any applicable laws or the terms of any agreement,
                            including, without limitation, any Ecolab Standard Agreement and
                            any associated privacy terms.
                        </div>
                    </li>
                    <li data-list-text="d.">
                        <div style="
                padding-top: 9pt;
                padding-left: 81pt;
                text-indent: -17pt;
                text-align: justify;
              ">
                            <u>Export Law Compliance</u>. Each Party shall (a) comply with
                            Applicable Law administered by the
                        </div>
                        <div style="padding-left: 82pt; text-indent: 0pt; text-align: justify">
                            U.S. Commerce Department Bureau of Industry and Security, U.S.
                            Treasury Office of Foreign Assets Control, or other governmental
                            entity imposing export controls and trade sanctions (“Export
                            Laws”), including designating countries, entities and persons
                            (“Sanctions Targets”) and
                        </div>
                        <div style="padding-left: 82pt; text-indent: 0pt; text-align: justify">
                            (b) not directly or indirectly export, re-export or otherwise
                            deliver the Program to a Sanctions Target, or broker, finance or
                            otherwise facilitate any transaction in violation of any Export
                            Laws. Customer represents that it is not a Sanctions Target or
                            prohibited from utilizing the Program and/or purchasing or
                            licensing the associated Software, Documentation, Products and/or
                            Services under Applicable Law, including Export Laws, and further
                            represents that entering into these Terms will not violate any
                            Export Laws.
                        </div>
                    </li>
                </ol>
            </li>
            <li data-list-text="4.">
                <div class="s1" style="
            padding-top: 10pt;
            padding-left: 64pt;
            text-indent: -36pt;
            text-align: left;
          ">
                    <span><b>Title/Ownership, Confidentiality, Intellectual Property,
                            Third-Party Technology and Customer <u>Data License.</u></b></span>
                </div>
                <ol id="l5">
                    <li data-list-text="a.">
                        <div style="
                padding-top: 9pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            <u>Title/Ownership</u>. Customer acknowledges that, except for the
                            License, it has not and will not acquire any rights, title or
                            interest in or to any of the Software, Documentation, Products
                            and/or Services. Any Intellectual Property Rights in conjunction
                            with the Ecolab Software, Products, the Program, brands, logos,
                            trademarks and/or wordmarks, or any Intellectual Property related
                            to technology that is used in the provisioning of the Program
                            thereof are, and shall at all times remain the exclusive and sole
                            property of Ecolab, its related third parties, affiliates, agents
                            and its licensors, as applicable. All rights not expressly granted
                            herein are reserved and retained by Ecolab and its third parties,
                            affiliates, agents and its licensors, and no Intellectual Property
                            rights or any other form or type of rights or licenses are granted
                            at any time to Customer are assigned, granted, or transferred to
                            Customer, its employees, Affiliates, agents and independent
                            contractors or any other party by estoppel, implication, or
                            otherwise. Customer affirms, agrees and acknowledges that misuse
                            of Ecolab Services may violate third party Intellectual Property
                            in the Products and/or Software.
                        </div>
                    </li>
                    <li data-list-text="b.">
                        <div style="
                padding-top: 3pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            <u>Confidentiality&nbsp; and&nbsp; Ecolab’s&nbsp;
                                Intellectual&nbsp; Property</u>. Customer acknowledges that Ecolab’s Software,
                            Documentation,
                            Products and Services, information about such Products and
                            Services, business plans, trade secrets, discoveries, ideas,
                            designs, drawings, specifications, techniques, models, data, code,
                            software, programs, documentation, processes, lists, know-how,
                            marketing plans, customer information, and financial and technical
                            information and other information known to be or should be known
                            to be confidential information or designated by Ecolab as
                            confidential information are confidential information of Ecolab
                            (the “Confidential Information”), and Customer will not disclose
                            such Confidential Information or any of Customer’s use thereof to
                            any third party, or use such Confidential Information for any
                            purpose not authorized herein. Except for certain Customer Data as
                            described herein, all material included in the Program, including
                            (without limitation) the text, software, scripts, graphics,
                            photos, sounds, music, videos, interactive features and the like
                            (“Content”) are owned by or licensed to Ecolab, subject to
                            copyright and other intellectual property rights under United
                            States and foreign laws and international conventions. You and
                            Customer acknowledge and agree that Ecolab is and shall remain the
                            exclusive owner of the Program; all associated Software,
                            Documentation, Products and Services; Ecolab’s data, information,
                            and Content; and all patent, copyright, trade secret, trademark
                            and other intellectual property rights therein. In addition, the
                            compilation and presentation of the Content in the Program is
                            subject to copyright owned exclusively by us. Content in the
                            Program may not be used, copied, reproduced, distributed,
                            transmitted, broadcast, displayed, sold, licensed, or otherwise
                            exploited for any other purposes whatsoever without our prior
                            written consent. You and Customer agree to not engage in the use,
                            copying, or distribution of any of the Content, Program, Software,
                            Documentation, Products, and Services other than expressly
                            permitted herein, including any use, copying, or distribution
                            Content. If you or Customer downloads or prints a copy of the
                            Content for personal use, you must retain all copyright and other
                            proprietary notices contained therein. You and Customer agree not
                            to circumvent, disable or otherwise interfere with
                            security-related features of the Program or features that prevent
                            or restrict use or copying of any Content or enforce limitations
                            on use of the Program or the Content therein. You and Customer
                            shall not challenge or assist any third party to dispute or
                            challenge Ecolab’s or its licensors’ ownership of such rights or
                            the validity or enforceability of such rights.
                        </div>
                    </li>
                    <li data-list-text="c.">
                        <div style="
                padding-top: 10pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            <u>Reservation of Rights</u>. Ecolab reserves all rights not
                            specifically granted to Customer under these Terms and any Ecolab
                            Standard Agreement.
                        </div>
                    </li>
                    <li data-list-text="d.">
                        <div style="
                padding-top: 9pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            <u>Third Party Technology</u>. The software provided by Ecolab to
                            Customer may include software, content, data or other materials,
                            including related documentation, that are owned by persons other
                            than Ecolab that are provided to Customer on terms that are in
                            addition to and/or different from those contained in these Terms
                            or any Ecolab Standard Agreement (the “Third-Party Technology”).
                            Usage of such software, Product or Service is deemed to constitute
                            acceptance of any and all additional terms applicable to
                            Third-Party Technology. Any breach by Customer of any Third-Party
                            Technology license is also a breach of these Terms.
                        </div>
                    </li>
                    <li data-list-text="e.">
                        <div style="
                padding-top: 10pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            <u>Customer Data License</u>. Ecolab acknowledges and agrees that
                            Customer owns and retains all right, title and interest in and to
                            Customer Data. Subject to the terms and conditions of the these
                            Terms, Customer grants to Ecolab, and its affiliates, a perpetual,
                            nonexclusive, worldwide, royalty-free, transferable, sublicensable
                            license to use, copy, store, process, manipulate, modify, change,
                            configure, perform, display and transmit Customer Data as
                            necessary to provide Program Software, Products and Services,
                            including any Deliverables, to Customer and to incorporate
                            Customer Data into aggregated and anonymized data sets including,
                            without limitation, for the purpose of improving the Software,
                            Documentation, Products and Services of Ecolab and its Affiliates
                            and for creating Derived Data. Customer grants to Ecolab an
                            irrevocable, non- exclusive, worldwide, royalty-free,
                            transferable, sublicensable, perpetual right to aggregate Customer
                            Data with other data from others and to use in any way,
                            de-identified Customer Data and aggregated Customer Data
                            including, without limitation, to create Derived Data. Customer
                            represents and warrants that Customer owns and/or has all
                            necessary rights in the Customer Data
                        </div>
                        <div style="
                padding-top: 4pt;
                padding-left: 82pt;
                text-indent: 0pt;
                text-align: justify;
              ">
                            to grant Ecolab this Customer Data license to Ecolab. Customer
                            represents and warrants that Customer Data will not include any
                            protected health information, or any other information of the type
                            enumerated in Article 9 of the General Data Protection Regulation,
                            Regulation 2016/679 of the European Parliament and of the Council
                            of 27 April 2016, or equivalent information as defined in any
                            other Applicable Law, nor include any information subject to
                            Health Insurance Portability and Accountability Act, as amended,
                            as either may be amended or superseded. Customer shall not
                            transmit or upload via Program, or to any Ecolab software, product
                            or service, any spam, viruses, worms, trapdoor, backdoor,
                            time-bombs, trojan horses, or other harmful, malicious or
                            disruptive code or components, including, without limitation, in
                            any Customer Data.
                        </div>
                    </li>
                </ol>
            </li>
            <li data-list-text="5.">
                <div class="s1" style="
            padding-top: 10pt;
            padding-left: 63pt;
            text-indent: -35pt;
            text-align: left;
          ">
                    <span><u><b>Use of Customer Data and Administration of the Program</b></u>.</span>
                </div>
                <ol id="l6">
                    <li data-list-text="a.">
                        <div style="
                padding-top: 9pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            <u>Service Analysis</u>. Without limiting any Customer Data
                            ownership rights, Ecolab shall have the right to: (i) compile
                            statistical and other information related to the performance,
                            operation and use of the Software, Documentation, Products,
                            Services and Customer Data, and (ii) use data from the Software,
                            Documentation, Products, Services and Customer Data in aggregated
                            form for security and operations management, to create statistical
                            analyses, for research and development purposes and to incorporate
                            Customer Data into aggregated and anonymized data sets for the
                            purpose of improving and commercializing products, software,
                            technology and services of Ecolab (clauses (i) and (ii) are
                            collectively referred to as “Service Analysis”). Ecolab retains
                            all rights to such Service Analysis and will take reasonable steps
                            not to incorporate Customer Data in a form that could serve to
                            identify Customer. Customer acknowledges and agrees that Ecolab
                            owns and retains all right, title and interest in and to Derived
                            Data.
                        </div>
                    </li>
                    <li data-list-text="b.">
                        <div style="
                padding-top: 10pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            <u>Monitoring the Program</u>. Ecolab may use tools, scripts,
                            software, and utilities (collectively, the “Tools”) to monitor and
                            administer the Software, Products and Services and to help resolve
                            Customer’s service requests. Information collected by the Tools
                            may also be used to assist in managing Ecolab product and service
                            portfolio, to help Ecolab address deficiencies in its product and
                            service offerings, and for license and Program Software, Products
                            and Services management.
                        </div>
                    </li>
                    <li data-list-text="c.">
                        <div style="
                padding-top: 9pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            <u>Third-Party Service Providers</u>. Ecolab works with other
                            companies that help Ecolab provide products and services,
                            including modifications and updates thereto, to Customer, such as
                            third- party manufacturers, third-party software providers,
                            cloud-hosting service providers, freight carriers, and credit card
                            processing companies, and Ecolab may share certain information
                            with these companies for this purpose including information
                            regarding Customer’s use of the Software, Documentation, Products
                            and Services.
                        </div>
                    </li>
                </ol>
            </li>
            <li data-list-text="6.">
                <div class="s1" style="
            padding-top: 10pt;
            padding-left: 63pt;
            text-indent: -35pt;
            text-align: left;
          ">
                    <span><u><b>Data Privacy; Data Security; Privacy Policy</b></u>.</span>
                </div>
                <ol id="l7">
                    <li data-list-text="a.">
                        <div style="
                padding-top: 9pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            Customer shall, in its use of the Software, Documentation,
                            Products and/or Services, collect, access, use, store, disclose,
                            dispose of, transfer, transmit to Ecolab and otherwise process
                            personal information of Customer and its Authorized Users in
                            accordance with the requirements of all Applicable Laws including,
                            without limitation, applicable data protection laws and
                            regulations and data privacy provisions of the DPA. Customer shall
                            have sole responsibility for the accuracy, quality, and legality
                            of such personal information and the means by which Customer or
                            any relevant Affiliate of Customer collects, stores, processes and
                            transmits such personal information.
                        </div>
                    </li>
                    <li data-list-text="b.">
                        <div style="
                padding-top: 10pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            Customer acknowledges and agrees that Ecolab may store, share,
                            process and use Customer Data for the purposes defined in these
                            Terms. Ecolab may also share such data globally with its
                            Affiliates and subsidiaries and within the Ecolab group of
                            companies. If Ecolab is receiving Customer Data from the European
                            Union, the United Kingdom, or other jurisdictions requiring a
                            transfer mechanism, such Customer Data shall be subject to the
                            DPA.
                        </div>
                    </li>
                    <li data-list-text="c.">
                        <div style="
                padding-top: 9pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            The storing and hosting of Customer Data via Program shall be
                            under the safeguards for the protection of the security,
                            confidentiality, and integrity of Customer Data, as described in
                            the Ecolab’s Program-specific or general security documentation,
                            which is available upon
                        </div>
                        <div style="
                padding-top: 4pt;
                padding-left: 82pt;
                text-indent: 0pt;
                text-align: left;
              ">
                            Customer’s request.
                        </div>
                    </li>
                    <li data-list-text="d.">
                        <div style="
                padding-top: 9pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            Customer represents and warrants that Customer Data will not
                            include any information deemed to be sensitive under any law or
                            regulation, including but not limited to health information,
                            financial account numbers, or other similarly sensitive personal
                            information. Customer assumes all risk arising from use of any
                            such sensitive information with Program, including the risk of
                            inadvertent disclosure or unauthorized access or use thereto.
                        </div>
                    </li>
                    <li data-list-text="e.">
                        <div style="
                padding-top: 10pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            If either Party learns of any inadvertent data disclosure or data
                            breach concerning the other Party’s data or systems, that Party
                            shall give prompt notification to the other Party and the Parties
                            shall cooperatively establish a data breach notification and
                            remediation plan, in compliance with Applicable Laws, with the
                            responsibility for such notification and remediation plan being
                            borne according to the Parties’ respective, proportionate
                            responsibility for the disclosure or breach; provided, however,
                            Ecolab’s liability shall be subject to the provisions of Sections
                            4, 13, 14, and 15 of this Agreement.
                        </div>
                    </li>
                </ol>
            </li>
            <li data-list-text="7.">
                <div class="s1" style="
            padding-top: 10pt;
            padding-left: 63pt;
            text-indent: -35pt;
            text-align: left;
          ">
                    <span><u><b>Customer Support.</b></u></span>
                </div>
                <ol id="l8">
                    <li data-list-text="a.">
                        <div style="
                padding-top: 9pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            <u>Internet</u>. Customer understands that the internet by which
                            Authorized User&#39;s access to the Program may be subject to
                            system delays and failures beyond either Party&#39;s reasonable
                            control. Customer acknowledges that Ecolab is not responsible for
                            delays, delivery failures or other damages resulting from Customer
                            or Customer&#39;s Authorized Users&#39; access to the internet,
                            and such access is the responsibility of the Customer or each
                            Authorized User.
                        </div>
                    </li>
                    <li data-list-text="b.">
                        <div style="
                padding-top: 10pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            <u>System Updates</u>. Ecolab reserves the right, in its sole
                            discretion, to make any enhancements or modifications to the
                            Program that it deems necessary or useful to: (a) maintain or
                            enhance (i) the quality or delivery of the Program; (ii) the
                            competitive strength of or market for the Program; or
                        </div>
                        <div style="padding-left: 82pt; text-indent: 0pt; text-align: justify">
                            (iii) the Program&#39;s cost efficiency or performance; or (b) to
                            comply with Applicable Law. If there are any functional updates or
                            enhancements that in Ecolab&#39;s sole discretion are considered
                            new products relating to the Program, Ecolab will notify Customer
                            of such new products and the cost in the event Customer elects to
                            incorporate such new products as part of the Program. Ecolab has
                            the right to bill and Customer shall pay for all reasonable
                            upgrade charges to the extent such Program upgrade is deemed
                            necessary by Ecolab.
                        </div>
                    </li>
                    <li data-list-text="c.">
                        <div style="
                padding-top: 9pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            <u>Scheduled&nbsp; Maintenance</u>. Scheduled system maintenance
                            will take place during a normal maintenance window. During such
                            time, the Services will be unavailable. Emergency maintenance may
                            be required at other times in the event of an incident or
                            circumstance requiring immediate attention outside of normally
                            scheduled system maintenance. Ecolab will use commercially
                            reasonable efforts to remedy any system failure and restore the
                            Program.
                        </div>
                    </li>
                    <li data-list-text="d.">
                        <div style="
                padding-top: 10pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            <u>Program Performance and Availability</u>. Ecolab makes no
                            representations, warranties or claims to Customer regarding the
                            reliability or availability of the Program. Additionally, Ecolab
                            is not liable for any delay or failure to provide the Program from
                            time to time, and unscheduled downtime may occur that is caused
                            by, but in no way limited to, circumstances beyond Ecolab’s
                            reasonable control and/or the following events: (a) Customer or
                            any Authorized User&#39;s breach of any provision of these Terms
                            or Agreement; (b) incompatibility of Authorized User’s equipment
                            or software with the Program; (c) poor or inadequate performance
                            of Authorized User’s network, hardware, or other systems,
                            including internet connectivity; (d) Authorized User’s equipment
                            failures; (e) force majeure (including as may be defined in these
                            Terms or the Ecolab Standard Agreement); (f) failure,
                            interruption, outage, or other problem with any software,
                            hardware, system, network, facility, or other matter not supplied
                            by Ecolab pursuant to these Terms; or (g) disabling, suspension or
                            termination of the Program (including as may be described in any
                            Ecolab Standard Agreement) (such downtime resulting from the
                            events named in (a) through (g) hereof, the “Unscheduled
                            Downtime”). In the event of Unscheduled Downtime, Ecolab’s sole
                            obligation will be to use commercially reasonable efforts to
                            resolve the issue and to resume providing access to the Program.
                            Ecolab may provide notice of any Unscheduled Downtime to Customer
                            and to provide updates regarding resumption of access to the
                            Program following any Unscheduled
                        </div>
                        <div style="
                padding-top: 4pt;
                padding-left: 82pt;
                text-indent: 0pt;
                text-align: left;
              ">
                            Downtime to the extent known to Ecolab.
                        </div>
                    </li>
                </ol>
            </li>
            <li data-list-text="8.">
                <div style="
            padding-top: 9pt;
            padding-left: 64pt;
            text-indent: -36pt;
            text-align: justify;
          ">
                    <span><u><b>Prohibited Uses</b></u>.</span>
                    In addition to uses otherwise prohibited by Ecolab, these Terms or
                    Applicable Law, you and Customer shall not, and you and Customer shall
                    not allow any third party to, do any of the following:
                </div>
                <ol id="l9">
                    <li data-list-text="a.">
                        <div style="
                padding-top: 10pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            Use the Program or any Software, Documentation, Product or Service
                            for any purpose other than the Intended Purposes or in any way
                            beyond the scope of the license set forth in any Ecolab Standard
                            Agreement or these Terms.
                        </div>
                    </li>
                    <li data-list-text="b.">
                        <div style="
                padding-top: 9pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            Copy or distribute any Software, Documentation, Product or Service
                            without Ecolab’s prior written approval.
                        </div>
                    </li>
                    <li data-list-text="c.">
                        <div style="
                padding-top: 10pt;
                padding-left: 82pt;
                text-indent: -17pt;
                text-align: left;
              ">
                            Download, access, use or register for the Program if you are under
                            eighteen (18) years old.
                        </div>
                    </li>
                    <li data-list-text="d.">
                        <div style="
                padding-top: 9pt;
                padding-left: 82pt;
                text-indent: -17pt;
                text-align: left;
              ">
                            Change or delete any copyright or proprietary notices from the
                            Program.
                        </div>
                    </li>
                    <li data-list-text="e.">
                        <div style="
                padding-top: 10pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            Impersonate or attempt to impersonate Ecolab, an Ecolab employee,
                            another Authorized User, or any other Person.
                        </div>
                    </li>
                    <li data-list-text="f.">
                        <div style="
                padding-top: 9pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            Access or use the Program to send unsolicited commercial messages
                            to any Person, or to threaten, harm or harass another Authorized
                            User’s Account, use and/or access of the Program, including, but
                            not limited to, attempting to gain access to another Authorized
                            User’s Account.
                        </div>
                    </li>
                    <li data-list-text="g.">
                        <div style="
                padding-top: 10pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            Access or use the Program by artificial means or in a manner that,
                            in Ecolab’s sole discretion, would inflict an excessive load on
                            Ecolab’s or its suppliers’ hardware or software.
                        </div>
                    </li>
                    <li data-list-text="h.">
                        <div style="
                padding-top: 9pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            Use the Software, Documentation, Products or Services in the
                            operation of a service bureau, timesharing or hosting purposes or
                            otherwise use the Software, Documentation, Products or Services
                            for the benefit of third parties.
                        </div>
                    </li>
                    <li data-list-text="i.">
                        <div style="
                padding-top: 10pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            Disclose information or analysis (including without limitation
                            benchmarks) regarding the quality or performance of the Software,
                            Documentation, Products or Services.
                        </div>
                    </li>
                    <li data-list-text="j.">
                        <div style="
                padding-top: 9pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            Post, upload or transmit: (i) any data, content or materials meant
                            to market or advertise Program or products that are competitive
                            with the Program, solicit any other Authorized Users of the
                            Program for such purposes and/or use data, content or materials
                            gathered from the Program for such purposes, (ii) any data,
                            content or materials of any type that infringe or violate any
                            rights of any party, including, but not limited to, any patent,
                            copyright, trademark, trade secret or any proprietary or other
                            right of Ecolab or a third party, (iii) any data, content or
                            materials that are illegal, threatening, obscene, defamatory,
                            harmful, invasive to privacy or otherwise violate the rights of
                            Ecolab or a third party, and/or (iv) any disabling code (defined
                            as computer code designed to interfere with the normal operation
                            of the Program or Ecolab’s or a third party’s hardware or
                            software) or any program routine, device or other undisclosed
                            feature, including, but not limited to, a time bomb, virus,
                            software lock, drop-dead device, malicious logic, worm, Trojan
                            horse or trap door which is designed to delete, disable,
                            deactivate, interfere with or otherwise harm the Program or
                            Ecolab’s or a third party’s hardware or software.
                        </div>
                    </li>
                    <li data-list-text="k.">
                        <div style="
                padding-top: 10pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            Use the Program for any benchmarking, monitoring or testing
                            availability or performance, or any other competitive use,
                            including, but not limited to, creating programs, products,
                            services, technology or software that are substantially similar to
                            the Program.
                        </div>
                    </li>
                    <li data-list-text="l.">
                        <div style="
                padding-top: 9pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            Modify, adapt, alter (except for any such modification, adaptation
                            and/or alteration of Program reported information agreed in any
                            Ecolab Standard Agreement), disassemble, decompile, decode,
                            translate or convert into human readable form, or reverse
                            engineer, all or any part of any Software, Documentation, Product
                            or Service.
                        </div>
                    </li>
                    <li data-list-text="m.">
                        <div style="
                padding-top: 9pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: left;
              ">
                            Decompile, disassemble or otherwise reverse engineer or attempt to
                            reconstruct or derive any
                        </div>
                        <div style="
                padding-top: 4pt;
                padding-left: 82pt;
                text-indent: 0pt;
                text-align: left;
              ">
                            source code (or underlying ideas, algorithms, structure or
                            organization), modify or create a derivative work of the Program
                            by any means whatsoever.
                        </div>
                    </li>
                    <li data-list-text="n.">
                        <div style="
                padding-top: 9pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            Distribute, disclose or allow use of any portion of the Program or
                            your Account in any format through any timesharing device, service
                            bureau, network or by any other means, to or by any third party.
                        </div>
                    </li>
                    <li data-list-text="o.">
                        <div style="
                padding-top: 10pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            Use or access any data, content or materials for any purpose or in
                            any manner other than real- time viewing (which prohibits, for
                            example, screenshots, downloading and storing) unless expressly
                            permitted by the features of the Program.
                        </div>
                    </li>
                    <li data-list-text="p.">
                        <div style="
                padding-top: 9pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            Use or access the Program or any data, content or materials
                            provided through the Program in violation of international,
                            federal, state or local law or other applicable rules or
                            regulations (including, without limitation, any laws regarding the
                            export of data or software to and from the United States and other
                            countries). If you access the Program from outside the United
                            States, you do so at your own risk and you are solely responsible
                            for compliance with laws of your jurisdiction.
                        </div>
                    </li>
                    <li data-list-text="q.">
                        <div style="
                padding-top: 10pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            To engage in any other conduct that restricts or inhibits anyone’s
                            use or enjoyment of the Program, or which, as determine by us, may
                            harm Ecolab or Authorized Users of the Program or expose them to
                            liability.
                        </div>
                    </li>
                </ol>
            </li>
            <li data-list-text="9.">
                <div style="
            padding-top: 10pt;
            padding-left: 64pt;
            text-indent: -36pt;
            text-align: justify;
          ">
                    <span><u><b>Term</b></u>.</span>
                    Unless otherwise agreed in any Ecolab Standard Agreement, these Terms
                    shall commence on the Effective Date and continue for 12 months (the
                    “Initial Term”), and shall auto-renew for successive one-year periods
                    thereafter (each additional one-year period to be considered an
                    “Extended Term” and together with the Initial Term, the “Term”),
                    unless terminated at the end of the Initial Term or the end of an
                    Extended Term upon at least 30 days written notice delivered prior to
                    the end of such Initial Term or Extended Term, as applicable.
                </div>
            </li>
            <li data-list-text="10.">
                <div style="
            padding-top: 10pt;
            padding-left: 64pt;
            text-indent: -36pt;
            text-align: justify;
          ">
                    <span><u><b>Termination</b></u>.</span>
                    These Terms shall continue until terminated: (i) by Ecolab with seven
                    (7) days’ prior written notice to Customer for any reason or no
                    reason; (ii) by Customer with 30 days’ written notice to Ecolab prior
                    to the end of the applicable Initial Term or Extended Term as set
                    forth in Section 9;
                </div>
                <div style="padding-left: 64pt; text-indent: 0pt; text-align: justify">
                    (iii) by Ecolab immediately in the event of a breach of the
                    confidentiality or license provisions of these Terms by you or
                    Customer; (iv) by Ecolab in the event of a breach of these Terms or
                    any Ecolab Standard Agreement by Customer which remains uncured 30
                    days after written notice to the Customer. Upon the effective date of
                    termination of these Terms, Ecolab will immediately cease providing
                    the Program and all usage rights granted to Customer under these
                    Terms, including but not limited to the License, will automatically
                    terminate without any further action by Ecolab. If these Terms are
                    terminated, Ecolab shall provide Customer a reasonable opportunity to
                    remove Customer Data at Customer’s sole cost; provided, that if
                    Customer fails to remove such Customer Data within a reasonable time,
                    not to exceed sixty (60) days following such termination, then Ecolab
                    may retain or destroy such Customer Data without liability with
                    respect thereto. Customer will assist Ecolab with expediting the
                    retrieval of any Software, Documentation or Products that remain the
                    ownership of Ecolab, and Customer will make same available to Ecolab
                    for de-installation and removal. Returned Product must be in the same
                    condition as when received by Customer, reasonable and ordinary wear
                    and tear excepted. Termination shall not relieve Customer of its
                    obligations under these Terms with respect to the payment of all fees
                    and other expenses that have accrued up to and including the
                    termination date or that Customer has agreed to pay. Any
                    confidentiality and any indemnification, defense and hold harmless
                    rights and obligations in these Terms, and any other right or
                    obligation of the Parties hereunder that, by its nature, should
                    survive termination or expiration of these Terms, will survive any
                    expiration or termination of these Terms, including without
                    limitation: Sections 3; 4; 5; 6; 8; 10; 12; 13; 14; 15; 16; 17; and 18
                    and the DPA.
                </div>
            </li>
            <li data-list-text="11.">
                <div style="
            padding-top: 10pt;
            padding-left: 64pt;
            text-indent: -36pt;
            text-align: justify;
          ">
                    <span><u><b>Fees and Taxes</b></u>.</span>
                    Ecolab shall charge a fee for the Program as set forth in the Ecolab
                    Standard Agreement or as otherwise outlined in the specific Program
                    proposal, details, work order, or similar documentation. Except as
                    otherwise agreed between the Parties, all payments are due within 30
                    days following the invoice date. Without prejudice to its other rights
                    and remedies, if Ecolab does not
                </div>
                <div style="
            padding-top: 4pt;
            padding-left: 64pt;
            text-indent: 0pt;
            text-align: justify;
          ">
                    receive any payment within 30 days from the date it is due, Ecolab may
                    terminate the Program and/or, Subject to the terms of any Ecolab
                    Standard Agreement, assess a late payment charge on the unpaid amount
                    at the rate of 1.5% per month or the highest rate allowed under
                    Applicable Law, whichever is higher. Customer shall reimburse Ecolab
                    for all reasonable costs related to any proceedings to collect any
                    past-due amounts, including without limitation attorneys’ fees and any
                    collection agency cost. Ecolab reserves the right, at any time, to
                    change any fees or charge fees for using the Program (or to begin
                    charging fees for any free aspects of a Program), provided that such
                    changes will not apply to fees or charges paid prior to the time of
                    the change. We may charge Customer sales tax as applicable to the
                    Program or any order placed through the Program. Customer will be
                    responsible for all other taxes, levies, duties or similar charges.
                </div>
            </li>
            <li data-list-text="12.">
                <div style="
            padding-top: 10pt;
            padding-left: 64pt;
            text-indent: -36pt;
            text-align: justify;
          ">
                    <span><u><b>Rental and Purchase of Products</b></u>.</span>
                    You, on behalf of yourself and the Customer, agree to following terms
                    and conditions for rental or use of Ecolab-owned products or other
                    equipment, parts and/or items (“Equipment”) in connection with
                    Program: (a) Equipment shall remain the sole property of Ecolab; (b)
                    Customer shall not use the Equipment with any materials or products
                    other than those recommended or approved by Ecolab; (c) Customer shall
                    not alter the Equipment or permit any third- party to alter or repair
                    the Equipment without Ecolab’s prior written authorization; (d)
                    Customer assumes all risk of loss or liability arising from or
                    pertaining to its possession, operation or use of the Equipment, and
                    shall indemnify, defend and hold Ecolab and its Affiliates harmless
                    from all Claims (as defined herein) arising from Customer’s possession
                    and use of the Equipment except to the extent damage to the Equipment
                    is caused by Ecolab’s or its Affiliates’ negligence or willful
                    misconduct. Upon termination of these Terms by either Ecolab or
                    Customer, Customer shall return Equipment to Ecolab at Customer’s sole
                    expense in the same condition as received, ordinary wear and tear
                    excepted. You, on behalf of yourself and the Customer, agree to
                    promptly notify Ecolab in writing of any damage, loss or destruction
                    of Equipment. In the event Equipment is lost, damaged or destroyed,
                    Customer shall pay to Ecolab the cost of replacement, or of repair at
                    Ecolab’s standard charges then in effect.
                </div>
            </li>
            <li data-list-text="13.">
                <div style="
            padding-top: 9pt;
            padding-left: 64pt;
            text-indent: -36pt;
            text-align: justify;
          ">
                    <span><u><b>Ecolab Warranties</b></u>.</span>
                    EXCEPT TO THE EXTENT OTHERWISE AGREED IN THESE TERMS, THE PROGRAM,
                    SOFTWARE, PRODUCTS, AND DOCUMENTATION ARE PROVIDED TO CUSTOMER “AS IS”
                    AND “WHERE IS” AND “WITH ALL FAULTS AND DEFECTS” WITHOUT WARRANTY OF
                    ANY KIND.
                </div>
            </li>
            <li data-list-text="14.">
                <div class="s1" style="
            padding-top: 9pt;
            padding-left: 63pt;
            text-indent: -35pt;
            text-align: left;
          ">
                    <span><u><b>DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY</b></u>.</span>
                </div>
                <ol id="l10">
                    <li data-list-text="a.">
                        <div style="
                padding-top: 10pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            ECOLAB DOES NOT WARRANT UNINTERRUPTED OR ERROR-FREE OPERATION OF
                            THE PROGRAM, SOFTWARE, PRODUCTS OR SERVICES OR THE LIFE OF ANY URL
                            OR THIRD-PARTY WEB SERVICE. THE WARRANTIES IN ARTICLE 13 ABOVE ARE
                            THE EXCLUSIVE WARRANTIES FROM ECOLAB AND ECOLAB DISCLAIMS ALL
                            OTHER WARRANTIES, EXPRESS OR IMPLIED, INCLUDING WARRANTIES OF
                            MERCHANTABILITY, TITLE, NON-INFRINGEMENT, FITNESS FOR A PARTICULAR
                            PURPOSE, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF DEALING,
                            COURSE OF PERFORMANCE, USAGE OR TRADE PRACTICE. ECOLAB PROVIDES NO
                            WARRANTY OR UNDERTAKING, AND MAKES NO REPRSENTATIONS OF ANY KIND
                            THAT THE SOFTWARE, DOCUMENTATION, PRODUCT OR SERVICES WILL MEET
                            THE CUSTOMER’S REQUIREMENTS, ACHIEVE ANY INTENDED RESULTS, BE
                            COMPATIBLE OR WORK WITH ANY OTHER SOFTWARE, APPLICATIONS, SYSTEMS
                            OR SERVICES, OPERATE WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR
                            RELIABILITY STANDARDS OR BE ERROR FREE OR THAT ANY ERRORS OR
                            DEFECTS CAN OR WILL BE CORRECTED. TO THE EXTENT ANY PRODUCT OR
                            SERVICE RUNS ON OR RELIES UPON ANY THIRD-PARTY PRODUCT OR SERVICE,
                            ECOLAB SHALL HAVE NO LIABILITY OR RESPONSIBILITY FOR SUCH THIRD-
                            PARTY PRODUCT OR SERVICE, VULNERABILITY OF, FLAW OF OR DOWNTIMES
                            CAUSED BY SUCH THIRD-PARTY PRODUCTS OR SERVICES.
                        </div>
                    </li>
                    <li data-list-text="b.">
                        <div style="
                padding-top: 10pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            CUSTOMER USES PROGRAM AT ITS OWN DISCRETION AND RISK. EXCEPT AND
                            ONLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW, CUSTOMER WILL BE
                        </div>
                        <div style="
                padding-top: 4pt;
                padding-left: 82pt;
                text-indent: 0pt;
                text-align: justify;
              ">
                            SOLELY RESPONSIBLE FOR (AND ECOLAB DISCLAIMS) ANY AND ALL LOSS,
                            LIABILITY OR DAMAGES RESULTING FROM CUSTOMER’S USE OF THE
                            SOFTWARE, DOCUMENTATION, PRODUCTS AND SERVICES INCLUDING, BUT NOT
                            LIMITED TO, PERSONAL INJURY, PROPERTY DAMAGES, INCLUDING WITHOUT
                            LIMITATION, DAMAGE OR LOSS TO CUSTOMER’S COMPUTER SYSTEMS,
                            SOFTWARE, WATER TREATMENT SYSTEMS, HEATING AND COOLING SYSTEMS AND
                            OTHER ASSETS, PROPERTY OR ITEMS AT THE LOCATION.
                        </div>
                    </li>
                    <li data-list-text="c.">
                        <div style="
                padding-top: 10pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            UNLESS PROHIBITED OR RESTRICTED BY LAW, ECOLAB’S AND ITS
                            AFFILIATES, INCLUDING ANY OF ITS OR THEIR RESPECTIVE LICENSORS,
                            CUMULATIVE AGGREGATE LIABILITY FOR CLAIMS ARISING OUT OF OR IN
                            CONNECTION WITH THESE TERMS SHALL NOT EXCEED THE AGGREGATE FEES
                            PAID BY CUSTOMER TO ECOLAB DURING THE TRAILING SIX-MONTH PERIOD
                            PRIOR TO THE DATE ANY SUCH CLAIM AROSE.
                        </div>
                    </li>
                    <li data-list-text="d.">
                        <div style="
                padding-top: 10pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            UNLESS PROHIBITED OR MODIFIED BY LAW AND EXCEPT FOR GROSS
                            NEGLIGENCE, WILLFUL MISCONDUCT, AND FRAUD, NEITHER ECOLAB, NOR ITS
                            AFFILIATES NOR ANY OF ITS RESPECTIVE LICENSORS OF ECOLAB, WILL BE
                            LIABLE TO YOU OR TO CUSTOMER FOR ANY INDIRECT, ECONOMIC,
                            CONSEQUENTIAL, INCIDENTAL, EXEMPLARY, PUNITIVE OR SPECIAL DAMAGES
                            OR, WHETHER DIRECT OR INDIRECT, ANY LOST PROFITS, LOSS OF BUSINESS
                            REVENUES OR EARNINGS, LOSS OF USE, LOSS OR CORRUPTION OF DATA,
                            LOSS OF SAVINGS, LOSSES BY REASON OF COST OF CAPITAL, A FAILURE TO
                            REALIZE EXPECTED SAVINGS OR DELAYS, LOSS OR INTERRUPTION OF
                            SERVICE, SYSTEMS OR SYSTEMS SERVICE FAILURES, MALFUNCTION OR
                            SHUTDOWN, OR FOR ANY USE, INTERRUPTION, DELAY OR INABILITY TO USE
                            THE SOFTWARE, DOCUMENTATION, PRODUCTS OR THE FAILURE TO TRANSFER,
                            READ OR TRANSMIT INFORMATION, FAILURE TO UPDATE OR PROVIDE CORRECT
                            INFORMATION, SYSTEM INCOMPATIBILITY OR PROVISION OF INCORRECT
                            COMPATIBILITY INFORMATION OR ANY BREACHES IN SYSTEM SECURITY,
                            DIRECTLY OR INDIRECTLY ARISING OUT OF, OR IN CONNECTION WITH THE
                            PROGRAM OR ACTIONS UNDER THESE TERMS, WHETHER OR NOT SUCH DAMAGES
                            COULD REASONABLY BE FORESEEN OR THEIR LIKELIHOOD HAS BEEN
                            DISCLOSED TO ECOLAB, AND REGARDLESS OF WHETHER A CLAIM IS BASED ON
                            CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE AND STRICT
                            LIABILITY), VIOLATION OF ANY APPLICABLE UNFAIR OR DECEPTIVE TRADE
                            PRACTICES ACT, OR ANY OTHER LEGAL OR EQUITABLE PRINCIPLE.
                        </div>
                    </li>
                </ol>
            </li>
            <li data-list-text="15.">
                <div style="
            padding-top: 10pt;
            padding-left: 63pt;
            text-indent: -35pt;
            text-align: left;
          ">
                    <span><u><b>Indemnity</b></u>.</span>
                </div>
                <ol id="l11">
                    <li data-list-text="a.">
                        <div style="
                padding-top: 9pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            Without limiting Customer’s obligations set forth in this
                            Agreement, Customer shall defend, indemnify and hold harmless
                            Ecolab and its Affiliates, and their officers, directors,
                            employees and representatives, from and against all claims,
                            judgments, damages, liabilities, actions, demands, costs,
                            expenses, or losses, including, without limitation, reasonable
                            attorneys’ fees and costs related thereto (“Claim”), to the extent
                            resulting from, arising out of, or in connection with or related
                            to: (i) Customer’s, its Authorized User or Customer’s Affiliate’s,
                            and your, use, misuse, or access of the Program, Software,
                            Deliverables, Products or Services or materials or information
                            related thereto; or (ii) violation of any Applicable Law by
                            Customer, its Affiliates, you, or any other third party on behalf
                            of Customer. Customer’s obligations under this Section 15 will not
                            apply to the extent any Claims are the result of Ecolab’s gross
                            negligence or willful misconduct. Customer will promptly notify
                            Ecolab in writing of the respective Claim, and will permit the
                            Customer to investigate, settle, defend and solely control such
                            defense or settlement, provided that such settlement does not
                            impose obligations upon Ecolab. Ecolab will reasonably cooperate,
                            at Customer’s cost, in the investigation and defense of such
                            matters. Ecolab will have the right, but not the obligation, to be
                            represented by counsel of its own selection and at its own sole
                            expense.
                        </div>
                    </li>
                    <li data-list-text="b.">
                        <div style="
                padding-top: 4pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            If a credible claim is made by a third party that the Software,
                            Documentation, Products or Services or any portion thereof
                            infringes a U.S. patent, copyright, trademark or misappropriates a
                            trade secret, upon receipt of Customer’s notice of such Claim,
                            Ecolab will have the option, in its sole discretion, to (i)
                            replace such Software, Documentation, Product or Service with
                            software, documentation, product or service that is
                            non-infringing; (ii) modify such Software, Documentation, Product
                            or Service to make it non-infringing; or (iii) remove such
                            Software, Documentation, Product or Service and refund to Customer
                            all applicable fees paid to Ecolab after deduction of an
                            appropriate charge based on use by Customer prior to such removal
                            of such Software, Documentation, Product or Service. The foregoing
                            is your and Customer’s sole remedy, and Ecolab’s sole
                            responsibility, for any Claim of third-party infringement based on
                            the Software, Documentation, Products or Services. The foregoing
                            shall not apply in the event any Claim arises out of (a) any
                            Software, Documentation, Products or Services that have been
                            altered or modified by any party other than Ecolab or (b) the use
                            of any product in combination with other equipment and materials
                            not furnished directly by Ecolab.
                        </div>
                    </li>
                    <li data-list-text="c.">
                        <div style="
                padding-top: 9pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            Notwithstanding anything to the contrary herein, Ecolab will have
                            no liability for any Claim based on or relating to: (i) Customer
                            Data as provided or made available by Customer; (ii) any
                            modification of the Program including Software, Documentation,
                            Products or Services not authorized in writing by Ecolab; or (iii)
                            the use of the Program Software, Documentation, Products and/or
                            Services other than in accordance with these Terms.
                        </div>
                    </li>
                </ol>
            </li>
            <li data-list-text="16.">
                <div style="
            padding-top: 10pt;
            padding-left: 64pt;
            text-indent: -36pt;
            text-align: justify;
          ">
                    <span><u><b>Assignment</b></u>.</span>
                    Customer shall not assign or otherwise transfer any of its rights, or
                    delegate or otherwise transfer any of its obligations or performance,
                    under these Terms, in each case whether voluntarily, involuntarily, by
                    operation of law, merger, change of control, consolidation,
                    reorganization or otherwise, without Ecolab’s prior written consent,
                    which consent Ecolab may give or withhold in its sole discretion. No
                    delegation or other transfer will relieve Customer of any of its
                    obligations or performance under these Terms. Ecolab may assign all or
                    part of these Terms without Customer’s consent. Ecolab is expressly
                    permitted to utilize subcontractors which may include the disclosure
                    to a subcontractor of Customer Data, provided that such subcontractor
                    has agreed to reasonable means to keep Customer Data confidential.
                </div>
                <div style="text-indent: 0pt; text-align: left"><br /></div>
            </li>
            <li data-list-text="17.">
                <div style="padding-left: 64pt; text-indent: -36pt; text-align: justify">
                    <span><u><b>Intellectual Property</b></u>.</span>
                    Without limiting Customer’s obligations set forth in any Ecolab
                    Standard Agreement or these Terms, Customer shall promptly notify
                    Ecolab if Customer becomes aware of any infringement of Ecolab’s
                    Intellectual Property Rights in Software, Documentation, Products or
                    Services and fully cooperate with Ecolab in any legal action taken by
                    Ecolab to enforce its Intellectual Property Rights.
                </div>
            </li>
            <li data-list-text="18.">
                <div style="
            padding-top: 9pt;
            padding-left: 63pt;
            text-indent: -35pt;
            text-align: left;
          ">
                    <span><u><b>General</b></u>.</span>
                </div>
                <ol id="l12">
                    <li data-list-text="a.">
                        <div style="
                padding-top: 10pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            Unless otherwise agreed in writing, to the extent any terms or
                            conditions of these Terms vary from or conflict with any
                            preexisting Ecolab Standard Agreement between the Parties, the
                            terms and conditions of such Ecolab Standard Agreement shall
                            govern and have precedence with respect to the matters covered by
                            these Terms, including without limitation the Program, Software,
                            Documentation, Products or Services. In the event of a conflict
                            between the terms of any Ecolab Standard Agreement and these Terms
                            including the DPA and any other terms, the terms in the following
                            order shall govern over terms contained in subsequent documents:
                            portions of any Ecolab Standard Agreement that expressly calls out
                            the particular sections or provisions in these Terms that are
                            intended to be amended shall control and take precedence, followed
                            by these Terms, the remainder of any Ecolab Standard Agreement and
                            the DPA, and finally any other mutually agreed terms. You agree
                            that any representations and warranties made by you together with
                            any covenants or obligations you have assumed, in these Terms
                            shall be made jointly and severally with Customer.
                        </div>
                    </li>
                    <li data-list-text="b.">
                        <div style="
                padding-top: 9pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            Neither you nor Customer, on the one hand, and Ecolab, on the
                            other hand, will incur any liability to the other Party on account
                            of any loss or damage resulting from any delay or failure to
                            perform any or all part of these Terms if such delay or failure is
                            caused, in whole or in part, by events or occurrences or causes
                            beyond the reasonable control and without the gross negligence of
                            the
                        </div>
                        <div style="
                padding-top: 4pt;
                padding-left: 82pt;
                text-indent: 0pt;
                text-align: justify;
              ">
                            applicable Party (each such event, “Force Majeure”). Such events
                            and occurrences would include, without limitation, acts of God,
                            strikes, lock outs, riots, acts of war, earthquakes, fires,
                            pandemics, and explosions. For the avoidance of doubt, Customer’s
                            inability to pay the applicable fees to Ecolab for any reason
                            shall not constitute an event of Force Majeure.
                        </div>
                    </li>
                    <li data-list-text="c.">
                        <div style="
                padding-top: 9pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            These Terms shall in all respects as to its validity,
                            interpretation, construction and enforcement be governed by and
                            construed in accordance with the laws of the State of Minnesota
                            without regard to the conflicts of laws rules, provisions or
                            statutes of any jurisdiction. In the event a dispute arising under
                            these Terms results in litigation, the non-prevailing Party shall
                            pay the court costs and reasonable attorneys’ fees of the
                            prevailing Party. The Parties agree that the exclusive
                            jurisdiction for resolution of any disputes arising out of or in
                            connection with the execution, performance and/or termination of
                            these Terms shall be competent courts in the State of Minnesota.
                            The Parties agree that the U.N. Convention on Contracts for the
                            International Sale of Goods will not apply to these Terms.
                        </div>
                    </li>
                    <li data-list-text="d.">
                        <div style="
                padding-top: 10pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            Notice to Ecolab: Any notice or other communication required or
                            permitted hereunder shall be given in writing to Ecolab at 1
                            Ecolab Place, St. Paul, Minnesota, 55102 USA, Attn: General
                            Counsel, or at such other addresses as shall be given by Ecolab to
                            Customer in writing. Such notice shall be deemed to have been
                            given when (a) delivered personally, (b) sent via certified mail
                            (return receipt requested) (c) sent via cable, telegram, telex,
                            email, telecopier, fax (all with confirmation of receipt), or (d)
                            by recognized air courier service. Notice to Customer: Ecolab may
                            provide any notice to Customer using electronic means, whether
                            through the Program or email. Notices sent by email shall be
                            deemed to have been given when Ecolab sends the email and notices
                            sent via the Program shall be deemed given when posted. If Ecolab
                            sends notice by email, it shall use the email address affiliated
                            with your Account.
                        </div>
                    </li>
                    <li data-list-text="e.">
                        <div style="
                padding-top: 10pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            To the extent any Ecolab Standard Agreement between you or
                            Customer on one hand and Ecolab on the other is not previously in
                            effect, these Terms shall constitute all of the terms and
                            conditions agreed upon by you, Customer, and Ecolab with respect
                            to Program, though these terms may be superseded by a separate,
                            signed agreement that specifically indicates that it supersedes
                            these Terms.
                        </div>
                    </li>
                    <li data-list-text="f.">
                        <div style="
                padding-top: 9pt;
                padding-left: 82pt;
                text-indent: -18pt;
                text-align: justify;
              ">
                            Notwithstanding the provisions of Section 18(a) hereof, the
                            Parties agree that these Terms (excluding the fees for the
                            remainder of the annual subscription) may be modified at any time
                            by Ecolab by posting a revised version on this web page (and
                            appropriately linked on the Program login or other pages upon
                            which these Terms previously appeared), or by otherwise notifying
                            you in accordance with Section 18(d) above. The modified terms
                            will become effective, unless otherwise agreed in these Terms or
                            the Ecolab Standard Agreement, upon posting, or, if Ecolab
                            notifies Customer via another method, as stated in such message.
                            By continuing to use the Products or Services after the effective
                            date of any amendments or modifications to these Terms, Customer
                            agrees to be bound by the modified terms.
                        </div>
                    </li>
                </ol>
            </li>
        </ol>
        <div class="agree-checkbox">
            <input type="checkbox" id="agreeCheckbox" (change)="handleCheckboxChange($event)" />
            <label for="agreeCheckbox">By checking this box, you are agreeing to Ecolab terms and conditions.</label>
        </div>
    </div>
    <div class="btn-footer">
        <div class="btn-container">
            <button class="btn btn-secondary" (click)="cancel()">Cancel</button>
            <button class="btn btn-primary" [disabled]="!isChecked" (click)="agree()">Agree</button>
        </div>
    </div>
</div>