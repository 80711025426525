import * as contracts from "../contracts/_contracts";

export class Asset implements contracts.IAsset {
    id: string;
    name: string;
    sortOrder?: number;
    type?: string;
    fullyConfigured?: boolean;
    status?: string;
    action: string;
    programType?: string;
    oldParentId?: string;
    isdeleted?: boolean;
    makeupWater?: string;
    pressureRange?: string;
    isElementReady = true;

    constructor(asset?: contracts.IAsset) {
        if (asset) {
            this.id = asset.id;
            this.name = asset.name;
            this.sortOrder = asset.sortOrder;
            this.type = asset.type;
            this.fullyConfigured = asset.fullyConfigured;
            this.status = asset.status;
            this.action = asset.action;
            this.programType = asset.programType;
            this.oldParentId = asset.oldParentId;
            this.isdeleted = asset.isdeleted;
            this.makeupWater = asset.makeupWater;
            this.pressureRange = asset.pressureRange;

            if (typeof asset.isElementReady !== 'undefined') {
                this.isElementReady = asset.isElementReady;
            }
        }
    }
}

export class  TimeZoneCdm implements contracts.ITimeZoneCdm {
    TimeZoneId?: number = 0;
    MsWindows?: string = '';
    Unix?: string = '';
    Trasar3?: string = '';
    ModifiedBy?: number = 0;
    ModifiedOn?: string = '';
    CdmTimeZone?: string = '';
    constructor(TimeZone?: contracts.ITimeZoneCdm) {
        Object.assign(this, TimeZone);
    }
}
