import { Component, ElementRef, HostListener, NgModule, OnInit } from '@angular/core';
import { SearchComponent } from '../search/globalsearch.component';

@Component({
  selector: 'nalco-global-search-dropdown',
  templateUrl: './globalsearchdropdown.component.html',
  styleUrls: ['./globalsearchdropdown.component.css']
})

export class GlobalSearchDropdownComponent {
  
  showSearchPopup = false;
  constructor(private elementRef: ElementRef){}
  /**
   * @description on click of search icon will enable the search box
   */
  onSearchIconClick() : void
  {
    this.showSearchPopup = true;
    const fakeEvent = new KeyboardEvent('keydown', {
      key: 'm',
      code: 'KeyM',
      ctrlKey: true,
      bubbles: true,
      cancelable: true,
      view: window
    });
    document.dispatchEvent(fakeEvent);
  }
  /**
   * @description reverse the state of pop is present removes otherwise shows it
   */
  onclick() : void {
    this.showSearchPopup=!this.showSearchPopup;
  }
  /**
   * @description removes pop up when click outside of screen
   * @param searchKey mouse click event
   */
  @HostListener('document:click', ['$event'])  
  onDocumentClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.showSearchPopup = false;
      document.body.classList.remove('app-blur');
    }
  }
  /**
   * @description shows the popup for search
   * @param searchKey ctrl + M as shortcut
   */
  @HostListener('document:keydown.control.m', ['$event'])
  onCtrlM(event: KeyboardEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.showSearchPopup = true;
    }
  }
}
