import * as contracts from '../contracts/_contracts';

export class AssetMeasurement implements contracts.IAssetMeasurement {

    name?: string;
    value?: string;
    isCommonCategory?: boolean;
    category?: string;

    constructor(assetMeasurement?: contracts.IAssetMeasurement) {
        if (assetMeasurement) {
            this.value = assetMeasurement.value;
            this.name = assetMeasurement.name;
            this.isCommonCategory = assetMeasurement.isCommonCategory;
            this.category = assetMeasurement.category;
        }
    }
}
