import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimal'
})
export class DecimalPipe implements PipeTransform {

  transform(value: any, limit: any): any {
    if (value != null && value != undefined && value != "") {
      console.log(value ,':::::::',Number(value).toFixed(limit));
      
      return Number(value).toFixed(limit);
    }
    else {
      return value;
    }
  }

}
