/* eslint-disable @typescript-eslint/consistent-type-imports */
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs/Observable';
import { AppEnvConfig } from './../../../app.env.config';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { GlobalSearchFiltersServiceConfig } from './global-search-filters.service.config'
import { Config } from './../contracts/configEnv';
import { SearchHistoryModal, SearchRequesModel, SearchHistoryResultsModal, SearchResultModal } from './../contracts/modal';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { takeUntil, debounceTime } from 'rxjs/operators';


@Injectable()

export class GlobalSearchFiltersService {

  private configEnv: Config;
  // Private subject to handle cancelling
  private cancelPendingRequests = new Subject<void>();
  private cancelTokens: Map<string, Subject<void>> = new Map();



  constructor(private globalSearchFiltersServiceConfig: GlobalSearchFiltersServiceConfig,
    private httpClient: HttpClient,
    private config: AppEnvConfig) {
    this.configEnv = globalSearchFiltersServiceConfig.getEnvironment();
    this.configEnv.apiServerUrl = config.getEnv('apiServerUrl');
    this.configEnv.jsonServerUrl = config.getEnv('jsonServerUrl');
    this.configEnv.apiServerUrlAuth = config.getEnv('apiServerUrl');
    this.configEnv.userContextAggregatorServiceUrl = config.getEnv('usercontextaggserviceUrl');
  }


  getSearchResult(serviceRequest : SearchRequesModel,instanceId : string,debounceMs : number) {
    const url = this.configEnv.userContextAggregatorServiceUrl + this.configEnv.apiUrl.getContextSearch;
    return this.cancelMultipleRequestByInstanceId<any>(url, 'POST', serviceRequest, instanceId, debounceMs);
  }


  getGlobalSearchResult(searchRequest: SearchRequesModel): Observable<any> {
    // const url = this.configEnv.userContextAggregatorServiceUrl + this.configEnv.apiUrl.getContextSearch;

    // Signal any pending request to cancel
    this.cancelPendingRequests.next();

    const url = this.configEnv.userContextAggregatorServiceUrl + this.configEnv.apiUrl.getContextSearch;

    // Create a new observable to be returned
    // The observable is responsible for making the HTTP request,
    // listening for cancellation, and emitting the HTTP response
    const requestObservable = new Observable<any>(observer => {
      // Create an inner subscription to handle the request
      const subscription = this.httpClient.post(url, searchRequest)
        .pipe(debounceTime(400), takeUntil(this.cancelPendingRequests))
        .subscribe(
          (response) => {
            observer.next(response);
            observer.complete();
          },
          (error) => {
            observer.error(error);
          }
        );

      // Return the teardown logic which will be invoked when the
      // consumer unsubscribes, it ensures cancellation of the request
      return () => {
        this.cancelPendingRequests.next();
        subscription.unsubscribe();
      };
    });

    return requestObservable;

  }

  /**
  * Generic API method to handle HTTP requests with dynamic inputs.
  * @param url - API endpoint URL
  * @param method - HTTP method (GET, POST, PUT, DELETE, etc.)
  * @param payload - Request payload (for POST, PUT, DELETE)
  * @param instanceId - Unique identifier for request cancellation
  * @param debounceMs - Debounce time in milliseconds
  * @returns Observable of the HTTP response
  */
  cancelMultipleRequestByInstanceId<T>(
    url: string,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    payload: any = null,
    instanceId: string,
    debounceMs: number
  ): Observable<T> {
    // Ensure a cancellation token exists for this instanceId
    if (!this.cancelTokens.has(instanceId)) {
      this.cancelTokens.set(instanceId, new Subject<void>());
    }

    const cancelSubject = this.cancelTokens.get(instanceId);

    // Signal cancellation for any ongoing request tied to this instanceId
    cancelSubject?.next();

    return new Observable<T>((observer) => {
      // Determine the HTTP method
      let httpCall: Observable<T>;
      switch (method) {
        case 'GET':
          httpCall = this.httpClient.get<T>(url);
          break;
        case 'POST':
          httpCall = this.httpClient.post<T>(url, payload);
          break;
        case 'PUT':
          httpCall = this.httpClient.put<T>(url, payload);
          break;
        case 'DELETE':
          httpCall = this.httpClient.delete<T>(url, { body: payload });
          break;
        default:
          throw new Error(`Unsupported HTTP method: ${method}`);
      }

      // Handle debounce and cancellation
      const subscription = httpCall
        .pipe(debounceTime(debounceMs), takeUntil(cancelSubject!))
        .subscribe(
          (response) => {
            observer.next(response);
            observer.complete();
          },
          (error) => {
            observer.error(error);
          }
        );

      // Cleanup logic
      return () => {
        cancelSubject?.next();
        subscription.unsubscribe();
      };
    });
  }

  /**
   * Clears cancellation token for a specific instanceId.
   * @param instanceId - Unique identifier for request cancellation
   */
  clearCancelToken(instanceId: string): void {
    if (this.cancelTokens.has(instanceId)) {
      this.cancelTokens.get(instanceId)?.complete();
      this.cancelTokens.delete(instanceId);
    }
  }


  /**
 * @description This function is used get the search results based on the user access and roles based
 * @returns search results
 */

  public getUserContextSearchHistory(): Observable<SearchHistoryResultsModal[]> {
    const url = this.configEnv.userContextAggregatorServiceUrl + this.configEnv.apiUrl.getUserContextSearchHistory
      + '?count=10'
    return this.httpClient.get<SearchHistoryResultsModal[]>(url).map(response => {
      return response
    });
  }



  /**
* @description This function is used get the search results based on the user access and roles based
* @returns search results
*/

  public saveUserContextSearchHistory(data: SearchHistoryModal): Observable<null> {
    const url = this.configEnv.userContextAggregatorServiceUrl + this.configEnv.apiUrl.saveUserContextSearchHistory
    return this.httpClient.post<null>(url, data).map(response => {
      return response
    });
  }

  /**
* @description This function is used clear the duplicate results based on the search history results
* @param event 
* @returns filtered and final duplicate removed search history
*/


  removeDuplicates(event: string[]) {
    const finalArray = event.filter(function (value, index) {
      return event.indexOf(value) == index;
    })
    return finalArray
  }


}










