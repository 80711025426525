import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable()
export class SidePanelService {

    constructor() {

    }

    private openSidePanel = new BehaviorSubject<boolean>(false); // to trigger the side panel open
    triggerSidePanel = this.openSidePanel.asObservable();
    public sidePanelDataPass = new BehaviorSubject<any>({});
    triggerSidePanelData = this.sidePanelDataPass.asObservable();

        /**
    * @description This function is used trigger the open call for the side drawer
    */

    openSideWindow(value: boolean, data?: any) : void {
        this.openSidePanel.next(value);
        if(data) this.sidePanelDataPass.next(data);
        if (value === false) {
          document.body.classList.remove('overlay-body');     
        }
    }

}
