<div class="config-popup">
    <div class="modal-header">
      <h4 class="modal-title" translate>Gateway Statistics</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
        <img src="../../../../assets/images/modal-close-icon.svg" alt="close" />
      </button>
    </div>
    <div class="mb-0 delete-content">
      <nalco-loader [loader]="isLoading" [transparentBG]="true" [fitToScreen]="true"></nalco-loader>
      <div class="account-name modal-header">
        <span> {{'CONTROLLER_MANAGEMENT.SERIAL_NO' | translate}} : {{SerialNo}} </span> <br/>
        <span> {{'CONTROLLER_MANAGEMENT.LAST_FILE_RECEIVED' | translate}} : {{LastDataReceived | date : "yyyy-MM-dd h:mm:ss"}}</span>
      </div>
        <div class="table-wrapper" #accountResults infiniteScroll [infiniteScrollContainer]="accountResults"
        [infiniteScrollDistance]="1"  [infiniteScrollThrottle]="500"   [scrollWindow]="false">
        <kendo-grid  [data]="viewData">
        <kendo-grid-messages noRecords="{{'COMMON.NO_RECORDS' | translate}}" 
          pagerItemsPerPage="{{'COMMON.ITEMS_PER_PAGE' | translate}}">
        </kendo-grid-messages>
        <kendo-grid-column field="ParameterName" [title]="'CONTROLLER_MANAGEMENT.PARAMETER_NAME' | translate" [width]="250">
        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-template #tipContent>
            <span title="{{dataItem.ParameterName}}">{{( dataItem.ParameterName ? (dataItem.ParameterName) : '-')}}</span>
          </ng-template>
          <div class="first-col overflow-ellipsis" #element
            [ngbTooltip]="element?.offsetWidth+2 < element.scrollWidth ? tipContent: ''" container="body">
            <span>{{( dataItem.ParameterName ? (dataItem.ParameterName) : '-')}}</span>
          </div> 
          </ng-template>
        </kendo-grid-column>
        
        <kendo-grid-column field="Value" [title]="'CONTROLLER_MANAGEMENT.VALUE' | translate">
        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-template #tipContent>
            <span title="{{dataItem.Value}}">{{( dataItem.Value ? dataItem.Value.toFixed(2) : (dataItem.ValueText ? dataItem.ValueText : '-'))}}</span>
          </ng-template>
          <div class="first-col overflow-ellipsis" #element
            [ngbTooltip]="element?.offsetWidth+2 < element.scrollWidth ? tipContent: ''" container="body">
            <span>{{( dataItem.Value ? dataItem.Value.toFixed(2) : (dataItem.ValueText ? dataItem.ValueText : '-'))}}</span>
          </div> 
        </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="ViewChart" [sortable]="false" [title]="'CONTROLLER_MANAGEMENT.VIEW_CHART' | translate">
          <ng-template kendoGridCellTemplate let-dataItem>
            <ng-template #tipContent>
              <span title="{{dataItem.Value}}">{{( dataItem.Value ? dataItem.Value.toFixed(2) : (dataItem.ValueText ? dataItem.ValueText : '-'))}}</span>
            </ng-template>
            <div class="first-col" #element [ngbTooltip]="element?.offsetWidth+2 < element.scrollWidth ? tipContent: 'View Chart'"
              container="body">
              <span>
                <img *ngIf="dataItem.MeasureInstanceId && !dataItem.ValueText " alt="View Chart" (click)="onViewChartClick(dataItem)" class="view-chart-icon" src="../../../../../assets/images/trendCharts_icon-01.svg" >    
              </span>
            </div>
          </ng-template>
        </kendo-grid-column>
  
        </kendo-grid>
        </div>
     </div> 
  </div>
