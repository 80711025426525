import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ControllerManagementService } from '../../../controller-management/_services/controller-management.service';
import { LocalCacheService } from '../../../core/local-cache/local-cache.service';
import { SharedService } from '../../services/shared-services.service';
import { Subscription } from 'rxjs/Subscription';
import { ErrorHandling } from '../error-handling/error-handling';
import { Router } from '@angular/router';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { ComponentState } from '../xd-container/xd-component-state';
import { XdComponentState } from '../xd-container/xd-component';
import { ViewTrendChartComponent } from '../view-trend-chart/view-trend-chart.component';

@Component({
  selector: 'nalco-system-health',
  templateUrl: './system-health.component.html',
  styleUrls: ['./system-health.component.scss']
})
export class SystemHealthComponent extends XdComponentState implements OnInit {
    @Input() SiteCpId;
    @Input() SerialNo = '';
    @Input() LastDataReceived;
    @Input() ControllerCpId
    isLoading: boolean =false;
    subscription: Subscription;
    errorHandling = new ErrorHandling(this.modalService, this.translate );
    errorInfo: any;
    isError = false;
    configurationList =[];
    viewData : GridDataResult;
    modalRef: any;
  constructor(private translate: TranslateService,
     public activeModal: NgbActiveModal,
     public controllerManagementService : ControllerManagementService,
     private localCacheService: LocalCacheService,
     private modalService: NgbModal,
     private router: Router) {super()
  }
  ngOnInit() {
    this.getSystemHealth(); 
  }
  getSystemHealth() {
    this.isLoading = true;
    this.controllerManagementService.getSystemHealthParameters(this.ControllerCpId).subscribe(response => {
      if (response) {
        this.configurationList.push(response);
        for(let i=this.configurationList[0].length-1;i>=0;i--) {{
           if(this.configurationList[0][i].ParameterName === null)
            {
              this.configurationList[0].splice(i,1);
            }
        }}
        this.viewData = {
          data: this.configurationList[0],
          total: this.configurationList[0].length,
        };
        
        this.isLoading = false;
      }
    }, error => {
      this.isLoading = false;
      this.errorInfo = error;
      if (500 <= error.status && error.status < 600) {
      } else if (error.status === 417) {
        this.router.navigate(['/access-denied']);
      } else if (error.status === 404) {
        this.router.navigate(['/**']);
      } else {
        this.errorHandling.showErrorPopup(error);
      }
    });
  }
  onViewChartClick(data){
    this.modalRef = this.modalService.open(ViewTrendChartComponent,
      {backdrop: 'static', keyboard: false }).componentInstance;
        this.modalRef.MeasurementInstanceId = data.MeasurementInstanceId;
        this.modalRef.SiteCpId = this.SiteCpId;
        this.modalRef.ParameterName = data.ParameterName;
        this.modalRef.HeaderTitle = 'System Health';
        this.modalRef.SerialNo = this.SerialNo;
        this.modalRef.LastDataReceived = this.LastDataReceived;
    }
}