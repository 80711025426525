import { Component, Input, Output, EventEmitter } from '@angular/core';

import { ISubheaderRightSection } from '../sub-header.modal';
import { IDropdownEventType } from '../../dropdown/dropdown-model';

@Component({
    selector: 'nalco-subheader-right-section',
    templateUrl: './right-section.component.html'
})
export class SubHeaderRightSectionComponent {
    @Input() details: ISubheaderRightSection;
    @Output() btnClick= new EventEmitter<string>();
    @Output() ddChange = new EventEmitter<IDropdownEventType>();

    btnClickInSubhead(which: string) {
        this.btnClick.emit(which);
    }

    ddChangeInSubheader(data) {
        this.ddChange.emit(data);
    }
}
