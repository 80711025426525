<div class="operating-parameter-dashboard">
    <nalco-tile [parentInstance]="myParentInstance()"   [headerTitle]="" [errorData]='errorInfo' [showMoreOptions]=false [showInfoIcon]=false class="no-header">
        <div tile-content>
            <form class="op-tab" [formGroup]="operatingParameterForm" *ngIf="optdataCount === opData.length-1">
                <div class="col-11">
                    <div *ngFor="let control of opData">
                        <nalco-combo-box [data]="control" [formGroup]="operatingParameterForm"
                            (ddChange)="updateDropdown($event)" (tbChange)="updateInput($event)"></nalco-combo-box>
                    </div>
                </div>
            </form>
        </div>
    </nalco-tile>
</div>