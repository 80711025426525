<div class="modal-header pt-4 pl-4 mb-0 border-0">
   <h3 class="modal-title">      
      <span *ngIf="IsServiceEnabled === true">           
      <nalco-dropdown [ddData]="servicesDropdown"  (ddChange)="serviceChange($event)">
        </nalco-dropdown> 
      </span> 
   </h3>  
      <h3 class="modal-title" *ngIf="IsServiceEnabled === false">
        <span class="iconwater-alert-yellow"></span>
        <!-- <span>{{modalText}}</span>               -->
    </h3>
   <button type="button"  class="close" aria-label="Close" (click)="closeModalPopup()">
      <img src="../../../../assets/images/modal-close-icon.svg" alt="close" />
    </button>
</div>
<p class="revert mb-0"  *ngIf="IsServiceEnabled === false"></p>   <!--[innerHtml]="modcontent"-->
<div class="modal-footer">    
    <button type="button" class="btn btn-primary" *ngIf="IsServiceEnabled === true" (click)="navigateToSite(siteId)" translate>COMMON.NAVIGATE</button>   
    <button type="button" class="btn btn-primary" *ngIf="IsServiceEnabled === false" (click)="closeModalPopup()">{{'COMMON.OK' | translate}}</button>
</div>