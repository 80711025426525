import { Injectable } from '@angular/core';
import { APP_CONFIG, ENVIRONMENTS } from '../../app.config';

@Injectable({
    providedIn: 'root'
})
export class SharedServiceConfig {

    private configMock: any = { // MOCK - local json settings
        environment: ENVIRONMENTS.mock,
        baseUrl: '',
        apiUrl: {
            getServiceCategoriesAndAssetTypes: '/api/v1/ContextPoints/ServiceCategoriesAndAssetTypes',
            getsites: 'SitePagingByUser',
            getSitesForCorporates: 'CustomerSites',
            getSiteCompliance: 'sites/{0}/compliance',
            getMeasurementsToCompare: '/sites/{0}/assetsmeasurements',
            getUsersNavigationMenu: 'users/{0}/navigationmenu',
            getDagAccountChildren: 'DagCorporateAccountChildren/',
            getDagParentEdgeChildren: 'DagParentEdgeChildren/',
            getDagParentEdgeMultipleChild: 'api/v1/ContextPoints/DagCVoCParentsEdgeChildren',
            getDagSalesRegionDistrictTerritories: 'DagSalesRegionDistrictTerritories/NalcoWater',
            getDagRegionCountries: 'DagRegionCountries/NalcoWater',
            getCurrencies: 'Currencies',
            getFactor: 'Currency/Convert',
            getProjectOverview: 'financialimpact/api/projects/',
            getCorporateSetting: 'financialimpact/api/corporatepreferences/',
            getActionLogRecommendation: 'api/v1/Recommendations/RecommendationSets/',
            getAssets: 'SiteAssetCurrentStatus',
            getResponsiblePerson: 'Users',
            saveActionLogRecommendation: 'api/v1/Recommendations/Recommendation',
            getAttachmentUri: 'api/v1/Recommendations/RecommendationAttachmentUri',
            getAssetCategoriesMasterData: 'api/v1/ContextPoints/Categories',
            getAssetsByAssetType: 'api/v1/Sites/AssetsByAssetType',
            getAssetCount: 'api/v1/AssetHarvester/TotalAssetsPlannedByDag/{0}',
            savePlannedAssets: 'api/v1/AssetHarvester/MergeTotalAssetsPlanned/{0}/{1}',
            saveOnBoarding: 'api/v1/AssetHarvester/MergeIsonboarding/{0}/{1}',
            getContextPointKpiMapData: "api/v1/SystemAssurance/ContextPointKpiMap",
            getMeasurementsDetails: "api/v1/ContextPoints/Measurements",
            getServiceEnabledSites: 'ServiceEnabled/SitePagingByUser',
            getDataSourceContextPoints: 'api/v1/ContextPoints/DataSourceContextPoints/{0}/{1}',
            getDatasourceDetails: '/api/v1/ContextPoints/DataSourceContextPoints/{0}/{1}/{2}',
            getDatasourceDetailsSiteHarvester: 'SiteManagement/api/v1/DataSourceContextPoints/{0}/{1}/{2}',
            timeZoneCdms: '/api/v1/TimeZoneCdms/TimeZones',
            getServiceEnabled: 'api/v1/Sites/ServiceEnabled/SitePagingByUser'
        }
    };
    private configProd: any = { // PRODUCTION - PRODUCTION ENVIRONMENT SETTINGS
        environment: ENVIRONMENTS.prod,
        baseUrl: '',
        apiUrl: {
            getServiceCategoriesAndAssetTypes: '/api/v1/ContextPoints/ServiceCategoriesAndAssetTypes',
            getsites: 'SitePagingByUser',
            getSitesForCorporates: 'CustomerSites',
            getSiteCompliance: 'sites/{0}/compliance',
            getMeasurementsToCompare: '/sites/{0}/assetsmeasurements',
            getUsersNavigationMenu: 'users/{0}/navigationmenu',
            getDagSalesRegionDistrictTerritories: 'DagSalesRegionDistrictTerritories/NalcoWater',
            getDagRegionCountries: 'DagRegionCountries/NalcoWater',
            getDagAccountChildren: 'DagCorporateAccountChildren/',
            getDagParentEdgeChildren: 'DagParentEdgeChildren/',
            getDagParentEdgeMultipleChild: 'api/v1/ContextPoints/DagCVoCParentsEdgeChildren',
            getCurrencies: 'Currencies',
            getFactor: 'Currency/Convert',
            getProjectOverview: 'financialimpact/api/projects/',
            getCorporateSetting: 'financialimpact/api/corporatepreferences/',
            getActionLogRecommendation: 'api/v1/Recommendations/RecommendationSets/',
            getAssets: 'SiteAssetCurrentStatus',
            getResponsiblePerson: 'Users',
            saveActionLogRecommendation: 'api/v1/Recommendations/Recommendation',
            getAttachmentUri: 'api/v1/Recommendations/RecommendationAttachmentUri',
            getAssetCategoriesMasterData: 'api/v1/ContextPoints/Categories',
            getAssetsByAssetType: 'api/v1/Sites/AssetsByAssetType',
            getAssetCount: 'api/v1/AssetHarvester/TotalAssetsPlannedByDag/{0}',
            savePlannedAssets: 'api/v1/AssetHarvester/MergeTotalAssetsPlanned/{0}/{1}',
            saveOnBoarding: 'api/v1/AssetHarvester/MergeIsonboarding/{0}/{1}',
            getContextPointKpiMapData: "api/v1/SystemAssurance/ContextPointKpiMap",
            getMeasurementsDetails: "api/v1/ContextPoints/Measurements",
            getServiceEnabledSites: 'ServiceEnabled/SitePagingByUser',
            getDataSourceContextPoints: 'api/v1/ContextPoints/DataSourceContextPoints/{0}/{1}',
            getDatasourceDetails: '/api/v1/ContextPoints/DataSourceContextPoints/{0}/{1}/{2}',
            getDatasourceDetailsSiteHarvester: 'SiteManagement/api/v1/DataSourceContextPoints/{0}/{1}/{2}',
            timeZoneCdms: '/api/v1/TimeZoneCdms/TimeZones',
            getServiceEnabled: 'api/v1/Sites/ServiceEnabled/SitePagingByUser'
        }
    };

    private _environment: string = APP_CONFIG.ENVIRONMENT;

    constructor() {
        // void
    }

    public getEnvironment(): any {
        switch (this._environment) {
            case ENVIRONMENTS.mock:
                return this.configMock;
            case ENVIRONMENTS.dev:
                return this.configProd;
            case ENVIRONMENTS.test:
                return this.configProd;
            case ENVIRONMENTS.stg:
                return this.configProd;
            case ENVIRONMENTS.prod:
                return this.configProd;
            default:
                return null;
        }
    }

    public setEnvironment(environment: string): any {
        this._environment = environment;
    }

}
