<div class="modal-header pt-4 pl-4 mb-0 border-0">
    <h3 class="modal-title">
      <span [ngClass]=" (alertIcon == true || alertIcon == undefined) ? 'iconwater-alert-yellow' : ''"></span>
      <span>{{modalText}}</span>
    </h3>
    <button type="button" *ngIf="closeIcon" class="close" aria-label="Close" (click)="closeConfigModal()">
      <img src="../../../../assets/images/modal-close-icon.svg" alt="close" />
    </button>
  </div>
  <div class="revert config-content mb-0">
    <p [innerHtml]="modcontent"></p>
    <p *ngIf="contentLink" translate>ASSET_HARVESTER.MESSAGE.DECISION_TREE <a class="inv-tree-hyperlink" (click)="openDocument('tree')" translate>ASSET_HARVESTER.MESSAGE.CLICK_HERE</a></p>
    <p *ngIf="contentLink" translate>ASSET_HARVESTER.MESSAGE.INVENTORY_FAQ <a class="inv-faq-hyperlink" (click)="openDocument('faq')" translate>ASSET_HARVESTER.MESSAGE.CLICK_HERE</a></p>
  </div>
  <div class="modal-footer config-footer mt-0">
    <button type="button" *ngIf="cancelBtn" class="btn btn-secondary" (click)="closeConfigModal()">{{closeBtn}}</button>
    <button type="button" class="btn btn-primary proceed-btn" (click)="proceedModal()">{{submitBtn}}</button>
  </div>