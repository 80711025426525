import { ICorporatePreference, ICurrency } from '../contracts/ICorporatePreference';

export class CorporatePreference implements ICorporatePreference {
    UnitsOfMeasure: string;
    Currency: ICurrency;
    IsDefault: boolean;

    constructor(corporatePreference?: CorporatePreference) {
        this.UnitsOfMeasure = corporatePreference.UnitsOfMeasure;
        this.Currency = corporatePreference.Currency;
        this.IsDefault = corporatePreference.IsDefault;
    }
}

export class Currency implements ICurrency {
    CurrencyCodeId: string;
    CurrencyName: string;
    Symbol: string;
    constructor(currency?: Currency) {
        this.CurrencyCodeId = currency.CurrencyCodeId;
        this.CurrencyName = currency.CurrencyName;
        this.Symbol = currency.Symbol;

    }
}
