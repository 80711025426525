<div ngbDropdown class="dropdown" [ngClass]="{'disabled': (isDisabled && isDisabled==true), dropdownClassName: dropdownClassName != ''}" display="static">
  <div class="btn-outline-primary drop-btn" id="dropdownBasic1" ngbDropdownToggle [ngClass]="{'disabled': (isDisabled && isDisabled==true)}">
    <span [ngClass]="iconClass!=''? iconClass:'' " class="{{(isDisabled && isDisabled==true) ?'disabled' :''}}" ></span>
    <span *ngIf="(!showSelect && !hideLabelIfSelected) || (hideLabelIfSelected && selectedOptionText()=='')" class="label">{{changeLabel(ddData.label)}}</span>
    <span *ngIf="selectedOptionText()=='' && showSelect" class="label">{{ddData.label}}</span>
    <span class="selected-text" *ngIf="ddData.options.length!=0 && selectedOptionText().trim()!=''" [ngClass]="{'disabled': (isDisabled && isDisabled==true), 'noLabel': hideLabelIfSelected}"
      [innerHTML]="selectedOptionText()" placement="bottom" #element1 [ngbTooltip]="selectedOptionTextVal"  (mouseenter)="getselectedOptionText(element1)"
      container="body"></span>
    <span class="c-three-dots-loader" *ngIf="isDisabled==false && ddData.options.length==0 && selectedOptionText().trim()==''"></span>
  </div>

  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
    <span *ngFor="let option of ddData.options; let i = index">
      <button class="dropdown-header" [hidden]="!option.isHeader">{{option.text}}</button>
      <span *ngIf="option.isImage" class="filter-icon"></span>
      <button class="dropdown-item overflow-ellipsis" placement="bottom" #element
        [ngbTooltip]="tooltipText[i]" (mouseenter)="getTooltip(element,i,'text1')" *ngIf="!option.isHeader"
        [ngClass]="{selected: option.isSelected,'disabled': option.isDisabled,'last-dd-option': option.isImage}" value="{{option.value}}"
        (click)="changeInDropdown(ddData.name, option.value,option.text)" [innerHTML]="option.text"
        [disabled]="option.isDisabled"></button>
      <button class="dropdown-item second-text overflow-ellipsis" placement="bottom" #element
        [ngbTooltip]="tooltipSecondText[i]" (mouseenter)="getTooltip(element,i,'text2')" *ngIf="!option.isHeader && isFromEdataWidget" [ngClass]="{selected: option.isSelected,'disabled': option.isDisabled}"
        value="{{option.value}}" (click)="changeInDropdown(ddData.name, option.value,option.text, option.secondText)"
        [innerHTML]="option.secondText" [disabled]="option.isDisabled">
      </button>
    </span>
  </div>
</div>