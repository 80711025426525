<div class="overlay" [ngClass]="{'collapse': collapsed}"></div>
<nav class="navbar fixed-top navbar-expand-md navbar-light">
  <div class="container-fluid">
    <a class="navbar-brand ml-3" (click)="clickBtn()">
      <img src="../../../../assets/images/logo-ecolab.PNG" alt="ECOLAB" class="img-fluid" />
      <span class="brand-seperator"></span>
      <img src="../../../../assets/images/logo-nalco-water.PNG" alt="Nalco Water" class="img-fluid" />
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleCollapsed()">
      <span class="navbar-toggler-icon"></span>
      <span class="navbar-toggler-icon"></span>
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="navbar-menu-panel" [ngClass]="{'collapse': collapsed, 'navbar-collapse': true}" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <div class="left-nav" *ngIf="user?.authenticated">
          <nalco-navigation></nalco-navigation>
        </div>



      </ul>

      <ul class="list-inline navbar-nav">
        <li class="list-inline-item nav-item">
        </li>

        <li ngbDropdown class="d-inline-block list-inline-item nav-item rounded-circle">
          <a ngbDropdownToggle>{{ Initials }}</a>
          <div class="profile-dropdown-menu" ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <button (click)="logout()" class="dropdown-item">{{'COMMON.LOGOUT' | translate}}</button>
          </div>

        </li>
        <li class="d-inline-block list-inline-item nav-item float-right mr-3">
          <div (click)="logout()" class="d-lg-none d-md-none h6 mt-1">{{'COMMON.LOGOUT' | translate}}</div>
        </li>
      </ul>




      <ng-content></ng-content>
    </div>
  </div>
</nav>

<nalco-sub-header [ngClass]="{'collapse': collapsed}" *ngIf="user?.authenticated"></nalco-sub-header>