import { IReferenceCondition, IRODataModel, IROMeasures, IROModel } from "../contracts/IROMeasures";
import { ROOutputModel } from "./ROOutputModel";

export class RODataModel implements IRODataModel {
  Source: number;
  rOOptimizerScenarioInfo: ROMeasures;

  constructor(ro?: IRODataModel) {
    Object.assign(this, ro);
  }
}

export class ROMeasures implements IROMeasures {
  SiteId: number;
  AssetId: number;
  LastUpdatedByFirstName: string;
  LastUpdatedByLastName: string;
  LastUpdatedByEmail: string;
  ScenarioId: string;
  ScenarioName: string;
  ScenarioUnit: string;
  OperatingParameters: Array<ROModel>;
  InputStream: Array<ROModel>;
  ReferenceCondition: ReferenceCondition;
  CreatedDate: Date;
  ModifiedDate: Date;
  ChargeBalanceAdded: ROModel;

  constructor(data?: IROMeasures) {
    Object.assign(this, data);
  }
}

export class ROModel implements IROModel {
  Name: string;
  Type: string;
  Value: any;

  constructor(model?: IROModel) {
    Object.assign(this, model);
  }
}

export class ReferenceCondition implements IReferenceCondition {
  Recovery: ROModel;
  Temperature: ROModel;
  pHSetPoint: ROModel;
  Output: ROOutputModel;

  constructor(data?: IReferenceCondition) {
    Object.assign(this, data);
  }
}