<!-- header -->
<!-- <div *ngIf="!hideLayout" style="height: 100%;">  -->
<nalco-app-header *ngIf="isUserInfoAvailable && user?.authenticated && !hideLayout" [user]='user'
  [hideSubHeader]='hideSubHeader' [isHomePage] = 'isHomePage' (showLoader)="handleLoading($event)"
 (subHeaderHiddenChange)="onSubHeaderHiddenChange($event)"></nalco-app-header>
<!-- Sub header -->
<div class="main-outer-wrapper wrapper-position" *ngIf="isUserInfoAvailable && user?.authenticated && !hideLayout"
[ngClass]="isSubHeaderHidden && hideSubHeader ? 'subheader-hidden' : isHomePage ? 'main-home-page' : 'main-page-layout'">
  <div *ngFor="let item of allannouncement index as i">
    <div #alert class="alert alert-primary fade show whatsnewpopup" *ngIf="item.Isdisable" [style.top.px]="item.top"
      role="alert">
      <button type="button" class="close" aria-label="Close" (click)="onCloseClick(i,item.AnnouncementId)">
        <span aria-hidden="true">&times;</span>
      </button>
      <div [innerHtml]="item.Message"></div>
      <button type="button" class="btn btn-primary btn-align" (click)="onGotItClick(i,item.AnnouncementId)"
        translate>COMMON.GOTIT</button>
    </div>
  </div>
  <div class="row">
    <div class="container-fluid p-0" [ngClass]="isHomePage ? 'home-background-color' : ''">
      <!-- left nav -->
      <!-- <div class="left-nav" *ngIf="user?.authenticated">
              <nalco-navigation *ngIf="!isMobile"></nalco-navigation>
            </div> -->
      <!-- content -->
      <div [ngClass]="hideLayout ? 'hide-layout' : ''" class="main-content" [ngClass]="isMobile? 'main-content-collapse-view' : isExpand? 'main-content-expand' : 'main-content-collapse'">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
<div class="main-content-push"></div>

<!-- wil enable footer once UX design is available -->

<nalco-footer [isExpand]="isExpand" *ngIf="isUserInfoAvailable && !hideLayout"></nalco-footer>
<nalco-loader [loader]="isUserInfoAvailable && !user.authenticated || loading || showLoader"></nalco-loader>
<nalco-logger></nalco-logger>
<!-- </div>
<div *ngIf="hideLayout">
  <router-outlet></router-outlet>
</div>  -->


<!-- idle timeout modal -->
<div class="idle-modal-cont">
  <ng-template #idleModalContent let-modal>
    <!-- <div class="modal-header idle-modal-header">
    </div> -->
    <div class="modal-body">
      <h4 class="modal-title" id="modal-basic-title">{{'IDLE_TIMEOUT_POPUP.HEADER' | translate}}</h4>
      <div class="idle-time-out-text">
        {{ idleState | async }}
        <span *ngIf="idleCountFormatted != ''">{{ idleCountFormatted }} </span>
        <p>{{'IDLE_TIMEOUT_POPUP.IDLE_NOTIFICATION_TEXT' | translate}}</p>
      </div>
    </div>
    <div class="modal-footer popup-footer-section">
      <button type="button" class="btn btn-primary" (click)="idleStay()" [disabled]="timedOut">
        {{'IDLE_TIMEOUT_POPUP.IDLE_TIMEOUT_POPUP_STAY_BTN_TEXT' | translate}}
      </button>
      <button type="button" class="btn btn-secondary" (click)="idleLogout()" [disabled]="timedOut">
        {{'IDLE_TIMEOUT_POPUP.IDLE_TIMEOUT_POPUP_LOGOUT_BTN_TEXT' | translate}}
      </button>
    </div>
  </ng-template>
</div>