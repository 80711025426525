<div class='row customDateRange'>
  <div class="col-lg-4 col-sm-12">
    <div class="data-visual nalco-date-range-dropdown">
      <nalco-dropdown [isDisabled]='isDisabled' [ddData]="dateRanges" (ddChange)="selectionChanged($event, false)">
      </nalco-dropdown>
    </div>
  </div>
  <div class="col-lg-4 col-sm-12">
    <span class="position-relative filterWidth inline-block-display-moz">
      <p class="start-label" translate>DATA_VISUALIZATION.TIME_RANGE.START_DATE</p>
      <kendo-datepicker placeholder="*" [(value)]="sDate" [format]="monthYearFormat ? 'MM/yyyy' : 'MM/dd/yyyy'" #element3 placement='bottom'
        (valueChange)="startNEndDate('startDate', $event); onValueChange($event)"  
        [popupSettings]="{popupClass: 'popupClass', appendTo: 'root'}"
        [bottomView]="getCalendarView('year')" [topView]="getCalendarView('decade')" [disabledDates]="isDateDisabled">
        <ng-template kendoCalendarMonthCellTemplate let-date *ngIf="futureDatesDisabled">
          <span [class.disabled]="isDateDisabled(date)">
              {{ date.getDate() }}
          </span>
        </ng-template>
        <kendo-datepicker-messages
          today="{{'DATA_VISUALIZATION.TIME_RANGE.TODAY' | translate}}">
        </kendo-datepicker-messages>
      </kendo-datepicker>
    </span>
  </div>
  <div class="col-lg-4 col-sm-12">
    <span class="position-relative filterWidth inline-block-display-moz">
      <p class="start-label" #endDate translate [ngbTooltip]="endDate.scrollWidth > endDate.offsetWidth + 1 ? ('DATA_VISUALIZATION.TIME_RANGE.END_DATE' | translate): ''">
        DATA_VISUALIZATION.TIME_RANGE.END_DATE
      </p>
      <kendo-datepicker placeholder="*" [format]="monthYearFormat ? 'MM/yyyy' : 'MM/dd/yyyy'" [(value)]="eDate" #element4 placement='bottom'
        [popupSettings]="{popupClass: 'popupClass', appendTo: 'root'}"
        (valueChange)="startNEndDate('endDate', $event); onEndValueChange($event);"
        [bottomView]="getCalendarView('year')" [topView]="getCalendarView('decade')" [disabledDates]="isDateDisabled">
        <ng-template kendoCalendarMonthCellTemplate let-date *ngIf="futureDatesDisabled">
          <span [class.disabled]="isDateDisabled(date)">
              {{ date.getDate() }}
          </span>
        </ng-template>
        <kendo-datepicker-messages
          today="{{'DATA_VISUALIZATION.TIME_RANGE.TODAY' | translate}}">
        </kendo-datepicker-messages>
      </kendo-datepicker>
    </span>
  </div>
</div>
<div class='error-msg'>{{invalidTimeRangeMsg}}</div>

