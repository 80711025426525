import type { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { XdComponentState } from '../../../shared/components/xd-container/xd-component';

@Component({
  selector: 'nalco-combo-box',
  templateUrl: './combo-box.component.html',
  styleUrls: ['./combo-box.component.scss']
})

export class ComboBoxComponent extends XdComponentState implements OnInit, OnChanges {

  @Input() data;
  @Output() ddChange = new EventEmitter();
  @Output() tbChange = new EventEmitter();
  @Input() formGroup: UntypedFormGroup;
  currentValue: string;
  previousValue: string;
  control = {
    name: '',
    updatedValue: ''
  }

  constructor() {
    super();
  }

  ngOnInit() {
    if (this.data.Type == 'combo-box') {
      this.currentValue = this.data.DropdownParam.Value;
      this.previousValue = this.data.DropdownParam.Value;
    } else if (this.data.Type == 'drop-down') {
      this.currentValue = this.data.DropdownParam.Value;
      this.previousValue = this.data.DropdownParam.Value;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.formGroup && changes.formGroup.currentValue) {
      this.formGroup.controls = { ...changes.formGroup.currentValue.controls };
      this.updateFormControlsValue();
    }
    if (changes.data && changes.data.currentValue) {
      this.data = changes.data.currentValue;
      if (this.data.InputParam && this.data.InputParam.Value && typeof (this.data.InputParam.Value) === 'number') {
        this.data.InputParam.Value = parseFloat(parseFloat(this.data.InputParam.Value).toFixed(6));
        this.updateFormControlsValue();
      }

    }
  }


  /**
   * 
   * @description Updates the form group controls.
   * Loops through each control in the form group and performs specific actions based on the control's key.
   * If the control's key ends with 'tb' and its value is a number, it sets the value to a fixed precision of 6 decimal places.
   * @returns void
   */
  updateFormControlsValue(): void {
    // iterates on the control keys and check if the control is a number and ends with 'tb' then transform the value to show decimal upto 6th decimal place
    Object.keys(this.formGroup.controls).forEach((key) => {
      if (key.slice(-2) === 'tb' && typeof (this.formGroup.controls[key].value) === 'number') {
        this.formGroup.controls[key].setValue(parseFloat(this.formGroup.controls[key].value.toFixed(6)));
      }
    });
  }

  onDropdownChange(event) {
    event.previousValue = this.previousValue;
    event.Value = this.currentValue;
    this.ddChange.emit(event);
    if (this.currentValue != this.previousValue) {
      this.previousValue = this.currentValue;
    }
  }

  onInputChange(event: Event, name) {
    this.control.name = name;
    this.control.updatedValue = parseFloat((parseFloat((event.target as HTMLInputElement).value)).toFixed(6)).toString();
    this.formGroup.controls[name].setValue(this.control.updatedValue);
    this.tbChange.emit(this.control);
  }
}
