import { IAddMeasurementData } from "../_contracts/IAddMeasurementData";

export class AddMeasurementData implements IAddMeasurementData {
    HighValue: number;
    InputMax: number;
    InputMin: number;
    LowValue: number;
    MeasurementInstanceId: number;
    MeasurementInstanceScaleId: number;
    Offset: number;
    ScaleHighValue: number;
    ScaleLowValue: number;
    ScaleValueChangeMsg: string;
    StartDate: string;
    ValueUnitSubUnitName: string;
    IsActive: boolean;
    DerivedMeasurementId: number;
    LatestTag: string;
    ScaledName: string;
    IsModbusInput: boolean;


    constructor(mostUseful?: AddMeasurementData) {

        this.HighValue = mostUseful.HighValue;
        this.InputMax= mostUseful.InputMax;
        this.InputMin= mostUseful.InputMin;
        this.LowValue= mostUseful.LowValue;
        this.MeasurementInstanceId= mostUseful.MeasurementInstanceId;
        this.MeasurementInstanceScaleId= mostUseful.MeasurementInstanceScaleId;
        this.Offset= mostUseful.Offset;
        this.ScaleHighValue= mostUseful.HighValue;
        this.ScaleLowValue= mostUseful.LowValue;
        this.StartDate= mostUseful.StartDate;
        this.ValueUnitSubUnitName = mostUseful.ValueUnitSubUnitName;
        this.IsActive = mostUseful.IsActive;
        this.DerivedMeasurementId = mostUseful.DerivedMeasurementId;
        this.LatestTag = mostUseful.LatestTag;
        this.ScaledName = mostUseful.ScaledName;
        this.IsModbusInput = mostUseful.IsModbusInput;
    }
}