import { Injectable } from '@angular/core';
import { AppEnvConfig } from '../../app.env.config';
import { Observable } from 'rxjs/Observable';
import { LocalCacheService } from '../../core/local-cache/local-cache.service';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../../../app/core/user/user.service';

@Injectable()
export class LooperService {
  constructor(
    private userService: UserService,
    private httpClient: HttpClient, private localCache: LocalCacheService,
    private config: AppEnvConfig) { }

    get() {
      // this.msalJsService.refreshToken();
    }

  // public checkVersion(source = false) {
  //   return this.httpClient.get('version.json?t='+ new Date().getTime(), {}).subscribe(
  //     (envResponse) => {
  //       if (this.localCache.fetchData('localVersionId') !== envResponse['build']) {
  //         this.localCache.storeData('localVersionId', envResponse['build'])
  //         if (!source) {
  //           this.userService.clear();
  //           window.location.reload(); //this is deprecated with force reload parameter
  //         }
  //       }
  //     },
  //     error => {
  //       this.userService.clear();
  //       window.location.reload();
  //     }
  //   );
  // }
}
