<nalco-loader [loader]="!user?.authenticated"></nalco-loader>
<div *ngIf="isUserSettingLoading && !isUserSettingFailed" class="col-sm-6 page-content">
  <h3>{{'COMMON.LOADING_MESSAGE_LOGIN' | translate}}</h3>
  <nalco-loader [loader]="true"></nalco-loader>
</div>
<div *ngIf="isUserSettingFailed" class="col-sm-6 page-content">
  <h3>{{'COMMON.ERROR_MESSAGE_LOGIN' | translate}}</h3>
  <p>{{'COMMON.TRY_AGAIN_MESSAGE_LOGIN' | translate}}</p>
  <a class="btn btn-secondary col-sm-6" (click)="loadUserProfile()">{{'COMMON.RENTRY_MESSAGE_LOGIN' | translate}}</a>
</div>
