
import { IFlowMeterInfoMeasurementInstanceMap, IMeasurementInstances, IMeasurementInstanceScales, ISensorMappingFormDataSave } from '../_contracts/ISensorMappingFormDataSave';


export class FlowMeterInfoMeasurementInstanceMap implements IFlowMeterInfoMeasurementInstanceMap {
    MeasurementInstanceId = 0;
    FlowMeterInfoId = 0;
    IsActive = true;

    constructor(FlowMeterInfoMeasurementInstanceMap) {
        if (FlowMeterInfoMeasurementInstanceMap) {
            this.MeasurementInstanceId = FlowMeterInfoMeasurementInstanceMap.MeasurementInstanceId;
            this.FlowMeterInfoId = FlowMeterInfoMeasurementInstanceMap.SelectedFlowData.FlowMeterInfoId;
            this.IsActive = true;
        }
    }
}

export class MeasurementInstanceScales implements IMeasurementInstanceScales {
    MeasurementInstanceScaleId: number;
    MeasurementInstanceId: number;
    StartDate: any;
    EndDate = '9999-12-31';
    LowValue: number;
    HighValue: number;
    InputMin: number;
    InputMax: number;
    Offset: number;
    CreatedBy: number;
    CreatedDate: Date;
    ModifiedBy: number;
    ModifiedDate: Date;
    UnitUsageSubUnitId: number;
    IsScaled = false;

    constructor(MeasurementInstanceScales?) {

        if (MeasurementInstanceScales) {
            this.MeasurementInstanceScaleId = MeasurementInstanceScales.MeasurementInstanceScaleId;
            this.MeasurementInstanceId = MeasurementInstanceScales.MeasurementInstanceId;
            this.StartDate = MeasurementInstanceScales.StartDate;
            this.EndDate = MeasurementInstanceScales.EndDate;
            this.LowValue = MeasurementInstanceScales.LowValue;
            this.HighValue = MeasurementInstanceScales.HighValue;
            this.InputMin = MeasurementInstanceScales.InputMin;
            this.InputMax = MeasurementInstanceScales.InputMax;
            this.Offset = MeasurementInstanceScales.Offset;
            this.CreatedBy = MeasurementInstanceScales.CreatedBy;
            this.CreatedDate = MeasurementInstanceScales.CreatedDate;
            this.ModifiedBy = MeasurementInstanceScales.ModifiedBy;
            this.ModifiedDate = MeasurementInstanceScales.ModifiedDate;
            this.UnitUsageSubUnitId = MeasurementInstanceScales.UnitUsageSubUnitId;
            this.IsScaled = false;
        }
    }
}

export class MeasurementInstances implements IMeasurementInstances {
    MeasurementInstanceScales: Array<MeasurementInstanceScales> = [];
    MeasurementInstanceId: number;
    ContextPointId: number;
    StuiTypeId: number;
    StuiId: number;
    IncomingInstance: string;
    InstanceId: number;
    MeasurementId: number;
    MeasurementName: string;

    constructor(MeasurementInstances?) {
        if (MeasurementInstances) {
            this.MeasurementInstanceId = MeasurementInstances.MeasurementInstanceId;
            this.ContextPointId = MeasurementInstances.ContextPointId;
            this.StuiTypeId = MeasurementInstances.StuiTypeId;
            this.StuiId = MeasurementInstances.StuiId;
            this.IncomingInstance = MeasurementInstances.IncomingInstance;
            this.InstanceId = MeasurementInstances.InstanceId;
            this.MeasurementId = MeasurementInstances.MeasurementId;
            this.MeasurementName = MeasurementInstances.MeasurementName;
            MeasurementInstances.MeasurementInstanceScales.forEach(element => {
                this.MeasurementInstanceScales.push(new MeasurementInstanceScales(element));
            });
        }
    }
}

export class SensorMappingFormDataSave implements ISensorMappingFormDataSave {
    MeasureType = '';
    PortContextPointId = 0;
    ParentContextPointId = 0;
    PortContextPointName = '';
    PortContextTypeId;
    PortContextSubTypeId;
    SiteContextPointId = 0;
    IsDeleted = false;
    DeleteType = '';
    MeasurementInstance: MeasurementInstances;
    FlowMeterInfoMeasurementInstanceMap: IFlowMeterInfoMeasurementInstanceMap;
    DeviceTimeZoneId: number = null;


    constructor(SensorMappingFormDataSave?) {

        if (SensorMappingFormDataSave) {
            const MeasureType = SensorMappingFormDataSave.MeasureType.options.filter(item => item.isSelected === true);
            this.MeasureType = MeasureType[0].text;
            this.IsDeleted = this.IsDeleted;
            this.DeleteType = this.DeleteType;

            this.PortContextPointId = parseInt(SensorMappingFormDataSave.PortContextPointId[0].value);
            this.SiteContextPointId = SensorMappingFormDataSave.SiteContextPointId;
            this.MeasurementInstance = new MeasurementInstances(SensorMappingFormDataSave);
            this.FlowMeterInfoMeasurementInstanceMap = new FlowMeterInfoMeasurementInstanceMap(SensorMappingFormDataSave);
            this.DeviceTimeZoneId = SensorMappingFormDataSave.DeviceTimeZoneId;
        }

    }
}
