<div class="custom-popup">
  <div class="modal-header pt-4 pl-4 mb-0 border-0">
    <h3 *ngIf="!showWarning" class="modal-title delete-txt-icon">{{modalText}}</h3>
    <h3 *ngIf="savedPopup" class="modal-title save-txt-icon">{{modalsaveText}}</h3>
    <button type="button" *ngIf="closeIcon" class="close" aria-label="Close"
      (click)="disablebtn? activeModal.close(): cancelAddEditModal();">
      <img src="../../../../assets/images/modal-close-icon.svg" alt="close" />
    </button>
  </div>
  <p class="revert mb-0 custom-content" [innerHtml]="modcontent" *ngIf="modcontent != null">

  </p>
  <div *ngIf="showRadio" class="revert mb-0 custom-content">
    <div class="pt-3 pb-3">{{'WATER_FLOW_INTELLIGENCE.SELECT_OPTIONS' | translate}}</div>
    <label class="allData" translate>ASSET_TREE.ALL_DATA
      <input type="radio" name="radio" (checked)="selectedValue" (change)="onSelectionChange('allData')">
      <span class="checkmark"></span>
    </label>
    <label class="allData" translate>ASSET_TREE.FUTURE_DATA
      <input type="radio" name="radio" (checked)="selectedValue" (change)="onSelectionChange('futureData')">
      <span class="checkmark"></span>
    </label>
  </div>
  <div class="p-0 ">
  </div>


  <div class="modal-footer mt-0">
    <button type="button" *ngIf="cancelBtn" class="btn btn-secondary"
      (click)="cancelAddEditModal()">{{closeBtn}}</button>
    <button type="button" *ngIf="actionBtn" class="btn btn-primary action-btn"
      (click)="cancelAddEditModal()">{{actionBtn}}</button>
    <button type="button" *ngIf="showBtn" class="btn btn-secondary stay-btn" (click)="stayModal()">{{stayBtn}}</button>
    <button type="button" *ngIf="disablebtn" id="btnId" class="btn btn-primary save-btn" (click)="Delete()"
      [disabled]="isEnable && !selectedValue">{{confirmBtn}}</button>
  </div>

</div>