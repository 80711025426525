import { Component, OnInit , Input, Output, EventEmitter} from '@angular/core';

@Component({
    selector: 'nalco-favorite',
    templateUrl: './favorite.component.html'
})
export class FavoriteComponent implements OnInit {
    @Input() id; // Defines the ID of the favourite selected
    @Input() isFavourite = false; // Defines the requirement of favourite
    @Output() favouriteToggled: EventEmitter<any>= new EventEmitter(); // Emits the toggle event of the favourite
    constructor() { }

    ngOnInit() {
    }
    changeFavourite($event) {
        this.isFavourite = $event.target.checked;
        this.favouriteToggled.emit({id: this.id, state: $event.target.checked});
    }
}
