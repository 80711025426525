import { IdandName } from './IdandName';
import * as contracts from '../contracts/_contracts';
import { IPill } from '../contracts/_contracts';

export class Pill extends IdandName implements contracts.IPill {
  isFavorite?: boolean;
  sortId?: number;
  value?: string;
  categoryType?: string;
  selected?: boolean;
  property?: string;
  subPills?: Array<Pill> = [];

  constructor(pill?: IPill) {
    super();
    if (pill) {
      this.isFavorite = pill.isFavorite;
      this.value = pill.value;
      this.categoryType = pill.categoryType;
      this.selected = pill.selected;
      this.property = pill.property;
      pill.subPills.forEach(p => {
        this.subPills.push(new Pill(p));
      });
    }
  }
}
