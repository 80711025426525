export enum InventoryParameters{
        SerialNumber = 1,
        SystemName = 2,
        ProductName = 4,
        Capacity = 8,
        Status = 16,
        CurrentReading = 32,
        PreviousReading = 64,
        NumberOfSlugs = 128,
        TotalUsage = 256,
        AverageUsage = 512,
        AverageUsagePriorDays = 1024,
        DateToEmpty = 2048,
        TargetUsage = 4096,
        PlusMinusTargetUsage = 8192,
        YTDTotal = 16384,
        PredictedAnnualUsage = 32768,
        LastRefillQuantity = 65536,
        AllParameters = 131071
        
        // SerialNumber | SystemName | ProductName | Capacity | Status 
        //     | CurrentReading | PreviousReading | NumberOfSlugs | TotalUsage | AverageUsage 
        //     | AverageUsagePriorDays | DateToEmpty | TargetUsage | TargetUsagePercent
        //     | YTDTotal | PredictedAnnualUsage

}

export class InventoryParametersType{
  public static readonly TotalUsage = 'TotalUsage';
  public static readonly AverageUsage = 'AverageUsage';
}

export enum InventoryTargetUsageType{
    Day = 0,
    Week = 1,
    Month = 2,
    Year = 3,
    Slug = 4
}

export const InventoryTargetUsage = [
  {
    display: 'Day',
    id: 0,
    value: 'Day',
    checked: false
  },
  {
    display: 'Week',
    id: 1,
    value: 'Week',
    checked: false
  },
  {
    display: 'Month',
    id: 2,
    value: 'Month',
    checked: false
  },
  {
    display: 'Slug',
    id: 4,
    value: 'Slug',
    checked: false
  }
];