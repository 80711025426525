<div class="config-popup">
  <div class="modal-header">
    <h4 class="modal-title" translate>CONTROLLER_MANAGEMENT.CONFIGURATION_FILES</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
      <img src="../../../../assets/images/modal-close-icon.svg" alt="close" />
    </button>
  </div>

  <div class="mb-0 delete-content">
    <nalco-loader [loader]="isLoading" [transparentBG]="true" [fitToScreen]="true"></nalco-loader>
    <div class="account-name modal-header">
      <span> {{'CONTROLLER_MANAGEMENT.SERIAL_NO' | translate}} : {{SerialNo}} </span> <br/>
      <span> {{'CONTROLLER_MANAGEMENT.LAST_FILE_RECEIVED' | translate}} : {{LastDataReceived | date : "yyyy-MM-dd h:mm:ss"}}</span>
    </div>
   <div class="table-wrapper" #accountResults infiniteScroll [infiniteScrollContainer]="accountResults"
      [infiniteScrollDistance]="1"  [infiniteScrollThrottle]="500"   [scrollWindow]="false">
      <kendo-grid  [data]="viewData" >
      <kendo-grid-messages noRecords="{{'COMMON.NO_RECORDS' | translate}}" 
        pagerItemsPerPage="{{'COMMON.ITEMS_PER_PAGE' | translate}}">
      </kendo-grid-messages>
      <kendo-grid-column field="FileName" [title]="'CONTROLLER_MANAGEMENT.FILENAME' | translate">
        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-template #tipContent>
            <span title="{{dataItem.FileName}}">{{( dataItem.FileName ? (dataItem.FileName) : '-')}}</span>
          </ng-template>
          <div class="first-col overflow-ellipsis" #element
            [ngbTooltip]="element?.offsetWidth+2 < element.scrollWidth ? tipContent: ''" container="body">
            <span>{{( dataItem.FileName ? (dataItem.FileName) : '-')}}</span>
          </div> 
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="TechnologyType" [title]="'CONTROLLER_MANAGEMENT.TECHNOLGY_TYPE' | translate" [width]="130">
        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-template #tipContent>
            <span title="{{dataItem.TechnologyType}}">{{( dataItem.TechnologyType)?( dataItem.TechnologyType): '-'}}</span>
          </ng-template>
          <div class="first-col" #element [ngbTooltip]="element?.offsetWidth+2 < element.scrollWidth ? tipContent: ''"
            container="body">
            <span>{{( dataItem.TechnologyType)?( dataItem.TechnologyType):'-'}}</span>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="CreatedBy" [title]="'CONTROLLER_MANAGEMENT.CREATED_BY' | translate"  [width]="140">
        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-template #tipContent>
            <span title="{{dataItem.CreatedBy}}">{{( dataItem.CreatedBy)?( dataItem.CreatedBy): '-'}}</span>
          </ng-template>
          <div class="first-col" #element [ngbTooltip]="element?.offsetWidth+2 < element.scrollWidth ? tipContent: ''"
            container="body">
            <span>{{( dataItem.CreatedBy)?( dataItem.CreatedBy):'-'}}</span>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="Action" [sortable]="false" [title]="'CONTROLLER_MANAGEMENT.ACTION' | translate" [width]="90">
        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-template #tipContent>
            <span title="{{dataItem.CreatedBy}}">{{( dataItem.CreatedBy)?( dataItem.CreatedBy): '-'}}</span>
          </ng-template>
          <div class="first-col" #element [ngbTooltip]="element?.offsetWidth+2 < element.scrollWidth ? tipContent: 'Download'"
            container="body">
            <span (click)="downloadConfiguration(dataItem)">
              <img alt="Download Configuration" class="download-configuration-icon" src="../../../../../assets/images/download-document-icon.svg" >    
            </span>
          </div>
        </ng-template>
      </kendo-grid-column>
    
    </kendo-grid>
    </div> 
  </div>
</div>
