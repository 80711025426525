import { Component, OnInit, Input, OnChanges, Output, EventEmitter, OnDestroy, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IDropdown, IDropdownOption } from '../dropdown/dropdown-model';
@Component({
  selector: 'nalco-dynamic-dropdown',
  templateUrl: './dynamic-dropdown.component.html',
  styleUrls: ['./dynamic-dropdown.component.scss']
})
export class DynamicDropdownComponent implements OnInit, OnChanges, OnDestroy {
  @Input() controlType: string;
  @Input() data: any;
  @Input() selectedData: string;
  @Input() disabled: boolean;
  @Output() selectedUnit = new EventEmitter<any>();
  oldVal: string;
  dropdownData?: IDropdown = {
    label: this.translate.instant('COMMON.SELECT'),
    name: '',
    options: []
  };
  constructor(private translate: TranslateService) { }

  ngOnInit() {
    this.checkNullValue();
  }
  ngOnChanges(changes: SimpleChanges) {
    this.checkNullValue();
    for (const propName in changes) {
      if (propName === 'selectedData') {
        this.oldVal = this.selectedData;
      }
    }
  }
  updateUnit(event: any) {
    this.selectedUnit.emit({ 'fromUnit': this.oldVal === undefined ? this.selectedData : this.oldVal, 'toUnit': event });
    this.oldVal = event;
  }
  checkNullValue() {
    // if Symbol is null then it should display UnitName instead of Symbol
    if (this.data) {
      this.data.forEach(item => {
        if (item.Symbol === null) {
          item.Symbol = item.UnitName;
        }
      });
    }
  }
  ngOnDestroy() {
  }
}
