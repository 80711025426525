import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { IButtonData } from './contracts/button-model';

@Component({
  selector: 'nalco-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ButtonComponent implements OnChanges {
  @Input() buttonData: IButtonData;
  @Input() disabled: boolean = false;
  @Output() buttonClick = new EventEmitter<Event>();
  btnClass: string;
  btnTextClass: string;
  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    const buttonDataChanges = changes['buttonData'];
    if (buttonDataChanges && buttonDataChanges.currentValue) {
      this.updateClass(buttonDataChanges.currentValue);
    }
  }

  updateClass(data: IButtonData) {
    switch (data.buttonClass) {
      case 'primary':
        this.btnClass = 'shared-btn primary';
        this.btnTextClass = 'shared-btn primary-label';
        break;
      case 'secondary':
        this.btnClass = 'shared-btn secondary';
        this.btnTextClass = 'shared-btn secondary-label';
        break;
      // Added in case if there is any custom classes for this particular to the services
      case 'custom':
        this.btnClass = 'shared-btn ' + data.customClass;
        this.btnTextClass = 'shared-btn primary-label';
        break;
      case 'default':
        this.btnClass = 'shared-btn default';
        this.btnTextClass = 'shared-btn default-label';
        break;
    }
  }

  /**
   * @description Handles the click event of the button.
   * @param $event - The event object.
   * @returns void
   */

  click($event: Event): void {
    this.buttonClick.emit($event);
  }
}
