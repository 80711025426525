<div class="horizantal-bar" *ngIf="showGraph">
    <!-- <div class="table-mob d-lg-none">
        <div class="opportunityItem clearfix" *ngFor="let item of testResults; let id=index">
            <span class="float-left">
              <span class="results-count"><span class="d-inline-block">{{currencySymbol}}</span>{{item.ResultsCount |  currencyFormat : true:  'en-US' : currencySymbol :conversionFactor : currencySpecificFormat}} </span> <span class="sub-tag">  {{item.stageName}} </span>
            </span>
            <span class="float-right">
                <span class="project-count" [ngClass] = "onIncrease(item.count)"> {{item.count}} </span> <span class="sub-tag" translate  [translateParams]="{value:item.count}"> COMMON.PROJECT </span> </span>
          </div>
    </div> -->
    <div class="clearfix pl16">
      <div class="w20 float-left"></div>
      <div *ngIf="testResults.length>0" class="xAxis float-left">
        <span class="initial-val d-inline-block"> 
          <span class="d-inline-block" [ngClass]="{'font-family-zh-lang': (currencySymbol == '¥') }">{{currencySymbol}}</span>{{0 |  fiCurrencyFormat : true :  'en-US' : currencySymbol :conversionFactor : currencySpecificFormat : true}}</span>
        <span *ngFor="let axis of xAxis" class="axis-val d-inline-block text-right" [ngStyle]="{'width.%': xAxisColWidth,'font-family': (currencySymbol == '¥') ? 'Arial' : ''}" ><span style="display:inline-block">{{currencySymbol}}</span>{{axis}}</span>
      </div>
    </div>
  
    <div #barGraph class="slider-bars" [ngClass] = "{'expand-view': expanded}">
      <div *ngFor="let item of testResults; let id=index" class="bars">
        <div class="opportunityItem clearfix ">
          <span class="float-left">
            <span class="results-count"><span class="d-inline-block" [ngClass]="{'font-family-zh-lang': (currencySymbol == '¥') }">{{currencySymbol}}</span>{{translateService.currentLang == 'zh' ? (item.ResultsCount |  fiCurrencyFormat : true:  'en-US' : currencySymbol :conversionFactor : currencySpecificFormat : false : 'zh') : (item.ResultsCount |  fiCurrencyFormat : true:  'en-US' : currencySymbol :conversionFactor : currencySpecificFormat)}}</span> <span class="sub-tag">  {{item.stageName}} </span>
          </span>
          <span *ngIf="savingsTabSelected" class="float-right" >
            <span class="project-count" [ngClass] = "onIncrease(item.recommendationsCount)"> {{item.recommendationsCount}}</span> <span class="sub-tag"> {{getActionsLabelTranslationKey(item.recommendationsCount) | translate}} </span> </span>
          <span class="float-right">
            <span class="project-count" [ngClass] = "onIncrease(item.count)"> {{item.count}}</span> <span class="sub-tag"> {{getProjectsLabelTranslationKey(item.count) | translate}} </span> </span>           
        </div>
        <div class="progress">
          <div *ngIf="cummulativeGraph" class="progress-bar" [ngStyle]="{'width.%':   item.percent}" >
             <span class="subItem" *ngFor = "let subValue of item.differentcolor ; let i=index" [ngStyle]="{'width.%':   (subValue.value / item.cummulativeItems )*100 , 'background':subValue.color  }" >  </span>
             <span *ngIf="item.goalPercent !== null " [ngStyle]="{'left.%': item.goalPercent } " class="goal"></span>
          </div>
          <div *ngIf= "!cummulativeGraph" class="progress-bar" [ngStyle]="{'width.%':   item.percent  , 'background':item.color  }"></div>
        </div>
      </div>
    </div>
  <div  *ngIf= "expandmore">
  <div class="expand-more"  *ngIf= "expandReqiured"> <a href="javascript:void(0)" (click) ="expandGraph()" > {{expanded? collapseView : expandView}} </a> </div>
</div>
</div>
