import { Widget } from './Widget';
import { IDashboard, IFinancialImpactCommonConfig } from '../_contracts/IDashboard';
import { DagGroupToAssetMapData } from './DagGroupToAssetMapData';

export class Dashboard implements IDashboard {
    Id: string = '';
    ContextPointId: number = 0;
    ContextPointName: string = '';
    ContextPointTypeId: number = 0;
    GroupContextPointId?: number;
    ContextPointSubTypeId: 0;
    ServiceTypeId: number = 0;
    ServiceSubTypeId: number = 0;
    DashboardId: number = null;
    DashboardName: string = '';
    TabOrder: number = 0;
    TabIndex: number = null;
    IsDefaultView: boolean = true;
    CreatedBy: number = 0;
    ModifiedBy: number = 0;
    FilterDurationInDays: number = null;
    StartDate:string = null;
    EndDate:string = null;
    Widgets?: Array<Widget> = [];
    UnitAndCurrencyPreferenceType: number = 1;
    DagGroupToAssetMapData?: DagGroupToAssetMapData;
    CustomerLogoEnabled: boolean = false;
    RestrictFiWidget?: boolean;
    CorpAcctCpId: number = 0;
    FinancialImpactCommonConfig ? : IFinancialImpactCommonConfig;
    constructor(dashboard?: Dashboard) {
        if (dashboard) {
            this.Id = dashboard.Id;
            this.ContextPointId = dashboard.ContextPointId;
            this.ContextPointName = dashboard.ContextPointName;
            this.ContextPointTypeId = dashboard.ContextPointTypeId;
            this.GroupContextPointId = dashboard.GroupContextPointId;
            this.ContextPointSubTypeId = dashboard.ContextPointSubTypeId;
            this.ServiceTypeId = dashboard.ServiceTypeId;
            this.ServiceSubTypeId = dashboard.ServiceSubTypeId;
            this.DashboardId = dashboard.DashboardId;
            this.DashboardName = dashboard.DashboardName;
            this.TabOrder = dashboard.TabOrder;
            this.TabIndex = dashboard.TabIndex;
            this.IsDefaultView = dashboard.IsDefaultView;
            this.CreatedBy = dashboard.CreatedBy;
            this.ModifiedBy = dashboard.ModifiedBy;
            this.FilterDurationInDays = dashboard.FilterDurationInDays;
            this.StartDate = dashboard.StartDate;
            this.EndDate = dashboard.EndDate;
            this.UnitAndCurrencyPreferenceType = dashboard.UnitAndCurrencyPreferenceType;
            this.DagGroupToAssetMapData = dashboard.DagGroupToAssetMapData;
            this.CustomerLogoEnabled = dashboard.CustomerLogoEnabled;
            this.RestrictFiWidget = dashboard.RestrictFiWidget;
            this.CorpAcctCpId = dashboard.CorpAcctCpId;
            this.FinancialImpactCommonConfig = dashboard.FinancialImpactCommonConfig;
            if (dashboard.Widgets) {
                dashboard.Widgets.forEach(widget => {
                    this.Widgets.push(new Widget(widget));
                });
            }
        }
    }
}
