import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { LocalCacheService } from '../../../core/local-cache/local-cache.service';
import { LoggerService } from '../../../core/logger/global-logger.service';
import { SubheaderHandlerService } from '../../../core/nav/subheader-handler.service';
import { FiNumberFormatPipe } from '../../../shared-financial-impact/pipe/fi-number-format.pipe';
import { ISubheader } from '../../../shared/components/sub-header/sub-header.modal';
import { XdComponentState } from '../../../shared/components/xd-container/xd-component';
import { ComponentState } from '../../../shared/components/xd-container/xd-component-state';
import { ConversionUtilityService } from '../../../shared/services/coversionUtility.service';
import { EROIValueType, StageType } from '../../_enum/constants';
import { CorporatePreference } from '../../models/CoporatePreference';
import * as model from '../../models/_models';
import { ProjectDetails } from '../../models/_models';
import { SharedService } from '../../services/shared-services.service';
import { ErrorHandling } from '../error-handling/error-handling';
import { MultipleSitePopupComponent } from '../multiple-site-popup/multiple-site-popup';



@Component({
  selector: 'nalco-fi-omni-project-overview',
  templateUrl: './fi-omni-project-overview.component.html',
  styleUrls: ['./fi-omni-project-overview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FiOmniProjectOverviewComponent extends XdComponentState implements OnInit {
  public breadCrumbData: any = [
    { text: this.translate.instant('FINANCIAL_IMPACT.FINANCIAL_IMPACT_ENTERPRISE_OVERVIEW'), link: '/omni' },
    { text: this.translate.instant('FINANCIAL_IMPACT.FI_SITE_NAME'), link: '' },
    { text: this.translate.instant('FINANCIAL_IMPACT.PROJECT_OVERVIEW'), link: '' }
  ];
  projectID: string;
  projectOverviewData: ProjectDetails;
  totalAnnualSaving: number;
  currencySpecificFormat = '';
  subheaderData: ISubheader = {
    leftSection: {
      type: 'breadcrumb',
      data: this.breadCrumbData,
      className: 'col-lg-12'
    },
    middleSection: {
      type: '',
      data: [],
      className: ''
    },
    rightSection: {
      type: 'dd-group',
      className: 'col-lg-6',
      data: [
        {
          label: ' ',
          name: 'durationDropDown',
          options: [
          ]
        }
      ]
    }
  };
  CategorySavingsData: any[] = [
    { name: this.translate.instant('FINANCIAL_IMPACT.PROJECT_TABLE.WATER'), value: 0, iconClass: 'saving-water', UnitAmount: 0, Units: ' ', Hide: false },
    { name: this.translate.instant('FINANCIAL_IMPACT.PROJECT_TABLE.ENERGY'), value: 0, iconClass: 'saving-energy', UnitAmount: 0, Units: ' ', Hide: false }
  ];


  secondCategorySavingsData = [];
  currencyToggleSetting = 'Personal';
  currencySetting: any = {
    UnitsOfMeasure: '1',
    Currency: {
      CurrencyCodeId: 'USD',
      CurrencyName: 'US Dollar',
      Symbol: '$'
    }
  };
  currencySymbol = '$';
  conversionFactor = 1;
  numberForamtPipe: any;
  showUnitValueForROITypes = [1, 2, 3, 4, 11, 7, 12, 13, 9];
  serviceflag = '';
  omniAdminSetting: any;
  sortedFinancials = [];
  modalRef: any;
  errorInfo: any;
  errorHandling = new ErrorHandling(this.modalService, this.translate );
  isMultipleSite = false;
  siteDetails: Array<{
    SiteName : string,
    StateOrProvince : string,
    Country : string
    }> = [];

  constructor(private route: ActivatedRoute,
    private router: Router,
    private subHeaderService: SubheaderHandlerService,
    private localCacheService: LocalCacheService,
    private translate: TranslateService,
    private logger: LoggerService,
    public financialImpactService: SharedService,
    private modalService: NgbModal,
    private dataService: ConversionUtilityService,) {
    super();
    this.subHeaderService.updateLeftSection(this.subheaderData.leftSection);
    // this.subHeaderService.updateRightSection(this.subheaderData.rightSection);
    this.subHeaderService.updateMiddleSection(this.subheaderData.middleSection);
    this.projectID = route.snapshot.params['projectID'];

    this.serviceflag = route.parent.snapshot.data.path;
    this.breadCrumbData[0].link = '/' + this.serviceflag;
    if (this.serviceflag === 'omni') {
      this.breadCrumbData[0].text = this.translate.instant('OMNI.DASHBOARD.OMNI_ENTERPRISE_OVERVIEW');
    } else if (this.serviceflag === 'financial-impact') {
      this.breadCrumbData[0].text = this.translate.instant('FINANCIAL_IMPACT.FINANCIAL_IMPACT_ENTERPRISE_OVERVIEW');
      this.breadCrumbData.splice(1, 1);
    } else if (this.serviceflag === 'scale-guard-index') {
      this.breadCrumbData[0].text = this.translate.instant('SGI.DASHBOARD.SCALGUARD_INDEX');
      this.breadCrumbData[0].link = ''
      this.breadCrumbData[1].text = this.translate.instant('SGI.DASHBOARD.ENTERPRISE_OVERVIEW');
      this.breadCrumbData[1].link = 'scale-guard-index';
    }
  }

  ngOnInit() {
    // EROIValueType.forEach(item => {
    //   item.text = this.translate.instant(item.text);
    // })
    // StageType.forEach(item => {
    //   item.text = this.translate.instant(item.text);
    // })
    this.setState(ComponentState.loading);
    this.numberForamtPipe = new FiNumberFormatPipe(this.translate);
    const cacheObjFI = JSON.parse(this.localCacheService.fetchData('FISettings'));
    if(cacheObjFI !== null){
      this.currencyToggleSetting = cacheObjFI.currencySetting;
    }
    
    if (this.currencyToggleSetting === 'Personal') {
      const userSetting = JSON.parse(this.localCacheService.fetchData('UserSettings'));
      if (userSetting != null) {
        this.currencySetting = userSetting.UserPreference;

      }

    } else if (this.currencyToggleSetting === 'Corporate') {
      const corporateSetting = JSON.parse(this.localCacheService.fetchData('CorporateSetting'));
      if (corporateSetting != null) {
        this.currencySetting = new CorporatePreference(corporateSetting);
      }
    }
    if (this.currencySetting.Currency.Symbol != null && this.currencySetting.Currency.Symbol !== '') {
      this.currencySymbol = this.currencySetting.Currency.Symbol;
    } else {
      this.currencySymbol = this.currencySetting.Currency.CurrencyCodeId;
    } if (this.serviceflag === 'omni') {
      this.omniAdminSetting = JSON.parse(this.localCacheService.fetchData('Admin_Details'));
      this.getCorporatePreference(this.omniAdminSetting.AdminCommonConfiguration.CorporateAccountCpId);
    } if (this.currencySetting.Currency.CurrencyCodeId !== 'USD') {
      this.dataService.getConversionFactor('USD', this.currencySetting.Currency.CurrencyCodeId).subscribe(
        (data) => {
          // Need to pass the fromCurrencyCode and toCurrencyCode to API to get the coversion factor between these
          if (data != null) {
            this.conversionFactor = data;
          }
        }
      );
    }
    this.getProjectOverviewDetails(this.projectID);
    // this.processData();
  }

  myParentInstance(): FiOmniProjectOverviewComponent{
    return this;
  }

  getCorporatePreference(cpID = 0) {
    this.financialImpactService.getCorporateSetting(cpID.toString()).subscribe(
      response => {
        const corporateSetting = new CorporatePreference(response);
        this.currencySymbol = response.Currency.Symbol;
      },
      error => {
        this.translate.get('COMMON.GENERIC_ERROR_MESSAGE').subscribe(res => {
          this.logger.error({ message: res }, false, true);
        });
      }
    );

  }

  getProjectOverviewDetails(projID: string) {
    const unitsOfMeasure = this.currencySetting.UnitsOfMeasure.toString();
    this.isMultipleSite = false;
    this.siteDetails = [];
    this.financialImpactService.getProjectOverview(projID, unitsOfMeasure).subscribe(
      response => {
        if (response) {
          this.projectOverviewData = new model.ProjectDetails(response);
          const arrayofTotalSaving = [];
          // this.projectOverviewData.ProjectFinancials.sort(function (a, b) {
          //   return b.AnnualSavings - a.AnnualSavings;
          // });
          if(this.projectOverviewData.Sites.length > 1) {
            this.isMultipleSite = true;
            this.projectOverviewData.Sites.forEach( site => {
              this.siteDetails.push({
                SiteName : site.SiteName,
                StateOrProvince : site.StateProvince,
                Country : site.Country
              })
            });

          }
          if (this.projectOverviewData.ProjectFinancials !== null) {
          this.projectOverviewData.ProjectFinancials.forEach (item => {
              const selectedType = EROIValueType.find(type=>type.value == item.EroiValueType);
              if(selectedType){
              this.sortedFinancials.push({
                name: this.translate.instant(selectedType.text),
                AnnualSavings: item.AnnualSavings
              });
            }
          });
          this.sortedFinancials.sort(function (a, b) {
            if (a.AnnualSavings === b.AnnualSavings) {
              const textA = a.name.toUpperCase();
              const textB = b.name.toUpperCase();
              return textA.localeCompare(textB);
            }
            return b.AnnualSavings - a.AnnualSavings;
          });
        }
          this.projectOverviewData.ProjectFinancials.forEach(item => {
            arrayofTotalSaving.push(item.AnnualSavings);
          });
          this.projectOverviewData.ProjectStage = this.translate.instant(StageType[this.projectOverviewData.ProjectStage].text);
          this.totalAnnualSaving = arrayofTotalSaving.reduce((acc, cur) => acc + cur, 0);
          const currencySpecificFormat = this.numberForamtPipe.transform(this.totalAnnualSaving, true);
          const regex = /(\d+)(?=[^\d]+$)/g;
          const m = regex.exec(currencySpecificFormat );
          if(m !== null){
            this.currencySpecificFormat  = currencySpecificFormat .substring(m.index + m[0].length);
          }else{
            this.currencySpecificFormat  = '';
          }
          this.processData(this.projectOverviewData.ProjectFinancials);
          this.setState(ComponentState.ready);
        } else {
          this.setState(ComponentState.empty);
          // this.projectOverviewData = [];
        }
      },
      error => {
        this.errorInfo = error;
        if ( 500 <= error.status && error.status < 600) {
          } else if (error.status === 417) {
            this.router.navigate(['/access-denied']);
          } else if (error.status === 404) {
            this.router.navigate(['/**']);
          } else {
            this.errorHandling.showErrorPopup(error);
          }
        this.setState(ComponentState.error);
      }
    );
  }

  getContact(firstname: string, lastname: string, email: string) {

    const name = firstname + ' ' + lastname;
    if ((firstname === null || firstname === ' ' || firstname === '')
      && (lastname === null || lastname === ' ' || lastname === '')
      && (email === null || email === ' ' || email === '')) {
      return this.translate.instant('COMMON.NOT_AVAILABLE');
    } else if ((firstname !== null && firstname !== ' ' && firstname !== '')
      && (lastname === null || lastname === ' ' || lastname === '')
      && (email === null || email === ' ' || email === '')) {
      return firstname;
    } else if ((firstname === null || firstname === ' ' || firstname === '')
      && (lastname !== null && lastname !== ' ' && lastname !== '')
      && (email === null || email === ' ' || email === '')) {
      return lastname;
    } else if ((firstname === null || firstname === ' ' || firstname === '')
      && (lastname === null || lastname === ' ' || lastname === '')
      && (email != null && email !== ' ' && email !== '')) {
      return email;
    } else if ((firstname !== null && firstname !== ' ' && firstname !== '')
      && (lastname !== null && lastname !== ' ' && lastname !== '')
      && (email === null || email === ' ' || email === '')) {
      return firstname + ' ' + lastname;
    } else if ((firstname !== null && firstname !== ' ' && firstname !== '')
      && (lastname === null || lastname === ' ' || lastname === '')
      && (email != null && email !== ' ' && email !== '')) {
      return firstname + ' - ' + email;
    } else if ((firstname === null || firstname === ' ' || firstname === '')
      && (lastname !== null && lastname !== ' ' && lastname !== '')
      && (email != null && email !== ' ' && email !== '')) {
      return lastname + ' - ' + email;
    } else {
      return name + ' - ' + email;
    }
  }

  getText(text: string) {
    if (text === null || text === ' ' || text === '') {
      return this.translate.instant('COMMON.NOT_AVAILABLE');
    }
    const totalLength = text.length;
    if (totalLength === 1) {
      return this.translate.instant('COMMON.NOT_AVAILABLE');
    } else {
      return text;
    }
  }

  getFormatedDate(val: string) {
    let date = moment(val).format('DD-MMM-YYYY').toString();
    const d = date.split('-');
    let m = d[1];
    let trans_m = this.translate.instant('COMMON.'+m.toUpperCase());
    const res = date.replace(m, trans_m);
    return res;
  }

  processData(financials: Array<any>) {
    let electricalEnergySaving = 0;
    let thermalEnergySaving = 0;
    let electricalEnergyUnit = 0;
    let thermalEnergyUnit = 0;
    let electricalEnergyUnitSymbol = '';
    let thermalEnergyUnitSymbol = '';
    financials.forEach(e => {
      if (e.EroiValueType === 13) {
        if (e.AnnualSavings === ' ') {
          this.CategorySavingsData[0].value = 0;
          this.CategorySavingsData[0].UnitAmount = 0;
        } else {
          this.CategorySavingsData[0].value = e.AnnualSavings;
          this.CategorySavingsData[0].UnitAmount = e.UnitAmount;
        }
        this.CategorySavingsData[0].UnitSymbol = e.UnitSymbol;
      } else if (e.EroiValueType === 4) {
        if (e.AnnualSavings !== ' ') {
          electricalEnergySaving = e.AnnualSavings;
          electricalEnergyUnit = e.UnitAmount;
          electricalEnergyUnitSymbol = e.UnitSymbol;
        }
      } else if (e.EroiValueType === 11) {
        if (e.AnnualSavings !== ' ') {
          thermalEnergySaving = e.AnnualSavings;
          thermalEnergyUnit = e.UnitAmount;
          thermalEnergyUnitSymbol = e.UnitSymbol;
        }
      } else {
        let selectedType = EROIValueType.find(type=> type.value == e.EroiValueType)
        if ((e.AnnualSavings > 0 || e.UnitAmount > 0 || (e.EroiValueType === 1 && e.UnitAmount !== 0))
            && selectedType) { 
        this.secondCategorySavingsData.push(
          {

            name: this.translate.instant(selectedType.text),
            value: e.AnnualSavings,
            iconClass: this.getIconClass(selectedType.value),
            UnitAmount: e.UnitAmount,
            UnitSymbol: e.UnitSymbol,
            Units: ' ',
            Hide: (this.showUnitValueForROITypes.includes(e.EroiValueType)) ? false : true
          }
        );
      }
        this.secondCategorySavingsData.sort(function (a, b) {
          if (a.value === b.value) {
            const textA = a.name.toUpperCase();
            const textB = b.name.toUpperCase();
            return textA.localeCompare(textB);
          }
          return b.value - a.value;
        });
        this.secondCategorySavingsData.splice(2);
      }
    });
    this.CategorySavingsData[1].value = electricalEnergySaving + thermalEnergySaving;
    if (electricalEnergyUnit === 0 && thermalEnergyUnit > 0) {
      this.CategorySavingsData[1].UnitAmount = thermalEnergyUnit;
      this.CategorySavingsData[1].UnitSymbol  = thermalEnergyUnitSymbol;
    } else {
      this.CategorySavingsData[1].UnitAmount = electricalEnergyUnit;
      this.CategorySavingsData[1].UnitSymbol  = electricalEnergyUnitSymbol;
    }
    this.CategorySavingsData = this.CategorySavingsData.concat(this.secondCategorySavingsData);
    let airIndex = this.CategorySavingsData.findIndex( x => x.name === this.translate.instant(EROIValueType[0].text));
    if (airIndex >= 0 && this.serviceflag === 'financial-impact' && this.CategorySavingsData[airIndex].UnitAmount !== null
          && this.CategorySavingsData[airIndex].UnitAmount !== 0) {
      this.CategorySavingsData[airIndex].UnitAmount = parseFloat(this.CategorySavingsData[airIndex].UnitAmount) * -1;
    }
  }

  getEROIType(EroiValueType) {
    let type = EROIValueType.find(type=>type.value == EroiValueType)
    if (type)
      return this.translate.instant(type.text);
    else
      return '';
  }

  toggleTooltip(evt: MouseEvent, tooltip, show: boolean) {
    const element: any = evt.srcElement;
    let overflows = false;
    if (element.offsetHeight + 1 < element.scrollHeight ||
        element.offsetWidth + 1 < element.scrollWidth) {
        overflows = true;
    }
    if (show && overflows) {
        tooltip.open();
    } else {
        tooltip.close();
    }
}

  getIconClass(categoryType: string) {
    if (categoryType === '13') {
      return 'saving-water';
    } else if (categoryType === '7') {
      return 'saving-productivity';
    } else if (categoryType === '4') {
      return 'saving-energy';
    } else if (categoryType === '2'){
      return 'saving-assetpreservation';
    } else if (categoryType === '3') {
      return 'saving-chemistry';
    } else if (categoryType === '9') {
      return 'saving-cost';
    } else if (categoryType === '12') {
      return 'saving-waste';
    } else if (categoryType === '10') {
      return 'saving-chemistry';
    } else if (categoryType === '1') {
      return 'saving-co2-reduction';
    } else if (categoryType === '8') {
      return 'category-profitability';
    } else if (categoryType === '6') {
      return 'category-product-quality';
    } else if (categoryType === '5') {
      return 'category-food-safety';
    }
  }
  multipleSitePopup(){
    this.modalRef = this.modalService.open(MultipleSitePopupComponent, {
      backdrop: 'static',
      keyboard: false,
      windowClass: 'multiple-site-popup'
  }); (<MultipleSitePopupComponent>this.modalRef.componentInstance).siteDetails = this.siteDetails;

  }

}


