import { Injectable } from '@angular/core';
import { ENVIRONMENTS, APP_CONFIG } from '../../../app.config';

@Injectable()
export class WaterFlowIntelligenceAdminServiceConfig {

    private configMock: any = { // MOCK - local json settings
        environment: ENVIRONMENTS.mock,
        baseUrl: '',
        apiUrl: {
            getSensorMapping: 'api/v1/SensorMapping/Datasources/{0}/MacAddress',
            getFlowMeters: 'api/v1/FlowMeters/All',
            saveSensorMappingForm: 'api/v1/SensorMapping/Save',
            postConversionByUnitType: 'ConversionByUnitType',
            getsites: 'ServiceEnabled/SitePagingByUser',
            getSiteSensorMaps: 'api/v1/SensorMapping/SensorMaps/',
            getUnitUsageById: 'api/v1/UnitUsage/',
            getRecalculationDays: 'GeneralCollection?documentName=WaterFlowIntelligence.Calculation'
        }
    };

    private configProd: any = { // PRODUCTION - PRODUCTION ENVIRONMENT SETTINGS
        environment: ENVIRONMENTS.prod,
        baseUrl: '',
        apiUrl: {
            getSensorMapping: 'api/v1/SensorMapping/Datasources/{0}/MacAddress',
            getFlowMeters: 'api/v1/FlowMeters/All',
            saveSensorMappingForm: 'api/v1/SensorMapping/Save',
            postConversionByUnitType: 'ConversionByUnitType',
            getsites: 'ServiceEnabled/SitePagingByUser',
            getSiteSensorMaps: 'api/v1/SensorMapping/SensorMaps/',
            getUnitUsageById: 'api/v1/UnitUsage/',
            getRecalculationDays: 'GeneralCollection?documentName=WaterFlowIntelligence.Calculation'
        }
    };

    private _environment: string = APP_CONFIG.ENVIRONMENT;

    constructor() {
        // void
    }

    public getEnvironment(): any {
        switch (this._environment) {
            case ENVIRONMENTS.mock:
                return this.configMock;
            case ENVIRONMENTS.dev:
                return this.configProd;
            case ENVIRONMENTS.test:
                return this.configProd;
            case ENVIRONMENTS.stg:
                return this.configProd;
            case ENVIRONMENTS.prod:
                return this.configProd;
            default:
                return null;
        }
    }

    public setEnvironment(environment: string): any {
        this._environment = environment;
    }

}
