import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/do';

@Component({
  selector: 'nalco-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.scss']
})
export class ConfirmationPopupComponent implements OnInit {

  @Input() alertIcon: boolean; //Defines the alert icon
  @Input() modalText: string; // Defines the title of the modal
  @Input() modcontent: string; // Defines the content of the modal
  @Input() disablebtn: boolean; // Defines the enable/disable of the button
  @Input() closeIcon?: boolean = false; // Defines the close icon
  @Input() closeAsCancel?: boolean = false; // Defines if the close icon button behave like the cancel button cancellation
  @Input() actionBtn?: string = '';
  @Output('cancelModal') cancelModal = new EventEmitter<any>(); // Emits the cancel event of the modal
  @Output('submitModal') submitModal = new EventEmitter<any>();
  @Output() deleteModal = new EventEmitter<any>(); // Emits the delete event of the modal
  @Input() localDriverBtn: boolean;
  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
  }
  cancelAddEditModal() {
    if (this.disablebtn) {
      this.cancelModal.emit({});
      this.activeModal.close();
    }
    else {
      this.activeModal.close();
    }
  }
  closeAddEditModal() {
    this.cancelModal.emit(false);
    this.activeModal.close();
  }
  submitAddEditModal() {
    if (this.disablebtn) {
      this.submitModal.emit({});
      this.activeModal.close();
    }
    else {
      this.activeModal.close();
    }
  }

  public delete() {
    this.deleteModal.emit({});
    this.activeModal.close();

  }

}
