import { Component, OnInit, OnChanges, SimpleChanges, EventEmitter, Output, ViewEncapsulation, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getDate } from '@progress/kendo-date-math';
import { IDropdown } from '../dropdown/dropdown-model';
import { DurationOption } from '../../models/DurationOption';
import { LocalCacheService } from '../../../core/local-cache/local-cache.service';

@Component({
  selector: 'nalco-select-date-range',
  templateUrl: './select-date-range.component.html',
  styleUrls: ['./select-date-range.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SelectDateRangeComponent implements OnInit, OnChanges {

  @Input() isDisabled?= false;
  @Input() isSelected;
  @Input() service = '';
  private duration: DurationOption;
  public value: string;
  currentYear = new Date().getFullYear();
  previousYear = this.currentYear - 1;
  @Output() updateDate = new EventEmitter<any>();
  @Output() updateDateData = new EventEmitter<any>();
  key = '';

  month: Array<string> = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];



  dateRanges: IDropdown =
    {
      label: this.translate.instant('DATA_VISUALIZATION.VIEW_GRAPH_BY'),
      name: 'durationDropDown',
      options: [
        {
          text: this.translate.instant('COMMON.SORT_OPTIONS.ONEDAYS'),
          value: '1'
        },
        {
          text: this.translate.instant('COMMON.SORT_OPTIONS.THREEDAYS'),
          value: '2'
        },
        {
          text: this.translate.instant('COMMON.SORT_OPTIONS.SEVENDAYS'),
          value: '3'
        },
        {
          text: this.translate.instant('COMMON.SORT_OPTIONS.THIRTYDAYS'),
          value: '4'
        },
        {
          text: this.translate.instant('COMMON.SORT_OPTIONS.SIXTYDAYS'),
          value: '5'
        },
        {
          text: this.translate.instant('COMMON.SORT_OPTIONS.NINETYDAYS'),
          value: '6'
        },
        {
          text: this.translate.instant('COMMON.SORT_OPTIONS.ONETWOZERODAYS'),
          value: '7'
        },
        {
          text: this.translate.instant('COMMON.SORT_OPTIONS.ONEEIGHTZERODAYS'),
          value: '8'
        },
        {
          text: this.translate.instant('COMMON.SORT_OPTIONS.LASTTHREESIXTYFIVEDAYS'),
          value: '9'
        },
        {
          text: this.translate.instant('COMMON.SORT_OPTIONS.YEARTODATE'),
          value: '10'
        },
        {
          text: this.translate.instant('COMMON.SORT_OPTIONS.CUSTOM'),
          value: '11'
        }
      ]
    };

  constructor(private translate: TranslateService, private localCacheService: LocalCacheService) {

  }

  ngOnInit() {
    this.key = this.service + 'DRDuration';
    const cacheDuration = JSON.parse(this.localCacheService.fetchData(this.key));
    let isAvailable = false;
    if (cacheDuration == null) {
      this.dateRanges.options.forEach(it => {
        if (it.value === this.dateRanges.options[3].value) {
          it.isSelected = true;
          this.selectionChanged(it);
        } else {
          it.isSelected = false;
        }
      });
    } else {
      this.dateRanges.options.forEach(it => {
        if (it.value === cacheDuration.timerange) {
          it.isSelected = true;
          this.selectionChanged(it);
          isAvailable = true;
        } else {
          it.isSelected = false;
        }
      });
      if (!isAvailable) {
        this.dateRanges.options.forEach(it => {
          if (it.value === this.isSelected) {
            it.isSelected = true;
            this.selectionChanged(it);
          } else {
            it.isSelected = false;
          }
        });
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isSelected) {
      this.dateRanges.options.forEach(it => {
        if (it.value === changes.isSelected.currentValue) {
          it.isSelected = true;
          this.selectionChanged(it);
        } else {
          it.isSelected = false;
        }
      });
    }
  }

  selectionChanged(data) {
    this.isSelected = data.value;
    const dateData = new Date();
    let days;
    let startDateContent;
    let endDateContent;

    if (data.value === this.dateRanges.options[0].value) {
      days = new Date(dateData.getTime() - (1 * 24 * 60 * 60 * 1000));
      startDateContent = days.getDate() + '-' + this.month[days.getMonth()] + '-' + days.getFullYear();
      endDateContent = dateData.getDate() + '-' + this.month[dateData.getMonth()] + '-' + dateData.getFullYear();
    } else if (data.value === this.dateRanges.options[1].value) {
      days = new Date(dateData.getTime() - (3 * 24 * 60 * 60 * 1000));
      startDateContent = days.getDate() + '-' + this.month[days.getMonth()] + '-' + days.getFullYear();
      endDateContent = dateData.getDate() + '-' + this.month[dateData.getMonth()] + '-' + dateData.getFullYear();
    } else if (data.value === this.dateRanges.options[2].value) {
      days = new Date(dateData.getTime() - (7 * 24 * 60 * 60 * 1000));
      startDateContent = days.getDate() + '-' + this.month[days.getMonth()] + '-' + days.getFullYear();
      endDateContent = dateData.getDate() + '-' + this.month[dateData.getMonth()] + '-' + dateData.getFullYear();
    } else if (data.value === this.dateRanges.options[3].value) {
      days = new Date(dateData.getTime() - (30 * 24 * 60 * 60 * 1000));
      startDateContent = days.getDate() + '-' + this.month[days.getMonth()] + '-' + days.getFullYear();
      endDateContent = dateData.getDate() + '-' + this.month[dateData.getMonth()] + '-' + dateData.getFullYear();
    } else if (data.value === this.dateRanges.options[4].value) {
      days = new Date(dateData.getTime() - (60 * 24 * 60 * 60 * 1000));
      startDateContent = days.getDate() + '-' + this.month[days.getMonth()] + '-' + days.getFullYear();
      endDateContent = dateData.getDate() + '-' + this.month[dateData.getMonth()] + '-' + dateData.getFullYear();
    } else if (data.value === this.dateRanges.options[5].value) {
      days = new Date(dateData.getTime() - (90 * 24 * 60 * 60 * 1000));
      startDateContent = days.getDate() + '-' + this.month[days.getMonth()] + '-' + days.getFullYear();
      endDateContent = dateData.getDate() + '-' + this.month[dateData.getMonth()] + '-' + dateData.getFullYear();
    } else if (data.value === this.dateRanges.options[6].value) {
      days = new Date(dateData.getTime() - (120 * 24 * 60 * 60 * 1000));
      startDateContent = days.getDate() + '-' + this.month[days.getMonth()] + '-' + days.getFullYear();
      endDateContent = dateData.getDate() + '-' + this.month[dateData.getMonth()] + '-' + dateData.getFullYear();
    } else if (data.value === this.dateRanges.options[7].value) {
      days = new Date(dateData.getTime() - (180 * 24 * 60 * 60 * 1000));
      startDateContent = days.getDate() + '-' + this.month[days.getMonth()] + '-' + days.getFullYear();
      endDateContent = dateData.getDate() + '-' + this.month[dateData.getMonth()] + '-' + dateData.getFullYear();
    } else if (data.value === this.dateRanges.options[8].value) {
      days = new Date(dateData.getTime() - (365 * 24 * 60 * 60 * 1000));
      startDateContent = days.getDate() + '-' + this.month[days.getMonth()] + '-' + days.getFullYear();
      endDateContent = dateData.getDate() + '-' + this.month[dateData.getMonth()] + '-' + dateData.getFullYear();
    } else if (data.value === this.dateRanges.options[9].value) {
      days = new Date(dateData.getFullYear(), 0, 1);
      startDateContent = days.getDate() + '-' + this.month[(days.getMonth())] + '-' + days.getFullYear();
      endDateContent = dateData.getDate() + '-' + this.month[dateData.getMonth()] + '-' + dateData.getFullYear();
    }
    if (data.value !== this.dateRanges.options[10].value) {
      const DVDurationData = {
        'timerange': data.value,
        'startdate': startDateContent,
        'enddate': endDateContent,
        'starttime': '',
        'endtime': '',
        'isMoreThanYear' : false
      };
      this.localCacheService.storeData(this.key, JSON.stringify(DVDurationData));
      this.updateDateData.emit(DVDurationData);
    }
        this.updateDate.emit(data);
  }
}