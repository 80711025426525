// import { Injectable } from '@angular/core';
// import { Observable } from 'rxjs/Observable';
// import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

// export interface CanComponentDeactivate {
//     canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
//   }

//   @Injectable()
//   export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
//     canDeactivate(component: CanComponentDeactivate, 
//       currentRoute: ActivatedRouteSnapshot,
//       currentState: RouterStateSnapshot,
//       nextState?: RouterStateSnapshot
//       ) : Observable<boolean> | Promise<boolean> | boolean {

//        let url: string = currentState.url;
//        console.log('Url: '+ url);

//       return component.canDeactivate ? component.canDeactivate() : true;
//     }
//   }

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Observable, from } from 'rxjs';
import { DialogService } from './dialog.service';
import { map, catchError, mergeMap} from 'rxjs/operators';

export interface CanComponentDeactivate {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate>, CanActivate {
    constructor(private dialogService: DialogService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        throw new Error("Method not implemented.");
    }

    canDeactivate(component: CanComponentDeactivate,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot) {
        if (!component.canDeactivate) {
            return new Promise<boolean>(resolve => { return resolve(true); });
        }

        var retValue = component.canDeactivate();
        let currenturl: string = currentState.url;
        let prevurl: string = nextState.url;

        if (retValue instanceof Observable) {
            return this.intercept(retValue, currenturl, prevurl);
        } else {
            return retValue;
        }
    }

    private intercept(observable: Observable<any>, currenturl: string, prevurl: string): Observable<any> {
        return observable.pipe(
            map((res) => { return res; }),
            mergeMap((res) => {
                // Inverse logic - false means deactivate of route is not allowed (hasChanges is true) 
                if (res === false) {
                    var modalPromise = this.dialogService.confirm();
                    var newObservable = from(modalPromise);
                    newObservable.subscribe(
                        (res) => {
                            if (res === true) {
                                history.pushState({}, "", prevurl);

                            } else {
                                history.pushState({}, "", currenturl);
                            }
                        },
                        (reason) => {
                        }
                    );
                    return newObservable;
                } else {
                    return Observable.of(res);
                }
            }),
            catchError(error => Observable.of(false)));
    }
}