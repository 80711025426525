import { IDropdownOption } from '../../shared/components/dropdown/dropdown-model';
export const StageType = [
  {
    text: 'FINANCIAL_IMPACT.STAGE_TYPES.IDENTIFIED',
    value: '0'
  },
  {
    text: 'FINANCIAL_IMPACT.STAGE_TYPES.APPROVED',
    value: '1'
  },
  {
    text: 'FINANCIAL_IMPACT.STAGE_TYPES.IN_PROCESS',
    value: '2'
  },
  {
    text: 'FINANCIAL_IMPACT.STAGE_TYPES.IMPLEMENTED',
    value: '3'
  },
  {
    text: 'FINANCIAL_IMPACT.STAGE_TYPES.FINAL_SIGN',
    value: '4'
  },
];

export const EROIValueType = [
  {
    text: 'FINANCIAL_IMPACT.PROJECT_TABLE.GREENHOUSE_GASES',
    value: '1'
  },
  {
    text: 'FINANCIAL_IMPACT.PROJECT_TABLE.CAPACITY_ASSET_PROTECTION',
    value: '2'
  },
  {
    text: 'FINANCIAL_IMPACT.PROJECT_TABLE.PRODUCTIVITY_PRODUCT',
    value: '3'
  },
  {
    text: 'FINANCIAL_IMPACT.PROJECT_TABLE.ENERGY_ELECTRICAL',
    value: '4'
  },
  {
    text: 'FINANCIAL_IMPACT.PROJECT_TABLE.SAFETY_FOOD',
    value: '5'
  },
  {
    text: 'FINANCIAL_IMPACT.PROJECT_TABLE.QUALITY_PRODUCT',
    value: '6'
  },
  {
    text: 'FINANCIAL_IMPACT.PROJECT_TABLE.PRODUCTIVITY_LABOR',
    value: '7'
  },
  {
    text: 'FINANCIAL_IMPACT.PROJECT_TABLE.PRODUCTIVITY_LOCATION',
    value: '9'
  },
  {
    text: 'FINANCIAL_IMPACT.PROJECT_TABLE.SAFETY_HUMAN',
    value: '10'
  },
  {
    text: 'FINANCIAL_IMPACT.PROJECT_TABLE.ENERGY_THERMAL',
    value: '11'
  },
  {
    text: 'FINANCIAL_IMPACT.PROJECT_TABLE.WASTE_SOLID',
    value: '12'
  },
  {
    text: 'FINANCIAL_IMPACT.PROJECT_TABLE.WATER',
    value: '13'
  }

];

export const ProjectTableColumns = [
  {
    'title': 'FINANCIAL_IMPACT.PROJECT_TABLE.SITE_NAME',
    'defaultActive': true,
    'columnOrder': 1,
    'displayFormat': 'Col',
    'isLocked': true,
    'field': 'SiteName',
    'width': '200'
  },
  {
    'title': 'FINANCIAL_IMPACT.PROJECT_TABLE.PROJECT_NAME',
    'defaultActive': true,
    'columnOrder': 2,
    'displayFormat': 'Col',
    'isLocked': true,
    'field': 'ProjectName',
    'width': '200'
  },
  {
    'title': 'FINANCIAL_IMPACT.PROJECT_TABLE.STAGE',
    'defaultActive': true,
    'columnOrder': 3,
    'displayFormat': 'Col',
    'isLocked': true,
    'field': 'ProjectStage',
    'width': '100'
  },
  /** Splitting the Stage and Days in stage into two separate columns */
  {
    'title': 'FINANCIAL_IMPACT.ACCORDING_TO_TYPES.DATE_PROJECT_LAST_UPDATED',
    'defaultActive': true,
    'columnOrder': 4,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'ModifiedOn',
    'width': '100'
  },
  /*
  {
    'title': 'Stage',
    'defaultActive': false,
    'columnOrder': null,
    'displayFormat': 'Col',
    'isLocked': true
  },
  {
    'title': 'Days in Stage',
    'defaultActive': false,
    'columnOrder': null,
    'displayFormat': 'Col',
    'isLocked': true
  },*/
  {
    'title': 'FINANCIAL_IMPACT.ACCORDING_TO_TYPES.DATE_IDENTIFIED',
    'defaultActive': false,
    'columnOrder': 5,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'ProjectIdentifiedDate',
    'width': '100'
  },
  {
    'title': 'FINANCIAL_IMPACT.ACCORDING_TO_TYPES.DATE_APPROVED',
    'defaultActive': false,
    'columnOrder': 6,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'CustomerApprovalDate',
    'width': '100'
  },
  {
    'title': 'FINANCIAL_IMPACT.ACCORDING_TO_TYPES.DATE_IN_PROCESS',
    'defaultActive': false,
    'columnOrder': 7,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'ActualStartDate',
    'width': '100'
  },
  {
    'title': 'FINANCIAL_IMPACT.ACCORDING_TO_TYPES.DATE_IMPLEMENTED',
    'defaultActive': false,
    'columnOrder': 8,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'ActualImplementationDate',
    'width': '100'
  },
  {
    'title': 'FINANCIAL_IMPACT.ACCORDING_TO_TYPES.DATE_SIGNED_OFF',
    'defaultActive': false,
    'columnOrder': 9,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'SignOffDate',
    'width': '100'
  },
  // added above - the additional datestamp columns
  {
    'title': 'FINANCIAL_IMPACT.PROJECT_TABLE.PROJECT_TYPE',
    'defaultActive': false,
    'columnOrder': 10,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'ProjectType'
  },
  // adding the new CRM fields
  {
    'title': 'FINANCIAL_IMPACT.PROJECT_TABLE.CUSTOMER_SIGNOFF_DOCUMENT_EXISTS',
    'defaultActive': false,
    'columnOrder': 11,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'CustomerSignOffDocumentationExists'
  },
  {
    'title': 'FINANCIAL_IMPACT.PROJECT_TABLE.PROJECT_CHARTER_EXISTS',
    'defaultActive': false,
    'columnOrder': 12,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'ProjectCharterExists'
  },
  {
    'title': 'FINANCIAL_IMPACT.PROJECT_TABLE.CIP_UTILIZED',
    'defaultActive': false,
    'columnOrder': 13,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'CIPUtilized'
  },
  {
    'title': 'FINANCIAL_IMPACT.PROJECT_TABLE.TOTAL_SAVINGS',
    'defaultActive': true,
    'columnOrder': 14,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'TotalSavings'
  },
  {
    'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.WATER_SAVINGS',
    'defaultActive': true,
    'columnOrder': 15,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'WaterSavings'
  },
  {
    'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.WATER_AMOUNT',
    'defaultActive': true,
    'columnOrder': 16,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'WaterAmount'
  },
  {
    'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.ENERGY_ELECTRICAL_SAVINGS',
    'defaultActive': false,
    'columnOrder': 17,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'EnergyElectricalSavings',
  },
  {
    'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.ENERGY_ELECTRICAL_AMOUNT',
    'defaultActive': false,
    'columnOrder': 18,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'EnergyElectricalAmount'
  },
  {
    'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.ENERGY_THERMAL_SAVINGS',
    'defaultActive': false,
    'columnOrder': 19,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'EnergyThermalSavings'
  },
  {
    'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.ENERGY_THERMAL_AMOUNT',
    'defaultActive': false,
    'columnOrder': 20,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'EnergyThermalAmount'
  },
  {
    'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.WASTE_SAVINGS',
    'defaultActive': false,
    'columnOrder': 21,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'WasteSavings'
  },
  {
    'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.WASTE_AMOUNT',
    'defaultActive': false,
    'columnOrder': 22,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'WasteAmount'
  },
  {
    'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.PRODUCTIVITY_PLANT_SAVINGS',
    'defaultActive': false,
    'columnOrder': 23,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'ProductivityPlantSavings'
  },
  {
    'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.PRODUCTIVITY_PLANT_AMOUNT',
    'defaultActive': false,
    'columnOrder': 24,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'ProductivityPlantAmount'
  },
  {
    'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.GREENHOUSE_GASES_SAVINGS',
    'defaultActive': false,
    'columnOrder': 25,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'GreenhouseGasesSavings'
  },
  {
    'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.GREENHOUSE_GASES_AMOUNT',
    'defaultActive': false,
    'columnOrder': 26,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'GreenhouseGasesAmount'
  },
  {
    'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.ASSET_PROTECTION_SAVINGS',
    'defaultActive': false,
    'columnOrder': 27,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'AssetProtectionSavings'
  },
  {
    'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.ASSET_PROTECTION_AMOUNT',
    'defaultActive': false,
    'columnOrder': 28,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'AssetProtectionAmount'
  },
  {
    'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.PRODUCTIVITY_CHEMISTRY_SAVINGS',
    'defaultActive': false,
    'columnOrder': 29,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'ProductivityChemistrySavings'
  },
  {
    'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.PRODUCTIVITY_CHEMISTRY_AMOUNT',
    'defaultActive': false,
    'columnOrder': 30,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'ProductivityChemistryAmount'
  },
  {
    'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.SAFETY_FOOD_SAVINGS',
    'defaultActive': false,
    'columnOrder': 31,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'SafetyFoodSavings'
  },
  {
    'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.PRODUCT_QUALITY_SAVINGS',
    'defaultActive': false,
    'columnOrder': 32,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'ProductQualitySavings'
  },
  {
    'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.PRODUCTIVITY_PEOPLE_SAVINGS',
    'defaultActive': false,
    'columnOrder': 33,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'ProductivityPeopleSavings'
  },
  {
    'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.PRODUCTIVITY_PEOPLE_AMOUNT',
    'defaultActive': false,
    'columnOrder': 34,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'ProductivityPeopleAmount'
  },
  {
    'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.SAFETY_HUMAN_SAVINGS',
    'defaultActive': false,
    'columnOrder': 35,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'SafetyHumanSavings'
  },
  {
    'title': 'FINANCIAL_IMPACT.PROJECT_TABLE.PROJECT_BENEFIT',
    'defaultActive': false,
    'columnOrder': 36,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'ProjectBenefits'
  },
  {
    'title': 'FINANCIAL_IMPACT.PROJECT_TABLE.CURRENT_STATUS',
    'defaultActive': true,
    'columnOrder': 37,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'CurrentStatus',
  },
  {
    'title': 'FINANCIAL_IMPACT.PROJECT_TABLE.NALCO_CONTACT',
    'defaultActive': false,
    'columnOrder': 38,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'RepFirstName',
  },
  {
    'title': 'FINANCIAL_IMPACT.PROJECT_TABLE.CUSTOMER_CONTACT',
    'defaultActive': false,
    'columnOrder': 99,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'ResCustomerFirstName',
  }

];

export const ActionStatusType = [
  {
    display: 'All ',
    id: -1,
    value: 'All',
    checked: false
  },
  {
    display: 'Deferred',
    id: 1,
    value: 'Deferred',
    checked: false
  },
  {
    display: 'Open',
    id: 2,
    value: 'Open',
    checked: false
  },
  {
    display: 'Closed',
    id: 4,
    value: 'Closed',
    checked: false
  }];

export const ActionImpactType = [
  {
    display: 'All ',
    id: -1,
    value: 'all',
    checked: false
  },
  {
    display: 'Low',
    id: 0,
    value: 'low',
    checked: false
  },
  {
    display: 'Medium',
    id: 1,
    value: 'medium',
    checked: false
  },
  {
    display: 'High',
    id: 2,
    value: 'high',
    checked: false
  },
  {
    display: 'Safety',
    id: 3,
    value: 'safety',
    checked: false
  }];

export const AssetStatusType = [
  {
    display: 'All ',
    id: -1,
    value: 'all',
    checked: false
  },
  {
    display: 'Red',
    id: 0,
    value: 'red',
    checked: false,
    isImage: 'red'
  },
  {
    display: 'Amber',
    id: 1,
    value: 'yellow',
    checked: false,
    isImage: 'yellow'
  },
  {
    display: 'Green',
    id: 2,
    value: 'green',
    checked: false,
    isImage: 'green'
  },
  {
    display: 'Grey',
    id: 3,
    value: 'grey',
    checked: false,
    isImage: 'grey'
  }];

export const enum ContextPointType {
  None = 0,
  Root = 1000,
  EcolabViewOfCustomer = 2000,
  EcolabGeography = 3000,
  CustomerViewOfCustomer = 4000,
  Site = 5000,
  Account = 6000,
  Group = 7000,
  System = 8000,
  Asset = 9000,
  SubAsset = 10000,
  Port = 11000,
  AutomaticDatasource = 12000,
  ManualDatasource = 13000,
  MeasurementPoint = 15000
}

export enum ServiceType {
  None = 0,
  WaterSafety = 1,
  WaterQuality = 2,
  AssetTreeBuilder = 3,
  FinacialImpact = 4,
  Omni = 5,
  DataVisualization = 6,
  WaterFlowIntelligence = 7,
  WaterFlowIntelligenceNotification = 8,
  WaterFlowIntelligenceDdl = 9,
  AssetHarvesterBuilder = 10,
  ServiceSummary = 11,
  SharedDocuments = 12,
  SystemAssurance = 16,
  AssetDetails = 17,
  ClimateIntelligence = 19,
  CrudeFlex = 20,
  Paper = 32
}

export const ServiceTypeIds = [ 4, 11, 19]

export enum DurationType {
  YearToDate = 10,
  CustomDate = 11
}

export const DocumentType = {
  ServiceSummary: 'ServiceSummaryDashboard',
  SharedDocuments: 'SharedDocuments',
  LimsDocuments: 'LimsDocument',
  CustomerLogo: 'CustomerLogo'
}

export enum AssetStatus {
  Hidden = 0,
  Active = 1,
  Pending = 2,
  Delete = 3,
  Inactive = 4,
  Offline = 5,
  UnHidden = 6
}

export enum Services {
  WSI = 1,
  WQ = 2,
  ATB = 3,
  FI = 4,
  OMNI = 5,
  DV = 6,
  WFI = 7,
  WFIN = 8,
  WFID = 9,
  AHB = 10,
  SSD = 11,
  MyDocuments = 12,
  ICC = 13,
  SCI = 14,
  WBI = 15,
  SA = 16,
  CS = 17,
}

export enum NotDelatableReason {
  OMNI = 1,
  Inventory = 2,
  Mining = 3,
  WFI = 4,
  LIMS = 5,
}

export const ArrayHeader = [
  {
    text: ['Asset Name', 'Alarm', 'Alarm On Date', 'Alarm On Time'],
    value: 1
  },
  {
    text: ['Asset Name', 'Alarm', 'Alarm On Date', 'Alarm On Time', 'Alarm Off Date', 'Alarm Off Time'],
    value: 2
  }
];

export enum RTECount {
  MaxRTELength = 4
}

export enum StatusCode {
  InternalServerError = 500,
  Success = 200
}

export enum DataSourceTypes {
  None = 0,
  OBC = 1,
  NXC = 2,
  NGC = 3,
  DigiNGG = 21,
  EcolabDataCollector = 26,
  FordEndNode = 7,
  KomodoEndNode = 9
}
export const UnitOfMeasure: Array<IDropdownOption> = [
  {
    text: 'FINANCIAL_IMPACT.ADMIN.GENERAL_SETTINGS.UNIT_OF_MEASURE_ITEMS.US_STANDARD',
    value: '0'
  },
  {
    text: 'FINANCIAL_IMPACT.ADMIN.GENERAL_SETTINGS.UNIT_OF_MEASURE_ITEMS.METRIC',
    value: '1'
  }

];
export const Language: Array<IDropdownOption> = [
  {
    text: 'English US',
    value: 'en-US'
  },
  {
    text: 'Deutsch',
    value: 'de-DE'
  },
  {
    text: 'Español',
    value: 'es-ES'
  },
  {
    text: 'Français',
    value: 'fr-FR'
  },
  {
    text: 'Italiano',
    value: 'it-IT'
  },
  {
    text: 'Nederlands',
    value: 'nl-NL'
  },
  {
    text: 'Português',
    value: 'pt-PT'
  },
  {
    text: 'Русский язык',
    value: 'ru-RU'
  },
  {
    text: '中文',
    value: 'zh-ZH'
  }
];

export enum LastServiceVisitCount {
  VisitCount = 10
}

export const CorporateAccountName = {
  UNALIGNED_SITE:"UNALIGNED"
}

export const ServiceEnabledName = {
  ASSET_DETAILS:'Asset_Details',
  HEAT_SURVEYS:'Heat_Surveys',
  WATER_QUALITY:'WQ'
}

export enum ActiveFilterTab {
  CustomerDivision = "Customer Division",
  GeoAndSalesRegion = "Geo and Sales Region",
  Sites = "Sites"
}

export const starttimeData = [
  {
    text: 'COMMON.12_AM',
    value: '12 AM',
    isSelected: false
  },
  {
    text: 'COMMON.01_AM',
    value: '01 AM',
    isSelected: false
  },
  {
    text: 'COMMON.02_AM',
    value: '02 AM',
    isSelected: false
  },
  {
    text: 'COMMON.03_AM',
    value: '03 AM',
    isSelected: false
  },
  {
    text: 'COMMON.04_AM',
    value: '04 AM',
    isSelected: false
  },
  {
    text: 'COMMON.05_AM',
    value: '05 AM',
    isSelected: false
  },
  {
    text: 'COMMON.06_AM',
    value: '06 AM',
    isSelected: false
  },
  {
    text: 'COMMON.07_AM',
    value: '07 AM',
    isSelected: false
  },
  {
    text: 'COMMON.08_AM',
    value: '08 AM',
    isSelected: false
  },
  {
    text: 'COMMON.09_AM',
    value: '09 AM',
    isSelected: false
  },
  {
    text: 'COMMON.10_AM',
    value: '10 AM',
    isSelected: false
  },
  {
    text: 'COMMON.11_AM',
    value: '11 AM',
    isSelected: false
  },
  {
    text: 'COMMON.12_PM',
    value: '12 PM',
    isSelected: false
  },
  {
    text: 'COMMON.01_PM',
    value: '01 PM',
    isSelected: false
  },
  {
    text: 'COMMON.02_PM',
    value: '02 PM',
    isSelected: false
  },
  {
    text: 'COMMON.03_PM',
    value: '03 PM',
    isSelected: false
  },
  {
    text: 'COMMON.04_PM',
    value: '04 PM',
    isSelected: false
  },
  {
    text: 'COMMON.05_PM',
    value: '05 PM',
    isSelected: false
  },
  {
    text: 'COMMON.06_PM',
    value: '06 PM',
    isSelected: false
  },
  {
    text: 'COMMON.07_PM',
    value: '07 PM',
    isSelected: false
  },
  {
    text: 'COMMON.08_PM',
    value: '08 PM',
    isSelected: false
  },
  {
    text: 'COMMON.09_PM',
    value: '09 PM',
    isSelected: false
  },
  {
    text: 'COMMON.10_PM',
    value: '10 PM',
    isSelected: false
  },
  {
    text: 'COMMON.11_PM',
    value: '11 PM',
    isSelected: false
  },
  {
    text: '*',
    value: '*',
    isSelected: true
  }
];

export const endtimeData = [
  {
    text: 'COMMON.12_AM',
    value: '12:59 AM',
    isSelected: false
  },
  {
    text: 'COMMON.01_AM',
    value: '01:59 AM',
    isSelected: false
  },
  {
    text: 'COMMON.02_AM',
    value: '02:59 AM',
    isSelected: false
  },
  {
    text: 'COMMON.03_AM',
    value: '03:59 AM',
    isSelected: false
  },
  {
    text: 'COMMON.04_AM',
    value: '04:59 AM',
    isSelected: false
  },
  {
    text: 'COMMON.05_AM',
    value: '05:59 AM',
    isSelected: false
  },
  {
    text: 'COMMON.06_AM',
    value: '06:59 AM',
    isSelected: false
  },
  {
    text: 'COMMON.07_AM',
    value: '07:59 AM',
    isSelected: false
  },
  {
    text: 'COMMON.08_AM',
    value: '08:59 AM',
    isSelected: false
  },
  {
    text: 'COMMON.09_AM',
    value: '09:59 AM',
    isSelected: false
  },
  {
    text: 'COMMON.10_AM',
    value: '10:59 AM',
    isSelected: false
  },
  {
    text: 'COMMON.11_AM',
    value: '11:59 AM',
    isSelected: false
  },
  {
    text: 'COMMON.12_PM',
    value: '12:59 PM',
    isSelected: false
  },
  {
    text: 'COMMON.01_PM',
    value: '01:59 PM',
    isSelected: false
  },
  {
    text: 'COMMON.02_PM',
    value: '02:59 PM',
    isSelected: false
  },
  {
    text: 'COMMON.03_PM',
    value: '03:59 PM',
    isSelected: false
  },
  {
    text: 'COMMON.04_PM',
    value: '04:59 PM',
    isSelected: false
  },
  {
    text: 'COMMON.05_PM',
    value: '05:59 PM',
    isSelected: false
  },
  {
    text: 'COMMON.06_PM',
    value: '06:59 PM',
    isSelected: false
  },
  {
    text: 'COMMON.07_PM',
    value: '07:59 PM',
    isSelected: false
  },
  {
    text: 'COMMON.08_PM',
    value: '08:59 PM',
    isSelected: false
  },
  {
    text: 'COMMON.09_PM',
    value: '09:59 PM',
    isSelected: false
  },
  {
    text: 'COMMON.10_PM',
    value: '10:59 PM',
    isSelected: false
  },
  {
    text: 'COMMON.11_PM',
    value: '11:59 PM',
    isSelected: false
  },
  {
    text: '*',
    value: '*',
    isSelected: true
  }
];

export const enum DatasourceContextPointType {
  AutomaticDatasource = 'AutomaticDatasource',
  ManualDatasource = 'ManualDatasource'
}

export const enum BusinessUnitCode {
  All = "All",
  NalcoWater = "WT"
}

export const enum ContextPointSubType {
  One = 1,
  Six = 6
}
export const DateOption =
{
  label: 'COMMON.SORT_OPTIONS.VIEW_SITES_BY',
  name: 'durationDropDown',
  options: [
    {
      text: 'COMMON.SORT_OPTIONS.ONEDAYS',
      value: 'last1',
      isSelected: false
    },
    {
      text: 'COMMON.SORT_OPTIONS.SEVENDAYS',
      value: 'last7',
      isSelected: false
    },
    {
      text: 'COMMON.SORT_OPTIONS.THIRTYDAYS',
      value: 'last30',
      isSelected: false
    },
    {
      text: 'COMMON.SORT_OPTIONS.SIXTYDAYS',
      value: 'last60',
      isSelected: false
    },
    {
      text: 'COMMON.SORT_OPTIONS.NINETYDAYS',
      value: 'last90',
      isSelected: false
    },
    {
      text: 'COMMON.SORT_OPTIONS.ONETWOZERODAYS',
      value: 'last120',
      isSelected: false
    },
    {
      text: 'COMMON.SORT_OPTIONS.ONEEIGHTZERODAYS',
      value: 'last180',
      isSelected: false
    },
    {
      text: 'COMMON.SORT_OPTIONS.THREESIXFIVEDAYS',
      value: 'last365',
      isSelected: false
    },
    {
      text: 'COMMON.SORT_OPTIONS.CUSTOM',
      value: 'cust11',
      isSelected: false
    }
  ]
}

export const enum AssetQuestionConstant {
  YearPutIntoService = 'Year Put Into Service',
  ExpectedRetirementDate = 'Expected Retirement Date',
  Manufacturer = 'Manufacturer',
  Model = 'Model',
  SerialNumber = 'Serial Number',
  RecirculationRate = 'Recirculation Rate',
  CoolingCapacity = 'Cooling Capacity',
  AvgHoldingTimeIndex = 'Avg Holding Time Index',
  RetirementExpectedDate = 'Retirement Expected Date',

  RecirculationRatePropertyResourceName = 'CPSubTypeProperties_PropertyName_RecirculationRate',
  CoolingCapacityPropertyResourceName = 'CPSubTypeProperties_PropertyName_CoolingCapacity',
  AvgHoldingTimeIndexPropertyResourceName = 'CPSubTypeProperties_PropertyName_AvgHoldingTimeIndex'
}
