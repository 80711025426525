import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/do';

@Component({
    selector: 'nalco-custom-popup',
    templateUrl: './custom-popup.component.html',
    styleUrls: ['./custom-popup.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CustomPopupComponent implements OnInit {
    @Input() modalText: string;
    @Input() modcontent;
    @Input() isValid: boolean = true;
    @Input() disablebtn: boolean;
    @Input() showBtn: boolean;
    @Input() cancelBtn: boolean;
    @Input() actionBtn: boolean;
    @Input() confirmBtn = '';
    @Input() closeBtn = '';
    @Input() stayBtn = '';
    @Input() closeIcon: boolean;
    @Input() btnShow: boolean = false;
    @Input() showWarning: boolean;
    @Input() savedPopup: boolean;
    @Output('cancelModal') cancelModal = new EventEmitter<any>();
    @Output('stayModalPopUp') stayModalPopUp = new EventEmitter<any>();
    @Output() deleteModal = new EventEmitter<any>();
    currentEdit: any;
    btnId: string;
    showRadio: boolean = false;
    isEnable: boolean = false;
    selectedValue: string;
    constructor(public activeModal: NgbActiveModal, public changeRef: ChangeDetectorRef) {
    }

    ngOnInit() {
    }
    cancelAddEditModal() {
        this.cancelModal.emit({});
        this.activeModal.close();
    }

    public Delete() {
        this.deleteModal.emit({ selectedValue: this.selectedValue });
    }

    stayModal() {
        this.stayModalPopUp.emit({});
        this.activeModal.close();
    }

    onSelectionChange(value: string) {
        this.selectedValue = value;
        this.isEnable = false;
    }
}
