import { IRegionCountries } from '../contracts/IRegionCountries';

export class RegionCountries implements IRegionCountries {
    RegionId: number;
    RegionName: string;
    CountryId: number;
    CountryCode: string;
    CountryName: string;

    constructor(regions: IRegionCountries) {
        this.RegionId = regions.RegionId;
        this.RegionName = regions.RegionName;
        this.CountryId = regions.CountryId;
        this.CountryCode = regions.CountryCode;
        this.CountryName = regions.CountryName;
    }
}
