import { Injectable } from '@angular/core';

@Injectable()
export class AssetDataStoreService {
    private data: { [key: string]: any } = {};

    public getAll(): any {
        return this.data;
    }

    public get(key: string): any {
        return this.data[key] || null;
    }

    public set(key: string, value: any): void {
        this.data[key] = value;
    }

    public removeKey(key: string): any {
        delete this.data[key];
    }

    public reset(): void {
        this.data = {};
    }
}
