import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserProfileDetailsComponent  } from './user-profile-details.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DividerModule } from '../divider/divider.module'


@NgModule({
  declarations: [
    UserProfileDetailsComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    DividerModule
  ],
  exports: [
    UserProfileDetailsComponent
  ]
})
export class UserProfileDetailsModule { }
