import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartObject } from 'highcharts';
import * as Highcharts from 'highcharts/highcharts';

@Component({
  selector: 'nalco-speedo-meter-guage',
  templateUrl: './speedo-meter-guage.component.html',
  styleUrls: ['./speedo-meter-guage.component.scss']
})
export class SpeedoMeterGuageComponent implements OnInit {
  customChart = 'speedo-meter';
  @Input() maxValue;
  @Input() dataValue;
  @Input() minValue;
  @Input() garphId;

  chartSpeed: ChartObject;

  constructor(private translate: TranslateService) {
  }

  ngOnInit() {
    this.valueCheck();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.dataValue && !changes.dataValue.firstChange) {
      this.updateChartData();
    }
  }
  ngAfterViewInit() {
    this.initializeChart();
  }
  initializeChart() {
    const gaugeOptions = {
      chart: {
        type: 'gauge',
        spacing: [10, 10, 10, 10],
        plotBackgroundColor: null,
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false,
        height: '67%',
        marginTop: 0,
        marginBottom: 20,
        marginLeft: 100,
        marginRight: 0,
        spacingTop: 0,
        spacingBottom: 500,
        spacingLeft: 0,
        spacingRight: 0,
        width: 420
      },
      title: {
        text: this.translate.instant('CRUDE_FLEX.WIDGET_TITLE.NCSV_CRUDE_BLEND_STABILITY'),
        align: "left",
        backgroundColor: '#f0f0f0',
        x: 160,
        style: {
          color: '#007ac9',
          fontSize: '16px',
          fontFamily: 'Interstate-Regular, sans-serif',
          margin: '10px 0 0 20px'
        },
      },
      pane: {
        startAngle: -90,
        endAngle: 89.9,
        background: null,
        center: ['50%', '75%'],
        size: '110%'
      },
      exporting: {
        enabled: false
      },
      tooltip: {
        enabled: false
      },
      yAxis: {
        min: this.minValue,
        max: this.maxValue,
        tickPixelInterval: 72,
        tickPosition: 'inside',
        tickColor: '#666',
        tickLength: 20,
        tickWidth: 2,
        minorTickInterval: null,
        labels: {
          formatter: function () {
            return (this.value == 1.6 || this.value == 2) ? this.value : '';
          },
          distance: 20,
          style: {
            fontSize: '14px'
          }
        },
        lineWidth: 0,
        plotBands: [
          {
            from: this.minValue,
            to: 1.6,
            color: '#E00034',
            thickness: 20,
          }, {
            from: 1.6,
            to: 2,
            color: '#EEAF00',
            thickness: 20,
          }, {
            from: 2,
            to: this.maxValue,
            color: '#6FB43F',
            thickness: 20,
          }
        ]
      },
    };
    this.chartSpeed = Highcharts.chart('container-speed' + this.garphId, Highcharts.merge(gaugeOptions, {
      yAxis: {
        min: this.minValue,
        max: this.maxValue,
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Speed',
        data: [this.dataValue],
        className: this.customChart,
        dataLabels: {
          enabled: false,
          borderWidth: 0,
          color: (
            Highcharts.defaultOptions.title &&
            Highcharts.defaultOptions.title.style &&
            Highcharts.defaultOptions.title.style.color
          ) || '#333333',
          style: {
            fontSize: '16px'
          }
        },
        dial: {
          radius: '80%',
          backgroundColor: '#000',
          baseWidth: 12,
          baseLength: '0%',
          rearLength: '0%'
        },
        pivot: {
          backgroundColor: '#000',
          radius: 13
        }
      }]
    }));
  }

  valueCheck() {
    if (this.dataValue == null || this.dataValue == undefined) {
      this.dataValue = 1.2;
    }
  }

  updateChartData() {
    if (this.chartSpeed) {
      this.chartSpeed.update({
        series: [{
          data: [this.dataValue],
        }]
      });
    }
  }
}
