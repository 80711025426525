import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'nalco-view-all-notification',
  templateUrl: './view-all-notification.component.html',
  styleUrls: ['./view-all-notification.component.scss']
})
export class ViewAllNotificationComponent {

  constructor(private modalService: NgbModal) { }

}
