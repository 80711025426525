<div class="modal-header pt-4 pl-4 mb-0 border-0">

    <h3 class="modal-title delete-txt-icon">{{modalText}}</h3>
    <button type="button" *ngIf="closeIcon" class="close" aria-label="Close" (click)="disablebtn? activeModal.close(): cancelAddEditModal();">
        <img src="../../../../assets/images/modal-close-icon.svg" alt="close" />
    </button>
  </div>
 
  <p class="revert mb-0" [innerHtml]="modcontent"></p>
  <div class="modal-body p-0" *ngIf="showModalBody">
  </div>
  <div class="modal-footer mt-0">
    <button type="button" class="btn btn-secondary" (click)="delete()" [ngClass]="[disablebtn ===true  ? 'display-block'  : 'display-none']">{{'COMMON.DELETE' | translate}}</button>
    <button type="button" class="btn btn-primary" (click)="cancelAddEditModal()" [ngClass]="[localDriverBtn ===true  ? 'display-none'  : 'display-block']">{{disablebtn == true ? 'Cancel' : 'Close'}}</button>
    <button type="button" class="btn btn-primary" (click)="cancelAddEditModal()" [ngClass]="[localDriverBtn ===true  ? 'display-block'  : 'display-none']">{{'COMMON.OK' | translate}}</button>
  </div>
  