import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChange } from '@angular/core';
import { IIdAndName } from '../../contracts/IIdAndName';
import { LocalCacheService } from '../../../core/local-cache/local-cache.service';
import { Icon } from '../../../quality/_enum/color.enum';

@Component({
  selector: 'nalco-filter-pills',
  templateUrl: './filter-pills.component.html',
  styleUrls: ['./filter-pills.component.scss']
})
export class FilterPillsComponent implements OnInit, OnChanges {
  @Input() componentIdentifier: string;
  @Input() primaryFilters: Array<any> = [];
  @Input() secondaryFilters?: Array<any> = [];
  @Input() defaultPrimaryFilters: Array<any> = [];
  @Input() defaultSecondaryFilters?: Array<any> = [];
  @Output('changeInFilterCriteria') changeInFilterCriteria = new EventEmitter<any>();
  @Input() showInfoIcon = false;
  @Input() showFilterText = true;
  @Input() secondaryFilterText = 'Filters';
  @Input() htmlContent?: any;
  @Input() barType?: string;
  @Input() multiSelect: boolean;
  @Input() multiFilter: boolean;
  @Input() isPrintPreview: boolean;
  @Input() serviceType?: number;
  appliedfilter: { primary: Array<any>; secondary: Array<any> } = { primary: [], secondary: [] };

  constructor(private localCacheService: LocalCacheService) {

  }
  public Icons = Icon;
  pills: Array<any> = [
    { id: 1, iconClass: this.Icons.Green },
    { id: 2, iconClass: this.Icons.Yellow },
    { id: 3, iconClass: this.Icons.Red},
    { id: 4, iconClass: this.Icons.Grey}
  ];

  ngOnInit() {
    this.checkDataPrimaryAppliedFilters();
    this.checkDataSecondaryAppliedFilters();
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange, }) {
    // if (changes.defaultPrimaryFilters && changes.defaultPrimaryFilters.firstChange === true) { // Fault 232825
    if (changes.defaultPrimaryFilters) {
      this.appliedfilter.primary = JSON.parse(JSON.stringify(this.defaultPrimaryFilters));
    }
    if (changes.defaultSecondaryFilters && changes.defaultSecondaryFilters.firstChange === true) {
      this.appliedfilter.secondary = JSON.parse(JSON.stringify(this.defaultSecondaryFilters));
    }
  }


  isSelectedFilter(type, id) {
    if (type === 'primary') {
      // return this.appliedfilter.primary.toString().indexOf(id.toString()) > -1;
      if (this.multiFilter === true) {
        return this.appliedfilter.primary.filter(item => item === id).length > 0;
      } else {
        //return this.appliedfilter.primary.toString().indexOf(id.toString()) > -1;
        return this.appliedfilter.primary.length > 0 ? this.appliedfilter.primary[0].toString() === id.toString() : false;
      }
    }
    if (type === 'secondary') {
      return this.appliedfilter.secondary.filter(item => item.id === id).length > 0;
    }
  }

  changePrimaryFilter($event, item: any) {
    // this.appliedfilter.primary = [item.id];
    let primary = [];
    if (this.multiFilter === true) {
      if (this.appliedfilter.primary.indexOf(item.id) > -1) {
        primary = this.appliedfilter.primary.filter(id => id !== item.id);
        if (primary.length>0 && this.serviceType===11){
          this.appliedfilter.primary = primary;
        }
      } else {
        this.appliedfilter.primary.push(item.id);
      }
    } else {
      this.appliedfilter.primary = [item.id];
    }
    this.appliedfilter.secondary = [];
    this.changeInFilterCriteria.emit(this.appliedfilter);
    this.storeData('primaryAppliedFilterData_' + this.componentIdentifier, JSON.stringify(this.appliedfilter.primary));
    
    const secData = this.fetchData('secondaryAppliedFilterData_' + this.componentIdentifier);
    if(secData){
      this.localCacheService.clearData('secondaryAppliedFilterData_' + this.componentIdentifier,'session');
    }
  }

  toggleSecondaryFilter($event: any, item: any) {
    if (this.multiSelect) {
      this.appliedfilter.secondary = [];
      this.appliedfilter.secondary.push(item);
    } else {
      const filter = this.appliedfilter.secondary.find(secondaryFilter => secondaryFilter.id === item.id);
      let isExist = true;
      for (let index = 0; index < this.appliedfilter.secondary.length; index++) {
        if (this.appliedfilter.secondary[index].id === item.id) {
          this.appliedfilter.secondary.splice(index, 1);
          isExist = false;
          break;
        }
      }
      if (isExist) {
        this.appliedfilter.secondary.push(item);
      }
    }
    this.changeInFilterCriteria.emit(this.appliedfilter);
    this.storeData('secondaryAppliedFilterData_' + this.componentIdentifier, JSON.stringify(this.appliedfilter.secondary));
  }

  /************** Session Storage section ****************/
  private storeData(key: string, value: any) {
    this.localCacheService.storeData(key, value);
    this.fetchData(key);
  }

  private fetchData(key: string) {
    this.localCacheService.fetchData(key);
    // console.log('Fetch stored data ===> ' + this.localCacheService.fetchData(key));
    // console.log(this.appliedfilter.primary);
    return this.localCacheService.fetchData(key);
  }

  private checkDataPrimaryAppliedFilters() {
    if (this.fetchData('primaryAppliedFilterData_' + this.componentIdentifier) !== null) {
      // this.appliedfilter.primary = JSON.parse(this.fetchData('primaryAppliedFilterData_' + this.componentIdentifier));
      if (this.multiFilter === true) {
        this.appliedfilter.primary = [JSON.parse(this.fetchData('primaryAppliedFilterData_' + this.componentIdentifier))];
      } else {
        this.appliedfilter.primary = JSON.parse(this.fetchData('primaryAppliedFilterData_' + this.componentIdentifier));
      }
      this.changeInFilterCriteria.emit(this.appliedfilter);
    } else {
      //console.log('value is null, no primary filters applied');
    }
  }

  private checkDataSecondaryAppliedFilters() {
    if (this.fetchData('secondaryAppliedFilterData_' + this.componentIdentifier) !== null) {
      this.appliedfilter.secondary = JSON.parse(this.fetchData('secondaryAppliedFilterData_' + this.componentIdentifier));
      this.changeInFilterCriteria.emit(this.appliedfilter);
    } else {
     // console.log('value is null, no secondary filters applied');
    }
  }
}
