import { Component, OnInit, Input, OnChanges, SimpleChange, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { NumberFormatPipe } from '../../pipes/number-format.pipe';
import { TranslateService } from '@ngx-translate/core';
import { FiNumberFormatPipe } from '../../../shared-financial-impact/pipe/fi-number-format.pipe';

@Component({
    selector: 'nalco-horizantal-bar-chart',
    templateUrl: './horizantal-bar-chart.component.html',
    styleUrls: ['./horizantal-bar-chart.component.scss']
})
export class HorizantalBarChartComponent implements OnInit, OnChanges {


    @Input() dataSource?: any;
    @Input() currencySymbol = '$';
    @Input() conversionFactor = 1;
    @Input() cummulativeGraph = false;
    @Input() expandReqiured = false;
    @Input() maxCurrencyValue = 0;
    @Input() maxXaxisDefine?;
    @Input() ResultMaxVal?= 0;
    @Input() isApiDataReceived;
    @Input() savingsTabSelected? : boolean;
    XAxisData = [];
    isLoading = false;
    timer = null;
    xAxis: Array<string> = [];
    xAxisColWidth: string;
    testResults = [];
    expanded = false;
    currencySpecificFormat = '';
    numberForamtPipe: any;
    highestFailuresCount = 0;
    expandmore: boolean;
    expandView = '';
    collapseView = '';
    allCategories = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105];
    showGraph: boolean = false;
    get translateService(){
        return this.translate;
    }
    constructor(private translate: TranslateService) { }

    ngOnInit() {
    }

    ngOnChanges(changes: { [propName: string]: SimpleChange }) {
        // changes for bug 196988
        this.numberForamtPipe = new FiNumberFormatPipe(this.translate);
        this.collapseView = this.translate.instant('COMMON.COLLAPSE_VIEW');
        this.expandView = this.translate.instant('COMMON.EXPAND_VIEW');
        this.showGraph = this.isApiDataReceived === true ? true : false;
        // if (this.timer) {
        //     clearTimeout(this.timer);
        // }
        // // SonarQube Defect: Duplicate conditional structure
        // if ((changes.dateRange !== undefined && !changes.dateRange.firstChange)) {
        //     this.timer = setTimeout(() => {
        //         this.updateTestResults();
        //     }, 500);
        // } else {
        //     this.timer = setTimeout(() => {
        //         this.updateTestResults();
        //     }, 500);
        // }

        const resultFilter = (firstArray, secondArray) => {
            if(secondArray === undefined) {
                secondArray = [];
            }
            return firstArray.filter(x => !secondArray.includes(x));
        };
        const  dataSourceChange = (changes.dataSource !== null &&  changes.dataSource && changes.dataSource.currentValue.length > 0 ) ? resultFilter(changes.dataSource.currentValue, changes.dataSource.previousValue) : null
        if(dataSourceChange!=null){
            if (
                dataSourceChange.length > 0 ||
                changes.currencySymbol.currentValue !== changes.currencySymbol.previousValue ||
                changes.ResultMaxVal.currentValue !== changes.ResultMaxVal.previousValue ||
                changes.maxCurrencyValue.currentValue !== changes.maxCurrencyValue.previousValue ||
                changes.conversionFactor.currentValue !== changes.conversionFactor.previousValue ||
                changes.expandReqiured.currentValue !== changes.expandReqiured.previousValue ||
                changes.cummulativeGraph.currentValue !== changes.cummulativeGraph.previousValue ||
                changes.expandReqiured.currentValue !== changes.expandReqiured.previousValue ||
                changes.maxXaxisDefine.currentValue !== changes.maxXaxisDefine.previousValue
            ) {
                if (this.showGraph) {
                    this.updateTestResults();
                }
            }
        }
        
    }

    updateTestResults() {
        this.processResponseData(this.dataSource);
    }

    processResponseData(data) {
        const processedData = [];
        if (data.length) {
            const dataCount = data.length;
            if (this.cummulativeGraph) {
                let cummulativeYearValue = [];
                let differentGraphColor = [];
                let previousValue = 0;
                let previousGoalValue = null;
                data.forEach(item => {
                    previousValue = previousValue + item.ResultsCount;
                    if (item.goal === null && previousGoalValue === null) {
                        previousGoalValue = null;
                    } else if (item.goal !== null && previousGoalValue === null) {
                        previousGoalValue = item.goal;
                    } else if (item.goal === null && previousGoalValue !== null) {
                        previousGoalValue = previousGoalValue;
                    } else {
                        previousGoalValue = previousGoalValue + item.goal;
                    }
                    differentGraphColor.push({
                        value: item.ResultsCount,
                        color: item.color
                    });
                    let tempArray = [];
                    differentGraphColor.forEach(element => {
                        tempArray.push(element);
                    });
                    cummulativeYearValue.push({
                        ResultsCount: item.ResultsCount,
                        ProjectCount: item.ProjectCount,
                        RecommendationsCount: item.RecommendationsCount,
                        color: item.color,
                        stageType: item.stageType,
                        year: item.year,
                        cummulativeValue: previousValue,
                        differentcolor: tempArray,
                        goal: previousGoalValue
                    });
                });
                this.highestFailuresCount = cummulativeYearValue.reduce((max, n) => Math.max(max, n.cummulativeValue), 0);
                let highestGoal = cummulativeYearValue.reduce((max, n) => Math.max(max, n.goal), 0);
                if (this.highestFailuresCount < highestGoal) {
                    this.highestFailuresCount = highestGoal;
                }
                let currencySpecificFormat = this.numberForamtPipe.transform(this.maxCurrencyValue * this.conversionFactor, true);
                const regex = /(\d+)(?=[^\d]+$)/g;
                let m = regex.exec(currencySpecificFormat);
                if (m !== null) {
                    this.currencySpecificFormat = currencySpecificFormat.substring(m.index + m[0].length);
                } else {
                    this.currencySpecificFormat = '';
                }
                if (this.currencySpecificFormat === this.translate.instant('FINANCIAL_IMPACT.CURRENCY_FORMAT.TRILLION_SHORT')) {
                    this.highestFailuresCount = this.defineXAxisForT(this.maxXaxisDefine);
                } else if (this.currencySpecificFormat === this.translate.instant('FINANCIAL_IMPACT.CURRENCY_FORMAT.MILLION_SHORT')){
                    this.highestFailuresCount = this.defineXAxis(this.maxXaxisDefine);
                }else if(this.currencySpecificFormat === this.translate.instant('FINANCIAL_IMPACT.CURRENCY_FORMAT.BILLION_SHORT')){
                     this.highestFailuresCount = this.defineXAxisForB(this.maxXaxisDefine);
                }else{
                     this.highestFailuresCount = this.defineXAxisForK(this.maxXaxisDefine);
                }
                for (let i = 0; i < dataCount; i++) {
                    processedData.push({
                        stageName: cummulativeYearValue[i].stageType,
                        ResultsCount: cummulativeYearValue[i].ResultsCount,
                        count: cummulativeYearValue[i].ProjectCount,
                        recommendationsCount: cummulativeYearValue[i].RecommendationsCount,
                        color: cummulativeYearValue[i].color,
                        percent: (cummulativeYearValue[i].cummulativeValue * this.conversionFactor / this.highestFailuresCount) * 100,
                        cummulativeItems: cummulativeYearValue[i].cummulativeValue,
                        differentcolor: cummulativeYearValue[i].differentcolor,
                        // eslint-disable-next-line max-len
                        goalPercent: (cummulativeYearValue[i].goal || cummulativeYearValue[i].goal !== null) ? (cummulativeYearValue[i].goal  /  this.highestFailuresCount) * 100 : null

                    });
                }
                if (processedData.length <= 5) {
                    this.expandmore = false;
                } else {
                    this.expandmore = true;
                }
            } else {
                this.highestFailuresCount = data[0].ResultsCount;
                for (let i = 1; i < this.dataSource.length; i++) {
                    if (this.highestFailuresCount < data[i].ResultsCount) {
                        this.highestFailuresCount = data[i].ResultsCount;
                    }
                }
                let currencySpecificFormat1 = this.numberForamtPipe.transform(this.maxCurrencyValue * this.conversionFactor, true);
                const regex = /(\d+)(?=[^\d]+$)/g;
                const m = regex.exec(currencySpecificFormat1);
                if (m !== null) {
                    this.currencySpecificFormat = currencySpecificFormat1.substring(m.index + m[0].length);
                } else {
                    this.currencySpecificFormat = '';
                }
                if(this.currencySpecificFormat === this.translate.instant('FINANCIAL_IMPACT.CURRENCY_FORMAT.TRILLION_SHORT')) {
                    this.highestFailuresCount = this.defineXAxisForT(this.ResultMaxVal);
                } else if(this.currencySpecificFormat === this.translate.instant('FINANCIAL_IMPACT.CURRENCY_FORMAT.MILLION_SHORT')) {
                    this.highestFailuresCount = this.defineXAxis(this.ResultMaxVal);
                }else if(this.currencySpecificFormat === this.translate.instant('FINANCIAL_IMPACT.CURRENCY_FORMAT.BILLION_SHORT')){
                    this.highestFailuresCount = this.defineXAxisForB(this.ResultMaxVal);
                }else {
                    this.highestFailuresCount = this.defineXAxisForK(this.ResultMaxVal);
                    
                }
                for (let i = 0; i < dataCount; i++) {
                    processedData.push({
                        stageName: data[i].stageType,
                        ResultsCount: data[i].ResultsCount,
                        count: data[i].ProjectCount,
                        recommendationsCount: data[i].RecommendationsCount,
                        color: data[i].color,
                        percent: (data[i].ResultsCount * this.conversionFactor / this.highestFailuresCount) * 100,
                        outputvalue: ((Math.round(data[i].ResultsCount) / 1000) * 1000)
                    });
                }
                if (processedData.length <= 5) {
                    this.expandmore = false;
                } else {
                    this.expandmore = true;
                }
            }
            this.testResults = processedData;
        } else {
            this.testResults = [];
        }
    }

    defineXAxis(highestFailuresCount: number): number {
        let maxXAxisVal = 10;
        let interval = 1;
        let currentXIndex = 0;
        let currentCategory = 10;
        const numStr = highestFailuresCount.toString();
        const firstTwoDigits = parseInt(numStr[0] + (typeof numStr[1] !== 'undefined' ? numStr[1] : ''), 10);
        const length = Math.ceil(highestFailuresCount).toString().length;

        for (let i = 0; i < this.allCategories.length; i++) {
            if ((this.allCategories[i] - 2.5) > firstTwoDigits) {
                currentCategory = this.allCategories[i];
                break;
            }
        }
        maxXAxisVal = currentCategory * (length > 2 ? Math.pow(10, length - 2) : 1);
        interval = Math.ceil((maxXAxisVal) / 5);
        let maxXAxisVal2;
        maxXAxisVal2 = interval * 5;
        // eslint-disable-next-line max-len
        maxXAxisVal2 = this.numberForamtPipe.transform(maxXAxisVal2 * this.conversionFactor, true, 'en-US', this.currencySpecificFormat);
        this.xAxis = [];
        const regex = /(\d+)(?=[^\d]+$)/g;
        let m = regex.exec(maxXAxisVal2);
        let numStr1;
        if (m !== null) {
            numStr1 = maxXAxisVal2.substring(0, m.index + m[0].length);
        } else {
            numStr1 = '';
        }
        numStr1 = numStr1.replace(/,/g, '');
        // numStr1 = parseInt(numStr1, 10);
        maxXAxisVal2 = numStr1 * 1000000;
        while (currentXIndex + interval <= maxXAxisVal) {
            currentXIndex += interval;
            // eslint-disable-next-line max-len
            let val = this.numberForamtPipe.transform(currentXIndex * this.conversionFactor, true, 'en-US', this.currencySpecificFormat, true);
            this.xAxis.push(val);
        }
        if (this.dataSource.length > 5) {
            this.xAxisColWidth = (98 / this.xAxis.length).toFixed(2);
        } else {
            this.xAxisColWidth = (100 / this.xAxis.length).toFixed(2);
        }
        return maxXAxisVal2;
    }
    defineXAxisForB(highestFailuresCount: number): number {
        let maxXAxisVal = 10;
        let interval = 1;
        let currentXIndex = 0;
        let currentCategory = 10;
        const numStr = highestFailuresCount.toString();
        const firstTwoDigits = parseInt(numStr[0] + (typeof numStr[1] !== 'undefined' ? numStr[1] : ''), 10);
        const length = Math.ceil(highestFailuresCount).toString().length;

        for (let i = 0; i < this.allCategories.length; i++) {
            if ((this.allCategories[i] - 2.5) > firstTwoDigits) {
                currentCategory = this.allCategories[i];
                break;
            }
        }
        maxXAxisVal = currentCategory * (length > 2 ? Math.pow(10, length - 2) : 1);
        interval = Math.ceil((maxXAxisVal) / 5);
        let maxXAxisVal2;
        maxXAxisVal2 = interval * 5;
        // eslint-disable-next-line max-len
        maxXAxisVal2 = this.numberForamtPipe.transform(maxXAxisVal2 * this.conversionFactor, true, 'en-US', this.currencySpecificFormat);
        this.xAxis = [];
        const regex = /(\d+)(?=[^\d]+$)/g;
        let m = regex.exec(maxXAxisVal2);
        let numStr1;
        if (m !== null) {
            numStr1 = maxXAxisVal2.substring(0, m.index + m[0].length);
        } else {
            numStr1 = '';
        }
        numStr1 = numStr1.replace(/,/g, '');
        // numStr1 = parseInt(numStr1, 10);
        maxXAxisVal2 = numStr1 * 1000000000;
        while (currentXIndex + interval <= maxXAxisVal) {
            currentXIndex += interval;
            // eslint-disable-next-line max-len
            let val = this.numberForamtPipe.transform(currentXIndex * this.conversionFactor, true, 'en-US', this.currencySpecificFormat, true);
            this.xAxis.push(val);
        }
        if (this.dataSource.length > 5) {
            this.xAxisColWidth = (98 / this.xAxis.length).toFixed(2);
        } else {
            this.xAxisColWidth = (100 / this.xAxis.length).toFixed(2);
        }
        return maxXAxisVal2;
    }
    defineXAxisForT(highestFailuresCount: number): number {
        let maxXAxisVal = 10;
        let interval = 1;
        let currentXIndex = 0;
        let currentCategory = 10;
        const numStr = highestFailuresCount.toString();
        const firstTwoDigits = parseInt(numStr[0] + (typeof numStr[1] !== 'undefined' ? numStr[1] : ''), 10);
        const length = Math.ceil(highestFailuresCount).toString().length;

        for (let i = 0; i < this.allCategories.length; i++) {
            if ((this.allCategories[i] - 2.5) > firstTwoDigits) {
                currentCategory = this.allCategories[i];
                break;
            }
        }
        maxXAxisVal = currentCategory * (length > 2 ? Math.pow(10, length - 2) : 1);
        interval = Math.ceil((maxXAxisVal) / 5);
        let maxXAxisVal2;
        maxXAxisVal2 = interval * 5;
        // eslint-disable-next-line max-len
        maxXAxisVal2 = this.numberForamtPipe.transform(maxXAxisVal2 * this.conversionFactor, true, 'en-US', this.currencySpecificFormat);
        this.xAxis = [];
        const regex = /(\d+)(?=[^\d]+$)/g;
        let m = regex.exec(maxXAxisVal2);
        let numStr1;
        if (m !== null) {
            numStr1 = maxXAxisVal2.substring(0, m.index + m[0].length);
        } else {
            numStr1 = '';
        }
        numStr1 = numStr1.replace(/,/g, '');
        // numStr1 = parseInt(numStr1, 10);
        maxXAxisVal2 = numStr1 * 1000000000000;
        while (currentXIndex + interval <= maxXAxisVal) {
            currentXIndex += interval;
            // eslint-disable-next-line max-len
            let val = this.numberForamtPipe.transform(currentXIndex * this.conversionFactor, true, 'en-US', this.currencySpecificFormat, true);
            this.xAxis.push(val);
        }
        if (this.dataSource.length > 5) {
            this.xAxisColWidth = (98 / this.xAxis.length).toFixed(2);
        } else {
            this.xAxisColWidth = (100 / this.xAxis.length).toFixed(2);
        }
        return maxXAxisVal2;
    }

    defineXAxisForK(highestFailuresCount: number): number {
        let maxXAxisVal = 10;
        let interval = 1;
        let currentXIndex = 0;
        let currentCategory = 10;
        // eslint-disable-next-line max-len
        let tempNumStr = this.numberForamtPipe.transform(highestFailuresCount * this.conversionFactor, true, 'en-US', this.currencySpecificFormat);
        const regex = /(\d+)(?=[^\d]+$)/g;
        let m = regex.exec(tempNumStr);
        let numStr;
        if (m !== null) {
            numStr = tempNumStr.substring(0, m.index + m[0].length);
        } else {
            numStr = '';
        }
        numStr = numStr.replace(/,/g, '');
        const firstTwoDigits = parseInt(numStr[0] + (typeof numStr[1] !== 'undefined' ? numStr[1] : ''), 10);
        const length = Math.ceil(numStr).toString().length;
        for (let i = 0; i < this.allCategories.length; i++) {
            if ((this.allCategories[i] - 2.5) > firstTwoDigits) {
                currentCategory = this.allCategories[i];
                break;
            }
        }
        maxXAxisVal = currentCategory * (length > 2 ? Math.pow(10, length - 2) : 1);
        interval = Math.ceil((maxXAxisVal) / 5);
        let maxXAxisVal2;
        maxXAxisVal2 = interval * 5;
        this.xAxis = [];
        if (this.currencySpecificFormat !== '') {
            maxXAxisVal2 = maxXAxisVal2 * 1000;
        }

        while (currentXIndex + interval <= maxXAxisVal) {
            currentXIndex += interval;
            const value = currentXIndex.toString() + this.currencySpecificFormat;
            this.xAxis.push(value);
        }
        if (this.dataSource.length > 5) {
            this.xAxisColWidth = (98 / this.xAxis.length).toFixed(2);
        } else {
            this.xAxisColWidth = (100 / this.xAxis.length).toFixed(2);
        }
        return maxXAxisVal2;
    }

    expandGraph() {
        this.expanded = !this.expanded;
    }
    onIncrease(item) {
        if (item.toString().length > 2) {
            return "count-increase";
        }
        else {
            return "project-count";
        }
    }

    getProjectsLabelTranslationKey(itemCount): string {
        if (itemCount == 0) {
            return 'COMMON.PROJECT.ZERO';
        } else if (itemCount == 1) {
            return 'COMMON.PROJECT.ONE';
        } else {
            return 'COMMON.PROJECT.OTHER';
        }
    }
    getActionsLabelTranslationKey(itemCount): string {
        if (itemCount == 0) {
            return 'COMMON.ACTION.ZERO';
        } else if (itemCount == 1) {
            return 'COMMON.ACTION.ONE';
        } else {
            return 'COMMON.ACTION.OTHER';
        }
    }
}
