<div class="modal-header">
    <h3 class="modal-title">{{modalText}}</h3>
    <button type="button" class="close" aria-label="Close" style="color: white;" (click)="dimissModal()">
        <span class="ico-exit-round-edge"></span>
    </button>
</div>
<div class="outerbody">
    <form [formGroup]="chargeBalanceForm">
        <div class="modal-body">
            <div class="row">
                <label class="label-class col-5" translate>RO_OPTIMIZER.CHARGE_BALANCE.TOTAL_CATIONS </label>
                <input class="col-4 mt-1 mb-1 disabled" type="text" formControlName="totalCations">
                <span class="span-class" translate>RO_OPTIMIZER.CHARGE_BALANCE.UNIT </span>
            </div>
            <div class="row">
                <label class="label-class col-5" translate>RO_OPTIMIZER.CHARGE_BALANCE.TOTAL_ANIONS </label>
                <input class="col-4 mt-1 mb-1 disabled" type="text" formControlName="totalAnions">
                <span class="span-class" translate>RO_OPTIMIZER.CHARGE_BALANCE.UNIT </span>
            </div>
            <div class="row">
                <label class="label-class col-5" translate>RO_OPTIMIZER.CHARGE_BALANCE.DIFFERENCE </label>
                <input class="col-4 mt-1 mb-1 disabled" type="text" formControlName="difference">
                <span class="span-class" translate>RO_OPTIMIZER.CHARGE_BALANCE.UNIT </span>
            </div>
            <div class="row selectRow">
                <label class="label-class col-5" translate>RO_OPTIMIZER.CHARGE_BALANCE.BALANCE_WITH </label>
                <select class="dropDown col-4 p-2" formControlName="balance" (change)="onChangeBalance()">
                    <option *ngFor="let dropDown of result.IonBalanceTypes" [value]="dropDown.Name">{{dropDown.Name}}
                    </option>
                </select>
            </div>
            <div class="row">
                <label class="label-class col-5" translate>RO_OPTIMIZER.CHARGE_BALANCE.BALANCE_WITH </label>
                <input class="col-4 mt-1 mb-1 disabled" type="text" formControlName="balanceValue">
                <span class="span-class" translate>RO_OPTIMIZER.CHARGE_BALANCE.UNIT </span>
            </div>
        </div>
    </form>
</div>

<div class="modal-footer">
    <div>
        <button type="button" class="btn btn-secondary" (click)="dimissModal()" translate>COMMON.CANCEL</button>
        <button type="button" class="btn btn-primary" (click)="submitChargeModal()" translate>COMMON.OK</button>
    </div>
</div>