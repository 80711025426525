import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { SystemAssuranceAdminServiceConfig } from './system-assurance-admin.service.config';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { AppEnvConfig } from '../../app.env.config';
import { IContextPointServiceKpi } from '../../shared/contracts/IContextPointServiceKpi';
import { Subject } from 'rxjs';

@Injectable()
export class SystemAssuranceAdminService extends BehaviorSubject<GridDataResult> {
  private configEnv: any;
  url: string;
  constructor(
    private systemAssuranceAdminServiceConfig: SystemAssuranceAdminServiceConfig,
    private httpClient: HttpClient,
    private config: AppEnvConfig) {
    super(null);
    this.configEnv = this.systemAssuranceAdminServiceConfig.getEnvironment();
    this.configEnv.baseUrl = config.getEnv('jsonServerUrl');
    this.configEnv.apiServerUrl = config.getEnv('apiServerUrl');
  }

  private leaveSite = new Subject<boolean>();
  $leaveSite = this.leaveSite.asObservable();

  setleaveSiteStatus(leaveSite: boolean) {
    this.leaveSite.next(leaveSite);
  }

  public saveContextPointKpiMapData(contextPointServiceKpiData: IContextPointServiceKpi): Observable<any> {
    const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.saveContextPointKpiMapData}`;
    return this.httpClient.post(url, contextPointServiceKpiData).map(response => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }
}
