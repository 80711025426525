/* eslint-disable @typescript-eslint/consistent-type-imports */
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { Component, Input, forwardRef, Output, EventEmitter } from '@angular/core';
import { IRadioButton } from './contracts/radio-button.model';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'nalco-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RadioButtonComponent),
    multi: true
  }]
})


export class RadioButtonComponent implements ControlValueAccessor {
  @Input() radioButton: IRadioButton;
  @Output() radioButtonClick = new EventEmitter();
  radioButtonValue: IRadioButton[]; // this is set as any because it is depend on the input we are getting
  onRadioButtonChnage = (_value: IRadioButton[]): void => { }
  onRadioButtonTouched = () => { }

  constructor() { }

  /**
* @description function is used write value using control value processor
* @param obj
*/

  writeValue(obj: any): void {
    this.radioButtonValue = obj;
  }

  /**
* @description function is used get the function and save it
* @param fn
*/

  registerOnChange(fn: any): void {
    this.onRadioButtonChnage = fn;
  }

  /**
* @description function is used get the register the function
* @param fn
*/

  registerOnTouched(fn: any): void {
    this.onRadioButtonTouched = fn;
  }

  /**
* @description function is used to analysis the toggle change and capture the event and emit it to parent component
*/

  toggleChange($event: Event): void {
    const eventTarget = $event.target as HTMLInputElement;
    const id = eventTarget.id;
    this.radioButtonValue = this.getIdValue(this.radioButtonValue, id)
    this.onRadioButtonChnage(this.radioButtonValue);
    this.onRadioButtonTouched();
    this.radioButtonClick.emit(this.radioButtonValue);

  }

  /**
* @description function is used to get the filtered and modifed data
* @param value
* @param id
* @returns IRadiobutton[]
*/


  getIdValue(value: IRadioButton[], id: string): IRadioButton[] {
    return value.map((item) => {
      if (item.id === id) {
        return { ...item, checked: true };
      } else {
        return { ...item, checked: false };
      }
      return item;
    });
  }

}




