import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
//import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv'
import { drawDOM, exportImage, DrawOptions, Group, exportPDF, pdf, drawing, Surface } from '@progress/kendo-drawing';
import { saveAs } from '@progress/kendo-file-saver';
import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import * as domToImage from 'dom-to-image';
import { PDFModule } from '@progress/kendo-angular-grid';
import { defineFont } from '@progress/kendo-drawing/pdf';
import * as moment from 'moment';
import { LocalCacheService } from '../../../core/local-cache/local-cache.service';
import { TranslateService } from '@ngx-translate/core';
import { ResponsiveService } from '../../services/reponsive.service';
import { VULNERABLE_CHAR } from '../../../../app/app.config';
import { DateTimePipe } from '../../pipes/date-time-format.pipe';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { IAppliedFilter, IChild } from '../../../shared-financial-impact/_contracts/IFIAppliedFilterDetails';
import { StartEndDate } from '../../models/StartEndDate';
import { DurationType } from '../../_enum/constants';
import { IQuickFiltersData, IQuickFiltersText } from '../../../shared-ssd/_contracts/IQuickFiltersInfo';
//declare var domtoimage:any;'../../../../scripts/dom-to-image'
const domtoimage = require('../../../../scripts/dom-to-image');

@Component({
  selector: 'nalco-more-option',
  templateUrl: './more-option.component.html'
})
export class MoreOptionComponent implements OnInit {
  @Input() uid: string; // To get the component ID
  @Input() dataSource: any; // To get the data of the component
  @Input() useGraphExport?= false; // Defines the requirement of Graph export
  @Input() fileName: string; // To get the fileName of the component
  @Input() showExportAsPDF = false;
  @Input() showExportAsPrintChart = false;
  @Input() showAddToReport = true;
  @Input() showExportAsImage = true;
  @Input() showExportAsCsv = false;
  @Input() showExportAllAsCsv = false;
  @Input() showAuditDetails = false;
  @Input() showMaximize?= false;
  @Input() currentDays?: number;
  @Input() epxortWithInfo?= false;
  @Input() epxortWithInfoDetails?= false;
  @Input() imageTitle?: string;
  @Input() companyName?: string;
  @Input() exportDateText?: string;
  @Input() pageLevelFilterText?: string;
  @Input() timeRangeText?: string;
  @Input() divisionByText?: string;
  @Input() titleText?: string;
  @Input() isTimeRange?= true;
  @Input() exportedDateFormat?: string;
  @Input() isTCOPipeline?= true;
  @Input() isExportToImageSSD ?= true;
  @Input() selectedFIDuration?: any;
  @Input() selectedDivisions?: any;
  @Input() selectedDVDuration?: any;
  @Input() chartTitle?: string;
  @Input() siteName?: string;
  @Input() isDataViz = false;
  @Input() isWFI = false;
  @Input() WFIPrintDetials;
  @Input() selectedWFIDuration = '';
  @Input() showAccountDetails = false;
  @Input() disableMoreOption = false;
  @Input() showDownloadConfig= false;
  @Input() showSystemHealth= false;
  @Input() showGatewayStatistics= false;
  @Input() showDataDownload= false;
  @Input() showHardwareDetails = false;
  @Input() showAlarmNotification = false;
  @Input() showAlarmList = false;
  @Input() isFI = false;
  @Input() filterDetails:IAppliedFilter;
  @Input() monthlyQuarterly: {durationType: string, value: number};
  @Input() dateRangeObject: StartEndDate;
  @Input() dvDurationData;
  @Input() customDatetype?:boolean = false;
  @Input() quickFiltersText?: IQuickFiltersText;
  @Input() quickFilterData?: IQuickFiltersData

  @Output() exportAsCsvClick: EventEmitter<any> = new EventEmitter();
  @Output() exportAllAsCsvClick: EventEmitter<any> = new EventEmitter();
  @Output() exportAsPrintChartClick: EventEmitter<any> = new EventEmitter();
  @Output() exportAsAuditClick: EventEmitter<any> = new EventEmitter();
  @Output() maximizeClick: EventEmitter<any> = new EventEmitter();
  @Output() moreOptionPrintWidgetClicked: EventEmitter<boolean> = new EventEmitter();
  @Output() exportAsAccountClick :EventEmitter<any>=new EventEmitter();
  @Output() downloadConfigurationClick :EventEmitter<any>=new EventEmitter();
  @Output() systemHealthClick :EventEmitter<any>=new EventEmitter();
  @Output() gatewayStatisticsClick :EventEmitter<any>=new EventEmitter();
  @Output() dataDownloadClick :EventEmitter<any>=new EventEmitter();
  @Output() hardwareDetailsClick :EventEmitter<any>=new EventEmitter();
  @Output() alarmNotificationClick : EventEmitter<any> = new EventEmitter();
  @Output() alarmListClick: EventEmitter<any> = new EventEmitter();


  private options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    showTitle: true,
    useBom: false
  };

  htmlData = '';
  angularFileName = '';
  formatedDate = '';
  exportData: any;
  csvoptions: any;


  constructor(private dropdownConfig: NgbDropdownConfig,private localCacheService: LocalCacheService, private translate: TranslateService,
    private responsive: ResponsiveService,private dateFormat: DateTimePipe) {
      dropdownConfig.placement = 'bottom-right';

  }


  ngOnInit() {
    const d = new Date();
    if (this.exportedDateFormat === 'yearMonthDate') {
      this.formatedDate = moment(d).format('YYYY/MM/DD');
    } else {
      this.formatedDate = moment(d).format('MM/DD/YYYY');
    }
    this.formatedDate = this.dateFormat.transform(this.formatedDate, true, false);
    this.responsive.updateUnsupportedMessage(false);
  }

  public handleExportAsCsvClick() {
    this.exportAsCsvClick.emit();
  }

  public handleExportAllAsCsvClick() {
    this.exportAllAsCsvClick.emit();
  }

  public handleExportAsAuditClick() {
    this.exportAsAuditClick.emit();
  }

  public handleExportAsAccountClick() {
    this.exportAsAccountClick.emit();
  }
  public handleDownloadConfigurationClick() {
    this.downloadConfigurationClick.emit();
  }
  public handleSystemHealthClick() {
    this.systemHealthClick.emit();
  }
  public handleGatewayStatisticsClick() {
    this.gatewayStatisticsClick.emit();
  }
  public handleMaximizeClick() {
    this.maximizeClick.emit();
  }
  public handleDataDownloadClick() {
    this.dataDownloadClick.emit();
  }
  public handleHardwareDetailsClick() {
    this.hardwareDetailsClick.emit();
  }
  public handleAlarmNotificationClick() {
    this.alarmNotificationClick.emit();
  }

  public handleAlarmListClick() {
    this.alarmListClick.emit();
  }

  // will be called on event
  public ExportAsCSV(data: any) {
    const exportData = this.removeVulnerables(data);
    this.angularFileName = this.generateFileName();
    this.exportData = exportData;
    this.csvoptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: false,
      headers: Object.keys(exportData[0]),
      showTitle: true,
      removeNewLines: true,
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    // new Angular2Csv(exportData, filename, { headers: Object.keys(exportData[0]) });
    new AngularCsv(exportData, this.fileName, { headers: Object.keys(exportData[0]) });
  }

  // will be called on event
  public ExportAsCSVWater(data: any, filename: string, option: any) {
    const exportData = this.removeVulnerables(data);
    this.angularFileName = filename;
    this.exportData = exportData;
    this.csvoptions = option;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    // new Angular2Csv(exportData, filename, option);
    new AngularCsv(exportData, filename, option);
  }

  public removeVulnerables(exportData: any) {
    for (const propertyKey in exportData) {
      if (exportData.hasOwnProperty(propertyKey)) {
        for (const i in exportData[propertyKey]) {
          if (typeof (exportData[propertyKey][i]) === "string") {
            if (VULNERABLE_CHAR.find(it => it === (((exportData[propertyKey][i])).substr(0, 1)))) {
              exportData[propertyKey][i] = "'" + (exportData[propertyKey][i]);
            }
          }
        }
      }
    }
    return exportData;
  }

  // for dom operation temporarily add or remove something
  public exportImgDomOperation(remove:boolean, title:any, className:string){
    remove? title.classList.remove(className): title.classList.add(className);
  }

  public exportAsImage(myDrop: any) {
    myDrop.close();
    if (this.responsive.isIos || this.isSafari() || this.isIE()) {
      this.responsive.updateUnsupportedMessage(true);
    } else {
      if (this.useGraphExport) {
        if (this.epxortWithInfo) {
          this.exportAsImagePDFWithInfo('image');
        } else {
          this.exportAsImagePDF('image');
        }
      } else {
        if (this.epxortWithInfo) {
          this.exportElementWithInfo(document.getElementById(this.uid));
        } else if (this.epxortWithInfoDetails) {
          this.exportElementWithInfoDetails(document.getElementById(this.uid));
        } else {
          this.exportElement(document.getElementById(this.uid));

        }
      }
    }
  }

  public exportAsImagePDFWithInfo(type: string) {
    let title = document.getElementById(this.uid);
    title.getElementsByClassName('show')[1].classList.remove('show');
    if(title.getElementsByClassName('ico-info-thin').length){
      this.exportImgDomOperation(false, title.getElementsByClassName('ico-info-thin')[0], 'd-none');
    }
    if(title.getElementsByClassName('currency-setting-btn').length){
      title.getElementsByClassName('currency-setting-btn')[0].classList.add('currency-setting-position');
    }
    if ( title.getElementsByClassName('value-categories').length) {
      this.exportImgDomOperation(false, title.getElementsByClassName('value-categories')[0], 'viewMode')
    }
    if ( title.getElementsByClassName('time-range-filter-button').length) {
      this.exportImgDomOperation(false, title.getElementsByClassName('time-range-filter-button')[0], 'd-none')
    }
    if ( title.getElementsByClassName('filter-button').length) {
      this.exportImgDomOperation(false, title.getElementsByClassName('filter-button')[0], 'd-none')
    }
    if ( title.getElementsByClassName('dd-time-range-filter').length ) {
      this.exportImgDomOperation(false, title.getElementsByClassName('dd-time-range-filter')[0], 'd-none')
    }
    if ( title.getElementsByClassName('filter-menu-wrapper').length ) {
      this.exportImgDomOperation(false, title.getElementsByClassName('filter-menu-wrapper')[0], 'd-none')
    }
    if ( title.getElementsByClassName('btn-pill').length ) {
      let appliedFilterPills = title.getElementsByClassName('btn-pill');
      for (let i=0; i<appliedFilterPills.length;i++) {
        (appliedFilterPills[i] as HTMLElement).style.display = 'none';
      }
    } 
    title.getElementsByClassName('ico-more')[0].classList.add('d-none');
    for (let i = 0; i < title.getElementsByClassName('highcharts-background').length; i++) {
      let fill = title.getElementsByClassName('highcharts-background')[i].getAttribute('fill');
      if (fill !== null && fill.startsWith('url')) {
        title.getElementsByClassName('highcharts-background')[i].setAttribute('fill',
          fill.replace(window.location.origin + '/', ''));
      }
    }

    for (let i = 0; i < title.getElementsByClassName('highcharts-point').length; i++) {
      let fill = title.getElementsByClassName('highcharts-point')[i].getAttribute('fill');
      if (fill !== null && fill.startsWith('url')) {
        title.getElementsByClassName('highcharts-point')[i].setAttribute('fill',
          fill.replace(window.location.origin + '/', ''));
      }
    }

    if (type === "image") {
      const d = new Date();
      let date = ' ';
      if (this.exportedDateFormat === 'yearMonthDate') {
        date = moment(d).format('YYYY/MM/DD');
      } else {
        date = moment(d).format('MM/DD/YYYY');
      }
      date = this.dateFormat.transform(date, true, false);
      let cachevalue = [];
      if (title.getElementsByClassName('tile-content-wrapper')[0] as HTMLElement) {
        cachevalue = [(title.getElementsByClassName('tile-content-wrapper')[0] as HTMLElement).style.maxHeight,
        (title.getElementsByClassName('tile-content-wrapper')[0] as HTMLElement).style.overflow];
        (title.getElementsByClassName('tile-content-wrapper')[0] as HTMLElement).style.maxHeight = 'none';
        (title.getElementsByClassName('tile-content-wrapper')[0] as HTMLElement).style.overflow = 'hidden';
      }

      if (title.getElementsByClassName('icon-calendar-icon')) {
        let calendarElementList = title.getElementsByClassName('icon-calendar-icon');
        for (let i=0; i<calendarElementList.length;i++) {
            (calendarElementList[i] as HTMLElement).style.display = 'none';
        }
      }

      const boxHeight = title.clientHeight + 150 + 'px';
      title.style.height = boxHeight;

      if (this.isTimeRange === true) {
        let durationString = "";
        if(this.monthlyQuarterly){
          durationString = '<p> &nbsp; ' + this.timeRangeText + ' : ' + this.translate.instant("WATER_SAFETY.LAST") + ' ';
          let quartersOrMonths = this.monthlyQuarterly.value + ' ' + (this.monthlyQuarterly.durationType === 'monthly'? this.translate.instant("COMMON.MONTHS"): this.translate.instant("COMMON.QUARTERS"))+ '  </p>';
          durationString = durationString + quartersOrMonths;
        }else{
          if(this.dateRangeObject && this.dateRangeObject.dateRange === DurationType.CustomDate.toString()){
            durationString = '<div style="margin-left: 7px"><p>' + this.timeRangeText + ' : ' + this.translate.instant("COMMON.SORT_OPTIONS.CUSTOM")
                              + ' <span style="white-space: nowrap;">(' + this.dateFormat.transform(moment(this.dateRangeObject.startDate).format("MM/DD/YYYY"), true, false) + ' - ' +
                              this.dateFormat.transform(moment(this.dateRangeObject.endDate).format("MM/DD/YYYY"), true, false) + ')</span>  </p> </div>';
          }else
          if(this.customDatetype){
            durationString = '<p> &nbsp; ' + this.timeRangeText + ' : ' + this.translate.instant("SS_DASHBOARD.CUSTOM") + ' '
                               + '  </p>';
          }
          else {
            durationString = '<p> &nbsp; ' + this.timeRangeText + ' : ' + this.translate.instant("WATER_SAFETY.LAST") + ' '
                              + this.currentDays + ' ' + this.translate.instant("COMMON.DAYS") + '  </p>';
          }
        }
        this.htmlData = '<div class="bg-white more-info" id="moreInfo">'
          + '<p> &nbsp; </p>'
          + '<p> &nbsp; <b>' + this.exportDateText + ' : </b> ' + date + '</p>'
          + '<p> &nbsp; <b>' + this.pageLevelFilterText + ' </b> </p>'
          + '<p> &nbsp;&nbsp;' + this.getSiteFilterDetails() + '</p>'
          + durationString
          + '<p> &nbsp; <b>' + this.titleText + '</b> : ' + this.imageTitle + '</p>'
          + '</div>';
      } else {
        if (this.isTCOPipeline === true) {
          this.htmlData = '<div class="bg-white more-info" id="moreInfo">'
            + '<p> &nbsp; </p>'
            + '<p> &nbsp; <b>' + this.exportDateText + ' : </b> ' + date + '</p>'
            + '<p> &nbsp; <b>' + this.pageLevelFilterText + ' </b> </p>'
            + '<p> &nbsp;&nbsp;' + this.getSiteFilterDetails() + '</p>'
            + '<p> &nbsp; <b>' + this.titleText + '</b> : ' + this.imageTitle + '</p>'
            + '</div>';
        } else if (this.isDataViz) {
          if(DurationType.CustomDate.toString() === this.dvDurationData.timerange){
            const endTime = this.selectedDVDuration.match(/\d{1,2}\:\d{1,2} [AMP]*/g)[1];
            const starttime = this.selectedDVDuration.match(/\d{1,2}\:\d{1,2} [AMP]*/g)[0];
            this.selectedDVDuration = 'From ' + this.dateFormat.transform(moment(this.dvDurationData.startdate).format('YYYY/MM/DD'), true, false) +
                ' at ' + starttime + ' to ' + this.dateFormat.transform(moment(this.dvDurationData.enddate).format('YYYY/MM/DD'), true, false) + ' at ' + endTime;
          }
          this.htmlData = '<div class="bg-white more-info" id="moreInfo">'
            + '<p> &nbsp; </p>'
            + '<p> &nbsp; <b>' + this.exportDateText + ' : </b> ' + date + '</p>'
            + '<p> &nbsp; <b>' + this.titleText + ' : ' + this.chartTitle + '</b></p>'
            + '<p> &nbsp; <b>' + this.timeRangeText + ' : </b>' + this.selectedDVDuration + '</p>'
            + '</div>';
        } if (this.isWFI) {
          this.htmlData = '<div class="bg-white more-info" id="moreInfo">'
            + '<p> &nbsp; </p>'
            + '<p> &nbsp; <b>' + this.exportDateText + ' : </b> ' + date + '</p>'
            + '<p> &nbsp; <b> Site Name : </b> ' + this.WFIPrintDetials.site + '</p>'
            + '<p> &nbsp; <b> Asset Name : </b> ' + this.WFIPrintDetials.asset + '</p>'
            + '<p> &nbsp; <b> Port Name : </b> ' + this.WFIPrintDetials.port + '</p>'
            + '<p> &nbsp; <b>' + this.timeRangeText + ' : </b>' + this.selectedWFIDuration + '</p>'
            + '</div>';
        } if(this.isExportToImageSSD) {
          this.htmlData = '<div class="bg-white more-info" id="moreInfo">'
          + '<p> &nbsp; </p>'
          + '<p> &nbsp; <b>' + this.exportDateText + ' : </b> ' + date + '</p>'
          + '<p> &nbsp; <b>' + this.pageLevelFilterText + ' </b> </p>'
          + '<p> &nbsp;&nbsp;<b>' + this.getSiteFilterDetails() + '</p>'
          // need to remove for new value category.... no more needed
          // + '<p> &nbsp; <b>' + this.divisionByText + '</b> : ' + this.selectedDivisions + '</p>'
          // need to uncomment for new value category 
          + '<p> &nbsp; <b>' + this.quickFiltersText.DivisionByText + ' : </b> ' + this.quickFilterData.DivisionFilter + '</p>'
          + '<p> &nbsp; <b>' + this.quickFiltersText.ValueChannelText + ' : </b> ' + this.quickFilterData.ValueChannelFilter + '</p>'
          + '<p> &nbsp; <b>' + this.quickFiltersText.CurrencyText + ' : </b> ' + this.quickFilterData.CurrencyFilter + '</p>'
          + '<p> &nbsp; <b>' + this.quickFiltersText.UnitSymbolText + ' : </b> ' + this.quickFilterData.UOM + '</p>'
          + '<p> &nbsp; <b>' + this.quickFiltersText.TimeRangeText + ' : </b>' + this.quickFilterData.TimeRangeFilter + '</p>'
          // + '<p> &nbsp; ' + this.timeRangeText + ' : ' + this.selectedFIDuration + '</p>'
          // + '<p> &nbsp; <b>' + this.divisionByText + '</b> : ' + this.selectedDivisions + '</p>'
          // + '<p> &nbsp; <b>' + this.titleText + '</b> : ' + this.imageTitle + '</p>'
          + '</div>';
        }
        else {
          this.htmlData = '<div class="bg-white more-info" id="moreInfo">'
            + '<p> &nbsp; </p>'
            + '<p> &nbsp; <b>' + this.exportDateText + ' : </b> ' + date + '</p>'
            + '<p> &nbsp; <b>' + this.pageLevelFilterText + ' </b> </p>'
            + '<p> &nbsp;&nbsp;' + this.getSiteFilterDetails() + '</p>'
            + '<p> &nbsp; ' + this.timeRangeText + ' : ' + this.selectedFIDuration + '</p>'
            + '<p> &nbsp; <b>' + this.titleText + '</b> : ' + this.imageTitle + '</p>'
            + '</div>';
        }

      }
      title.insertAdjacentHTML('afterbegin', this.htmlData);
      title.style.backgroundColor = "#fff";

      drawDOM(title).then((group: Group) => {
        return exportImage(group);
      }).then((dataUri) => {
        // document.getElementById('moreInfo').outerHTML = ' ';
      });
      const filters = this.getCorporatNameOrEvocLastNode();
      const ImageTitle = this.imageTitle;
      let imageFileName = '';
      if (this.isDataViz) {
        imageFileName = ImageTitle + '_' + date + '.png';
      } else if (this.siteName) {
        imageFileName = this.translate.instant('QUALITY.SERVICE_NAME_LINK') + '_' + this.siteName + '_' + this.uid + '_' + this.formatedDate + '.png'
      }
      else {
        if (document.URL.indexOf('quality') > 0) {
          imageFileName = this.translate.instant('QUALITY.SERVICE_NAME_LINK') + '_' + ImageTitle + '_' + filters + '_' + date + '.png';
        }
        else {
          imageFileName = ImageTitle + '_' + filters + '_' + date + '.png';
        }
      }

      domToImage.toPng(title)
        .then(function (dataUrl) {
          saveAs(dataUrl, imageFileName);
          if (title.getElementsByClassName('tile-content-wrapper')[0] as HTMLElement) {
            (title.getElementsByClassName('tile-content-wrapper')[0] as HTMLElement).style.maxHeight = cachevalue[0].toString();
            (title.getElementsByClassName('tile-content-wrapper')[0] as HTMLElement).style.overflow = cachevalue[1].toString();
          }
          if (title.getElementsByClassName('icon-calendar-icon')) {
            let calendarElementList = title.getElementsByClassName('icon-calendar-icon');
            for (let i=0; i<calendarElementList.length;i++) {
                (calendarElementList[i] as HTMLElement).style.display = '';
            }
          }
          if(title.getElementsByClassName('ico-info-thin').length){
            title.getElementsByClassName('ico-info-thin')[0].classList.remove('d-none');
          }
          if(title.getElementsByClassName('currency-setting-btn').length){
            title.getElementsByClassName('currency-setting-btn')[0].classList.remove('currency-setting-position');
          }
          if(title.getElementsByClassName('ico-more').length){
            title.getElementsByClassName('ico-more')[0].classList.remove('d-none');
          }
          if ( title.getElementsByClassName('value-categories').length) {
            title.getElementsByClassName('value-categories')[0].classList.remove('viewMode')
          }
          if ( title.getElementsByClassName('time-range-filter-button').length) {
            title.getElementsByClassName('time-range-filter-button')[0].classList.remove('d-none')
          }
          if ( title.getElementsByClassName('filter-button').length) {
            title.getElementsByClassName('filter-button')[0].classList.remove('d-none')
          }
          if ( title.getElementsByClassName('dd-time-range-filter').length) {
            title.getElementsByClassName('dd-time-range-filter')[0].classList.remove('d-none')
          }
          if ( title.getElementsByClassName('filter-menu-wrapper').length) {
            title.getElementsByClassName('filter-menu-wrapper')[0].classList.remove('d-none')
          }
          if (title.getElementsByClassName('btn-pill')) {
            let appliedFilterPills = title.getElementsByClassName('btn-pill');
            for (let i=0; i<appliedFilterPills.length;i++) {
                (appliedFilterPills[i] as HTMLElement).style.display = '';
            }
          }
          title.style.height = 'auto';
          document.getElementById('moreInfo').outerHTML = ' ';
        })
        .catch(function (error) {
        });
    } else if (type === "pdf") {
      domToImage.toBlob(title)
        .then(function (dataUrl) {
          saveAs(dataUrl, title.id + '.pdf');
        })
        .catch(function (error) {
        });
    }
  }

  public exportAsImagePDF(type: string) {
    const title = document.getElementById(this.uid);
    title.getElementsByClassName('show')[1].classList.remove('show');
    for (let i = 0; i < title.getElementsByClassName('highcharts-background').length; i++) {
      const fill = title.getElementsByClassName('highcharts-background')[i].getAttribute('fill');
      if (fill !== null && fill.startsWith('url')) {
        title.getElementsByClassName('highcharts-background')[i].setAttribute('fill',
          fill.replace(window.location.origin + '/', ''));
      }

    }

    for (let i = 0; i < title.getElementsByClassName('highcharts-point').length; i++) {
      const fill = title.getElementsByClassName('highcharts-point')[i].getAttribute('fill');
      if (fill !== null && fill.startsWith('url')) {
        title.getElementsByClassName('highcharts-point')[i].setAttribute('fill',
          fill.replace(window.location.origin + '/', ''));
      }

    }
    for (let i = 0; i < title.getElementsByClassName('carousel-control-prev').length; i++) {
      const myNode = title.getElementsByClassName('carousel-control-prev')[i];
      while (myNode.firstChild) {
        myNode.removeChild(myNode.firstChild);
      }
    }

    for (let i = 0; i < title.getElementsByClassName('carousel-control-next').length; i++) {
      const myNode = title.getElementsByClassName('carousel-control-next')[i];
      while (myNode.firstChild) {
        myNode.removeChild(myNode.firstChild);
      }
    }
    if (type === "image") {
      domToImage.toPng(title)
        .then(function (dataUrl) {
          saveAs(dataUrl, 'site' + title.id + '.png');
        })
        .catch(function (error) {
        });
    } else if (type === "pdf") {
      domToImage.toBlob(title)
        .then(function (dataUrl) {
          saveAs(dataUrl, title.id + '.pdf');
        })
        .catch(function (error) {
        });
    }
  }


  public exportAsPDF(event: MouseEvent, options?: DrawOptions) {
    const element = event.target as HTMLElement;
    let id = this.uid;
    let title = document.getElementById(this.uid);
    title.getElementsByClassName('show')[1].classList.remove('show');

    drawDOM(title).then((group: Group) => {
      return exportPDF(group);
    }).then((dataUri) => {
      saveAs(dataUri, 'site' + id + '.pdf');
    });

  }
  private exportElementWithInfo(element: HTMLElement, options?: DrawOptions) {
    let durationString = "";
    if(this.monthlyQuarterly){
      durationString = '<p> &nbsp; ' + this.timeRangeText + ' : ' + this.translate.instant("WATER_SAFETY.LAST") + ' ';
      let quartersOrMonths = this.monthlyQuarterly.value + ' ' + (this.monthlyQuarterly.durationType === 'monthly'? this.translate.instant("COMMON.MONTHS"): this.translate.instant("COMMON.QUARTERS"))+ '  </p>';
      durationString = durationString + quartersOrMonths;
    }else{
      durationString = '<p> &nbsp; ' + this.timeRangeText + ' : ' + this.translate.instant("WATER_SAFETY.LAST") + ' '
                        + this.currentDays + ' ' + this.translate.instant("COMMON.DAYS") + '  </p>'
    }
    element.getElementsByClassName('show')[1].classList.remove('show');
    if (this.isTimeRange === true) {
      this.htmlData = '<div class="bg-white more-info" id="moreInfo">'
        + '<p> &nbsp; </p>'
        + '<p> &nbsp; <b>' + this.exportDateText + ' : </b> ' + this.formatedDate + '</p>'
        + '<p> &nbsp; <b>' + this.pageLevelFilterText + ' </b> </p>'
        + '<p> &nbsp;&nbsp;' + this.getSiteFilterDetails() + '</p>'
        + durationString
        + '<p> &nbsp; <b>' + this.titleText + '</b> : ' + this.imageTitle + '</p>'
        + '</div>';
    } else {
      if (this.isTCOPipeline === true) {
        this.htmlData = '<div class="bg-white more-info" id="moreInfo">'
          + '<p> &nbsp; </p>'
          + '<p> &nbsp; <b>' + this.exportDateText + ' : </b> ' + this.formatedDate + '</p>'
          + '<p> &nbsp; <b>' + this.pageLevelFilterText + ' </b> </p>';
        if (this.getSiteFilterDetails()) {
          this.htmlData += '<p> &nbsp;&nbsp;' + this.getSiteFilterDetails() + '</p>';
        }
        this.htmlData += '<p> &nbsp; <b>' + this.titleText + '</b> : ' + this.imageTitle + '</p>'
          + '</div>';
      } else if (this.isDataViz) {
        this.htmlData = '<div class="bg-white more-info" id="moreInfo">'
          + '<p> &nbsp; </p>'
          + '<p> &nbsp; <b>' + this.exportDateText + ' : </b> ' + this.formatedDate + '</p>'
          + '<p> &nbsp; <b>' + this.titleText + ' : ' + this.chartTitle + '</b></p>'
          + '<p> &nbsp; <b>' + this.timeRangeText + ' : </b>' + this.selectedDVDuration + '</p>'
          + '</div>';
      } else {
        this.htmlData = '<div class="bg-white more-info" id="moreInfo">'
          + '<p> &nbsp; </p>'
          + '<p> &nbsp; <b>' + this.exportDateText + ' : </b> ' + this.formatedDate + '</p>'
          + '<p> &nbsp; <b>' + this.pageLevelFilterText + ' </b> </p>'
          + '<p> &nbsp;&nbsp;' + this.getSiteFilterDetails() + '</p>'
          + '<p> &nbsp; ' + this.timeRangeText + ' : ' + this.selectedFIDuration + '</p>'
          + '<p> &nbsp; <b>' + this.titleText + '</b> : ' + this.imageTitle + '</p>'
          + '</div>';
      }

    }
    element.insertAdjacentHTML('afterbegin', this.htmlData);
    const filters = this.getCorporatNameOrEvocLastNode();
    drawDOM(element, options).then((group: Group) => {
      return exportImage(group);
    }).then((dataUri) => {
      saveAs(dataUri, this.imageTitle + '_' + filters + '_' + this.formatedDate + '.png');
      document.getElementById('moreInfo').outerHTML = ' ';
    });
  }

  private exportElement(element: HTMLElement, options?: DrawOptions) {
    element.getElementsByClassName('show')[1].classList.remove('show');
    drawDOM(element, options).then((group: Group) => {
      return exportImage(group);
    }).then((dataUri) => {
      saveAs(dataUri, this.uid + this.formatedDate + '.png');
    });
  }

  private exportElementWithInfoDetails(element: HTMLElement, options?: DrawOptions) {
    element.getElementsByClassName('show')[1].classList.remove('show');
    drawDOM(element, options).then((group: Group) => {
      return exportImage(group);
    }).then((dataUri) => {
      if (this.siteName) {
        saveAs(dataUri, this.translate.instant('QUALITY.SERVICE_NAME_LINK') + '_' + this.siteName + '_' + this.uid + '_' + this.formatedDate + '.png');
      } else {
        saveAs(dataUri, this.translate.instant('QUALITY.SERVICE_NAME_LINK') + '_' + this.uid + '_' + this.formatedDate + '.png');
      }
    });
  }

  private generateFileName() {
    // ToDo Reduce the code using moment js
    const date = new Date();
    const dateformat = [
      ('0' + (date.getMonth() + 1)).slice(-2),
      ('0' + date.getDate()).slice(-2),
      date.getFullYear().toString().substr(2, 2)].join('') + '-' +
      [date.getHours(),
      date.getMinutes(),
      date.getSeconds()].join('');

    return this.fileName + '-' + dateformat;

  }

  private getSiteFilterDetails(): string {
    let filteredString = '';
    let appliedfilterDetails = JSON.parse(this.localCacheService.fetchData('appliedfilterDetails', 'local'));
    appliedfilterDetails = appliedfilterDetails? appliedfilterDetails : JSON.parse(this.localCacheService.fetchData('appliedSSDfilterDetails', 'local'))
    let siteFilterDetails = this.filterDetails? this.filterDetails : appliedfilterDetails;

    if (siteFilterDetails && siteFilterDetails.contextPointId != null) {
      let childString = this.getChildrenDetails(siteFilterDetails.filterObject.children);
      if (siteFilterDetails.filterObject && siteFilterDetails.filterObject.parentId !== '') {
        filteredString = 'CVOC: </b>' + siteFilterDetails.filterObject.parentName + childString;
      } else {
        if (childString) {
          filteredString = 'EVOC: </b>' + (childString).substr(2);
        }
      }
    }
    return filteredString;
  }

  private getChildrenDetails(children:IChild[]): string {
    let childString = '';
    children.forEach((child:IChild) => {
      if(child && child.contextPointName){
          childString += ', ' + child.contextPointName;
        }
    });
    return childString;
  }

  private getCorporatNameOrEvocLastNode() {
    const appliedFilter = JSON.parse(this.localCacheService.fetchData('appliedfilterDetails', 'local'));
    const appliedFIFilter = JSON.parse(this.localCacheService.fetchData('appliedFIfilterDetails', 'local'));
    if ((appliedFilter && appliedFilter.filterObject) || (appliedFIFilter && appliedFIFilter.filterObject)) {
      const fobj = appliedFilter?appliedFilter.filterObject:appliedFIFilter.filterObject;
      if (fobj.parentId !== '') {
        return 'CVOC: ' + fobj.parentName;
      } else {
        if (fobj.children.length) {
          return 'EVOC: ' + fobj.children[fobj.children.length - 1].contextPointName;
        }
      }
    }
    return '';
  }

  private isIE() {
    const match = navigator.userAgent.search(/(?:Edge|MSIE|Trident\/.*; rv:)/);
    return (match !== -1);
  }

  private isSafari() {
    const ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('safari') !== -1) {
      if (ua.indexOf('chrome') > -1) {
        return false;  // Chrome
      } else {
        return true;  // Safari
      }
    }
  }

  exportAsPrintChart() {
    this.moreOptionPrintWidgetClicked.emit(true);
    setTimeout(() => {
      window.print();
    }, 500);
  }

  /**
   * @description method to print data in excel along with special characters
   * @param data Json data to print in csv
   * @param filename CSV File name to save as
   * @param option additional info
   */
  public ExportToExcel(data: any, filename: string, option: any) {
    new AngularCsv(data, filename, option);
  }
  
}
