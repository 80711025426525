// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'nalco-cancel-popup',
//   templateUrl: './cancel-popup.component.html',
//   styleUrls: ['./cancel-popup.component.scss']
// })
// export class CancelPopupComponent implements OnInit {

//   constructor() { }

//   ngOnInit()
//   }

// }
import { ChangeDetectorRef, Component, EventEmitter, Input,  OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/do';
import * as contracts from '../../../shared/contracts/_contracts';

// FK - renamed to Pascal case (component name, entity name)
@Component({
    selector: 'nalco-cancel-popup',
    templateUrl: './cancel-popup.component.html',
    styleUrls: ['./cancel-popup.component.scss']
})

export class CancelpopupComponent implements OnInit {
    @Input() modalText: string;
    @Input() inventoryText: string;
    @Input() modcontent: string;
    @Input() isValid = true;
    @Input() disablebtn: boolean;
    @Input() showBtn: boolean;
    @Input() cancelBtn: boolean;
    @Input() actionBtn: boolean;
    @Input() confirmBtn = '';
    @Input() closeBtn = '';
    @Input() stayBtn = '';
    @Input() closeIcon: boolean;
    @Input() btnShow = false;
    @Input() showWarning: boolean;
    @Input() savedPopup: boolean;
    @Input() modalNoteText: string = '';
    @Input() modalAlertText: string = '';
    @Input() removeBtn = false;
    @Input() cancelBtnText: string = '';
    @Input() disableSaveButton = false;
    @Input() disableSubmitButton = false;
    @Input() modalsaveText: string;

    @Output('cancelModal') cancelModal = new EventEmitter<any>();
    @Output('stayModalPopUp') stayModalPopUp = new EventEmitter<any>();
    @Output() deleteModal = new EventEmitter<any>();
    @Output() closeModal = new EventEmitter<any>();
    @Output('removeModal') removeModal = new EventEmitter<any>();

    currentEdit: any;

    area: contracts.IArea;
    unit: contracts.IUnit;
    asset: contracts.IAsset;
    btnId: string;
    showRadio = false;
    isEnable = false;
    selectedValue: string;
    constructor(public activeModal: NgbActiveModal, public changeRef: ChangeDetectorRef) {
    }
    ngOnInit() { }

    cancelAddEditModal() {
        // SonarQube Defect: Duplicate conditional structure
        // if (this.disablebtn) {
        //     this.cancelModal.emit({});
        //     this.activeModal.close();
        // } else {
        this.cancelModal.emit(true);
        this.activeModal.close();
        // }
    }

    closeAddEditModal() {
        this.cancelModal.emit(false);
        this.activeModal.close();
    }

    closeModalPopup() {
        this.closeModal.emit();
        this.activeModal.close();
    }

    public Delete() {
        this.deleteModal.emit({});
    }

    stayModal() {
        // SonarQube Defect: Duplicate conditional structure
        // if (this.showBtn) {
        //     this.stayModalPopUp.emit({});
        //     this.activeModal.close();
        // } else {
        this.stayModalPopUp.emit({});
        this.activeModal.close();
        // }
    }

    onSelectionChange(value: string) {
        this.selectedValue = value;
        this.isEnable = false;
    }

    cancelButtonModal() {
        this.removeModal.emit(false);
        this.activeModal.close();
    }
}
