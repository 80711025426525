import { Location } from '@angular/common';
import type { HttpErrorResponse } from '@angular/common/http';
import type { OnDestroy, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import type { Subscription } from 'rxjs/Subscription';
import { SubheaderHandlerService } from '../../../app/core/nav/subheader-handler.service';
import { USERROLES } from '../../app.config';
import { UserService } from '../../core/user/user.service';
import { XdComponentState } from '../../shared/components/xd-container/xd-component';
import { UserSettings } from '../../shared/models/User';
import type { IDataSource } from '../_contracts';
import { NotificationService } from '../_services/notification.service';
import { ComponentState } from './../../shared/components/xd-container/xd-component-state';

@Component({
  selector: 'nalco-merge-system-name',
  templateUrl: './merge-system-name.component.html',
  styleUrls: ['./merge-system-name.component.scss']
})
export class MergeSystemNameComponent extends XdComponentState implements OnInit, OnDestroy {

  dagId: number;
  systemName: string;

  oldDataSourceId: number;

  newDataSourceId: number;

  oldDataSource: IDataSource;

  oldDSAssetName = '';

  newDSAssetName = '';

  newDataSource: IDataSource;

  errorInfo: HttpErrorResponse;
  isCopyMappingFailed = false;

  subheaderData = {
    leftSection: {
      type: 'title',
      data: 'Asset Harvester',
      className: 'col-lg-6'
    },
    middleSection: {
      type: '',
      data: [],
      className: ''
    },
    rightSection: {
      type: '',
      data: [],
      className: 'col-lg-6'
    }
  };


  public writeAlso: boolean = false;

  private subscriptions: Subscription[] = [];
  data = [
    { text: this.translate.instant('NOTIFICATIONS.ASSET_HARVESTER'), link: 'assetharvester' },
    { text: '', link: '' },
  ];

  constructor(
    private activateRoute: ActivatedRoute,
    private location: Location,
    private translate: TranslateService,
    private notificationService: NotificationService,

    private subHeaderService: SubheaderHandlerService,

    private userService: UserService,
    private router: Router
  ) {
    super();
    this.getAHPermissions();
  }

  ngOnInit(): void {
    this.activateRouteSubscribtion();

    this.subHeaderService.updateLeftSection(this.subheaderData.leftSection);
    this.subHeaderService.updateMiddleSection(this.subheaderData.middleSection);
    this.subHeaderService.updateRightSection(this.subheaderData.rightSection);
  }

  myParentInstance(): MergeSystemNameComponent{
    return this;
  }

  private getAHPermissions(): any {
    const user: UserSettings = this.userService.getCurrentSettings();
    user.Permissions.forEach(p => {
      if (p.PermissionId.toString() == USERROLES.ASSETHARVESTERREADWRITE) {
        this.writeAlso = true;
      }
    });
  }

  /**
   * @description To get the parameters comming through route URL and initialize the local variables.
   * @returns void
   */
  activateRouteSubscribtion(): void {
    this.subscriptions.push(this.activateRoute.params.subscribe((params) => {
      if (params && params.dagId && params.oldDataSourceId && params.newDataSourceId) {
        this.dagId = Number(params.dagId);
        this.oldDataSourceId = Number(params.oldDataSourceId);
        this.newDataSourceId = Number(params.newDataSourceId);
        this.systemName = params.systemName;
        this.data[1].text = this.systemName;
      }
      this.setState(ComponentState.loading);
      this.getDataSourceMappingDetails(this.dagId, this.oldDataSourceId, this.newDataSourceId);
    }));
  }

  /**
   * @description To get the DataSource mapping details
   * @param contextPointId
   * @returns void
   */
  getDataSourceMappingDetails(contextPointId: number, oldDatasourceCpId: number, newDatasourceCpId: number): void {
    this.subscriptions.push(this.notificationService.getDataSourceMappingDetails(contextPointId, oldDatasourceCpId, newDatasourceCpId).subscribe((res: IDataSource[]) => {
      this.oldDSAssetName = '';
      this.newDSAssetName = '';
      res.map((ds) => {
        // filtering the old datasource object
        if (ds.DataSourceContextPointId === this.oldDataSourceId) {
          this.oldDataSource = ds;
          if (ds.MappedAsset && ds.MappedAsset.length > 0) {
            ds.MappedAsset.map((asset, index) => {
              this.oldDSAssetName = this.oldDSAssetName + asset.AssetName
              if (ds.MappedAsset.length > 1 && index < ds.MappedAsset.length - 1) {
                this.oldDSAssetName = this.oldDSAssetName + ' , ';
              }
            })
          }
        }
        // filtering the new datasource object
        if (ds.DataSourceContextPointId === this.newDataSourceId) {
          this.newDataSource = ds;
        }

      })
      this.setState(ComponentState.ready);
    },
      (error: HttpErrorResponse) => {
        this.errorInfo = error;
      }));
  }

  /**
  * @description To copy mapping from old to new data source and if mapping is successfull, it navigates to site tile page.
  * @returns void
  */
  mapDataSource(): void {
    // Set serial number using the new data source's serial number
    this.notificationService.setSerialNumber(this.newDataSource.SerialNumber);
    this.setState(ComponentState.loading);
    // Subscribe to copy mapping to data source.
    this.subscriptions.push(this.notificationService.copyMappingToDatasource(this.dagId,
      this.oldDataSourceId, this.newDataSourceId).subscribe(res => {
        if (res) {
          // Set refreshNotification flag to true
          this.notificationService.setMapped(true);
          this.isCopyMappingFailed = false;
          this.router.navigate(['/assetharvester/' + this.dagId + '/asset']);
          this.setState(ComponentState.ready);
        } else {
          this.isCopyMappingFailed = true;
          this.setState(ComponentState.ready);
        }
      },
        (error) => {
          this.errorInfo = error;
          this.isCopyMappingFailed = true;
          this.setState(ComponentState.error);
        }));
  }


  /**
   * @description: To go back to the previous page.
   * @returns void
   */
  onCancel(): void {
    this.location.back();
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      if (s) {
        s.unsubscribe();
      }
    });
  }


}
