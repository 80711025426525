import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ErrorhandlingPopupComponent } from "./errorhandling-popup/errorhandling-popup.component";
import { TranslateService } from "@ngx-translate/core";

export class ErrorHandling {

  errorRegExp = /5[0-9][0-9]/gmi;
  modalRef: any;
  errorMessage = '';
  constructor(private modalService: NgbModal, 
    private translate: TranslateService
    ) { }

  showErrorPopup(errorText) {
    let status = errorText.status;
    let errorMessage = '';
    let guid = '';

    if ((status !== 0) && (status !== 401)){​​​​​​
      let error = errorText._body;
      if (!error || !error.ExceptionMessage ) {
        errorMessage = errorText._body ? errorText._body : (errorText.statusText ? errorText.statusText :errorText);
      }
      else {
        errorMessage = error.ExceptionMessage;
        guid = error.Message;
        errorMessage = '{' + guid + '} ' + errorMessage;
      }
      this.modalRef = this.modalService.open(ErrorhandlingPopupComponent, {
        backdrop: 'static',
        keyboard: false,
        windowClass: 'saveExit-modal'
      }).componentInstance;
      this.modalRef.errorMessage = errorMessage;
    } else {
      if(status == 401){
              
      }
      // this.msalJsService.clear(true);
      // setTimeout(() => { this.msalJsService.refreshToken() }, 1000);
      // setTimeout(() => { location.reload(true) }, 6000);
      }
  }
}