import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserService } from '../../user/user.service';

@Injectable({ providedIn: 'root' })
export class UserSettingsResolverService implements Resolve<any> {
  constructor(private userSettings: UserService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any>|Promise<any>|any {
    return this.userSettings.getUserSettings().pipe(
        catchError(error => {
          return of('No UserSettings available'+ error);
        })
    );
  }
}