import { NgModule, ModuleWithProviders } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateICUParser } from '@pawelalikowski/ngx-translate-parser-plural-select';
import { TranslateLoader, TranslateModule, TranslateParser } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { createTranslateLoader } from '../../app.module';
import { HttpClient } from '@angular/common/http';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { ClickOutside } from './services/click-outside.directive';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { SharedDropdownComponent } from './components/shared-dropdown/shared-dropdown.component';
import { DropdownSearchPipe } from '../../core/pipes/drop-down-search-pipe/drop-down-search.pipe';
import { CustomDatePickerModule } from '../custom-date-picker/custom-date-picker.module';
import { DividerModule } from '../divider/divider.module';
@NgModule({
    imports: [
        NgbModule,
        DropDownsModule,
        CommonModule,
        FormsModule,
        InfiniteScrollModule,    
        CustomDatePickerModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
            parser: {
                provide: TranslateParser,
                useClass: TranslateICUParser
            }
        }),
        ReactiveFormsModule,
        ButtonsModule,
        DividerModule,
    ],
    declarations: [
        ClickOutside,
        DropdownComponent,
        SharedDropdownComponent,
        DropdownSearchPipe
    ],
    exports: [
        ClickOutside,
        DropdownComponent,
        SharedDropdownComponent,
        NgbModule,
        FormsModule,
        CommonModule,
        
    ],
    providers: [
    ]
})

export class SharedButtonDropdownModule {
    private static count = 0;
    static forRoot(): ModuleWithProviders<SharedButtonDropdownModule> {
        return {
            ngModule: SharedButtonDropdownModule,
            providers: [],
        };
    }
}
