/* eslint-disable @typescript-eslint/consistent-type-imports */
import { Component, OnInit, Input, HostListener, OnDestroy, Output, EventEmitter } from '@angular/core';
import { User } from './../../shared-main/models/User';
import { headerHandlerService } from './../../core/nav/header-handler.service';
import { LocalCacheService } from '../../core/local-cache/local-cache.service';
import { Router } from '@angular/router';
import { ISubheader, ISubheaderLeftSection } from './../../shared-main/components/sub-header/sub-header.modal';
import { Subscription } from 'rxjs/Subscription';
import { SubheaderHandlerService } from './../../core/nav/subheader-handler.service';
import { MenuService } from '../../menu/menu.service';
import { DividerOption } from './../divider/divider.model'
import { TranslateService } from '@ngx-translate/core';
import { AppEnvConfig } from '../../app.env.config';

@Component({
  selector: 'nalco-app-header',
  templateUrl: './app-header.component.html',
})
export class AppHeaderComponent implements OnInit , OnDestroy {
  @Input() user: User; // Defines the user Authentication
  @Input() hideSubHeader?: boolean = false;
  @Input() isHomePage?: boolean = false;
  @Output() subHeaderHiddenChange = new EventEmitter<boolean>();
  userInfo: User;
  pageTitle = '';
  profileDdToggle = false;
  Initials = '';
  collapsed = true;
  toggleCollapsed(): void {
    this.collapsed = !this.collapsed;
  }
  isSubHeaderHidden: boolean = false;
  shouldHideSubHeader: boolean;
  dividerCss: DividerOption;
  leftSection: ISubheaderLeftSection; // Getting page information from the existing sub header service
  isExpand = false;
  isMobile = false;
  showDropdownMenu: boolean;
  showThreeDotOptions: boolean;
  isNewUIEnabled : boolean;
  @Output() showLoader: EventEmitter<boolean> = new EventEmitter();
  private subscriptions: Subscription[] = [];


  constructor(private headerService: headerHandlerService,
    private translate: TranslateService,
    private cache: LocalCacheService, 
    private subHeaderService: SubheaderHandlerService,
    private router: Router,
    private menuService: MenuService,
    private config : AppEnvConfig
  ) {

  }

   /**
   * @description This hostlistner function is used to display and hide the user profile according to the user selection
   * @param event to catch the instance to get the class name 
   */

  @HostListener('document:click', ['$event']) clickedProfile(event: { target: { className: string ; }; }) {
    if ((event.target.className.indexOf !== undefined
      && event.target.className === 'custom-dropdown-img')) {
      this.showDropdown();
    }else if (this.isMobile && event.target.className === 'span-round') {
      this.showDropdownMenu = true;
    }
    else{
      if (this.showDropdownMenu) {
      this.showDropdownMenu = !this.showDropdownMenu;
      }
    }
    if (event.target.className.indexOf !== undefined && this.isMobile
      && (event.target.className.indexOf('submenu-margin') > 0 ||
      event.target.className.indexOf('singlemenu') > -1) ){
      this.isExpand = false;

    }
  }

  ngOnInit() {
    this.isMobile = (/android|webos|iphone|ipad|ipod|blackberry|windows phone/).test(navigator.userAgent.toLowerCase());
    let initial = '';
    this.userInfo = JSON.parse(this.cache.fetchData('msalUserInfo'));
    this.isNewUIEnabled = JSON.parse(this.config.getEnv('isNewUIEnabled').toString())
    const givenName = this.checkUserName(this.userInfo.userPrincipalName || this.userInfo.EmailAddress);
    if (givenName) {
      initial = givenName.substring(0, 1);
    }
    if (this.userInfo.surname || this.userInfo.LastName) {
      const surname = this.userInfo.surname || this.userInfo.LastName;
      if (surname) {
        initial += surname.substring(0, 1);
      } else {
        // fallback to get from given_name, if family name is null
        const firstName = this.userInfo.FirstName || this.userInfo.displayName;
        initial += firstName.split(' ')[1].substring(0, 1);
      }
    }
    this.Initials = initial.toUpperCase();
    // For the divider Css
    this.dividerCss = {
      color: "#bac7d8",
      minLength: '25px',
      isHorizontal: false
    }
    // to get exisiting isExpand value
    this.menuService.navExpand.subscribe(value =>
      this.isExpand = value
  )
  this.subHeaderService.subheaderData$.subscribe((data) => { 
    this.isSubHeaderHidden = data;
    this.shouldHideSubHeader = this.hideSubHeader && this.isSubHeaderHidden;
    this.shouldHideSubHeader = this.shouldHideSubHeader === undefined ? false : this.shouldHideSubHeader;
    this.subHeaderHiddenChange.emit(this.shouldHideSubHeader);
  });
    this.getPagetTitle();
  }

  /**
   * @description This function is to get the page title from existing sub header module service 
   */

  getPagetTitle(): void {
    this.subscriptions.push(this.subHeaderService.subheaderLeftSection$.subscribe(async (update) => {
      this.leftSection = await update;
      if (this.leftSection.data && this.leftSection.data[0] && this.leftSection.data[0].text) {
        this.pageTitle = this.leftSection.data[0].text
      } else {
        this.pageTitle =  this.leftSection.data ?  this.leftSection.data : this.translate.instant("LEFT_NAV.HOME_NAV_LABEL");
      }
      this.pageTitle = (typeof this.pageTitle === 'string') ? this.pageTitle : '';
    }));
  }

  

  /**
   * @description It Will expand the left navigation bar 
   * Passing values with the help of behaviour subject
   */
  expandNavBar(): void {
    this.isExpand = !this.isExpand;
    this.menuService.navExpand.next(this.isExpand);
    this.showThreeDotOptions = false;
  }

   /**
   * @description used to close the left navigation bar on click of close icon.
   */
  onCloseNavBar():void{
    this.isExpand = !this.isExpand;
    this.menuService.navExpand.next(this.isExpand);
    this.showThreeDotOptions = false;
  }

  /**
   * @description Hide & Show DropDownMenu
   */

  showDropdown(): void {
    this.showDropdownMenu = !this.showDropdownMenu;
  }

   /**
   * @description used to show the logout dropdown on click.
   */
  showDropdownForLogout(): void {
    if(this.isMobile){
    this.showDropdownMenu = !this.showDropdownMenu;
    }
  }

  logout() {
    this.router.navigate(['/logout']);
  }

  clickBtn() {
    this.headerService.btnclickhead$(event);
    this.router.navigate(['/home']);
    this.showThreeDotOptions = false;
  }

  showProfileSubMenu() {
    this.profileDdToggle = !this.profileDdToggle;
  }

  // Checking given_name is undefined
  checkUserName(name) {
    if (name !== undefined) {
      return name;
    } else {
      return this.userInfo.FirstName;
    }
  }

   /**
   * @description used to show the Profile and Notifications dropdown on three dot click.
   */
   showThreeDotOptionsMenu(): void {
    if(this.isMobile){
    this.showThreeDotOptions = !this.showThreeDotOptions;
    }
  }
  
  handleLoading(loader: boolean){
    this.showLoader.emit(loader);
  }
   /**
   * @description this lifecycle is used unsubscribe the subscribed api calls and observables
   */

  ngOnDestroy() {
     this.menuService.navExpand.unsubscribe();
  }
}
