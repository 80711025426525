import { IMeasurementType, IAvailableMeasurementUnits, IMeasurementTypes, IMeasurementsList, IMostUsefulMeasurement, IProbeData, IProbeMapLists, IProbeTankDefaultsLists } from "../_contracts/IMeasurementType";

export class MeasurementTypes implements IMeasurementTypes{
    CategoryId?: number;
    CategoryName?: string;
    Measurements? :Array<MeasurementType>;
}

export class MeasurementType implements IMeasurementType{
    AvailableMeasurementUnits?: Array<AvailableMeasurementUnits>;
    ImmutableName?: string;
    Description?: string;
    RelatedMeasurementId?: number;
    UnitLabel?: string; 
    UnitLabelPositionTypeId?: string;
    UnitLabelPositionTypePrettyName?: string;
    OptionGroupId?: number;
    CreatedBy?: number; 
    CreatedOn?: string; 
    ModifiedBy?: number; 
    ModifiedOn?: string; 
    MeasurementId?: number; 
    MeasurementName?: string; 
    NalcoNumericsUnitOrSpecies?: string; 
    StuiTypeId?: number; 
    NeedsMeasurementContext?: boolean; 

    constructor(measurementType: MeasurementType) {
        if(measurementType){
            this.ImmutableName = measurementType.ImmutableName;
            this.Description = measurementType.Description;
            this.RelatedMeasurementId = measurementType.RelatedMeasurementId;
            this.UnitLabel = measurementType.UnitLabel;
            this.UnitLabelPositionTypeId = measurementType.UnitLabelPositionTypeId;
            this.UnitLabelPositionTypePrettyName = measurementType.UnitLabelPositionTypePrettyName;
            this.OptionGroupId = measurementType.OptionGroupId;
            this.CreatedBy = measurementType.CreatedBy;
            this.CreatedOn = measurementType.CreatedOn;
            this.ModifiedBy = measurementType.ModifiedBy;
            this.ModifiedOn = measurementType.ModifiedOn;
            this.MeasurementId = measurementType.MeasurementId; 
            this.MeasurementName = measurementType.MeasurementName;
            this.NalcoNumericsUnitOrSpecies = measurementType.NalcoNumericsUnitOrSpecies;
            this.StuiTypeId = measurementType.StuiTypeId;
            this.NeedsMeasurementContext = measurementType.NeedsMeasurementContext;

            if (measurementType.AvailableMeasurementUnits) {
                measurementType.AvailableMeasurementUnits.forEach(availableMeasurementUnits => {
                    this.AvailableMeasurementUnits.push(new AvailableMeasurementUnits(availableMeasurementUnits));
                });
        }
        }
    }
}

export class AvailableMeasurementUnits implements IAvailableMeasurementUnits{
    IsBaseUnit?: boolean;
    SignificantDigits?: number;
    Symbol: string;
    UnitName: string

    constructor(availableMeasurementUnits: AvailableMeasurementUnits) {
        this.IsBaseUnit = availableMeasurementUnits.IsBaseUnit;
        this.SignificantDigits = availableMeasurementUnits.SignificantDigits;
        this.Symbol = availableMeasurementUnits.Symbol;
        this.UnitName = availableMeasurementUnits.UnitName;
    }
}

export class MeasurementOrderList implements IMeasurementsList {
    MeasurementId: number;
    StuiId: number;
    StuiTypeId: number;
    Order: number; 

    constructor(MsrOrder?: MeasurementOrderList) {
    if (MsrOrder) {
        this.MeasurementId = MsrOrder.MeasurementId;
        this.StuiId = MsrOrder.StuiId;
        this.StuiTypeId = MsrOrder.StuiTypeId;
        this.Order = MsrOrder.Order;
        }
    }
}

export class MostUsefulMeasurement implements IMostUsefulMeasurement {
    Id: string
    ServiceTypeId: number;
    ContextPointTypeId: number;
    ContextPointSubTypeId: number;
    ModifiedBy: number;
    ModifiedOn: any;
    Measurements: Array<IMeasurementsList>

    constructor(mostUseful?: MostUsefulMeasurement) {
        this.Id = mostUseful.Id;
        this.ServiceTypeId = mostUseful.ServiceTypeId;
        this.ContextPointTypeId = mostUseful.ContextPointTypeId;
        this.ContextPointSubTypeId = mostUseful.ContextPointSubTypeId;
        this.ModifiedBy = mostUseful.ModifiedBy;
        this.ModifiedOn = mostUseful.ModifiedOn;
        this.Measurements = mostUseful.Measurements;
    }
}

export class ProbeData implements IProbeData{
    ProbeMapLists? :Array<ProbeMapLists>;
    ProbeTankDefaultsLists? :Array<ProbeTankDefaultsLists>;
    constructor(pbData?: ProbeData){
        Object.assign(this, pbData);
    }
}
export class ProbeMapLists implements IProbeMapLists{
    ProbeId?: number;
    ProbeName?: string;
    ProbeModel?: string;
    ProbeMeaZero?: number;
    ProbeMeaIsInverse?: number;
    ProbeMeaMin?: number;
    ProbeMeaMax?: number;
    ProbeMeaUOMID?: number;
    PartNumber?: string;
    Status?: number;
    ProbeDistMin?: number;
    ProbeDistMax?: number;
    UseCustomMinMax?: number;
    BlockingDistance?: number;
    EnabledForObc?: number;
    constructor(pbList?: ProbeMapLists){
        Object.assign(this, pbList);
    }
}
export class ProbeTankDefaultsLists implements IProbeTankDefaultsLists{
    ProbeId?: number;
    TankTypeId?: number;
    ProbeMeaZero?: number;
    ProbeMeaMin?: number;
    ProbeMeaMax?: number;
    ProbeMeaUOMID?: number;
    Status?: number;
}