import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationHandlerService } from '../../../../core/nav/navigation-handler.service';

@Component({
  selector: 'nalco-humbergur-menu',
  templateUrl: './humbergur-menu.component.html',
  styleUrls: ['./humbergur-menu.component.scss']
})
export class HumbergurMenuComponent implements OnInit {


  @Input() dataSource: any;

  disabled = false;

  constructor(private navService: NavigationHandlerService) {
    navService.navToggle$.subscribe(state => {
      this.disabled = state;
    });

  }

  ngOnInit() {
  }
}
