import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../../user/user.service';
import { UserSettings } from '../../../shared/models/User';

@Injectable()
export class QualityGuard implements CanActivate {
    constructor(private router: Router, private userService: UserService) { }

    canActivate(): Observable<boolean> {
        const user: UserSettings = this.userService.getCurrentSettings();

        if (user.IsSingleSiteUser && user.DefaultSiteId !== undefined) {
            this.router.navigate(['quality/siteView/', user.DefaultSiteId]);
        } else {
            return Observable.of(true);
        }
    }
}