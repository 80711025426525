import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import type { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalService,
} from '@azure/msal-angular';
import { AppEnvConfig } from '../../app.env.config';
// import { Logger } from 'msal';
import type { IPublicClientApplication } from '@azure/msal-browser';
import { InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { AppConfigService } from '../services/app-config.service';
// protected resource map factory function
export const protectedResourceMap = new Map<string, string[]>();
/**
 * This function generates a protected resource map based on the provided configuration.
 * 
 * @param config - The configuration object containing the MSAL configuration and endpoints.
 */
export const protectedResourceMapMethod = (config: AppEnvConfig) => {
  const end = Object.keys(config.getEnv('msalConfig').endpoints);
  const endv = Object.values(config.getEnv('msalConfig').endpoints);
  const IsB2CEnable = config.getEnv('IsB2CEnable') === 'true' ? true : false;
  end.map((v: string) => {
    protectedResourceMap.set(v, IsB2CEnable ? ['openid', `${config.getEnv('msalConfig').clientId}`] : ['user.read']);
    endv.map((k: string) => {
      protectedResourceMap.set(k, IsB2CEnable ? ['openid', `${config.getEnv('msalConfig').clientId}`] : ['user.read']);
    });
  });
}


/**
 * Determines if the current browser is Internet Explorer (IE).
 * @returns {boolean} True if the browser is IE, false otherwise.
 */
const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

/**
 * Loads the configuration and sets it in the AppConfigService.
 * @param config - The AppEnvConfig object used to load the configuration.
 * @param appConfigService - The AppConfigService used to set the loaded configuration.
 * @returns A function that loads the configuration and sets it in the AppConfigService.
 */
export function loadConfig(config: AppEnvConfig, appConfigService: AppConfigService) {
  return () => config.load().then(() => {
    appConfigService.setConfig(config);
  });
}

/**
 * Factory function to create an instance of IPublicClientApplication.
 * 
 * @param config - The configuration object containing environment variables.
 * @returns An instance of IPublicClientApplication.
 */
export function MSALInstanceFactory(config: AppEnvConfig): IPublicClientApplication {
  const IsB2CEnable = config.getEnv('IsB2CEnable').toString();
  const b2cPolicies = {
    names: {
      signUpSignIn: config.getEnv('signUpSignInPolicy'),
    },
    authorities: {
      signUpSignIn: {
        authority: config.getEnv('authority'),
      },
    },
    authorityDomain: config.getEnv('authorityDomain'),
  };
  return new PublicClientApplication({
    auth: {
      clientId: config.getEnv('msalConfig').clientId,
      authority:
        IsB2CEnable === 'true'
          ? b2cPolicies.authorities.signUpSignIn.authority
          : config.getEnv('authorityUrl') +
          config.getEnv('msalConfig').tenant +
          '/',
      knownAuthorities:
        IsB2CEnable === 'true' ? [b2cPolicies.authorityDomain] : [],
      // validateAuthority: true,
      navigateToLoginRequestUrl: true,
      // postLogoutRedirectUri: window.location.origin + '/assets/logout_redirect/logout.html'
      redirectUri: '/',
      postLogoutRedirectUri: '/'
    },
    cache: {
      cacheLocation: config.getEnv('msalConfig').cacheLocation, // This configures where your cache will be stored
      storeAuthStateInCookie: isIE, // Set this to 'true' if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions: {
        loggerCallback: (
          level: LogLevel,
          message: string,
          containsPii: boolean
        ): void => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
          }
        },
        piiLoggingEnabled: false,

      },

    }
  });
}


/**
 * Factory function for creating MSALInterceptorConfiguration.
 * 
 * @param config - The AppEnvConfig object.
 * @returns The created MSALInterceptorConfiguration object.
 */
export function MSALInterceptorConfigFactory(
  config: AppEnvConfig
): MsalInterceptorConfiguration {
  protectedResourceMapMethod(config);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: protectedResourceMap,
  };
}

/**
 * Factory function to create an MSALGuardConfiguration object.
 * 
 * @param config - The AppEnvConfig object.
 * @returns The created MsalGuardConfiguration object.
 */
export function MSALGuardConfigFactory(config: AppEnvConfig): MsalGuardConfiguration {
  const IsB2CEnable = config.getEnv('IsB2CEnable').toString();
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: IsB2CEnable === 'true' ? ['openid'] : ['user.read'],
    },
    loginFailedRoute: '/'
  };
}

@NgModule({
  providers: [],
  imports: [MsalModule],
})
export class MsalApplicationModule {
  static forRoot() {
    return {
      ngModule: MsalApplicationModule,
      providers: [
        HttpClient,
        AppEnvConfig,
        {
          provide: APP_INITIALIZER,
          useFactory: loadConfig,
          deps: [AppEnvConfig, AppConfigService],
          multi: true,
        },
        {
          provide: MSAL_INSTANCE,
          useFactory: MSALInstanceFactory,
          deps: [AppEnvConfig],
        },
        {
          provide: MSAL_GUARD_CONFIG,
          useFactory: MSALGuardConfigFactory,
          deps: [AppEnvConfig],
        },
        {
          provide: MSAL_INTERCEPTOR_CONFIG,
          useFactory: MSALInterceptorConfigFactory,
          deps: [AppEnvConfig],
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: MsalInterceptor,
          multi: true
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        MsalService,
      ],
    };
  }
}
