import * as contracts from '../contracts/_contracts';

export class Navigation implements contracts.INavigation {
    leftNavigation: contracts.INavigationMenu[];
    topNavigation: contracts.INavigationMenu[];

    constructor(navigation?: contracts.INavigation) {
        if (navigation.leftNavigation) {
            this.leftNavigation = new Array<contracts.INavigationMenu>();
            for (const leftNavigation of navigation.leftNavigation) {
                this.leftNavigation.push(leftNavigation);
            }
        }
        if (navigation.topNavigation) {
            this.topNavigation = new Array<contracts.INavigationMenu>();
            for (const topNavigation of navigation.topNavigation) {
                this.topNavigation.push(topNavigation);
            }
        }
    }
}
