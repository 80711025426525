import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'nalco-status-icon',
  templateUrl: './status-icon.component.html',
  styleUrls: ['./status-icon.component.scss']
})
export class StatusIconComponent implements OnInit {
  @Input() assetStatus: any;
  imagePath: any;

  constructor() { }

  ngOnInit() {
      this.getStatusImage();
  }

  getStatusImage() {
      if (this.assetStatus === '1') { // Green
        this.imagePath = '../../../../assets/images/3d-green.svg';
      } else if (this.assetStatus  === '2') { //Orange
        this.imagePath = '../../../../assets/images/3d-yellow.svg';
      } else if (this.assetStatus  === '3') { //Red
        this.imagePath = '../../../../assets/images/3d-red.svg';
      } else if (!this.assetStatus) {
        this.imagePath = '../../../../assets/images/3d-null.svg';
      }
  }
}
