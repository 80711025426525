import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule, TranslateParser } from '@ngx-translate/core';
import { ReactiveFormsModule , FormsModule } from '@angular/forms';

import { SharedRoModule } from '../shared-ro/shared-ro.module';
import { createTranslateLoader } from '../app.module';
import { HttpClient } from '@angular/common/http';
import { TranslateICUParser } from '@pawelalikowski/ngx-translate-parser-plural-select';
import { SearchComponent } from './globalsearch.component';
import { SearchServiceConfig } from './globalsearch.component_service.config';
import {ApiService} from './globalsearch.component.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule, matFormFieldAnimations } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input'

@NgModule({
  declarations: [SearchComponent],
  imports: [
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    SharedModule.forRoot(),
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    SharedRoModule.forRoot(),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      parser: {
        provide: TranslateParser,
        useClass: TranslateICUParser
      }
    })
  ],
  exports : [
    SearchComponent
  ],
  providers: [SearchServiceConfig, ApiService]
})
export class SearchModule { }
