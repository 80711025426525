import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorPageComponent } from './error-page.component'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from '../../../shared-ui-refresh/button/button.module';
import { TranslateLoader, TranslateModule, TranslateParser } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateICUParser } from '@pawelalikowski/ngx-translate-parser-plural-select';
import { createTranslateLoader } from '../../../app.module';



@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        RouterModule,
        FormsModule,
        ButtonModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
            parser: {
                provide: TranslateParser,
                useClass: TranslateICUParser
            }
        }),
    ],
    declarations: [
        ErrorPageComponent
    ],
    exports: [
        ErrorPageComponent
    ],

})

export class ErrorPageModule { }
