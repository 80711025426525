import { IMap } from '../contracts/IMap';

export class Map implements IMap {
    siteId: string;
    siteLat: number;
    siteLong: number;
    siteName: string;
    // legionellaGrowth:string;
    // legionellaGrowthStatus: string;
    // complianceStatus: Array<any>;

    constructor(map: IMap) {
        this.siteId = map.siteId;
        this.siteLat = map.siteLat;
        this.siteLong = map.siteLong;
        this.siteName = map.siteName;
        // this.legionellaGrowth = map.legionellaGrowth;
        // this.legionellaGrowthStatus = map.legionellaGrowthStatus;
        // this.complianceStatus = map.complianceStatus;

    }
}
