import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupBy'
})
export class GroupByPipe implements PipeTransform {
  transform(collection: Array<any>, property: string, value?: string, resultType?: string): any {

    if (!collection) {
      return null;
    }

    const groupedCollection = collection.reduce((previous, current) => {
      if (value && value.length && value != current[property]) {
        return previous;
      }
      if (!previous[current[property]]) {
        previous[current[property]] = [current];
      } else {
        previous[current[property]].push(current);
      }
      return previous;
    }, {});

    if (resultType && resultType.toLowerCase() === 'number' && groupedCollection) {
      if (value) {
        return groupedCollection[value] ? groupedCollection[value].length : 0;
      } else {
        return Object.keys(groupedCollection).length;
      }
    } else {
      return Object.keys(groupedCollection).map(key => ({ key, value: groupedCollection[key] }));
    }
  }
}
