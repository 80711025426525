import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, HostListener, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalCacheService } from '../../../core/local-cache/local-cache.service';
import { SimpleTimer } from 'ng2-simple-timer';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'nalco-multiple-sites',
  templateUrl: './multiple-site-popup.html',
  styleUrls: ['./multiple-site-popup.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MultipleSitePopupComponent implements OnInit {
  @Input() siteDetails: Array<{
  SiteName : string,
  StateOrProvince : string,
  Country : string
  }> = [];
  @Output('closeModal') closeModal = new EventEmitter<any>();

  headers = [
    { name: 'Site Name', value: 0 },
    { name: 'State/Province', value: 0 },
    { name: 'Country', value: 0 },
  ];
  constructor(public activeModal: NgbActiveModal, private translate: TranslateService,
    private localCacheService: LocalCacheService,
    private st: SimpleTimer) {

  }

  ngOnInit() {

  }
  dismissModal() {
    this.activeModal.close();
  }
}
