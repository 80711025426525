<div class="dosage-recommendation-dashboard">
    <nalco-tile [parentInstance]="myParentInstance()"   [headerTitle]="" [errorData]='errorInfo' [showMoreOptions]=false [showInfoIcon]=false class="no-header">
        <div tile-content class="dr-tab">
            <div class="row col-12">
                <div *ngIf="waterChemistry === 'RawWater'" class="col-4 box phDisplay">
                    <form [formGroup]="dosageAndRecommendationForm" *ngIf="drtdataCount === drData.length-1">
                        <div *ngFor="let control of drData">
                            <nalco-combo-box [data]="control" [formGroup]="dosageAndRecommendationForm"
                                (ddChange)="updateDropdown($event)" (tbChange)="updateInput($event)"></nalco-combo-box>
                        </div>
                    </form>
                </div>
                <nalco-bar-chart [ngClass]="waterChemistry === 'RawWater' ? 'col-7 box' : 'col-12 box'"
                [data]="chartData" [yAxisTitle]="'Saturation %'" [xFormatText]="true" [pointPadding]="pointPadding" [tooltipInfo]="tooltip" [noDataAvailableMsg]="true">
            </nalco-bar-chart>

            </div>

            <div class="col-4 recommendationTable">
                <form [formGroup]="recommendationsForm">
                    <div class="heading">
                        <label class="col-9" translate> RO_OPTIMIZER.RECOMMENDATION_TABLE.RECOMMENDATIONS </label>
                        <label class="col-3" translate> RO_OPTIMIZER.RECOMMENDATION_TABLE.REFERENCE </label>
                    </div>
                    <div class="tableContent">
                        <div class="row tableCell">
                            <label class="col-9" translate> RO_OPTIMIZER.RECOMMENDATION_TABLE.PRODUCT </label>
                            <input class="col-3 disabled" formControlName="selectedProduct">
                        </div>
                        <div class="row tableCell">
                            <label class="col-9" translate> RO_OPTIMIZER.RECOMMENDATION_TABLE.DOSAGE_IN_FEED </label>
                            <input class="col-3 disabled" formControlName="feedDosage">
                        </div>
                        <div class="row tableCell">
                            <label class="col-9" translate> RO_OPTIMIZER.RECOMMENDATION_TABLE.DOSAGE_IN_CONCENTRATE
                            </label>
                            <input class="col-3 disabled" formControlName="concentrateDosage">
                        </div>
                        <div class="row tableCell" *ngIf="waterChemistry == 'RawWater'">
                            <label class="col-9" translate> RO_OPTIMIZER.RECOMMENDATION_TABLE.PH_CONTROL_DOSAGE </label>
                            <input class="col-3 disabled" formControlName="pHControl">
                        </div>
                        <div class="row tableCell" *ngIf="waterAnalysis != 'Seawater'; else seawater">
                            <label class="col-9" translate> RO_OPTIMIZER.RECOMMENDATION_TABLE.CONCENTRATE_LSI </label>
                            <input class="col-3 disabled" formControlName="concentrateLSI">
                        </div>
                        <ng-template #seawater>
                            <div class="row tableCell">
                                <label class="col-9" translate> RO_OPTIMIZER.RECOMMENDATION_TABLE.CONCENTRATE_SDI
                                </label>
                                <input class="col-3 disabled" formControlName="concentrateSDI">
                            </div>
                        </ng-template>
                    </div>
                </form>
            </div>

            <button type="button" class="btn btn-primary col-1 save-button" [disabled]="isSaveDisabled"
                (click)="saveScenario()">{{'COMMON.SAVE' | translate}}</button>
        </div>
    </nalco-tile>
</div>