<div class="nalco-home">
  <div class="home-header p-4">
    <h4 class="title-medium">
      <span translate>COMMON.HI</span>, <span  *ngIf="user">{{user?.FirstName}} {{user?.LastName}}</span>
    </h4>
    <div class="col-lg-12 col-md-12 col-sm-12 heading">
      <h1 class="heading-medium" translate>
        COMMON.WELCOME_TO <span class="logo-text" translate>COMMON.ECOLAB3D_TEXT</span>
      </h1>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6" translate>COMMON.CONNECTING_DATA_INTELLIGENCE_TO_SMART</div>
      <div class="col-lg-1 col-md-1 col-sm-1"></div>
      <div class="col-lg-5 col-md-5 col-sm-5 sub-text">
        <div class="list-text"><span class="green-dot"></span><span translate>COMMON.DISCOVER_DATA_DRIVEN</span></div>
       <div class="list-text"> <span class="green-dot"></span><span translate>COMMON.ACHIEVE_MORE_WITH</span></div>
       <div class="list-text"> <span class="green-dot"></span><span translate>COMMON.STAY_AHEAD_FORCUSED</span></div>
      </div>
    </div>
  </div>

  <div class="top-class p-4">
    <div class="row card-section">

      <div class="col-lg-4 col-md-4 col-sm-4">
        <!-- USER STORY 676883 Hide My Territory menu from Left Navigation and home page  -->
        <!-- <div class="card" style="display: none;> -->
        <!-- need to uncomment before (september) release -->
        <div class="card" *ngFor="let item of menuList.leftTopNavigation" [hidden]="!(item.label === 'LEFT_NAV.MYTERRITORY_NAV_LABEL')">
          <div class="card-header">
            <!-- Should be removed before the release -->
            <div class="card-header-title">
              <span>
                <a class="home-icon-anchor">
                  <img class="icon-css" src="../../../../assets/images/menus/menu-territory-view-icon.svg">
                </a>
              </span>
              <span translate>
                LEFT_NAV.MYTERRITORY_NAV_LABEL</span>
              <span class="float-right" routerLink="/my-territory">
                <a class="home-icon-anchor">
                  <img class="icon-css cursor-icon" src="../../../../assets/images/menus/arrows/arrow-caret-right.svg">
                </a>
              </span>
            </div>
            <!-- <p class="header-description">Lorem ipsum is placeholder text commonly used in the graphic, print, and
            publishing </p> -->
          </div>
        </div>
        <!-- </div> -->
        <!-- <br /> -->
        <div class="card core-elements-card">
          <div class="card-header">
            <div class="card-header-title">
              <span>
                <a class="home-icon-anchor">
                  <img class="icon-css" src="../../../../assets/images/menus/menu-core-elements.svg">
                </a>
              </span>
              <span translate>
                LEFT_NAV.MAIN_NAV_CORE_ELEMENTS</span>
            </div>
            <!-- <p class="header-description">Lorem ipsum is placeholder text commonly used in the graphic, print, and
            publishing </p> -->
          </div>
          <div class="card-body">
            <ul class="card-list" *ngIf="menuList.leftMidNavigation.length > 0">
              <li class="card-text" *ngFor="let item of menuList.leftMidNavigation">
                <a class="label-large" routerLink="{{item.isExternal? '': item.link}}" *ngIf="item.subNavigation.length === 0"
                  (click)="item.isExternal ? navigateToExternal(navigationItem) : ''">{{item.text | translate}}</a>
              </li>
            </ul>
            <div class="no-content-msg" *ngIf="menuList.leftMidNavigation.length === 0">No Content Available</div>
          </div>
        </div>
      </div>


      <div class="col-lg-4 col-md-4 col-sm-4">

        <div class="card">
          <div class="card-header">
            <div class="card-header-title">
              <span>
                <a class="home-icon-anchor">
                  <img class="icon-css" src="../../../../assets/images/menus/menu-dashboard-icon.svg">
                </a>
              </span>
              <span translate>
                LEFT_NAV.MAIN_NAV_LABEL</span>
            </div>
            <!-- <p class="header-description">Lorem ipsum is placeholder text commonly used in the graphic, print, and
            publishing </p> -->
          </div>
          <div class="card-body">
            <ul class="card-list" *ngIf="digitalServicesMenu.length > 0">
              <li class="card-text" *ngFor="let item of digitalServicesMenu">
                <a class="label-large" routerLink="{{item.isExternal? '': item.link}}"
                  (click)="item.isExternal? navigateToExternal(item) : ''">
                  {{item.label | translate}}</a>
              </li>
            </ul>
            <div class="no-content-msg" *ngIf="digitalServicesMenu.length === 0">No Content Available</div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-4 col-sm-4">
        
        <div class="user-profile-home">
        <nalco-user-profile-details [user]="user" [isHomePage]="true"></nalco-user-profile-details>
        </div>

        <div class="card">
          <div class="card-header">
            <div class="card-header-title">
              <span>
                <a class="home-icon-anchor">
                  <img class="icon-css" src="../../../../assets/images/menus/menu-tools-icon.svg">
                </a>
              </span>
              <span translate>
                LEFT_NAV.MAIN_NAV_TOOLS</span>
            </div>
            <!-- <p class="header-description">Lorem ipsum is placeholder text commonly used in the graphic, print, and
            publishing </p> -->
          </div>
          <div class="card-body">
            <div>
          <ul class="card-list" *ngIf="toolsMenus.length > 0">
              <li class="card-text" *ngFor="let item of toolsMenus">
                <a class="label-large" routerLink="{{item.isExternal? '': item.link}}"
                  (click)="item.isExternal? navigateToExternal(item) : ''">
                  {{item.label | translate}}</a>
              </li>
            </ul>
            <span class="float-right cursor-icon" *ngIf="leftMidNavigationItems.length > 6 && !isexpanded"
              (click)="expandAccordian()">View More
              <img class="icon-css" src="../../../../assets/images/menus/arrows/arrow-down-darkblue.svg">
            </span>
            <span class="float-right cursor-icon" *ngIf="isexpanded"
            (click)="getToolsMenus()">View Less
              <img class="icon-css" src="../../../../assets/images/menus/arrows/arrow-up-darblue.svg">
            </span>
          </div>
          <div class="no-content-msg" *ngIf="toolsMenus.length === 0">No Content Available</div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>