import { Injectable } from '@angular/core';
import { ENVIRONMENTS, APP_CONFIG } from '../app.config';
import { IQualityServiceConfig } from '../quality/_contracts/iQuality.service.config.contract';

@Injectable()
export class SearchServiceConfig{
    private configMock: IQualityServiceConfig = { // MOCK - local json settings
        environment: ENVIRONMENTS.mock,
        baseUrl: '',
        apiUrl: {
            getSearchQuery: 'api/GlobalSearch?query={0}&siteCount={1}'
        }
    };

    private _environment: string = APP_CONFIG.ENVIRONMENT;

    constructor() {
        // void
    }
    /**
     * @description return the config mock
     * @returns Mock Environment
     */
    public getEnvironment(): IQualityServiceConfig {
        return this.configMock;
    }
    /**
     * @description sets the environment to the value passed in the parameter environment
     * @param environment 
     */
    public setEnvironment(environment: string): void {
        this._environment = environment;
    }
}