import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'nalco-favorite-toggle',
    templateUrl: './favorite-toggle.component.html'
})
export class FavoriteToggleComponent {
    @Input() isTurnedOn = false; // Defines the enable/disable of the favouriteToggle Button
    @Input() isDisabled = false;
    @Input() toggleId = 'favoriteToggleInput';
    @Output() bindCheckChange: EventEmitter<any> = new EventEmitter(); // Emits the toggle event of the button

    constructor() { }

    changeFavouriteToggle($event) {
        this.isTurnedOn = $event.target.checked;
        this.bindCheckChange.emit(this.isTurnedOn);
    }
}
