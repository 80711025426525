export class selectParentOption { 
    Parent: string; 
    Child: selectChildOption[]; 
} 

export class selectChildOption { 
    Service: string; 
    ShortName: string; 
    URL: string;
}

export class selectSubChildOption{
    ServiceName: string;
    SiteId: string;
    CorpAcctId: bigint;
    DeeplinkUrl: string;
    PermissionId: string[];
    SearchDisplayName: string;
}

export class newSearchResult{
    SiteId: string;
    CorporateAccount: string;
    Country: string;
    Customer: string;
    District: string;
    EcolabDivision: string;
    IndustrialDivision: string;
    Market: string;
    Region: string;
    SalesAccount: string;
    Site: string;
    SoldTo : string;
    Territory : string;
    City: string;
    PostalCode: string;
    StateProvince: string;
    Street: string;
}