import { Pipe, PipeTransform } from '@angular/core';
import * as contracts from '../../shared/contracts/_contracts';
import { DecimalPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';


@Pipe({
    name: 'dateYearDay'
})

export class DateYearDayPipe implements PipeTransform {
    constructor(private translate: TranslateService) { }

    transform(changeDate: Date): string {
        let convertedData: string;
        const todayDate = new Date();

        const timeDiff = Math.abs(changeDate.getTime() - todayDate.getTime());
        const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
        if (diffDays < 365) {

            this.translate.get('DASHBOARD.SOME_DAYS', { value: diffDays.toString() }).subscribe((translations: string) => {

                convertedData = translations;
            });
            return convertedData;
        } else if (diffDays === 365) {
            return '1 Year';
        } else {
            const years = Math.floor(diffDays / 365);
            const days = diffDays % 365;
            this.translate.get('DASHBOARD.SOME_YEAR', { value: years.toString() }).subscribe((translations: string) => {

                convertedData = translations;
            });
            this.translate.get('DASHBOARD.SOME_DAYS', { value: days.toString() }).subscribe((translations: string) => {

                convertedData = convertedData + ' ' + translations;
            });
            return convertedData;
        }

    }
}
