import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { AppEnvConfig } from '../../app.env.config';
import { SharedServiceConfig } from './shared-services.service.config';


@Injectable()
export class ConversionUtilityService {
    configEnv: any;

    url: string;


    constructor(private httpClient: HttpClient,
        private sharedServiceConfig: SharedServiceConfig,
        private config: AppEnvConfig) {
        this.configEnv = sharedServiceConfig.getEnvironment();
        this.configEnv.apiServerUrl = config.getEnv('apiServerUrl');
    }


    public getCurrencyData(): Observable<any> {
        this.url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getCurrencies}`;
        return this.httpClient.get(this.url);
    }

    public getConversionFactor(fromCurrency, toCurrency): Observable<number> {
        this.url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getFactor + '/' + fromCurrency + '/' + toCurrency}`;
        return this.httpClient.get(this.url).map((response: number) => response);
    }
}
