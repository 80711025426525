import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'nalco-threshold-popup',
  templateUrl: './threshold-popup.component.html',
  styleUrls: ['./threshold-popup.component.scss']
})

export class ThresholdPopupComponent implements OnInit {
  constructor(private translate: TranslateService, public activeModal: NgbActiveModal) { }
  ngOnInit() { }
}
