import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CompositeFilterDescriptor, SortDescriptor, State } from '@progress/kendo-data-query';
import { IFIRefreshRoutes } from '../contracts/IProjectDetails';

export const mainUrl = 'financial-impact';
export const navigationUrl: Array<IFIRefreshRoutes> = [{
    name: 'Settings',
    url: '/admin-settings'
}, {
    name: 'ValueLisitngs',
    url: '/value-listings'
}
];

export const preferenceType = {
    Corporate: '0',
    Personal: '1'
};

export const StageType = [
    {
        name: 'FINANCIAL_IMPACT.STAGE_TYPES.ALL',
        id: 0
    },
    {
        name: 'FINANCIAL_IMPACT.STAGE_TYPES.IDENTIFIED',
        id: 1
    },
    {
        name: 'FINANCIAL_IMPACT.STAGE_TYPES.APPROVED',
        id: 2
    },
    {
        name: 'FINANCIAL_IMPACT.STAGE_TYPES.IN_PROCESS',
        id: 3
    },
    {
        name: 'FINANCIAL_IMPACT.STAGE_TYPES.IMPLEMENTED',
        id: 4
    },
    {
        name: 'FINANCIAL_IMPACT.STAGE_TYPES.FINAL_SIGN',
        id: 5
    },
];
export const Division = 'Division ';
export const DivisionBy = 'Division By :  ';
export const AllLabel = 'All';
export const ValueChannel = 'ValueChannel';
export const NalcoWaterCode = 'WT';
export const UserAndCorporateCurrency = 'userAndCorporateCurrency'
export const UserCurrency = "userCurrency"
export const DefaultNalcoWaterBusinessUnitCodes = ['WT', 'PA', 'DS', 'EN'];

export enum EroiValueTypeEnum {
    CostAndBenefit = -1,
    Air = 1,
    Assets = 2,
    ProductivityChemistryOrProduct = 3,
    ElectricalEnergy = 4,
    FoodSafety = 5,
    ProductQuality = 6,
    Productivity = 7,
    Profitability = 8,
    ProductivityPlantOrLocation = 9,
    Safety = 10,
    ThermalEnergy = 11,
    Waste = 12,
    Water = 13,
    AirQuality = 14,
    BrandProtectionOrEnhancement = 15,
    ComplianceRegulatoryOrIndustry = 16,
    Reliability = 17,
    SafetyWater = 18,
    RiskReduction = 19,
    ProductivityRawMaterials = 20
}

export type CostAndBenefit = EroiValueTypeEnum.CostAndBenefit;
export type Waste = EroiValueTypeEnum.Waste;
export type ThermalEnergy = EroiValueTypeEnum.ThermalEnergy;
export type Water = EroiValueTypeEnum.Water;

export const EROIValueType = [
    {
        text: 'FINANCIAL_IMPACT.PROJECT_TABLE.COST_AND_BENEFIT',
        type: 'Cost & benefit',
        value: '-1',
        typeName: EroiValueTypeEnum.CostAndBenefit,
        typeColor: '#B91A37'
    },
    {
        text: 'FINANCIAL_IMPACT.PROJECT_TABLE.GREENHOUSE_GASES',
        type: 'Greenhouse Gases',
        value: '1',
        typeName: EroiValueTypeEnum.Air,
        typeColor: '#7A7A00'
    },
    {
        text: 'FINANCIAL_IMPACT.PROJECT_TABLE.CAPACITY_ASSET_PROTECTION',
        type: 'Capacity - Asset Protection',
        value: '2',
        typeName: EroiValueTypeEnum.Assets,
        typeColor: '#006172'
    },
    {
        text: 'FINANCIAL_IMPACT.PROJECT_TABLE.PRODUCTIVITY_PRODUCT',
        type: 'Productivity - Product',
        value: '3',
        typeName: EroiValueTypeEnum.ProductivityChemistryOrProduct,
        typeColor: '#640078'
    },
    {
        text: 'FINANCIAL_IMPACT.PROJECT_TABLE.ENERGY_ELECTRICAL',
        type: 'Energy - Electrical',
        value: '4',
        typeName: EroiValueTypeEnum.ElectricalEnergy,
        typeColor: '#5E5E00'
    },
    {
        text: 'FINANCIAL_IMPACT.PROJECT_TABLE.SAFETY_FOOD',
        type: 'Safety - Food',
        value: '5',
        typeName: EroiValueTypeEnum.FoodSafety,
        typeColor: '#368500'
    },
    {
        text: 'FINANCIAL_IMPACT.PROJECT_TABLE.QUALITY_PRODUCT',
        type: 'Quality - Product',
        value: '6',
        typeName: EroiValueTypeEnum.ProductQuality,
        typeColor: '#008666'
    },
    {
        text: 'FINANCIAL_IMPACT.PROJECT_TABLE.PRODUCTIVITY_LABOR',
        type: 'Productivity - Labor',
        value: '7',
        typeName: EroiValueTypeEnum.Productivity,
        typeColor: '#BD00E4'
    },
    {
        text: 'FINANCIAL_IMPACT.PROJECT_TABLE.PRODUCTIVITY_LOCATION',
        type: 'Productivity - Location',
        value: '9',
        typeName: EroiValueTypeEnum.ProductivityPlantOrLocation,
        typeColor: '#3D004A'
    },
    {
        text: 'FINANCIAL_IMPACT.PROJECT_TABLE.SAFETY_HUMAN',
        type: 'Safety - Human',
        value: '10',
        typeName: EroiValueTypeEnum.Safety,
        typeColor: '#2E6900'
    },
    {
        text: 'FINANCIAL_IMPACT.PROJECT_TABLE.ENERGY_THERMAL',
        type: 'Energy - Thermal',
        value: '11',
        typeName: EroiValueTypeEnum.ThermalEnergy,
        typeColor: '#343200'
    },
    {
        text: 'FINANCIAL_IMPACT.PROJECT_TABLE.WASTE_SOLID',
        type: 'Waste - Solid',
        value: '12',
        typeName: EroiValueTypeEnum.Waste,
        typeColor: '#AA4800'
    },
    {
        text: 'FINANCIAL_IMPACT.PROJECT_TABLE.WATER',
        type: 'Water',
        value: '13',
        typeName: EroiValueTypeEnum.Water,
        typeColor: '#0040CE'
    },
    {
        text: 'ACTION_LOG.WASTE_AIR_EMISSIONS',
        type: 'Waste - Air Emissions',
        value: '14',
        typeName: EroiValueTypeEnum.AirQuality,
        typeColor: '#783200'
    },
    {
        text: 'ACTION_LOG.BRAND_PROTECTION',
        type: 'Brand - Protection',
        value: '15',
        typeName: EroiValueTypeEnum.BrandProtectionOrEnhancement,
        typeColor: '#C9006F'
    },
    {
        text: 'ACTION_LOG.COMPLIANCE_REGULATORY/INDUSTRY',
        type: 'Compliance -Regulatory/Industry',
        value: '16',
        typeName: EroiValueTypeEnum.ComplianceRegulatoryOrIndustry,
        typeColor: '#830022'
    },
    {
        text: 'ACTION_LOG.CAPACITY_RELIABILITY',
        type: 'Capacity - Reliability',
        value: '17',
        typeName: EroiValueTypeEnum.Reliability,
        typeColor: '#007EA8'
    },
    {
        text: 'ACTION_LOG.SAFETY_WATER',
        type: 'Safety - Water',
        value: '18',
        typeName: EroiValueTypeEnum.SafetyWater,
        typeColor: '#244D00'
    },
    {
        text: 'ACTION_LOG.PRODUCTIVITY_RAW_MATERIAL',
        type: 'Productivity - Raw Materials',
        value: '20',
        typeName: EroiValueTypeEnum.ProductivityRawMaterials,
        typeColor: '#8A009C'
    }
];

export const ProjectTableColumns = [
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_TABLE.SITE_NAME',
        'defaultActive': true,
        'columnOrder': 1,
        'displayFormat': 'Col',
        'isLocked': true,
        'field': 'SiteName',
        'width': '200'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_TABLE.PROGRAM_NAME',
        'defaultActive': true,
        'columnOrder': 2,
        'displayFormat': 'Col',
        'isLocked': true,
        'field': 'ProjectName',
        'width': '200'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_TABLE.STAGE',
        'defaultActive': true,
        'columnOrder': 3,
        'displayFormat': 'Col',
        'isLocked': true,
        'field': 'ProjectStage',
        'width': '100'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_TABLE.TOTAL_SAVINGS',
        'defaultActive': false,
        'columnOrder': 4,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'TotalSavings'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_TABLE.TVD_SAVINGS',
        'defaultActive': false,
        'columnOrder': 5,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'TVDSavings'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_TABLE.PIPELINE_SAVINGS',
        'defaultActive': false,
        'columnOrder': 6,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'PipelineSavings'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_TABLE.POTENTIAL_SAVINGS',
        'defaultActive': false,
        'columnOrder': 7,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'PotentialSavings'
    },
    /** Splitting the Stage and Days in stage into two separate columns */
    {
        'title': 'FINANCIAL_IMPACT.ACCORDING_TO_TYPES.DATE_PROGRAM_LAST_UPDATED',
        'defaultActive': false,
        'columnOrder': 8,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'ModifiedOn',
        'width': '100'
    },
    /*
    {
      'title': 'Stage',
      'defaultActive': false,
      'columnOrder': null,
      'displayFormat': 'Col',
      'isLocked': true
    },
    {
      'title': 'Days in Stage',
      'defaultActive': false,
      'columnOrder': null,
      'displayFormat': 'Col',
      'isLocked': true
    },*/
    {
        'title': 'FINANCIAL_IMPACT.ACCORDING_TO_TYPES.DATE_IDENTIFIED',
        'defaultActive': false,
        'columnOrder': 9,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'ProjectIdentifiedDate',
        'width': '100'
    },
    {
        'title': 'FINANCIAL_IMPACT.ACCORDING_TO_TYPES.DATE_APPROVED',
        'defaultActive': false,
        'columnOrder': 10,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'CustomerApprovalDate',
        'width': '100'
    },
    {
        'title': 'FINANCIAL_IMPACT.ACCORDING_TO_TYPES.DATE_IN_PROCESS',
        'defaultActive': false,
        'columnOrder': 11,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'ActualStartDate',
        'width': '100'
    },
    {
        'title': 'FINANCIAL_IMPACT.ACCORDING_TO_TYPES.DATE_IMPLEMENTED',
        'defaultActive': false,
        'columnOrder': 12,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'ActualImplementationDate',
        'width': '100'
    },
    {
        'title': 'FINANCIAL_IMPACT.ACCORDING_TO_TYPES.DATE_SIGNED_OFF',
        'defaultActive': false,
        'columnOrder': 13,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'SignOffDate',
        'width': '100'
    },
    // added above - the additional datestamp columns
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_TABLE.PROGRAM_TYPE',
        'defaultActive': false,
        'columnOrder': 14,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'ProjectType'
    },
    // adding the new CRM fields
    // {
    //     'title': 'FINANCIAL_IMPACT.PROJECT_TABLE.CUSTOMER_SIGNOFF_DOCUMENT_EXISTS',
    //     'defaultActive': false,
    //     'columnOrder': 11,
    //     'displayFormat': 'Col',
    //     'isLocked': false,
    //     'field': 'CustomerSignOffDocumentationExists'
    // },
    // {
    //     'title': 'FINANCIAL_IMPACT.PROJECT_TABLE.PROJECT_CHARTER_EXISTS',
    //     'defaultActive': false,
    //     'columnOrder': 12,
    //     'displayFormat': 'Col',
    //     'isLocked': false,
    //     'field': 'ProjectCharterExists'
    // },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_TABLE.CIP_UTILIZED',
        'defaultActive': false,
        'columnOrder': 15,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'CIPUtilized'
    },

    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.WATER_SAVINGS',
        'defaultActive': false,
        'columnOrder': 16,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'WaterSavings'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.WATER_AMOUNT',
        'defaultActive': false,
        'columnOrder': 17,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'WaterAmount'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.ENERGY_ELECTRICAL_SAVINGS',
        'defaultActive': false,
        'columnOrder': 18,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'EnergyElectricalSavings',
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.ENERGY_ELECTRICAL_AMOUNT',
        'defaultActive': false,
        'columnOrder': 19,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'EnergyElectricalAmount'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.ENERGY_THERMAL_SAVINGS',
        'defaultActive': false,
        'columnOrder': 20,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'EnergyThermalSavings'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.ENERGY_THERMAL_AMOUNT',
        'defaultActive': false,
        'columnOrder': 21,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'EnergyThermalAmount'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.WASTE_SOLID_SAVINGS',
        'defaultActive': false,
        'columnOrder': 22,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'WasteSolidSavings'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.WASTE_SOLID_AMOUNT',
        'defaultActive': false,
        'columnOrder': 23,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'WasteSolidAmount'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.PRODUCTIVITY_LOCATION_SAVINGS',
        'defaultActive': false,
        'columnOrder': 24,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'ProductivityLocationSavings'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.PRODUCTIVITY_LOCATION_AMOUNT',
        'defaultActive': false,
        'columnOrder': 25,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'ProductivityLocationAmount'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.GREENHOUSE_GASES_SAVINGS',
        'defaultActive': false,
        'columnOrder': 26,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'GreenhouseGasesSavings'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.GREENHOUSE_GASES_AMOUNT',
        'defaultActive': false,
        'columnOrder': 27,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'GreenhouseGasesAmount'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.CAPACITY_ASSET_PROTECTION_SAVINGS',
        'defaultActive': false,
        'columnOrder': 28,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'CapacityAssetProtectionSavings'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.CAPACITY_ASSET_PROTECTION_AMOUNT',
        'defaultActive': false,
        'columnOrder': 29,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'CapacityAssetProtectionAmount'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.PRODUCTIVITY_PRODUCT_SAVINGS',
        'defaultActive': false,
        'columnOrder': 30,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'ProductivityProductSavings'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.PRODUCTIVITY_PRODUCT_AMOUNT',
        'defaultActive': false,
        'columnOrder': 31,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'ProductivityProductAmount'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.SAFETY_FOOD_SAVINGS',
        'defaultActive': false,
        'columnOrder': 32,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'SafetyFoodSavings'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.QUALITY_PRODUCT_SAVINGS',
        'defaultActive': false,
        'columnOrder': 33,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'QualityProductSavings'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.QUALITY_PRODUCT_AMOUNT',
        'defaultActive': false,
        'columnOrder': 34,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'QualityProductAmount'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.PRODUCTIVITY_LABOR_SAVINGS',
        'defaultActive': false,
        'columnOrder': 35,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'ProductivityLaborSavings'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.PRODUCTIVITY_LABOR_AMOUNT',
        'defaultActive': false,
        'columnOrder': 36,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'ProductivityLaborAmount'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.SAFETY_HUMAN_SAVINGS',
        'defaultActive': false,
        'columnOrder': 37,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'SafetyHumanSavings'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.COST_AND_BENEFIT_SAVINGS',
        'defaultActive': false,
        'columnOrder': 38,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'CostAndBenefitSavings'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.WASTE_AIR_EMISSIONS_SAVINGS',
        'defaultActive': false,
        'columnOrder': 39,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'WasteAirEmissionSavings'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.BRAND_PROTECTION_SAVINGS',
        'defaultActive': false,
        'columnOrder': 40,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'BrandProtectionSavings'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.COMPLIANCE_REGULATORY/INDUSTRY_SAVINGS',
        'defaultActive': false,
        'columnOrder': 41,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'ComplianceRegulatorySavings'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.CAPACITY_RELIABILITY_SAVINGS',
        'defaultActive': false,
        'columnOrder': 42,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'CapacityReliabilitySavings'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.SAFETY_WATER_SAVINGS',
        'defaultActive': false,
        'columnOrder': 43,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'SafetyWaterSavings'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.PRODUCTIVITY_RAW_MATERIAL_SAVINGS',
        'defaultActive': false,
        'columnOrder': 44,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'ProductivityRawMaterialSavings'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.PRODUCTIVITY_RAW_MATERIAL_AMOUNT',
        'defaultActive': false,
        'columnOrder': 45,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'ProductivityRawMaterialAmount'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_TABLE.PROGRAM_BENEFIT',
        'defaultActive': false,
        'columnOrder': 46,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'ProjectBenefits'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_TABLE.CURRENT_STATUS',
        'defaultActive': false,
        'columnOrder': 47,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'CurrentStatus',
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_TABLE.NALCO_CONTACT',
        'defaultActive': false,
        'columnOrder': 48,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'RepFirstName',
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_TABLE.CUSTOMER_CONTACT',
        'defaultActive': false,
        'columnOrder': 49,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'ResCustomerFirstName',
    }
];

export const ActionLogTableColumns = [

    {
        'title': 'ACTION_LOG.HEADER.SITE',
        'defaultActive': true,
        'columnOrder': 1,
        'displayFormat': 'Col',
        'isLocked': true,
        'field': 'SiteName',
        'width': '200'
    },
    {
        'title': 'ACTION_LOG.ASSETS',
        'defaultActive': true,
        'columnOrder': 2,
        'displayFormat': 'Col',
        'isLocked': true,
        'field': 'AssetName',
        'width': '200'
    },
    {
        'title': 'QUALITY.COLUMN_TITLE.SOURCE',
        'defaultActive': false,
        'columnOrder': 3,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'SourceApplicationType',
    },
    {
        'title': 'ACTION_LOG.HEADER.ACTION_STATUS',
        'defaultActive': true,
        'columnOrder': 4,
        'displayFormat': 'Col',
        'isLocked': true,
        'field': 'ActionStatus',
        'width': '100'
    },
    {
        'title': 'ACTION_LOG.HEADER.TOTAL_SAVINGS',
        'defaultActive': false,
        'columnOrder': 5,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'TotalSavings'
    },
    {
        'title': 'ACTION_LOG.HEADER.EROI_CATEGORY',
        'defaultActive': false,
        'columnOrder': 6,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'EROIValueCategory'
    },
    {
        'title': 'ACTION_LOG.HEADER.ANNUAL_SAVINGS_TYPE',
        'defaultActive': false,
        'columnOrder': 7,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'AnnualSavingsType'
    },
    {
        'title': 'ACTION_LOG.HEADER.TOTAL_AMOUNT',
        'defaultActive': false,
        'columnOrder': 8,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'TotalUnitAmount'
    },

    {
        'title': 'ACTION_LOG.HEADER.IMPACT',
        'defaultActive': true,
        'columnOrder': 9,
        'displayFormat': 'Col',
        'isLocked': true,
        'field': 'ActionImpact',
        'width': '100'
    },
    {
        'title': 'ACTION_LOG.HEADER.RECOMMENDATION_DATE',
        'defaultActive': true,
        'columnOrder': 10,
        'displayFormat': 'Col',
        'isLocked': true,
        'field': 'RecommendationDate',
        'width': '100'
    },
    {
        'title': 'ACTION_LOG.HEADER.EXPECTED_RESOLUTION_DATE',
        'defaultActive': false,
        'columnOrder': 11,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'ResolutionDate',
        'width': '100'
    },
    {
        'title': 'ACTION_LOG.HEADER.RESPONSIBLE_PERSON',
        'defaultActive': false,
        'columnOrder': 12,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'ResponsiblePerson',
        'width': '100'
    },
    {
        'title': 'ACTION_LOG.HEADER.ACTION_CREATED_BY',
        'defaultActive': false,
        'columnOrder': 13,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'CreatedByUser',
        'width': '100'
    },
    {
        'title': 'ACTION_LOG.HEADER.PROBLEM',
        'defaultActive': false,
        'columnOrder': 14,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'Problem',
        'width': '100'
    },
    {
        'title': 'ACTION_LOG.HEADER.RECOMMENDED_ACTION',
        'defaultActive': false,
        'columnOrder': 15,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'RecommendedAction',
        'width': '100'
    },
    {
        'title': 'ACTION_LOG.HEADER.MOST_RECENT_ACTION',
        'defaultActive': false,
        'columnOrder': 16,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'RecentActionTaken',
        'width': '100'
    },
    {
        'title': 'ACTION_LOG.HEADER.MOST_RECENT_ACTION_DATE',
        'defaultActive': false,
        'columnOrder': 17,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'RecentActionTakenDate'
    },


]
export const defaultCurrency = 'USD';
export const UnitsOfMeasure = 'USStandard';
export const UOM = 'UOM';
export const Currency = {
    CurrencyCodeId: 'USD',
    CurrencyName: 'US Dollar',
    Symbol: '$',
    Name: 'Currency'
}


export const UOMOptions = [
    {
        text: 'FINANCIAL_IMPACT.UOM_OPTIONS.US',
        value: '0'
    },
    {
        text: 'FINANCIAL_IMPACT.UOM_OPTIONS.METRIC',
        value: '1'
    }
]

export const filterConstant: CompositeFilterDescriptor = {
    logic: 'and',
    filters: []
};

export const sortingConstant: SortDescriptor[] = [{
    field: 'RecommendationDate',
    dir: 'desc'
}];

export const stateConstent: State = {
    skip: 0,
    take: 10,
    sort: [
        { field: 'RecommendationDate', dir: 'desc' }
    ]
};
export const pageSizesConstant = [10, 20, 50, 100];

export const actionStatusNameConstant = {
    'Open': 'ACTION_LOG.OPEN', 'Closed': 'ACTION_LOG.CLOSED',
    'Deferred': 'ACTION_LOG.DEFERRED', 'All': 'ACTION_LOG.ALL'
};

export const impactTypeNameConstant = {
    'Safety': 'ACTION_LOG.SAFETY', 'High': 'ACTION_LOG.HIGH',
    'Medium': 'ACTION_LOG.MEDIUM', 'Low': 'ACTION_LOG.LOW'
};

export const BusinessUnit = 'WT';
export const AccordingToFieldConstant = 'ProjectIdentifiedDate';
export const sitePageFlag = true;

// sorting order for ImpactName
export const impactSortingOrder = {
    'Safety': 1,
    'High': 2,
    'Medium': 3,
    'Low': 4
};

// sorting order for Asset Status
export const assetStatusSortingOrder = {
    'Red': 1,
    'Yellow': 2,
    'Green': 3,
    'Grey': 4
};

export const NgbModalConstant: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    windowClass: 'right-side-modal',
};
export const NgbModalCustomizeConstant: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    windowClass: 'customize-center-modal'
}


export const ProjectExcelColumns = [
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_TABLE.SITE_NAME',
        'defaultActive': true,
        'columnOrder': 1,
        'displayFormat': 'Col',
        'isLocked': true,
        'field': 'SiteName',
        'width': '200'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_TABLE.PROGRAM_NAME',
        'defaultActive': true,
        'columnOrder': 2,
        'displayFormat': 'Col',
        'isLocked': true,
        'field': 'ProjectName',
        'width': '200'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_TABLE.STAGE',
        'defaultActive': true,
        'columnOrder': 3,
        'displayFormat': 'Col',
        'isLocked': true,
        'field': 'ProjectStage',
        'width': '100'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_TABLE.TOTAL_SAVINGS',
        'defaultActive': false,
        'columnOrder': 4,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'TotalSavings'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_TABLE.TVD_SAVINGS',
        'defaultActive': false,
        'columnOrder': 5,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'TVDSavings'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_TABLE.PIPELINE_SAVINGS',
        'defaultActive': false,
        'columnOrder': 6,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'PipelineSavings'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_TABLE.POTENTIAL_SAVINGS',
        'defaultActive': false,
        'columnOrder': 7,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'PotentialSavings'
    },
    {
        'title': 'FINANCIAL_IMPACT.ACCORDING_TO_TYPES.DATE_PROJECT_LAST_UPDATED',
        'defaultActive': false,
        'columnOrder': 8,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'ModifiedOn',
        'width': '100'
    },
    {
        'title': 'FINANCIAL_IMPACT.ACCORDING_TO_TYPES.DATE_IDENTIFIED',
        'defaultActive': false,
        'columnOrder': 9,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'ProjectIdentifiedDate',
        'width': '100'
    },
    {
        'title': 'FINANCIAL_IMPACT.ACCORDING_TO_TYPES.DATE_APPROVED',
        'defaultActive': false,
        'columnOrder': 10,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'CustomerApprovalDate',
        'width': '100'
    },
    {
        'title': 'FINANCIAL_IMPACT.ACCORDING_TO_TYPES.DATE_IN_PROCESS',
        'defaultActive': false,
        'columnOrder': 11,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'ActualStartDate',
        'width': '100'
    },
    {
        'title': 'FINANCIAL_IMPACT.ACCORDING_TO_TYPES.DATE_IMPLEMENTED',
        'defaultActive': false,
        'columnOrder': 12,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'ActualImplementationDate',
        'width': '100'
    },
    {
        'title': 'FINANCIAL_IMPACT.ACCORDING_TO_TYPES.DATE_SIGNED_OFF',
        'defaultActive': false,
        'columnOrder': 13,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'SignOffDate',
        'width': '100'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_TABLE.PROGRAM_TYPE',
        'defaultActive': false,
        'columnOrder': 14,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'ProjectType'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_TABLE.CIP_UTILIZED',
        'defaultActive': false,
        'columnOrder': 15,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'CIPUtilized'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.WATER_SAVINGS',
        'defaultActive': false,
        'columnOrder': 16,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'WaterSavings'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.WATER_AMOUNT',
        'defaultActive': false,
        'columnOrder': 17,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'WaterAmount'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.ENERGY_ELECTRICAL_SAVINGS',
        'defaultActive': false,
        'columnOrder': 18,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'EnergyElectricalSavings',
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.ENERGY_ELECTRICAL_AMOUNT',
        'defaultActive': false,
        'columnOrder': 19,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'EnergyElectricalAmount'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.ENERGY_THERMAL_SAVINGS',
        'defaultActive': false,
        'columnOrder': 20,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'EnergyThermalSavings'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.ENERGY_THERMAL_AMOUNT',
        'defaultActive': false,
        'columnOrder': 21,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'EnergyThermalAmount'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.WASTE_SOLID_SAVINGS',
        'defaultActive': false,
        'columnOrder': 22,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'WasteSolidSavings'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.WASTE_SOLID_AMOUNT',
        'defaultActive': false,
        'columnOrder': 23,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'WasteSolidAmount'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.PRODUCTIVITY_LOCATION_SAVINGS',
        'defaultActive': false,
        'columnOrder': 24,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'ProductivityLocationSavings'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.PRODUCTIVITY_LOCATION_AMOUNT',
        'defaultActive': false,
        'columnOrder': 25,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'ProductivityLocationAmount'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.PRODUCTIVITY_LOCATION_AMOUNT',
        'defaultActive': false,
        'columnOrder': 25,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'ProductivityLocationAmount2'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.GREENHOUSE_GASES_SAVINGS',
        'defaultActive': false,
        'columnOrder': 26,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'GreenhouseGasesSavings'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.GREENHOUSE_GASES_AMOUNT',
        'defaultActive': false,
        'columnOrder': 27,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'GreenhouseGasesAmount'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.CAPACITY_ASSET_PROTECTION_SAVINGS',
        'defaultActive': false,
        'columnOrder': 28,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'CapacityAssetProtectionSavings'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.CAPACITY_ASSET_PROTECTION_AMOUNT',
        'defaultActive': false,
        'columnOrder': 29,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'CapacityAssetProtectionAmount'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.PRODUCTIVITY_PRODUCT_SAVINGS',
        'defaultActive': false,
        'columnOrder': 30,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'ProductivityProductSavings'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.PRODUCTIVITY_PRODUCT_AMOUNT',
        'defaultActive': false,
        'columnOrder': 31,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'ProductivityProductAmount'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.PRODUCTIVITY_PRODUCT_AMOUNT',
        'defaultActive': false,
        'columnOrder': 31,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'ProductivityProductAmount2'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.SAFETY_FOOD_SAVINGS',
        'defaultActive': false,
        'columnOrder': 32,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'SafetyFoodSavings'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.QUALITY_PRODUCT_SAVINGS',
        'defaultActive': false,
        'columnOrder': 33,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'QualityProductSavings'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.QUALITY_PRODUCT_AMOUNT',
        'defaultActive': false,
        'columnOrder': 34,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'QualityProductAmount'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.QUALITY_PRODUCT_AMOUNT',
        'defaultActive': false,
        'columnOrder': 34,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'QualityProductAmount2'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.PRODUCTIVITY_LABOR_SAVINGS',
        'defaultActive': false,
        'columnOrder': 35,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'ProductivityLaborSavings'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.PRODUCTIVITY_LABOR_AMOUNT',
        'defaultActive': false,
        'columnOrder': 36,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'ProductivityLaborAmount'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.SAFETY_HUMAN_SAVINGS',
        'defaultActive': false,
        'columnOrder': 37,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'SafetyHumanSavings'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.COST_AND_BENEFIT_SAVINGS',
        'defaultActive': false,
        'columnOrder': 38,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'CostAndBenefitSavings'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.WASTE_AIR_EMISSIONS_SAVINGS',
        'defaultActive': false,
        'columnOrder': 39,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'WasteAirEmissionSavings'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.BRAND_PROTECTION_SAVINGS',
        'defaultActive': false,
        'columnOrder': 40,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'BrandProtectionSavings'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.COMPLIANCE_REGULATORY/INDUSTRY_SAVINGS',
        'defaultActive': false,
        'columnOrder': 41,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'ComplianceRegulatorySavings'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.CAPACITY_RELIABILITY_SAVINGS',
        'defaultActive': false,
        'columnOrder': 42,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'CapacityReliabilitySavings'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.SAFETY_WATER_SAVINGS',
        'defaultActive': false,
        'columnOrder': 43,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'SafetyWaterSavings'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.PRODUCTIVITY_RAW_MATERIAL_SAVINGS',
        'defaultActive': false,
        'columnOrder': 44,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'ProductivityRawMaterialSavings'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_COLUMNS_FILTER.PRODUCTIVITY_RAW_MATERIAL_AMOUNT',
        'defaultActive': false,
        'columnOrder': 45,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'ProductivityRawMaterialAmount'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_TABLE.PROJECT_BENEFIT',
        'defaultActive': false,
        'columnOrder': 46,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'ProjectBenefits'
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_TABLE.CURRENT_STATUS',
        'defaultActive': false,
        'columnOrder': 47,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'CurrentStatus',
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_TABLE.NALCO_CONTACT',
        'defaultActive': false,
        'columnOrder': 48,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'RepFirstName',
    },
    {
        'title': 'FINANCIAL_IMPACT.PROJECT_TABLE.CUSTOMER_CONTACT',
        'defaultActive': false,
        'columnOrder': 49,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'ResCustomerFirstName',
    }

];

export const eROITypeList = ['Safety - Food', 'ProductQuality', 'Safety - Human'];

export const dateTypes = ['ModifiedOn', 'ProjectIdentifiedDate', 'CustomerApprovalDate', 'ActualStartDate', 'ActualImplementationDate', 'SignOffDate'];

export const sheetNames = ['TVD Program', 'Action & Recommendations', 'Automated TVD'];

export const ValueChannels = [
    {
        Id: 7,
        Type: 'All',
        Name: 'All',
        SortOrder: 1
    },
    {
        Id: 1,
        Type: 'CmvProjects',
        Name: 'TVD Program',
        SortOrder: 2
    },
    {
        Id: 2,
        Type: 'ActionLog',
        Name: 'Action Log',
        SortOrder: 3
    },
    {
        Id: 4,
        Type: 'AutomatedTVD',
        Name: 'AutomatedTVD',
        SortOrder: 4
    },
];

export enum SourceApplicationTypeId {
    None = -1,
    Ecolab3D = 0,
    NalcoCRM = 1,
    AlarmManagerApp = 2,
    TcdCRM = 3,
    AI = 4,
}

export const quickFilterList = ['Time Range', 'Division', 'Value Channel', 'Annual Savings Type', 'Currency', 'Unit'];

export const programsColumnsDeleteKeys = ['SiteId', 'FinancialImpactProjectId', 'CorporateCpId', 'CorporateCpName', 'QualityProductAmountUnitSymbol2', 'ProductivityProductAmountUnitSymbol2', 'ProductivityLocationAmountUnitSymbol2'];

export const GridName = {
    ProjectTable: 'ProjectTable',
    SSDProgramGrid: 'SSDProgramGrid',
    ActionLogAndRecommendation : 'ActionLogAndRecommendation',
    AutomatedTVDSavings: 'AutomatedTVDSavings'
}

export enum ActionLogInfoType
{
	RecommendationInfo = 1,
	ActionsTaken = 2,
	Attachments = 4,
	Financials = 8,
	RecommendationCounts = 16,
	RecommendationInfoAndCounts = RecommendationInfo | RecommendationCounts, // 17
	ActionLogWithFinancials = RecommendationInfo | ActionsTaken | Attachments | Financials, // 15
	All = RecommendationInfo | ActionsTaken | Attachments | Financials | RecommendationCounts  // 31
}

export enum ActionStatusType
{
     Deferred = 1,
     Open = 2,
     Closed = 4,
     All = Deferred | Open | Closed,
}

export enum TimeRangeOptionValue {
    All = '0',
    CurrentYear = '1',
    PreviousYear = '2',
    BothYears = '3',
    ContractStart = '4',
    Custom = '5'
}

export const TimeRangeFilterOptions = [
    {
        text: 'FINANCIAL_IMPACT.TIME_RANGE.ALL',
        value: TimeRangeOptionValue.All,
        isSelected: false
    },
    {
        text: 'FINANCIAL_IMPACT.TIME_RANGE.CURRENT_YEAR',
        value: TimeRangeOptionValue.CurrentYear,
        isSelected: false
    },
    {
        text: 'FINANCIAL_IMPACT.TIME_RANGE.PREVIOUS_YEAR',
        value: TimeRangeOptionValue.PreviousYear,
        isSelected: false
    },
    {
        text: 'FINANCIAL_IMPACT.TIME_RANGE.BOTH_YEARS',
        value: TimeRangeOptionValue.BothYears,
        isSelected: false
    },
    {
        text: 'FINANCIAL_IMPACT.TIME_RANGE.CONTRACT_START',
        value: TimeRangeOptionValue.ContractStart,
        isSelected: false
    },
    {
        text: 'FINANCIAL_IMPACT.TIME_RANGE.CUSTOM',
        value: TimeRangeOptionValue.Custom,
        isSelected: false
    }
]

export const defaultTimeRangeFilterOptions = [
    {
        text: 'FINANCIAL_IMPACT.TIME_RANGE.ALL',
        value: TimeRangeOptionValue.All,
        isSelected: true
    },
    {
        text: 'FINANCIAL_IMPACT.TIME_RANGE.CUSTOM',
        value: TimeRangeOptionValue.Custom,
        isSelected: false
    }
]

export const AutomatedTVDSavingsColumns = [

    {
        'title': 'ACTION_LOG.HEADER.SITE',
        'defaultActive': true,
        'columnOrder': 1,
        'displayFormat': 'Col',
        'isLocked': true,
        'field': 'SiteName',
        'width': '200'
    },
    {
        'title': 'FINANCIAL_IMPACT.AUTOMATED_TVD_SAVINGS.CALCULATOR_TYPE',
        'defaultActive': true,
        'columnOrder': 2,
        'displayFormat': 'Col',
        'isLocked': true,
        'field': 'calculatorType',
        'width': '200'
    },
    {
        'title': 'FINANCIAL_IMPACT.AUTOMATED_TVD_SAVINGS.ASSET_TYPE',
        'defaultActive': true,
        'columnOrder': 3,
        'displayFormat': 'Col',
        'isLocked': true,
        'field': 'assetType',
    },
    {
        'title': 'FINANCIAL_IMPACT.AUTOMATED_TVD_SAVINGS.ASSET',
        'defaultActive': true,
        'columnOrder': 4,
        'displayFormat': 'Col',
        'isLocked': true,
        'field': 'Asset',
        'width': '100'
    },
    {
        'title': 'FINANCIAL_IMPACT.AUTOMATED_TVD_SAVINGS.TVD_SAVINGS',
        'defaultActive': true,
        'columnOrder': 5,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'TVDSavings'
    },
    {
        'title': 'FINANCIAL_IMPACT.AUTOMATED_TVD_SAVINGS.GAP_TO_OPTIMAL',
        'defaultActive': true,
        'columnOrder': 6,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'gaptoOptimal'
    },

    {
        'title':'FINANCIAL_IMPACT.AUTOMATED_TVD_SAVINGS.OPTIMAL_TARGET_VALUE',
        'defaultActive': true,
        'columnOrder': 7,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'optimalTargetValue',
        'width': '100'
    },
    {
        'title': 'FINANCIAL_IMPACT.AUTOMATED_TVD_SAVINGS.BASELINE_EFFECTIVE_DATE',
        'defaultActive': true,
        'columnOrder': 8,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'baselineEffectiveDate',
        'width': '100'
    },
    {
        'title': 'FINANCIAL_IMPACT.AUTOMATED_TVD_SAVINGS.LAST_UPDATED_DATE',
        'defaultActive': true,
        'columnOrder': 9,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'lastUpdatedDate',
        'width': '100'
    },
    {
        'title': 'FINANCIAL_IMPACT.AUTOMATED_TVD_SAVINGS.WATER_SAVINGS',
        'defaultActive': true,
        'columnOrder': 10,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'waterSavings',
        'width': '100'
    },
    {
        'title': 'FINANCIAL_IMPACT.AUTOMATED_TVD_SAVINGS.WATER_AMOUNT',
        'defaultActive': true,
        'columnOrder': 11,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'waterUnits',
        'width': '100'
    },
]

export const HiddenAutomatedTVDColumns = [
    {
        'title': 'FINANCIAL_IMPACT.AUTOMATED_TVD_SAVINGS.COST_SAVINGS',
        'defaultActive': false,
        'columnOrder': 1,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'CostSavings'
    }, {
        'title': 'FINANCIAL_IMPACT.AUTOMATED_TVD_SAVINGS.COST_AVOIDANCE',
        'defaultActive': false,
        'columnOrder': 2,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'CostAvoidance'
    }
];

export const MyTerritoryViewColumns = [
    {
        'title': 'ACTION_LOG.HEADER.COMPANY_NAME',
        'defaultActive': false,
        'columnOrder': 1,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'CorporateName'
    },
    {
        'title': 'ACTION_LOG.HEADER.SYSTEM',
        'defaultActive': false,
        'columnOrder': 2,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'System'
    },
    {
        'title': 'ACTION_LOG.HEADER.ACTIONS',
        'defaultActive': false,
        'columnOrder': 3,
        'displayFormat': 'Col',
        'isLocked': false,
        'field': 'Actions'
    }
]

enum FISCAL_PERIOD_ITEMS {
  JAN_DEC = 'Jan - Dec',
  FEB_JAN = 'Feb - Jan',
  MAR_FEB = 'Mar - Feb',
  APR_MAR = 'Apr - Mar',
  MAY_APR = 'May - Apr',
  JUN_MAY = 'Jun - May',
  JUL_JUN = 'Jul - Jun',
  AUG_JUL = 'Aug - Jul',
  SEP_AUG = 'Sep - Aug',
  OCT_SEP = 'Oct - Sep',
  NOV_OCT = 'Nov - Oct',
  DEC_NOV = 'Dec - Nov'
  }

export const financialPeriodOptions = [
  {
      display: FISCAL_PERIOD_ITEMS.JAN_DEC,
      id: 1
  },
  {
      display: FISCAL_PERIOD_ITEMS.FEB_JAN,
      id: 2
  },
  {
      display: FISCAL_PERIOD_ITEMS.MAR_FEB,
      id: 3
  },
  {
      display: FISCAL_PERIOD_ITEMS.APR_MAR,
      id: 4
  },
  {
      display: FISCAL_PERIOD_ITEMS.MAY_APR,
      id: 5
  },
  {
      display: FISCAL_PERIOD_ITEMS.JUN_MAY,
      id: 6
  },
  {
      display: FISCAL_PERIOD_ITEMS.JUL_JUN,
      id: 7
  },
  {
      display: FISCAL_PERIOD_ITEMS.AUG_JUL,
      id: 8
  },
  {
      display: FISCAL_PERIOD_ITEMS.SEP_AUG,
      id: 9
  },
  {
      display: FISCAL_PERIOD_ITEMS.OCT_SEP,
      id: 10
  },
  {
      display: FISCAL_PERIOD_ITEMS.NOV_OCT,
      id: 11
  },
  {
      display: FISCAL_PERIOD_ITEMS.DEC_NOV,
      id: 12
  }
  ];

export function containsSelectedEnumValue<T>(flagNumber: number, enumType: T, enumValuesToCheck: T[keyof T][]): boolean
{
    const allValuesByFlag = getSelectedEnumTypes(flagNumber, enumType);
    let isAnyValueSelected = false;

    for (const value of enumValuesToCheck) {
        if (allValuesByFlag.includes(value)) {
            isAnyValueSelected = true;
            break;
        }
    }

    return isAnyValueSelected;
}


    /**
     * Computes and get all selected enum Ids from flagnumber
     * @param flagNumber - Flag number
     * @returns List of value channel types selected
     */
export function getSelectedEnumTypes<T>(flagNumber: number, enumType: T): Array<T[keyof T]> {
    const selectedFlags: Array<T[keyof T]> = [];

    for (const flag in enumType) {
        // Skip over non-numeric values in the enum
        if (isNaN(Number(flag))) {
            continue;
        }
        const enumValue = Number(flag);

        // Check if the flag is set in flagNumber
        if ((flagNumber & enumValue) === enumValue) {
            selectedFlags.push(enumValue as T[keyof T]);
        }
    }

    return selectedFlags;
}

/**
 * Computes flag number using enum Ids
 * @param selectedIds - All the enum ids selected in order to compute flag number
 * @returns
 */
export function computeFlagNumber(selectedIds: number[]): number {
    let flagNumber = 0;

    for (const id of selectedIds) {
        flagNumber |= id; // Use bitwise OR to combine flags
    }

    return flagNumber;
}



