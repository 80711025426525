import { HttpBackend, HttpClient, HttpXhrBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ENVIRONMENTS, APP_CONFIG } from './app.config';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class AppEnvConfig {

    private config: Object = null;
    private env: Object = null;
    private http: HttpClient;

    constructor(private readonly httpHandler: HttpXhrBackend) {

        this.http = new HttpClient(this.httpHandler);

    }

    public getEnv(key: any) {
        return this.env[key];
    }


    public load() {
        return new Promise((resolve, reject) => {
           // alert(1);
            this.http.get('env.json').pipe(map(res => res)
                ,catchError((error: any): any => {
                  //  alert(2);
                    resolve(true);
                    return Observable.throw(error.json().error || 'Server error');
                })).subscribe((envResponse) => {
                   // alert(3);
                    this.env = envResponse;
                 //   debugger;
                    //console.log('Environment is ready', this.env);
                    resolve(true);
                });
        });
    }
}
