/* eslint-disable @typescript-eslint/consistent-type-imports */
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { DividerCss, SidePanelOptions } from './constants/side-panel.model';
import { SidePanelService } from './services/side-panel.service';

@Component({
  selector: 'nalco-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidePanelComponent implements OnInit {

  constructor(private sidePanelService: SidePanelService) {

  }

  private document: Document

  @Input() headerDetails?: SidePanelOptions;
  @Input() showDevider = false;

  @Output() closeSidePanelEvent = new EventEmitter<boolean>();
  sidePanelValue: boolean;
  closeSidePanelValue: boolean;
  dividerCss = DividerCss;

  ngOnInit(): void {
    this.sidePanelService.triggerSidePanel.subscribe(value => this.sidePanelValue = value);
    document.body.classList.add('overlay-body')
  }

  /**
  * @description This function is used to close the side opened side panel
  */

  closeSidePanel(): void {
    this.closeSidePanelEvent.emit(true);
    document.body.classList.remove('overlay-body')
  }


}
