import { Component, OnInit, Input, OnChanges, AfterViewInit } from '@angular/core';
import { DonutChartConfig } from './performance-donut-chart.config';
import { Chart } from 'angular-highcharts';

@Component({
  selector: 'nalco-performance-donut-chart',
  templateUrl: './performance-donut-chart.component.html'
})
export class PerformanceDonutChartComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() data?: any; // To get the data for the  chart
  @Input() height?: number; // Defines the height of the chart
  @Input() width?: number; // Defines the width of the chart
  @Input() color?: [string]; // Defines the color of the donut
  @Input() mainTitle?: string; // Defines the center value of the donut
  @Input() customcolour?: any; // adding colour array
  @Input() subTitle: string; // adding subtitle string to donut chart
  @Input() innerSize: string; // set the radius width of the donut
  @Input() tooltipEnable?= true;
  @Input() subTitleAlignment?= 122;
  @Input() mainTitleAllignment?= 5; // Defines the allignment value of the donut
  donutChartOptions: any;
  chart: Chart;

  constructor() { }

  ngOnInit() {
  }
  ngOnChanges() {
    this.redrawDonut();
  }
  ngAfterViewInit() {
    this.redrawDonut();
  }
  private redrawDonut() {
    this.donutChartOptions = {
      chart: {
        type: 'pie',
        backgroundColor: null,
        center: [0, 0],
        width: this.width,
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0,
        style: {
          fontFamily: 'Interstate-Regular'
        }
      },
      title: {
        useHTML: true,
        text: this.mainTitle,
        style: {
          color: '#666666',
          fontSize: '14px',
          'line-height': '1'
        },
        align: DonutChartConfig.align,
        verticalAlign: DonutChartConfig.verticalAlign,
        y: this.mainTitleAllignment
      },
      subtitle: {
        // useHTML: true,
        text: this.subTitle,
        style: {
          color: '#666666',
          fontSize: '12px',
        },
        y: this.subTitleAlignment,
        widthAdjust: 20
      },
      credits: DonutChartConfig.credits,
      plotOptions: DonutChartConfig.plotOptions,
      tooltip: {
        enabled: this.tooltipEnable,
        useHTML: true,
        backgroundColor: '#000000',
        followPointer: false, // for the tool-tip pointer
        style: {           // for the interior text
          color: '#FFFFFF'
        },
        borderColor: '#000000',
        formatter: function () {
          if (this.point.tooltip) {
            return ' ' + this.point.tooltip;
          } else {
            return ' ' + Math.round((this.y) * Math.pow(10, 2)) / Math.pow(10, 2) + '%</b>';
          }
        }
      },
      series: [{
        startAngle: DonutChartConfig.startAngle,
        colors: [{
          linearGradient: DonutChartConfig.perShapeGradient,
          stops: this.customcolour,
        }, DonutChartConfig.greyColor],
        states: DonutChartConfig.states,
        data: this.data,
        size: DonutChartConfig.size,
        innerSize: this.innerSize,
        showInLegend: DonutChartConfig.showInLegend,
        dataLabels: DonutChartConfig.dataLabels
      }]
    };
    this.donutChartOptions.chart.height = this.height ? this.height : 'auto';
    this.chart = new Chart(this.donutChartOptions);
  }

}
