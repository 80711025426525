<div class="search-popup">
    <form class="example-form">
        <mat-form-field floatLabel="never" appearance="none">
            <input #myInput type="text" id="globalsearchinputbox" placeholder="Enter Corporate Account or Site Name."
                style="color:black; font-size: medium;" [formControl]="myControl" matInput [matAutocomplete]="auto"
                [(ngModel)]="searchTerm">
            <mat-autocomplete #auto="matAutocomplete" class="global-search-set-maxheight">
                <mat-optgroup >
                    <mat-option  *ngFor="let parentOption of newFilteredOptions">
                        <div>
                            <span>{{ parentOption.SiteId }}</span>|<span [ngClass]="parentOption.CorporateAccount == searchTerm ? 'matched': 'nothing'" >{{ parentOption.CorporateAccount}}</span>
                            <span>{{ parentOption.Country }}</span>|<span [ngClass]="parentOption.Customer == searchTerm ? 'matched': 'nothing'" >{{ parentOption.Customer}}</span>
                            <span>{{ parentOption.District }}</span>|<span [ngClass]="parentOption.DivisionalAccountGroup == searchTerm ? 'matched': 'nothing'" >{{ parentOption.DivisionalAccountGroup}}</span>
                            <span>{{ parentOption.EcolabDivision }}</span>|<span [ngClass]="parentOption.IndustrialDivision == searchTerm ? 'matched': 'nothing'" >{{ parentOption.IndustrialDivision}}</span>
                            <span>{{ parentOption.Market }}</span>|<span>{{ parentOption.Region}}</span>
                            <span>{{ parentOption.SalesAccount }}</span>
                            
                            <span>{{ parentOption.Site }}</span>|<span>{{ parentOption.SoldTo}}</span>
                            <span>{{ parentOption.Territory }}</span>|<span>{{ parentOption.City}}</span>
                            <span>{{ parentOption.PostalCode }}</span>|<span>{{ parentOption.StateProvince}}</span>
                            <span>{{ parentOption.Street }}</span>
                        </div>
                    </mat-option>
                    <!-- <div *ngIf= "parentOption.Child.length>0" class="parentoption">{{ parentOption.Parent }}</div>
                    <div *ngIf= "parentOption.Child.length<1" class="parentoption">
                        <a [href]= "baseURL + parentOption.URL" style="color: white; display: block;">
                            {{ parentOption.Parent }}
                        </a>
                    </div>
                    <mat-option *ngFor= "let childOption of parentOption.Child">
                        <a [href]= "baseURL + childOption.URL" style="display: block;">
                            <span *ngIf= "childOption.ShortName" class="highlight"> {{childOption.ShortName}} :</span> {{childOption.Service}}
                        </a>
                    </mat-option> -->
                </mat-optgroup>
            </mat-autocomplete>
        </mat-form-field>
    </form>
</div>