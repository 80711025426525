import { Pipe, PipeTransform } from '@angular/core';
import { selectChildOption, selectSubChildOption } from '../../app.model';

@Pipe({
  name: 'filterNavigation'
})
export class FilterNavigation implements PipeTransform {
  transform(role, data, isInternalUser, IsE3DChina, linksToExclude: string[], UserRegion?): any {
    const navigationData = [];
        data.forEach(value => {
            this.hideNavigationItemsE3DChina(value, IsE3DChina, isInternalUser);
      if (value.subNavigation.length === 0 && value.role) {
        role.forEach(main => {
          if (value && value.role && value.role.indexOf(main) !== -1) {
            if (navigationData.indexOf(value) === -1) {
              navigationData.push(value);
            }
          }
        });
      } else {
        // value.subNavigation = this.filterByRole(role, value.subNavigation);
        value.subNavigation = this.filterByRoles(role, value.subNavigation, isInternalUser, linksToExclude, UserRegion);
        navigationData.push(value);
      }
      //}
    });
    return navigationData;
  }
  /**
   * @param role string array and menu object array
   * @description filter the subnavigation
   * @returns the sub-navigation objects
   */
  apitransform(role:string[], data: selectSubChildOption[]): selectSubChildOption[] {
      const navigationData = [];
          data.forEach(value => {
                  role.forEach(main => {
                      if (value && value.PermissionId && value.PermissionId.indexOf(main) !== -1) {
                          if (navigationData.indexOf(value) === -1) {
                              navigationData.push(value);
                          }
                      }
                  });
          });
      return navigationData;
  }
    hideNavigationItemsE3DChina(item: any, isE3DChina: boolean, isInternalUser: boolean): void {
    if (item.name === 'Navigation - Main - Dashboards' && isE3DChina) {
      item.subNavigation = item.subNavigation.filter((m: any) => {
        return (
          m.name !== 'Navigation - Main - Scale Guard Index' &&
          m.name !== 'Navigation - Main - Water Balance Intelligence' &&
          m.name !== 'Navigation - Main - eCommerce'
        );
      });

      if (!isInternalUser) {
        item.subNavigation = item.subNavigation.filter((n: any) => {
          return n.name !== 'Navigation - Main - E-data';
        });
      }
    }

    if (item.name === 'Support Info' && isE3DChina) {
      item.subNavigation = item.subNavigation.filter((o: any) => {
        return (
          o.name !== 'Digital Support Dashboard' &&
          !o.name.includes('Onboarding Support')
        );
      });

      // item.subNavigation = item.subNavigation.map((p: any) => {
      //   if (p.name === 'EGIC Email') {
      //     p.href = "mailto:EcolabGlobalIntelligenceCenter@ecolab.com?subject=New";
      //   }
      //   return p;
      // });
    }

    if (item.name === 'Common Pages' && isE3DChina) {
      item.subNavigation = item.subNavigation.filter((q: any) => {
        return q.name !== 'SH&E Reference';
      });
    }

    if (item.name === 'Training' && isE3DChina) {
      item.subNavigation = item.subNavigation.filter((r: any) => {
        if (isInternalUser) {
          return r.name !== 'Training Customers';
        }
      });
    }
  }


  filterByRole(role, data) {
    const mod = [];
    role.forEach(value => {
      data.forEach(subNav => {
        if (value === subNav.role) {
          mod.push(subNav);
        }
      });
    });
    return mod;
  }

  // filterByRole with Array of roles
  filterByRoles(permissions, subNavigation, isInternalUser, linksToExclude: string[], UserRegion?
  ) {
    const filteredNav = [];
    for (const subItem of subNavigation) {
      if(linksToExclude.length){
        const toExclude = linksToExclude.find(l => l == subItem.link);

        if(toExclude){
          continue;
        }
      }

      for (const role of subItem.role) {
        if (permissions && permissions.indexOf(role.split('-')[1]) !== -1) {
          break;
        }
        if (permissions && permissions.indexOf(role) !== -1) {
          if (subItem.isVisible !== undefined && !isInternalUser && subItem.region === undefined
          ) {
            if (subItem.isVisible) {
              filteredNav.push(subItem);
            }
            //not internal user && region
          } else if (subItem.isVisible !== undefined && subItem.isInternalUser === isInternalUser && subItem.region.includes(UserRegion)) {
            if (subItem.isVisible) {
              filteredNav.push(subItem);
            }
          } else {
            if (subItem.region === undefined) {
              filteredNav.push(subItem);
            }
          }
          break;
        }
      }
    }
    return filteredNav;
  }
}
