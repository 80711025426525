import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren, ViewEncapsulation, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { BehaviorSubject, Observable, fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';

const KEY_CODE = {
  enter: 13,
  arrowUp: 38,
  arrowDown: 40,
  esc: 27,
}

const CSS_CLASS_NAMES = {
  highLight: 'dd-highlight-item',
}

@Component({
  selector: 'nalco-select-dropdown',
  templateUrl: './select-dropdown.component.html',
  styleUrls: ['./select-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectDropdownComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectDropdownComponent implements OnInit, ControlValueAccessor {

  @ViewChild('filterInput', { static: false }) filterInput: ElementRef;
  @ViewChild('displayLabel', { static: false }) displayLabel: ElementRef;
  @ViewChild('EditInput', { static: false }) EditInput: ElementRef;
  @ViewChildren('listItems') listItems: QueryList<ElementRef>;
  @ViewChild('checkBox', { static: false }) checkBox: ElementRef;


  _items = [];

  _list = new BehaviorSubject<any[]>([]);
  @Input() placeholder = this.translate.instant('COMMON.SELECT');
  @Input() SearchType? = 'list';
  @Output() itemSelected: EventEmitter<any> = new EventEmitter(); // Emits the toggle event of the button
  @Output() lazyLoading: EventEmitter<any> = new EventEmitter();
  @Output() filterText: EventEmitter<any> = new EventEmitter();
  @Output() getSelectedItems: EventEmitter<any> = new EventEmitter();
  @Output() clearItems: EventEmitter<any> = new EventEmitter();
  @Output() addItemClick: EventEmitter<any> = new EventEmitter();
  @Output() itemSelectedData: EventEmitter<any> = new EventEmitter();
  @Output() categorySelected: EventEmitter<any> = new EventEmitter();
  @Output() dropdownClosed: EventEmitter<any> = new EventEmitter();

  @Input() isDisable? = false;
  @Input() showLoader? = false;
  @Input() multiSelection? = false;
  @Input() displayOnInit = [];
  @Input() noRecoredsLabel = this.translate.instant('COMMON.NO_RECORDS_FOUND');
  @Input() noMatchingRecoredsLabel = this.translate.instant('COMMON.NO_RECORDS_FOUND');
  @Input() showFilter? = true;
  @Input() depValue? = false;
  @Input() isDefaultSelected? = false;
  @Input() imgBeforeLabel? = false;
  @Input() isSortEnabled = true;
  @Input() showPlaceHolder = true;
  @Input() showAdd: boolean = false;
  @Input() editable: boolean = false;
  @Input() headerValue?: string;
  @Input() minSearchLength?: number = 2;
  @Input() categoryList?: Array<{ value: number, text: string, checked: boolean }>;
  @Input() showFilterBycategory?: boolean = false;
  @Input() hideClearSelection? = false;
  @Input() FromSSD? = false;
  @Input() isDataClear = false;
  @Input() isSiteTab? = false;
  @Input() siteDisplayName?: string = '';
  @Input() isSiteCache? = false;
  @Input() isFromLimsPOC = false;
  @Input() isFromLIMSDropdown = false;
  @Input() isFromAHInventory = false;
  @Input() revertSetItem?: string = '';
  @Input() resetSelectedItem?: string = 'show';
  @Input() isRequired = false;
  @Input() isClearAllSelected = false;
  @Input() isFromOmniSurveys = false;

  noMatchingCategory: string;
  valueEdited: boolean = false;
  multiselectedItems = [];
  isWait = false;
  selectedCount = 0;
  count = 1;
  _value: string;
  _display: string = this.translate.instant('COMMON.SELECT');

  isListHide = true;
  private selected = false;

  searchText = '';
  keyDowns: Observable<KeyboardEvent> = fromEvent(this.elemRef.nativeElement, 'keydown');

  pressEnterKey: Observable<KeyboardEvent>;
  onChange: any = () => { };
  onTouched: any = () => { };
  editedValue: string;
  isNewlyAdded: boolean = false;
  disableClearSelection: boolean = true;

  @HostListener('document:click', ['$event'])
  onClick(ev: MouseEvent) {
    const clickInside = this.elemRef.nativeElement.contains(ev.target);
    if (!clickInside) {
      if (!this.isListHide) {
        this.dropdownClosed.emit(this.isListHide);
      }
      this.isListHide = true;
      if (this.revertSetItem !== '') {
        this.display = this.revertSetItem.toUpperCase();
      }
    }
  }

  @Input()
  set list(list) {
    this._list.next(list);
  }

  set items(list) {
    this._items = list;
  }
  get items(): Array<{ id: number, display: string, checked: boolean, isImage: string, editable: false, isMapped: boolean }> {
    return this._items;
  }
  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
  }

  @Input()
  set display(value) {
    this._display = value;
  }

  get display() {
    return this._display;
  }

  constructor(private elemRef: ElementRef, private translate: TranslateService, private cdRef: ChangeDetectorRef) {
    this.pressEnterKey = this.keyDowns.pipe(filter((e: KeyboardEvent) => e.keyCode === KEY_CODE.enter));
  }

  ngOnInit() {
    if (this.FromSSD) {
      if (!this.isSiteCache) {
        this._display = this.translate.instant('COMMON.SELECT_SITE');
        this.disableClearSelection = true;
      } else {
        this.disableClearSelection = false;
      }
    }

    this._list.subscribe((list) => {
      this.items = list;
      // if (this.SearchType === 'list') {
      //   this.setItem(this.findItem(this.value));
      // }
    });

    this.pressEnterKey.pipe(filter(() => !this.isListHide)).subscribe(() => {
      const hightLightItem = this.listItems.find((elem) => elem.nativeElement.classList.contains(CSS_CLASS_NAMES.highLight));
      if (hightLightItem) {
        const item = JSON.parse(hightLightItem.nativeElement.getAttribute('data-dd-value'));
        this.setItem(item);
        this.onChange(item.id);
      }
    })

    this.pressEnterKey.subscribe((e) => {
      this.toggle();
    });

    this.keyDowns.pipe(filter((e) => e.keyCode === KEY_CODE.esc)).subscribe(() => {
      this.isListHide = true;
      this.focus();
      this.dropdownClosed.emit(this.isListHide);
    });
    this.keyDowns.pipe(filter((e) => ((e.keyCode === KEY_CODE.arrowDown || e.keyCode === KEY_CODE.arrowUp) && !this.isListHide))).subscribe((e) => {
      this.moveUpAndDown(e.keyCode);
    });

    if (this.displayOnInit && this.displayOnInit.length > 0) {
      this.setItem(this.displayOnInit[0]);
    }

    if (this.multiSelection) {
      this.setMultiselection();
    } else {
      if (this.displayOnInit.length > 0) {
        this.setItem(this.displayOnInit[0]);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.resetSelectedItem == '') {
      this.display = this.translate.instant('COMMON.SELECT');
    }
    if (changes.depValue) {
      if (this.displayOnInit && this.displayOnInit.length === 0 && this.depValue && !this.isSiteCache) {
        this.setItem('');
        this.value = '';
        this.display = this.translate.instant('COMMON.SELECT');
      }
    }
    if (changes.showLoader) {
      if (!this.showLoader) {
        if (this.filterInput) {
          setTimeout(() =>
            this.filterInput.nativeElement.focus(),
            0);
        }

      }
    }
    if (this.displayOnInit && this.displayOnInit.length > 0) {
      this.setItem(this.displayOnInit[0]);
    }
    if (this.isDataClear) {
      if (this.FromSSD) {
        if (!this.isSiteCache || this.display === "")
          this.display = this.translate.instant('COMMON.SELECT_SITE');
        else if (this.display != "" && this.isClearAllSelected) {
          this.display = this.translate.instant('COMMON.SELECT_SITE');
        }
      }
    }

    if (this.isFromLIMSDropdown) {
      this.display = this.translate.instant('COMMON.SELECT_ASSET_PORT');
    }

    if (this.isFromLimsPOC) {
      this.setItem('');
      this.value = '';
      this.items.forEach(item => {
        if (item.checked === true) {
          item.checked = false;
        }
      });
    }
    if (this.displayOnInit && this.displayOnInit.length === 0 && this.isFromAHInventory) {
      this.setItem('');
      this.value = '';
      this.display = this.translate.instant('COMMON.SELECT');
    }
  }
  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }

  fetchMore() {
    this.lazyLoading.emit();
  }

  setMultiselection() {
    if (this.displayOnInit.length === 1) {
      this.setItem(this.displayOnInit[0]);

    } else if (this.displayOnInit.length > 1) {
      this.value = null;
      this.display = this.translate.instant("COMMON.MULTIPLE_SELECTED");

    } else {
      this.value = '';
      this.display = this.translate.instant('COMMON.SELECT');
      this.disableClearSelection = true;
    }
    this.multiselectedItems = this.displayOnInit;
  }

  addItemClicked() {
    this.selected = true;
    this.isListHide = true;
    this.isNewlyAdded = true;
    // this.addItemClick.emit();
    this.EditInput.nativeElement.focus();
    this.display = '';
    this.editedValue = undefined;
    this.onClear();
    this.focusOutFunction();
  }

  onEditableKeyUp(e: any) {
    if (e != undefined) {
      this.editedValue = e;
      this.valueEdited = true;
    }
  }

  focusOutFunction(item?) {
    if (this.isNewlyAdded == true) {
      if (this.EditInput.nativeElement.value == this.translate.instant('COMMON.SELECT')) {
        // this.editedValue = this.EditInput.nativeElement.value;
        this.editedValue = this.display;
      }
      this.addItemClick.emit({ 'name': this.editedValue, 'newlyAdded': this.isNewlyAdded, 'isEdited': this.valueEdited });
    } else if (this.isNewlyAdded == false) {
      let temp = this.editedValue ? this.editedValue : this.display;
      if (item == undefined) {
        let editItem = {}
        editItem = {
          id: this.value != '' ? this.value : undefined,
          display: temp,
          checked: this.value != '' ? true : false
        }
        item = Object.assign({}, editItem);
      }
      this.addItemClick.emit({ 'name': temp, 'newlyAdded': this.isNewlyAdded, 'item': item, 'isEdited': this.valueEdited });
    }
  }

  onKeyUp(searchText) {
    if (this.SearchType === 'server') {
      if (searchText.length == 0) {
        this.filterText.emit(searchText);
        this.searchText = searchText;
      }
      else if (searchText.length > this.minSearchLength) {
        this.filterText.emit(searchText);
        this.searchText = searchText;
      }
    }
  }

  sortByKey(array, key) {
    if (this.isSortEnabled) {
      return array.sort(function (a, b) {
        const x = a[key].toUpperCase();
        const y = b[key].toUpperCase();
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
      });
    }
    return array;
  }

  scrollToView(elem?: HTMLElement) {
    if (elem) {
      setTimeout(() => elem.scrollIntoView(), 0);
    } else {
      const selectedItem = this.listItems.find((item) => JSON.parse(item.nativeElement.getAttribute('data-dd-value'))['id'] === this.value);
      if (selectedItem) {
        setTimeout(() => selectedItem.nativeElement.scrollIntoView(), 0);
      }
    }
  }

  toggle() {
    this.isListHide = !this.isListHide;
    if (this.isListHide) {
      this.dropdownClosed.emit(this.isListHide);
    }
    if (!this.isListHide) {
      if (this.searchText !== '') {
        this.searchText = '';

        if (this.SearchType === 'server') {
          this.filterText.emit('');
        }
      } else {
        if (this.SearchType === 'server') {
          this.getSelectedItems.emit();
        }
      }
      if (this.SearchType === 'list') {
        this.getSelectedItems.emit();
      }
      if (this.filterInput) {
        setTimeout(() => this.filterInput.nativeElement.focus(), 0);
      }


      this.listItems.forEach((item) => {
        if (JSON.parse(item.nativeElement.getAttribute('data-dd-value'))['id'] === this.value) {
          this.addHightLightClass(item.nativeElement);

        } else {
          this.removeHightLightClass(item.nativeElement);
        }
      });

      this.selectedCount = 0;
      this.items.forEach(item => {
        if (item.checked) {
          this.selectedCount++;
          if (!this.multiSelection) {
            this.setItem(item);
          }
        }
      });
      if (this.selectedCount === 0) {
        if (this.displayOnInit && this.displayOnInit.length > 0) {
          this.selectedCount++;
          this.setItem(this.displayOnInit[0]);
        }

      }
    }
  }

  focus() {
    setTimeout(() => this.displayLabel.nativeElement.focus(), 0);
  }

  onItemSelect(item, event) {
    if (event.target.checked && !this.multiSelection) {
      this.selectedCount = 0;
      this.items.forEach(element => {
        if (element.id === parseInt(this.value, 10)) {
          element.checked = false;
        }
      });
    }
    if (event.target.checked) {
      this.selectedCount++;
      this.editedValue = undefined;
      this.isNewlyAdded = false;
      this.setItem(item);
      if (item !== undefined) {
        this.onChange(item.id);
      } else {
        this.onChange('');
      }
      this.focus();
      if (this.FromSSD)
        item.id.DisplayName = item.display;
      this.itemSelected.emit(item.id);
      this.itemSelectedData.emit(item);
      this.items.forEach(element => {
        if (element.id === parseInt(this.value, 10)) {
          element.checked = true;
        }
      });
    } else {
      this.items.forEach(element => {
        if (element.id === parseInt(this.value, 10)) {
          element.checked = false;
        }
      });
      if (this.isDefaultSelected) {
        this.items[0].checked = true;
        this.setItem(this.items[0]);
        this.itemSelected.emit(this.items[0].id);
        this.itemSelectedData.emit(this.items[0]);
        this.items = this.sortByKey(this.items, 'display');
      } else {
        this.selectedCount--;
        this.setItem('');
        this.itemSelected.emit();
      }

    }
    this.items = this.sortByKey(this.items, 'display');
  }


  onClear() {
    if (this.isSiteTab) {
      this.items = [];
    }
    let checkedItems = 0;
    this.items.forEach(item => {
      if (item.checked === true) {
        checkedItems++;
      }
    });

    if ((checkedItems > 0 || this.SearchType === 'server') && !this.isDefaultSelected) {
      this.items.forEach(item => {
        if (item.id === parseInt(this.value, 10)) {
          item.checked = false;
        }
      });
      if (this.searchText !== '') {
        this.searchText = '';

        if (this.SearchType === 'server') {

          this.filterText.emit(this.searchText);
        }
      }
      this.setItem('');
      this.editedValue = undefined;
      this.itemSelected.emit();
      this.items = this.sortByKey(this.items, 'display');
    } else if (this.isDefaultSelected && !this.items[0].checked) {
      this.items.forEach(item => {
        if (item.id === parseInt(this.value, 10)) {
          item.checked = false;
        }
      });
      this.items[0].checked = true;
      this.setItem(this.items[0]);
      this.itemSelected.emit(this.items[0].id);
      this.itemSelectedData.emit(this.items[0]);
      this.items = this.sortByKey(this.items, 'display');
    }
    this.clearItems.emit();
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  findItem(value) {
    return this.items.find((item) => +item.id === +value);
  }

  writeValue(value) {
    const item = this.findItem(value);
    this.value = value;
    this.display = item ? item.display : this.isFromOmniSurveys ? 'Select' : '';
  }

  setItem(item) {

    if (item) {
      if (item.id !== null) {
        this.value = item.id;
      }
      if (item.display) {
        if (this.editedValue == undefined) {
          this.display = item.display;
          item.checked = true;
        } else {
          this.display = this.editedValue;
        }
        this.disableClearSelection = false;
        this.focusOutFunction(item)
      }
    } else if (this.FromSSD) {
      this.value = '';
      this.display = this.translate.instant('COMMON.SELECT_SITE');
      this.disableClearSelection = true;
    }
    else {
      this.value = '';
      this.display = this.translate.instant('COMMON.SELECT');
      this.disableClearSelection = true;
    }
  }

  onKeyPress(e: KeyboardEvent) {
    if (e.keyCode === KEY_CODE.enter) {
      this.focus();
      return false;
    }
    if (this.filterInput) {
      setTimeout(() => this.filterInput.nativeElement.focus(), 0);
    }

  }

  addHightLightClass(elem: HTMLElement) {
    elem.classList.add(CSS_CLASS_NAMES.highLight)
  }

  removeHightLightClass(elem: HTMLElement) {
    elem.classList.remove(CSS_CLASS_NAMES.highLight);
  }

  moveUpAndDown(key: number) {
    const selectedItem = this.listItems.find((li) => li.nativeElement.classList.contains(CSS_CLASS_NAMES.highLight));
    if (selectedItem) {
      let hightLightedItem: HTMLElement;
      if (key === KEY_CODE.arrowUp) {
        //check for first element
        if (selectedItem !== this.listItems.first) {
          hightLightedItem = selectedItem.nativeElement.previousSibling;
        }
      } else if (key === KEY_CODE.arrowDown) {
        //check for last element
        if (selectedItem !== this.listItems.last) {
          hightLightedItem = selectedItem.nativeElement.nextSibling;
        }
      }
      if (hightLightedItem) {
        this.clearHlightClass();
        this.removeHightLightClass(selectedItem.nativeElement);
        this.addHightLightClass(hightLightedItem);
        this.scrollToView(hightLightedItem);
      }
    } else {
      let highLightedItem: ElementRef;
      if (key === KEY_CODE.arrowUp) {
        highLightedItem = this.listItems.last;
      }
      else if (key === KEY_CODE.arrowDown) {
        highLightedItem = this.listItems.first;
      }
      if (highLightedItem) {
        this.addHightLightClass(highLightedItem.nativeElement);
        this.scrollToView(highLightedItem.nativeElement);
      }
    }
  }

  isSelected(item: { id: number, display: string }) {
    return +item.id === +this.value;
  }

  stringify(item) {
    return JSON.stringify(item);
  }

  onHover(event: MouseEvent) {
    this.clearHlightClass();
    const target = event.target as HTMLElement;
    if (event.type === 'mouseover') {
      target.classList.add(CSS_CLASS_NAMES.highLight)
    } else {
      target.classList.remove(CSS_CLASS_NAMES.highLight);
    }
  }

  clearHlightClass() {
    this.listItems.forEach((item) => {
      this.removeHightLightClass(item.nativeElement);
    })
  }

  onCategoryChange(category) {
    for (let i = 0; i < this.categoryList.length; i++) {
      if (this.categoryList[i].text == category.text) {
        if (category.checked == false) {
          this.categoryList[i].checked = true;
          this.searchText = '';
          this.categorySelected.emit(category);
        } else
          this.categoryList[i].checked = false;
      } else
        this.categoryList[i].checked = false;
    }
  }

}
