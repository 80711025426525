import { Component, Input, Output, EventEmitter, forwardRef, ViewEncapsulation } from '@angular/core';
import { ICheckbox } from './contracts/checkbox.model';
import { FormGroup, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'nalco-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CheckboxComponent),
    multi: true
  }],
  encapsulation : ViewEncapsulation.None
})


export class CheckboxComponent implements ControlValueAccessor  {
  @Input() checkbox: ICheckbox;
  @Output() checkboxClick = new EventEmitter();
  
  @Input() enableCheckboxWithoutChecked = false;

  checkBoxValue: ICheckbox[] ; // this is set as any because it is depend on the input we are getting
  onCheckboxChange = (value: ICheckbox[]): void => { }
  OncheckboxTouched = () => { }
  constructor() { }



  /**
* @description function is used write value using control value processor
* @param obj
*/

writeValue(obj: any): void {
  if (obj && Array.isArray(obj)) {
    this.checkBoxValue = obj.map(item => ({
      ...item,
      checked: !!item.checked // Ensure boolean value
    }));
  } else {
    this.checkBoxValue = [];
  }
}

  /**
* @description function is used get the function and save it
* @param fn
*/

  registerOnChange(fn: any): void {
    this.onCheckboxChange = fn;
  }

  /**
* @description function is used get the register the function
* @param fn
*/

  registerOnTouched(fn: any): void {
    this.OncheckboxTouched = fn;
  }


  /**
* @description function is used to analysis the toggle change and capture the event and emit it to parent component
* @param $event
*/

  toggleChange($event: Event): void {
    const eventTarget = $event.target as HTMLInputElement;
    const id = eventTarget.id;
    this.checkBoxValue = this.getIdValue(this.checkBoxValue, id)
    this.onCheckboxChange(this.checkBoxValue);
    this.OncheckboxTouched();
    this.checkboxClick.emit(this.checkBoxValue);

  }

  /**
* @description function is used to get the filtered and modifed data
* @param value
* @param id
* @returns ICheckbox[]
*/

  getIdValue(value: ICheckbox[], id: string): ICheckbox[] {
    return value.map((item) => {
      if (item.id === id) {
        return { ...item, checked: !item.checked };
      } else {
        return { ...item, checked: item.checked }
      }
    });
  }

}