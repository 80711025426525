/* eslint-disable @typescript-eslint/consistent-type-imports */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MenuItems} from './contracts/menu'


@Injectable()

export class MenuService {

  navExpand = new BehaviorSubject(false);


  constructor() { }

  /**
   * @description  This is to highlight if the menu is selected for the menu or not // for the two level menus we are using it so created a public function
   * @param text 
   * @param menus 
   * @returns the menu selected
   */

  enableSelection(text:string,menus:MenuItems[]){
    if(text?.length > 0){
    for (let index = 0; index < menus.length; index++) {
     if (text === menus[index].text) {
         menus[index].isSelected = true ;
     } else{
         menus[index].isSelected = false;
     }   
    }
  }
    return menus
 }

}
