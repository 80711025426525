import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ErrorHandling } from '../../../shared/components/error-handling/error-handling';
import { XdComponentState } from '../../../shared/components/xd-container/xd-component';
import { ComponentState } from '../../../shared/components/xd-container/xd-component-state';
import { ChargeBalanceInput, ConversionSource, ROTabs, WaterAnalysisTypes, WaterChemistryTypes, pHAcids, pHBases } from '../../_enum/constants';
import { IAddedIons } from '../../contracts/IChargeBalance';
import { ChargeBalance } from '../../models/ChargeBalance';
import { UnitConversion } from '../../models/UnitConversion';
import { SharedRoService } from '../../services/shared-ro.service';
import { ChargeBalanceComponent } from '../charge-balance/charge-balance.component';

@Component({
  selector: 'nalco-water-chemistry',
  templateUrl: './water-chemistry.component.html',
  styleUrls: ['./water-chemistry.component.scss']
})
export class WaterChemistryComponent extends XdComponentState implements OnInit, OnChanges, OnDestroy {

  waterChemistryForm: UntypedFormGroup;
  @Input() wcData;
  @Input() dimensionalUsageKeys;
  @Input() pHControls;
  @Input() chargeBalanceInfo;
  @Output() errorTabStatus = new EventEmitter<any>();
  @Output() chargeBalanceValidation = new EventEmitter<any>();
  @Output() cbLoaderStatus = new EventEmitter<boolean>();
  wctdataCount: number = 0;
  wcSubscription: Subscription;
  subscription: Subscription;
  controlSubscription: Subscription;
  setPointSubscription: Subscription;
  waterChemistry: string;
  input = ChargeBalanceInput;
  errorInfo: any;
  errorHandling = new ErrorHandling(this.modalService, this.translate);
  unitConversion: Array<UnitConversion>;
  waterAnalysisType: string;
  pHControlValue: string;
  commonParams;
  paramData = [];
  prevSelectedUnit;
  subUnits;
  data = [];
  modalRef;
  addedIons: IAddedIons = new IAddedIons();
  chargeBalanceResult: ChargeBalance;
  pHValue: number;
  setPointError: boolean = false;
  isChargeBalanceCalculated: boolean;
  ionTypes: any;
  phControlscreated: boolean = false;

  constructor(private roService: SharedRoService,
    private translate: TranslateService,
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder,
    private router: Router,) {
    super();
  }

  myParentInstance(): WaterChemistryComponent {
    return this;
  }

  ngOnInit() {
    this.setState(ComponentState.ready);
    this.addedIons.cleared = true;
    this.roService.waterAnalysisTypeChangeEvnt.subscribe(x => this.waterAnalysisType = x);
    this.waterChemistryForm = this.formBuilder.group({
    });
    this.subscription = this.roService.waterChemistryTab.subscribe(x => {
      this.createForm();
    });
    this.roService.commonFormParams.subscribe((value) => {
      if (value != null) {
        this.commonParams = value;
        if (this.chargeBalanceInfo.ionDetails == null) {
          this.addedIons.cleared = true;
        } else {
          Object.keys(this.commonParams).forEach(control => {
            if (this.commonParams[control].FormControlName == 'RawWaterFlowtb' || this.commonParams[control].FormControlName == 'Temperaturetb') {
              if (this.commonParams[control].chargeBalupdated && this.commonParams[control].chargeBalupdated == true) {
                this.chargeBalanceInfo.updated = false;
                this.addedIons.cleared = true;
                this.isChargeBalanceCalculated = false;
                this.roService.setForms({ 'chargeBalance': null });
              }
            }
          })
        }
      }
    });
  }

  createForm() {
    if (Object.keys(this.waterChemistryForm.controls).length == 0) {
      this.waterChemistryForm.addControl('calculatedIons', new UntypedFormControl(''));
      this.waterChemistryForm.addControl('selectedUnit', new UntypedFormControl(''));
      this.waterChemistryForm.controls['calculatedIons'].disable();
      if (this.chargeBalanceInfo) {
        if (this.chargeBalanceInfo.updated == true) {
          this.isChargeBalanceCalculated = this.chargeBalanceInfo.ionDetails !== null && this.chargeBalanceInfo.ionDetails !== undefined ? true : false;
          this.prevSelectedUnit = 'PpmAsCaCO3';
          if (this.isChargeBalanceCalculated) {
            let type = this.chargeBalanceInfo.ionDetails.Type.split(".");
            let params = this.inputParam(type[type.length - 1], null, this.prevSelectedUnit, this.chargeBalanceInfo.ionDetails.Value, type[type.length - 1], ConversionSource);
            this.getConvertedUnits(params, false);
          }
          this.chargeBalanceInfo.updated = false;
        }
      }
      this.wcSubscription = this.roService.waterChemistryTypeChangeEvnt.subscribe(x => {
        this.waterChemistry = x;
        if (this.waterChemistry == WaterChemistryTypes[0] && this.wcData.length > 0) {
          this.waterChemistryForm.addControl('pHControl', new UntypedFormControl(''));
          this.waterChemistryForm.addControl('pHSetPoint', new UntypedFormControl(''));
          this.phControlscreated = true;
          this.controlSubscription = this.roService.pHControl.subscribe(c => {
            this.pHControlValue = c;
            if (this.pHControlValue !== null) {
              if (this.pHControlValue !== 'None' && this.waterChemistryForm.controls['pHSetPoint']) {
                this.waterChemistryForm.controls['pHSetPoint'].enable();
              }
              if (this.waterChemistryForm.controls['pHControl']) {
                this.waterChemistryForm.controls['pHControl'].setValue(this.pHControlValue);
              }
              this.setPointSubscription = this.roService.pHSetPoint.subscribe(s => {
                if (this.waterChemistryForm.controls['pHSetPoint']) {
                  this.waterChemistryForm.controls['pHSetPoint'].setValue(s)
                  if (this.waterChemistryForm.controls['pHtb']) {
                    this.setValidators();
                  }
                }
              });
            } else {
              if (this.waterChemistryForm.controls['pHControl']) {
                this.waterChemistryForm.controls['pHControl'].setValue(this.pHControls[0].key);
              }
              if (this.waterChemistryForm.controls['pHSetPoint']) {
                this.waterChemistryForm.controls['pHSetPoint'].disable();
              }
            }
          });
        } else if (this.waterChemistry != WaterChemistryTypes[0] && this.wcData.length > 0) {
          this.waterChemistryForm.removeControl('pHControl');
          this.waterChemistryForm.removeControl('pHSetPoint');
          this.pHControlValue = null;
        }
      });
      this.wcData.forEach((element, index) => {
        if (element.Type == 'combo-box') {
          this.waterChemistryForm.addControl(element.InputParam.FormControlName, new UntypedFormControl(''));
          this.waterChemistryForm.addControl(element.DropdownParam.FormControlName, new UntypedFormControl(''));
        } else if (element.Type === 'drop-down') {
          this.waterChemistryForm.addControl(element.DropdownParam.FormControlName, new UntypedFormControl(''));
        } else if (element.Type === 'input') {
          this.waterChemistryForm.addControl(element.InputParam.FormControlName, new UntypedFormControl(''));
        }
        this.wctdataCount = index;
      });
      this.setValues();
      this.setValidators();
    }
  }

  setValues() {
    if (this.wcData.length > 0) {
      this.wcData.forEach(x => {
        if (x.Type == 'combo-box' || x.Type == 'input') {
          this.waterChemistryForm.controls[x.InputParam.FormControlName].setValue(x.InputParam.Value);
        }
      })
    }
  }

  setValidators() {
    if (this.wcData.length > 0) {
      let wcType = this.waterChemistry.split("Water")[0];
      this.wcData.filter(x => {
        if (x.DisplayName.includes('pH')) {
          x.DisplayName = wcType + ' pH';
          this.pHValue = x.InputParam.Value;
        }
      });
      if (this.pHControlValue !== null) {
        if (pHAcids.includes(this.pHControlValue)) {
          this.waterChemistryForm.controls['pHSetPoint'].setValidators([Validators.required, Validators.min(6.5), Validators.max(this.pHValue)]);
        } else if (pHBases.includes(this.pHControlValue)) {
          this.waterChemistryForm.controls['pHSetPoint'].setValidators([Validators.required, Validators.min(this.pHValue), Validators.max(12)]);
        } else {
          this.waterChemistryForm.controls['pHSetPoint'].disable();
        }
      }
      this.waterChemistryForm.controls['pHtb'].setValidators([Validators.required, Validators.min(6.5), Validators.max(12)]);
      this.waterChemistryForm.controls['MAlkalinitytb'].setValidators([Validators.required]);
      this.waterChemistryForm.controls['Catb'].setValidators([Validators.required]);
      if (this.waterAnalysisType == WaterAnalysisTypes[1]) {
        this.waterChemistryForm.controls['Mgtb'].setValidators([Validators.required]);
        this.waterChemistryForm.controls['Natb'].setValidators([Validators.required]);
        this.waterChemistryForm.controls['Cltb'].setValidators([Validators.required]);
        this.waterChemistryForm.controls['SO4tb'].setValidators([Validators.required]);
        this.wcData.filter(x => x.DisplayName == 'Mg')[0].InputParam.Required = true;
        this.wcData.filter(x => x.DisplayName == 'Na')[0].InputParam.Required = true;
        this.wcData.filter(x => x.DisplayName == 'Cl')[0].InputParam.Required = true;
        this.wcData.filter(x => x.DisplayName == 'SO4')[0].InputParam.Required = true;
      }
      else if (this.waterAnalysisType == WaterAnalysisTypes[2]) {
        this.waterChemistryForm.controls['Mgtb'].setValidators([Validators.required]);
        this.waterChemistryForm.controls['PAlkalinitytb'].setValidators([Validators.required]);
        this.wcData.filter(x => x.DisplayName == 'Mg')[0].InputParam.Required = true;
        this.wcData.filter(x => x.DisplayName == 'PAlkalinity')[0].InputParam.Required = true;
      }
      this.subUnits = this.wcData.filter(x => x.DisplayName == 'Cl')[0].DropdownParam.ddData;
    }
    Object.keys(this.waterChemistryForm.controls).forEach(key => {
      this.waterChemistryForm.get(key).updateValueAndValidity();
    });
    this.setInputParams();
    this.waterChemistryForm = Object.create(this.waterChemistryForm);
  }

  updateDropdown(event) {
    this.setState(ComponentState.loading);
    this.getConvertedUnits(event, true);
  }

  //Setting up Input Parameters for Charge Balance
  setInputParams() {
    let obj = [];
    this.data = [];
    this.input = ChargeBalanceInput;
    this.wcData.forEach(x => {
      if (x.InputParam.Value != "" && x.InputParam.Value != null && x.Type != 'input') {
        let key = this.dimensionalUsageKeys.configurations.filter(y => y.dimensionalUsageKey == 'Roo_' + x.DropdownParam.FormControlName.slice(0, -2))[0];
        obj.push({ name: x.DisplayName, type: key.NamespacePrefix + "." + key.UnitType })
      };
    });
    this.input = [...this.input, ...obj];
    if (this.commonParams) {
      this.input.forEach(x => {
        if (x.name === 'FlowRate' || x.name === 'Temperature') {
          Object.keys(this.commonParams).forEach(key => {
            if (x.name == key || (x.name == 'FlowRate' && key == 'Raw Water Flow')) {
              let text = this.commonParams[key].FormControlName.substring(0, this.commonParams[key].FormControlName.length - 2)
              let dimensionalKey = this.dimensionalUsageKeys.configurations.filter(x => x.dimensionalUsageKey == 'Roo_' + text)[0];
              this.data.push(this.inputParam(dimensionalKey.UnitType, this.commonParams[key].DropdownValue, "", this.commonParams[key].InputValue, text, dimensionalKey.ConversionSource))
            }
          });
        }
        else {
          if (this.wcData && x.name.concat('tb') == 'pHtb') {
            const phType = this.waterChemistry == 'RawWater' ? 'Raw pH' : 'Concentrate pH';
            x['value'] = this.wcData.filter(x => x.DisplayName == phType)[0].InputParam.Value;
          }
          else {
            this.wcData.forEach(key => {
              if (key.InputParam.Value != "" && key.InputParam.Value != null && x.name.concat('tb') == key.InputParam.FormControlName) {
                let text = key.InputParam.FormControlName.substring(0, key.InputParam.FormControlName.length - 2)
                let dimensionalKey = this.dimensionalUsageKeys.configurations.filter(x => x.dimensionalUsageKey == 'Roo_' + text)[0];
                let conversion = key.InputParam;
                conversion['InputValue'] = key.InputParam.Value;
                conversion['DropdownValue'] = key.DropdownParam.Value;
                this.data.push(this.inputParam(dimensionalKey.UnitType, conversion.DropdownValue, "", conversion.InputValue, text, dimensionalKey.ConversionSource))
              }
            })
          }
        }
      });
    }
  }

  // Calculation Charge Balance
  getChargeBalance() {
    if (this.wcData.filter(x => x.InputParam.FormControlName == 'pHtb')[0].Valid) {
      if (this.addedIons) {
        this.setState(ComponentState.loading);
        this.cbLoaderStatus.emit(true);
        this.roService.GetConversionByUnitType(this.data).subscribe(
          response => {
            response.forEach(res => {
              this.input.forEach(x => {
                if (x.name == res.ParameterName || (x.name == 'FlowRate' && res.ParameterName == 'RawWaterFlow')) {
                  x['value'] = Number(res.ConvertedValue);
                }
              })
            })
            let finalInput = this.input.filter(x => x['value'] != undefined);
            this.roService.GetChargeBalance(finalInput).subscribe(ionResult => {
              let data = [];
              this.ionTypes = JSON.stringify(ionResult.IonBalanceTypes);
              ionResult.IonBalanceTypes.forEach(x => {
                let type = x.Type.split('.')[x.Type.split('.').length - 1];
                data.push(this.inputParam(type, "", 'PpmAsCaCO3', x.AddedIon, x.Name, ConversionSource))
              });
              this.roService.GetConversionByUnitType(data).subscribe(res => {
                res.forEach(result => {
                  ionResult.IonBalanceTypes.forEach(ionElement => {
                    if (ionElement.Name == result.ParameterName) {
                      ionElement['AddedIon'] = Number(result.ConvertedValue)
                    }
                  })
                })
                this.chargeBalanceResult = ionResult;
                this.cbLoaderStatus.emit(false);
                this.setState(ComponentState.ready);
                this.showModal();
              })
            }, error => {
              this.errorInfo = error;
              this.setState(ComponentState.ready);
              if (500 <= error.status && error.status < 600) {
                this.setState(ComponentState.error);
              } else if (error.status === 417) {
                this.router.navigate(['/access-denied']);
              } else if (error.status === 404) {
                this.router.navigate(['/**']);
              } else {
                this.setState(ComponentState.error);
                this.errorHandling.showErrorPopup(error);
              }
            });
          });
      }
      else {
        this.showModal()
      }
    }
    else {
      let controlError = this.waterChemistryForm.controls['pHtb'].errors;
      let errorMessage;
      Object.keys(controlError).forEach(keyError => {
        const errTyp = controlError[keyError];
        if (keyError == 'max') {
          errorMessage = '<p>' + 'pH' + ' should be less than or equal to' + ' ' + errTyp[keyError] + '</p>';
        }
        else if (keyError == 'min') {
          errorMessage = '<p>' + 'pH' + ' should be more than or equal to' + ' ' + errTyp[keyError] + '</p>';
        }
        else {
          errorMessage = '<p>' + 'pH' + ' is ' + keyError + '</p>'
        }
        let validation = {};
        validation['message'] = errorMessage;
        validation['component'] = ROTabs[1];
        this.chargeBalanceValidation.emit(validation);
      });

    }
  }

  //For unit conversion
  getConvertedUnits(value, updateDropdown) {
    this.setState(ComponentState.loading);
    let control;
    if (updateDropdown) {
      control = value.FormControlName.substring(0, value.FormControlName.length - 2);
      let tbvalue = this.waterChemistryForm.get(control.concat('tb')).value;
      let key = this.dimensionalUsageKeys.configurations.filter(x => x.dimensionalUsageKey == 'Roo_' + control)[0];
      this.paramData.push(this.inputParam(key.UnitType, value.previousValue, value.Value, tbvalue, control, key.ConversionSource));
    } else {
      this.paramData.push(value);
    }
    this.roService.GetConversionByUnitType(this.paramData).subscribe(
      response => {
        this.paramData = [];
        if (response) {
          this.setState(ComponentState.ready);
          if (updateDropdown) {
            this.unitConversion = response;
            this.wcData.filter(x => x.InputParam.FormControlName == control.concat('tb'))[0].InputParam.Value = this.unitConversion[0].ConvertedValue;
            this.waterChemistryForm.get(control.concat('tb')).setValue(this.unitConversion[0].ConvertedValue);
            this.setInputParams();
            this.roService.setForms({ 'waterChemistry': this.waterChemistryForm });
          } else {
            this.waterChemistryForm.controls['calculatedIons'].setValue(response[0].ConvertedValue.toFixed(3));
            this.waterChemistryForm.controls['selectedUnit'].setValue(this.prevSelectedUnit);
            this.addedIons.details = {
              Type: this.chargeBalanceInfo.ionDetails.Type,
              Name: value.ParameterName,
              AddedIon: response[0].ConvertedValue.toFixed(3),
              ShortName: this.chargeBalanceInfo.ionDetails.Name
            }
          }
        } else {
          this.setState(ComponentState.empty);
        }
        this.setValidators();
      }, error => {
        this.errorInfo = error;
        this.setState(ComponentState.ready);
        if (500 <= error.status && error.status < 600) {
          this.setState(ComponentState.error);
        } else if (error.status === 417) {
          this.router.navigate(['/access-denied']);
        } else if (error.status === 404) {
          this.router.navigate(['/**']);
        } else {
          this.setState(ComponentState.error);
          this.errorHandling.showErrorPopup(error);
        }
      }
    );
  }


  inputParam(unitType, dropdownValue, conversion, value, name, source) {
    return {
      UnitType: unitType,
      FromUnit: dropdownValue,
      ToUnit: conversion,
      OriginalValue: value,
      ParameterName: name,
      ConversionSource: source
    }
  }

  ngOnChanges() {
  }

  onpHControlChange(event) {
    this.waterChemistryForm.controls['pHSetPoint'].setValue(null);
    if (event.target.value == 'None') {
      this.waterChemistryForm.controls['pHSetPoint'].clearAsyncValidators();
      this.setPointError = false;
    }
    this.roService.changepHControlProduct(event.target.value);
    this.roService.changepHSetPoint(null);
    this.setValidators();
    this.roService.setForms({ 'waterChemistry': this.waterChemistryForm });
  }

  onpHSetpointChange(event) {
    this.roService.changepHSetPoint(event.target.value);
    this.setValidators();
    this.roService.setForms({ 'waterChemistry': this.waterChemistryForm });
    if (this.waterChemistryForm.controls['pHSetPoint'].errors) {
      this.setPointError = true;
    } else {
      this.setPointError = false;
      let tabStatus = {};
      tabStatus['status'] = false;
      tabStatus['tab'] = ROTabs[1];
      this.errorTabStatus.emit(tabStatus);
    }
  }

  showModal() {
    this.setState(ComponentState.ready);
    this.modalRef = this.modalService.open(ChargeBalanceComponent, {
      backdrop: 'static',
      keyboard: false,
      windowClass: 'nalco-charge-balance'
    }).componentInstance;
    this.modalRef.result = this.chargeBalanceResult;
    this.modalRef.chargeBalanceData = this.isChargeBalanceCalculated == true ? this.addedIons.details : null;
    this.modalRef.ionTypes = this.ionTypes;
    this.modalRef.balanceWith = this.addedIons.cleared ? null : this.addedIons.details;
    this.modalRef.modalText = this.translate.instant('RO_OPTIMIZER.WATER_CHEMISTRY.CHARGE_BALANCE');
    this.modalRef.localDriverBtn = false;
    this.modalRef.disablebtn = true;
    this.modalRef.closeAsCancel = true;
    this.modalRef.closeIcon = true;
    this.modalRef.actionBtn = this.translate.instant('COMMON.OK');
    this.modalRef.cancelModal.subscribe(($event) => {
      return false;
    }, error => {
    });
    this.modalRef.submitModal.subscribe(($event) => {
      this.addedIons = $event;
      this.prevSelectedUnit = 'PpmAsCaCO3';
      this.waterChemistryForm.controls['calculatedIons'].setValue(this.addedIons.value);
      this.waterChemistryForm.controls['selectedUnit'].setValue(this.prevSelectedUnit);
      this.commonParams['Temperature'].chargeBalupdated = this.commonParams['Raw Water Flow'].chargeBalupdated = false;
      this.roService.setCommonParams(this.commonParams);
      if (this.addedIons.details) {
        this.isChargeBalanceCalculated = true;
        this.chargeBalanceInfo.ionDetails = {
          Name: this.addedIons.details.ShortName,
          Type: this.addedIons.details.Type,
          Value: this.addedIons.selectedIon !== undefined ? this.addedIons.selectedIon[0].AddedIon : this.chargeBalanceInfo.Value
        }
        this.addedIons.cleared = false;
      }
      else {
        this.addedIons.cleared = true;
      }
      return false;
    }, error => {
    });
    return false;
  }

  updateInput(event) {
    const error = this.waterChemistryForm.controls[event.name].errors;
    this.wcData.forEach(x => {
      if (x.InputParam.FormControlName == event.name) {
        if (error == null) {
          x.Valid = true;
          let tabStatus = {};
          tabStatus['status'] = false;
          tabStatus['tab'] = ROTabs[1];
          this.errorTabStatus.emit(tabStatus);
        } else {
          x.Valid = false;
        }
        x.InputParam.Value = event.updatedValue;
      }
    });
    this.setValidators();
    this.setInputParams();
    this.addedIons.cleared = true;
    this.isChargeBalanceCalculated = false;
    if (this.waterChemistry == 'RawWater' && event.name == 'pHtb' && error == null && this.waterChemistryForm.controls['pHSetPoint'].errors == null) {
      this.setPointError = false;
    }
    this.roService.setForms({ 'chargeBalance': null });
    this.roService.setForms({ 'waterChemistry': this.waterChemistryForm });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.wcSubscription.unsubscribe();
    if (this.controlSubscription) {
      this.controlSubscription.unsubscribe();
    }
    if (this.setPointSubscription) {
      this.setPointSubscription.unsubscribe();
    }
  }

  //On SubUnit change for Added Ions
  subUnitChange(event) {
    this.setState(ComponentState.loading);
    let type = this.addedIons.details.Type.split('.')[this.addedIons.details.Type.split('.').length - 1];
    let cbVal = this.waterChemistryForm.controls['calculatedIons'].value;
    let params = this.inputParam(type, this.prevSelectedUnit, event.target.value, cbVal, this.addedIons.details.Name, ConversionSource);
    this.prevSelectedUnit = event.target.value;
    this.getConvertedUnits(params, false);
  }
}
