import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { DurationSlot } from '../../shared/models/DurationSlot'
@Injectable()

export class DurationSlotService {
  constructor(
  ) { }

  // Observable  source
  private _durationSelectedSource = new BehaviorSubject<DurationSlot>(null);
  public _durationSlotSelectedSource = new Subject<DurationSlot>();
  
  // Observable stream
  public durationSelected$ = this._durationSelectedSource.asObservable();
  
  public getDurationSelected(): DurationSlot {
    return this._durationSelectedSource.value;
  }

  public setDurationSelected(duration: DurationSlot) {
    this._durationSelectedSource.next(duration);
  }

  public setDurationSlotSelected(duration: DurationSlot) {
    this._durationSlotSelectedSource.next(duration);
  }

}
