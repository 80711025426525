export const BarLineChartConfig = {
    chart: {
      style: {
        fontFamily: 'Interstate-Regular'
      }
    },
    title: {
      text: ''
    },
    tooltip: {
      backgroundColor: 'black',
      borderColor: '000000',
      borderRadius: 5,
      useHTML: true,
     
    }
    ,
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false
          }
        }
      },
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: false,
          style:{
            fontSize:'14px'
          }
        },
        tooltip: {
          headerFormat: '<p class="tool-tip-header">{point.x}</p>',
          pointFormatter: function() {
          
            var ret,
                numericSymbols = ['K', 'M', 'bn', 'tn'],
                i = 6;
            if(this.y >=1000) {
            
                while (i-- && ret === undefined) {
                   let multi = Math.pow(1000, i + 1);
                    if (this.y >= multi && numericSymbols[i] !== null) {
                        ret = (this.y / multi) + numericSymbols[i];
                    }
                }
            }
            return '<span class="tooltip-content">$' +(ret ? ret : this.y)+'</span>';
             
        }
          
        }
      },
      line: {
        dataLabels: {
          enabled: true,
          align: 'center',
          borderRadius: 4,
          borderWidth: 1,
          backgroundColor: '#000',
          color: '#fff',
        },

        marker: {
          states: {
            hover: {
              enabled: false
            }
          },
          enabled: false
        },
        color: '#000',
        tooltip: {
          headerFormat: '<p class="tool-tip-header">{point.x}</p>',
          pointFormat: '<span class="tooltip-content">{point.y}</span>'
        }
      }
    },

    credits: {
      enabled: false
    },
    yAxis: [{ // Primary yAxis
      labels: {
        formatter: function() {
         // console.log(this);
            var ret,
                numericSymbols = ['K', 'M', 'bn', 'tn'],
                i = 6;
            if(this.value >=1000) {
                while (i-- && ret === undefined) {
                   let multi = Math.pow(1000, i + 1);
                    if (this.value >= multi && numericSymbols[i] !== null) {
                        ret = (this.value / multi) + numericSymbols[i];
                    }
                }
            }
            return '$' + (ret ? ret : this.value);
        }
    
      },
      title: {
        text: null
      }
    }, { // Secondary yAxis
      title: {
        text: null
      },
      labels: {
        format: '{value}',
        style: {
          color: '#000'
        }
      },
      opposite: true,
    }],
    legend: {
      enabled: false
    },
    projectTCO:
    {
      linearGradient: {
        x1: 1,
        y1: 0,
        x2: 1,
        y2: 1
      },
      stops: [
        [0, '#1C9AEC'],
        [1, '#007AC9'],
      ]
    },
    innovation: {
      linearGradient: {
        x1: 1,
        y1: 0,
        x2: 1,
        y2: 1
      },
      stops: [
        [0, '#7DC1E8'],
        [1, '#5EB6E4'],
      ]
    },
    pointWidths: 80,
    pointWidth: 24,

};
