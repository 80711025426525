import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorManagementService } from '../services/error-management/error-management.service';
@Injectable()
export class HttpErrorCatchingInterceptor implements HttpInterceptor {
  constructor(
    private errorManagementService: ErrorManagementService
  ) { }
  /**
   * @description will intercept all http request and will take action on the error response of http calls
   * @param request an instance of outgoing/incoming HTTP request
   * @param next Transforms an HttpRequest into a stream of HttpEvents, one of which will likely be a HttpResponse. 
   * @returns observable HttpEvent
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((Error) => {
        if (Error instanceof HttpErrorResponse) {
          this.errorManagementService.handleApiError(Error, request);
        }
        return throwError(Error);
      })
    );
  }

}
