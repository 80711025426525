<div
  class="card tile"
  id="{{ id }}"
  [ngClass]="showDragHandlerIcon ? 'dragWidgetEnable' : ''"
>
  <div class="card-header" #header *ngIf="showCardHeader">
    <h3
      class="card-title required-chinese-font"
      #element3
      placement="bottom"
      container="body"
      [ngbTooltip]="tooltipTitle"
      (mouseenter)="getTooltip(element3)"
    >
      {{ headerTitle }}
    </h3>
    <!-- <div #element *ngIf="headerTooltip" class="card-title"  placement="bottom"  [ngbTooltip]="ifElement(headerTitleWithTooltip, element.scrollWidth, element.offsetWidth )">{{headerTitleWithTooltip}}</div> -->
    <div
      #element
      *ngIf="headerTooltip"
      id="card-tooltip"
      class="card-title"
      placement="bottom"
      [ngbTooltip]="
        element.scrollWidth > element.offsetWidth + 1
          ? headerTitleWithTooltip
          : ''
      "
    >
      {{ headerTitleWithTooltip }}
      <span
        *ngIf="
          headerTitleWithTooltip &&
          (headerTitleWithTooltip.length === 25 ||
            headerTitleWithTooltip.length === 26)
        "
        >&nbsp;&nbsp;</span
      >
    </div>
    <div class="card-title-content">
      <a
        *ngIf="paramValues"
        #element1
        placement="bottom"
        class="required-chinese-font"
        [ngbTooltip]="
          element1.scrollWidth > element1.offsetWidth + 1 ? headerAnchor : ''
        "
        [routerLink]="headerAnchorlink"
        [queryParams]="paramValues"
        [ngClass]="!isPrintPreview ? 'card-title' : 'card-title-pdf'"
        >{{ headerAnchor }}
      </a>
      <a
        *ngIf="!paramValues"
        #element1
        placement="bottom"
        (click)="handleHeaderTitleClicked()"
        class="required-chinese-font"
        [ngbTooltip]="
          element1.scrollWidth > element1.offsetWidth + 1 ? headerAnchor : ''
        "
        [routerLink]="headerAnchorlink"
        [ngClass]="!isPrintPreview ? 'card-title' : 'card-title-pdf'"
        >{{ headerAnchor }}
      </a>
      <span *ngIf="showInfoIcon">
        <span
          class="ico-info-thin"
          placement="bottom"
          container="body"
          [ngbTooltip]="tooltipContentt"
        ></span>
        <ng-template #tooltipContentt>
          <p *ngIf="tooltipContent" class="tooltip-content">
            {{ tooltipContent }}
          </p>
          <div class="tooltip-msg" [innerHTML]="htmlContent"></div>
        </ng-template>
      </span>
    </div>
    <h4 class="card-subtitle">{{ headerSubTitle }}</h4>
    <ng-content select="[tile-header]"></ng-content>

    <div class="moreActionIcons">
      <span
        *ngIf="showDeleteIcon"
        class="drag-delete"
        (click)="removeTileItem()"
        placement="bottom"
        [ngbTooltip]="deleteIconNotification"
        container="body"
      ></span>
      <ng-template #deleteIconNotification>
        <p class="tooltip-content">{{ "COMMON.DELETE_WIDGET" | translate }}</p>
      </ng-template>

      <!-- <span *ngIf="showDragHandlerIcon" class="ico-grip" placement="bottom" [ngbTooltip]="dragHandler" container="body"></span>
      <ng-template #dragHandler>
        <p class="tooltip-content">{{'COMMON.MOVE_WIDGET' | translate}}</p>
      </ng-template> -->
    </div>

    <div class="right-aligned">
      <span *ngIf="showFavourites">
        <nalco-favorite
          [id]="details?.id"
          [isFavourite]="details?.isFavorite"
          (favouriteToggled)="onfavouriteToggle($event)"
        ></nalco-favorite>
      </span>
      <span
        *ngIf="isCollapsible && !disableHeader"
        (click)="isCollapsed = !isCollapsed"
      >
        <span
          class="accordion-caret"
          [ngClass]="{ collapsed: isCollapsed }"
        ></span>
      </span>

      <span *ngIf="showMoreOptions" (click)="isCollapsed = false">
        <nalco-more-option
          #moreOption
          [showExportAsPDF]="showExportAsPDF"
          [showAuditDetails]="showAudit"
          [showAccountDetails]="showAccountDetails"
          [showAddToReport]="showAddToReport"
          [showExportAsImage]="showExportAsImage"
          [useGraphExport]="useGraphExport"
          [uid]="id"
          (exportAsCsvClick)="handleExportAsCsvClick()"
          (exportAsAccountClick)="handleExportAsAccountClick()"
          (exportAsAuditClick)="handleExportAsAuditClick()"
          [showExportAsCsv]="showExportAsCsv"
          [fileName]="fileName"
          [currentDays]="days"
          [epxortWithInfo]="epxortWithInfo"
          [epxortWithInfoDetails]="epxortWithInfoDetails"
          [siteName]="siteName"
          [imageTitle]="imageTitle"
          [companyName]="companyName"
          [exportDateText]="exportDateText"
          [pageLevelFilterText]="pageLevelFilterText"
          [timeRangeText]="timeRangeText"
          [titleText]="titleText"
          [divisionByText]="divisionByText"
          [isTimeRange]="isTimeRange"
          [isTCOPipeline]="isTCOPipeline"
          [selectedFIDuration]="selectedFIDuration"
          [selectedDivisions]="selectedDivisions"
          [isExportToImageSSD]="isExportToImageSSD"
          [exportedDateFormat]="exportedDateFormat"
          (moreOptionPrintWidgetClicked)="setPrintWidgetOption()"
          [showDownloadConfig]="showDownloadConfig"
          (downloadConfigurationClick)="handleDownloadConfigurationClick()"
          [showSystemHealth]="showSystemHealth"
          (systemHealthClick)="handleSystemHealthClick()"
          [showGatewayStatistics]="showGatewayStatistics"
          (gatewayStatisticsClick)="handleGatewayStatisticsClick()"
          [showDataDownload]="showDataDownload"
          (dataDownloadClick)="handleDataDownloadClick()"
          [showHardwareDetails]="showHardwareDetails"
          (hardwareDetailsClick)="handleHardwareDetailsClick()"
          [showAlarmNotification]="showAlarmNotification"
          (alarmNotificationClick)="handleAlarmNotificationClick()"
          [showAlarmList]="showAlarmList"
          (alarmListClick)="handleAlarmListClicked()"
          [filterDetails]="filterDetails"
          [monthlyQuarterly]="monthlyQuarterly"
          [dateRangeObject]="dateRangeObject"
          [quickFiltersText]="quickFiltersText"
          [quickFilterData]="quickFilterData"
        >
        </nalco-more-option>
      </span>
      <span *ngIf="showTrendLines">
        <nalco-filter-pills
          class="line-chart-pills pill-borders margin"
          [primaryFilters]="chartLegends"
        >
        </nalco-filter-pills>
      </span>
      <span *ngIf="showExpandCollapse">
        <button
          class="k-button"
          *ngIf="expandState"
          (click)="collapseAllClicked()"
        >
          Collapse All
        </button>
        <button
          class="k-button"
          *ngIf="!expandState"
          (click)="expandAllClicked()"
        >
          Expand All
        </button>
      </span>
    </div>
  </div>
  <div
    class="tile-content-wrapper"
    [ngClass]="{ collapsed: isCollapsible && isCollapsed }"
  >
    <ng-content
      select="[tile-content]"
      *ngIf="(consumerState | async)?.IsReady"
    ></ng-content>

    <!-- LOADING -->
    <ng-container
      *ngTemplateOutlet="
        (consumerState | async)?.IsLoading ? defaultLoadingTemplate : null
      "
    ></ng-container>

    <!-- ERROR -->
    <ng-container
      *ngTemplateOutlet="
        (consumerState | async)?.IsError ? defaultErrorTemplate : null
      "
    ></ng-container>

    <!-- EMPTY -->
    <ng-container
      *ngTemplateOutlet="
        (consumerState | async)?.IsEmpty ? defaultEmptyTemplate : null
      "
    ></ng-container>
  </div>
</div>

<!-- default loading template -->
<ng-template #defaultLoadingTemplate>
  <div class="state-wrapper loading" [@stateChangeAnimation]>
    <span class="state-icon">
      <i [class]="loadingIconClass"></i>
    </span>
    <span class="state-description">
      <span [class]="loadingTitleClass">{{ loadingTitleText }}</span>
      <span [class]="loadingMessageClass">{{ loadingMessageText }}</span>
    </span>
  </div>
</ng-template>
<!-- default loading template -->
<ng-template #defaultErrorTemplate>
  <div class="state-wrapper error" [@stateChangeAnimation]>
    <span class="state-icon">
      <i [class]="errorIconClass"></i>
    </span>
    <span class="state-description">
      <span [class]="errorTitleClass">{{ errorTitleText }}</span>
      <span class="errorClass" (click)="errorPopUp($event)">{{
        errorMessageText
      }}</span>
      <span [class]="errorTitleClass">{{ errorContent }}</span>
    </span>
  </div>
</ng-template>
<!-- default loading template -->
<ng-template #defaultEmptyTemplate>
  <div class="state-wrapper" [@stateChangeAnimation]>
    <span class="state-icon">
      <i [class]="emptyIconClass"></i>
    </span>
    <span class="state-description">
      <span [class]="emptyTitleClass">
        <span class="defaultDataText p-2 required-chinese-font">{{
          emptyTitleText
        }}</span>
      </span>
    </span>
  </div>
</ng-template>
