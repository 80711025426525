<nav class="navbar">
  <div class="logo_item">
    <a class="ml-1" *ngIf="!isMobile" (click)="expandNavBar()">
      <img src="../../../assets/images/menus/menu-hamburger-icon.svg" class="hamburger-class-img" />
    </a>
    <a class="ml-1" *ngIf="isMobile && !isExpand" (click)="expandNavBar()">
      <img src="../../../assets/images/menus/Hamburger-Mobile.svg"/>
    </a>
    <a class="ml-1" *ngIf="isMobile && isExpand" (click)="onCloseNavBar()" >
      <img src="../../../assets/images/menus/Close.svg" />
    </a>
    <a class="logo_img img-fluid margin-left" (click)="clickBtn()">
      <img src="../../../../assets/images/Ecolab3D_Logo.svg" alt="ECOLAB"  />
    </a>
    <div class="header-divider" *ngIf="!isMobile">
      <nalco-divider [color]="dividerCss.color" [length]="dividerCss.minLength" [isHorizontal]= dividerCss.isHorizontal></nalco-divider>
    </div>
    <h5 class="heading-small page-title"  *ngIf="!isMobile">{{pageTitle | translate}}</h5>
  </div>
  <div class="navbar-globalsearch">
    <nalco-global-search-dropdown></nalco-global-search-dropdown>
  </div>
  <!-- For Future Search Bar Css  -->
  <!-- <div class="search_bar">
        <input type="text" placeholder="Search" />
      </div> -->
  <div>
    <nalco-menu class="expandmenu"></nalco-menu>
  </div>
  <div class="navbar_content margin-right" >
    <div class="notification-class"><nalco-notification (loader)="handleLoading($event)"></nalco-notification></div>
    <span class="span-round" (click)="showDropdownForLogout()">{{Initials}}</span>
    <div class="profile-details">
      <label class="profile-label">{{userInfo.FirstName}} {{userInfo.LastName}}</label>
      <!-- For Future Role Css  -->
      <!-- <p class="role-label">Role</p></div> -->
    </div>
    <div class="custom-dropdown">
      <a class="custom-dropdown-link" *ngIf="!isMobile" >
        <img class="custom-dropdown-img" src="../../../assets/images/menus/arrows/arrow-down.svg">
      </a>
      <div class="custom-dropdown-menu" *ngIf="showDropdownMenu">
        <nalco-user-profile-details [user]="userInfo"></nalco-user-profile-details>
      </div>
    </div>
  </div>
  <!-- Commenting for 20240620 release only -->
  <!-- <div class="threedot-logo" id="dropdownBasic1" ngbdropdown   [ngClass]="isMobile && !isHomePage ? ' ' : 'display-none'">
    <a class="ml-1">
      <img src="../../../assets/images/3dot-vertical.svg" (click)="showThreeDotOptionsMenu()"   class="threedot-class-img" />
    </a>
  </div>
  <div ngbDropdown  *ngIf="showThreeDotOptions" class="custome-threedot-menu">
    <div  id="dropdownConfig">
      <button  class="dropdown-item" translate>COMMON.PROFILE</button>
      <button  class="dropdown-item" translate>COMMON.NOTIFICATIONS</button>
    </div>   
  </div> -->
</nav>
<!-- this is the temproray fix for the sub header for time being  -->


<nalco-sub-header  *ngIf="user?.authenticated && !(hideSubHeader && isSubHeaderHidden)" [isExpand] = "isNewUIEnabled? isExpand : false" [isHomePage] = "isHomePage"></nalco-sub-header>

