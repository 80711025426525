import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { DurationOption } from '../models/DurationOption';
import { StartEndDate } from '../models/StartEndDate';

@Injectable()
export class DateRangeService {
  constructor(
    @Inject('defaultDateRange') private defaultOption: DurationOption,
    @Inject('startEndDate') private startEndDate: StartEndDate
  ) { }

  // Observable  source
  private _durationSource = new BehaviorSubject<DurationOption>(this.defaultOption);
  private _startEndSource = new BehaviorSubject<StartEndDate>(this.startEndDate);
  // Observable stream
  public duration$ = this._durationSource.asObservable();
  public startEnd$ = this._startEndSource.asObservable();

  public getDuration(): DurationOption {
    return this._durationSource.value;
  }

  public setDuration(duration: DurationOption) {
    this._durationSource.next(duration);
  }

  public getStartEnd(): StartEndDate {
    return this._startEndSource.value;
  }

  public setStartEnd(dateRange: StartEndDate) {
    this._startEndSource.next(dateRange);
  }
}
