/* eslint-disable @typescript-eslint/consistent-type-imports */
import { Component, Input , OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ISubheaderLeftSection} from '../../shared-main/components/sub-header/sub-header.modal';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IBreadcrumb } from './contracts/IBreadcrumb';
import { SubheaderHandlerService } from '../../core/nav/subheader-handler.service';
import { BreadcrumbConstant } from './constants/breadcrumb.constant';

@Component({
    selector: 'nalco-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    //styleUrls: ['./breadcrumb.component.scss']
})


export class BreadcrumbComponent implements OnInit {
  breadcrumbData: IBreadcrumb[];
  completeBreadcrumbData: IBreadcrumb[];
  isBreadcrumbExpanded = false;
  leftSection: ISubheaderLeftSection;
  showFullText = false;
  ssd = false;
  @Input() data: IBreadcrumb[];
  newData = BreadcrumbConstant.ellipsis;

  private subscriptions: Subscription[] = [];
  surveys: boolean;
  constructor(private router: Router, private subHeaderService: SubheaderHandlerService, private http: HttpClient) {}

  ngOnInit() {
    this.subscriptions.push(this.subHeaderService.subheaderLeftSection$.subscribe(async () => {
      this.ssdBreadcrumb();
    }));
  }

  /**
   * @description - Evaluates the router URL to determine the page context and sets flags ('ssd' and 'surveys') accordingly.
   * @returns -  This method does not return a value.
   */
  ssdBreadcrumb(): void {
    if (this.router.url === BreadcrumbConstant.SERVICE_SUMMARY_URL  || this.router.url === BreadcrumbConstant.E_DATA_ENTRY_URL) {
      this.ssd = true;
    }

    if (this.router.url.includes('surveys')) {
      this.surveys = true;
    }

  }

  /**
   * @description  Handles the click event on a specific element.
   * @param event - The click event object.
   * @param data - Additional data associated with the click event.
   * @returns - This method does not return a value.
   */
  handleClick(event: Event, data:IBreadcrumb[]): void {
    const targetElement = event.target as HTMLElement;
    const isThreeDotsClicked = targetElement.innerText.includes('...');
    if (isThreeDotsClicked) {
      this.toggleText(event, data);
    } else {
      setTimeout(() => {
        this.newData = BreadcrumbConstant.ellipsis;
      }, 2000);
     }
  }

  /** 
   * @description - Toggles the display of full text, updating 'showFullText' and capturing a partial portion of the provided 'data' array
   * @param event - The event object associated with the trigger.
   * @param data - The array containing text to be toggled.
   * @returns - This method does not return a value.
   */
  toggleText(event: Event, data: IBreadcrumb[]): void {
    this.showFullText = !this.showFullText;
    this.newData = [];
    data.slice(1, -1).forEach(item => {
      this.newData.push(item);
    });
    event.preventDefault();
  }
}
