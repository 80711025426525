// angular modules packages
import { Injectable } from '@angular/core';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';

// custom modules components
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import { AppEnvConfig } from '../../app.env.config';
import type { IConfig, IDataSource, INotification } from '../_contracts';
import { ApplicationTypes, NotificationCategoryType } from '../_enum/constants';
import { NotificationServiceConfig } from './notification.service.config';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class NotificationService {

  // declare all the variables
  private configEnv: IConfig;
  public url: string;
  public contextPoint: number;
  public jsonServerUrl: string;
  public dagType: string;
  public checkMaxDags: string;
  private serialNumber = new BehaviorSubject<string>(null);
  serialNumber$ = this.serialNumber.asObservable();
  private refreshNotification = new BehaviorSubject<boolean>(false);
  refreshNotification$ = this.refreshNotification.asObservable();
  private category = new BehaviorSubject<string>(null);
  category$ = this.category.asObservable();

  // constructor
  constructor(
    private notificationServiceConfig: NotificationServiceConfig,
    private httpClient: HttpClient,
    private config: AppEnvConfig) {

    this.configEnv = notificationServiceConfig.getEnvironment();
    this.configEnv.apiServerUrl = config.getEnv('apiServerUrl');
    this.configEnv.jsonServerUrl = config.getEnv('jsonServerUrl');
    this.configEnv.apiServerUrlAuth = config.getEnv('apiServerUrl');
    this.configEnv.msrAggregatorServerUrl = config.getEnv('msrAggregatorServerUrl'),
    this.configEnv.contextPoint = 0;
    this.dagType = 'NalcoWater/';
    this.checkMaxDags = '/true/';
  }

  /**
   * @description To get all the Unread Notifications.
   * @returns INotification[]
   */
  getAllUnreadNotifications(): Observable<INotification[]> {
    const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getAllNotification}?applicationType=${ApplicationTypes.ECOLAB3DWEBAPP}&notificationCategoryType=${NotificationCategoryType.NOTIFICATIONPAYLOAD}`;
    return this.httpClient.get(url).map((response: INotification[]) => {
      return response;
    });
  }

  /**
   * @description To get the DataSource Mapping Details.
   * @param contextPointId
   * @returns IDataSource[]
   */
  public getDataSourceMappingDetails(contextPointId: number, oldDataSourceId: number, newDataSourceId: number): Observable<IDataSource[]> {
    this.url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getDataSourceMappingDetails,
      [contextPointId.toString(), oldDataSourceId.toString(), newDataSourceId.toString()]) + '?dataSourceType=Controller';
    return this.httpClient.get(this.url).map((response: IDataSource[]) => {
      return response;
    });
  }

  /**
   * @description To concatenate url with dynamic params
   * @param url
   * @param params
   * @returns string
   */
  private parse(url: string, params: string[] = []): string {
    let result: string = url;
    if (params.length > 0) {
      // tslint:disable-next-line:no-unused-variable
      result = url.replace(/\{(\d+)\}/g, function (match: string, current: string): string {
        return params[parseInt(current, 10)] !== undefined ? params[parseInt(current, 10)] : '';
      });
    }
    return result;
  }

  /**
   * @description: To copy mapping from old to new data source.
   * @param dagId
   * @param sourceDatasouceCpId
   * @param destinationDatasouceCpId
   * @param isRemapToAllUnmappedDs
   * @returns boolean
   */
  public copyMappingToDatasource(dagId: number, oldDatasourceCpId: number, newDatasourceCpId : number): Observable<boolean> {
    const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.copyMappingToDatasource}?dagId=${dagId}&newDatasourceCpId=${newDatasourceCpId}&oldDatasourceCpId=${oldDatasourceCpId}&isRemapToAllUnmappedDs=true`;
    return this.httpClient.post(url,{}).map((response: boolean) => {
        return response;
    });
  }

  /**
   * @description: To set the value of serial number.
   * @param value
   * @returns void
   */
  setSerialNumber(value: string): void{
    this.serialNumber.next(value);
  }

  /**
   * @description: To get the value of serial number.
   * @returns string
   */
  getSerialNumber() : Observable<string>{
    return this.serialNumber$;
  }

  /**
   * @description: To set the value of notificationCategoryType.
   * @param value
   * @returns void
   */ 
  setNotificationCategory(value: string): void{
    this.category.next(value);
  } 

  /**
   * @description: To get the value of notificationCategoryType.
   * @returns string
   */
  getNotificationCategory() : Observable<string>{
    return this.category$;
  }

  /**
   * @description: To set the value of refreshNotification flag.
   * @param value
   * @returns void
   */
  setMapped(value: boolean): void{
    this.refreshNotification.next(value);
  }

  /**
   * @description: To get the value of refreshNotification flag.
   * @returns boolean
   */
  getMapped() : Observable<boolean>{
    return this.refreshNotification$;
  }

  /**
   * @description to retry replication of measurements and limits
   * @params notification id
   * @returns boolean or string value 
  */
  public retryReplicateOrMoveMeasurementsAndLimits(id: string): Observable<any> {
    const url = `${this.configEnv.msrAggregatorServerUrl}${this.configEnv.apiUrl.retryReplicateOrMoveMeasurementsAndLimits}`;
    return this.httpClient.post(url, id).map(response => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }
}
