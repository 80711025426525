
import { IIdAndName, IAssetMeasurement , ISiteAsset } from '../contracts/_contracts';
import { IdandName } from './IdandName';
import { AssetMeasurement } from './AssetMeasurement';
export class SiteAsset implements ISiteAsset {
    id: string;
    name: string;
    assetType?: IIdAndName;
    isFavourite?: boolean;
    category?: string;
    assetMeasurement?: IAssetMeasurement[];
    constructor(SiteAsset?: ISiteAsset) {
        if (SiteAsset) {
            this.id = SiteAsset.id;
            this.name = SiteAsset.name;

            if (SiteAsset.isFavorite) {
                this.isFavourite = SiteAsset.isFavorite;
            }

            if (SiteAsset.measurements) {
                this.assetMeasurement = new Array<AssetMeasurement>();
                for (let measurement of SiteAsset.measurements) {
                    this.assetMeasurement.push(new AssetMeasurement(measurement));
                }
            }

            if (SiteAsset.assetType) {
                this.assetType = new IdandName(SiteAsset.assetType);
            }
        }
    }
}
