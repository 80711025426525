import * as contracts from "../contracts/_contracts";

export class DAGSites implements contracts.IDAGSites {
    DagId: string;
    DagName: string;
    ContextPointName: string;
    Country: string;
    CorpAccountId: string;
    CorpAccountName: string;
    ContextPointId: string;
    SiteName: string;
    SiteNameEnglish: string;
    FriendlyName: string;
    Street: string;
    City: string;
    StateProvince: string;
    PostalCode: string;
    Latitude: string;
    Longitude: string;
    ModifiedBy: string;
    ModifiedOn: Date;
    CdmSiteNo: string;
    Elevation: string;
    CoordinateType: string;
    CoordinateModifiedOn: Date;
    constructor(DAGsite?: contracts.IDAGSites) {
        if (DAGsite) {
            this.DagId = DAGsite.DagId;
            this.DagName = DAGsite.DagName;
            this.ContextPointName = DAGsite.ContextPointName;
            this.Country = DAGsite.Country;
            this.CorpAccountId = DAGsite.CorpAccountId;
            this.CorpAccountName = DAGsite.CorpAccountName;
            this.ContextPointId = DAGsite.ContextPointId;
            this.SiteName = DAGsite.SiteName;
            this.SiteNameEnglish = DAGsite.SiteNameEnglish;
            this.FriendlyName = DAGsite.FriendlyName;
            this.Street = DAGsite.Street;
            this.City = DAGsite.City;
            this.StateProvince = DAGsite.StateProvince;
            this.PostalCode = DAGsite.PostalCode;
            this.Latitude = DAGsite.Latitude;
            this.Longitude = DAGsite.Longitude;
            this.ModifiedBy = DAGsite.ModifiedBy;
            this.ModifiedOn = DAGsite.ModifiedOn;
            this.CdmSiteNo = DAGsite.CdmSiteNo;
            this.Elevation = DAGsite.Elevation;
            this.CoordinateType = DAGsite.CoordinateType;
            this.CoordinateModifiedOn = DAGsite.CoordinateModifiedOn;
        }

    }
}