import { IFailedDataSourceDetail } from '../_contracts/IFailedDataSourceDetail';

export class FailedDataSourceDetail implements IFailedDataSourceDetail{
    ContextPointId: number;
    ContextPointType: string;
    ContextPointSubTypeId: number;
    RequestGuid: string
    constructor(details?: IFailedDataSourceDetail) {
        if(details){
            this.ContextPointId = details.ContextPointId;
            this.ContextPointType = details.ContextPointType;
            this.ContextPointSubTypeId = details.ContextPointSubTypeId;
            this.RequestGuid = details.RequestGuid;
        }
    }
}