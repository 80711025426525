import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SubheaderHandlerService } from '../../nav/subheader-handler.service';

@Component({
    selector: 'nalco-consent-page',
    templateUrl: './consent-page.component.html',
    styleUrls: ['./consent-page.component.scss']
})
export class ConsentPageComponent implements OnInit {

    isChecked: boolean = false;

    subheaderData = {
        leftSection: {
            type: '',
            data: '',
            className: 'col-lg-6'
        },
        middleSection: {
            type: '',
            data: [],
            className: ''
        },
        rightSection: {
            type: '',
            data: [],
            className: ''
        }
    };
    constructor(
        private router: Router,
        private subHeaderService: SubheaderHandlerService
    ) {
        this.subheaderData.leftSection.data = '';
        this.subHeaderService.updateLeftSection(this.subheaderData.leftSection);
        this.subHeaderService.updateMiddleSection(this.subheaderData.middleSection);
        this.subHeaderService.updateRightSection(this.subheaderData.rightSection);
    }

    handleCheckboxChange(event: Event) {
        const checkbox = event.target as HTMLInputElement;
        this.isChecked = checkbox.checked;
    }
    agree() {
        this.router.navigate(['/quality']);
    }
    cancel() {
        this.router.navigate(['/home']);
    }
    ngOnInit() {
    }
}
