import {Injectable } from '@angular/core';
import { AppEnvConfig } from '../../app.env.config';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  private configData: AppEnvConfig; 
  setConfig(appConfig: AppEnvConfig) {
    this.configData = appConfig;
  }

  getConfig() {
    return this.configData;
  }
}
