import {
    IContextPointConfigurations, IContextPointLite,
    IDataCaptureDimensionalSubUnit,
    IMapping,
    IMeasurement, IMeasurementInstanceConfigurations,
    IMeasurementInstanceLite,
    IMeasurementOptionDetails,
} from "../_contracts/IConfig";
import { IMeasurementLimit } from '../_contracts/IHistoricalData';
import { MeasurementLimit } from "./HistoricalData";


export class ContextPointConfigurations implements IContextPointConfigurations {
    ContextPointLite: ContextPointLite;
    MeasurementInstanceConfigurations: MeasurementInstanceConfigurations[] = [];
    order: number = 0;
    constructor(parameter?: ContextPointConfigurations) {
        if (parameter) {
            this.ContextPointLite = new ContextPointLite(parameter.ContextPointLite);
            if (parameter.MeasurementInstanceConfigurations.length > 0) {
                parameter.MeasurementInstanceConfigurations.forEach(value => {
                    this.MeasurementInstanceConfigurations.push(new MeasurementInstanceConfigurations(value))
                })
            }
        }
    }
}

export class Mapping implements IMapping {
    MeasurementInstanceId: number = 0;
    SequenceNumber: number = 0;
    constructor(mapping?: Mapping) {
        if (mapping) {
            this.MeasurementInstanceId = mapping.MeasurementInstanceId;
            this.SequenceNumber = mapping.SequenceNumber;
        }
    }
}

export class ContextPointLite implements IContextPointLite {
    ParentId: number = 0;
    ContextPointId: number = 0;
    ContextPointName: string = '';
    ContextPointSubTypeId: number = 0;
    ContextPointSubTypeName: string = '';
    expand:boolean = true;
    ParentName: string = '';
    ContextPointType: number = 0;
    constructor(contextPointLite?: ContextPointLite) {
        if (contextPointLite) {
            this.ParentId = contextPointLite.ParentId;
            this.ContextPointId = contextPointLite.ContextPointId;
            this.ContextPointName = contextPointLite.ContextPointName;
            this.ContextPointSubTypeId = contextPointLite.ContextPointSubTypeId;
            this.ContextPointSubTypeName = contextPointLite.ContextPointSubTypeName;
            this.expand = true;
            this.ParentName = contextPointLite.ParentName;
            this.ContextPointType = contextPointLite.ContextPointType;
        }
    }
}

export class MeasurementInstanceConfigurations implements IMeasurementInstanceConfigurations {
    MeasurementInstanceLite: MeasurementInstanceLite;
    DataCaptureDimensionalSubUnit: DataCaptureDimensionalSubUnit;
    MeasurementLimit: MeasurementLimit;
    Attributes: [];
    MeasurementOptionDetails: MeasurementOptionDetails[];
    isInfinity: boolean = false;
    EnvisionSamplePointName: string;
    measurementComments: boolean | string;
    constructor(measurementInstanceConfigurations?: MeasurementInstanceConfigurations) {
        if (measurementInstanceConfigurations) {
            this.MeasurementInstanceLite = measurementInstanceConfigurations.MeasurementInstanceLite
            this.MeasurementLimit = new MeasurementLimit(measurementInstanceConfigurations.MeasurementLimit);
            this.DataCaptureDimensionalSubUnit = measurementInstanceConfigurations.DataCaptureDimensionalSubUnit;
            this.Attributes = measurementInstanceConfigurations.Attributes;
            this.MeasurementOptionDetails = measurementInstanceConfigurations.MeasurementOptionDetails;
            this.isInfinity = false;
            this.EnvisionSamplePointName = measurementInstanceConfigurations.EnvisionSamplePointName;
            this.measurementComments = measurementInstanceConfigurations.measurementComments;
        }
    }
}

export class MeasurementInstanceLite implements IMeasurementInstanceLite {
    Measurement: Measurement;
    DisplayName: string = '';
    state: number = 1;
    MeasurementInstanceId: number = 0;
    SourceId: number = 0;
    measurementComments: false;
    ContextPointId = 0;
    OriginType: any;
    SourceApplication: any;
    constructor(measurementInstanceLite?: MeasurementInstanceLite) {
        if (measurementInstanceLite) {
            this.Measurement = measurementInstanceLite.Measurement;
            this.state = measurementInstanceLite.state;
            this.DisplayName = measurementInstanceLite.DisplayName;
            this.MeasurementInstanceId = measurementInstanceLite.MeasurementInstanceId;
            this.SourceId = measurementInstanceLite.MeasurementInstanceId;
            this.measurementComments = false;
            this.ContextPointId = measurementInstanceLite.ContextPointId;
            this.OriginType = measurementInstanceLite.OriginType;
            this.SourceApplication = measurementInstanceLite.SourceApplication;
        }
    }
    StuiName: string;
    MeasurementSource: string;
}

export class Measurement implements IMeasurement {
    MeasurementId: number = 0;
    MeasurementName: string = '';
    NalcoNumericsUnitOrSpecies: string = '';
    OptionGroupId: number;
    constructor(measurement?: Measurement) {
        if (measurement) {
            this.MeasurementId = measurement.MeasurementId;
            this.MeasurementName = measurement.MeasurementName;
            this.NalcoNumericsUnitOrSpecies = measurement.NalcoNumericsUnitOrSpecies;
            this.OptionGroupId = measurement.OptionGroupId
        }
    }
}

export class DataCaptureDimensionalSubUnit implements IDataCaptureDimensionalSubUnit {
    UnitName: string;
    SignificantDigits: number;
    Symbol: string;
    IsBaseUnit: boolean;
    constructor(subUnit?: DataCaptureDimensionalSubUnit) {
        if (subUnit) {
            this.UnitName = subUnit.UnitName;
            this.SignificantDigits = subUnit.SignificantDigits;
            this.Symbol = subUnit.Symbol;
            this.IsBaseUnit = subUnit.IsBaseUnit;
        }
    }

}
export class MeasurementOptionDetails implements IMeasurementOptionDetails {
    IsEnabled: boolean;
    OptionGroupId: number;
    OptionId: number;
    OptionName: string;
    OptionResourceName: string;
    constructor(option?: MeasurementOptionDetails) {
        if (option) {
            this.IsEnabled = option.IsEnabled;
            this.OptionGroupId = option.OptionGroupId;
            this.OptionId = option.OptionId;
            this.OptionName = option.OptionName;
            this.OptionResourceName = option.OptionResourceName
        }
    }
}

