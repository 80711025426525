import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ErrorHandling } from '../../../shared/components/error-handling/error-handling';
import { XdComponentState } from '../../../shared/components/xd-container/xd-component';
import { ComponentState } from '../../../shared/components/xd-container/xd-component-state';
import { Units } from '../../_enum/constants';
import { ScenarioType } from '../../_enum/scenario-type.enum';
import { ROAsset } from '../../models/ROAssetDetails';
import { RODataModel, ROMeasures } from '../../models/ROMeasures';
import { SharedRoService } from '../../services/shared-ro.service';


@Component({
  selector: 'nalco-system',
  templateUrl: './system.component.html',
  styleUrls: ['./system.component.scss']
})

export class SystemComponent extends XdComponentState implements OnInit, OnChanges {
  @Input() siteId;
  @Input() componentReady;
  @Input() saveSuccess;
  @Output() scenarioData = new EventEmitter<ROMeasures>();
  @Output() tab = new EventEmitter<boolean>();
  systemDetailsForm: UntypedFormGroup;
  Assets: Array<ROAsset>;
  errorInfo: any;
  errorHandling = new ErrorHandling(this.modalService, this.translate);
  Units: Array<any> = [
    { id: 1, name: Units[0] },
    { id: 2, name: Units[1] }
  ];
  assetsDropdown: any = [];
  scenariosDropdown: any = [];
  selectedAsset: any;
  selectedScenario: any;
  scenarioInfo: ROMeasures;
  proScenario: RODataModel;
  oipScenario: RODataModel;
  disableOIP = true;
  disablePRO = true;
  initialCaseInfo: string;
  lastSavedInfo: string;
  isScenarioAtOIP: boolean = false;


  constructor(private fb: UntypedFormBuilder,
    private translate: TranslateService,
    private roService: SharedRoService,
    private router: Router,
    private modalService: NgbModal,
  ) {
    super();
    this.createForm();
  }

  myParentInstance(): SystemComponent{
    return this;
  }

  ngOnInit() {
    this.setState(ComponentState.ready);
    this.getAssets(this.siteId);
  }

  getAssets(siteId) {
    this.roService.GetRoOptimizerAssets(siteId).subscribe(
      response => {
        if (response) {
          this.Assets = response;
          this.Assets.forEach(asset => {
            this.assetsDropdown.push({ AssetId: asset.AssetScenarioInfo.AssetId, AssetName: asset.AssetName, scenarioCount: asset.AssetScenarioInfo.ScenarioInfo.length });
          });
          if (this.assetsDropdown.length === 1) {
            this.systemDetailsForm.controls['Asset'].setValue(this.assetsDropdown[0]);
            this.onAssetChange();
          } else {
            this.setState(ComponentState.ready);
          }
        } else {
          this.setState(ComponentState.empty);
        }
      }, error => {
        this.errorInfo = error;
        this.setState(ComponentState.ready);
        if (500 <= error.status && error.status < 600) {
          this.setState(ComponentState.error);
        } else if (error.status === 417) {
          this.router.navigate(['/access-denied']);
        } else if (error.status === 404) {
          this.router.navigate(['/**']);
        } else {
          this.setState(ComponentState.error);
          this.errorHandling.showErrorPopup(error);
        }
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.componentReady && changes.componentReady.currentValue) || (changes.saveSuccess && changes.saveSuccess.currentValue)) {
      this.setState(ComponentState.ready)
    }
    else {
      this.setState(ComponentState.loading)
    }
  }

  onAssetChange() {
    this.disableOIP = true;
    this.disablePRO = true;
    this.selectedScenario = null;
    this.systemDetailsForm.controls['ScenarioName'].setValue(null);
    this.systemDetailsForm.controls['PreferredUnits'].setValue(null);
    this.tab.emit(true);
    this.scenariosDropdown = [];
    this.selectedAsset = this.systemDetailsForm.get('Asset').value;
    this.Assets.forEach(a => {
      if (a.AssetScenarioInfo.AssetId == this.selectedAsset.AssetId) {
        a.AssetScenarioInfo.ScenarioInfo.forEach(sc => {
          this.scenariosDropdown.push({ ScenarioId: sc.ScenarioId, ScenarioName: sc.ScenarioName });
        });
      }
    });
    if (this.scenariosDropdown.length === 1) {
      this.systemDetailsForm.controls['ScenarioName'].setValue(this.scenariosDropdown[0]);
      this.onScenarioChange();
    }
  }

  onScenarioChange() {
    this.selectedScenario = this.systemDetailsForm.get('ScenarioName').value;
    this.setState(ComponentState.loading);
    this.disableOIP = true;
    this.disablePRO = true;
    this.oipScenario = new RODataModel();
    this.proScenario = new RODataModel();
    this.scenarioInfo = new ROMeasures();
    this.tab.emit(true);
    this.systemDetailsForm.controls['PreferredUnits'].setValue(null);
    this.roService.GetRoOptimizerScenarioInfo(this.selectedScenario.ScenarioId, this.selectedAsset.AssetId).subscribe(
      response => {
        if (response) {
          response.forEach(sc => {
            if (sc.rOOptimizerScenarioInfo !== null) {
              if (sc.Source == ScenarioType.PRO) {
                this.proScenario = sc;
                this.disablePRO = false;
                this.initialCaseInfo = this.translate.instant('RO_OPTIMIZER.SYSTEM.INITIAL_CASE_INFO') + moment(this.proScenario.rOOptimizerScenarioInfo.CreatedDate).format('YYYY/MM/DD HH:mm');
              } else if (sc.Source == ScenarioType.OIP) {
                this.isScenarioAtOIP = true;
                this.oipScenario = sc;
                this.disablePRO = false;
                this.disableOIP = false;
                this.lastSavedInfo = this.translate.instant('RO_OPTIMIZER.SYSTEM.LAST_SAVED_INFO') + this.oipScenario.rOOptimizerScenarioInfo.LastUpdatedByEmail + ' on ' + moment(this.oipScenario.rOOptimizerScenarioInfo.ModifiedDate).format('YYYY/MM/DD HH:mm');
              }
            }
          });
          this.setState(ComponentState.ready);
        } else {
          this.setState(ComponentState.empty);
        }
      }, error => {
        this.errorInfo = error;
        this.setState(ComponentState.ready);
        if (500 <= error.status && error.status < 600) {
          this.setState(ComponentState.error);
        } else if (error.status === 417) {
          this.router.navigate(['/access-denied']);
        } else if (error.status === 404) {
          this.router.navigate(['/**']);
        } else {
          this.setState(ComponentState.error);
          this.errorHandling.showErrorPopup(error);
        }
      }
    );
  }

  onUnitsChange(event) {
    this.roService.changePreferredUnit(event.target.value);
    this.tab.emit(true);
  }

  loadOIP() {
    if (this.saveSuccess) {
      this.systemDetailsForm.controls['PreferredUnits'].setValue(null);
    }
    if (this.scenarioInfo !== this.oipScenario.rOOptimizerScenarioInfo) {
      this.scenarioInfo = this.oipScenario.rOOptimizerScenarioInfo;
      if (!this.systemDetailsForm.controls['PreferredUnits'].value) {
        this.systemDetailsForm.controls['PreferredUnits'].setValue(this.scenarioInfo.ScenarioUnit);
      }
      this.scenarioInfo.ScenarioUnit = this.systemDetailsForm.controls['PreferredUnits'].value;
      this.scenarioData.emit(this.scenarioInfo);
    }
  }

  loadPRO() {
    if (this.scenarioInfo !== this.proScenario.rOOptimizerScenarioInfo) {
      this.scenarioInfo = this.proScenario.rOOptimizerScenarioInfo;
      if (!this.systemDetailsForm.controls['PreferredUnits'].value) {
        this.systemDetailsForm.controls['PreferredUnits'].setValue(this.scenarioInfo.ScenarioUnit);
      }
      this.scenarioInfo.ScenarioUnit = this.systemDetailsForm.controls['PreferredUnits'].value;
      this.scenarioData.emit(this.scenarioInfo);
    }
  }

  createForm() {
    this.systemDetailsForm = this.fb.group({
      Asset: [''],
      ScenarioName: [''],
      PreferredUnits: ['']
    });
  }
}
