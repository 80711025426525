<div class="nalco-geo-sales-region-filter"> 
  <div class="filterPopup">
    <div class="col-lg-12">
      <div class="col-lg-12 float-left">
      <h3 class="pt-3 mb-0" translate>COMMON.GEOGRAPHY_FILTERS</h3>
      <nalco-dropdown [hideLabelIfSelected]="true" [isDisabled]="showRegions" [ddData]="selectGeoRegion" (ddChange)="populateRegions($event)"></nalco-dropdown>
      <nalco-dropdown [hideLabelIfSelected]="true" [isDisabled]="showCountries" [ddData]="selectGeoCountry" (ddChange)="populateCountries($event)">
      </nalco-dropdown>
      </div>
    <div class="col-lg-12 float-left">
      <h3 class="pt-2 mb-0" translate> COMMON.SALES_FILTERS</h3>
      <nalco-dropdown [hideLabelIfSelected]="true" [isDisabled]="showSalesRegions" [ddData]="selectSalesRegion" (ddChange)="populateSalesRegions($event)">
      </nalco-dropdown>
      <nalco-dropdown [hideLabelIfSelected]="true" [isDisabled]="showSalesDistricts" [ddData]="selectSalesDistrict" (ddChange)="populateSalesDistricts($event)">
      </nalco-dropdown>
      <nalco-dropdown [hideLabelIfSelected]="true" [isDisabled]="showSalesTerritories" [ddData]="selectSalesTerritory" (ddChange)="populateTerritories($event)">
      </nalco-dropdown>
    </div>
    <div class=" col-lg-12">
        <a class="clearAll" href="javascript:void(0)" (click)="clearFilter()" translate>COMMON.CLEAR_ALL</a>
    </div>
    </div>
  </div>
</div>