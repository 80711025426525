import { Injectable } from '@angular/core';
import { ENVIRONMENTS, APP_CONFIG } from '../../app.config';

@Injectable()
export class EDataOverviewServiceConfig {
    private configMock: any = { // MOCK - local json settings
        environment: ENVIRONMENTS.mock,
        baseUrl: '',
        apiUrl: {
            getsites: 'SitePagingByUser',
            getConfiguration: 'api/v1/EDataConfiguration/GetConfiguratonsBySiteCpId',
            getConfigSetup: 'api/v1/EDataConfiguration/GetConfigurationSetupById',
            getHistoryData: 'api/v1/EDataEntryV1/GetHistoryData',
            IsReadingCollectionExists: 'api/v1/EDataEntryV1/IsReadingCollectionExists',
            saveMeasurementInstanceReading: 'api/v1/EDataEntryV1/SaveMeasurementInstanceReading',
            SaveContextPointAnnotation: 'api/v1/Annotations/SaveContextPointAnnotation',
            getContextPointAnnotations: 'api/v1/Annotations/GetContextPointAnnotations',
            getCalculatedMeasurementValue: 'api/v1/EDataEntryV1/GetCalculatedMeasurementValue',
            saveMeasurementAnnotation: 'api/v1/Annotations/SaveMeasurementAnnotation',
            getMeasurementAnnotations:'api/v1/Annotations/GetMeasurementAnnotations',
            deleteReadingCollection: 'api/v1/EDataEntryV1/DeleteReadingCollection',
            getEDataReadingsInExcel: 'api/v1/EDataEntryV1/ExportEDataReadingsInExcel',
            getDataSourceDetails: 'api/v1/DataVisualization/DataVizDatasources',
            getExcelTemplate:'api/v1/ExcelTemplateIO/EDataExcelSheetTemplate',
            uploadedExcelDocument :'api/v1/ExcelTemplateIO/UploadDataExcelTemplate',
            uploadPermission: 'api/v1/ExcelTemplateIO/CheckDataUploadPermission',
            getSitebyID :'api/v1/Sites/GetSiteBySiteId'
        }
    };

    private configProd: any = { // PRODUCTION - PRODUCTION ENVIRONMENT SETTINGS
        environment: ENVIRONMENTS.prod,
        baseUrl: '',
        apiUrl: {
            getsites: 'SitePagingByUser',
            getConfiguration: 'api/v1/EDataConfiguration/GetConfiguratonsBySiteCpId',
            getConfigSetup: 'api/v1/EDataConfiguration/GetConfigurationSetupById',
            getHistoryData: 'api/v1/EDataEntryV1/GetHistoryData',
            IsReadingCollectionExists: 'api/v1/EDataEntryV1/IsReadingCollectionExists',
            saveMeasurementInstanceReading: 'api/v1/EDataEntryV1/SaveMeasurementInstanceReading',
            SaveContextPointAnnotation: 'api/v1/Annotations/SaveContextPointAnnotation',
            getContextPointAnnotations: 'api/v1/Annotations/GetContextPointAnnotations',
            getCalculatedMeasurementValue: 'api/v1/EDataEntryV1/GetCalculatedMeasurementValue',
            saveMeasurementAnnotation: 'api/v1/Annotations/SaveMeasurementAnnotation',
            getMeasurementAnnotations:'api/v1/Annotations/GetMeasurementAnnotations',
            deleteReadingCollection: 'api/v1/EDataEntryV1/DeleteReadingCollection',
            getEDataReadingsInExcel: 'api/v1/EDataEntryV1/ExportEDataReadingsInExcel',
            getDataSourceDetails: 'api/v1/DataVisualization/DataVizDatasources',
            getExcelTemplate:'api/v1/ExcelTemplateIO/EDataExcelSheetTemplate',
            uploadedExcelDocument :'api/v1/ExcelTemplateIO/UploadDataExcelTemplate',
            uploadPermission: 'api/v1/ExcelTemplateIO/CheckDataUploadPermission',
            getSitebyID :'api/v1/Sites/GetSiteBySiteId'
        }
    };

    private _environment: string = APP_CONFIG.ENVIRONMENT;

    constructor() {
        // void
    }

    public getEnvironment(): any {
        switch (this._environment) {
            case ENVIRONMENTS.mock:
                return this.configMock;
            case ENVIRONMENTS.dev:
                return this.configProd;
            case ENVIRONMENTS.test:
                return this.configProd;
            case ENVIRONMENTS.stg:
                return this.configProd;
            case ENVIRONMENTS.prod:
                return this.configProd;
            default:
                return null;
        }
    }

    public setEnvironment(environment: string): any {
        this._environment = environment;
    }
}