import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DurationOption } from '../../models/DurationOption';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { ControllerManagementService } from '../../../../../src/app/controller-management/_services/controller-management.service';

@Component({
  selector: 'nalco-data-download',
  templateUrl: './data-download.component.html',
  styleUrls: ['./data-download.component.scss']
})
export class DataDownloadComponent implements OnInit {
  @Input() SerialNo = '';
  @Input() LastDataReceived;
  @Input() SiteCpId;
  @Input() DataSourceId;
  @Input() SiteName;
  @Input() DataSourceName
  showMessage : string;
  selectedFilterCriteria: Array<any> = [];
  dataDownloading = false;
  StartDate;
  EndDate;
  sDate;
  eDate;
  invalidTimeRangeMsg = '';
  payload;
  todayDate = new Date();
  constructor(
    public activeModal: NgbActiveModal,
    private translate: TranslateService,
    private controllerManagementService: ControllerManagementService
  ) { }

  ngOnInit() {
    this.payload = {
      "SiteCpId" : this.SiteCpId,
      "DataSourceId" : this.DataSourceId,
      "UnitOfMeasure" : 'USStandard',
      "IncludeMeasurementsHavingData" : true,
      "Duration" : 0,
      "StartDate" : new Date(),
      "EndDate" : new Date(),
      "DataDownloadType": 'AllThree',
      "IncludeMeasurementsData" : true,
      "IncludeAlarmsData" : false,
      "IncludeEventsData" : false,
      "Parameters24Hr": false
      };
  }
  updateDates(event: DurationOption) {
    if (event && event.IsValid) {
      this.StartDate = moment(event.StartDate , 'DD-MMM-YYYY HH:mm:ss');
      this.EndDate = moment(event.EndDate, 'DD-MMM-YYYY HH:mm:ss');
      this.payload.Duration = event.Number !== undefined?this.EndDate.diff(this.StartDate, 'days'):0;
      if((this.EndDate.diff(this.StartDate, 'days'))>90)
      {
        this.showMessage = this.translate.instant('CONTROLLER_MANAGEMENT.DATE_RANGE_INVALID');
      }
      else if(this.EndDate > this.todayDate || this.StartDate > this.todayDate )
      {
        this.showMessage = this.translate.instant('CONTROLLER_MANAGEMENT.INVALID_MSG_1');
      }
      else if(this.StartDate > this.EndDate ){
        this.showMessage = this.translate.instant('LIBRARY.DV.INVALID_MSG_1');
      }
      else{
        this.showMessage = '';
      }
      document.getElementsByClassName('error-msg')[1].innerHTML = this.showMessage
    }

  }
  GenerateExcelFile()
  { 
   let startDate = moment(this.StartDate , 'DD-MMM-YYYY HH:mm:ss');
   let endDate = moment(this.EndDate, 'DD-MMM-YYYY HH:mm:ss');
   let SDate = startDate.format("YYYY-MM-DDTHH:mm:ss");
   let EDate = endDate.format("YYYY-MM-DDTHH:mm:ss");
    this.payload.StartDate = SDate;
    this.payload.EndDate = EDate;
    if(this.showMessage === '' && ((endDate.diff(startDate, 'days'))<=90) && (this.payload.IncludeMeasurementsData || 
      this.payload.IncludeAlarmsData || this.payload.IncludeEventsData || this.payload.Parameters24Hr))
      {
        if (
          this.payload.IncludeMeasurementsData == true &&
          this.payload.IncludeAlarmsData == false &&
          this.payload.IncludeEventsData == false
        ) {
          this.payload.DataDownloadType = "Measurements";
        } else if (
          this.payload.IncludeMeasurementsData == false &&
          this.payload.IncludeAlarmsData == true &&
          this.payload.IncludeEventsData == false
        ) {
          this.payload.DataDownloadType = "Alarms";
        } else if (
          this.payload.IncludeMeasurementsData == false &&
          this.payload.IncludeAlarmsData == false &&
          this.payload.IncludeEventsData == true
        ) {
          this.payload.DataDownloadType = "Events";
        } else if (
          this.payload.IncludeMeasurementsData == true &&
          this.payload.IncludeAlarmsData == true &&
          this.payload.IncludeEventsData == false
        ) {
          this.payload.DataDownloadType = "MeasurementsAndAlarms";
        } else if (
          this.payload.IncludeMeasurementsData == true &&
          this.payload.IncludeAlarmsData == false &&
          this.payload.IncludeEventsData == true
        ) {
          this.payload.DataDownloadType = "MeasurementsAndEvents";
        } else if (
          this.payload.IncludeMeasurementsData == false &&
          this.payload.IncludeAlarmsData == true &&
          this.payload.IncludeEventsData == true
        ) {
          this.payload.DataDownloadType = "AlarmsAndEvents";
        }
        else if(this.payload.IncludeMeasurementsData == false &&
          this.payload.IncludeAlarmsData == false &&
          this.payload.IncludeEventsData == false &&
          this.payload.Parameters24Hr == true){
          this.payload.DataDownloadType = "";
        } else {
          this.payload.DataDownloadType = "AllThree";
        }
        
    this.dataDownloading = true;
    this.controllerManagementService.getDataDownloadForContextPoint(this.payload).subscribe(
      response => {
        response = response.replace(/"\\t/g,'"\t');
        this.dataDownloading = false;
        let dateStartFrom;
        if(this.payload.Duration>0){
        let Startd =  moment(startDate, "DD-MM-YYYY").add(1, 'days');
        dateStartFrom = Startd.format("YYYY-MM-DDTHH:mm:ss")
        }
        else{
          dateStartFrom = SDate;
        }
        let fileName = 'ControllerManagement-download-' + this.SiteName + "-" + this.DataSourceName + "-From-" + dateStartFrom + "-To-" + EDate + ".csv"
        const link = document.createElement('a');
        if (link.download !== undefined) {
          const blob = new Blob(['\ufeff', response])
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', fileName);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
   }

  }

  onItemSelect(event,source){
    if (source === 'Measurements') {
      this.payload.IncludeMeasurementsData= event.currentTarget.checked;
    }
    else if (source === 'Alarms') {
      this.payload.IncludeAlarmsData= event.currentTarget.checked;
    }
    else if (source === 'Events') {
      this.payload.IncludeEventsData= event.currentTarget.checked;
    }
    else if(source === '24HrsParameters')
    {
      this.payload.Parameters24Hr= event.currentTarget.checked;
    }
  }
}
