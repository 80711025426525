import { Component, OnInit, ViewEncapsulation,Input, Output, EventEmitter } from '@angular/core';
import { IDropdown, IDropdownOption } from '../dropdown/dropdown-model';
import { DateRangeService } from '../../services/date-range.service';
import { DurationOption } from '../../models/DurationOption';
import { TranslateService } from '@ngx-translate/core';
import { LocalCacheService } from '../../../core/local-cache/local-cache.service';

@Component({
  selector: 'nalco-date-range-dropdown',
  templateUrl: './date-range-dropdown.component.html',
  styleUrls: ['./date-range-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DateRangeDropdownComponent implements OnInit {

  @Input() showOneDayDuration?: boolean = false;
  @Input() showThreeDayDuration?: boolean = false;
  @Input() isDisabled = false; // Defines the enable/disable value of the dropdown
  @Input() queryParamDate?: number; // Date Value whch is present in queryparams
  @Input() isQueryParams?: boolean = false; //if there are queryParams in URL
  @Input() filterDays?: number; // takes the number of days passed in SSD filter
  @Input() isFilterDays?: boolean; // if filterdays are passed from SSD
  @Input() isMonthlyQuaterly?: {value: string};
  @Output() filterValChanged = new EventEmitter<any>();
  @Input() fromInventory: boolean;
  @Input() newValue:any;
  @Input() showDate:any;

  private duration: DurationOption;
  public value: string;
  public isFilterValuePassed = false;
  private localFilterDays: number;
  userCulture;

  dateRanges: IDropdown =
    {
      label: this.translate.instant('COMMON.SORT_OPTIONS.VIEW_SITES_BY'),
      name: 'durationDropDown',
      options: [
        {
          text: this.translate.instant('COMMON.SORT_OPTIONS.SEVENDAYS'),
          value: 'last7'
        },
        {
          text: this.translate.instant('COMMON.SORT_OPTIONS.THIRTYDAYS'),
          value: 'last30'
        },
        {
          text: this.translate.instant('COMMON.SORT_OPTIONS.SIXTYDAYS'),
          value: 'last60'
        },
        {
          text: this.translate.instant('COMMON.SORT_OPTIONS.NINETYDAYS'),
          value: 'last90'
        },
        {
          text: this.translate.instant('COMMON.SORT_OPTIONS.ONETWOZERODAYS'),
          value: 'last120'
        },
        {
          text: this.translate.instant('COMMON.SORT_OPTIONS.ONEEIGHTZERODAYS'),
          value: 'last180'
        },
        {
          text: this.translate.instant('COMMON.SORT_OPTIONS.THREESIXFIVEDAYS'),
          value: 'last365'
        }
      ]
    }

  constructor(private dateService: DateRangeService, private translate: TranslateService, private localCacheService: LocalCacheService) {
  }

  ngOnInit() {
    const userSettings = JSON.parse(this.localCacheService.fetchData('UserSettings'));
    this.userCulture = userSettings.Culture;
    if (this.isMonthlyQuaterly) {
      this.dateRanges = {
        label: this.translate.instant('COMMON.SORT_OPTIONS.VIEW_SITES_BY'),
        name: 'durationDropDown',
        options: [
          {
            text: 'Monthly',
            value: '1',
            isSelected: false
          },
          {
            text: 'Quarterly',
            value: '2',
            isSelected: false
          }
        ]
      }
    }
    if(this.showOneDayDuration == true){
      const oneDayObj = {
        text: this.translate.instant('COMMON.SORT_OPTIONS.ONEDAYS'),
        value: 'last1'
      };
      this.dateRanges.options.splice(0, 0, oneDayObj);
    }

    if(this.showThreeDayDuration == true){
      const threeDayObj = {
        text: this.translate.instant('COMMON.SORT_OPTIONS.THREEDAYS'),
        value: 'last3'
      };
      this.dateRanges.options.splice(1, 0, threeDayObj);
    }

    if(this.isQueryParams){
      if (this.queryParamDate !== null) {
        const durationValue = "last" + this.queryParamDate;
        const duration = this.dateRanges.options.filter(x => x.value == durationValue)
        const dateValueObj = {
          label: "View by",
          name: "durationDropDown",
          text: duration[0].text,
          value: duration[0].value
        }
        this.selectionChanged(dateValueObj);
      }
    }
    this.duration = this.dateService.getDuration();
    let navigatingFromCustom=this.duration.Value;
    if(navigatingFromCustom.indexOf('cust') === 0){
      this.value = this.dateRanges.options[1].value;
    }
    else{
    this.value = this.isMonthlyQuaterly ?  this.isMonthlyQuaterly.value :
    this.fromInventory ? this.newValue : this.duration.Value;
    }
  }
  ngOnChanges() {
    if (this.isFilterDays && !this.isQueryParams) {
      const durationValue = "last" + this.filterDays;
      const duration = this.dateRanges.options.filter(x => x.value == durationValue)
      if (duration[0] !== undefined) {
        const dateValueObj = {
          label: "View by",
          name: "durationDropDown",
          text: duration[0].text,
          value: duration[0].value
        }
        this.isFilterValuePassed = true;
        if (!this.localFilterDays || this.filterDays !== this.localFilterDays) {
          this.selectionChanged(dateValueObj);
          this.localFilterDays = this.filterDays;
        }
        // this.duration = this.dateService.getDuration();
        // this.value = this.duration.Value;
      }
      this.duration = this.setupDurationForDropDown(duration[0]);
      this.value = this.duration.Value;
    }
    // below condition checks for only deep link
    if (this.isQueryParams) {
      const durationValue = "last" + this.queryParamDate;
      const duration = this.dateRanges.options.filter(x => x.value == durationValue)
      if (duration[0] !== undefined) {
        const dateValueObj = {
          label: "View by",
          name: "durationDropDown",
          text: duration[0].text,
          value: duration[0].value
        }
        this.isFilterValuePassed = true;
        if (!this.localFilterDays || this.filterDays !== this.localFilterDays) {
          this.selectionChanged(dateValueObj);
          this.localFilterDays = this.filterDays;
        }
        this.duration = this.setupDurationForDropDown(duration[0]);
        this.value = this.duration.Value;
      }
    }
  }
  selectionChanged(data) {
    if (this.isMonthlyQuaterly) {
      this.filterValChanged.emit(data);
    } else {
      let selectedValue = data.value;
      let durationNumber: number;
      // All value selections in dropdown begin with the word last + duration, so we parse the number after it
      if (selectedValue.indexOf('last') === 0) {
        durationNumber = Number(selectedValue.substring(4));
      }

      this.dateService.setDuration({ Number: durationNumber, Value: selectedValue });
      data['duration']=durationNumber;
      if (this.isFilterDays && !this.isQueryParams) {
        this.filterValChanged.emit(data);
      }
    }
    // TODO add else if blocks if we add a duration in the future that does not conform to this structure.
  }
  setupDurationForDropDown(duration): DurationOption {
    let durationFromDropDown: DurationOption = new DurationOption();
    let selectedValue = duration?.value;
    let durationNumber: number;
    if (selectedValue.indexOf('last') === 0) {
      durationNumber = Number(selectedValue.substring(4));
    }
    durationFromDropDown.Number = durationNumber;
    durationFromDropDown.Value = selectedValue;
    return durationFromDropDown;
  }

}
