import { Component, OnInit, Input, OnChanges, AfterViewInit, SimpleChange, ViewChild, ElementRef, HostListener, ContentChild,ChangeDetectionStrategy,ChangeDetectorRef } from '@angular/core';
import { Chart, HIGHCHARTS_MODULES } from 'angular-highcharts';
import { LineChartConfig } from './line-chart.config';
import { ChartOptions, Options, AxisOptions, LoadingOptions, ChartObject } from 'highcharts';
import * as Highcharts from 'highcharts';
import moment  from 'moment';
import { Point } from '@progress/kendo-drawing/dist/es/geometry';
import { IChartData } from '../../contracts/IChartData';
import { DateLocaleService } from '../../services/date-locale.service';
import { TranslateService } from '@ngx-translate/core';
// import * as highstock from 'highcharts/highstock';

// const noData = require('highcharts/modules/no-data-to-display');
// noData(Highcharts);

@Component({
  selector: 'nalco-line-chart',
  templateUrl: './line-chart.component.html',
  styles: [' .line-chart-wrap { overflow-x:auto; }'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LineChartComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() data: IChartData | any;
  @Input() height?: number;
  @Input() format?: string;
  @Input() tickInterval?: number;
  @Input() backgroundColor?: any;
  @Input() lineColor?: string;
  @Input() yaxisLabel?: string;
  @Input() gridLineColor?: string;
  @Input() lineIndex?: number = -1;
  @Input() chartContainerID?: string;
  @Input() xfontSize?: string;
  @Input() yfontSize?: string;
  @Input() xAxisDistance?: number;
  @Input() days?: number;
  @Input() yAxisLabelType?: string;
  @Input() dataPointFrequency?: string;
  @Input() yAxisLabelRange?: any;
  @Input() yAxisMaxValue?: any = undefined;
  @Input() yAxisMinValue?: number = undefined;
  @Input() serviceName?: any = '';
  @Input() noDataAvailableMsg?: boolean;
  @ViewChild('lineChartContainer', { static: false }) lineChartContainer: ElementRef;
  @Input() regionLGR?: string;
  @Input() connectNulls?: boolean = false;
  @Input() plotLines?: any = null;
  @Input() dataforTooltip?: any = null;
  @Input() isPrintPreview: boolean = false;
  chart: ChartObject | any;
  private xAxisLabel: LoadingOptions;
  private xAxisOptions: AxisOptions;
  private yAxisOptions: AxisOptions;
  private chartsOptions: ChartOptions;
  private option: Options;
  private rect = null;
  private tickStart = 1;
  private tickEnd = 3;
  /*
  * Property to calculate the dynamic minwidth of the high chart to be rendered on various devices.
  * Calculate the minwidth on window load and resize window events for setting the value.
  */
  private minWidth: number;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setWidth();
  }


  constructor(private dateLocaleService: DateLocaleService,
    private translate: TranslateService,
    private cdRef: ChangeDetectorRef) {
  }
  ngOnInit() { }

  ngAfterViewInit() {
    this.setWidth();
    this.reDrawCharts();
    this.cdRef.detectChanges();
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (changes.lineIndex !== undefined && changes.lineIndex.firstChange === false
      && changes.lineIndex.currentValue !== -1) {
      this.chart.series[this.lineIndex].update(LineChartConfig.highlightOption);

      if (changes.lineIndex.previousValue !== -1) {
        this.chart.series[changes.lineIndex.previousValue].update(LineChartConfig.baseOption);
      }
    } else {
      this.reDrawCharts();
    }
  }

  private setWidth() {
    if (this.lineChartContainer.nativeElement.clientWidth === 0) {
      return;
    }
    this.minWidth = Math.max(this.lineChartContainer.nativeElement.clientWidth, 544);
    this.reDrawCharts();
  }

  private reDrawCharts() {
    const self = this;
    let element: any;
    let msg = this.translate.instant('COMMON.DAY');
    if (this.lineChartContainer) {
      element = this.lineChartContainer.nativeElement;
    }

    if (!element) {
      return;
    }
    this.xAxisLabel = {
      labelStyle: LineChartConfig.labelStyle
    };
    if (this.chartContainerID == "asset-performance") {
      this.xAxisOptions = {
        categories: this.data.xAxis.categories,
        labels: {
          autoRotationLimit: 0,
          style: {
            fontSize: this.xfontSize,
            fontWeight: '700'
          },
          formatter: function () {
            const values = this.value.split('-');
            let xLabel = '';
            values.forEach((value, index) => {
              if (index == 0) {
                xLabel = xLabel + `<p>${value}</p>`;
              } else {
                if (this.chart.plotSizeX >= 1150) {
                  xLabel = xLabel + ` - <p>${value}</p>`;
                } else {
                  xLabel = xLabel + `- <br/> ` + `<p>${value}</p>`;
                }
              }
            })
            return xLabel;
          }
        }
      }
    } else {
      this.xAxisOptions = {
        type: 'datetime',
        gridLineColor: this.gridLineColor || LineChartConfig.gridLineColor,
        reversed: false,
        gridLineWidth: LineChartConfig.gridLineWidth,
        labels: {
          style: {
            fontSize: this.xfontSize,
          },
          formatter: function () {
            if (self.days > 60) {
              return Highcharts.dateFormat('%b/%y', this.value);
            } else {
              return Highcharts.dateFormat('%e/%b/%y', this.value);
            }
          },
          y: this.xAxisDistance
        }
      };
      if (self.dataPointFrequency === 'daily') {
        this.xAxisOptions.tickInterval = 24 * 3600 * 1000 * 7;
      } else {
        this.xAxisOptions.tickInterval = null;
      }
      if (self.days > 60) {
        this.xAxisOptions.tickInterval = 3600 * 24 * 30 * 1000;
      }
      if (self.days === 60) {
        this.xAxisOptions.tickInterval = 7 * 24 * 36e5;
      }
    }
    this.yAxisOptions = {
      tickInterval: this.tickInterval,
      gridLineColor: this.gridLineColor || LineChartConfig.gridLineColor,
      gridLineWidth: this.chartContainerID == 'asset-performance' ? 1 : LineChartConfig.gridLineWidth,
      maxPadding: LineChartConfig.yAxisPadding,
      categories: self.yAxisLabelRange,
      max: this.yAxisMaxValue,
      min: this.yAxisMinValue,
      title: { text: null },
      plotLines: this.plotLines || [],
      labels: {
        formatter: function () {
          if (self.yAxisLabelType === 'text') {
            //Europe Region
            if (self.regionLGR != undefined && self.regionLGR.toLowerCase() == "europe") {
              if (this.value < 20) {
                this.value = self.yAxisLabelRange[0];
              } else if (this.value >= 20 && this.value < 90) {
                this.value = self.yAxisLabelRange[1];
              } else if (this.value >= 90) {
                this.value = self.yAxisLabelRange[2];
              } else {
                this.value = '';
              }
            }
            // console.log('value===>>', this.value)
            // Updating Default NA values
            else {
              if (this.value < 40) {
                this.value = self.yAxisLabelRange[0];
              } else if (this.value >= 40 && this.value < 70) {
                this.value = self.yAxisLabelRange[1];
              } else if (this.value >= 70) {
                this.value = self.yAxisLabelRange[2];
              } else {
                this.value = '';
              }
            }
            return this.value;
          } else {
            return this.value + self.format;
          }
        },
        style: {
          color: this.yaxisLabel,
          fontSize: this.yfontSize,
          fontWeight: this.chartContainerID == "asset-performance" ? '700' : '400'
        },
        align: this.chartContainerID == 'asset-performance' ? undefined : 'left',
        x: this.chartContainerID == 'asset-performance' ? -10 : 10,
        y: this.chartContainerID == 'asset-performance' ? 0 : -2
      },

    };
    this.chartsOptions = {
      type: LineChartConfig.type,
      spacing: [0, 0, 10, 0],
      zoomType: 'x',
      marginTop: 12,
      events: {
        load: function () {
          const chart = this,
            xAxis = chart.xAxis[0],
            yAxis = chart.yAxis[0],
            top = chart.plotTop + yAxis.height,
            height = LineChartConfig.xAxisHeight,
            options = LineChartConfig.xAxisColorFill,
            // colorize from 2 to 8
            start = 0,
            end = chart.chartWidth;
          const rect1 = chart.renderer.rect(
            start,
            top,
            end,
            height
          ).attr(options).add();
        }
      },
      width: this.minWidth,
      style: LineChartConfig.style,
      renderTo: element

    };
    if (this.chartContainerID == 'asset-performance') {
      this.chartsOptions['scrollablePlotArea'] = {
        minWidth: this.isPrintPreview ? 800 : 1050,
        scrollPositionX: 0
      };
    }
    const FullMonthnames = this.dateLocaleService.getLocaleMonths();
    const ShortMonthnames = this.dateLocaleService.getShortLocaleMonths();
    this.option = {
      chart: this.chartsOptions,
      legend: LineChartConfig.legend,
      title: LineChartConfig.title,
      tooltip: {
        backgroundColor: LineChartConfig.tooltipStyle.backgroundColor,
        borderColor: LineChartConfig.tooltipStyle.borderColor,
        borderRadius: LineChartConfig.tooltipStyle.borderRadius,
        useHTML: LineChartConfig.tooltipStyle.useHTML,
        xDateFormat: LineChartConfig.tooltipStyle.xDateFormat,
        shared: self.chartContainerID == 'asset-performance' ? true : false,
        formatter: function () {
          if (self.chartContainerID == 'asset-performance') {
            return this.points.reduce(function (s, point) {
              if (self.dataforTooltip[point.series.index][point.point.index].text == 'month') {
                if (point.point.index != 12) {
                  return '<span class="tooltip-content" style="font-size: 12px;">' + s + self.dataforTooltip[point.series.index][point.point.index].period + ': ' +
                    point.y.toFixed(2) + '%' + '</span>';
                } else {
                  return '<span class="tooltip-content" style="font-size: 12px;">' + s + self.dataforTooltip[point.series.index][point.point.index].period + ': ' +
                    point.y.toFixed(2) + '%' + '</span>' + '<p class="tooltip-content" style="font-size: 12px;">' + 'The data shown is current ' + self.dataforTooltip[point.series.index][point.point.index].text + ' up to date' + '</p>';
                }
              } else {
                if (point.point.index != 4) {
                  return '<span class="tooltip-content" style="font-size: 12px;">' + s + self.dataforTooltip[point.series.index][point.point.index].period + ': ' +
                    point.y.toFixed(2) + '%' + '</span>';
                } else {
                  return '<span class="tooltip-content" style="font-size: 12px;">' + s + self.dataforTooltip[point.series.index][point.point.index].period + ': ' +
                    point.y.toFixed(2) + '%' + '</span>' + '<p class="tooltip-content" style="font-size: 12px;">' + 'The data shown is current ' + self.dataforTooltip[point.series.index][point.point.index].text + ' up to date' + '</p>';
                }
              }
            }, '');
          } else {
            var date = new Date(this.x);
            if (self && (self.serviceName == 'AssetDetails' || self.serviceName == 'WQ')) {
              date = new Date(date.getUTCFullYear(),
                date.getUTCMonth(),
                date.getUTCDate(),
                date.getUTCHours(),
                date.getUTCMinutes(),
                date.getUTCSeconds()
              );
            }
            const yText = ' ';
            if (self.regionLGR != undefined && self.regionLGR.toLowerCase() == "europe") {
              if (this.y < 20) {
                this.yText = 'Low';
              } else if (this.y >= 20 && this.y < 90) {
                this.yText = 'Medium';
              } else if (this.y >= 90) {
                this.yText = 'High';
              }
            }
            else {
              if (this.y < 40) {
                this.yText = 'Low';
              } else if (this.y >= 40 && this.y < 70) {
                this.yText = 'Medium';
              } else if (this.y >= 70) {
                this.yText = 'High';
              }
            }
            const month = date.getMonth();
            const mnth = ('0' + (date.getMonth() + 1)).slice(-2);
            const day = ('0' + date.getDate()).slice(-2);
            const year = date.getFullYear();
            // const hours = ('0' + date.getHours()).slice(-2);
            // const minutes = ('0' + date.getMinutes()).slice(-2);
            // const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun','Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

            const mnthName = ('0' + (date.getMonth() + 1)).slice(-2);
            const formattedDate = [day, mnth, date.getFullYear()].join('/');
            const toolTipContent = ' ';
            if (self.yAxisLabelType === 'text') {
              this.toolTipContent = '<span class="tooltip-content">' + this.yText + '</span>';
            } else {
              if (this.series.userOptions && this.series.userOptions.needCustomLabel !== undefined) {
                if (this.series.userOptions.offlineDurationData !== undefined) {
                  let offlineHours = this.series.userOptions.offlineDurationData.filter(offD => offD[0] === this.x)[0][2];
                  if (offlineHours !== null) {
                    // this.toolTipContent =  '<span class="tooltip-content">' + this.series.userOptions.customLabelValue + ' ' + offlineHours.toString() + ' Hrs'+'</span>';
                    this.toolTipContent = '<span class="tooltip-content">' + this.series.userOptions.customLabelValue + ', ' + offlineHours.toString() + '&nbsp;' + msg + '</span>';
                  } else {
                    this.toolTipContent = '<span class="tooltip-content">' + this.series.userOptions.customLabelValue + '</span>';
                  }
                } else {
                  this.toolTipContent = '<span class="tooltip-content">' + this.series.userOptions.customLabelValue + '</span>';
                }
              } else {
                this.toolTipContent = '<span class="tooltip-content">' + this.y + self.format + '</span>';
              }
            }
            let HeaderContent;
            if (this.chartContainerID === 'AssetPerformanceTrendLineChart') {
              HeaderContent = '<p class="tool-tip-header">' + FullMonthnames[month] + '&nbsp;' + day + ', ' + year + '</span></p>';
            } else {
              HeaderContent = '<p class="tool-tip-header">' + day + '/' + ShortMonthnames[month] + '/' + (year.toString()).slice(-2) + '</span></p>';
            }
            return HeaderContent + this.toolTipContent;
          }
        }
      },
      plotOptions: LineChartConfig.plotOptions,
      credits: LineChartConfig.credits,
      exporting: {
        enabled: LineChartConfig.exporting.enabled
      },
      xAxis: this.xAxisOptions,
      yAxis: this.yAxisOptions,
      series: this.data.series
    };

    this.option.chart.height = this.height || 'auto';
    // const d = moment({this.chart.point.x}).format('DD/MMM/YY');
    // this.option.tooltip.headerFormat = '<p class="tool-tip-header">{point.x}</p>';
    this.option.tooltip.pointFormat = '<span class="tooltip-content">{point.y}' + this.format + '</span>';
    if (this.backgroundColor) {
      // if provided
      this.option.chart.backgroundColor = this.backgroundColor;
    } else {
      // otherwise default blue
      this.option.chart.backgroundColor = LineChartConfig.backgroundColor;
    }

    const dataSeries = this.option.series;

    this.lineColor = this.lineColor || LineChartConfig.lineColor;
    for (let i = 0; i < dataSeries.length; i++) {
      dataSeries[i].color = dataSeries[i].color || this.lineColor;
      // If the chart has only one data point, display the marker else don't --> Bug 123633: WSI || STG || BAAT || Single datapoints on Compliance graph not seen
      // if(dataSeries[i].data.length <= 1){
      //   this.option.plotOptions.series.marker.enabled = true;
      //   this.option.plotOptions.series.marker.radius = 1;
      // }else{
      //   this.option.plotOptions.series.marker.enabled = false;
      //   this.option.plotOptions.series.marker.radius = 0;
      // }
    }

    Highcharts.setOptions({
      lang: {
        months: this.dateLocaleService.getLocaleMonths(),
        shortMonths: this.dateLocaleService.getShortLocaleMonths(),
        weekdays: this.dateLocaleService.getLocaleDays(),
        noData: self.noDataAvailableMsg ? "No Data Available" : null
      }
    });
    this.option.plotOptions.series.connectNulls = this.connectNulls;
    this.chart = new Highcharts.Chart(this.option);
    setTimeout(() => {
      this.chart.reflow();
    }, 100);

  }


}
