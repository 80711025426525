import { Injectable } from '@angular/core';
import { APP_CONFIG, ENVIRONMENTS } from '../../app.config';


@Injectable()
export class AssetHarvesterServiceConfig {

    private configMock: any = { // MOCK - local json settings
        environment: ENVIRONMENTS.mock,
        baseUrl: '',
        timeoutSession: '',
        timeoutIdle: '',
        apiUrl: {
            getAssetCategoriesMasterData: 'api/v1/ContextPoints/Categories',
            getAssetsCollection: 'api/v1/Sites/{0}/{1}/AssetCollection/{2}',
            getFullRefactorAssetCollection: '/api/v1/AssetHarvester/FullRefactor/{0}/{1}/AssetCollection/{2}',
            // getAssetSystemGroupCollection: '/api/v1/Sites/{0}/{1}/AssetSystemGroupCollection',
            // getAssetSystemGroupCollection: '/SiteDagChildren/{0}/{1}/{2}/{3}/{4}/{5}',
            getAssetSystemGroupCollection: '/api/v1/Sites/{0}/{1}/SiteDagChildren/{2}',
            saveAssetsCollection: 'api/v1/Sites/{0}/AssetCollection',
            saveAssetsCollectionNew: 'api/v1/Sites/{0}/AssetCollection1',
            saveContextPointLimits: 'api/v1/AssetHarvester/ContextPointLimits',
            unitConversion: 'ConversionByUnitType',
            getAccountsByDag: '/api/v1/Accounts/AccountsByDag/{0}',
            getContextPointTypeActiveMapCount: 'api/v1/AssetHarvester/ContextPointTypeActiveMapCounts/{0}',
            getScalingInformationForAnalogInput: 'api/v1/AssetHarvester/ScalingInformationForAnalogInputs',
            getInventoryConfiguration: 'api/v1/Sites/InventoryConfiguration/{0}',
            getTankSerialNumber: 'api/v1/Sites/GetAllTankSerialNoByDagId',
            getSoldToShipTo: 'api/v1/Sites/GetSoldToShipToByDagId',
            getproducts: 'api/v1/Sites/GetProductDetailsFromProductTable/',
            getCalibration: 'api/v1/Sites/CultureBasedCurrentReadingFromCosmos/{0}',
            updateCalibrate: 'api/v1/Sites/CalibrateByAssetCpid/{0}',
            TimeDataForAssetBasedOnDate: 'api/v1/Sites/TimeDataForAssetBasedOnDate/{0}',
            getAssetsByCategory: 'api/v1/AssetHarvester/CategoryAssetChildrenAndDatasources/{0}/{1}',
            getAssetProperties: 'api/v1/AssetHarvester/AtbAssetProperties/{0}',
            getSystemProperties: 'api/v1/AssetHarvester/AtbSystemProperties/{0}',
            getOfflineConfig: 'api/v1/AssetHarvester/OfflineConfigurations/{0}',
            getContextPointLimits: 'api/v1/AssetHarvester/ContextPointLimits/{0}',
            getMasterSlaveList: 'api/v1/AssetHarvester/OfflineAssetMasters',
            getTotalAssetsPlannedByDag: 'api/v1/AssetHarvester/TotalAssetsPlannedByDag/{0}',
            postOnBoardingByDag: '/api/v1/AssetHarvester/MergeIsonboardingAndIsTrial/{0}/{1}/{2}',
            getMappedMsrInstances: '/api/v1/AssetHarvester/MeasurementInstances/Asset/{0}',
            getUnMappedMsrInstances: '/api/v1/AssetHarvester/MeasurementInstances/Unmapped/{0}',
            getUnMappedMsrInstancesByDs: 'MeasurementAggregatorService/api/v1/GetUnmappedMeasurementsByDataSource?dataSourceCpId={0}&siteCpId={1}&CategoryId={2}',
            getAllAssetsBySiteCpId: '/api/v1/AssetHarvester/GetAllAssetsBySiteCpId',
            getAssetConfig: '/api/v1/AssetHarvester/InventoryConfigurationByAsset/{0}',
            getSystems: '/api/v1/AssetHarvester/Systems/{0}',
            deleteItem: 'api/v1/AssetHarvesterDeleteEngine/DeleteItemByIds/{0}/{1}/{2}',
            deleteSensorMapping: 'api/v1/AssetHarvesterDeleteEngine/DeleteSensorMapping?measurementMappingDeleteType={0}&userId={1}',
            assetMappedInfo: '/api/v1/AssetHarvester/HistoricalMeasurementMappings/{0}',
            assetAssociatedServices: 'api/v1/AssetHarvester/AssetAssociatedServices/{0}/{1}',
            favoriteSite: '/api/v1/AssetHarvester/DagFavoritesByUser/{0}/{1}',
            saveMSRDetails: 'api/v1/AssetHarvester/All/MapMeasurement',
            getWQAssetTypePort: 'api/v1/AssetHarvester/WaterQualityEnabledPortsAndAssets',
            deleteValidation: 'api/v1/AssetHarvesterDeleteEngine/Validation/{0}',
            getChildrenCountInAssetByDagCpIdCategoryId: 'api/v1/AssetHarvester/GetChildrenCountInAssetByDagCpIdCategoryId/{0}/{1}',
            getSiteLevelMeasurements: 'api/v1/AssetHarvester/GetSiteLevelMeasurements',
            getCalculatedMeasurements: 'api/v1/AssetHarvester/GetCalculatedMeasurementsByCpId',
            getCalculatedMeasurementsVariableDetails: 'api/v1/AssetHarvester/GetMeasurementInstanceCalculationMapByMeasurementInstanceId',
            getAssetLevelMeasurements: 'api/v1/AssetHarvester/GetAssetLevelMeasurements',
            getMeasurementContextPointMapByContextPointId : 'MeasurementAggregatorService/api/v1/GetMeasurementContextPointMapByContextPointId/{0}?includeChildren=true&contextPointType=None',
            getRegionDetails: 'api/v1/Sites/CheckRegionByDagId/{0}',
            getInventoryAssetData: 'api/v1/Sites/GetInventoryAssetMetaDataByDagId/{0}',
            getTourConfiguration: 'api/v1/EDataConfiguration/GetConfiguratonsBySiteCpId',
            getEdataContextPointsAndMeasurementsBySiteCpId: 'api/v1/EDataConfiguration/GetEdataContextPointsAndMeasurementsBySiteCpId',
            getConfigurationSetup: 'api/v1/EDataConfiguration/GetConfigurationSetup',
            SaveMultipleConfiguration: 'api/v1/EDataConfiguration/SaveMultipleConfiguration',
            SaveAndReturnConfiguration: 'api/v1/EDataConfiguration/SaveAndReturnConfiguration',
            getCalcConfig: 'api/v1/AssetHarvester/GetCalculationConfigurations',
            getMeasurementCalcs: 'api/v1/AssetHarvester/MeasurementContextPointCalculations',
            getMeasurementInstanceCalculationMapByMeasurementInstanceId: 'api/v1/AssetHarvester/GetMeasurementInstanceCalculationMapByMeasurementInstanceId',
            getVariableMeasurementsByTypeId: 'api/v1/AssetHarvester/GetVariableMeasurementsByTypeId',
            getSiteLevelMsrs: 'api/v1/AssetHarvester/GetAssetPortDatasourceMeasurementDetails',
            getMeasureType: 'api/v1/Measurements',
            getStui: 'api/v1/StuiType/',
            getMeasurementSource: 'api/v1/AssetHarvester/GetMeasurementSource',
            getStuiType: 'api/v1/StuiType/GetStuiType',
            getUOMByNalcoNumericsUnitOrSpeciesorMeasurementID: 'api/v1/AssetHarvester/GetUOMByNalcoNumericsUnitOrSpeciesorMeasurementID',
            getStuiTypeByStuiTypeId: 'api/v1/StuiType/{0}',
            checkIfMeasurementExists: 'api/v1/AssetHarvester/CheckIfMeasurementExists',
            saveEdataMeasurements: 'api/v1/AssetHarvester/SaveEdataMeasurements',
            retryFailedLimits: 'api/v1/Sites/RetryFailedRequests/{0}',
            GetEDataMeasurements: 'api/v1/AssetHarvester/GetEDataMeasurements',
            getUnitsByDimensionalKey: 'LoadUnitsSymbolByDimensionalKey',
            getROTrainNormalizeParameter: 'api/v1/AssetHarvester/GetROTrainNormalizeDetails/{0}/{1}',
            saveNormalizeDetails: 'api/v1/AssetHarvester/SaveROTrainNormalizeDetails',
            GetROTrainNormalizeReferenceValues: 'api/v1/AssetHarvester/GetROTrainNormalizeReferenceValues/{0}/{1}',
            SaveMeasurementsForAllDataSources: 'api/v1/AssetHarvester/SaveMeasurementsForAllDataSources',
            createEdataInventoryMeasurements: 'api/v1/AssetHarvester/CreateEdataInventoryMeasurements',
            SaveAssetOperationalStatus: 'api/v1/AssetHarvester/All/SaveAssetOperationalStatus?assetCreatorType=None',
            saveLimits: 'api/v1/Sites/All/UpsertTreatmentLimits?assetCreatorType=AssetHarvester',
            getMeasurementMapLocationsBySiteCpId: 'api/v1/AssetHarvester/GetMeasurementMapLocationBySiteCpId',
            getDagPagingByUser: 'api/v1/AssetHarvester/DagPagingByUser',
            getInputConfiguration: 'AutomatedTVDService/api/v1/GetInputConfiguration',
            saveInputConfiguration: 'AutomatedTVDService/api/v1/SaveInputConfiguration',
            GetMappingLocationMeasurementDetails: 'api/v2/AssetHarvester/GetMappingLocationMeasurementDetails',
            getSensorMapping:'MeasurementAggregatorService/api/v1/GetMeasurementContextPointMapByContextPointId/{0}?includeChildren=true',
            getFlowMeters: 'api/v1/AssetHarvester/FlowMeters/All',
            getCurrentReading: 'api/v1/AssetHarvester/CurrentReadings?dataSourceCpId={0}',
            getUnitUsageById: 'api/v1/UnitUsage/',
            getRecalculationDays: 'GeneralCollection?documentName=WaterFlowIntelligence.Calculation',
            getSiteSensorMaps: 'api/v1/SensorMapping/SensorMaps/',
            postConversionByUnitType: 'ConversionByUnitType',
            replicateOrMoveMeasurementsAndLimits: 'MeasurementAggregatorService/api/v1/ReplicateOrMoveMeasurementsAndLimits',
            validateReplicateButtonPermission: 'MeasurementAggregatorService/api/v1/CheckReplicateButtonPermission',
            getCurrencies:'Currencies',
            getSiteCultureBySiteCpId: 'Site/api/v1/GetSiteCultureBySiteCpId',
            getBaselineAndTarget : 'TelemetryThresholds/api/v1/GetTelemetryThresholds',
            getDataSourceBySiteId: 'SiteManagement/api/v1/DatasourcesBySiteId/{0}/{1}',
            duplicateAsset: 'SiteAggregator/api/v1/DuplicateAsset/{0}',
            getAssetsDetailsByCategoryId: 'SiteManagement/api/v1/AssetsDetailsByCategoryId/{0}/{1}',
            siteFavorites:'Site/api/v1/SiteFavoritesByUser/{0}/{1}'
        }
    };

    private configProd: any = { // PRODUCTION - PRODUCTION ENVIRONMENT SETTINGS
        environment: ENVIRONMENTS.prod,
        baseUrl: '',
        apiUrl: {
            getAssetCategoriesMasterData: 'api/v1/ContextPoints/Categories',
            getAssetsCollection: '/api/v1/Sites/{0}/{1}/AssetCollection/{2}',
            getFullRefactorAssetCollection: '/api/v1/AssetHarvester/FullRefactor/{0}/{1}/AssetCollection/{2}',
            // getAssetSystemGroupCollection: '/api/v1/Sites/{0}/{1}/AssetSystemGroupCollection',
            // getAssetSystemGroupCollection: '/SiteDagChildren/{0}/{1}/{2}/{3}/{4}/{5}',
            getAssetSystemGroupCollection: '/api/v1/Sites/{0}/{1}/SiteDagChildren/{2}',
            saveAssetsCollection: 'api/v1/Sites/{0}/AssetCollection',
            saveAssetsCollectionNew: 'api/v1/Sites/{0}/AssetCollection1',
            saveContextPointLimits: 'api/v1/AssetHarvester/ContextPointLimits',
            unitConversion: 'ConversionByUnitType',
            getAccountsByDag: '/api/v1/Accounts/AccountsByDag/{0}',
            getContextPointTypeActiveMapCount: 'api/v1/AssetHarvester/ContextPointTypeActiveMapCounts/{0}',
            getScalingInformationForAnalogInput: 'api/v1/AssetHarvester/ScalingInformationForAnalogInputs',
            getInventoryConfiguration: 'api/v1/Sites/InventoryConfiguration/{0}',
            getTankSerialNumber: 'api/v1/Sites/GetAllTankSerialNoByDagId',
            getSoldToShipTo: 'api/v1/Sites/GetSoldToShipToByDagId',
            getproducts: 'api/v1/Sites/GetProductDetailsFromProductTable/',
            getCalibration: 'api/v1/Sites/CultureBasedCurrentReadingFromCosmos/{0}',
            updateCalibrate: 'api/v1/Sites/CalibrateByAssetCpid/{0}',
            TimeDataForAssetBasedOnDate: 'api/v1/Sites/TimeDataForAssetBasedOnDate/{0}',
            getAssetsByCategory: 'api/v1/AssetHarvester/CategoryAssetChildrenAndDatasources/{0}/{1}',
            getAssetProperties: 'api/v1/AssetHarvester/AtbAssetProperties/{0}',
            getSystemProperties: 'api/v1/AssetHarvester/AtbSystemProperties/{0}',
            getOfflineConfig: 'api/v1/AssetHarvester/OfflineConfigurations/{0}',
            getContextPointLimits: 'api/v1/AssetHarvester/ContextPointLimits/{0}',
            getMasterSlaveList: 'api/v1/AssetHarvester/OfflineAssetMasters',
            getTotalAssetsPlannedByDag: 'api/v1/AssetHarvester/TotalAssetsPlannedByDag/{0}',
            postOnBoardingByDag: '/api/v1/AssetHarvester/MergeIsonboardingAndIsTrial/{0}/{1}/{2}',
            getMappedMsrInstances: '/api/v1/AssetHarvester/MeasurementInstances/Asset/{0}',
            getUnMappedMsrInstances: '/api/v1/AssetHarvester/MeasurementInstances/Unmapped/{0}',
            getUnMappedMsrInstancesByDs: 'MeasurementAggregatorService/api/v1/GetUnmappedMeasurementsByDataSource?dataSourceCpId={0}&siteCpId={1}&CategoryId={2}',
            getAllAssetsBySiteCpId: '/api/v1/AssetHarvester/GetAllAssetsBySiteCpId',
            getAssetConfig: '/api/v1/AssetHarvester/InventoryConfigurationByAsset/{0}',
            getSystems: '/api/v1/AssetHarvester/Systems/{0}',
            deleteItem: 'api/v1/AssetHarvesterDeleteEngine/DeleteItemByIds/{0}/{1}/{2}',
            deleteSensorMapping: 'api/v1/AssetHarvesterDeleteEngine/DeleteSensorMapping?measurementMappingDeleteType={0}&userId={1}',
            assetMappedInfo: '/api/v1/AssetHarvester/HistoricalMeasurementMappings/{0}',
            assetAssociatedServices: 'api/v1/AssetHarvester/AssetAssociatedServices/{0}/{1}',
            favoriteSite: '/api/v1/AssetHarvester/DagFavoritesByUser/{0}/{1}',
            saveMSRDetails: 'api/v1/AssetHarvester/All/MapMeasurement',
            getWQAssetTypePort: 'api/v1/AssetHarvester/WaterQualityEnabledPortsAndAssets',
            deleteValidation: 'api/v1/AssetHarvesterDeleteEngine/Validation/{0}',
            getChildrenCountInAssetByDagCpIdCategoryId: 'api/v1/AssetHarvester/GetChildrenCountInAssetByDagCpIdCategoryId/{0}/{1}',
            getSiteLevelMeasurements: 'api/v1/AssetHarvester/GetSiteLevelMeasurements',
            getCalculatedMeasurements: 'api/v1/AssetHarvester/GetCalculatedMeasurementsByCpId',
            getCalculatedMeasurementsVariableDetails: 'api/v1/AssetHarvester/GetMeasurementInstanceCalculationMapByMeasurementInstanceId',
            getAssetLevelMeasurements: 'api/v1/AssetHarvester/GetAssetLevelMeasurements',
            getMeasurementContextPointMapByContextPointId : 'MeasurementAggregatorService/api/v1/GetMeasurementContextPointMapByContextPointId/{0}?includeChildren=true&contextPointType=None',
            getRegionDetails: 'api/v1/Sites/CheckRegionByDagId/{0}',
            getInventoryAssetData: 'api/v1/Sites/GetInventoryAssetMetaDataByDagId/{0}',
            getTourConfiguration: 'api/v1/EDataConfiguration/GetConfiguratonsBySiteCpId',
            getEdataContextPointsAndMeasurementsBySiteCpId: 'api/v1/EDataConfiguration/GetEdataContextPointsAndMeasurementsBySiteCpId',
            getConfigurationSetup: 'api/v1/EDataConfiguration/GetConfigurationSetup',
            SaveMultipleConfiguration: 'api/v1/EDataConfiguration/SaveMultipleConfiguration',
            SaveAndReturnConfiguration: 'api/v1/EDataConfiguration/SaveAndReturnConfiguration',
            getCalcConfig: 'api/v1/AssetHarvester/GetCalculationConfigurations',
            getMeasurementCalcs: 'api/v1/AssetHarvester/MeasurementContextPointCalculations',
            getMeasurementInstanceCalculationMapByMeasurementInstanceId: 'api/v1/AssetHarvester/GetMeasurementInstanceCalculationMapByMeasurementInstanceId',
            getVariableMeasurementsByTypeId: 'api/v1/AssetHarvester/GetVariableMeasurementsByTypeId',
            getSiteLevelMsrs: 'api/v1/AssetHarvester/GetAssetPortDatasourceMeasurementDetails',
            getMeasureType: 'api/v1/Measurements',
            getStui: 'api/v1/StuiType/',
            getMeasurementSource: 'api/v1/AssetHarvester/GetMeasurementSource',
            getStuiType: 'api/v1/StuiType/GetStuiType',
            getUOMByNalcoNumericsUnitOrSpeciesorMeasurementID: 'api/v1/AssetHarvester/GetUOMByNalcoNumericsUnitOrSpeciesorMeasurementID',
            getStuiTypeByStuiTypeId: 'api/v1/StuiType/{0}',
            checkIfMeasurementExists: 'api/v1/AssetHarvester/CheckIfMeasurementExists',
            saveEdataMeasurements: 'api/v1/AssetHarvester/SaveEdataMeasurements',
            retryFailedLimits: 'api/v1/Sites/RetryFailedRequests/{0}',
            GetEDataMeasurements: 'api/v1/AssetHarvester/GetEDataMeasurements',
            getUnitsByDimensionalKey: 'LoadUnitsSymbolByDimensionalKey',
            getROTrainNormalizeParameter: 'api/v1/AssetHarvester/GetROTrainNormalizeDetails/{0}/{1}',
            saveNormalizeDetails: 'api/v1/AssetHarvester/SaveROTrainNormalizeDetails',
            GetROTrainNormalizeReferenceValues: 'api/v1/AssetHarvester/GetROTrainNormalizeReferenceValues/{0}/{1}',
            SaveMeasurementsForAllDataSources: 'api/v1/AssetHarvester/SaveMeasurementsForAllDataSources',
            createEdataInventoryMeasurements: 'api/v1/AssetHarvester/CreateEdataInventoryMeasurements',
            SaveAssetOperationalStatus: 'api/v1/AssetHarvester/All/SaveAssetOperationalStatus?assetCreatorType=None',
            saveLimits: 'api/v1/Sites/All/UpsertTreatmentLimits?assetCreatorType=AssetHarvester',
            getMeasurementMapLocationsBySiteCpId: 'api/v1/AssetHarvester/GetMeasurementMapLocationBySiteCpId',
            getDagPagingByUser: 'api/v1/AssetHarvester/DagPagingByUser',
            getInputConfiguration: 'AutomatedTVDService/api/v1/GetInputConfiguration',
            saveInputConfiguration: 'AutomatedTVDService/api/v1/SaveInputConfiguration',
            GetMappingLocationMeasurementDetails: 'api/v2/AssetHarvester/GetMappingLocationMeasurementDetails',
            getSensorMapping:'MeasurementAggregatorService/api/v1/GetMeasurementContextPointMapByContextPointId/{0}?includeChildren=true',
            getFlowMeters: 'api/v1/AssetHarvester/FlowMeters/All',
            getCurrentReading: 'api/v1/AssetHarvester/CurrentReadings?dataSourceCpId={0}',
            getUnitUsageById: 'api/v1/UnitUsage/',
            getRecalculationDays: 'GeneralCollection?documentName=WaterFlowIntelligence.Calculation',
            getSiteSensorMaps: 'api/v1/SensorMapping/SensorMaps/',
            postConversionByUnitType: 'ConversionByUnitType',
            replicateOrMoveMeasurementsAndLimits: 'MeasurementAggregatorService/api/v1/ReplicateOrMoveMeasurementsAndLimits',
            validateReplicateButtonPermission: 'MeasurementAggregatorService/api/v1/CheckReplicateButtonPermission',
            getCurrencies:'Currencies',
            getSiteCultureBySiteCpId: 'Site/api/v1/GetSiteCultureBySiteCpId',
            getBaselineAndTarget : 'TelemetryThresholds/api/v1/GetTelemetryThresholds',
            getDataSourceBySiteId: 'SiteManagement/api/v1/DatasourcesBySiteId/{0}/{1}',
            duplicateAsset: 'SiteAggregator/api/v1/DuplicateAsset/{0}',
            getAssetsDetailsByCategoryId: 'SiteManagement/api/v1/AssetsDetailsByCategoryId/{0}/{1}',
            siteFavorites:'Site/api/v1/SiteFavoritesByUser/{0}/{1}'
        
        }
    };

    private _environment: string = APP_CONFIG.ENVIRONMENT;

    constructor() {
        // void
    }

    public getEnvironment(): any {
        switch (this._environment) {
            case ENVIRONMENTS.mock:
                return this.configMock;
            case ENVIRONMENTS.dev:
                return this.configProd;
            case ENVIRONMENTS.test:
                return this.configProd;
            case ENVIRONMENTS.stg:
                return this.configProd;
            case ENVIRONMENTS.prod:
                return this.configProd;
            default:
                return null;
        }
    }

    public setEnvironment(environment: string): any {
        this._environment = environment;
    }

}
