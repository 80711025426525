<div class="nalco-threshold-popup">
  <div class="modal-header">
    <h3 class="modal-title">{{'QUALITY.ADMIN.RYG_THRESHOLD'| translate}}</h3>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
      <span class="ico-exit-round-edge"></span>
    </button>
  </div>
  <div class="modal-body p-0 scrollbar-border">
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>{{'QUALITY.ADMIN.STATUS'| translate}} </th>
            <th>{{'QUALITY.ADMIN.PCT_PERFORMANCE'| translate}}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <span class="listview-icon iconwater-HighRiskRed"></span>
              <label translate>QUALITY.ADMIN.RED</label>
            </td>
            <td>&lt; 70%</td>
          </tr>
          <tr>
            <td>
              <span class="listview-icon iconwater-MediumRiskYellow"></span>
              <label translate>QUALITY.ADMIN.YELLOW</label>
            </td>
            <td>&ge; 70% {{'QUALITY.ADMIN.AND'| translate}} &lt; 90%</td>
          </tr>
          <tr>
            <td>
              <span class="listview-icon iconwater-LowRiskGreen"></span>
              <label translate>QUALITY.ADMIN.GREEN</label>
            </td>
            <td>&ge; 90%</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button"  (click)="activeModal.close()"  class="btn btn-primary">{{'COMMON.CLOSE'| translate}}</button>
  </div>
</div>