// angular modules packages
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/of";
import "rxjs/add/operator/map";
import { GridDataResult } from "@progress/kendo-angular-grid";
import { BehaviorSubject } from "rxjs/BehaviorSubject";

// custom modules components
import { AppEnvConfig } from "../../app.env.config";
import { LocalCacheService } from "../../core/local-cache/local-cache.service";
import { Subject, of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { AssetDetailsServiceConfig } from "./asset-details.service.config";

@Injectable()
export class AssetDetailsService extends BehaviorSubject<GridDataResult> {
  // declare all the variables
  private configEnv: any;
  public url: string;
  public data: any;
  public contextPoint: number;
  public jsonServerUrl: string;
  public dagType: string;
  public checkMaxDags: string;
  private changeinCVOCfilter = new Subject<string>();
  private changeinDropdownFilter = new Subject<string>();
  public CVOCfilerchangeEvnt$ = this.changeinCVOCfilter.asObservable();
  public dropdownchangeEvnt$ = this.changeinDropdownFilter.asObservable();
  public chartTemplates = {};


  // constructor
  constructor(
    private assetDetailsServiceConfig: AssetDetailsServiceConfig,
    private httpClient: HttpClient,
    private config: AppEnvConfig,
    private cache: LocalCacheService,
  ) {
    super(null);
    this.configEnv = assetDetailsServiceConfig.getEnvironment();
    this.configEnv.apiServerUrl = config.getEnv("apiServerUrl");
    this.configEnv.jsonServerUrl = config.getEnv("jsonServerUrl");
    this.configEnv.apiServerUrlAuth = config.getEnv("apiServerUrl");
    this.configEnv.apiWaterServerUrl = config.getEnv("apiWaterServerUrl");
    this.configEnv.contextPoint = 0;
    this.dagType = "NalcoWater/";
    this.checkMaxDags = "/true/";

    this.cache.storeData('adrumService', 'Omni', 'local');
  }

  public getAssetSystemGroupCollection(dagtype: string, dagId: number, contextPointType: string): Observable<any> {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getAssetSystemGroupCollection,
      [dagtype, dagId.toString(), contextPointType]);
    return this.httpClient.get(url).map((response: any) => {
      try {
        return response;
      } catch (e) {
        return e;
      }
    });
  }
  private parse(url: string, params: string[] = []): string {
    let result: string = url;
    if (params.length > 0) {
      result = url.replace(/\{(\d+)\}/g, function (match: string, current: string): string {
        return params[parseInt(current, 10)] !== undefined ? params[parseInt(current, 10)] : '';
      });
    }
    return result;
  }

  getDashboardConfig(configFileName: string): Observable<any> {
    const url = 'assets/config/' + configFileName;
    return this.httpClient.get(url).map(response => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }
  
  private changeReq = new BehaviorSubject<any>(null);
  public $changeReq = this.changeReq.asObservable();

  private siteURLChange = new BehaviorSubject<any>(null);
  public $siteURLChange = this.siteURLChange.asObservable();

  private isShowAssetPerfOrBucket = new BehaviorSubject<any>(null);
  public $isShowAssetPerfOrBucket = this.isShowAssetPerfOrBucket.asObservable();

  private siteTrendReq = new BehaviorSubject<any>(null);
  public $siteTrendReq = this.siteTrendReq.asObservable();

  private setAssetPerformanceReq = new BehaviorSubject<any>(null);
  public $setAssetPerformanceReq = this.setAssetPerformanceReq.asObservable();

  private loadReq = new BehaviorSubject<any>(null);
  public $loadReq = this.loadReq.asObservable();
  
  private customDays = new BehaviorSubject<any>(null);
  public $customDays = this.customDays.asObservable();
    
  private currentPerformance = new BehaviorSubject<any>(null);
  public $currentPerformance = this.currentPerformance.asObservable();

  setSystemCurrentPerf(data : any){
    this.currentPerformance.next(data);
  }

  getmydata(isEdited: any) {
    this.changeReq.next(isEdited);
   }

   getloading() {
    this.loadReq.next(null);
   }

   siteTrendData(data: any) {
    this.siteTrendReq.next(data);
   }

   setAssetPerformance(data: any) {
    this.setAssetPerformanceReq.next(data);
   }
 
  setCustomDays(customRangeDays: number)
   {
     this.customDays.next(customRangeDays);
   }

  modifySiteURL(siteDataURL: any) {
    this.siteURLChange.next(siteDataURL);
  }

  showAssetPerfOrBucket(latestValue: any) {
    this.isShowAssetPerfOrBucket.next(latestValue);
  }

  public toggleTooltip(evt: MouseEvent, tooltip, show: boolean) {
    const element: any = evt.srcElement;
    let overflows = false;
    if (element.offsetHeight + 1 < element.scrollHeight ||
        element.offsetWidth + 1 < element.scrollWidth) {
        overflows = true;
    }
    if (show && overflows) {
        tooltip.open();
    } else {
        tooltip.close();
    }
}


  public getSpecificSite(siteId : string): Observable<any> {      
    const url: string = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getSpecificSite, [siteId]);
    return this.httpClient.get(url).map(response =>
    {        
        try
        { return response; }
        catch (e) 
        { return response; }
    });
  }
  
  getIsAssetPerfOrBuckets(siteId: number): Observable<any> {
    const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getIsBuckets}` + '/' + siteId;
        return this.httpClient.get(url).map((response: any) => {
            try {
                return response;
            } catch (e) {
                return response;
            }
        });
  }

  public getChartTemplates(contextPointTypeId: number, assetTypeId: number, forceLoad?: boolean): Observable<any> {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getChartTemplates,
        [contextPointTypeId.toString(), assetTypeId.toString()]);
    let key = contextPointTypeId + '_' + assetTypeId;
    if (this.chartTemplates[key] && !forceLoad) {
        return of(this.chartTemplates[key]);
    } else {
        return this.httpClient.get(url).map((response: any) => {
            try {
                this.chartTemplates[key] = response;
                return response;
            } catch (e) {
                return response;
            }
        });
    }
}
}
