import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textCategorySearch'
})
export class TextCategorySearchPipe implements PipeTransform {

  transform(items: any[], text?: any, category?: any, showUnmapped = true): any {
    if (showUnmapped) {
      if (!text && !category) {
        return items;
      } else if (!text && category) {
          return items.filter((item) => {
              if (item.category === category && typeof item.display === 'string') {
                return true;
              }
              return false;
            });
      } else if (text && !category) {
          return items.filter((item) => {
              if (item.display && typeof item.display === 'string') {
                return item.display.toLowerCase().indexOf(text.toLowerCase()) > -1;
              }
              return false;
            });
      }
      return items.filter((item) => {
        if (item.display && typeof item.display === 'string' && item.category === category) {
          return item.display.toLowerCase().indexOf(text.toLowerCase()) > -1;
        }
        return false;
      });
    } else {
      if (!text && !category) {
        return items.filter((item) => {
          let str = item.display.split('|');
          if ((str[str.length - 1]) !== ' (unmapped)') {
            return true;
          }
          return false;
        });
      } else if (!text && category) {
          return items.filter((item) => {
            let str = item.display.split('|');
              if (item.category === category && typeof item.display === 'string' && (str[str.length - 1]) !== ' (unmapped)') {
                return true;
              }
              return false;
            });
      } else if (text && !category) {
          return items.filter((item) => {
            let str = item.display.split('|');
              if (item.display && typeof item.display === 'string' && (str[str.length - 1]) !== ' (unmapped)') {
                return item.display.toLowerCase().indexOf(text.toLowerCase()) > -1;
              }
              return false;
            });
      }
      return items.filter((item) => {
        let str = item.display.split('|');
        if (item.display && typeof item.display === 'string' && item.category === category && (str[str.length - 1]) !== ' (unmapped)') {
          return item.display.toLowerCase().indexOf(text.toLowerCase()) > -1;
        }
        return false;
      });
    }
    
  }
}
