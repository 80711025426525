import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { ISubheaderLeftSection, ISubheaderMiddleSection, ISubheaderRightSection } from './sub-header.modal';
import { SubheaderHandlerService } from './../../../core/nav/subheader-handler.service';

@Component({
  selector: 'nalco-sub-header',
  templateUrl: './sub-header.component.html'
})
export class SubHeaderComponent implements OnDestroy {
  leftSection: ISubheaderLeftSection;
  middleSection: ISubheaderMiddleSection;
  rightSection: ISubheaderRightSection;
  @Input() isExpand : boolean;
  @Input() isHomePage = false;
  isMobile: boolean;
  private subscriptions: Subscription[] = [];
  headerColor: string = 'white-header-background' ;

  constructor(private subHeaderService: SubheaderHandlerService,
    private cd: ChangeDetectorRef) {
    
  }

  btnClicked(which) {
    this.subHeaderService.btnClickInSubhead(which);
  }

  ddChange(data) {
    this.subHeaderService.ddChangeInSubhead(data);
  }

  ngOnInit() {
    this.isMobile = (/android|webos|iphone|ipad|ipod|blackberry|windows phone/).test(navigator.userAgent.toLowerCase());
    this.subscriptions.push(this.subHeaderService.subheaderLeftSection$.subscribe(async (update) => {
      this.leftSection = await update;
    }));

    this.subscriptions.push(this.subHeaderService.subheaderMiddleSection$.subscribe(async (update) => {
      this.middleSection = await update;
    }));

    this.subscriptions.push(this.subHeaderService.subheaderRightSection$.subscribe(async (update) => {
      this.rightSection = await update;
    }));
  }


  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscriptions.forEach(s => s.unsubscribe());
  }

}
