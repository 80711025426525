import { IChartDataSeries } from "../contracts/IChartData";


export class ChartDataSeries implements IChartDataSeries {
    name: string;
    data: Array<any>;
    color?: string;
    index?: number;
    stack?: string;
    constructor(ChartDataSeries?: IChartDataSeries) {
        if (ChartDataSeries) {
            this.name = ChartDataSeries.name;
            this.data = ChartDataSeries.data;
            this.color = ChartDataSeries.color;
            this.index = ChartDataSeries.index;
            this.stack = ChartDataSeries.stack;
        } else {
            this.name = '';
            this.data = [];
            this.color = '';
            this.index = 0;
            this.stack = '';
        }
    }
}
