import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IDropdown, IDropdownEventType } from '../dropdown/contracts/dropdown-model';
import { DurationOption } from '../../shared/models/DurationOption';
import { IButtonData } from '../button/contracts/button-model';
import { PopupSettings } from '@progress/kendo-angular-popup';

@Component({
  selector: 'custom-date-picker',
  templateUrl: './custom-date-picker.component.html',
  styleUrls: ['./custom-date-picker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomDatePickerComponent implements OnInit{
  @Input() isDisabled: boolean = false;
  @Input() dateRanges: IDropdown;
  @Input() bottomView:string='month';
  @Input() showApplyButton: boolean = true;
  @Input() sDate: Date;
  @Input() eDate: Date;
  @Input() dateFormat? : string;
  disabled = false;
  public popupSettings: PopupSettings = {
    popupClass: "time-range-datepicker",
  };
  buttonPrimary: IButtonData;

  public showOtherClassic = true
  updatedData: IDropdownEventType;
  @Output('updateDate') updateDate = new EventEmitter<IDropdownEventType>();
  constructor(
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.buttonPrimary = {
      buttonClass: "custom", buttonText: this.translate.instant('COMMON.APPLY'),    
      customClass: 'primary-small'
    }
  }
  /**
   * @description  on calender date change event
   * @param string
   */
  onChange(event: any, param: string) {
    const dateE = event ? event.toString().split(' ') : '';
    if (param === 'startDate') {
      this.sDate = new Date(dateE ? dateE[2] + '-' + dateE[1] + '-' + dateE[3] : undefined);
    } else if (param === 'endDate') {
      this.eDate = new Date(dateE ? dateE[2] + '-' + dateE[1] + '-' + dateE[3] : undefined);
    }
    if(this.sDate && this.eDate){
    if(!this.showApplyButton){
    this.updatedData = { name : 'Custom',value: 'Custom', startDate: this.sDate.toString(), endDate: this.eDate.toString() };
    this.updateDate.emit(this.updatedData);
    }
    }
  }
  public disabledDates = (date: Date): boolean => {
    return date < this.sDate;
  }

    /**
   * @description  on calender date change and click on apply button 
   */
  applySelectedDate() {
    if(this.sDate && this.eDate){
      this.disabled =
      this.sDate.getTime() >= this.eDate.getTime() ? true : false;
    this.updatedData = { name: 'Custom',value: 'Custom', startDate: this.sDate.toString(), endDate: this.eDate.toString() };
    this.updateDate.emit(this.updatedData);
    }
  }

  
    /**
   * @description  on click of clear button 
   */
  clearFilters() {
    if(this.sDate && this.eDate){
    this.sDate = null;
    this.eDate = null;
    }
  }



}

