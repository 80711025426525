import { Injectable } from '@angular/core';
import { ENVIRONMENTS, APP_CONFIG } from '../../app.config';

@Injectable()
export class DataVisualizationServiceConfig {

    private configMock: any = { // MOCK - local json settings
        environment: ENVIRONMENTS.mock,
        baseUrl: '',
        apiUrl: {
            getsites: 'SitePagingByUser',
            getsystems: 'datavisualization/api/gethierarchies?dagId=',
            // getsiteAssetsMapData: 'DagDataSources/{0}/true/true/true?useAlias=true',
            getsiteAssetsMapData: 'SiteDataSources/',
            getSiteDagChildren: 'SiteDagChildren/All/{0}/{1}/{2}/{3}/{4}',
            getGraphCsvData: 'api/v1/DataVisualization/GraphDataByTimeRange',
            getGeneralConfigurations: 'GeneralCollection',
            getSaveFavData: 'api/v1/Favorites',
            setSaveFavData: 'api/v1/Favorites/UpsertFavorite',
            fetchFavData: 'api/v1/Favorites/',
            getSiteDetails: 'CustomerSites',
            getSiteDetailsWithPaging: 'CustomerSitesWithPaging', 
            getSelectedParameterDetail: 'api/v1/DataVisualization/DataVizDatasources/Favorites/',
            setAnnotation: 'api/v1/Annotations?',
            getAnnotation: 'api/v1/Annotations/ByDateRange?',
            getParameters: 'api/v1/DataVisualization/DataVizDatasourceMini/',
            getShareFavorite: 'api/v1/Favorites/GetUserListForShareFavorites',
            upsertShareFavorite: '/api/v1/Favorites/UpsertShareFavoriteToUsers'
            //getParameters: 'assets/sample/parameter.json/'
        }
    };

    private configProd: any = { // PRODUCTION - PRODUCTION ENVIRONMENT SETTINGS
        environment: ENVIRONMENTS.prod,
        baseUrl: '',
        apiUrl: {
            getsites: 'SitePagingByUser',
            getsystems: 'datavisualization/api/gethierarchies?dagId=',
            // getsiteAssetsMapData: 'DagDataSources/{0}/true/true/true?useAlias=true',
            getsiteAssetsMapData: 'SiteDataSources/',
            getSiteDagChildren: 'SiteDagChildren/All/{0}/{1}/{2}/{3}/{4}',
            getGraphCsvData: 'api/v1/DataVisualization/GraphDataByTimeRange',
            getGeneralConfigurations: 'GeneralCollection',
            getSaveFavData: 'api/v1/Favorites',
            setSaveFavData: 'api/v1/Favorites/UpsertFavorite',
            fetchFavData: 'api/v1/Favorites/',
            getSiteDetails: 'CustomerSites',
            getSiteDetailsWithPaging: 'CustomerSitesWithPaging', 
            getSelectedParameterDetail: 'api/v1/DataVisualization/DataVizDatasources/Favorites/',
            setAnnotation: 'api/v1/Annotations?',
            getAnnotation: 'api/v1/Annotations/ByDateRange?',
            getParameters: 'api/v1/DataVisualization/DataVizDatasourceMini/',
            getShareFavorite: 'api/v1/Favorites/GetUserListForShareFavorites',
            upsertShareFavorite: '/api/v1/Favorites/UpsertShareFavoriteToUsers'
            //getParameters: 'assets/sample/parameter.json/'
            
        }
    };

    private _environment: string = APP_CONFIG.ENVIRONMENT;

    constructor() {
        // void
    }

    public getEnvironment(): any {
        switch (this._environment) {
            case ENVIRONMENTS.mock:
                return this.configMock;
            case ENVIRONMENTS.dev:
                return this.configProd;
            case ENVIRONMENTS.test:
                return this.configProd;
            case ENVIRONMENTS.stg:
                return this.configProd;
            case ENVIRONMENTS.prod:
                return this.configProd;
            default:
                return null;
        }
    }

    public setEnvironment(environment: string): any {
        this._environment = environment;
    }

}




