import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { USERROLES } from './../../../app.config';
import { ApplicationInsightsService } from '../../analytics/logger.service';
import { LocalCacheService } from '../../local-cache/local-cache.service';
import { UserService } from '../../../core/user/user.service';
import { UserSettings } from '../../../shared/models/_models';
import { routeErrorCodes } from '../../fallback-modules/_enum/constants';

/**
 * @description Injectable Error Management Service
 */
@Injectable({
  providedIn: 'root',
})
export class ErrorManagementService {
  envisionAccess: boolean;

  /**
   * @description Creates an instance of error management service.
   * @param alertService - an instance of AlertService
   * @param locationService - an instance of LocationService
   */
  constructor(
    private localCacheService: LocalCacheService,
    private userService: UserService,
    private router: Router,
    private applicationInsightsService : ApplicationInsightsService
  ) { }

  /**
   * @description handleApiError() is a public class method,
   * used to handle various API errors.
   * @param alertTitle is a text to be shown as a title on Alert Box.
   * @param errorResponse is the error response from the API.
   * @param options - optional error callback options
   */
  public handleApiError(
    errorResponse: HttpErrorResponse,
    request: HttpRequest<any>
  ): void {
    this._logCustomEvents(errorResponse, request);
    if (errorResponse instanceof HttpErrorResponse) {
      if (!(errorResponse.error instanceof ErrorEvent)) {
        this._handleServerSideResponseError(errorResponse);
      }
    }
  }

  /**
 * @description handleServerSideResponseError() is a private class method,
 * used to handle server rejected known errors like 400, 401, 404, 500, 502, and so on.
 * @param errorResponse is the error response from the API.
 * @param request the HTTP request object.
 */
  private _handleServerSideResponseError(
    errorResponse: HttpErrorResponse
  ): void {
    if(errorResponse.status && 
        Object.values(routeErrorCodes).includes(errorResponse.status.toString())){         
     this.router.navigate(['/error-page'], {
       queryParams: { 'status': errorResponse.status },
       skipLocationChange: true 
     });
    }

  }

  /**
   * @description log HTTP failure events to the appInsights.
   * @param error error instance of type HttpErrorResponse
   * @param request instance of the triggerd HTTP call.
   * @returns no value.
   */
  public _logCustomEvents(error: HttpErrorResponse, request?: HttpRequest<any>): void {
    this.checkEnvisionPermission();
    this.applicationInsightsService.setUserId(this.localCacheService.fetchData('username'));
    this.applicationInsightsService.logException(error);
    if(request){
    this.applicationInsightsService.logEvent(error.statusText,
      {
        'Method': request.method,
        'Payload': request.body,
        'URL': request.url,
        'Page': this.router.url,
        'Response Message': error.message,
        'Response Status': error.status,
        'Response statusText': error.statusText,
        'Request': request,
        'Error': error
      });
    }
    else{
      this.applicationInsightsService.logEvent(error.statusText,
        {
          'URL': error.url,
          'Response Message': error.message,
          'Response Status': error.status,
          'Response statusText': error.statusText,
          'Error': error
        });
    }
  }

  /** Added below Permission for Asset Details page to show the custom error message for 417 PBI #517135 */
  checkEnvisionPermission() {
    let isWQPermission: boolean;
    const user: UserSettings = this.userService.getCurrentSettings();
      if (user) {
        let permissions = user.Permissions.map(x => x.PermissionId);
        isWQPermission = permissions.includes(parseInt(USERROLES.WQDASHBOARD, 10));
      }
      user.Permissions.forEach(permission => {
        if (this.router.url.includes('/asset-details') && permission.PermissionId.toString() === USERROLES.ENVISION4WEB && !isWQPermission) {
          this.envisionAccess = true;
        }
      });
    
  }

}
