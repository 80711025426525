import { IMeasurementToCompare } from '../contracts/IMeasurementToCompare';

export class MeasurementToCompare implements IMeasurementToCompare {
    name: string;
    category: string;

    constructor(measurement: IMeasurementToCompare) {
        this.name = measurement.name;
        this.category = measurement.category;
    }
}
