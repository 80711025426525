<nalco-tile [parentInstance]="myParentInstance()"   [headerTitle]="" [errorData]='errorInfo' [showMoreOptions]=false [showInfoIcon]=false
  class="no-header Sites-table">
  <div tile-content>
    <div class="site-diagnostics-wrapper">

      <div *ngIf="activeAlarm.length !== 0 ">
        <div class="notifications" *ngFor="let active of activeAlarm">
          <p>{{active.AlarmDiscription}}</p>
          <p class="date-color">{{'OMNI.SITE_DETAILS.NOTIFICATIONS_ON' | translate}} {{active.OnDate}} {{active.OnTime}}
          </p>
        </div>
      </div>
      <div *ngIf="activeAlarm.length === 0 && filterData ==1">
        <h3 class="notFoundMessage col-md-10" translate>OMNI.PERFORMANCE.NO_CONTENT_AVAILABLE_MESSAGE</h3>
      </div>

      <div *ngIf="historicalAlarm.length !== 0 ">
        <div class="notifications" *ngFor="let historical of historicalAlarm">
          <p>{{historical.AlarmDiscription}}</p>
          <p class="date-color">{{'OMNI.SITE_DETAILS.NOTIFICATIONS_ON' | translate}} {{historical.OnDate}}
            {{historical.OnTime}}</p>
          <p class="date-color">{{'OMNI.SITE_DETAILS.NOTIFICATIONS_OFF' | translate}} {{historical.OffDate}}
            {{historical.OffTime}}</p>
        </div>
      </div>
      <div *ngIf="historicalAlarm.length === 0 && filterData ==2">
        <h3 class="notFoundMessage col-md-10" translate>OMNI.PERFORMANCE.NO_CONTENT_AVAILABLE_MESSAGE</h3>
      </div>
    </div>
  </div>
</nalco-tile>
<nalco-more-option #moreoptions [showExportAsPDF]='false' [showAddToReport]='false' [showExportAsImage]='false'
[useGraphExport]='false' (exportAsCsvClick)="exportToExcel()" (maximizeClick)="maximizeDialog()"
[showMaximize]=true [showExportAsCsv]=true fileName="Export_Data" class="pop-up-grid-view showExportAsPdf">
</nalco-more-option>