import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppEnvConfig } from '../../app.env.config';
import { LocalCacheService } from '../local-cache/local-cache.service';

declare var ADRUM: any;

@Injectable()
export class AppDynamicsService {
    public appDynamicsEnv: string;
    public GAkey: string;

    constructor(
        private storage: LocalCacheService,
        private route: ActivatedRoute,
        private config: AppEnvConfig,
    ) {
        this.appDynamicsEnv = config.getEnv('appDynamicsEnvironment');
        this.GAkey = config.getEnv('appDynamicsEnvironment');
    }

    adrumAddScript() {
        //Google Analytics script. Commented for current release
        //Commented for testing 315321
       /* const GAscript = document.createElement('script');
        GAscript.innerHTML =
            'var adrumUser = localStorage["ecolab.envision_username"] || "";'
            + ' var sitename = localStorage["ecolab.envision_sitename"] || "";'
            + 'var corporateAccountName = localStorage["ecolab.envision_corporateAccountName"] || "";'
            + 'var adrumService = localStorage["ecolab.envision_adrumService"] || "";'
            + 'var adrumHeader = localStorage["ecolab.envision_adrumHeader"] || "";'
            + 'var adrumComponent = localStorage["ecolab.envision_adrumComponent"] || "";'
            + 'var adrumElement = localStorage["ecolab.envision_adrumElement"] || "";'
            + '(function (i, s, o, g, r, a, m) {'
            + 'i["GoogleAnalyticsObject"] = r; i[r] = i[r] || function () {'
            + '(i[r].q = i[r].q || []).push(arguments)'
            + '}, i[r].l = 1 * new Date(); a = s.createElement(o),'
            + 'm = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m)'
            + '})(window, document, "script", "https://www.google-analytics.com/analytics.js", "ga");'
            + 'ga("create","' + this.GAkey + '" , "auto", { userId: this.adrumUser });'
            + 'ga("set", "dimension1", this.adrumUser );'
            + 'ga("set", "dimension2", this.adrumService );'
            + 'ga("send", "pageview");';
        document.body.appendChild(GAscript);*/

        // add appdynamics variables
        const script = document.createElement('script');
        script.innerHTML = '(function (config) {'
        + 'config.appKey = "' + this.appDynamicsEnv + '";'
        + 'config.adrumExtUrlHttp = "http://cdn.appdynamics.com";'
        + 'config.adrumExtUrlHttps = "https://cdn.appdynamics.com";'
        + 'config.beaconUrlHttp = "http://pdx-col.eum-appdynamics.com";'
        + 'config.beaconUrlHttps = "https://pdx-col.eum-appdynamics.com";'
        + 'config.fetch = false;'
        + 'config.xd = {'
        + 'enable: true'
        + '};'
        + 'config.spa = {'
        + '"spa2": true'
        + '};'
        + 'config.isZonePromise = true;'
        + '       (function (info) {'
        + '       info.Ajax = function (context) {'
        + '           return userDetails("AJAX");'
        + '       };'
        + '       info.VPageView = function (context) {'
        + '           return userDetails("VPageView");'
        + '       };'
        + '       info.PageView = function (context) {'
        + '           return userDetails("pageView");'
        + '       };'
        + '       function userDetails(initiator){'
        + '        var adrumService = localStorage["ecolab.envision_adrumService"] || "";'
        + '        var userEmail = localStorage["ecolab.envision_username"] || "";'
        + '        var sitename = localStorage["ecolab.envision_sitename"] || "";'
        + '        var corporateAccountName = localStorage["ecolab.envision_corporateAccountName"] || "";'
        + '        var adrumHeader = localStorage["ecolab.envision_adrumHeader"] || "";'
        + '        var adrumComponent = localStorage["ecolab.envision_adrumComponent"] || "";'
        + '        var adrumElement = localStorage["ecolab.envision_adrumElement"] || "";'
        + '        var sSDContextPointID = localStorage["ecolab.envision_SSDContextPointID"] || "";'
        + '        var sSDCorpAccountId = localStorage["ecolab.envision_SSDCorpAccountId"] || "";'
        + '        var sSDCorporateAccountName = localStorage["ecolab.envision_SSDCorporateAccountName"] || "";'
        + '        var sSDSiteName = localStorage["ecolab.envision_SSDSiteName"] || "";'
        + '        var sSDDisplayName = localStorage["ecolab.envision_SSDDisplayName"] || "";'
        + '        var userFirstName = localStorage["ecolab.envision_User_FirstName"] || "";'
        + '        var userLastName = localStorage["ecolab.envision_User_LastName"] || "";'
        + '        var internalUser = localStorage["ecolab.envision_internalUser"] || "";'
        + '        var userId = localStorage["ecolab.envision_userId"] || "";'
        + '        var wqCorporateId = localStorage["ecolab.envision_corporateId"] || "";'
        + '        var HasElevatedAccess = localStorage["ecolab.envision_HasElevatedAccess"] || "";'
        + '        var UserType = localStorage["ecolab.envision_UserType"] || "";'
        
//        + '        console.log("----------"+initiator+"-----------adrumService- > "+adrumService+" adrumComponent - >"+adrumComponent+" adrumElement - >"+adrumElement+" adrumHeader - >"+adrumHeader);'
        + '                return {'
        + '                    userData: {'
        + '                       adrumService: adrumService,'
        + '                       email: userEmail,'
        + '                       sitename: sitename,'
        + '                       corporateAccountName: corporateAccountName,'
        + '                       adrumHeader: adrumHeader,'
        + '                       adrumComponent: adrumComponent,'
        + '                       adrumElement: adrumElement,'
        + '                       sSDContextPointID: sSDContextPointID,'
        + '                       sSDCorpAccountId: sSDCorpAccountId,'
        + '                       sSDCorporateAccountName: sSDCorporateAccountName,'
        + '                       sSDSiteName: sSDSiteName,'
        + '                       sSDDisplayName: sSDDisplayName,'
        + '                       userFirstName: userFirstName,'
        + '                       userLastName: userLastName,'
        + '                       internalUser: internalUser,'
        + '                       userId: userId,'
        + '                       wqCorporateId: wqCorporateId,'
        + '                       HasElevatedAccess: HasElevatedAccess,'
        + '                       UserType: UserType,'
        + '                    }'
        + '                }'
        + '       }'
        + '   }'
        + '   )(config.userEventInfo || (config.userEventInfo = {}))'
        + ' })(window["adrum-config"] || (window["adrum-config"] = {}))';



        document.head.appendChild(script);

        // add link to ADRUM CDN
        const adrumCDN = document.createElement('script');
        adrumCDN.src = 'https://cdn.appdynamics.com/adrum/adrum-latest.js';
        document.head.appendChild(adrumCDN);
    }

    adrumClickEvent(adrumEvent: any, adrumService: string, adrumComponent: string) {

        const adrumHeader = this.adrumHeader(adrumService);
        let adrumEventValue = adrumEvent.srcElement.innerText;
        const getSessionStorageData = adrumEvent.view.sessionStorage;
        let Sitename = getSessionStorageData.getItem('ecolab.envision_sitename') ?
            getSessionStorageData.getItem('ecolab.envision_sitename') : '';
        var username = getSessionStorageData.getItem('ecolab.envision_username');
        if (Sitename === '' && adrumService === 'asset-tree' && adrumComponent === 'AssetTreeComponent') {
            if (getSessionStorageData.getItem('nalco.SELECTEDSITE')) {
                Sitename = JSON.parse(getSessionStorageData.getItem('nalco.SELECTEDSITE')).SiteName;
            }
        }
        
        const userSettings = JSON.parse(getSessionStorageData.getItem('ecolab.envision_UserSettings'));
        this.storage.storeData('internalUser', userSettings.IsInternalUser, 'local');
        this.storage.storeData('userId', userSettings.UserId.toString(), 'local');
        this.storage.storeData('username', username, 'local');
        this.storage.storeData('adrumService', adrumService, 'local');
        this.storage.storeData('adrumHeader', adrumHeader, 'local');
        this.storage.storeData('adrumComponent', adrumComponent, 'local');
        this.storage.storeData('adrumElement', adrumEventValue, 'local');
        this.storage.storeData('sitename', Sitename, 'local');
        this.storage.storeData('HasElevatedAccess',userSettings.HasNalcoAccesUser, 'local');
        if(userSettings.IsInternalUser){
            this.storage.storeData('UserType','Internal', 'local');
        }else{
            this.storage.storeData('UserType','External', 'local');
        }

        adrumEventValue = '';
    }

    adrumHeader(adrumService) {
        if (document.getElementsByTagName('h1').item(0) !== null) {
            return document.getElementsByTagName('h1').item(0).innerText;
        } else {
            if (adrumService === 'asset-tree') {
                const middleSection: any = document.getElementsByTagName('nalco-subheader-middle-section');
                return middleSection[0].getElementsByClassName('btn-primary')[0].innerText;
            }
            return 'Header Undefined';
        }
    }
    // adrumPost(adrumService: string, adrumComponent: string, adrumElement: string, Sitename: string) {
    //     // DISABLED FOR TESTING INTERNET EXPLORER UI DELAY, NEED TO RE-ENABLE FOR CLICK EVENTS TO WORK
    //     // CONFIRMATION FROM COLIN ON 24 JULY TO RE-ENABLE THIS CODE

    //     const ajaxT = new ADRUM.events.Ajax();
    //     const adrumHeader = this.adrumHeader(adrumService);

    //     console.log('Service: ' + adrumService + ' | ' + 'Header: ' + adrumHeader + ' | '
    //     + 'Component: ' + adrumComponent + ' | ' + 'Element: ' + adrumElement);

    //     // console.log(adrumElement);
    //     // write variables to local storage
    //     this.storage.storeData('adrumService', adrumService, 'local');
    //     this.storage.storeData('adrumHeader', adrumHeader, 'local');
    //     this.storage.storeData('adrumComponent', adrumComponent, 'local');
    //     this.storage.storeData('adrumElement', adrumElement, 'local');
    //     this.storage.storeData('sitename', Sitename, 'local');
    //     // service - widget - component - (element)
    //     ajaxT.url('Service: ' + adrumService + ' | ' + 'Header: ' + adrumHeader + ' | ' + 'Component: ' + adrumComponent + ' | ' + 'Element: ' + adrumElement);
    //     // mark timings
    //     ajaxT.markSendTime(100);
    //     ajaxT.markFirstByteTime(200);
    //     ajaxT.markRespAvailTime(300);
    //     ajaxT.markRespProcTime(400);
    //     ADRUM.report(ajaxT);
    // }

}
