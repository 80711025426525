import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateICUParser } from '@pawelalikowski/ngx-translate-parser-plural-select';
import { TranslateLoader, TranslateModule, TranslateParser } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { createTranslateLoader } from '../../app.module';
import { HttpClient } from '@angular/common/http';
import { AppHeaderComponent } from "./app-header.component";
import { MenuModule } from './../../menu/menu.module';
import { NotificationModule } from './../../notification/notification.module';
import { DividerModule } from '../divider/divider.module';
import { SharedMainModule } from './../../shared-main/shared-main.module';
import { UserProfileDetailsModule } from './../user-profile-details/user-profile-details.module'
@NgModule({
    imports: [
        NgbModule,
        CommonModule,
        FormsModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
            parser: {
                provide: TranslateParser,
                useClass: TranslateICUParser
            }
        }),
        ReactiveFormsModule,
        SharedMainModule,
        MenuModule,
        NotificationModule,
        DividerModule,
        UserProfileDetailsModule
    ],
    declarations: [
        AppHeaderComponent
    ],
    exports: [
        AppHeaderComponent,
        NgbModule,
        FormsModule,
        CommonModule,
    ],
    providers: []
})

export class AppHeaderModule {
}
