import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { EDataOverviewService } from '../../_services/e-data-overview.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ErrorHandling } from '../../../shared/components/error-handling/error-handling';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { MODALS } from '../../_enum/constant';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'nalco-e-data-entry-modals',
  templateUrl: './e-data-entry-modals.component.html',
  styleUrls: ['./e-data-entry-modals.component.scss']
})
export class EDataEntryModalsComponent implements OnInit, OnDestroy {
  measurementCommentForm: UntypedFormGroup;
  @Input() selectedCalcMsrDetails: any;
  @Input() modalText: string;
  @Input() displayData: any = {};
  @Output() emitResponse = new EventEmitter();
  @Input() editData: string;
  @Input() deleteData: any = {};
  dataFetched: boolean = false;
  getCommentResult: any;
  MeasurementLimit: any = {};
  isButtonDisabled: boolean = true;
  subscriptions: Array<Subscription> = [];
  errorHandling = new ErrorHandling(this.modalService, this.translate);
  commentLoader: boolean = false;
  previousComment: any;
  @Output() emitRetryResponse = new EventEmitter();
  @Input() showLoader;
  @Output() emitSiteSelection = new EventEmitter();
  @Output() deleteAction = new EventEmitter();

  constructor(private activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private edataService: EDataOverviewService,
    private router: Router,
    private modalService: NgbModal,
    private translate: TranslateService,
    private toasterService: ToastrService,
  ) { }

  ngOnInit() {
    this.createMeasurementForm();
    this.measurementCommentForm.valueChanges.subscribe(value => {
      value.comment !== '' ? this.isButtonDisabled = false : this.isButtonDisabled = true;
      if (this.editData == MODALS.EDIT_COMMENTS || this.displayData.commentData) {
        this.isButtonDisabled = false;
      }
    })
    if (this.editData == MODALS.EDIT_COMMENTS || this.displayData.commentData) {
      this.fetchComments();
    }
  }

  closeMeasurementModal() {
    this.activeModal.close();
  }

  closeRetryModal() {
    this.activeModal.close();
  }

  createMeasurementForm() {
    this.measurementCommentForm = this.formBuilder.group({
      comment: ['']
    });
  }

  submitMeasurementForm(form) {
    let data: any = {}
    this.commentLoader = true;
    // EDIT COMMENTS
    if (this.editData == MODALS.EDIT_COMMENTS) {
      data = {
        IsDeleted: false,
        MeasurementInstancesAnnotationId: this.getCommentResult.MeasurementInstancesAnnotationId,
        MeasurementInstanceId: this.displayData.measurementDetails.MeasurementInstanceId,
        MeasurementInstanceDateTime: this.displayData.date,
        Annotation: form.value.comment? form.value.comment.trim() : null,
      }
      // Check if comment was updated
      this.checkCommentUpdated(data, form);
    }
    else {
      // ADD COMMENTS
      data = {
        IsDeleted: false,
        MeasurementInstanceId: this.displayData.measurementDetails.MeasurementInstanceId,
        MeasurementInstanceDateTime: this.displayData.date,
        Annotation: form.value.comment.trim(),
      }

      this.subscriptions.push(this.edataService.saveMeasurementAnnotation(data).subscribe(res => {
        if (res !== null) {
          this.commentLoader = false;
          this.emitResponse.emit({ data: data, result: res });
          this.closeMeasurementModal();
        }
      },
        error => {
          if (500 <= error.status && error.status < 600) {
          } else if (error.status === 417) {
            this.router.navigate(['/access-denied']);
          } else if (error.status === 404) {
            this.router.navigate(['/**']);
          } else {
            this.errorHandling.showErrorPopup(error);
          }
          this.commentLoader = false;
          this.emitResponse.emit({ data: data, result: error });
          this.closeMeasurementModal();
        }))
    }

  }

  // Display comments
  fetchComments() {
    this.dataFetched = false;
    if (this.displayData.commentData) {
      this.dataFetched = true;
    }
    this.commentLoader = true;
    const measurementInstanceId = this.displayData.measurementDetails.MeasurementInstanceId;
    const measurementDate = this.displayData.date;
    this.subscriptions.push(this.edataService.getMeasurementAnnotations(measurementInstanceId, measurementDate).subscribe(res => {
      if (res) {
        this.getCommentResult = res;
        this.measurementCommentForm.patchValue({
          comment: res.Annotation
        })
        this.previousComment = res.Annotation
        this.commentLoader = false;
      }
    },
      error => {
        if (500 <= error.status && error.status < 600) {
        } else if (error.status === 417) {
          this.router.navigate(['/access-denied']);
        } else if (error.status === 404) {
          this.router.navigate(['/**']);
        } else {
          this.errorHandling.showErrorPopup(error);
        }
        this.commentLoader = false;
      }))
  }

  checkCommentUpdated(data, form) {
    this.previousComment = this.previousComment.trim();
    form.value.comment = form.value.comment? form.value.comment.trim(): null;
    if (JSON.stringify(this.previousComment) === JSON.stringify(form.value.comment)) {
      // comment not updated
      this.commentLoader = false;
      this.emitResponse.emit({ data: data, result: 'noChanges' });
      this.closeMeasurementModal();
    }
    else {
      // comment updated   
      this.subscriptions.push(this.edataService.saveMeasurementAnnotation(data).subscribe(res => {
        this.commentLoader = false;
        if (res == true) {
          this.emitResponse.emit({ data: data, result: res });
          this.closeMeasurementModal();
        } else if ((res == false)) {
          this.closeMeasurementModal();
          this.toasterService.warning('', this.translate.instant('Edata.COMMENT_UPDATE_FAILED'));
        }
      },
        error => {
          if (500 <= error.status && error.status < 600) {
          } else if (error.status === 417) {
            this.router.navigate(['/access-denied']);
          } else if (error.status === 404) {
            this.router.navigate(['/**']);
          } else {
            this.errorHandling.showErrorPopup(error);
          }
          this.commentLoader = false;
          this.emitResponse.emit({ data: data, result: error });
          this.closeMeasurementModal();
        }))
    }
  }

  closeCommentModal(form) {
    if (this.editData == MODALS.EDIT_COMMENTS) {
      // Edit close
      if (this.previousComment !== undefined && form.value.comment !== '') {
        this.previousComment = this.previousComment.trim();
        form.value.comment = form.value.comment.trim();
      }
      if (JSON.stringify(this.previousComment) === JSON.stringify(form.value.comment)) {
        this.closeMeasurementModal();
      }
      else {
        this.emitResponse.emit({ data: '', result: 'commentUpdated' });
      }
    }
    else {
      // Display and Add close
      this.closeMeasurementModal();
    }
  }

  retryAutosave() {
    this.emitRetryResponse.emit("retry");
    this.activeModal.close();
  }

  submitSavedReadings() {
    this.emitRetryResponse.emit("submitWithoutUnsavedData");
    this.activeModal.close();
  }

  get modals(): typeof MODALS {
    return MODALS;
  }

  allowSiteChange() {
    this.emitSiteSelection.emit({ result: true });
    this.activeModal.close();
  }

  closeSiteModal() {
    this.activeModal.close();
    this.emitSiteSelection.emit({ result: false });
  }

  deleteDataSet() {
    this.commentLoader = true;
    // delete DataSet Column;
    if ((this.deleteData.column.userStatus != "new") || (this.deleteData.column.userStatus == "new" && this.deleteData.dataPresent)) {
      this.subscriptions.push(this.edataService.deleteReadingCollection(this.deleteData.configId, this.deleteData.measurementDate).subscribe(res => {
        if (res == true) {
          this.deleteAction.emit({ result: res });
          this.closeMeasurementModal();
        }
        else if (res == false) {
          this.closeMeasurementModal();
          this.toasterService.warning('', this.translate.instant('Edata.DELETE_FAILED_MSG'));
        }
        this.commentLoader = false;
      },
        error => {
          if (500 <= error.status && error.status < 600) {
          } else if (error.status === 417) {
            this.router.navigate(['/access-denied']);
          } else if (error.status === 404) {
            this.router.navigate(['/**']);
          } else {
            this.errorHandling.showErrorPopup(error);
          }
          this.commentLoader = false;
          this.deleteAction.emit({ result: error });
          this.closeMeasurementModal();
        }))
    }
    else {
      this.deleteAction.emit({ result: 'newSetWithoutData' });
      this.closeMeasurementModal();
    }

  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

}
