import { ICurrencyData } from '..//contracts/ICurrencyData';
export class CurrencyData implements ICurrencyData {
    CurrencyCodeId: string;
    CurrencyName: string;
    Symbol: string;
    constructor(currencyData:ICurrencyData) {           
            this.CurrencyCodeId = currencyData.CurrencyCodeId;
            this.CurrencyName = currencyData.CurrencyName;
            this.Symbol = currencyData.Symbol;
        }
    }