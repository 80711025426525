
// declare var require:any;
// let Boost=require('highcharts/modules/boost');
// import * as Highcharts from 'highcharts';
// Boost(Highcharts);
export const LineChartConfig = {
  type: 'line',
  chart: {
    zoomType: 'x'
  },
  legend: { enabled: false },
  style: {
    fontFamily: 'Interstate-Regular'
  },
  xAxisHeight: 45,
  xAxisColorFill: {
    fill: '#fff',
  },
  title: { text: null },
  headerFormat: '<p class="tool-tip-header">{point.x}</p>',
  pointFormat: '<span class="tooltip-content">{point.y} %</span>',
  lineColor: '#fff',
  backgroundColor: {
    linearGradient: [0, 0, 500, 500],
    stops: [
      [0, 'rgb(18,124,197)'],
      [1, 'rgb(19,182,197)'],
    ]
  },
  credits: {
    enabled: false
  },
  exporting: {
    enabled: false
  },
  boost: {
    enabled: true
    },
  plotOptions: {
    series: {
      events: {
        legendItemClick: function (event) {
          const visibility = this.visible ? 'visible' : 'hidden';
          event.preventDefault();
        }
      },
      marker: {
        enabled: true,
        radius: 1
      },
      customProperties: {
        isCustomLabel: false,
        customLabelValue: ''
      }      
    },
  },
  
  labelFontSize: '14px',

  labelStyle: {
    color: '#fff',
    cursor: 'default',
    fontSize: '14',
  },

  gridLineWidth: 0.2,
  gridLineColor: '#fff',
  yAxisPadding: 0.5,
  
  tooltipStyle: {
    backgroundColor: 'black',
    borderColor: '000000',
    borderRadius: 5,
    useHTML: true,
    headerFormat: '<p class="tool-tip-header">{point.x}</p>',
    pointFormat: '<span class="tooltip-content">{point.y:.2f}%</span>',
    xDateFormat: '%Y-%m-%d'
  },
  highlightOption: {
    color: '#fff',
    lineWidth: 4,
    shadow: true,
  },
  baseOption: {
    color: '#fff',
    lineWidth: 2,
    shadow: false
  }

};
