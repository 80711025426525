import { IMoreOptions } from "../../shared-ui-refresh/more-options/contracts/IMoreOptions";
export enum CategoryType {
    Inventory = 45,
    Edata_MeasurementID = 68,
    WaterLoss_CalculationID = 65,
    WaterTotalizer_CalculationID = 63
}
export enum ASCIIValue {
    CHAR_e_E = 69,
    CHAR_MINUS_SIGN = 187,
    CHAR_PLUS_SIGN = 189
}
export enum AssetType {
    BAG = 44,
    BULKBAG = 64,
    BULK_TANK = 193,
    DRUM = 25,
    PAIL = 96,
    PORTAFEED = 69,
    TOTE = 174,
    OTHER = 614
}
export enum TankType {
    CUSTOM_BULK_TANK = 8
}
export enum AssetDataSourceType {
    UNKNOWN = -1,
    MANUAL = 0,
    AUTOMATIC = 1
}
export enum AssetTypeName {
    BAG = 'Bag',
    BULKBAG = 'Bulk Bag',
    BULKTANK = 'Bulk Tank',
    DRUM = 'Drum',
    PAIL = 'Pail',
    PORTAFEED = 'PORTAFEED®',
    TOTE = 'Tote/IBC',
    OTHER = 'Other Inventory'
}
export enum GeometryName {
    CUBE = 'ASSET_HARVESTER.GEOMETRY_NAME.CUBE',
    SPHERE = 'ASSET_HARVESTER.GEOMETRY_NAME.SPHERE',
    VERTICAL_CYLINDER = 'ASSET_HARVESTER.GEOMETRY_NAME.VERTICAL_CYLINDER',
    HORIZONTAL_CYLINDER = 'ASSET_HARVESTER.GEOMETRY_NAME.HORIZONTAL_CYLINDER',
    HORIZONTAL_CYLINDER_ELLIPTICAL_END = 'ASSET_HARVESTER.GEOMETRY_NAME.HORIZONTAL_CYLINDER_ELLIPTICAL_END',
}
export enum InventoryValue {
    VOLUME = 'ASSET_HARVESTER.INVENTORY_VALUE.VOLUME',
    MASS = 'ASSET_HARVESTER.INVENTORY_VALUE.MASS',
    COUNT = 'ASSET_HARVESTER.INVENTORY_VALUE.COUNT'
}

export enum Image {
    HORIZONTAL_CYLINDER_ELLIPTICAL_END = 'HorizontalCylinderEllipticalEnd'
}

export enum Mode {
    ADD = 'add',
    EDIT = 'edit',
    VIEWONLY = 'viewOnly',
    DELETE = 'delete',
    DUPLICATE = 'duplicate'
}
export enum FormatNumber {
    TWODECIMALDIGIT = '1.2-2'
}
export enum ContextPointType {
    AUTOMATICDATASOURCE = 12000,
    MANUALDATASOURCE = 13000,
    CALCULATEDMEASUREMENT = 3,
    MANUALDATAENTRY = 2
}
export enum AutoDatasourceSubType {
    OBC = 1,
    NXC = 2,
    NGC = 3,
    DIGINGG = 21,
    ECOLABDATACOLLECTOR = 26,
    CUSTOMERDATA = 31,
    DOWNSTREAM_EDATA = 66,
    PARTNERDATA = 65,
    ZIGBEE = 35
}
export enum UnitOfMeasurement {
    INCHES = 'inches',
    KILOGRAMS = 'kilograms',
    GALLONS = 'gallons',
    CENTIMETER = 'centimeters',
    POUNDS = 'pounds',
    LITRES = 'litres'
}
export enum DataSourceType {
    MANUAL = 'Manual',
    AUTOMATIC = 'Automatic',
    CONTROLLER = 'Controller',
    GATEWAY = 'Gateway',
    CUSTOMERDATA = 'Customer Data',
    ZIGBEE = 'Zigbee',
    DOWNSTREAM_EDATA = 'Downstream E-Data',
    PARTNERDATA = 'Partner Data'
}
export const DataSourceName = {
  'Controller' : 'Controller',
  'Gateway' : 'Gateway',
  'Sensor': 'Sensor',
  'LIMS' : 'Lims',
  'E-Data' : 'EData',
  'Customer Data' : 'CustomerData',
  'Zigbee' : 'Zigbee',
  'Partner Data' : 'PartnerData',
  'Downstream E-Data' : 'DownstreamEData'
}
export enum MeasurementIds {
    ANALOG_INPUT = 55,
    MA_SIGNAL = 1
}
export enum CollectionObjectType {
    ASSETS = 'Assets',
    SYSTEMS = 'System'
}

export enum UnmappedMsrType {
    HARD_DELETE = '1',
    SOFT_DELETE = '2'
}

export enum AHAPIResponse {
    SAVE_SUCCESSFULL = 'Saved Successfully',
    HIDDEN_ASSET = 'Asset is hidden.',
    CALIBRATION_SUCCESSFULL = 'Calibration Successfull',
    RETRY_FAILED_LIMIT = 'Retry request sent successfully',
    NAME_PATTERN_ERROR = 'Asset Name should not start with special character'
}
export enum MeasurementMappingDeleteType {
    None = 0,
    DeleteMapping = 1,
    StopNow = 2,
}
export const SensorMeasurementMappingDeleteType: string[] = ['DeleteMapping', 'StopNow'];
export enum DeleteEngineItemType {
    None = 0,
    CpIdSystem = 1,
    CpIdAsset = 2,
    CpIdPort = 3,
    MeasurementInstanceIdTo = 4,
    MeasurementInstanceIdFrom = 5,
    MeasurementInstanceIdCalculationMap = 6,
    MeasurementInstanceScaleId = 7,
}

export enum DeleteReasonType {
    Invalid = 1,
    DoesNotExists = 2,
    Inventory = 3,
    Lims = 4,
    WBI = 5,
    Omni = 6,
    WFI = 7,
    WaterBalance = 8,
    NgcInventoryAsset = 9,
    PushLimitsToControllerRequestInProgress = 10,
    EDataEntryAvaialble = 11,
    AvailableInCalculation = 12
}

export const noScalingGridData = [{
    MeasurementId: 4,
    MeasurementName: 'Digital Input'
}];

export const ROTrainDimensionalUsageKeys = ['RoTrain_Normalize_ConcentrateFlow',
    'RoTrain_Normalize_PermeateFlow',
    'RoTrain_Normalize_FeedFlow',
    'RoTrain_Normalize_FeedTemperature',
    'RoTrain_Normalize_FeedConductivity',
    'RoTrain_Normalize_PermeateConductivity',
    'RoTrain_Normalize_FeedPressure',
    'RoTrain_Normalize_PermeatePressure',
    'RoTrain_Normalize_ConcentratePressure'];

export const MappedSIUnits = [
    { MeasurementName: 'Flow', UnitType: 'VolumeRate', SIUnit: 'cubic_meter_per_second' },
    { MeasurementName: 'Temperature', UnitType: 'Temperature', SIUnit: 'kelvin' },
    { MeasurementName: 'Conductivity', UnitType: 'ConductivityElectrolytic', SIUnit: 'Siemens_per_meter' },
    { MeasurementName: 'Pressure', UnitType: 'Pressure', SIUnit: 'pascal' }
]



export enum TAB_NAME {
    MEASUREMENT_CONFIG = 'measurementConfig',
    E_DATA_TOUR_CONFIG = 'edataTourConfig'
}

// List of measuremnt id's which ahs unique identifiers
export const MeasuremntsHasUIds = [795, 784, 743, 168, 757, 744, 169, 532, 678, 801, 208, 536, 480, 538, 537, 479, 535, 521, 20, 220, 221, 976, 873, 975, 872, 138, 848, 869, 248, 85, 263, 741, 823, 810, 336, 337, 868, 338, 27, 533, 679, 339, 340, 840, 818, 817, 816, 1003, 677, 84, 680, 412, 418, 824, 972, 820, 1566, 1422, 1421];

export const USER_TYPE = {
    USER_TYPE_CUSTOMER: 2,
    USER_TYPE_ADMIN: 1,
    USER_TYPE_SALES: 3
}

export enum dimensions {
    HEIGHT = 'Height',
    WIDTH = 'Width',
    DEPTH = 'Depth',
    DIAMETER = 'diameter',
    ELLIPTICAL_RADIUS = 'elliptical_radius'
}

export enum Geometry {
    CUBE = 'Cube',
    SPHERE = 'Sphere',
    VERTICAL_CYLINDER = 'Vertical Cylinder',
    HORIZONTAL_CYLINDER = 'Horizontal Cylinder',
    HORIZONTAL_CYLINDER_ELLIPTICAL_END = 'Horizontal Cylinder with Elliptical End'
}

export enum Inventory {
    VOLUME = 'Volume',
    MASS = 'Mass',
    COUNT = 'Count'
}

export enum ProductStateValue {
    SOLID = 'Solid',
    LIQUID = 'Liquid',
}

export enum FlowIncomingInstance {
    OBC_FLOW = 'Flow Status',
    NGC_FLOW = 'i_di01'
}

export enum AssetOffline {
    COOLING_TOWER = 175,
    Evaporative_CONDENSER = 93,
    COOLING_TOWER_ONCE_THROUGH = 482,
    CLOSED_LOOP = 206,
    CENTRIFUGAL_CHILLER = 128
}

export enum AutomaticDataSourceType {
    Unknown = 0,
    Obc = 1,
    Nxc = 2,
    Ngc = 3,
    Tla = 4,
    ObcApache = 5,
    Condenser = 6,
    FordEndNode = 7,
    KomodoGatewayConduit = 8,
    KomodoEndNode = 9,
    KomodoGatewayWirelessAggregator = 20,
    Cgw = 10,
    CgwApp = 11,
    Cp = 12,
    Cos = 13,
    NgcConfig = 15,
    CassadyBoiler = 16,
    CassadyAggregator = 17,
    CassadyDataCollector = 18,
    DigiNGG = 21,
    DigiN2G2 = 22,
    Ewcd = 23,
    SmallCooling3DT = 25,
    EcolabDataCollector = 26,
    CustomerData = 31,
    Zigbee = 35,
    AssetCreation = 36,
    CustomerDataAggregator = 37,
    ControllerDataAggregator = 44,
    PressSection = 45,
    AioCooling = 46,
    AioMembrane = 47,
    PostTreatment = 49
}

export enum ManualDataSourceType {
    Lims = 1,
    Mde = 2,
    Transcendance = 3,
    InventoryDelivery = 9,
    PartnerData = 65,
    DownstreamEData = 66,
    Synlab = 10,
    Eurofins = 11
}

export enum NonReachbackMeasurements {
    OriginType = 1,
    SourceApplication = 1
}
export enum ReplicateEdata {
    Measurements = 1,
    Limits = 2,
    Both = 3
}

export enum tvdSteps {
    BaselineCycles = 1,
    MakeUpOrBlowdownWaterMeter = 2,
    Cost = 3,
    SucessScreen = 4
    //EnterReadings = 4
}
export enum tvdStatus {
    NotConfigured = 0,
    Partial = 1,
    Completed = 2
}

export const mstMappingLocationCPSubTypeIds: number[] = [175, 436];

export enum MeasurementSources {
    '22DegreesC' = 10,
    '30DegreesC' = 11,
    '36DegreesC' = 12,
    '37DegreesC' = 13,
    'Controller' = 4,
    'Custom' = 0,
    'Customer' = 3,
    'Default' = -1,
    'Gas Chromatography' = 7,
    'Hydrostatic Sensor' = 8,
    'Laser' = 9,
    'Meter' = 1,
    'Nalco' = 2,
    'Rapid Bio Intelligence' = 5,
    'Titration' = 6
}


export enum UnitUsageType {
  None = 0,
  Flow = 960,
  Volume = 962,
  Temperature = 964
}
export enum COOLING_TOWER_PORTS {
    MakeupWater = 175001,
    Blowdown = 175003,
    CoolingWaterSupply = 175004,
    CollingWaterReturn = 175000,
    Evaporation = 175002
}

export const MakeupWater_Measurements = [346, 978]

export enum TVD_MEASUREMENTS_UOM {
    'RecirculationRate' = 'SI.VolumeRate.cubic_meter_per_second',
    'returnTemperature' = 'SI.Temperature.kelvin',
    'supplyTemperature' = 'SI.Temperature.kelvin',
    'evaporationEfficiency' = 'SI.Percentage.ratio'
}

export const TVD_Measurement_Mapping = [
    {
        portName: 'Cooling Water Supply',
        portSubType: 175004,
        measurementsMapping: [
            {
                measurementId: 346,
                measurementName: 'Recirculation Rate',
                UOM: 'SI.VolumeRate.cubic_meter_per_second',
                IncomingInstance: '458'

            },
            {
                measurementId: 7,
                measurementName: 'Supply Temperature',
                UOM: 'SI.Temperature.kelvin',
                IncomingInstance: '5'
            }
        ]
    },
    {
        portName: 'Cooling Water Return',
        portSubType: 175000,
        measurementsMapping: [
            {
                measurementId: 7,
                measurementName: 'Return Temperature',
                UOM: 'SI.Temperature.kelvin',
                IncomingInstance: '5'
            }
        ]
    },
    {
        portName: 'Evaporation',
        portSubType: 175002,
        measurementsMapping: [
            {
                measurementId: 978,
                measurementName: 'Evaporation Efficiency Rate',
                UOM: 'SI.Percentage.ratio',
                IncomingInstance: 'Cooling Tower Efficiency'
            }]
    }
]
export const MeasureTypeList = [
  {
      EventCode: 6,
      MeasureType: [
          {
              MeasureId: 5,
              MeasureName: 'Flow',
              MeasureUnit: 'Amp',
              FlowMeterType: 1,
              PipeSizeUnit: [
                  {
                      text: 'Gallon',
                      symbol: 'gal'
                    },
                    {
                      text: 'Liter',
                      symbol: 'L'
                    },
              ],
              disable: 'Sensor, Pipesize'
          }, {
              MeasureId: 7,
              MeasureName: 'Temperature',
              FlowMeterType: 2,
              MeasureUnit: '°C',
              PipeSizeUnit: [
                  {
                      text: 'Fahrenheit',
                      symbol: 'F'
                    },
                  {
                      text: 'Celsius',
                      symbol: '°C'
                    }
              ],
              disable: ''
          }
      ]

  }, {
      EventCode: 9,
      MeasureType: [
          {
              MeasureId: 28,
              MeasureName: 'Volume',
              MeasureUnit: 'Amp',
              FlowMeterType: 4,
              PipeSizeUnit: [
                  {
                      text: 'Gallon',
                      symbol: 'gal'
                    },
                    {
                      text: 'Liter',
                      symbol: 'L'
                    },
              ],
              disable: ''
          }
      ]
  }, {
      EventCode: 7,
      MeasureType: [
          {
              MeasureId: 7,
              MeasureName: 'Temperature',
              FlowMeterType: 8,
              MeasureUnit: '',
              PipeSizeUnit: [
                  {
                      text: 'Fahrenheit',
                      symbol: 'F'
                    },
                  {
                      text: 'Celsius',
                      symbol: '°C'
                    }
              ],
              disable: ''
          }
      ]
  }];
export const MoreOptions: IMoreOptions[] = [
    { id: 'assetTree', value: 'assetTree', 'iconClass': 'more-options-asset-tree', label: 'ASSET_HARVESTER.HEADER.ASSET_TREE' },
    { id: 'manageSite', value: 'manageSite', 'iconClass': 'more-options-manage-site', label: 'ASSET_HARVESTER.HEADER.MANAGE_SITE' },
    { id: 'exportCsv', value: 'exportCsv', 'iconClass': 'more-options-export', label: 'ASSET_HARVESTER.HEADER.EXPORT_AS_CSV' },
    { id: 'accountDetails', value: 'accountDetails', 'iconClass': 'more-options-account-details', label: 'ASSET_HARVESTER.HEADER.ACCOUNT_DETAILS' },
];


