import * as contracts from '../contracts/_contracts';
import { AccountSearch } from './AccountSearch';
import { Permissions } from './Permissions';

export class User implements contracts.IUser {

    private static _user: User;

    authenticated: boolean = false;
    username?: string;
    profile: UserProfile;

    constructor(user?: contracts.IUser) {
        if (user) {
            this.authenticated = true; // BYPASS Authentication steps
            this.username = user.EmailAddress;
            if (user.profile) {
                this.profile = new UserProfile(user.profile);
            }

            User._user = this;
        }
    }

    public static currentUser() {
        return User._user;
    }

    public getInitials() {
        let firstName = "this.profile.given_name";
        let lastName = "this.profile.family_name";
        if (!firstName || !lastName) {
            const name = this.profile.name.split('.');
            firstName = name[0];
            lastName = name[1];
        }

        return (firstName.substr(0, 1) + lastName.substr(0, 1)).toUpperCase();
    }
}

export class UserProfile implements contracts.IUserProfile {
    email: string;
    family_name?: string;
    given_name?: string;
    name: string;
    unique_name?: string;
    aio?: string;
    amr?: string[];
    aud?: string;
    exp?: number;
    iat?: number;
    idp?: string;
    ipaddr?: string;
    iss?: string;
    nbf?: number;
    nonce?: string;
    oid?: string;
    sub?: string;
    tid?: string;
    ver?: string;

    constructor(userProfile?: contracts.IUserProfile) {
        if (userProfile) {
            //console.log('User Profile=>', userProfile);
            this.name = "userProfile.name";
            this.given_name = "userProfile.given_name";
            this.email = "userProfile.EmailAddress";
            this.family_name = "userProfile.family_name";
            // if we ever going to use anything populate further
        }
    }

    getInitials() {
        let firstName = this.given_name;
        let lastName = this.family_name;
        if (!firstName || !lastName) {
            const name = this.name.split('.');
            firstName = name[0];
            lastName = name[1];
        }

        return (firstName.substr(0, 1) + lastName.substr(0, 1)).toUpperCase();
    }
}

export class UserPreference {
    UnitsOfMeasure: string;
    Currency: Currency;
    UserRegion?: string;
    constructor(userPreference?: UserPreference) {
        Object.assign(this, userPreference);
    }
}

export class Currency {
    CurrencyCodeId: string;
    CurrencyName: string;
    Symbol: string;
    constructor(currency?: Currency) {
        Object.assign(this, currency);
    }
}


export class UserSettings {
    DefaultContextPointId: number;
    DefaultContextPointName: string;
    DefaultSiteId?: string;
    IsInternalUser: boolean;
    IsStreetAccountUser: boolean;
    HasNalcoAccesUser: boolean;
    IsSingleSiteUser?: boolean;
    Permissions: Permissions[];
    Accounts: AccountSearch[];
    UserPreference: UserPreference;
    Culture: string;
    UserId: number;


    constructor(values: UserSettings) {
        Object.assign(this, values);
    }
}
