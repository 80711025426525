import { IIdAndName } from '../contracts/_contracts';

export class IdandName implements IIdAndName {
    id: string;
    name: string;

    // deprecated properties and should be avoided from using
    isFavorite?: boolean;
    categoryType?: string;

    constructor(idName?: IIdAndName) {
        if (idName) {
            this.id = idName.id;
            this.name = idName.name;

            if (typeof (idName.isFavorite) !== 'undefined') {
                this.isFavorite = idName.isFavorite;
            }
            this.categoryType = idName.categoryType;
        }
    }
}
