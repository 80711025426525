import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { User } from '../../models/_models';
import { headerHandlerService } from './../../../core/nav/header-handler.service';
import { LocalCacheService } from '../../../core/local-cache/local-cache.service';

import { Router } from '@angular/router';
import { MsalV3Service } from '../../../core/services/msal-V3.service';

@Component({
  selector: 'nalco-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  @Input() user: User; // Defines the user Authentication
  @Output() onFilter = new EventEmitter();
  profileDdToggle = false;
  Initials = '';
  collapsed = true;
  userInfo;
  toggleCollapsed(): void {
    this.collapsed = !this.collapsed;
  }

  constructor(private headerService: headerHandlerService, 
    private cache: LocalCacheService, private MsalV3Service: MsalV3Service,
    private router: Router) {
  }

  @HostListener('document:click', ['$event']) clickedOutside(event) {
    if  ((event.target.className.indexOf  !==  undefined
       &&  event.target.className.indexOf('LeftNavigation-link')  >  0)
      ||  (event.target.innerText  !==  undefined  &&  event.target.innerText  ===  'HOME') ||
      (event.target.className.indexOf  !==  undefined && event.target.className.indexOf('overlay')  >=  0)) {
      this.collapsed  =  true;
    }
  }

  ngOnInit() {
    //  this.isMobile = (/android|webos|iphone|ipad|ipod|blackberry|windows phone/).test(navigator.userAgent.toLowerCase());
    this.userInfo = JSON.parse(this.cache.fetchData('msalUserInfo'));
    let initial = '';
    const givenName = this.checkUserName(this.userInfo.givenName);
    if (givenName) {
      initial = givenName.substring(0, 1);
    }
    if (this.userInfo.surname !== undefined) {
      if (this.userInfo.surname) {
        initial += this.userInfo.surname.substring(0, 1);
      } else {
        // fallback to get from given_name, if family name is null
        initial += this.userInfo.givenName.split(' ')[1].substring(0, 1);
      }
    }
    this.Initials = initial.toUpperCase();
  }

  logout() {
    this.router.navigate(['/logout']);
  }

  clickBtn() {
    this.headerService.btnclickhead$(event);
  }

  showProfileSubMenu() {
    this.profileDdToggle = !this.profileDdToggle;
  }

  // Checking given_name is undefined
  checkUserName(obj) {
    if (obj.given_name !== undefined) {
      return obj.givenName;
    } else {
      const res = obj.name.split('.');
      return res[0];
    }
  }
}
