import { Injectable } from '@angular/core';
import { ENVIRONMENTS, APP_CONFIG } from '../../app.config';

@Injectable()
export class SharedOmniConfig {

    private configMock: any = { // MOCK - local json settings
        environment: ENVIRONMENTS.mock,
        baseUrl: '',
        apiUrl: {
            getsites: 'SitePagingByUser',
            getSitesForCorporates: 'CustomerSites',
            getSystemOverallCount: 'api/v1/WaterQualityV1Controller/CategoryOverallCount',
            getDashboardOverview: '/financialimpact/api/dashboard/',
            getContextPointStatus:'api/v1/Aggregation/ContextPointStatus',
            getContextPointGrid: 'api/v1/Aggregation/ContextPointKpisPerformance',
            getContextPoints: 'api/v1/Aggregation/ContextPoints',
            getTopBottomContextPoints :'api/v1/AggregationEnterprise/TopBottomContextPoints',
            getContextPointKpis:'api/v1/Aggregation/ContextPointKpis',
            getSiteAssetPerformance: 'api/v1/AggregationEnterprise/ContextPointsPerformanceInRYG',
            getContextPointKPIEnterprise: 'api/v1/AggregationEnterprise/KpiPerformanceAndContextPointCountInRYG',
            getMeasurementStatus:'api/v1/Aggregation/MeasurementStatus',
            getMACMeasurements: 'api/v1/Aggregation/MeasurementAverage',
            getGraphData: 'Measurement/GraphDataByRanges',
            getMeasurementId: 'Measurement/MeasurementInstances',
            getDiagnostic: 'api/v1/Aggregation/GenericOmniDiagnostic',
        }
    };
    private configProd: any = { // PRODUCTION - PRODUCTION ENVIRONMENT SETTINGS
        environment: ENVIRONMENTS.prod,
        baseUrl: '',
        apiUrl: {
            getsites: 'SitePagingByUser',
            getSitesForCorporates: 'CustomerSites',
            getSystemOverallCount: 'api/v1/WaterQualityV1Controller/CategoryOverallCount',
            getDashboardOverview: '/financialimpact/api/dashboard/',
            getContextPointStatus:'api/v1/Aggregation/ContextPointStatus',
            getContextPointGrid: 'api/v1/Aggregation/ContextPointKpisPerformance',
            getContextPoints: 'api/v1/Aggregation/ContextPoints',
            getTopBottomContextPoints :'api/v1/AggregationEnterprise/TopBottomContextPoints',
            getContextPointKpis:'api/v1/Aggregation/ContextPointKpis',
            getSiteAssetPerformance: 'api/v1/AggregationEnterprise/ContextPointsPerformanceInRYG',
            getContextPointKPIEnterprise: 'api/v1/AggregationEnterprise/KpiPerformanceAndContextPointCountInRYG',
            getMeasurementStatus:'api/v1/Aggregation/MeasurementStatus',
            getMACMeasurements: 'api/v1/Aggregation/MeasurementAverage',
            getGraphData: 'Measurement/GraphDataByRanges',
            getMeasurementId: 'Measurement/MeasurementInstances',
            getDiagnostic: 'api/v1/Aggregation/GenericOmniDiagnostic',
        }
    };

    private _environment: string = APP_CONFIG.ENVIRONMENT;

    constructor() {
        // void
    }

    public getEnvironment(): any {
        switch (this._environment) {
            case ENVIRONMENTS.mock:
                return this.configMock;
            case ENVIRONMENTS.dev:
                return this.configProd;
            case ENVIRONMENTS.test:
                return this.configProd;
            case ENVIRONMENTS.stg:
                return this.configProd;
            case ENVIRONMENTS.prod:
                return this.configProd;
            default:
                return null;
        }
    }

    public setEnvironment(environment: string): any {
        this._environment = environment;
    }

}
