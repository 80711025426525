import { IDropdownControl, IDataProperties, IInputControl } from "../contracts/IData";

export class DataProperties implements IDataProperties {
  DisplayName: string;
  Type: string;
  InputParam: InputControl;
  DropdownParam: DropdownControl;
  Valid: boolean;

  constructor(obj?: IDataProperties) {
    Object.assign(this, obj);
  }
}

export class InputControl implements IInputControl {
  Value: any;
  Required: boolean;
  FormControlName: string;

  constructor(obj?: IInputControl) {
    Object.assign(this, obj);
  }
}

export class DropdownControl implements IDropdownControl {
  ddData: Array<any>;
  Value: any;
  Required: boolean;
  FormControlName: string;

  constructor(obj?: IDropdownControl) {
    Object.assign(this, obj);
  }
}
