<header class="sub-header fixed-top">
  <div class="row container-fluid">
    <nalco-subheader-left-section [ngClass]='leftSection?.className' (btnClick)="btnClicked($event);" [details]="leftSection"></nalco-subheader-left-section>
    <nalco-subheader-middle-section [ngClass]='middleSection?.className' (btnClick)="btnClicked($event);" [details]="middleSection"></nalco-subheader-middle-section>
    <nalco-subheader-right-section [ngClass]='rightSection?.className' (ddChange)="ddChange($event)"  (btnClick)="btnClicked($event);"
      [details]="rightSection"></nalco-subheader-right-section>
      <!-- <h3 style="position: absolute; right: 8%;">
        <iframe  src='../../../../assets/Training Videos/Asset_Tree_Overview/launch.html'  [width]="320" height="568" >Training videos</iframe>
      </h3> -->
  </div>

</header>
