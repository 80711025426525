import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'nalco-success-band',
  templateUrl: './success-band.component.html',
  styleUrls: ['./success-band.component.scss']
})
export class SuccessBandComponent implements OnInit {

  @Input() modalTitle: string;
  @Input() modalText: string;
  @Input() modalContent: string;
  @Input() modalBtn: string;
  @Input() modalData: string;

  constructor(private modalService: NgbModal,
    private activeModal: NgbActiveModal
    ) { }

  /**
   * @description: To dismiss the snackbar.
   * @returns void
   */
  dismissSnackBar(): void {
    this.activeModal.close();
  }

  ngOnInit() : void {
    const timeout = 4000; 

    setTimeout(() => {
      this.dismissSnackBar(); 
    }, timeout)
  }

}
