import {
    IAnnotations,
    IHistoricalData, IMeasurementInstanceAnnotation,
    IMeasurementInstanceLite, IMeasurementInstanceReadings, IMeasurementValue, IUser, IMeasurementLimit
} from "../_contracts/IHistoricalData";
import { USER_STATUS, LIMIT_STATUS } from "../_enum/constant";

export class HistoricalData implements IHistoricalData {
    MeasurementInstanceReadings = [];
    MeasurementDate = '';
    userStatus = USER_STATUS.OLD;
    Annotations = [];
    CreatedBy = {};
    constructor(historyData?: HistoricalData) {
        if (historyData) {
            this.MeasurementDate = historyData.MeasurementDate;
            this.userStatus = USER_STATUS.OLD;
            this.CreatedBy = historyData.CreatedBy; 
            if (historyData.MeasurementInstanceReadings !== null && historyData.MeasurementInstanceReadings.length > 0) {
                historyData.MeasurementInstanceReadings.forEach(value => {
                    this.MeasurementInstanceReadings.push(new MeasurementInstanceReadings(value))
                })
            }else{
                this.MeasurementInstanceReadings.push(new MeasurementInstanceReadings());
            }
            if (historyData.Annotations !== null && historyData.Annotations) {
                historyData.Annotations.forEach(value => {
                    this.Annotations.push(new AnnotationsReadings(value))
                })
            }else{
                this.Annotations.push(new AnnotationsReadings());
            }
        } else {
            this.MeasurementDate = '';
            this.MeasurementInstanceReadings = [];
            this.userStatus = USER_STATUS.OLD;
            this.Annotations = [];
            this.CreatedBy = {};
        }
    }
}
export class MeasurementInstanceReadings implements IMeasurementInstanceReadings {
    MeasurementInstanceAnnotation: MeasurementInstanceAnnotation;
    MeasurementValue: MeasurementValue;
    MeasurementInstanceLite: MeasurementInstanceLite;
    MeasurementLimit: MeasurementLimit
    User: User;

    constructor(measurementInstanceReadings?: MeasurementInstanceReadings) {
        if (measurementInstanceReadings) {
            this.MeasurementInstanceAnnotation = measurementInstanceReadings.MeasurementInstanceAnnotation;
            this.MeasurementValue = measurementInstanceReadings.MeasurementValue;
            this.MeasurementInstanceLite = measurementInstanceReadings.MeasurementInstanceLite;
            this.MeasurementLimit = new MeasurementLimit(measurementInstanceReadings.MeasurementLimit);
            this.User = measurementInstanceReadings.User;
        } else {
            this.MeasurementInstanceAnnotation = new MeasurementInstanceAnnotation();
            this.MeasurementValue = new MeasurementValue();
            this.MeasurementInstanceLite = new MeasurementInstanceLite();
            this.MeasurementLimit = new MeasurementLimit();
            this.User = new User();
        }
    }
}

export class MeasurementInstanceAnnotation implements IMeasurementInstanceAnnotation {
    MeasurementInstancesAnnotationId: number = 0;
    MeasurementInstanceId: number = 0;
    Annotation: string = '';
    MeasurementInstanceDateTime: string = '';
    IsDeleted: false;
    constructor(measurementInstanceAnnotation?: MeasurementInstanceAnnotation) {
        if (measurementInstanceAnnotation) {
            this.MeasurementInstancesAnnotationId = measurementInstanceAnnotation.MeasurementInstancesAnnotationId;
            this.MeasurementInstanceId = measurementInstanceAnnotation.MeasurementInstanceId;
            this.Annotation = measurementInstanceAnnotation.Annotation;
            this.MeasurementInstanceDateTime = measurementInstanceAnnotation.MeasurementInstanceDateTime;
            this.IsDeleted = measurementInstanceAnnotation.IsDeleted;
        } else {
            this.MeasurementInstancesAnnotationId = 0;
            this.MeasurementInstanceId = 0;
            this.Annotation = '';
            this.MeasurementInstanceDateTime = '';
            this.IsDeleted = false;
        }
    }
}

export class MeasurementValue implements IMeasurementValue {
    ValueText: string = '';
    v: any;
    constructor(measurementValue?: MeasurementValue) {
        if (measurementValue) {
            this.ValueText = measurementValue.ValueText;
            this.v = measurementValue.v;
        } else {
            this.ValueText = '';
            this.v = '';
        }
    }
}

export class MeasurementInstanceLite implements IMeasurementInstanceLite {
    MeasurementInstanceId: number = 0;
    constructor(measurementInstanceLite?: MeasurementInstanceLite) {
        if (measurementInstanceLite) {
            this.MeasurementInstanceId = measurementInstanceLite.MeasurementInstanceId;
        } else {
            this.MeasurementInstanceId = 0;
        }
    }
}

export class User implements IUser {
    FirstName: string = '';
    LastName: string = '';
    UserId: number = 0;
    constructor(user?: User) {
        if (user) {
            this.FirstName = user.FirstName;
            this.LastName = user.LastName;
            this.UserId = user.UserId;
        } else {
            this.FirstName = '';
            this.LastName = '';
            this.UserId = 0;
        }
    }
}

export class AnnotationsReadings implements IAnnotations {
    Annotation: string = '';
    AnnotationType: number = 0;
    ContextPointAnnotationId: number = 0;
    ContextPointId: number = 0;
    CreatedBy: number = 0;
    CreatedOn: string = '';
    MeasurementDateTime: string = '';
    ModifiedBy: number = 0;
    ModifiedOn: string = '';
    constructor(annotations?: AnnotationsReadings) {
        if (annotations) {
            this.Annotation = annotations.Annotation;
            this.AnnotationType = annotations.AnnotationType;
            this.ContextPointAnnotationId = annotations.ContextPointAnnotationId;
            this.ContextPointId = annotations.ContextPointId;
            this.CreatedBy = annotations.CreatedBy;
            this.CreatedOn = annotations.CreatedOn;
            this.MeasurementDateTime = annotations.MeasurementDateTime;
            this.ModifiedBy = annotations.ModifiedBy;
            this.ModifiedOn = annotations.ModifiedOn;
        } else {
            this.Annotation = '';
            this.AnnotationType = 0;
            this.ContextPointAnnotationId = 0;
            this.ContextPointId = 0;
            this.CreatedBy = 0;
            this.CreatedOn = '';
            this.MeasurementDateTime = '';
            this.ModifiedBy = 0;
            this.ModifiedOn = '';
        }
    }

}

export class MeasurementLimit implements IMeasurementLimit {
    LowLowLimit: number = null;
    HighHighLimit: number = null;
    LowerLimit: number = null;
    HigherLimit: number = null;
    MeasurementLimitType: string = '';
    MeasurementLimitTypePrettyName: string = '';
    LimitThresholdType: string = '';
    TargetValue: number = 0;
    ModifiedBy: string = '';
    ModifiedOn: string = '';
    AssetContextPointId: number = 0;
    PortSubTypeId: number = 0;
    ControlRange: number = 0;
    ControlMethodType: string = '';
    StartOn: string = '';
    EndOn: string = '';
    ContextPointSubTypeId: number = 0;
    ContextPointTypeId: number = 0;
    limit_status = LIMIT_STATUS.NO_LIMIT;
    constructor(measurementLimit?: MeasurementLimit) {
        if (measurementLimit) {
            this.LowLowLimit = measurementLimit.LowLowLimit;
            this.HighHighLimit = measurementLimit.HighHighLimit;
            this.LowerLimit = measurementLimit.LowerLimit % 1 == 0 ? measurementLimit.LowerLimit : Math.round(measurementLimit.LowerLimit * 100) / 100;
            this.HigherLimit = measurementLimit.HigherLimit % 1 == 0 ? measurementLimit.HigherLimit : Math.round(measurementLimit.HigherLimit * 100) / 100;
            this.MeasurementLimitType = measurementLimit.MeasurementLimitType;
            this.MeasurementLimitTypePrettyName = measurementLimit.MeasurementLimitTypePrettyName;
            this.LimitThresholdType = measurementLimit.LimitThresholdType;
            this.TargetValue = measurementLimit.TargetValue;
            this.ModifiedBy = measurementLimit.ModifiedBy;
            this.ModifiedOn = measurementLimit.ModifiedOn;
            this.AssetContextPointId = measurementLimit.AssetContextPointId;
            this.PortSubTypeId = measurementLimit.PortSubTypeId;
            this.ControlRange = measurementLimit.ControlRange;
            this.ControlMethodType = measurementLimit.ControlMethodType;
            this.StartOn = measurementLimit.StartOn;
            this.EndOn = measurementLimit.EndOn;
            this.ContextPointSubTypeId = measurementLimit.ContextPointSubTypeId;
            this.ContextPointTypeId = measurementLimit.ContextPointTypeId;
            switch (this.LowerLimit + "|" + this.HigherLimit) {
                case (null + "|" + null):
                    this.limit_status = LIMIT_STATUS.NO_LIMIT
                    break;
                case (null + "|" + this.HigherLimit):
                    this.limit_status = LIMIT_STATUS.MAX
                    break;
                case (this.LowerLimit + "|" + null):
                    this.limit_status = LIMIT_STATUS.MIN;
                    break;
                default:
                    this.limit_status = LIMIT_STATUS.AVAILABLE;
                    break;
            }
        } else {
            this.LowLowLimit = null;
            this.HighHighLimit = null;
            this.LowerLimit = null;
            this.HigherLimit = null;
            this.MeasurementLimitType = '';
            this.MeasurementLimitTypePrettyName = '';
            this.LimitThresholdType = '';
            this.TargetValue = 0;
            this.ModifiedBy = '';
            this.ModifiedOn = '';
            this.AssetContextPointId = 0;
            this.PortSubTypeId = 0;
            this.ControlRange = 0;
            this.ControlMethodType = '';
            this.StartOn = '';
            this.EndOn = '';
            this.ContextPointSubTypeId = 0;
            this.ContextPointTypeId = 0;
            this.limit_status = LIMIT_STATUS.NO_LIMIT;
        }
    }
}