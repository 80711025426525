<div class="nalco-customer-division-filter">

    <div class="col-lg-12">
     <div *ngIf="!isMyDocument">
        <div *ngIf="!multiselectDropdownFlag">
            <div class=" col-lg-12" *ngFor="let item of accountDetailArray; let i = index">
                <nalco-dropdown [hideLabelIfSelected]="true" [ddData]="item == undefined ? ddItem : item" (ddChange)="selectedDropdown($event, i);SelectedRow(i)"
                    [isDisabled]="allowChanges">
                    <span class="label">data.label</span>
                </nalco-dropdown>
            </div>
        </div>
        <div *ngIf="multiselectDropdownFlag">
            <div class="multiselectDd" [ngClass]="serviceEnabledName == 'Asset_Details' && (isClearAllVisible ? 'multiselectDd1' : 'multiselectDd2')">
                <div class='ml-2 mb-3' *ngFor="let item of wfiAccountDetailArray; let i = index">
                    <nalco-select-dropdown-integrated *ngIf='i<2' 
                        [list]="item != undefined ? item : []" [showClearSelectionFlag]="false" (getSelectedItems)="itemSelected($event, i);"
                        [multiSelection]="true" [showFilterBycategory]="false" [checkedItemsLimit]="item?.length" (itemSelected)="itemchecked($event,i)"
                        [noRecoredsLabel]="'COMMON.NO_RECORDS_FOUND' | translate" [multiselectText]="'All'" [displayOnInit] = "selectedItems[i] != undefined ? selectedItems[i] : []"
                        [showSearchBox]='false' [isSelectAllAvailable]="true" [serviceEnabledName]='serviceEnabledName == "WFI" ? "WFI" : ""'> 
                    </nalco-select-dropdown-integrated>
                </div>
            </div>
            <div class=" col-lg-12" *ngFor="let item of accountDetailArray; let i = index">
                <nalco-dropdown *ngIf='i>1' [hideLabelIfSelected]="true" [ddData]="item == undefined ? ddItem : item" (ddChange)="selectedDropdown($event, i);SelectedRow(i)"
                    [isDisabled]="allowChanges">
                    <span class="label">data.label</span>
                </nalco-dropdown>
            </div>
        </div>
     </div>
        <div class=" col-lg-12" id={{ifSSDclearAll}} *ngIf="isClearAllVisible">
            <a class="clearAll" href="javascript:void(0)" (click)="clearFilter()" translate>COMMON.CLEAR_ALL</a>
        </div>
    </div>
</div>