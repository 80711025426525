import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { ISubheaderMiddleSection } from '../sub-header.modal';
import { SharedMainService } from '../../../services/shared-main.service';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'nalco-subheader-middle-section',
  templateUrl: './middle-section.component.html',
  styleUrls: ['./middle-section.component.scss']
})
export class SubHeaderMiddleSectionComponent implements OnInit, OnDestroy {
  @Input() details: ISubheaderMiddleSection;
  @Output() btnClick= new EventEmitter<string>();

  activeBtn = 'build';
  routerSubscription: Subscription;

  constructor(private sharedMainService: SharedMainService, private router: Router) {}

  ngOnInit() {
      this.sharedMainService.$assetMaptabSelection.subscribe(res => {
      this.btnClickInSubhead(res.path);
    });
    // Subscribe to router events
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Used setTimeout to ensure update happens after change detection
        setTimeout(() => {
          this.updatePrimaryButton();
        });
      }
    });
    // Initial call to updatePrimaryButton
    setTimeout(() => {
      this.updatePrimaryButton();
    });

  }
  updatePrimaryButton() {
    const currentUrl = this.router.url;
    if (this.details && this.details.data) {
      this.details.data.forEach((btn) => btn.isPrimary = false);
      const urlSegments = currentUrl.split('/');
      const lastSegment = urlSegments[urlSegments.length - 1];
      if (lastSegment === 'asset') {
        this.details.data[0].isPrimary = true;
        this.activeBtn = this.details.data[0].value
      } else if (lastSegment === 'mapMeasurements') {
        this.details.data[1].isPrimary = true;
        this.activeBtn = this.details.data[1].value
      } else if (lastSegment === 'manageMeasurements') {
        this.details.data[2].isPrimary = true;
        this.activeBtn = this.details.data[2].value
      }
    } 
  }

  btnClickInSubhead(which: string) {
    this.activeBtn = which;
    this.btnClick.emit(which);
  }
  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}


