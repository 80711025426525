export const defaultCurrency = 'USD';
export const AgingFilterOption = [
    {
        value: 'All',
        isSelected: true
    },
    {
        value: '30',
        isSelected: false
    },
    {
        value: '60',
        isSelected: false
    },
    {
        value: '90',
        isSelected: false

    }
]
export const AIGeneratedUserID = -28;