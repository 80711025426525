<div class="modal-header pt-4 pl-4 mb-0 border-0">
  <h3 *ngIf="!showWarning" class="modal-title delete-txt-icon">{{modalText}}</h3>
  <h3 *ngIf="savedPopup" class="modal-title save-txt-icon cancelPopup">{{modalsaveText}}</h3>
  <h3 *ngIf="savedPopup" class="modal-title save-txt-icon cancelPopup" [innerHTML]="inventoryText">{{inventoryText}}</h3>
  <button type="button" *ngIf="closeIcon" class="close" aria-label="Close"
    (click)="disablebtn? closeModalPopup(): closeAddEditModal();">
    <img src="../../../../assets/images/modal-close-icon.svg" alt="close" />
  </button>
</div>
<h3 *ngIf="modalAlertText !==''" class="modal-title save-txt-icon cancelPopup" style="margin: 0 25px;">{{modalAlertText}}</h3>
<p *ngIf="modalNoteText !==''" class="modal-title save-txt-icon cancelPopup" style="margin: 0 25px;">{{modalNoteText}}</p>
<p class="revert mb-0 delete-content">{{modcontent}}</p>

<!-- <div *ngIf="showRadio" class="revert mb-0 delete-content">
      <label class="allData" translate>ASSET_TREE.ALL_DATA
          <input type="radio" name="radio" (checked)="selectedValue" (change)="onSelectionChange('allData')">
          <span class="checkmark"></span>
        </label>
        <label class="allData" translate>ASSET_TREE.FUTURE_DATA
          <input type="radio" name="radio" (checked)="selectedValue" (change)="onSelectionChange('futureData')">
          <span class="checkmark"></span>
        </label>
    </div> -->
<div class="p-0 ">
</div>


<div class="modal-footer mt-0">
   <button type="button" *ngIf="cancelBtn" class="btn btn-secondary" (click)="cancelAddEditModal()">{{closeBtn}}</button>
   <button type="button" *ngIf="removeBtn" class="btn btn-secondary" style="margin-right: -25px;" (click)="cancelButtonModal()">{{cancelBtnText}}</button>
  <button type="button" *ngIf="actionBtn" class="btn btn-primary action-btn"
    (click)="cancelAddEditModal()">{{actionBtn}}</button>
  <button type="button" [disabled]="disableSaveButton" *ngIf="showBtn" class="btn btn-secondary stay-btn" (click)="stayModal()">{{stayBtn}}</button>
  <button type="button" *ngIf="disablebtn" id="btnId" class="btn btn-primary save-btn" (click)="Delete()"
    [disabled]="isEnable || disableSubmitButton">{{confirmBtn}}</button>
</div>