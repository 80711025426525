
// eslint-disable-next-line max-len
import { IDropdown } from '../../../shared/components/dropdown/dropdown-model';
import { ISensorMappingAssetDetailsData, ISensorMappingSensorTypeData } from '../_contracts/ISensorMappingFormAllData';
import { MeasureTypeList } from '../_enum/constants';
import { MeasurementInstanceScales } from './SensorMappingFormDataSave';
import { Site } from './Site';

export class FlowMeterInfo {
    MeasurementInstanceId = 0;
    FlowMeterId = 0;
    FlowMeterInfoId = 0;
    Manufacturer = '';
    ModelNumber = '';
    PipeSize = '';
    FlowMeterTypeId?: number;
    FlowMeterType?: number;
    Code = '';
    LowValue = 0;
    HighValue = 0;
    DefaultUnitLowValue = 0;
    DefaultUnitHighValue = 0;
    InputMin = 0;
    InputMax = 0;
    Offset = 0;
    MeasurementInstanceScaleId: number;
    Kfactor: number;
    KfactorUnit = null;
    KfactorIsEditable = false;
    KfactorRangeLow = null;
    KfactorRangeHigh = null;
    KfactorEditType = 0;
    KfactorCsvRange: string;
    HighValueMin: string;
    HighValueMax: string;
    UnitSymbol = '';
    FlowMeterTypeToString = '';
    FlowMeterMeasureTypeID = 0;
    DisplayName: '';


    constructor(flowMeterInfo?) {
        if (flowMeterInfo) {
            this.DisplayName = flowMeterInfo.DisplayName;
            this.MeasurementInstanceId = flowMeterInfo.MeasurementInstanceId;
            this.MeasurementInstanceScaleId = flowMeterInfo.MeasurementInstanceScaleId;
            this.FlowMeterId = flowMeterInfo.FlowMeterId;
            this.Manufacturer = flowMeterInfo.Manufacturer ? flowMeterInfo.Manufacturer : flowMeterInfo.FlowMeter.Manufacturer;
            this.ModelNumber = flowMeterInfo.ModelNumber !== null && flowMeterInfo.ModelNumber !== undefined
                ? flowMeterInfo.ModelNumber
                : flowMeterInfo.FlowMeter.ModelNumber;
            this.PipeSize = flowMeterInfo.PipeSize;
            this.FlowMeterTypeId = flowMeterInfo.FlowMeterTypeId;
            this.FlowMeterType = flowMeterInfo.FlowMeterType;
            this.Code = flowMeterInfo.Code;
            this.LowValue = flowMeterInfo.LowValue > 0 ? flowMeterInfo.LowValue : 0;
            this.HighValue = flowMeterInfo.HighValue > 0 ? flowMeterInfo.HighValue : 0;
            this.DefaultUnitLowValue = flowMeterInfo.LowValue > 0 ? flowMeterInfo.LowValue : 0;
            this.DefaultUnitHighValue = flowMeterInfo.HighValue > 0 ? flowMeterInfo.HighValue : 0;
            this.InputMin = flowMeterInfo.InputMin > 0 ? flowMeterInfo.InputMin : 0;
            this.InputMax = flowMeterInfo.InputMax > 0 ? flowMeterInfo.InputMax : 0;
            this.Offset = flowMeterInfo.Offset > 0 ? flowMeterInfo.Offset : 0;
            this.FlowMeterInfoId = flowMeterInfo.FlowMeterInfoId;
            this.Kfactor = flowMeterInfo.Kfactor;
            this.KfactorUnit = flowMeterInfo.KfactorUnit;
            this.KfactorIsEditable = flowMeterInfo.KfactorIsEditable;
            this.KfactorRangeLow = flowMeterInfo.KfactorRangeLow;
            this.KfactorRangeHigh = flowMeterInfo.KfactorRangeHigh;
            this.KfactorEditType = flowMeterInfo.KfactorEditType;
            this.KfactorCsvRange = flowMeterInfo.DdlRange;
            this.HighValueMin = flowMeterInfo.HighValueMin;
            this.HighValueMax = flowMeterInfo.HighValueMax;
            this.UnitSymbol = flowMeterInfo.UnitSymbol !== null ? flowMeterInfo.UnitSymbol : '';
            this.FlowMeterTypeToString = flowMeterInfo.FlowMeterTypeToString !== null ? flowMeterInfo.FlowMeterTypeToString : '';

            if (flowMeterInfo.FlowMeterType === 2) {
                this.FlowMeterMeasureTypeID = 7;
            }
        }
    }
}

export class DefaultFlowMeter {
    FlowMeterInfoId = 0;
    MeasurementInstanceId = 0;
    FlowMeterId = 0;
    Manufacturer = '';
    ModelNumber = '';
    PipeSize = '';
    FlowMeterTypeId?: number;
    FlowMeterType?: number;
    Code = '';
    LowValue?: number;
    HighValue?: number;
    DefaultUnitLowValue?: number;
    DefaultUnitHighValue?: number;
    InputMin?: number;
    InputMax?: number;
    Offset?: number;
    SensorName = '';
    IsSelected = false;
    Kfactor = 0;
    KfactorUnit: string;
    KfactorIsEditable = false;
    KfactorRangeLow: string;
    KfactorRangeHigh: string;
    KfactorEditType = 0;
    KfactorCsvRange: string;
    HighValueMin: string;
    HighValueMax: string;
    UnitSymbol = '';
    FlowMeterTypeToString = '';
    FlowMeterMeasureTypeID = 0;
    DisplayName = '';

    constructor(FlowMeterId, Manufacturer, ModelNumber, flowMeterInfo?) {

        this.FlowMeterId = FlowMeterId;
        this.Manufacturer = Manufacturer;
        this.ModelNumber = ModelNumber;
        this.SensorName = ModelNumber ? (Manufacturer + ' - ' + ModelNumber) : Manufacturer;
        if (flowMeterInfo) {
            this.DisplayName = flowMeterInfo.DisplayName;
            this.MeasurementInstanceId = flowMeterInfo.MeasurementInstanceId;
            this.PipeSize = flowMeterInfo.PipeSize;
            this.FlowMeterTypeId = flowMeterInfo.FlowMeterTypeId;
            this.FlowMeterType = flowMeterInfo.FlowMeterType;
            this.Code = flowMeterInfo.Code;
            this.LowValue = flowMeterInfo.LowValue > 0 ? flowMeterInfo.LowValue : 0;
            this.HighValue = flowMeterInfo.HighValue > 0 ? flowMeterInfo.HighValue : 0;
            this.DefaultUnitLowValue = flowMeterInfo.LowValue > 0 ? flowMeterInfo.LowValue : 0;
            this.DefaultUnitHighValue = flowMeterInfo.HighValue > 0 ? flowMeterInfo.HighValue : 0;
            this.InputMin = flowMeterInfo.InputMin > 0 ? flowMeterInfo.InputMin : 0;
            this.InputMax = flowMeterInfo.InputMax > 0 ? flowMeterInfo.InputMax : 0;
            this.Offset = flowMeterInfo.Offset > 0 ? flowMeterInfo.Offset : 0;
            this.FlowMeterInfoId = flowMeterInfo.FlowMeterInfoId;
            this.Kfactor = flowMeterInfo.Kfactor;
            this.KfactorUnit = flowMeterInfo.KfactorUnit;
            this.KfactorIsEditable = flowMeterInfo.KfactorIsEditable;
            this.KfactorRangeLow = flowMeterInfo.KfactorRangeLow;
            this.KfactorRangeHigh = flowMeterInfo.KfactorRangeHigh;
            this.KfactorEditType = flowMeterInfo.KfactorEditType;
            this.KfactorCsvRange = flowMeterInfo.DdlRange;
            this.HighValueMin = flowMeterInfo.HighValueMin;
            this.HighValueMax = flowMeterInfo.HighValueMax;
            this.UnitSymbol = flowMeterInfo.UnitSymbol !== null ? flowMeterInfo.UnitSymbol : '';
            this.FlowMeterTypeToString = flowMeterInfo.FlowMeterTypeToString !== null ? flowMeterInfo.FlowMeterTypeToString : '';
            if (flowMeterInfo.FlowMeterType === 2) {
                this.FlowMeterMeasureTypeID = 7;
            }
        }
    }
}

export class DatasourceMapping {
    ContextPointId = 0;
    ContextPointName = '';
    ContextPointType = 0;
    ContextPointTypePrettyName = '';
    MeasurementId = 0;
    MeasurementName = '';

    constructor(datasourceMapping, measurementId, measurementName) {
        this.ContextPointId = datasourceMapping.ContextPointId;
        this.ContextPointName = datasourceMapping.ContextPointName;
        this.ContextPointType = datasourceMapping.ContextPointType;
        this.ContextPointTypePrettyName = datasourceMapping.ContextPointTypePrettyName;
        this.MeasurementId = measurementId;
        this.MeasurementName = measurementName;

    }
}

export class MappingData {
    StuiName = '';
    FlowMeterInfo: FlowMeterInfo;
    MeasurementInstanceScales: Array<MeasurementInstanceScales> = [];
    ActiveMappedMeasurementContextPoint: Array<DatasourceMapping> = [];
    MeasurementInstanceId = 0;
    ContextPointId = 0;
    IncomingInstance = '';
    ReadingMeasurementDate?= new Date();
    Channel = '';
    EventCode: number;
    InstanceId?: number;
    MeasurementId = 0;
    Inputmode = '';
    IsSelected = false;
    MeasureType?: IDropdown = {
        name: '',
        label: '',
        options: []
    };
    SiteContextPointId = 0;
    Site: Site;
    DefaultFlowMeter: Array<DefaultFlowMeter> = [];
    StartDate: any;
    EndDate: any;
    EarliestAvailableMappingDateTime = new Date();
    SelectedFlowData = new FlowMeterInfo();
    MeasureTypeUnits = [];
    DeviceTimeZoneId?: number = null;

    constructor(mappingData?) {
        if (mappingData) {
            // this.DeviceTimeZoneId = mappingData.DeviceTimeZoneId;
            this.Inputmode = mappingData.MeasurementName;
            this.IncomingInstance = mappingData.IncomingInstance;
            this.ReadingMeasurementDate = mappingData.CurrentReadingMeasureDate;
            this.EarliestAvailableMappingDateTime = mappingData.EarliestAvailableMappingDateTime;
            this.FlowMeterInfo = new FlowMeterInfo(mappingData.FlowMeterInfo);
            if (mappingData.MeasurementInstanceScales && mappingData.MeasurementInstanceScales.length > 0) {
                mappingData.MeasurementInstanceScales.forEach(item => {
                    this.MeasurementInstanceScales.push(new MeasurementInstanceScales(item));
                });
            }
            this.StuiName = mappingData.StuiName;
            this.MeasurementId = mappingData.MeasurementId;
            this.MeasurementInstanceId = mappingData.MeasurementInstanceId;
            this.ContextPointId = mappingData.ContextPointId;
            this.InstanceId = mappingData.InstanceId;
            const instancesplit = this.IncomingInstance.split('-');
            this.Channel = instancesplit[0];
            if (instancesplit[0] === '1' && instancesplit[1] === '7') {
                this.Channel = 'RTD';
                this.Inputmode = 'RTD';
            }

            this.EventCode = parseInt(instancesplit[1], 10);
            this.Site = mappingData.Site;
            MeasureTypeList.forEach(item => {
                if (item.EventCode === parseInt(instancesplit[1], 10)) {
                    this.MeasureType.name = '';
                    this.MeasureType.label = 'Measure Type',
                        item.MeasureType.forEach(x => {
                            this.MeasureType.options.push({
                                text: x.MeasureName,
                                value: x.MeasureId.toString(),
                                isSelected: false
                            });
                            this.MeasureTypeUnits.push({
                                value: x.MeasureId.toString(),
                                unit: x.MeasureUnit.toString(),
                                pipeSizeUnit: x.PipeSizeUnit,
                                disable: x.disable,
                                isSelected: false,
                            });
                        });
                }
            });
        }
    }
}

export class SensorMappingFormAllData {
    channelMeasurementMapping: Array<MappingData> = [];
    constructor(sensorMappngFormAllData?) {
        if (sensorMappngFormAllData && sensorMappngFormAllData.length > 0) {
            sensorMappngFormAllData.forEach(element => {
                // eslint-disable-next-line max-len
                if (!element.ActiveMappedMeasurementContextPoint || element.ActiveMappedMeasurementContextPoint.length === 0 || element.IncomingInstance === '1-7') {
                    this.channelMeasurementMapping.push(new MappingData(element));
                }
                // Adding earliest mapping date to row measuerments
                if (element.EarliestAvailableMappingDateTime != null) {
                    sensorMappngFormAllData.forEach(item => {
                        if (element.IncomingInstance == item.IncomingInstance && item.EarliestAvailableMappingDateTime == null) {
                            item.EarliestAvailableMappingDateTime = element.EarliestAvailableMappingDateTime;
                        }
                    });
                }
            });
            if (this.channelMeasurementMapping.length > 0) {
                sensorMappngFormAllData.forEach(derived => {
                    if (derived.ActiveMappedMeasurementContextPoint && derived.ActiveMappedMeasurementContextPoint.length > 0
                        && (derived.MeasurementId === 5 || derived.MeasurementId === 25 || derived.MeasurementId === 7)) {
                        const index = this.channelMeasurementMapping.findIndex(x => x.IncomingInstance === derived.IncomingInstance);
                        if (index > -1) {
                            this.channelMeasurementMapping[index].Site = derived.Site;
                            derived.ActiveMappedMeasurementContextPoint.forEach(item => {
                                if (item.ContextPointType === 9000 || item.ContextPointType === 11000) {
                                    this.channelMeasurementMapping[index].ActiveMappedMeasurementContextPoint.push(
                                        new DatasourceMapping(item, derived.MeasurementId, derived.MeasurementName));
                                }
                            });
                        }
                    }
                });
            }
        }

    }
}

export class SensorMappingSensorTypeData implements ISensorMappingSensorTypeData {
    Sensortypevalue: string;
    Sensortypeid: string;
    PipeSizeValue: string;
    constructor(sensorType?: ISensorMappingSensorTypeData) {
        this.Sensortypevalue = sensorType.Sensortypevalue;
        this.Sensortypeid = sensorType.Sensortypeid;
        this.PipeSizeValue = sensorType.PipeSizeValue;
    }
}

export class SensorMappingAssetDetailsData implements ISensorMappingAssetDetailsData {
    AssetDetails: any;
    PortDetails: any;

    constructor(assetDetails?: ISensorMappingAssetDetailsData) {
        this.AssetDetails = assetDetails.AssetDetails;
        this.PortDetails = assetDetails.PortDetails;
    }
}

export class UnitConversionData {
    UnitType: string;
    FromUnit: string;
    ToUnit: string;
    OriginalValue: string;
    ConvertedValue: null;
    ErrorMessage?: null;
    ParameterName?: null;
    ConversionSource: string;

    constructor(unitConversionData?: UnitConversionData) {
        if (unitConversionData) {
            this.UnitType = unitConversionData.UnitType;
            this.FromUnit = unitConversionData.FromUnit;
            this.ToUnit = unitConversionData.ToUnit;
            this.OriginalValue = unitConversionData.OriginalValue;
            this.ConvertedValue = unitConversionData.ConvertedValue;
            this.ErrorMessage = unitConversionData.ErrorMessage;
            this.ParameterName = unitConversionData.ParameterName;
            this.ConversionSource = unitConversionData.ConversionSource;
        }
    }
}
