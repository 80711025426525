import { Component, OnInit } from '@angular/core';
//import { ToasterService, ToasterConfig } from 'angular2-toaster';
import { ToastrService } from 'ngx-toastr';
import { LoggerService } from './global-logger.service';

@Component({
  selector: 'nalco-logger',
  templateUrl: './logger.component.html',
  styleUrls: ['./logger.component.scss']
})
export class LoggerComponent {
//private toasterService: ToasterService,
 
  constructor(
    // private toasterService: ToasterService,
    private toasterService: ToastrService,
    private loggerService: LoggerService) {
    this.loggerService.logInfoMessage$.subscribe(message => {
      // this.toasterService.pop('info', message.message);
      this.toasterService.info('', message.message);
    });
    this.loggerService.logSuccessMessage$.subscribe(message => {
      // this.toasterService.pop('success', message.message);
      this.toasterService.success('', message.message);
    });
    this.loggerService.logWarningMessage$.subscribe(message => {
      // this.toasterService.pop('warning', message.message);
      this.toasterService.warning('', message.message);
    });
    this.loggerService.logErrorMessage$.subscribe(message => {
      // this.toasterService.pop('error', message.message);
      this.toasterService.error('', message.message);
    });
  }

  // public toasterconfig: ToasterConfig = new ToasterConfig({
  //   showCloseButton: false,
  //   tapToDismiss: true,
  //   timeout: 5000,
  //   positionClass: 'toast-bottom-center',
  //   animation: 'fade',
  //   mouseoverTimerStop: true,
  //   newestOnTop: true,
  //   iconClasses: {
  //     error: 'hide',
  //     info: 'hide',
  //     wait: 'hide',
  //     success: 'hide',
  //     warning: 'hide',
  //     custom: 'hide'
  //   }
  // });

  // iconClasses: {
  //   error: 'hide',
  //   info: 'hide',
  //   wait: 'hide',
  //   success: 'hide',
  //   warning: 'hide',
  //   custom: 'hide'
  // }
}
