<div [style.height.rem]='22'>

  <x-map [Options]="_mapoptions" [Box]="_mapbox" (MapClick)="HideCard()" (ZoomChange)="HideCard()" (CenterChange)="RepositionCard(null)">

    <x-map-marker-layer [EnableClustering]="_mapclustering" [MarkerOptions]="markers" [GridSize]="_mapgridSize"
      [UseDynamicSizeMarkers]="true" (MarkerClick)="ShowCard($event)" [Visible]="_mapvisible">
    </x-map-marker-layer>

  </x-map>

  <!-- Custom infobox -->

  <div class="infoBoxCanvas">
    <div #infoBox class="infoBox" [ngStyle]="{'visibility': _mapshowInfoBox ? 'visible' : 'hidden', 'top': _mapinfoBoxTop + 'px', 'left': _mapinfoBoxLeft + 'px', 'position':'absolute', 'display':'block'}"
      [ngClass]="{
                    'bottom': _mapinfoBoxBeakPosition==0,
                    'top': _mapinfoBoxBeakPosition==2,
                    'right': _mapinfoBoxBeakPosition==1,
                    'left': _mapinfoBoxBeakPosition==3,
                    'topright': _mapinfoBoxBeakPosition==1.5,
                    'topleft':_mapinfoBoxBeakPosition==2.5,
                    'bottomright': _mapinfoBoxBeakPosition==0.5,
                    'bottomleft':_mapinfoBoxBeakPosition==3.5 }">
      <div class="map-site-health-modal ">
        <div>
          <div class="custom-infobox ">
            <div class="text-center">
              <p class="infobox-inner" placement="bottom" [ngbTooltip]="_mapinfoBoxData?.siteName?.trim().length >= 22 ? _mapinfoBoxData?.siteName : ''">{{(_mapinfoBoxData?.siteName?.trim().length
                >= 22 ? _mapinfoBoxData?.siteName?.trim().substr(0,20)+'...' : _mapinfoBoxData?.siteName )}}</p>
              <div class="row infobox-middle">
                <div class="kpi-item" [ngClass]="{'active': kpiType === '1'}">
                  <h4>{{_mapinfoBoxData?.legionellaGrowth === null ? noDataText : _mapinfoBoxData?.legionellaGrowth}}</h4>
                  <p> {{kpiOne}} </p>
                </div>
                <div class="kpi-item" *ngIf="servicePlanType !=='1'" [ngClass]="{'active': kpiType === '2'}">
                  <h4>{{_mapinfoBoxData?.compliance === null ? noDataText : _mapinfoBoxData?.compliance | percent}}</h4>
                  <p> {{kpiTwo}} </p>
                </div>
                <div class="kpi-item" *ngIf="servicePlanType !=='1'" [ngClass]="{'active': kpiType === '3'}">
                  <h4>{{_mapinfoBoxData?.performance === null ? noDataText : _mapinfoBoxData?.performance | percent}}</h4>
                  <p>{{kpiThree}} </p>
                </div>
                <div class="kpi-item" [ngClass]="{'active': kpiType === '4'}">
                  <h4>{{_mapinfoBoxData?.legionellaTest === null ? noDataText : _mapinfoBoxData?.legionellaTest}}</h4>
                  <p>{{kpiFour}} </p>
                </div>
              </div>
              <div class="infobox-link">
                <a [routerLink]="getRouteURL(_mapinfoBoxData?.siteId)"> {{infoBoxLink}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
