import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'nalco-divider',
  templateUrl: './divider.component.html',
  styleUrls: ['./divider.component.scss'],
  encapsulation : ViewEncapsulation.None
})
export class DividerComponent {

  @Input() color: string;

  @Input() length: string;

  @Input() isHorizontal: boolean;

  @Input() customClasses? : string;

  constructor() {
    this.getStylesforDivider();

  }

/**
* @description function to get the styles for the divider based on the values passed
* @returns style const for divider
*/

  getStylesforDivider() {
    const dividerCss = {
      "background-color": this.color,
      "width": this.isHorizontal ? this.length : '1px',
      "height": this.isHorizontal ? '1px' : this.length
    }
    return dividerCss
  }

}
