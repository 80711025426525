import { Component, Input, OnInit } from '@angular/core';
import { NavigationHandlerService } from '../../../../core/nav/navigation-handler.service';

@Component({
  selector: 'nalco-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss']
})
export class LeftMenuComponent implements OnInit {

  @Input() dataSource: any;

  disabled = false;

  constructor(private navService: NavigationHandlerService) {
    navService.navToggle$.subscribe(state => {
      this.disabled = state;
    });

  }
  ngOnInit() {
  }

}
