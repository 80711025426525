import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CalendarView } from '@progress/kendo-angular-dateinputs';
import * as moment from 'moment';
import { LocalCacheService } from '../../../core/local-cache/local-cache.service';
import { DurationOption } from '../../models/DurationOption';
import { IDropdown } from '../dropdown/dropdown-model';
import { ErrorHandling } from '../error-handling/error-handling';

@Component({
  selector: 'custom-date-range-dropdown',
  templateUrl: './custom-date-range-dropdown.component.html',
  styleUrls: ['./custom-date-range-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomDateRangeDropdownComponent implements OnInit, OnChanges {

  @Input() isDisabled? = false;
  @Input() isSelected;
  @Input() service? = '';
  @Input() monthYearFormat = false;
  @Input() defaultSelection = '4';
  @Input() isCaching = true;
  @Input() paramsStartDate?;
  @Input() paramsEndDate?;
  @Input() customLabelValue? = 'DATA_VISUALIZATION.VIEW_GRAPH_BY';
  @Input() futureDatesDisabled?: boolean = false;
  @Input() maxDate?;
  @Input() daysPrior?;
  @Input() allowSameDaySelection: boolean = false;
  public value: string;
  currentYear = new Date().getFullYear();
  previousYear = this.currentYear - 1;
  selectedTimeRange = '7';
  StartDate: string;
  EndDate: string;
  sDate;
  eDate;
  invalidTimeRangeMsg: string;
  updatedData: DurationOption = new DurationOption();
  minDate;
  errorHandling = new ErrorHandling(this.modalService, this.translate);
  @Output('updateDate') updateDate = new EventEmitter<DurationOption>();
  //@Output('selectedText') selectedText = new EventEmitter<string>();
  @Input() disabledFutureDates?: boolean = false;
  @Input() showErrorPopup?: boolean = false;

  month: Array<string> = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];



  dateRanges: IDropdown =
    {
      label: this.translate.instant('DATA_VISUALIZATION.VIEW_GRAPH_BY'),
      name: 'durationDropDown',
      options: [
        {
          text: this.translate.instant('COMMON.SORT_OPTIONS.ONEDAYS'),
          value: '1'
        },
        {
          text: this.translate.instant('COMMON.SORT_OPTIONS.THREEDAYS'),
          value: '2'
        },
        {
          text: this.translate.instant('COMMON.SORT_OPTIONS.SEVENDAYS'),
          value: '3'
        },
        {
          text: this.translate.instant('COMMON.SORT_OPTIONS.THIRTYDAYS'),
          value: '4'
        },
        {
          text: this.translate.instant('COMMON.SORT_OPTIONS.SIXTYDAYS'),
          value: '5'
        },
        {
          text: this.translate.instant('COMMON.SORT_OPTIONS.NINETYDAYS'),
          value: '6'
        },
        {
          text: this.translate.instant('COMMON.SORT_OPTIONS.ONETWOZERODAYS'),
          value: '7'
        },
        {
          text: this.translate.instant('COMMON.SORT_OPTIONS.ONEEIGHTZERODAYS'),
          value: '8'
        },
        {
          text: this.translate.instant('COMMON.SORT_OPTIONS.LASTTHREESIXTYFIVEDAYS'),
          value: '9'
        },
        {
          text: this.translate.instant('COMMON.SORT_OPTIONS.YEARTODATE'),
          value: '10'
        },
        {
          text: this.translate.instant('COMMON.SORT_OPTIONS.CUSTOM'),
          value: '11'
        }
      ]
    };

  constructor(private translate: TranslateService, private localCacheService: LocalCacheService, private modalService: NgbModal) {

  }

  ngOnInit() {
    if (this.maxDate) {
      let lastDate = new Date(this.maxDate);
      this.calculateMinDateForCalender(lastDate)
    }
    this.dateRanges.label = this.translate.instant(this.customLabelValue);
    const cacheDuration = JSON.parse(this.localCacheService.fetchData(this.service + 'Duration'));
    let isAvailable = false;
    if (cacheDuration == null) {
      this.dateRanges.options.forEach(it => {
        // let selectedDate = this.dateRanges.options[3].value;
        // if (this.service === 'WFI') {
        //   selectedDate = this.dateRanges.options[6].value;
        // }
        if (it.value === this.defaultSelection) {
          it.isSelected = true;
          this.selectionChanged(it, true);
        } else {
          it.isSelected = false;
        }
      });
    } else {
      this.dateRanges.options.forEach(it => {
        if (it.value === cacheDuration.timerange) {
          it.isSelected = true;
          this.selectionChanged(it, true);
          isAvailable = true;
        } else {
          it.isSelected = false;
        }
      });
      // this.setDefaultDateRange();
      if (!isAvailable) {
        this.dateRanges.options.forEach(it => {
          if (it.value === this.isSelected) {
            it.isSelected = true;
            this.selectionChanged(it, true);
          } else {
            it.isSelected = false;
          }
        });
      }
    }
  }



  ngOnChanges(changes: SimpleChanges) {

  }

  setDefaultDateRange() {
    const cacheDuration = JSON.parse(this.localCacheService.fetchData(this.service + 'Duration'));
    if (cacheDuration && cacheDuration.startdate && cacheDuration.enddate) {
      this.StartDate = cacheDuration.startdate;
      this.EndDate = cacheDuration.enddate;
      this.sDate = new Date(this.getDate(this.StartDate));
      this.eDate = new Date(this.getDate(this.EndDate));
      this.dateRanges.options.forEach(it => {
        if (it.value === '11') {
          it.isSelected = true;
          this.selectionChanged(it, true);
        } else {
          it.isSelected = false;
        }
      });
    }
  }

  getCalendarView(format: CalendarView): CalendarView {
    if (this.monthYearFormat) {
      return format;
    }
  }

  selectionChanged(data, isDefault) {
    //this.selectedText.emit(data.text);    
    if (!isDefault && data.value === this.dateRanges.options[10].value) {
      data.value = this.isSelected;
      this.dateRanges.options.forEach(it => {
        if (it.value === this.dateRanges.options[10].value) {
          it.isSelected = true;
        } else if (it.value === this.isSelected) {
          it.isSelected = true;
        }
      });
      return;
    }
    this.isSelected = data.value;
    const dateData = new Date();
    let days;
    let startDateContent;
    let endDateContent;
    if (data.value === this.dateRanges.options[0].value) {
      days = new Date(dateData.getTime() - (1 * 24 * 60 * 60 * 1000));
      startDateContent = days.getDate() + '-' + this.month[days.getMonth()] + '-' + days.getFullYear();
      endDateContent = dateData.getDate() + '-' + this.month[dateData.getMonth()] + '-' + dateData.getFullYear();
    } else if (data.value === this.dateRanges.options[1].value) {
      days = new Date(dateData.getTime() - (3 * 24 * 60 * 60 * 1000));
      startDateContent = days.getDate() + '-' + this.month[days.getMonth()] + '-' + days.getFullYear();
      endDateContent = dateData.getDate() + '-' + this.month[dateData.getMonth()] + '-' + dateData.getFullYear();
    } else if (data.value === this.dateRanges.options[2].value) {
      days = new Date(dateData.getTime() - (7 * 24 * 60 * 60 * 1000));
      startDateContent = days.getDate() + '-' + this.month[days.getMonth()] + '-' + days.getFullYear();
      endDateContent = dateData.getDate() + '-' + this.month[dateData.getMonth()] + '-' + dateData.getFullYear();
    } else if (data.value === this.dateRanges.options[3].value) {
      days = new Date(dateData.getTime() - (30 * 24 * 60 * 60 * 1000));
      startDateContent = days.getDate() + '-' + this.month[days.getMonth()] + '-' + days.getFullYear();
      endDateContent = dateData.getDate() + '-' + this.month[dateData.getMonth()] + '-' + dateData.getFullYear();
    } else if (data.value === this.dateRanges.options[4].value) {
      days = new Date(dateData.getTime() - (60 * 24 * 60 * 60 * 1000));
      startDateContent = days.getDate() + '-' + this.month[days.getMonth()] + '-' + days.getFullYear();
      endDateContent = dateData.getDate() + '-' + this.month[dateData.getMonth()] + '-' + dateData.getFullYear();
    } else if (data.value === this.dateRanges.options[5].value) {
      days = new Date(dateData.getTime() - (90 * 24 * 60 * 60 * 1000));
      startDateContent = days.getDate() + '-' + this.month[days.getMonth()] + '-' + days.getFullYear();
      endDateContent = dateData.getDate() + '-' + this.month[dateData.getMonth()] + '-' + dateData.getFullYear();
    } else if (data.value === this.dateRanges.options[6].value) {
      days = new Date(dateData.getTime() - (120 * 24 * 60 * 60 * 1000));
      startDateContent = days.getDate() + '-' + this.month[days.getMonth()] + '-' + days.getFullYear();
      endDateContent = dateData.getDate() + '-' + this.month[dateData.getMonth()] + '-' + dateData.getFullYear();
    } else if (data.value === this.dateRanges.options[7].value) {
      days = new Date(dateData.getTime() - (180 * 24 * 60 * 60 * 1000));
      startDateContent = days.getDate() + '-' + this.month[days.getMonth()] + '-' + days.getFullYear();
      endDateContent = dateData.getDate() + '-' + this.month[dateData.getMonth()] + '-' + dateData.getFullYear();
    } else if (data.value === this.dateRanges.options[8].value) {
      days = new Date(dateData.getTime() - (365 * 24 * 60 * 60 * 1000));
      startDateContent = days.getDate() + '-' + this.month[days.getMonth()] + '-' + days.getFullYear();
      endDateContent = dateData.getDate() + '-' + this.month[dateData.getMonth()] + '-' + dateData.getFullYear();
    } else if (data.value === this.dateRanges.options[9].value) {
      days = new Date(dateData.getFullYear(), 0, 1);
      startDateContent = days.getDate() + '-' + this.month[(days.getMonth())] + '-' + days.getFullYear();
      endDateContent = dateData.getDate() + '-' + this.month[dateData.getMonth()] + '-' + dateData.getFullYear();
    }
    if (data.value !== this.dateRanges.options[10].value) {
      const DVDurationData = {
        'timerange': data.value,
        'startdate': startDateContent,
        'enddate': endDateContent,
        'starttime': '',
        'endtime': '',
        'isMoreThanYear': false,
        'text': data.text
      };
      if (this.isCaching) {
        this.localCacheService.storeData(this.service + 'Duration', JSON.stringify(DVDurationData));
      }
    }
    if ((data.value !== this.dateRanges.options[9].value) && (data.value !== this.dateRanges.options[10].value)) {
      let daysValue = this.getDaysValue(data);
      this.updatedData = { Number: daysValue, Value: data.text, StartDate: startDateContent, EndDate: endDateContent, DateRange: data.value, IsValid: true };
    } else {
      this.updatedData = { Number: undefined, Value: data.text, StartDate: startDateContent, EndDate: endDateContent, DateRange: data.value, IsValid: true };
    }
    this.dataRangeDropDownChange(data);
    if (this.paramsStartDate) {
      if (this.paramsStartDate.includes("1900")) {
        this.sDate = null;
      } else {
        this.updatedData.StartDate = this.paramsStartDate;
        this.sDate = new Date(this.getDate(this.paramsStartDate));
        this.startNEndDate('startDate', this.sDate);
      }
    }
    if (this.paramsEndDate) {
      if (this.paramsEndDate.includes("9999")) {
        this.eDate = null;
      } else {
        this.updatedData.EndDate = this.paramsEndDate;
        this.eDate = new Date(this.getDate(this.paramsEndDate));
        this.startNEndDate('endDate', this.eDate);
      }
    }
    if (data.value != '11') {
      this.updateDate.emit(this.updatedData);
    }
  }

  getDaysValue(data) {
    let selectedValue = data.value.toLowerCase().replace(/\s/g, "");
    let durationNumber: number;
    // Fetch the numeric value from the string of date dropdown
    durationNumber = parseInt(selectedValue.match(/\d+/g).join([]));
    return durationNumber;
  }

  startNEndDate(param: string, event: any) {
    const dateE = event ? event.toString().split(' ') : '';
    if (param === 'startDate') {
      this.StartDate = dateE ? dateE[2] + '-' + dateE[1] + '-' + dateE[3] : undefined;
    } else if (param === 'endDate') {
      this.EndDate = dateE ? dateE[2] + '-' + dateE[1] + '-' + dateE[3] : undefined;
    }
    this.validateTimeRange();
  }

  calculateMinDateForCalender(maxDate) {
    const dateE = maxDate.toString().split(' ');
    this.maxDate = dateE[2] + '-' + dateE[1] + '-' + dateE[3];
    const dateS = new Date(maxDate.setDate(maxDate.getDate() - this.daysPrior)).toString().split(' ');
    this.minDate = dateS[2] + '-' + dateS[1] + '-' + dateS[3];
  }

  public isDateDisabled = (date: Date): boolean => {
    if (this.disabledFutureDates == true) {
      return date.getTime() > new Date().getTime();
    }
    else {
      return (date < new Date(this.minDate) || date > new Date(this.maxDate));
    }
  };

  public onValueChange(date: Date) {
    if (this.futureDatesDisabled) {
      if (this.isDateDisabled(date)) {
        setTimeout(() => this.sDate = new Date(this.minDate), 0);
      } else {
        this.sDate = date;
      }
    }
  }

  public onEndValueChange(date: Date) {
    //this.selectedText.emit(this.translate.instant('COMMON.SORT_OPTIONS.CUSTOM')); //'Custom'
    if (this.futureDatesDisabled) {
      if (this.isDateDisabled(date)) {
        setTimeout(() => this.eDate = new Date(this.maxDate), 0);
      } else {
        this.eDate = date;
      }
    }
  }

  dataRangeDropDownChange(data) {
    this.selectedTimeRange = data.value;
    if (data.value === '11') {
      const cacheDuration = JSON.parse(this.localCacheService.fetchData(this.service + 'Duration'));
      if (cacheDuration && cacheDuration.timerange === '11') {
        this.StartDate = cacheDuration.startdate;
        this.EndDate = cacheDuration.enddate;
        this.sDate = new Date(this.getDate(this.StartDate));
        this.eDate = new Date(this.getDate(this.EndDate));
      }
      this.validateTimeRange();
    }
    if (data.value !== '11') {
      this.sDate = null;
      this.eDate = null;
      this.StartDate = undefined;
      this.EndDate = undefined;
      this.invalidTimeRangeMsg = '';
    }
  }

  cacheDateRange() {
    const DVDurationData = {
      'timerange': '11',
      'startdate': this.futureDatesDisabled == true ? ((new Date(this.StartDate) < new Date(this.minDate) || new Date(this.StartDate) > new Date(this.maxDate)) ? this.minDate : this.StartDate) : this.StartDate,
      'enddate': this.futureDatesDisabled == true ? ((new Date(this.EndDate) < new Date(this.minDate) || new Date(this.EndDate) > new Date(this.maxDate)) ? this.maxDate : this.EndDate) : this.EndDate,
      'starttime': '',
      'endtime': '',
      'isMoreThanYear': false,
      'text': this.translate.instant('COMMON.SORT_OPTIONS.CUSTOM') //'Custom'
    };
    this.localCacheService.storeData(this.service + 'Duration', JSON.stringify(DVDurationData));
  }

  validateTimeRange() {
    if (this.StartDate && this.EndDate) {
      let currentDate = new Date().getDate();
      if (this.monthYearFormat && this.service === 'WFI') {
        this.StartDate = this.StartDate ? moment(this.StartDate).startOf('month').format('DD-MMM-YYYY') : undefined;
        if (moment(this.EndDate).isSameOrAfter(moment(new Date()).format('DD-MMM-YYYY'))) {
          this.EndDate = this.EndDate ? moment(this.EndDate).set("date", currentDate).format('DD-MMM-YYYY') : undefined;
        }
        else {
          this.EndDate = this.EndDate ? moment(this.EndDate).endOf('month').format('DD-MMM-YYYY') : undefined;
        }
      }
      const startDateTime = new Date(this.getDate(this.StartDate)).getTime();
      const endDateTime = new Date(this.getDate(this.EndDate)).getTime();
      const msec = endDateTime - startDateTime;
      const mins = Math.floor(msec / 60000);
      const hrs = Math.floor(mins / 60);
      if (startDateTime && endDateTime) {
        //valid case
        if ((startDateTime < endDateTime) || (this.allowSameDaySelection && (startDateTime == endDateTime))) {
          this.dateRanges.options.forEach(it => {
            if (it.value === '11') {
              it.isSelected = true;
              // this.selectionChanged(it);
              if (this.isCaching) {
                this.cacheDateRange();
              }
            } else {
              it.isSelected = false;
            }
          });
          this.updatedData = { Number: undefined, Value: 'Custom', StartDate: this.StartDate, EndDate: this.EndDate, DateRange: '11', IsValid: true };
          this.updateDate.emit(this.updatedData);

          this.invalidTimeRangeMsg = '';
        } else {
          this.updatedData = { Number: undefined, Value: undefined, StartDate: undefined, EndDate: undefined, DateRange: undefined, IsValid: false };
          this.updateDate.emit(this.updatedData);
          if (this.showErrorPopup == true) {
            let invalidTimeRangeMsg = this.translate.instant('COMMON.INVALID_DATE_SELECTION');
            this.errorHandling.showErrorPopup(invalidTimeRangeMsg);
          }
          else {
            this.invalidTimeRangeMsg = this.translate.instant('COMMON.INVALID_DATE_SELECTION');
          }
        }
      } else {
        this.invalidTimeRangeMsg = '';
      }
    }
  }

  getDate(str) {
    let arr = str.split('-');
    let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    let i = 0;
    for (i; i <= months.length; i++) {
      if (months[i] === arr[1]) {
        break;
      }
    }
    return (i + 1) + '/' + arr[0] + '/' + arr[2];
  }
}

