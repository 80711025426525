import { LineChartConfig } from '../../../shared/components/line-chart/line-chart.config';
import * as moment from 'moment';

export const TrendChartConfig = {
  chart: {
    zoomType: 'x',
    type: 'spline',
    plotBackgroundColor: '#e5f1f9',
    alignTicks: true
  },
  navigator: {
    maskFill: 'rgba(97, 99, 101, 0.30)',
    series: {
      data: [],
    },
    xAxis: {
      reversed: false
    }
  },
  rangeSelector: {
    enabled: false,
    selected: 10
  },
  exporting: {
    enabled: false
  },
  credits: {
    enabled: false
  },
  xAxis: {
    type: 'datetime',
    ordinal: false,
    min: null,
    max: null
  },
  yAxis: [],
  tooltip: {
    split: false,
    shared: true,
    shadow: false,
    borderWidth: 0,
    backgroundColor: '#fff',
    borderColor: '#fff',
    linearGradient: [0, 0, 500, 500],
    borderRadius: LineChartConfig.tooltipStyle.borderRadius,
    useHTML: true,

    formatter: function () {
      const x = moment(this.x).format('DD-MMM-YY, HH.mm');
      const m = moment(this.x).format('MMM');
      const date = new Date(this.x);
      const month = date.getMonth();
      const tranlatedMonth = this.points[0].series.userOptions.months[month];
      const d = x.replace(m, tranlatedMonth);

      return this.points.reduce(function (s, point) {
        if (point.series.name === 'Annotation') {
          let noOfNotes = 0;
          let note = '';
          let color = '';
          point.series.userOptions.note.forEach(element => {

            if (element.time === point.x) {
              note = element.note;
              color = element.color;
              noOfNotes++;
            }
          });
          if (noOfNotes > 1) {
            note = point.series.userOptions.multipleNotesText;
          }
          // eslint-disable-next-line max-len
          return s + ' <br/><span style="position:relative;bottom:5px;font-size: 15px;color:' + color + '">\u25A0</span> ' +  '<span style="display:inline-block; overflow: hidden;text-overflow: ellipsis; max-width:600px;"> ' + note + '</span>';
        } else {
          let y = point.y;
          if (point.series && point.series.userOptions.isDiscrete) {
            y = parseInt(point.series.userOptions.discreteValues[point.y], 10);
          } else {
            y = parseFloat((y).toFixed(2));
          }
          return s + ' <br/><span style="font-size: 15px;color:' + point.color + '">\u25A0</span> ' + ' ' + point.series.name + ': ' + y;
        }
      // eslint-disable-next-line max-len
      }, '<div style = "border-bottom: 1px solid #616365 ;width : 100%; text-align:center; padding: 8px 0px; font-family: Interstate-Regular">'
      + d + '</div>');
    },
    style: {
      zIndex: 100
    },
  },

  legend: {
    enabled: false,
  },
  plotOptions: {
    series: {
      type: 'spline',
      pointStart: null,
      pointInterval: 10 * 60 * 1000,
    }
  },
  series: []
};

export const LineColors = ['#007AC9', '#EEAF00', '#5EB6E4', '#E00034', '#004D71', '#EE7700', '#58A618', '#275E37', '#4D4F53', '#2AD2C9'];

