import { IAccountDetails } from "../_contracts/IAccountDetails"

export class AccountDetails implements IAccountDetails{
    CorporateAccountId: number = 0;
    CorporateAccountName: string;
    SiteName: string;
    SoldToShipTo: string;
    AccountName: string;
    City: string;
    Street: string;
    CustomerLocation: string;
    IsOnboarding: boolean;
    IsTrialEnabled: boolean;

    constructor(accountDetails : IAccountDetails){
        this.CorporateAccountId = accountDetails.CorporateAccountId;
        this.CorporateAccountName = accountDetails.CorporateAccountName;
        this.SiteName = accountDetails.SiteName;
        this.SoldToShipTo = accountDetails.SoldToShipTo;
        this.AccountName = accountDetails.AccountName;
        this.City = accountDetails.City;
        this.Street = accountDetails.Street;
        this.CustomerLocation = accountDetails.CustomerLocation;
        this.IsOnboarding = accountDetails.IsOnboarding;
        this.IsTrialEnabled = accountDetails.IsTrialEnabled;
    }
}