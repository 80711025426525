import { Component, Input, Output, EventEmitter } from '@angular/core';

import { ISubheaderMiddleSection } from '../sub-header.modal';

@Component({
    selector: 'nalco-subheader-middle-section',
    templateUrl: './middle-section.component.html',
    styleUrls: ['./middle-section.component.scss'] 
})
export class SubHeaderMiddleSectionComponent {
    @Input() details: ISubheaderMiddleSection;
    @Output() btnClick= new EventEmitter<string>();

    activeBtn = 'build';

    btnClickInSubhead(which: string) {
        this.activeBtn = which;
        this.btnClick.emit(which);
    }
}
