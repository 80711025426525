import {
  DatePipe,
  HashLocationStrategy,
  LocationStrategy,
} from '@angular/common';
import {
  ErrorHandler,
  NgModule
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HIGHCHARTS_MODULES } from 'angular-highcharts';
import { DndModule } from 'ng2-dnd';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { AuthenticationGuard } from './core/auth/guards/authentication-guard';
import { QualityGuard } from './core/auth/guards/quality-guard';

import { AuthorizationGuard } from './core/auth/guards/authorization-guard';
import { AuthorizationService } from './core/auth/guards/authorization.service';
import { LoginComponent } from './core/auth/login/login.component';

import { headerHandlerService } from './core/nav/header-handler.service';
import { NavigationHandlerService } from './core/nav/navigation-handler.service';
import { SubheaderHandlerService } from './core/nav/subheader-handler.service';

import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
  HttpHeaders
} from '@angular/common/http';
import {
  TranslateLoader,
  TranslateModule,
  TranslateParser
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoggerService } from './core/logger/global-logger.service';
import { LoggerComponent } from './core/logger/logger.component';
// import { ToasterModule } from 'angular2-toaster';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { APP_ROUTER } from './app.routes';
// this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
// optional, provides moment-style pipes for date formatting
// import { MomentModule } from 'angular2-moment';
import { SimpleTimer } from 'ng2-simple-timer';
import { LocalCacheModule } from './core/local-cache/local-cache.module';
import { LocalCacheService } from './core/local-cache/local-cache.service';
// import { HttpInterctor } from './core/auth/http.interceptor';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { IdleExpiry, LocalStorageExpiry } from '@ng-idle/core';
import { TranslateICUParser } from '@pawelalikowski/ngx-translate-parser-plural-select';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import * as Highcharts from 'highcharts';
import * as Histogram from 'highcharts/modules/histogram-bellcurve';
import { AppDynamicsService } from './core/analytics/app-dynamics.service';
import { ApplicationInsightsErrorHandler } from './core/auth/appInsightsErrorHandler';
import { AssetTreeGuard } from './core/auth/guards/asset-tree-guard';
import { HttpErrorCatchingInterceptor } from './core/auth/httpError.interceptor';
import { LogoutComponent } from './core/auth/logout/logout.component';
import { MsalApplicationModule } from './core/auth/msalv3-application.module';
import { RequestHeadersInterceptor } from './core/auth/request-headers.interceptor';
import { ApplicationDowntimeComponent } from './core/components/application-downtime/application-downtime.component';
import { HomeComponent } from './core/components/home/home.component';
import { FilterNavigation } from './core/pipes/filter-navigation.pipe';
import { AppConfigService } from './core/services/app-config.service';
import { MsalV3Service } from './core/services/msal-V3.service';
import { UserService } from './core/user/user.service';
import { ErrorHandlerComponent } from './data-visualization/error-handler/error-handler.component';
import { MenuModule } from './menu/menu.module';
import { SharedMainModule } from './shared-main/shared-main.module';
import { AppHeaderModule } from './shared-ui-refresh/app-header/app-header.module';
import { UserProfileDetailsModule } from './shared-ui-refresh/user-profile-details/user-profile-details.module';
import { ErrorhandlingPopupComponent } from './shared/components/error-handling/errorhandling-popup/errorhandling-popup.component';
import { FnbComponent } from './shared/components/fnb/fnb.component';
import { SiteService } from './shared/services/site.service';
// Highcharts modules
import HighchartsMore from 'highcharts/highcharts-more.src';
import HighchartsBoost from 'highcharts/modules/boost.src';
import HighChartsExport from 'highcharts/modules/exporting';
import HighChartsNoData from 'highcharts/modules/no-data-to-display';
import HighchartsSankey from 'highcharts/modules/sankey.src';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';
import { ConsentPageComponent } from './core/components/consent-page/consent-page.component';
import { ErrorPageModule } from './core/fallback-modules/error-page/error-page.module';
import { SidePanelModule  } from './shared-ui-refresh/side-panel/side-panel.module';

HighChartsNoData(Highcharts);
HighChartsExport(Highcharts);


// ***** section for all factories ******
export function createTranslateLoader(http: HttpClient) {
  const headers = new HttpHeaders();
  headers.set(
    'Cache-Control',
    'no-cache, no-store, must-revalidate, post-check=0, pre-check=0'
  );
  headers.set('Pragma', 'no-cache');
  headers.set('Expires', '0');
  http.options('', { headers: headers });
  return new TranslateHttpLoader(
    http,
    './assets/i18n/',
    '.json?v=' + Date.now()
  );
}

export function HighchartsModules() {
  // apply Highstock Modules to this array
  return [HighchartsMore, HighchartsSankey, HighchartsBoost, Histogram, HighChartsNoData, HighchartsSolidGauge, HighChartsExport];
}
declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean
  }
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoggerComponent,
    HomeComponent,
    FilterNavigation,
    ApplicationDowntimeComponent,
    FnbComponent,
    ErrorHandlerComponent,
    ErrorhandlingPopupComponent,
    LogoutComponent,
    ConsentPageComponent
  ],
  exports: [
    ErrorhandlingPopupComponent,
    ErrorHandlerComponent,
    ConsentPageComponent
  ],
  imports: [
    MsalApplicationModule.forRoot(),
    // RouterModule.forRoot(APP_ROUTES, { preloadingStrategy: PreloadAllModules, useHash: true}),
    APP_ROUTER,
    SharedMainModule.forRoot(),
    // SharedAdminModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      parser: {
        provide: TranslateParser,
        useClass: TranslateICUParser,
      },
    }),
    BrowserModule,
    BrowserAnimationsModule,
    DndModule.forRoot(),
    NgbModule,
    //    ToasterModule,
    ToastrModule.forRoot(), // ToastrModule added
    NgxWebstorageModule.forRoot({
      prefix: 'nalco',
      separator: '.',
      caseSensitive: true,
    }),
    // MomentModule,
    NgIdleKeepaliveModule.forRoot(),
    LocalCacheModule.forRoot({
      mode: 'session',
      namespace: 'ecolab.envision_',
    }),
    DropDownsModule,
    AppHeaderModule,
    MenuModule,
    UserProfileDetailsModule,
    ErrorPageModule,
    SidePanelModule
  ],
  providers: [
    AppConfigService,
    DatePipe,
    SimpleTimer,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HIGHCHARTS_MODULES, useFactory: HighchartsModules },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestHeadersInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorCatchingInterceptor,
      multi: true,
    },
    {
      provide: IdleExpiry,
      useClass: LocalStorageExpiry,
    },
    AuthenticationGuard,
    QualityGuard,
    AssetTreeGuard,
    AuthorizationGuard,
    AuthorizationService,
    SubheaderHandlerService,
    headerHandlerService,
    NavigationHandlerService,
    LoggerService,
    LocalCacheService,
    UserService,
    MsalV3Service,
    SiteService,
    AppDynamicsService,
    NgbActiveModal,
    {
      provide: ErrorHandler,
      useClass: ApplicationInsightsErrorHandler,
    },
    FilterNavigation
  ],
  // entryComponents: [ErrorHandlerComponent, ErrorhandlingPopupComponent],

  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule { }
