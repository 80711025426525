import { Component, OnInit, EventEmitter, Output, Input, ViewEncapsulation, SimpleChanges, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalCacheService } from '../../../core/local-cache/local-cache.service';
import { SubheaderHandlerService } from '../../../core/nav/subheader-handler.service';
import { DateRangeService } from '../../services/date-range.service';
import { IDropdown, IDropdownOption } from '../dropdown/dropdown-model';
import { DatePipe } from '@angular/common';
import { starttimeData, endtimeData } from '../../../shared/_enum/constants';

@Component({
  selector: 'nalco-time-range-section',
  templateUrl: './time-range-section.component.html',
  providers: [DatePipe],
  styleUrls: ['./time-range-section.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TimeRangeSectionComponent implements OnInit, AfterViewInit {
  @Input() updatedValue?;
  @Input() customDatetext?;
  @Output() updateDateData = new EventEmitter<any>();
  @Output() inCompleteSelection = new EventEmitter<any>();
  @Input() service = '';
  key = '';
  selectedTimeRange = '';
  @Output('updateDate') updateDate = new EventEmitter();
  @Output('invalidTimeRange') invalidTimeRange = new EventEmitter();

  startTimeendTimeDropdown: Array<IDropdownOption> = [];
  endTimeDropdown: Array<IDropdownOption> = [];
  element3N;
  element4N;
  @ViewChild('element3',{read:ElementRef, static:false}) element3Ref: ElementRef;
  @ViewChild('element4',{read:ElementRef, static: false}) element4Ref: ElementRef;


  selectStartTimeData: IDropdown = {
    label: this.translate.instant('DATA_VISUALIZATION.START_TIME'),
    name: 'startTimeDropDown',
    options: this.startTimeendTimeDropdown
  };

  selectEndTimeData: IDropdown = {
    label: this.translate.instant('DATA_VISUALIZATION.END_TIME'),
    name: 'endTimeDropDown',
    options: this.endTimeDropdown
  };

  StartDate;
  EndDate;
  StartTime;
  EndTime;
  invalidTimeRangeMsg = '';
  sDate;
  eDate;

  month: Array<string> = [
    this.translate.instant('COMMON.JAN'),
    this.translate.instant('COMMON.FEB'),
    this.translate.instant('COMMON.MAR'),
    this.translate.instant('COMMON.APR'),
    this.translate.instant('COMMON.MAY_SHORT'),
    this.translate.instant('COMMON.JUN'),
    this.translate.instant('COMMON.JUL'),
    this.translate.instant('COMMON.AUG'),
    this.translate.instant('COMMON.SEP'),
    this.translate.instant('COMMON.OCT'),
    this.translate.instant('COMMON.NOV'),
    this.translate.instant('COMMON.DEC')];

  constructor(private subHeaderService: SubheaderHandlerService,
    private localCacheService: LocalCacheService,
    private translate: TranslateService,
    private dateService: DateRangeService, private datePipe: DatePipe) {
    starttimeData.forEach(item => {
      this.startTimeendTimeDropdown.push({
        text: item.value === '*' ? item.text : this.translate.instant(item.text),
        value: item.value,
        isSelected: item.isSelected
      });
    });
    endtimeData.forEach(item => {
      this.endTimeDropdown.push({
        text: item.value === '*' ? item.text : this.translate.instant(item.text),
        value: item.value,
        isSelected: item.isSelected
      });
    });
  }
  ngAfterViewInit(): void {
    this.element3N = this.element3Ref.nativeElement;
    this.element4N = this.element4Ref.nativeElement;
  }

  ngOnInit() {
    this.key = this.service + 'DRDuration';
  }

  // eslint-disable-next-line
  ngOnChanges(changes: SimpleChanges) {
    if (changes.updatedValue && this.updatedValue !== null && this.updatedValue !== '' && this.updatedValue !== undefined) {
      this.selectedTimeRange = this.updatedValue;
    }
  }

  dataRangeDropDownChange(data) {
    this.selectedTimeRange = data.value;
    if (data.value === '11') {
      const cacheDuration = JSON.parse(this.localCacheService.fetchData(this.key));
      if (cacheDuration && cacheDuration.timerange === '11') {
        this.StartDate = cacheDuration.startdate;
        this.EndDate = cacheDuration.enddate;
        this.StartTime = cacheDuration.starttime;
        this.EndTime = cacheDuration.endtime;
        this.sDate = new Date(this.getDate(this.StartDate));
        this.eDate = new Date(this.getDate(this.EndDate));
        this.selectStartTimeData.options.forEach(item => {
          const val = this.convertTime12to24(item.text);
          if (val === this.StartTime) {
            item.isSelected = true;
          } else {
            item.isSelected = false;
          }
        });
        this.selectEndTimeData.options.forEach(item => {
          const val = this.convertTime12to24(item.value);
          if (val === this.EndTime) {
            item.isSelected = true;
          } else {
            item.isSelected = false;
          }
        });
      }
      this.validateTimeRange();
    }
    if (data.value !== '11') {
      this.inCompleteSelection.emit(false);
      this.startTimeendTimeDropdown.forEach(item => {
        if (item.value === '*') {
          item.isSelected = true;
        } else {
          item.isSelected = false;
        }
      });
      this.endTimeDropdown.forEach(item => {
        if (item.value === '*') {
          item.isSelected = true;
        } else {
          item.isSelected = false;
        }
      });
      this.sDate = null;
      this.eDate = null;
      this.StartDate = undefined;
      this.EndDate = undefined;
      this.StartTime = undefined;
      this.EndTime = undefined;
      this.invalidTimeRangeMsg = '';
      this.invalidTimeRange.emit(false);
      if(data.isSelected){
        this.updateDateData.emit(true);
        this.updateDate.emit(data);
      }else{
        this.updateDateData.emit(false);
      }
    }
  }

  startNEndDate(param: string, event: any) {
    if (event != null) {
      const dateE = event.toString().split(' ');
      if (param === 'startDate') {
        this.StartDate = dateE[2] + '-' + dateE[1] + '-' + dateE[3];
      } else if (param === 'endDate') {
        this.EndDate = dateE[2] + '-' + dateE[1] + '-' + dateE[3];
      }
      this.validateTimeRange();
    } else 
      this.invalidTimeRange.emit(true);
  }
  startNEndTime(event: any, param: string) {
    if (param === 'startTime') {
      this.StartTime = this.convertTime12to24(event.text);
    } else if (param === 'endTime') {
      this.EndTime = this.convertTime12to24(event.value);
    }
    this.validateTimeRange();
  }

  getDate(str) {
    const arr = str.split('-');
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let i = 0;
    for (i; i <= months.length; i++) {
      if (months[i] === arr[1]) {
        break;
      }
    }
    return (i + 1) + '/' + arr[0] + '/' + arr[2];
  }

  validateTimeRange() {
    if (this.StartDate && this.EndDate && this.StartTime && this.EndTime) {
      const startDateTime = new Date(this.getDate(this.StartDate) + ' ' + this.StartTime).getTime();
      const endDateTime = new Date(this.getDate(this.EndDate) + ' ' + this.EndTime).getTime();
      const msec = endDateTime - startDateTime;
      const mins = Math.floor(msec / 60000);
      const hrs = Math.floor(mins / 60);
      if (startDateTime && endDateTime) {
        if (hrs < 0) {
          this.isButtonDisable(true);
          this.invalidTimeRangeMsg = this.translate.instant('DATA_VISUALIZATION.TIME_RANGE.INVALID_MSG_1');
        } else if (hrs > 0 && hrs < 7) {
          this.isButtonDisable(true);
          this.invalidTimeRangeMsg = this.translate.instant('DATA_VISUALIZATION.TIME_RANGE.INVALID_MSG_2');
        } else if (hrs > 26280 || (hrs === 26280 && mins > 0)) {
          this.isButtonDisable(true);
          this.invalidTimeRangeMsg = this.translate.instant('DATA_VISUALIZATION.TIME_RANGE.INVALID_MSG_3');
        } else if (hrs === 0) {
          this.isButtonDisable(true);
          if (mins > 0) {
            this.invalidTimeRangeMsg = this.translate.instant('DATA_VISUALIZATION.TIME_RANGE.INVALID_MSG_2');
          } else {
            this.invalidTimeRangeMsg = this.translate.instant('DATA_VISUALIZATION.TIME_RANGE.INVALID_MSG_1');
          }
        } else {
          let isMoreThanYear = false;
          if (hrs > 8760 || (hrs === 8760 && mins > 0)) {
            isMoreThanYear = true;
          }
          this.isButtonDisable(false);
          this.invalidTimeRangeMsg = '';
          this.selectedTimeRange = '11';
          const DVDurationData = {
            'timerange': this.selectedTimeRange,
            'startdate': this.StartDate,
            'enddate': this.EndDate,
            'starttime': this.StartTime,
            'endtime': this.EndTime,
            'isMoreThanYear': isMoreThanYear
          };
          this.localCacheService.storeData(this.key, JSON.stringify(DVDurationData));
          this.updateDateData.emit(true);
          this.updateDate.emit(DVDurationData);
        }
      } else {
        if (this.StartDate > this.EndDate) {
          this.isButtonDisable(true);
          this.invalidTimeRangeMsg = this.translate.instant('DATA_VISUALIZATION.TIME_RANGE.INVALID_MSG_1');
        }
      }
      if (this.invalidTimeRangeMsg != '')
        this.invalidTimeRange.emit(true);
    } else if (this.StartDate && this.EndDate && !this.StartTime && !this.EndTime) {
      this.isButtonDisable(true);
      if (this.StartDate > this.EndDate) {
        this.invalidTimeRangeMsg = this.translate.instant('DATA_VISUALIZATION.TIME_RANGE.INVALID_MSG_1');
      }
      this.invalidTimeRange.emit(true);
    } else {
      this.isButtonDisable(true);
      if (this.StartDate && !this.EndDate) {
        this.invalidTimeRangeMsg = '';
      } else if (this.EndDate && !this.StartDate) {
        this.invalidTimeRangeMsg = '';
      } else if ((!this.StartDate || !this.EndDate) && (this.StartTime || this.EndTime)) {
        this.invalidTimeRangeMsg = '';
      }
      this.invalidTimeRange.emit(true);
    }
  }

  isButtonDisable(invalid) {
    if (this.selectedTimeRange === '11' && invalid) {
      this.inCompleteSelection.emit(true);
    } else {
      this.inCompleteSelection.emit(false);
    }
  }

  convertTime12to24(time12h) {
    const [time, modifier] = time12h.split(' ');
    let [hours, minutes] = time.split(':');
    if (!minutes) {
      minutes = '00';
    }
    if (hours === '12') {
      hours = '00';
    }
    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }
    return hours + ':' + minutes;
  }

  dateUpdate(event) {
    this.updateDate.emit(event);
  }
}
