/* eslint-disable @typescript-eslint/consistent-type-imports */
import { AppDynamicsService } from './../../analytics/app-dynamics.service';
import { Component, OnInit, HostListener } from '@angular/core';
import { LocalCacheService } from '../../local-cache/local-cache.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LoggerService } from '../../logger/global-logger.service';
import { User } from '../../../shared/models/User';
import { AppEnvConfig } from '../../../app.env.config';
import { TranslateService } from '@ngx-translate/core';
import { SubheaderHandlerService } from '../../nav/subheader-handler.service';
import { NavigationHandlerService } from './../../../core/nav/navigation-handler.service';
import { UserService } from './../../../core/user/user.service';
import { FilterNavigation } from './../../../core/pipes/filter-navigation.pipe';
import { environment } from '../../../../environments/environment';
import { MenusResponse } from './../../../menu/menus.json';
import { MenusValues } from './../../../menu/models/menu-values';
import { MenuItems } from './../../../menu/contracts/menu';
@Component({
  selector: 'nalco-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  user: User;
  authenticated = false;
  menuList: MenusValues;
  toolsMenus : MenuItems[];
  digitalServicesMenu : MenuItems[];
  leftMidNavigationItems : MenuItems[] // to get the original midNavigation Items

  isexpanded = false;

  isEDataEntryNavigation: any;
  isClimateIntelligenceNavigation: any;
  isCrudeFlexEnabled: any;
  isFSQSEnabled: any;
  isCredentialMenuHide : boolean;
  isTotalBoothManagementEnabled : boolean;
  isPaperServiceEnabled : boolean;
  permissions = [];
  returnUrl: string;
  subheaderData = {
    leftSection: {
      type: '',
      data: '',
      className: 'col-lg-6'
    },
    middleSection: {
      type: '',
      data: [],
      className: ''
    },
    rightSection: {
      type: '',
      data: [],
      className: ''
    }
  };

  constructor(
    private logger: LoggerService,
    private route: ActivatedRoute,
    private router: Router,
    private cache: LocalCacheService,
    private appDynamicsService: AppDynamicsService,
    private config: AppEnvConfig,
    private translation: TranslateService,
    private subHeaderService: SubheaderHandlerService,
    private navService: NavigationHandlerService,
    private userService: UserService
  ) {
    this.subheaderData.leftSection.data = '';
    this.subHeaderService.updateLeftSection(this.subheaderData.leftSection);
    this.subHeaderService.updateMiddleSection(this.subheaderData.middleSection);
    this.subHeaderService.updateRightSection(this.subheaderData.rightSection);
    this.cache.storeData('adrumService', 'Home', 'local');
    this.cache.storeData('adrumHeader', '', 'local');
    this.cache.storeData('adrumComponent', "HomeComponent", 'local');
    this.cache.storeData('adrumElement', '', 'local');
    this.cache.storeData('sitename', '', 'local');
    this.isEDataEntryNavigation = config.getEnv('isEDataEntryNavigation').toString();
    this.isClimateIntelligenceNavigation = config.getEnv('isClimateIntelligenceNavigation').toString();
    this.isCrudeFlexEnabled = config.getEnv('isCrudeFlexEnabled').toString();
    this.isCredentialMenuHide = config.getEnv('isCredentialMenuHide');
    this.isFSQSEnabled = config.getEnv('isFSQSEnabled');
    this.isTotalBoothManagementEnabled = config.getEnv('isTotalBoothManagementEnabled');
    this.isPaperServiceEnabled = config.getEnv('isPaperServiceEnabled');
  }




  ngOnInit() {

    // not sending query param from guard, now using cache
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.logger.info({ message: 'login component init' }, false, true);
    this.cache.clearData('sitename', 'local');
    this.cache.clearData('corporateAccountName', 'local');
    this.cache.clearData('navigateDV', 'local');
    this.cache.clearData('AH_DefaultDashboard');
    this.getUserSettings();
    this.user = JSON.parse(this.cache.fetchData('msalUserInfo'));
    if (this.userService.userInfo) {
      this.returnUrl = this.returnUrl || this.cache.fetchData('returnUrl', 'local');
      // allow redirect from login only for the first time after receiving id token
      if (this.returnUrl && this.returnUrl.length > 1) {
        //this.router.navigateByUrl(decodeURIComponent(this.returnUrl));
      }
      
    } else {
      // this.msalJsService.login();
      /*
      this.cache.storeData('returnUrl', this.returnUrl, 'local');
     */
    }
  }


  filterByUserRole(role, navigationDetail, linksToExclude: string[]) {
    const userSettings = this.userService.getCurrentSettings();
    const isE3DChina = (this.config.getEnv("IsE3DChina") === "true") ? true : false;
    let navigationMenu = [];
    if (role !== null) {
        const pipe = new FilterNavigation();
        navigationMenu = pipe.transform(role, navigationDetail,userSettings.IsInternalUser, isE3DChina, linksToExclude);
        return navigationMenu;
    } else {
        return navigationMenu;
    }
  }
  
  /**
   * @description Gets list of links to exclude
   * @returns string of links to exclude
   */
  getLinksToExclude(): string[] {
    const isNewFiRefreshEnabled: boolean = this.config.getEnv('isNewFiRefreshEnabled') === "true";
    const excludeLinks = isNewFiRefreshEnabled ? ['/financial-impact/admin'] : [];

    return excludeLinks;
  }

  getUserSettings() {
    const userSettings = this.userService.getCurrentSettings();
    if (userSettings.Permissions) {
      this.permissions = userSettings.Permissions;
    }
    // const userCulture = userSettings.Culture.match(/en|es|pt|zh|de|nl|fr|ru/);
    // const userLanguage = userCulture != null ? userCulture[0] : 'en';

    const role = this.userService.getUserRole();
    const userPermissions = [];
    if (role !== null) {
        role.forEach(function (s) {
            userPermissions.push(s.PermissionId.toString());
        });
    }
    const MenuItemsArray = JSON.parse(JSON.stringify(MenusResponse))
    this.menuList = MenuItemsArray;
    const linksToExclude = this.getLinksToExclude();
    this.menuList.leftTopNavigation = this.filterByUserRole(userPermissions, MenuItemsArray.leftTopNavigation, linksToExclude)
    this.menuList.leftMidNavigation = this.filterByUserRole(userPermissions, MenuItemsArray.leftMidNavigation, linksToExclude)
    this.menuList.leftBottomNavigation = this.filterByUserRole(userPermissions, MenuItemsArray.leftBottomNavigation, linksToExclude)
    this.hideMenuItems();   
    this.getToolsMenus(); 
    this.getDigitalServicesMenu(); 
  }
  hideMenuItems() {
    if (this.isClimateIntelligenceNavigation == "false") {
      this.menuList.leftTopNavigation.forEach((s, index) => {
        if (s.label === 'LEFT_NAV.MAIN_NAV_LABEL') {
          s.subNavigation.forEach((d, i) => {
            if (d.label === 'LEFT_NAV.SUBNAV_CLIMATE.NAV_LABEL') {
              this.menuList.leftTopNavigation[index].subNavigation.splice(i, 1)
            }
          })
        }
      });
    }
    if (this.isCrudeFlexEnabled === "false") {
      this.menuList.leftTopNavigation.forEach((s, index) => {
        if (s.label === 'LEFT_NAV.MAIN_NAV_LABEL') {
          s.subNavigation.forEach((d, i) => {
            if (d.label === 'LEFT_NAV.SUBNAV_CRUDE_FLEX_CORPORATION.NAV_LABEL') {
              this.menuList.leftTopNavigation[index].subNavigation.splice(i, 1)
            }
          })
        }
      });
    }
    if (this.isFSQSEnabled === "false") {
      this.menuList.leftTopNavigation.forEach((s, index) => {
        if (s.label === 'LEFT_NAV.MAIN_NAV_LABEL') {
          s.subNavigation.forEach((d, i) => {
            if (d.label === 'LEFT_NAV.SUBNAV_FSQS.NAV_LABEL') {
              this.menuList.leftTopNavigation[index].subNavigation.splice(i, 1)
            }
          })
        }
      });
    }
    if (!this.isTotalBoothManagementEnabled) {
      this.menuList.leftTopNavigation.forEach((s, index) => {
        if (s.label === 'LEFT_NAV.MAIN_NAV_LABEL') {
          s.subNavigation.forEach((d, i) => {
            if (d.label === 'LEFT_NAV.SUBNAV_TOTAL_BOOTH_MANAGEMENT.NAV_LABEL') {
              this.menuList.leftTopNavigation[index].subNavigation.splice(i, 1)
            }
          })
        }
      });
    }
    if (!this.isPaperServiceEnabled) {
      this.menuList.leftTopNavigation.forEach((s, index) => {
        if (s.label === 'LEFT_NAV.MAIN_NAV_LABEL') {
          s.subNavigation.forEach((d, i) => {
            if (d.label === 'LEFT_NAV.SUBNAV_PAPER.NAV_NAME') {
              this.menuList.leftTopNavigation[index].subNavigation.splice(i, 1)
            }
          })
        }
      });
    }
    // code is commented ,Logic is no more required
    // if (!this.isCredentialMenuHide) { 
    //   this.navigationMenu.leftNavigation.forEach((s, index) => {
    //     if (s.label === 'LEFT_NAV.ADMIN_NAV_LABEL') {
    //       s.subNavigation.forEach((d, i) => {
    //         if (d.label === 'LEFT_NAV.SUBNAV_CREDENTIAL_MANAGEMENT.NAV_LABEL') {
    //           this.navigationMenu.leftNavigation[index].subNavigation.splice(i, 1)
    //         }
    //       })
    //     }
    //   });
    // }
  }
  navigateToExternal(navigationItem: any){
    if (navigationItem.breadcrumb === 'CIP') {
      const isE3DChina = (this.config.getEnv("IsE3DChina") === "true") ? true : false;
      if(isE3DChina){
        window.open(environment.CIP_LINK_China);
      }else{
        window.open(environment.CIP_LINK);
      }
    }
    else if (navigationItem.breadcrumb === 'eCommerce') {
      window.open(environment.E_Commerce);
    }
    else if (navigationItem.breadcrumb === 'E-data-workaround') {
      // Adding this condition to navigate to new E-Data Entry page only on QA.
      if(this.isEDataEntryNavigation == "true"){
        this.router.navigate(['/e-data-entry']);
      } else {
        window.open(environment.Envision_EData_Workaround);
      }
    }
    else if(navigationItem.breadcrumb === 'Wash Process Intelligence') {
      window.open(environment.Water_Process_Intelligence);
    }
    else if (navigationItem.isExternal) {
      window.open(environment[navigationItem.link]);
    } 
  }

    /**
   * @description Get the digital services menus as per roles
   * 
   */


  getDigitalServicesMenu():void{
    this.digitalServicesMenu = [];
    this.menuList.leftTopNavigation.forEach(menu => {
      if (menu.text === 'LEFT_NAV.MAIN_NAV_LABEL') {
        this.digitalServicesMenu = menu.subNavigation;
      }   
    });
  }


  
  /**
   * @description Get the tools menus as per the slice method
   * 
   */

  getToolsMenus(){
    this.toolsMenus = [];
    this.leftMidNavigationItems = [];
    this.menuList.leftMidNavigation.forEach(menu => {
      if (menu.text === 'LEFT_NAV.MAIN_NAV_TOOLS') {
        this.leftMidNavigationItems = menu.subNavigation
        if (menu.subNavigation.length > 6) {
          this.toolsMenus = menu.subNavigation.slice(0,6)
          this.isexpanded = false;
        } else{
          this.toolsMenus = menu.subNavigation;
          this.isexpanded = false;
        }
      }   
    });
  }

    
  /**
   * @description Expand the accordian on click by user
   */


  expandAccordian(){
    this.toolsMenus = this.leftMidNavigationItems;
    this.isexpanded = true;

  }

  /************ Analytics Listener ************/
  @HostListener('click', ['$event'])
  onClick(event) {
    const adrumEvent = event;
    const adrumService = 'Home';
    const adrumComponent = this.constructor.name.toString();
    this.appDynamicsService.adrumClickEvent(adrumEvent, adrumService, adrumComponent);
  }

}
