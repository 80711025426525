import { Component, HostListener, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ControllerManagementService } from '../../../controller-management/_services/controller-management.service';
import { LocalCacheService } from '../../../core/local-cache/local-cache.service';
import { ActivatedRoute, Router } from '@angular/router';
import { XdComponentState } from '../xd-container/xd-component';
import * as moment from 'moment';
import * as highstock from 'highcharts/highstock';
import { DateLocaleService } from '../../services/date-locale.service';
import { DatePipe } from '@angular/common';
import { DateTimePipe } from '../../pipes/date-time-format.pipe';
import { TrendChartConfig } from './trend-chart.config';
import { ErrorHandling } from '../error-handling/error-handling';
import { DateRangeService } from '../../services/date-range.service';
import { DurationOption } from '../../models/DurationOption';



@Component({
  selector: 'nalco-view-trend-chart',
  templateUrl: './view-trend-chart.component.html',
  styleUrls: ['./view-trend-chart.component.scss']
})
export class ViewTrendChartComponent extends XdComponentState implements OnInit {
    @Input() MeasurementInstanceId;
    @Input() SiteCpId;
    @Input() ParameterName;
    @Input() HeaderTitle;
    @Input() SerialNo = '';
    @Input() LastDataReceived;
    serialNumber: any;
    lastDataReceived: any;
    isLoading: boolean = false;
    errorInfo: any;
    chart;
    chartData = [];
    param = [];
    options = TrendChartConfig;
    maxValueforYAxis: any; 
    minValueforYAxis: any; 
    FullMonthnames;
    noDataError = true;
    dataPoints: any;
    removeChartLoader: boolean = false;
    activeTab = 'linechart';
    isError = false;
    isChartPlotted = false;
    userSettings : any;
    StartOn :any;
    EndBefore :any;
    leftOffsetCount = 10;
    rightOffsetCount = 10;
    stackedChart: boolean;
    duration = 30;
    durationData = {
      timerange: "4",
      startdate: '',
      enddate: '',
      starttime: "",
      endtime: "",
      isMoreThanYear: false,
      accordionId: "accordion-1"
    }
    errorHandling = new ErrorHandling(this.modalService, this.translate );
    isMobile : boolean;
    screenWidth :any
    width :any
    left:any;
    logScale_low_Value = 0.01;
    popUpWindow: NgbModalRef;
    displayUnit:any;
  constructor(private translate: TranslateService,
     public activeModal: NgbActiveModal,
     public controllerManagementService : ControllerManagementService,
     private localCacheService: LocalCacheService,
     private modalService: NgbModal,
     private router: Router,
     private dateLocaleService: DateLocaleService,
     private activatedRoute: ActivatedRoute,
     private datePipe: DatePipe,
     private dateTimeFormat: DateTimePipe,
     private dateService: DateRangeService) {super()
  }
  ngOnInit() {
    this.userSettings = JSON.parse(this.localCacheService.fetchData('UserSettings'));
    if(this.HeaderTitle === 'Gateway Statistics'){
    let length = this.ParameterName.length-1;
    let start = this.ParameterName.indexOf('(')+1;
    this.ParameterName = this.ParameterName.substring(start,length);
    }
    this.getDateRange(this.duration);
    this.FullMonthnames = this.dateLocaleService.getShortLocaleMonths();
    this.getMeasurementsData();
  }

  // Please note that getDateRange() logics only work in case page loading on default 
  // and we need to show Start date and End Date based on default Dropdown value which is 30 Days 
  // But below method won't perform properly in case Custom Date range is selected User selects End Date as other than current Date
  // Below methods changes value of StartDate Selected by User based on Duration of User's StartDate and EndDate
  // And Sets EndDate is Current Date which is incorrect.
 
  getDateRange(duration : any) {
  let currentDate = new Date()
  let durationdays = new Date(currentDate.getTime() - ( duration* 24 * 60 * 60 * 1000));
  let day = durationdays.getDate();
  let month = durationdays.getMonth();
  let year = durationdays.getFullYear();
  let dateRange = new Date(year, month, day)
  this.StartOn = this.datePipe.transform(dateRange, "dd-MMM-yyyy");
  this.EndBefore = this.datePipe.transform(currentDate, "dd-MMM-yyyy");
  }
viewDateFilters(content){
  this.popUpWindow = this.modalService.open(content, { backdrop: 'static', keyboard: false });
}
  getMeasurementsData() {
    this.dataPoints=null;
       const payload = {
          SiteCpId: this.SiteCpId,
          StartOn: this.StartOn,
          EndBefore: this.EndBefore,
          MeasurementIntanceId: this.MeasurementInstanceId 
        }
      this.isLoading = true;
      this.controllerManagementService.getParameterTrendData(payload).subscribe(
      response => {
        if (response) {
          this.dataPoints = response.GraphData;
          this.removeChartLoader = true;
          this.isChartPlotted = true;
          if (this.isError) {
            this.activeTab = 'linechart';
            this.isError = false;
          }
        } else {
          this.isError = true;
          this.activeTab = 'error';
          this.isChartPlotted = false;
        }
        this.setChartData(true);
        this.isLoading = false;
      },
      error => {
        this.isLoading = false;
        this.isError = true;
        this.activeTab = 'error';
        this.errorInfo = error;
        if (500 <= error.status && error.status < 600) {
        } else if (error.status === 417) {
          this.router.navigate(['/access-denied']);
        } else if (error.status === 404) {
          this.router.navigate(['/**']);
        } else {
          this.errorHandling.showErrorPopup(error);
        }

      });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.getWindowWidth();
  }
  getWindowWidth() {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 540) {
      this.width = 440
      this.left = 0
    } else if (this.screenWidth < 767 && this.screenWidth > 541) {
      this.width = 550;
      this.left = -2
    } else if (this.screenWidth < 1025 && this.screenWidth > 767) {
      this.width = 650;
      this.left = -5
    } else {
      this.width = 880;
      this.left = -10
    }
  }
  setChartData(param?) {
    this.chartData = [];
      let unit = '';
      this.dataPoints.forEach((param, i) => {
        if(this.HeaderTitle === "System Health")
        {
          unit='%';
          param.MeasureName = param.MeasureName + (unit ? ' (' + unit + ')' : '');
        }
        else
        {
          if (param.AvailableMeasurementUnits && param.AvailableMeasurementUnits.length > 0) {
            const unitIndex = param.AvailableMeasurementUnits.findIndex(x => x.IsSelected);
            if (unitIndex !== -1) {
              unit = param.AvailableMeasurementUnits[unitIndex].Symbol;
            }
            param.MeasureName = param.MeasureName + (unit ? ' (' + unit + ')' : '');
          }
        }
          this.displayUnit = (unit ? ' (' + unit + ')' : '');
          param.isCustomLegend = true;
          param.color = '#007ac9';
          param.logScale = (param.ChartScaleType === 1) ? true : false;
          this.chartData.push(param)
      });
    const series = [];
    const yAxis = [];

    let yAxisIndex = 0;
    let notInRangeCount = 0;
    let logscale;
    this.chartData.forEach((item, index) => {
      let notInRange = false;

      if (item !== null) {

        let lineColorValue = -1;

        let axisExtremes = { min: 0, max: 0 };
        let points = [];
        let isDiscrete = false;
        const discretePositions = [];
        const discretePoints = [];
        const discreteValues = [];

        if (this.chartData[index].AggregatedRawData && this.chartData[index].AggregatedRawData.length > 0) {
          this.chartData[index].AggregatedRawData.forEach(i => {
            const val = i.v;
            const d = moment(i.i).valueOf();
            const qualifierValue = i.q;
            // filtering data points as per chart axis range settings
            ((axisExtremes.min !== null && axisExtremes.min !== 0) && (axisExtremes.max !==null && axisExtremes.max !== 0)) ? ((val >= axisExtremes.min && val <= axisExtremes.max) ? points.push([d, val, qualifierValue]): null) : points.push([d, val, qualifierValue]);
          });
        }
        else if (this.chartData[index].HighChartValues && this.chartData[index].HighChartValues.length > 0) {
          points = this.chartData[index].HighChartValues;
        }
        if (points && points.length > 0 && axisExtremes.min === 0 && axisExtremes.max === 0) {
          axisExtremes = this.getMinMax(0.2, item.Statistic.Minimum, item.Statistic.Maximum);
        }
        this.maxValueforYAxis = 0;
        this.minValueforYAxis = 0;

        if (item.Statistic) {
          if (item.Statistic.HighLimit) {
            this.maxValueforYAxis = item.Statistic.HighLimit + (item.Statistic.HighLimit * 2) / 100;
          }
          if (item.Statistic.LowLimit) {
            this.minValueforYAxis = item.Statistic.LowLimit + (item.Statistic.LowLimit * 2) / 100;
          }
        }
        if (param) {
          this.maxValueforYAxis = axisExtremes.max;
          this.minValueforYAxis = axisExtremes.min;
        }

        const axisData = {
          tickPositions: (logscale || (axisExtremes.min === axisExtremes.max)) ? undefined : [axisExtremes.min, ...this.midPositions(axisExtremes.max, axisExtremes.min), axisExtremes.max],
          gridLineWidth: 0,
          title: {
            // eslint-disable-next-line max-len
            text: '<span title="' + item.MeasureName + '" style="background: white; display: block;width:230px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;text-align: center;"><span style="font-size: 10px;margin-right: 6px;color:' + item.color + '">\u25A0</span>' + item.MeasureName + '</span>',
            title: item.MeasureName,
            margin: 35, // Here
            useHTML: true,
            style: {
              color: '#616365',
              fontFamily: '\'Interstate-Regular\',\'sans-serif\''
            }
          },
          min: (logscale) ? axisExtremes.min : Math.min(axisExtremes.min, (this.minValueforYAxis)),
          max: Math.max(axisExtremes.max, (this.maxValueforYAxis)),
          top: this.setyAxisTop(index),
          height: this.setyAxisHeight(index),
          opposite: this.stackedChart === false ? item.isOpposite : (index === 1 || index === 3 || index === 5) ? true : false,
          offset: this.stackedChart === false ? this.setAxisOffset(item, index) : 0,
          lineWidth: this.stackedChart === false ? 0 : 2,
          showFirstLabel: false,
          showLastLabel: true,
          parameterInstaneId: item.ParameterInstanceId
        };
        axisData.gridLineWidth = 0;
          if (logscale) {
            axisData['type'] = 'logarithmic';
            axisData['min'] = (axisData.min <= 0) ? this.logScale_low_Value : axisData.min;
            axisData['max'] = (axisData.max <= 0) ? this.logScale_low_Value : axisData.max;
            axisData['labels'] = {
              formatter: function () {
                return parseFloat(Number(this.value).toFixed(2));
              },
              align: this.stackedChart === false ? (item.isOpposite === true) ? 'right' : 'left' : (index === 1 || index === 3 || index === 5) ? 'left' : 'right',
              y: 10
            };
          } else {
            axisData['labels'] = {
              formatter: function () {
               return parseFloat(Number(this.value).toFixed(2));
              },
              align: this.stackedChart === false ? (item.isOpposite === true) ? 'right' : 'left' : (index === 1 || index === 3 || index === 5) ? 'left' : 'right',
              y: 10
            };
          }
        //}
        // to show a single data point in chart
        if (points.length == 1) {
          points.forEach((p, i, point) => {
            if (p[2] == null) {
              point[i] = {
                y: p[1],
                name: 'one data',
                x: p[0],
                marker: {
                  radius: 3,
                  enabled: true
                }
              }
            }
          })
        }

        // for LOD
        let tooltipSymbols = [];
        let lodDataPoints = []; // array to hold normal and lod points
        yAxis.push(axisData);

        if (notInRange) {
          points = [];
        }
        series.push({
          name: item.MeasureName,
          type: 'spline',
          data: (isDiscrete) ? discretePoints : points,
          color: item.color,
          yAxis: yAxisIndex,
          min: axisExtremes.min,
          max: axisExtremes.max,
          isDiscrete: isDiscrete,
          discreteValues: discreteValues,
          axisTitle: item.MeasureName,
          months: this.FullMonthnames,
          tooltip: {
            valuePrefix: tooltipSymbols
          }
        });

        yAxisIndex++;
      } else {
        const axisData = {
          visible: false
        };
        yAxis.push(axisData);
        yAxisIndex++;
      }
    });
    this.options.series = [];
    this.options.series = series;
    let counter = 0;
    series.forEach(s => {
      if (s.data.length > 0) {
        counter++;
      }
    });
    if (counter) {
      this.noDataError = false;
    } else if (notInRangeCount) {
      this.noDataError = false;
    } else {
      this.noDataError = true;
    }
    this.options.yAxis = [];
    this.options.yAxis = yAxis;

    highstock.setOptions({
      global: {
        useUTC: false
      },
      lang: {
        months: this.dateLocaleService.getLocaleMonths(),
        shortMonths: this.dateLocaleService.getShortLocaleMonths(),
        weekdays: this.dateLocaleService.getLocaleDays()
      }
    });
    const fullScreenOpen = JSON.parse(sessionStorage.getItem('TrendIsopenFullScreen'));
    if(document.getElementById('chart-System-health')){
      this.chart = highstock.stockChart('chart-System-health', this.options);
    }
    window.dispatchEvent(new Event('resize'));
    this.chart.update({navigator: { enabled: false }});
    this.chart.update({scrollbar: { enabled: false }});

    if (fullScreenOpen) {
      this.chart.reflow();
    } else {
      setTimeout(() => {
        this.chart.reflow();
      }, 500);
    }

    const highchartsAxisTitle = document.getElementsByClassName('highcharts-axis-title');
    if (highchartsAxisTitle.length > 0) {
      for (var i = 1; i < highchartsAxisTitle.length; i++) {
        (highchartsAxisTitle[i] as HTMLElement).style.transformOrigin = (highchartsAxisTitle[i] as HTMLElement).style.transform === 'rotate(90deg)' ? '48%' : '53%';
      }
    }
    this.durationData.startdate=this.StartOn;
    this.durationData.enddate=this.EndBefore;
    if (this.durationData) {

      const isIE = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
      if (!isIE) {
        const dateRange = this.durationData;
        const min = new Date(dateRange.startdate + ' ' + dateRange.starttime).getTime();
        const max = new Date(dateRange.enddate + ' ' + dateRange.endtime).getTime();
        this.chart.xAxis[0].setExtremes(min, max);
      } else {
        const dateRange = this.durationData;

        const newstDt = moment(dateRange.startdate, 'DD-MMM-YYYY');
        const startDateFormat = moment(newstDt).format('MM/DD/YYYY');

        const newedDt = moment(dateRange.enddate, 'DD-MMM-YYYY');
        const endDateFormat = moment(newedDt).format('MM/DD/YYYY');

        const min = new Date(startDateFormat + ' ' + dateRange.starttime).getTime();
        const max = new Date(endDateFormat + ' ' + dateRange.endtime).getTime();
        this.chart.xAxis[0].setExtremes(min, max);
      }
    }
  }

  setyAxisTop(index) {
    if (this.stackedChart) {
      if (this.chartData.length > 4) {
        if (index === 0 || index === 1) {
          return '0%';
        } else if (index === 2 || index === 3) {
          return '33%';
        } else {
          return '66%';
        }
      } else if (this.chartData.length <= 4 && this.chartData.length > 2) {
        if (index === 0 || index === 1) {
          return '0%';
        } else {
          return '50%';
        }
      } else {
        return '0%';
      }
    }
    else {
      if (this.chartData.length <= 12) {
        return '0%';
      }
    }
  }

  setyAxisHeight(index) {
    if (this.stackedChart) {
      return (this.chartData.length > 4) ? '33%' : ((this.chartData.length <= 4 && this.chartData.length > 2) ? '50%' : '100%');
    }
    else {
      return ((this.chartData.length <= 12) ? '100%' : '70%');
    }
  }
  setAxisOffset(item, index) {
    if (item.isOpposite) {
      const rightCount = this.rightOffsetCount;
      this.rightOffsetCount = this.rightOffsetCount + 50;
      return rightCount;
    } else if (!item.isOpposite) {
      const leftCount = this.leftOffsetCount;
      this.leftOffsetCount = this.leftOffsetCount + 50;
      return leftCount;
    }
  }
  midPositions = (max, min)=> {
    const positions = [];
    let tick = Math.floor(min);
    const increment = Math.ceil((max - min) / 5);

    if (max !== null && min !== null) {
      for (tick; tick - increment < max; tick += increment) {
        positions.push(tick);
      }
    }
    return positions;
  }
  getMinMax(asfTrend, min, max) {

    const Minimum = min;
    const Maximum = max;

    const SignMin = Minimum > 0 ? 1 : (Minimum < 0 ? -1 : 0);
    const SignMax = Maximum > 0 ? 1 : (Maximum < 0 ? -1 : 0);

    let AxisLow = Minimum * (1 - (asfTrend * SignMin));
    let AxisHigh = Maximum * (1 + (asfTrend * SignMax));

    if (AxisLow < 0 && Minimum >= 0 && Maximum >= 0) {
        AxisLow = 0;
    }

    if (AxisHigh > 0 && Minimum < 0 && Maximum < 0) {
        AxisHigh = 0;
    }

    if (AxisLow > 0 && ((AxisLow) / (AxisHigh - AxisLow) <= 0.15)) {
        AxisLow = 0;
    }

    const Largest = Math.max(Math.abs(AxisLow), Math.abs(AxisHigh));

    if (Largest >= 3200) {
        AxisLow = Math.round(AxisLow / 1000) * 1000;
        AxisHigh = Math.round(AxisHigh / 1000) * 1000;
    } else if (Largest >= 320 && Largest < 3200) {
        AxisLow = Math.round(AxisLow / 100) * 100;
        AxisHigh = Math.round(AxisHigh / 100) * 100;
    } else if (Largest >= 32 && Largest < 320) {
        AxisLow = Math.round(AxisLow / 10) * 10;
        AxisHigh = Math.round(AxisHigh / 10) * 10;
    } else if (Largest >= 3.2 && Largest < 32) {
        AxisLow = Math.round(AxisLow / 1) * 1;
        AxisHigh = Math.round(AxisHigh / 1) * 1;
    } else if (Largest >= 0.32 && Largest < 3.2) {
        AxisLow = Math.round(AxisLow / 0.1) * 0.1;
        AxisHigh = Math.round(AxisHigh / 0.1) * 0.1;
    } else {
        AxisLow = Math.round(AxisLow / 0.01) * 0.01;
        AxisHigh = Math.round(AxisHigh / 0.01) * 0.01;
    }
    return { min: AxisLow, max: AxisHigh };
}

updateDates(event: DurationOption) {
  if (event && event.IsValid) {
    this.StartOn = this.datePipe.transform(event.StartDate, "dd-MMM-yyyy");
    this.EndBefore = this.datePipe.transform(event.EndDate, "dd-MMM-yyyy");
  }
}
close() {
  this.popUpWindow.close();
}
applyDateFilter(){
  this.popUpWindow.close();
  let startDate = moment(this.StartOn , 'DD-MMM-YYYY HH:mm:ss');
  let endDate = moment(this.EndBefore, 'DD-MMM-YYYY HH:mm:ss');
  this.duration = (endDate.diff(startDate, 'days'));
  this.getMeasurementsData();
}
}