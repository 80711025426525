/* eslint-disable @typescript-eslint/consistent-type-imports */
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DividerOption } from './../divider/divider.model'
import { User } from './../../shared-main/models/User';

@Component({
  selector: 'nalco-user-profile-details',
  templateUrl: './user-profile-details.component.html',
  styleUrls: ['./user-profile-details.component.scss']
})
export class UserProfileDetailsComponent implements OnInit {

  @Input() user : User;

  // To identity whether this component is displayed in home page or not

  @Input() isHomePage? : boolean;

  initials: string;

  dividerCss : DividerOption

  constructor(private router : Router) {

  }

  ngOnInit() {
    // for the divider css
    this.dividerCss = {
      color: "#D9EAF9",
      maxLength: "auto"
    }
   // Assign the initials to the this.initials
    this.initials = (this.user?.FirstName && this.user?.LastName) ? this.getInitials(this.user.FirstName) + this.getInitials(this.user.LastName) : 'XX'
  }

  /**
   * @description This function is used to get the initials from the name of the user
   * @param name 
   * @returns initials from the name 
   */

  getInitials(name:string) {
    const parts = name.split(' ')
    let initials = ''
    for (let i = 0; i < 1; i++) {
      if (parts[i].length > 0 && parts[i] !== '') {
        initials += parts[i][0]
      }
    }
    return initials
  }



  logout() {
    this.router.navigate(['/logout']);
  }

}

