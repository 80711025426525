<nalco-left-menu>

  <li
    class="nav-item slds-nav-vertical__item nav-item-container cPRTL_SideNav_MenuItem_TopLevel Home d-sm-block d-md-none"
    id="mobilehome">
    <a class="slds-nav-vertical__action nav-link text-center" placement="right" routerLinkactive="active"
      routerLink="/home" placement="right">
      <span class="slds-icon_container slds-line-height_reset icon-icon_home"></span>
      <span class="menu-text d-sm-block d-md-none d-lg-none">{{'LEFT_NAV.HOME_NAV_LABEL' | translate}}</span>
    </a>
  </li>

  <nalco-left-menu-item *ngFor="let leftnav of leftNavigationItems" [id]="leftnav.hover" [heading]="leftnav.label"
    [text]="leftnav.text" [tooltip]="leftnav.hover" [icon]="leftnav.icon" [link]="leftnav.link" [href]="leftnav.href"
    (menuItemClick)="menuItemClicked($event)" [subMenu]="leftnav.subNavigation">
  </nalco-left-menu-item>
  <nalco-left-menu-item [id]="'desktoptoggle'" [text]="'LEFT_NAV.DESKTOP_NAV_LABEL'" [icon]="'ico-nav-dashboard'" [link]="'/feedback'"
    [itemContainerClass]="'desktoptoggle'" [tooltip]="'DESKTOPVIEW'" (click)="desktopToggle()"
    *ngIf='navigationMenu?.leftNavigation'></nalco-left-menu-item>

  <!-- <li class="nav-item slds-nav-vertical__item nav-item-container cPRTL_SideNav_MenuItem_TopLevel">
    <a class="slds-nav-vertical__action nav-link text-center" placement="right" routerLinkactive="active"
      [ngbTooltip]="supportContent"
      href="mailto:ecolabcds@service-now.com?subject=New%20Ecolab 3D%20–%20Nalco%20Water%20–%20Support&body=Please%20mention%20which%20service%20you%20are%20using.%20(i.e.%20Water%20Quality%20Intelligence,%20Water%20Safety%20Intelligence,%20OMNI,%20My%20Documents,%20etc.)%20Please%20give%20a%20brief%20description%20of%20what%20you%20need%20help%20with."
      placement="right">
      <span class="slds-icon_container slds-line-height_reset ico-nav-help"></span>
      <span class="menu-text d-sm-block d-md-none d-lg-none">{{'LEFT_NAV.SUPPORT_NAV_LABEL' | translate}}</span>
    </a>
  </li> -->
  <!--<nalco-left-menu-item id="feedback" text="feedback" icon="ico-nav-user-voice" href="mailto:ecolabcds@service-now.com?subject=Ecolab%203D%20–%20Nalco%20Water%20–%20Feedback" [itemContainerClass]="'feedback'"
    tooltip="Feedback" (menuItemClick)="menuItemClicked($event)" *ngIf='navigationMenu?.leftNavigation' target="_top"></nalco-left-menu-item>-->
  <!-- <li class="nav-item slds-nav-vertical__item nav-item-container cPRTL_SideNav_MenuItem_TopLevel Feedback"  id="feedback">
      <a class="slds-nav-vertical__action nav-link text-center" placement="right" routerLinkactive="active" [ngbTooltip]="feedbackContent"  placement="right" [ngbTooltip]="tooltipContent">
        <span class="slds-icon_container slds-line-height_reset ico-nav-user-voice"></span>
        <span class="menu-text d-sm-block d-md-none d-lg-none">{{'LEFT_NAV.FEEDBACK_NAV_LABEL' | translate}}</span>
      </a>
    </li> -->

  <ng-template #supportContent>
    <p>{{'LEFT_NAV.SETTINGS_TOOLTIP' | translate}}</p>
  </ng-template>

  <ng-template #feedbackContent>
    <p>{{'LEFT_NAV.SETTINGS_TOOLTIP' | translate}}</p>
  </ng-template>

</nalco-left-menu>

<div class="side-navigation-container">
  <i class="ic_Q-settings-1"></i>
  <i class="ic_L-help-1"></i>
  <i class="ic_R-user-voice-1"></i>
</div>
