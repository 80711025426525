import { Component, Input, Output, EventEmitter } from '@angular/core';

import { ISubheaderLeftSection } from '../sub-header.modal';

@Component({
    selector: 'nalco-subheader-left-section',
    templateUrl: './left-section.component.html'
})
export class SubHeaderLeftSectionComponent {
    @Input() details: ISubheaderLeftSection;
    @Output() btnClick= new EventEmitter<string>();

    btnClickInSubhead(which: string) {
        this.btnClick.emit(which);
    }
}
