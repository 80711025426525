import { IAdditionalData, IMeasurement, IMeasurementInstanceLite, IMeasurementValue, ISaveReading } from "../_contracts/ISaveReading";
import { IDataCaptureDimensionalSubUnit } from '../_contracts/IConfig'

export class SaveReading implements ISaveReading {
    MeasurementValue: MeasurementValue;
    MeasurementInstanceLite: MeasurementInstanceLite;
    DataCaptureDimensionalSubUnit: DataCaptureDimensionalSubUnit;
    constructor(saveReading?: SaveReading) {
        if (saveReading) {
            this.MeasurementValue = saveReading.MeasurementValue;
            this.MeasurementInstanceLite = saveReading.MeasurementInstanceLite;
            this.DataCaptureDimensionalSubUnit = saveReading.DataCaptureDimensionalSubUnit;
        } else {
            this.MeasurementValue = new MeasurementValue();
            this.MeasurementInstanceLite = new MeasurementInstanceLite();
            this.DataCaptureDimensionalSubUnit = new DataCaptureDimensionalSubUnit();
        }
    }
}

export class MeasurementValue implements IMeasurementValue {
    AdditionalData: AdditionalData;
    ValueText: string = '';
    i: string = '';
    v = 0;
    constructor(measurementValue?: MeasurementValue) {
        if (measurementValue) {
            this.ValueText = measurementValue.ValueText;
            this.i = measurementValue.i;
            this.v = measurementValue.v;
            this.AdditionalData = measurementValue.AdditionalData;
        } else {
            this.AdditionalData = new AdditionalData();
            this.ValueText = '';
            this.i = '';
            this.v = 0;
        }
    }
}

export class AdditionalData implements IAdditionalData {
    ReadingSourceApplication: "MdeModuleOnline";
    constructor(additionalData?: AdditionalData) {
            this.ReadingSourceApplication = "MdeModuleOnline";
    }
}

export class MeasurementInstanceLite implements IMeasurementInstanceLite {
    Measurement: Measurement;
    MeasurementInstanceId: number = 0;
    ContextPointId: number = 0;
    OriginType: any;
    SourceApplication: any;
    constructor(measurementInstanceLite?: MeasurementInstanceLite) {
        if (measurementInstanceLite) {
            this.Measurement = measurementInstanceLite.Measurement;
            this.MeasurementInstanceId = measurementInstanceLite.MeasurementInstanceId;
            this.ContextPointId = measurementInstanceLite.ContextPointId;
            this.OriginType = measurementInstanceLite.OriginType;
            this.SourceApplication = measurementInstanceLite.SourceApplication
        } else {
            this.Measurement = new Measurement();
            this.MeasurementInstanceId = 0;
            this.ContextPointId = 0;
            this.OriginType = '';
            this.SourceApplication = '';
        }
    }
}

export class Measurement implements IMeasurement {
    NalcoNumericsUnitOrSpecies: string = '';
    OptionGroupId: number;
    constructor(measurement?: Measurement) {
        if (measurement) {
            this.NalcoNumericsUnitOrSpecies = measurement.NalcoNumericsUnitOrSpecies;
            this.OptionGroupId = measurement.OptionGroupId;
        } else {
            this.NalcoNumericsUnitOrSpecies = '';
            this.OptionGroupId = 0;
        }
    }
}

export class DataCaptureDimensionalSubUnit implements IDataCaptureDimensionalSubUnit {
    UnitName: string;
    SignificantDigits: number;
    Symbol: string;
    IsBaseUnit: boolean;
    constructor(subUnit?: DataCaptureDimensionalSubUnit) {
        if (subUnit) {
            this.UnitName = subUnit.UnitName;
            this.SignificantDigits = subUnit.SignificantDigits;
            this.Symbol = subUnit.Symbol;
            this.IsBaseUnit = subUnit.IsBaseUnit;
        }
        else{
            this.UnitName = '';
            this.SignificantDigits = 0;
            this.Symbol = '';
            this.IsBaseUnit = false;
        }
    }

}