import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren, ViewEncapsulation, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbDropdown, NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { SimpleTimer } from 'ng2-simple-timer';
import { BehaviorSubject, Observable, fromEvent } from 'rxjs';
import { LocalCacheService } from '../../../core/local-cache/local-cache.service';
import { TextCategorySearchPipe } from '../../pipes/text-category-search.pipe';
import { filter } from 'rxjs/operators';

const KEY_CODE = {
  enter: 13,
  arrowUp: 38,
  arrowDown: 40,
  esc: 27,
};

const CSS_CLASS_NAMES = {
  highLight: 'dd-highlight-item',
};

@Component({
  selector: 'nalco-select-dropdown-integrated',
  templateUrl: './select-dropdown-integrated.component.html',
  styleUrls: ['./select-dropdown-integrated.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    NgbDropdownConfig,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectDropdownComponentIntegrated),
      multi: true
    }
  ]
})
export class SelectDropdownComponentIntegrated implements ControlValueAccessor, OnInit, OnChanges {
  @ViewChild(NgbDropdown, { static: false })
  private dropdown: NgbDropdown;

  @ViewChild('filterInput', { static: false }) filterInput: ElementRef;
  @ViewChild('displayLabel', { static: false }) displayLabel: ElementRef;
  @ViewChildren('listItems') listItems: QueryList<ElementRef>;
  @ViewChild('checkBox', { static: false }) checkBox: ElementRef;
  @ViewChild('scroll', { static: false }) public scroll: ElementRef;


  _items = [];
  selectedItems: Array<{ ContextPointId: number, ContextPointName: string, checked: boolean, category: string, disable?: boolean }>;

  _list = new BehaviorSubject<any[]>([]);
  @Input() placeholder = this.translate.instant('COMMON.SELECT');
  @Input() SearchType? = 'list';
  @Input() setSelectedItems? = [];
  @Output() itemSelected: EventEmitter<any> = new EventEmitter(); // Emits the toggle event of the button
  @Output() categorySelected: EventEmitter<any> = new EventEmitter();
  @Output() selectedItemList: EventEmitter<any> = new EventEmitter();
  @Output() lazyLoading: EventEmitter<any> = new EventEmitter();
  @Output() filterText: EventEmitter<any> = new EventEmitter();
  @Output() getSelectedItems: EventEmitter<any> = new EventEmitter();
  @Output() itemUnchecked?: EventEmitter<any> = new EventEmitter();
  @Output() toggleEvent?: EventEmitter<any> = new EventEmitter();
  @Output() unSelectAll?: EventEmitter<any> = new EventEmitter();
  @Input() isDisable? = false;
  @Input() displayOnInit = [];
  @Input() noRecoredsLabel = this.translate.instant('COMMON.NO_RECORDS_FOUND');
  @Input() noMatchingRecoredsLabel = this.translate.instant('COMMON.NO_RECORDS_FOUND');
  @Input() noMatchingCategory = this.translate.instant('COMMON.NO_RECORDS_FOUND');
  @Input() showLoader? = false;
  @Input() showSelectLabel? = true;
  @Input() multiSelection? = false;
  @Input() categoryList;
  @Input() depValue = false;
  @Input() dependentValue = false;
  @Input() showUnmapped? = true;
  @Input() viewFavorite = false;
  @Input() showSearchBox?: boolean = true;
  @Input() showFilterBycategory?: boolean = true;
  @Input() checkedItemsLimit?: number = 4;
  @Input() showClearSelectionFlag?: boolean = true;
  @Input() addEditMode?: string;
  @Input() isSelectAllAvailable?: boolean;
  @Input() isSelectAllSelected?: boolean;
  @Input() serviceEnabledName?: string = '';
  @Input() multiselectText?: string = 'Select All';
  @Input() virtualMeterFlag?: boolean = false;
  @Input() disableDd?: boolean = false;
  @Input() isAHInventory?: boolean = false;
  @Input() isWaterUsage?: boolean = false;
  @Input() isAssetDetails?: boolean = false;
  @Input() isOmniSurveysTab?: boolean = false;
  @Input() headerValue?: string;
  @Input() scrolltoTop?: boolean = true;
  @Input() isfromMyDocuments?: boolean;
  multiselectedItems = [];
  selectionLimitOverMsg = false;
  disableRecordsFlag = false;
  errorMsgTimer: any;
  categoryOptions: Array<any> = [];
  //  selectedCategory = {value:'', text:'', checked:false};
  selectedCount = 0;
  _value: string;
  _display: string = this.translate.instant('COMMON.SELECT');

  isListHide = true;
  splicedItems = [];
  searchText = '';
  selectedCategory = '';
  newTimer: any;
  timerId: any;
  counter: number = 0;
  disableClearSelection: boolean = true;
  allOption: boolean = true;
  keyDowns: Observable<KeyboardEvent> = fromEvent(this.elemRef.nativeElement, 'keydown');
  _enableSorting: boolean = false;

  pressEnterKey: Observable<KeyboardEvent>;
  @HostListener('document:click', ['$event'])
  onClick(ev: MouseEvent) {
    const clickInside = this.elemRef.nativeElement.contains(ev.target);
    if (!clickInside) {
      this.multiSelection ? this.toggle() : '';
      this.isListHide = true;
      if (this.scrolltoTop === undefined || this.scrolltoTop === true) {
        this.scroll.nativeElement.scrollTop = 0;
      }
    }
  }
  onChange: any = () => { };
  onTouched: any = () => { };
  @Input()
  set list(list) {
    this._list.next(list);
  }

  set items(list) {
    this._items = list;
  }
  get items(): Array<{ id: number, display: string, checked: boolean, category: string, isMapped: boolean, disable?: boolean }> {
    return this._items;
  }
  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
  }

  get display() {
    return this._display;
  }
  @Input()
  set display(value) {
    this._display = value;
  }

  textCategorySearchPipe = new TextCategorySearchPipe();

  constructor(private elemRef: ElementRef, private translate: TranslateService,
    private st: SimpleTimer, private localCacheService: LocalCacheService, config: NgbDropdownConfig) {
    this.pressEnterKey = this.keyDowns.pipe(filter((e: KeyboardEvent) => e.keyCode === KEY_CODE.enter));
    config.placement = 'bottom';
    config.autoClose = false;
    this.counter = 0;
  }

  ngOnInit() {
    this._list.subscribe((list) => {
      this.items = list;
      //   this.setItem(this.findItem(this.value));
    });

    if (!this.isAssetDetails && this.items.length == 1) {
      this.allOption = false;
    }

    this.pressEnterKey.pipe(filter(() => !this.isListHide)).subscribe(() => {
      const hightLightItem = this.listItems.find((elem) => elem.nativeElement.classList.contains(CSS_CLASS_NAMES.highLight));
      if (hightLightItem) {
        const item = JSON.parse(hightLightItem.nativeElement.getAttribute('data-dd-value'));
        this.setItem(item);
        this.onChange(item.id);
      }
    });

    this.pressEnterKey.subscribe((e) => {
      this.toggle();
    });

    this.keyDowns.pipe(filter((e) => e.keyCode === KEY_CODE.esc)).subscribe(() => {
      this.isListHide = true;
      this.focus();
    });
    this.keyDowns.pipe(filter((e) => ((e.keyCode === KEY_CODE.arrowDown || e.keyCode === KEY_CODE.arrowUp) && !this.isListHide))).subscribe((e) => {
      this.moveUpAndDown(e.keyCode);
    });

    if (this.multiSelection) {
      this.setMultiselection();
    } else {
      if (this.displayOnInit.length > 0) {
        this.setItem(this.displayOnInit[0]);
      }
    }
    this.filterCategoryList();
  }

  setMultiselection() {
    if (this.isOmniSurveysTab && (this.displayOnInit.length == this.items.length) && this.multiSelection) {
      this.display = this.translate.instant("COMMON.ALL");
    } else if (this.displayOnInit.length === 1) {
      this.setItem(this.displayOnInit[0]);

    } else if (this.displayOnInit.length > 1) {
      this.value = null;
      if (this.multiselectedItems.length == this.items.length && this.multiSelection) {
        this.display = this.translate.instant("COMMON.ALL");
      }
      else if (this.isAssetDetails && this.displayOnInit.length == this.items.length && this.multiSelection) {
        this.display = this.translate.instant("COMMON.ALL");
      } else {
        this.display = this.multiSelection ? this.translate.instant("COMMON.MULTIPLE_SELECTION") :
          this.translate.instant("COMMON.MULTIPLE_SELECTED");
      }

    } else {
      this.value = '';
      this.display = this.isWaterUsage ? this.translate.instant('COMMON.SELECT_PORT_MEASUREMENT') : this.translate.instant('COMMON.SELECT');
      // this.display = this.translate.instant('COMMON.SELECT');
    }
    this.multiselectedItems = this.displayOnInit;
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.depValue || changes.dependentValue) {
      if (this.depValue || this.dependentValue) {
        this.setItem('');
        this.value = '';
        this.display = this.isWaterUsage ? this.translate.instant('COMMON.SELECT_PORT_MEASUREMENT') : this.translate.instant('COMMON.SELECT');
        this.multiselectedItems = [];
      }
    }
    if (changes.showUnmapped || (changes.viewFavorite && this.viewFavorite)) {
      if (this.multiSelection) {
        this.setMultiselection();
      }
    }

    if (this.displayOnInit.length > 0) {
      this.setMultiselection();
    } else {
      // this.display = this.translate.instant('COMMON.SELECT');
      this.display = this.isWaterUsage ? this.translate.instant('COMMON.SELECT_PORT_MEASUREMENT') : this.translate.instant('COMMON.SELECT');
    }
    // if (this.multiSelection) {
    //   this.setMultiselection();
    // } else {
    //   if (this.displayOnInit.length > 0) {
    //     this.setItem(this.displayOnInit[0]);
    //   }
    // }
    if (changes.isSelectAllSelected) {
      this.onSelectAll({ target: { checked: changes.isSelectAllSelected.currentValue } });
    }

  }

  filterCategoryList() {
    this.categoryList = [];
    let id = 1;
    this.items.forEach(item => {
      let str = item.display.split('|');
      let categoryItem = str[0].trim();
      let mappedInfo = str[str.length - 1].trim();
      if (!this.showUnmapped && mappedInfo !== '(unmapped)') {
        if (this.categoryList.findIndex(x => x.text === categoryItem) === -1) {
          this.categoryList.push({
            value: id,
            text: categoryItem
          });
          id++;
        }
      } else if (this.showUnmapped) {
        if (this.categoryList.findIndex(x => x.text === categoryItem) === -1) {
          this.categoryList.push({
            value: id,
            text: categoryItem
          });
          id++;
        }
      }
    });
    this.categoryList.sort(function (a, b) {
      const x = a.text.toUpperCase();
      const y = b.text.toUpperCase();
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }

  onCategoryChange(category) {
    let checkedFlag = false;
    // this.selectedCount = 0;
    // this.selectionLimitOverMsg = false;
    // this.value = '';
    // this.display = 'Select';
    // this.disableRecordsFlag = false;
    // this.multiselectedItems = [];
    // this.items.forEach(item => {
    //   item.checked = false;
    // });
    // this.setItem('');
    for (let i = 0; i < this.categoryList.length; i++) {
      if (this.categoryList[i].text == category.text) {
        if (category.checked == false) {
          this.categoryList[i].checked = true;
          // this.categorySelected.emit(category);
          checkedFlag = true;
          this.selectedCategory = this.categoryList[i].text;
        } else {
          this.categoryList[i].checked = false;
        }
      } else {
        this.categoryList[i].checked = false;
      }
    }

    if (checkedFlag == false) {
      // this.categorySelected.emit({value:'', text:'', checked:false});
      this.selectedCategory = '';
    }
  }

  onKeyUp(searchText) {
    this.filterText.emit(searchText);
  }

  scrollToView(elem?: HTMLElement) {
    if (elem) {
      setTimeout(() => elem.scrollIntoView(), 0)
    } else {
      const selectedItem = this.listItems.find((item) => JSON.parse(item.nativeElement.getAttribute('data-dd-value'))['id'] === this.value);
      if (selectedItem) {
        setTimeout(() => selectedItem.nativeElement.scrollIntoView(), 0);
      }
    }
  }

  toggle() {
    if (this.showFilterBycategory) {
      this.dropdown.close();
    }
    this.isListHide = !this.isListHide;
    if (!this.isListHide) {
      if (this.selectedCategory !== '') {
        this.selectedCategory = '';
        this.categoryList.forEach(item => {
          item.checked = false;
        });
      }

      if (this.searchText !== '') {
        this.searchText = '';

        if (this.SearchType === 'server') {
          this.filterText.emit('');
        }
      } else {
        if (this.SearchType === 'server') {
          this.getSelectedItems.emit();
        }
      }
      if (this.SearchType === 'list') {
        this.getSelectedItems.emit();
      }
      if (this.showSearchBox) {
        setTimeout(() => this.filterInput.nativeElement.focus(), 0);
      }

      this.selectionLimitOverMsg = false;
      this.disableRecordsFlag = false;
      this.listItems.forEach((item) => {
        if (JSON.parse(item.nativeElement.getAttribute('data-dd-value'))['id'] === this.value) {
          this.addHightLightClass(item.nativeElement);
          if (!this.isfromMyDocuments) {
            if (this.scrolltoTop === undefined || this.scrolltoTop === true) {
              this.scrollToView(item.nativeElement);
            }
          }
        } else {
          this.removeHightLightClass(item.nativeElement);
        }
      });
      this.multiselectedItems = [];
      this.selectedCount = 0;
      this.items.forEach(item => {
        if (item.checked) {
          this.selectedCount++;
          this.multiselectedItems.push(item);
          if (!this.multiSelection) {
            this.setItem(item);
          }
        }
      });
      if (this.selectedCount === 0) {
        if (this.displayOnInit.length > 0) {

          if (!this.multiSelection) {
            this.selectedCount++;
            this.setItem(this.displayOnInit[0]);
          } else {
            if (this.displayOnInit.length > 0) {
              this.displayOnInit.forEach(initElement => {
                this.items.forEach(element => {
                  if (initElement.display === element.display) {
                    element.checked = true;
                    this.selectedCount++;
                    this.multiselectedItems.push(initElement);
                  }
                });
              });
            }
          }
        }
      }
    } else {
      this.st.delTimer('1Sec');
      this.st.unsubscribe('1Sec');
      this.counter = 0;
      this.selectionLimitOverMsg = false;
    }
    if (this.multiSelection) {
      this.getSelectedItems.emit(this.multiselectedItems);
    }
  }

  focus() {
    setTimeout(() => this.displayLabel.nativeElement.focus(), 0);
  }

  sortByKey(array, key) {
    return array.sort(function (a, b) {
      const x = a[key].toUpperCase();
      const y = b[key].toUpperCase();
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }

  onItemSelect(item, event) {

    if (event.target.checked && !this.multiSelection) {
      this.selectedCount = 0;
      this.items.forEach(element => {
        if (element.id === parseInt(this.value, 10)) {
          element.checked = false;
        }
      });
    }
    if (event.target.checked) {
      this.selectedCount++;

      if (item !== undefined) {
        this.onChange(item.id);
      } else {
        this.onChange('');
      }
      this.focus();
      if (this.multiSelection) {

        this.items.forEach(element => {
          if (element.id === item.id) {
            element.checked = true;
          }
        });
        let index = this.multiselectedItems.findIndex(x => x.display === item.display);
        // To validate few more conditions along with 'x.display === item.display' for AH Data Source Measuremnts
        let indexForAh = !this.multiSelection ? this.multiselectedItems.findIndex(x =>
          x.display === item.display
          && x.id.FromMeasuremntInstanceId === item.id.FromMeasuremntInstanceId
          && x.id.ToMeasuremntInstanceId === item.id.ToMeasuremntInstanceId
          && x.id.FromMeasurementInstance.ContextPointId === item.id.FromMeasurementInstance.ContextPointId
          && x.id.ToMeasurementInstance.ContextPointId === item.id.ToMeasurementInstance.ContextPointId
        ) : '';

        if (index === -1 || indexForAh === -1) {
          this.multiselectedItems.push(item);
        }

        if (!this.isSelectAllAvailable)
          this.isSelectAllSelected = false;

        if (!this.isSelectAllSelected) {
          this.itemSelected.emit(this.multiselectedItems);
          this.displayOnInit = this.multiselectedItems;
        }
      } else {
        this.setItem(item);
        this.itemSelected.emit(item.id);
        this.multiselectedItems = [];
        this.items.forEach(element => {
          if (element.id === parseInt(this.value, 10)) {
            element.checked = true;
          } else {
            element.checked = false;
          }
        });
      }

      // this.multiselectedItems.push(item);
    } else {
      this.selectedCount--;
      if (this.multiSelection) {
        this.items.forEach(element => {
          if (element.id === item.id) {
            element.checked = false;
          }
        });
        let index = this.multiselectedItems.findIndex(x => x.display === item.display);
        if (index > -1) {
          this.multiselectedItems.splice(index, 1);
        }
        this.itemSelected.emit(this.multiselectedItems);
        this.itemUnchecked.emit(item);
        this.displayOnInit = this.multiselectedItems;
      } else {
        this.items.forEach(element => {
          //    if (element.id === parseInt(this.value, 10)) {
          element.checked = false;
          //    }
        });
        this.setItem('');
        this.itemSelected.emit();
        this.multiselectedItems = [];
      }
      // this.itemSelected.emit();
    }

    if (this.multiSelection) {

      if (this.multiselectedItems.length === 1) {
        this.value = this.multiselectedItems[0].id;
        this.display = this.multiselectedItems[0].display;
        this.setItem(this.multiselectedItems[0]);

      } else if (this.multiselectedItems.length > 1) {
        this.value = null;
        if (this.isOmniSurveysTab && this.multiselectedItems.length == this.items.length) {
          let isChecked = 0;
          this.multiselectedItems.forEach(element => {
            if (element.checked) {
              isChecked++;
            }
          })
          if (isChecked === this.items.length) {
            this.display = this.translate.instant("COMMON.ALL");
          } else if (isChecked === 1) {
            this.multiselectedItems.forEach(element => {
              if (element.checked) {
                this.display = element.display;
              }
            });
          } else {
            this.display = this.translate.instant("OMNI.SURVEYS.SELECTED_VALUES", { value: isChecked });
          }
        } else if (this.multiselectedItems.length == this.items.length) {

          this.display = this.translate.instant("COMMON.ALL");
        } else if (this.isOmniSurveysTab && this.multiselectedItems.length < this.items.length) {
          this.display = this.translate.instant("OMNI.SURVEYS.SELECTED_VALUES", { value: this.multiselectedItems.length });
        } else {
          this.display = this.multiSelection ? this.translate.instant("COMMON.MULTIPLE_SELECTION") :
            this.translate.instant("COMMON.MULTIPLE_SELECTED");
        }

      } else {
        this.value = '';
        this.display = this.isWaterUsage ? this.translate.instant('COMMON.SELECT_PORT_MEASUREMENT') : this.translate.instant('COMMON.SELECT');
        // this.display = this.translate.instant('COMMON.SELECT');
        this.disableClearSelection = true;
      }
      // this.getSelectedItems.emit();
      if (this.multiselectedItems.length > this.checkedItemsLimit) {
        this.items.forEach(element => {
          if (element.id === parseInt(item.id, 10)) {
            element.checked = false;
          }
        });

      } else {
        this.st.delTimer('1Sec');
        this.st.unsubscribe('1Sec');
        this.counter = 0;
        this.selectionLimitOverMsg = false;
      }
    }
    // this.items = this.sortByKey(this.items, 'display');
  }

  callback() {
    this.counter++;
    if (this.counter === 60) {
      this.st.delTimer('1Sec');
      this.st.unsubscribe('1Sec');
      this.counter = 0;
      this.selectionLimitOverMsg = false;
    }
  }

  checkBoxClick(event) {
    if (this.multiselectedItems.length >= this.checkedItemsLimit && this.items.length > this.checkedItemsLimit) {
      this.selectionLimitOverMsg = true;
      this.disableRecordsFlag = true;
      this.counter = 0;

      this.newTimer = this.st.newTimer('1Sec', 1);
      if (this.newTimer) {
        this.timerId = this.st.subscribe('1Sec', () => this.callback());
      }
    } else {
      this.st.delTimer('1Sec');
      this.st.unsubscribe('1Sec');
      this.counter = 0;
      this.selectionLimitOverMsg = false;
      this.disableRecordsFlag = false;
    }
  }

  onClear() {
    let checkedItems = 0;
    this.items.forEach(item => {
      if (item.checked === true) {
        checkedItems++;
      }
    });

    if (checkedItems > 0) {
      this.items.forEach(item => {
        item.checked = false;
      });

      if (this.searchText !== '') {
        this.searchText = '';

        if (this.SearchType === 'server') {
          this.filterText.emit(this.searchText);
        }
      }
      // this.selectedCategory = {value:'', text:'', checked:false};
      for (let i = 0; i < this.categoryList.length; i++) {
        this.categoryList[i].checked = false;
      }
      this.disableRecordsFlag = false;
      this.selectionLimitOverMsg = false;
      this.st.delTimer('1Sec');
      this.st.unsubscribe('1Sec');
      this.counter = 0;
      this.selectedCategory = '';
      this.multiselectedItems = [];
      this.setItem('');
      this.itemSelected.emit();
      this.getSelectedItems.emit([]);
      // this.categorySelected.emit({value:'', text:'', checked:false});
      if (this.isfromMyDocuments) {
        this.items = this.items
      }
      else {
        this.items = this.sortByKey(this.items, 'display');
      }

    }
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  findItem(value) {
    return this.items.find((item) => +item.id === +value);
  }

  writeValue(value) {
    const item = this.findItem(value)
    this.value = value;
    this.display = item ? item.display : '';
  }

  setItem(item) {
    if (item) {
      if (item.id) {
        this.value = item.id;
      }
      if (item.display) {
        this.display = item.display;
      }
      this.disableClearSelection = false;
    } else {
      this.value = '';
      this.display = this.isWaterUsage ? this.translate.instant('COMMON.SELECT_PORT_MEASUREMENT') : this.translate.instant('COMMON.SELECT');
      // this.display = this.translate.instant('COMMON.SELECT');
      this.disableClearSelection = true;
    }
  }

  onKeyPress(e: KeyboardEvent) {
    if (e.keyCode === KEY_CODE.enter) {
      this.focus();
      return false;
    }
  }

  addHightLightClass(elem: HTMLElement) {
    elem.classList.add(CSS_CLASS_NAMES.highLight)
  }

  removeHightLightClass(elem: HTMLElement) {
    elem.classList.remove(CSS_CLASS_NAMES.highLight);
  }

  moveUpAndDown(key: number) {
    const selectedItem = this.listItems.find((li) => li.nativeElement.classList.contains(CSS_CLASS_NAMES.highLight));
    if (selectedItem) {
      let hightLightedItem: HTMLElement;
      if (key === KEY_CODE.arrowUp) {
        //check for first element
        if (selectedItem !== this.listItems.first) {
          hightLightedItem = selectedItem.nativeElement.previousSibling;
        }
      } else if (key === KEY_CODE.arrowDown) {
        //check for last element
        if (selectedItem !== this.listItems.last) {
          hightLightedItem = selectedItem.nativeElement.nextSibling;
        }
      }
      if (hightLightedItem) {
        this.clearHlightClass();
        this.removeHightLightClass(selectedItem.nativeElement);
        this.addHightLightClass(hightLightedItem);
        this.scrollToView(hightLightedItem);
      }
    } else {
      let highLightedItem: ElementRef;
      if (key === KEY_CODE.arrowUp) {
        highLightedItem = this.listItems.last;
      }
      else if (key === KEY_CODE.arrowDown) {
        highLightedItem = this.listItems.first;
      }
      if (highLightedItem) {
        this.addHightLightClass(highLightedItem.nativeElement);
        this.scrollToView(highLightedItem.nativeElement);
      }
    }
  }

  isSelected(item: { id: number, display: string }) {
    return +item.id === +this.value;
  }

  stringify(item) {
    return JSON.stringify(item);
  }

  onHover(event: MouseEvent) {
    this.clearHlightClass();
    const target = event.target as HTMLElement;
    if (event.type === 'mouseover') {
      target.classList.add(CSS_CLASS_NAMES.highLight)
    } else {
      target.classList.remove(CSS_CLASS_NAMES.highLight);
    }
  }

  clearHlightClass() {
    this.listItems.forEach((item) => {
      this.removeHightLightClass(item.nativeElement);
    })
  }

  public isSelectAllChecked(ele) {
    if (this.items.filter(item => item.checked).length === Array.from(ele.closest('.dd-list-container').querySelectorAll('.dd-list-item')).length) {
      this.isSelectAllSelected = true;
    } else {
      this.isSelectAllSelected = false;
    }

    if (this.isOmniSurveysTab) {
      this.multiselectText = this.isSelectAllSelected ? 'Unselect All' : 'Select All';
    }
    return this.isSelectAllSelected;
  }
  public onSelectAll($event) {
    this.unSelectAll.emit($event.target.checked);
    this.isSelectAllSelected ? this.isSelectAllSelected = false : this.isSelectAllSelected = true;
    const selectedItems = this.textCategorySearchPipe.transform(this.items, this.searchText, this.selectedCategory, true);
    this.items.forEach(item => selectedItems.find(it => it.display === item.display) ? this.onItemSelect(item, { target: { checked: $event.target.checked } }) : null);
    if (this.isSelectAllSelected && this.multiselectedItems.length > 0 && this.items.length > 0) {
      this.itemSelected.emit(this.multiselectedItems);
      this.displayOnInit = this.multiselectedItems;
    }
    if (this.multiselectedItems.length == this.items.length && this.multiSelection) {
      this.display = this.translate.instant("COMMON.ALL");
    }

  }

  onDropdownClick() {
    this.toggleEvent.emit();
    if (this.scrolltoTop === undefined || this.scrolltoTop === true) {
      this.scroll.nativeElement.scrollTop = 0;
    }
  }
}
