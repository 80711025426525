import { ModuleWithProviders, NgModule } from '@angular/core';
import { LocalCacheService, LocalCacheMode } from './local-cache.service';

@NgModule({
    declarations: [],
    exports: []
})
export class LocalCacheModule {

    static forRoot(config: LocalCacheMode): ModuleWithProviders<LocalCacheModule> {
        return {
            ngModule: LocalCacheModule,
            providers: [
                { provide: LocalCacheMode, useValue: config }
            ]
        };
    }

}
