import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../../../shared/models/_models';
import { LoggerService } from '../../logger/global-logger.service';
import { AppEnvConfig } from '../../../app.env.config';
import { TranslateService } from '@ngx-translate/core';
import { IDropdownOption } from '../../../shared/components/dropdown/dropdown-model';
import { LocalCacheService } from '../../local-cache/local-cache.service';
import { Subscription } from 'rxjs/Subscription';
import { UserService } from '../../user/user.service';
import { NavigationHandlerService } from '../../nav/navigation-handler.service';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'nalco-app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit, OnDestroy {
  user: User;
  authenticated: Boolean = false;
  languages: Array<IDropdownOption> = [];
  returnUrl: string;
  private subscriptions: Subscription[] = [];
  public isUserSettingLoading: Boolean = false;
  public isUserSettingFailed: Boolean = false;
  private resource: any;
  private whiteListed: String[];
  private timeoutThread: any;
  private timeoutCount: number;

  constructor(
    private zone: NgZone,
    private logger: LoggerService,
    private router: Router,
    private config: AppEnvConfig,
    private translate: TranslateService,
    private cache: LocalCacheService,
    private userService: UserService,
    private navService: NavigationHandlerService,
    private activatedRoute: ActivatedRoute,
    private MsalV3Service: MsalService
  ) {
    this.resource = this.config.getEnv('msalConfig.endpoints');
    this.whiteListed = this.config.getEnv('domains');
  }

  ngOnInit() {
    this.timeoutCount = 0;
    this.loadUserProfile();
    this.logger.info(
      {
        message: 'login component init - and return url is -->',
        object: this.returnUrl,
      },
      false,
      true
    );

    let url = this.cache.fetchData('returnUrl');
    if (url == undefined || url == null || url == '') {
      url = '/';
    }
    this.returnUrl = url;
    this.router.navigateByUrl(decodeURIComponent(this.returnUrl));
  }

  loadUserProfile() {
    // todo: write retry for 3 times here
    this.isUserSettingLoading = true;
    this.subscriptions.push(
      this.activatedRoute.data.subscribe(
        (res: any) => {
          // .retryWhen(errors => errors.delay(1000).take(5))
          const userCulture = res.UserSettings.Culture.match(/en|es|pt|zh|de|nl|fr|ru|it/);
          const userLanguage = userCulture != null ? userCulture[0] : 'en';
          this.translate.use(userLanguage);
          // this.isUserSettingLoading = false;

          // redirecting the page to requested/stored only after user settings is completed
          // this.router.navigate([this.returnUrl]);
          this.navService.updateLeftPanel(true);
          this.zone.run(() => {
            this.router.navigateByUrl(decodeURIComponent(this.returnUrl));
          });
        },
        (error) => {
          if (this.timeoutCount < 4) {
            clearTimeout(this.timeoutThread);
            this.timeoutThread = setTimeout(() => {
              this.timeoutCount += 1;
              this.loadUserProfile();
            }, 5000);
          } else {
            this.isUserSettingFailed = true;
            this.logger.error(
              {
                message: 'Unable to download user settings - ->',
                object: error,
              },
              true,
              true
            );
            setTimeout(() => {
              this.MsalV3Service.logout();
            }, 3000);
          }
        }
      )
    );
  }

  ngOnDestroy() {
    // prevent memory leak when component is destroyed
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
