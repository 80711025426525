<ng-container *ngIf="!filterObjectFlag">
  <div class="filterButton" id='filterButtonID' (click)="showFilters()">
    <span class="nalco-filtericon float-left"></span>
    <p class="filterBy">
      {{'COMMON.FILTER_BY'| translate}}
    </p>
    <p class="customerName" placement="bottom" #element1
      [ngbTooltip]="element1.scrollWidth > element1.offsetWidth+1 ? ('COMMON.SELECT_FILTER'| translate) : ''">
      <span *ngIf="value; then thenBlock else elseBlock"></span>
      <ng-template #thenBlock>{{value}}</ng-template>
      <ng-template #elseBlock>{{'COMMON.SELECT_FILTER'| translate}}</ng-template>
    </p>
  </div>
</ng-container>
<ng-container *ngIf="filterObjectFlag">
  <div class="filterButton activeButton" id='filterButtonID' (click)="showFilters()">
    <span class="nalco-filtericon float-left"></span>
    <p class="filterBy">
      {{'COMMON.FILTER_BY'| translate}}
    </p>
    <ng-container *ngIf="!isOnlySiteSelectionCVOC">
      <p class="customerName " *ngIf="filterObject.parentId && !isQueryParams && !customerNameLoader && showFilterLoader">
        {{filterObject.parentName}}
      </p>
      <p class="customerName " *ngIf="!filterObject.parentId && filterObject.children.length && !isQueryParams">
        {{filterObject.children[0].contextPointName}}
      </p>
      <p class="customerName " *ngIf="this.isQueryParams && dataInput">
        {{dataInput[0]?.AccountName}}
      </p>
    </ng-container>
    <ng-container *ngIf="isOnlySiteSelectionCVOC">
      <p class="customerName " *ngIf="siteObject.siteId !=''">
        {{siteObject.siteName}}
      </p>
    </ng-container>
    <span class="c-three-dots-loader" *ngIf="customerNameLoader && filterObject.parentId && !isQueryParams || !showFilterLoader"></span>
  </div>
</ng-container>
<div *ngIf="filterObjectFlag" class="float-left water-filter-by showMe">
  <div class="innerBox">
    <div class="row ml-0 headerActive">
      <div class="col-md-12">{{'COMMON.ACTIVE_FILTERS'| translate}}</div>
    </div>
    <div class="row ml-0 selectedFilter">
      <div class="col-md-12 box1" *ngIf="!isOnlySiteSelectionCVOC">
        <span *ngIf="filterObject.parentId !='' && filterObject.parentId != null && siteObject.siteId == '' && !isQueryParams">
          {{filterObject.parentName}}
          <span *ngIf="filterObject.children.length > 0 ">-</span>
        </span>
        <span *ngIf="siteObject.siteId  && !isQueryParams">
          {{siteObject.siteName}}
          <span *ngIf="displayContextPointName(filterObject.children)">-</span>
        </span>
        <span *ngFor="let corpdetails of filterObject.children; let i=index">
          <span *ngIf="corpdetails">
            <span *ngIf="corpdetails[0]">
              <span *ngIf="corpdetails.length>1 &&accountchildrenDetails[i]?.length == corpdetails.length"> {{'COMMON.ALL'| translate}}</span>
              <span *ngIf="corpdetails.length>1 && accountchildrenDetails[i]?.length != corpdetails.length"> {{'COMMON.MULTIPLE_SELECTION'| translate}}</span>
              <span *ngIf="corpdetails.length==1"> {{corpdetails[0].contextPointName}}</span>
              <span *ngIf="(filterObject.children.length-1) != i">,</span>
            </span>
            <span *ngIf="corpdetails[0] == undefined">
              {{corpdetails.contextPointName}}
              <span *ngIf="(filterObject.children.length-1) != i">,</span>
            </span>
          </span>
        </span>
        <span *ngIf="isQueryParams == true && dataInput">
          {{dataInput[0]?.AccountName}}
        </span>
      </div>
      <div class="col-md-12 box1" *ngIf="isOnlySiteSelectionCVOC">
        <span *ngIf="siteObject.CorporateAccountName !='' && siteObject.CorporateAccountName != null">
          {{siteObject.CorporateAccountName}}
          <span *ngIf="siteObject.siteId != ''">-</span>
        </span>
        <span *ngIf="siteObject.siteId !=''">
          {{siteObject.siteName}}
        </span>
      </div>
    </div>
  </div>
</div>
<ng-template #filterSitesModal let-dimissSiteFilters="dismiss" let-saveSitesFilter="close">
  <div class="filterPopup" [ngClass]="isOnlySiteSelectionCVOC ? 'asset-details-sites-filter' : ''">
    <div class="modal-header">
      <h3 class="modal-title" translate>COMMON.FILTER_BY</h3>
      <button type="button" class="close" aria-label="Close" (click)="dimissSiteFilters();dismissSiteFilters();">
        <span class="ico-exit-round-edge"></span>
      </button>
    </div>
    <div class="modal-body p-0 scrollbar-border">
      <ngb-tabset class="site-nav col-md-12 tabs p-0" activeId="{{activeTabID}}" (tabChange)="tabChange($event)">
        <ngb-tab *ngIf="!hideTab" id='ngb-tab-0'>
        <ng-template ngbTabTitle>
          <span class="tab-width" #element2 [ngbTooltip]="element2.offsetWidth+1 < element2.scrollWidth ? title : ''" placement="bottom" container="body">{{title}}</span>
        </ng-template>
        <ng-template ngbTabContent>
            <div class="col-lg-12">
              <div class="pl-2 pr-2" *ngIf="serviceEnabledName == 'WFI'">
                <nalco-account-search [isEVOCFilter]="isEVOCFilter" (accountSelected)="onAccountSelected($event)" [initialAccountName]="filterObject.parentName"
                  (accountCleared)="onAccountCleared()" [callClear]='callClear' [clearData]="clearDataAccess" [serviceEnabledName]="'WFI'"></nalco-account-search>
              </div>
              <div class="pl-2 pr-2" *ngIf="serviceEnabledName != 'WFI'">
                <nalco-account-search [isEVOCFilter]="isEVOCFilter" (accountSelected)="onAccountSelected($event)"
                  (accountCleared)="onAccountCleared()" [callClear]='callClear' [clearData]="clearDataAccess"></nalco-account-search>
              </div>
            </div> 
              <nalco-customer-division-filter (LoaderforchildWFI)="getchildLoader($event)" [isMyDocument]="isMyDocument" [accountDetails]="accountDetails" [multiselectDropdownFlag]="multiselectDropdownFlag"
              [corporateAccountDetails]='selectedCorporateAccountDetails' [accountDetailsChild]="accountDetailsChild" (filterObjChange)="filterObjChange($event);" (appliedFiltersChange)="appliedFiltersChange($event)"
              (clearDetails)="clearDetails(true)" (clearAllClicked)="clearAll($event)" [serviceEnabledName]="serviceEnabledName == 'WFI' || serviceEnabledName == 'WQ' ? 'WFI' : serviceEnabledName" [isClearAllVisible]="!isOnlySiteSelectionCVOC ? true : (appliedFiltersAccess.parentId != '' || isClearAllVisible )"></nalco-customer-division-filter>  
            <div class="col-lg-12" *ngIf="isSSDTab || isOnlySiteSelectionCVOC">
              <div class="SSD-select-Site site-tab-loader  col-lg-12 pl-3 pr-3 pt-4 float-left" id="SSDCVOCsiteSearch">
                <nalco-select-dropdown [SearchType]="'server'" [list]="SiteDropdownData" [FromSSD]="true" [isDataClear]="isClear" [isDisable]="isOnlySiteSelectionCVOC ? false : partnerDivisonSiteSearchDisabled"
                  placeholder="{{'DATA_VISUALIZATION.SELECT_1_SITE' | translate}}"
                  [isRequired]="isOnlySiteSelectionCVOC ? true : false"
                  [showLoader]="sitesLoading" [multiSelection]="false"
                  noRecoredsLabel="{{'DATA_VISUALIZATION.NO_MATCHING_SITES' | translate}}"
                  [displayOnInit]="selectedSiteOnInit" (itemSelected)="loadParameters($event)"
                  (getSelectedItems)="selectedSite()" (filterText)="filterSiteText($event)"
                  (lazyLoading)="fetchMore()" [depValue]="depValue" [display]="siteDisplayName" [isSiteCache]="isSiteCache" [isSiteTab]="appliedFiltersAccess?.parentId == '' && isOnlySiteSelectionCVOC"
                  [isClearAllSelected] ="isClearAllSelected">
                </nalco-select-dropdown>
                <div class="alert-container">
                  <div id="alert-msg"></div>
                </div>
              </div>
            </div>
            <div *ngIf="serviceEnabledName == 'WFI' || serviceEnabledName == 'WQ'">
              <div *ngIf="loader"  class='loader'></div>
            </div>
          </ng-template>
        </ngb-tab>

        <ngb-tab *ngIf="isInternal && !isSSDTab && serviceEnabledName != 'WFI' && !isOnlySiteSelectionCVOC" id='ngb-tab-1'>
          <ng-template ngbTabTitle>
            <span class="tab-width" #element5 [ngbTooltip]="element5.offsetWidth+1 < element5.scrollWidth ? ('COMMON.GEO_SALES' | translate) : ''" placement="bottom" container="body"
            >{{'COMMON.GEO_SALES' | translate}}</span>
          </ng-template>
          <ng-template ngbTabContent>
            <nalco-geo-sales-region-filter [territoriesData]="territoriesData" [countryData]="countryData"
              (filterObjChange)="geoSalesFilterChange($event);"></nalco-geo-sales-region-filter>
          </ng-template>
        </ngb-tab>

        <!-- filter for Street account user -->
        <ngb-tab id='ngb-tab-2' *ngIf="isSSDTab || isFITab || isSite || isSiteTabEnabled">
          <ng-template ngbTabTitle>
            <span class="tab-width" #element4 [ngbTooltip]="element4.offsetWidth+1 < element4.scrollWidth ? ('COMMON.SITES' | translate) : ''" placement="bottom" container="body"
            >{{'COMMON.SITES' | translate}}</span>
          </ng-template>
            <ng-template ngbTabContent>
              <div class="col-lg-12 px-2 mt-3 SSD-select-Site site-tab-loader" *ngIf="!isSiteSelected">
                <nalco-select-dropdown [SearchType]="'server'" [list]="SiteDropdownData" [FromSSD]="true" [isSiteTab]="true"
                  placeholder="{{'DATA_VISUALIZATION.SELECT_1_SITE' | translate}}" [showLoader]="sitesLoading"
                  [multiSelection]="false" noRecoredsLabel="{{'DATA_VISUALIZATION.NO_MATCHING_SITES' | translate}}"
                  [displayOnInit]="selectedSiteOnInit" (itemSelected)="loadSiteParameters($event)"
                  (getSelectedItems)="selectedSiteTab()" (filterText)="filterSiteText($event)" (lazyLoading)="fetchMore()"
                  [depValue]="depValue" [display]="siteDisplayName" [isSiteCache]="isSiteCache" [isDataClear]="isClear">
                </nalco-select-dropdown>
              </div>
            </ng-template>
          </ngb-tab>

      </ngb-tabset>

    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="dimissSiteFilters();dismissSiteFilters()" translate>COMMON.CANCEL</button>
      <button type="button" [disabled]="isDisable" class="btn btn-primary" (click)="saveSitesFilter($event)"
        translate>COMMON.APPLY</button>
    </div>
  </div>
</ng-template>
