//TODO - change title to use translations
export const InventoryTableColumns = [
  {
    'title': 'Inventory Name', //changed from 'System Name' to 'Inventory Name'
    'resource_key':'SS_DASHBOARD.INVENTORY.TABLE.INVENTORY_NAME',
    'defaultActive': true,
    'isLocked': true,
    'field': 'SystemName',
    'isUOMNeeded': false ,
    'width' : 180
  },
  {
    'title': 'Serial Number',
    'resource_key':'SS_DASHBOARD.INVENTORY.TABLE.SERIAL_NUMBER',
    'defaultActive': true,
    'isLocked': true,
    'field': 'SerialNumber',
    'isUOMNeeded': false ,
    'width' : 180
  },
  {
    'title': 'Product Name',
    'resource_key':'SS_DASHBOARD.INVENTORY.TABLE.PRODUCT_NAME',
    'defaultActive': true,
    'isLocked': true,
    'field': 'ProductName',
    'isUOMNeeded': false ,
    'width' : 180
  },
  {
    'title': 'Status',
    'resource_key':'SS_DASHBOARD.INVENTORY.TABLE.STATUS',
    'defaultActive': true,
    'isLocked': false,
    'field': 'Status',
    'isUOMNeeded': false,
    'width' : 200
  },
  {
    'title': 'Capacity',
    'resource_key':'SS_DASHBOARD.INVENTORY.TABLE.CAPACITY',
    'defaultActive': true,
    'isLocked': false,
    'field': 'Capacity',
    'isUOMNeeded': true,
    'width' : 180
  },
  {
    'title': 'Previous Reading',
    'resource_key':'SS_DASHBOARD.INVENTORY.TABLE.PREVIOUS_READING',
    'defaultActive': true,
    'isLocked': false,
    'field': 'PreviousReading',
    'isUOMNeeded': true,
    'width' : 180
  },
  {
    'title': 'Last Reading',
    'resource_key':'SS_DASHBOARD.INVENTORY.TABLE.LATEST_READING',
    'defaultActive': true,
    'isLocked': false,
    'field': 'CurrentReading',
    'isUOMNeeded': true,
    'width' : 180
  },
  {
    'title': 'Last Refill/ Deliveries',
    'resource_key':'SS_DASHBOARD.INVENTORY.TABLE.LAST_REFILL_DELIVERIES',
    'defaultActive': false,
    'isLocked': false,
    'field': 'LastRefillQuantity',
    'isUOMNeeded': true,
    'width' : 180
  },
  {
    'title': '#Slugs',
    'resource_key':'SS_DASHBOARD.INVENTORY.TABLE.SLUG',
    'defaultActive': false,
    'isLocked': false,
    'field': 'NumberOfSlugs',
    'isUOMNeeded': false,
    'width' : 150
  },
  {
    'title': 'Total Usage',
    'resource_key':'SS_DASHBOARD.INVENTORY.TABLE.TOTAL_USAGE',
    'defaultActive': false,
    'isLocked': false,
    'field': 'TotalUsage',
    'isUOMNeeded': true,
    'width' : 150
  },
  {
    'title': 'Avg. Daily Usage',
    'resource_key':'SS_DASHBOARD.INVENTORY.TABLE.AVG_DAILY_USAGE',
    'defaultActive': false,
    'isLocked': false,
    'field': 'AverageUsage',
    'isUOMNeeded': true,
    'width' : 150
  },
  // {
  //   'title': 'Avg. Usage Prior < > Days ',
  //   'defaultActive': false,
  //   'isLocked': false,
  //   'field': 'AverageUsagePriorDays',
  //   'isUOMNeeded': true
  // },
  {
    'title': 'Date To Empty',
    'resource_key':'SS_DASHBOARD.INVENTORY.TABLE.DATE_TO_EMPTY',
    'defaultActive': false,
    'isLocked': false,
    'field': 'DateToEmpty',
    'isUOMNeeded': false,
    'width' : 180
  },
  {
    'title': 'Target Usage',
    'resource_key':'SS_DASHBOARD.INVENTORY.TABLE.TARGET_USAGE',
    'defaultActive': false,
    'isLocked': false,
    'field': 'TargetUsage',
    'isUOMNeeded': true,
    'width' : 150
  },
  {
    'title': '% Target Usage',
    'resource_key':'SS_DASHBOARD.INVENTORY.TABLE.PERCENT_TARGET_USAGE',
    'defaultActive': false,
    'isLocked': false,
    'field': 'PlusMinusTargetUsage',
    'isUOMNeeded': false,
    'width' : 150
  },
  {
    'title': 'YTD Total',
    'resource_key':'SS_DASHBOARD.INVENTORY.TABLE.YTD_TOTAL',
    'defaultActive': false,
    'isLocked': false,
    'field': 'YTDTotal',
    'isUOMNeeded': true,
    'width' : 150
  },
  {
    'title': 'Predicted Annual',
    'resource_key':'SS_DASHBOARD.INVENTORY.TABLE.PREDICTED_ANNUAL',
    'defaultActive': false,
    'isLocked': false,
    'field': 'PredictedAnnualUsage',
    'isUOMNeeded': true,
    'width' : 150
  }
];

export enum MaxDataSet {
  NumberOfDatasets = 5
}

export const InventoryMultipleDataSources = [
  {
    'value': 'Automatic',
    'resource_key':'SS_DASHBOARD.CUSTOMIZE_INVENTORY_TABLE.AUTOMATIC_DATASOURCE',
  },
  {
    'value': 'Manual',
    'resource_key':'SS_DASHBOARD.CUSTOMIZE_INVENTORY_TABLE.MANUAL_DATASOURCE',
  },
  {
    'value': 'Automatic,Manual',
    'resource_key':'SS_DASHBOARD.CUSTOMIZE_INVENTORY_TABLE.AUTOMATIC_MANUAL_DATASOURCE',
  },
]

export enum ClearPillsFilters {
  VC = 1,
  DivisionBy = 2,
  Currency = 3,
  UnitOfMeasure = 4,
  ClearAll = 5,
  AnnualSaving = 6
}

export const PARENT_NAME_SITE = 'Site';
export const MAPPING_LOCATION_CP_ID_DEFAULT = 0;

