import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'noDecimal' })
export class NoDecimalPipe implements PipeTransform {
  transform(ele, isString = false) {
    if (isString) {
      ele = parseFloat(ele);
    }
    if (ele < 1 && ele > 0) {
      return ele.toFixed(2);
    } else {
      return (ele != null) ? Math.round(ele) : null;
    }
  }
}