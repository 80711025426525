export class Color {
    public static readonly Red = '#E00034';
    public static readonly Yellow = '#EEAF00';
    public static readonly Green = '#6FB43F';
    public static readonly Gray = '#DEDEDE';
    public static readonly Grey = '#DEDEDE';
    public static readonly OfflineGray = '#DEDEDE';
}

export class Icon {
    public static readonly Red = 'iconwater-HighRiskRed';
    public static readonly Yellow = 'iconwater-MediumRiskYellow';
    public static readonly Green = 'iconwater-LowRiskGreen';
    public static readonly Gray = 'icon-trend-NoTest';
    public static readonly Grey = 'icon-trend-NoTest';
    public static readonly OfflineGray = 'iconwater-OfflineGray';
}

export class MapMarkerText {
    public static readonly Red = '\ue900';
    public static readonly Yellow = '\ue902';
    public static readonly Green = '\ue901';
}
