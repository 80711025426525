import { Component, OnInit, OnDestroy, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { Subscription } from "rxjs/Subscription";
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { SimpleChanges } from '@angular/core';
import { SerialNumberSearch } from '../../models/SerialNumberSearch';
import { ControllerManagementService } from '../../../../../src/app/controller-management/_services/controller-management.service';

@Component({
  selector: 'nalco-serial-number-search',
  templateUrl: './serial-number-search.component.html',
  styleUrls: ['./serial-number-search.component.scss'],
})

export class SerialNumberSearchComponent implements OnInit, OnDestroy {
  @ViewChild('autocomplete',{static: false}) autocomplete: AutoCompleteComponent;
  @Output() serialNoSelected = new EventEmitter<any>();
  @Output() serialNoCleared = new EventEmitter();
  @Input() placeholder: string;
  @Input() isQueryParams?: boolean = false;
  @Input() callClear: boolean;
  @Input() serviceEnabledName?: string = '';
  private _flag = true;
  serialNoList = [];

  @Input() set dataInput(value: any) {
    if (value !== undefined) {
      this.data = value;
      this.source = value;
      this._flag = false;
      if (this.isQueryParams === true) {
        this.queryParamsValidation();     // need to call this only when query params is present 
      }
    }
  }


  queryParamsValidation() {
    if (this.data && this.data.length === 1) {
      this.initialSerialNumber = this.data[0].SerialNumber;
      this.source = this.queryParamsUsed;
      this._flag = true;
    }
  }

  @Input() set clearData(value: boolean) {

    if (this.autocomplete && value && value !== undefined) {
      this.autocomplete.reset();
      this.autocomplete.value = '';
    }
  }

  private subscriptions: Subscription[] = [];
  public data: Array<SerialNumberSearch> = [];
  private source: Array<SerialNumberSearch> = [];
  private queryParamsUsed: Array<SerialNumberSearch> = [];
  private serialDetails = {};
  @Input() initialSerialNumber: string;
  @Input() resetOnClear:boolean = false;

  constructor(private controllerManagementService: ControllerManagementService) {
  }


  debounce(callback, delay) {
    let timeout;
    return function () {
      clearTimeout(timeout);
      timeout = setTimeout(callback, delay);
    }
  }

  handleFilter(value) {
    this.debounce(this.getdropdown(value), 1000);
    if(value == '' && this.resetOnClear) {
      this.autocomplete.reset();
      this.serialNoCleared.emit();
    }
  }

  getdropdown(value) {
    this.autocomplete.toggle(false);
    if (value.length >= 3) {
      this.data = this.source.filter((s) => s.SerialNumber.toLowerCase().indexOf(value.toLowerCase()) !== -1);
      if (this.data.length > 0) {
        this.autocomplete.toggle(true);
      } else {
        setTimeout(() => {
          this.autocomplete.toggle(true);
        }, 1000);
      }
    }
  } 

  ngOnInit() { 
  }

  ngOnChanges(change:SimpleChanges){
    if(change.callClear){
      if(this.autocomplete){
        this.autocomplete.reset();
        this.autocomplete.value = '';
      }
    }
  }

  ngOnDestroy() {
    // prevent memory leak when component is destroyed
    this.subscriptions.forEach(s => s.unsubscribe());
  }






  public valueChange(value: any): void {
    const serialNo: SerialNumberSearch = this.data.find((s) => s.SerialNumber.toLowerCase() === value.toLowerCase());
    if (serialNo !== undefined) {
      this.serialDetails['serialNumber'] = value;
      this.serialNoSelected.emit(this.serialDetails);
    } else {
      this.serialNoCleared.emit();
    }

  }

}
