import { Injectable } from '@angular/core';
import { APP_CONFIG, ENVIRONMENTS } from '../../app.config';

@Injectable()
export class ActionLogServiceConfig {

    private configMock: any = { // MOCK - local json settings
        environment: ENVIRONMENTS.mock,
        baseUrl: '',
        apiUrl: {
            getActionLogRecommendation: 'api/v1/Recommendations/RecommendationSets/',
            getAssets: 'SiteAssetCurrentStatus',
            getResponsiblePerson: 'Users',
            saveActionLogRecommendation: 'api/v1/Recommendation',
            // saveActionLogRecommendation: 'api/v1/Recommendations/Recommendation',
            getAttachmentUri: 'api/v1/Recommendations/RecommendationAttachmentUri',
            getsites: 'SitePagingByUser',
            getSitesForCorporates: 'CustomerSites',
            getEroiTypes: 'api/v1/FinancialImpact/ERoiTypes',
            getSavingCategoryTypes: 'api/v1/FinancialImpact/SavingCategoryTypes',
            getSoldTosBySiteContextPointId: 'api/v1/Accounts/SoldTosBySiteContextPointId'
        }
    };
    private configProd: any = { // PRODUCTION - PRODUCTION ENVIRONMENT SETTINGS
        environment: ENVIRONMENTS.prod,
        baseUrl: '',
        apiUrl: {
            getActionLogRecommendation: 'api/v1/Recommendations/RecommendationSets/',
            getAssets: 'SiteAssetCurrentStatus',
            getResponsiblePerson: 'Users',
            saveActionLogRecommendation: 'api/v1/Recommendation',
            getAttachmentUri: 'api/v1/Recommendations/RecommendationAttachmentUri',
            getsites: 'SitePagingByUser',
            getSitesForCorporates: 'CustomerSites',
            getEroiTypes: 'api/v1/FinancialImpact/ERoiTypes',
            getSavingCategoryTypes: 'api/v1/FinancialImpact/SavingCategoryTypes',
            getSoldTosBySiteContextPointId: 'api/v1/Accounts/SoldTosBySiteContextPointId'
        }
    };

    private _environment: string = APP_CONFIG.ENVIRONMENT;

    constructor() {
        // void
    }

    public getEnvironment(): any {
        switch (this._environment) {
            case ENVIRONMENTS.mock:
                return this.configMock;
            case ENVIRONMENTS.dev:
                return this.configProd;
            case ENVIRONMENTS.test:
                return this.configProd;
            case ENVIRONMENTS.stg:
                return this.configProd;
            case ENVIRONMENTS.prod:
                return this.configProd;
            default:
                return null;
        }
    }

    public setEnvironment(environment: string): any {
        this._environment = environment;
    }

}
