export class DurationOption {
    Number?: number;
    Value?: string;
    StartDate?: string;
    EndDate?: string;
    DateRange?: string;
    IsValid?: boolean;
    constructor(d?: DurationOption) {
        if(d){
            // Object.assign(this, d);
            this.Number = d.Number;
            this.Value = d.Value;
            this.StartDate = d.StartDate;
            this.EndDate = d.EndDate;
            this.DateRange = d.DateRange;
            this.IsValid = d.IsValid;
        }
    }
}
