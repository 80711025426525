import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'nalco-delta',
  templateUrl: './delta.component.html',
  styleUrls: ['./delta.component.scss']
})
export class DeltaComponent implements OnInit {
  @Input() percentChange: any;
  @Input() assetStatus: any;
  assetColor: any;
  isPositive: boolean;

  constructor() { }

  ngOnInit() {
  }

  setAssetColor() {
    this.isPositive = this.isPositiveChange(this.percentChange);

      if (this.isPositive) {
        this.assetColor = '#6FB43F'; //Green
      } else {
        this.assetColor = '#E00034'; //Red
      }

      const styles = {
        'color': this.assetColor,
      };

      return styles;
  }

  isPositiveChange(percentChange) {
    return Math.sign(percentChange) === -1 ? false : true;
  }
}
