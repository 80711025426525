import * as contracts from '../contracts/_contracts';
import { ServiceType, ContextPointType } from '../_enum/constants';

export class ServiceCategoryEntity implements contracts.IServiceCategoryEntity {
  ServiceCategoryId: number;
  ServiceTypeId: ServiceType;
  CategoryName: string;
  CategoryResourceName: string;
  SortOrder: number;
  ModifiedBy: number;
  ModifiedOn: string;
  IsActive: boolean;

  constructor(cat?: contracts.IServiceCategoryEntity) {
    if (cat) {
      this.ServiceCategoryId = cat.ServiceCategoryId;
      this.ServiceTypeId = cat.ServiceTypeId;
      this.CategoryName = cat.CategoryName;
      this.CategoryResourceName = cat.CategoryResourceName;
      this.SortOrder = cat.SortOrder;
      this.ModifiedBy = cat.ModifiedBy;
      this.ModifiedOn = cat.ModifiedOn;
      this.IsActive = cat.IsActive;
    }
  }
}

export class ServiceContextPointTypeMapEntity
  implements contracts.IServiceContextPointTypeMapEntity {
  ServiceTypeId: ServiceType;
  ContextPointTypeId: ContextPointType;
  ContextPointSubTypeId: number;
  ServiceCategoryId: number;
  SortOrder: number;
  CreatedOn: string;
  ModifiedBy: number;
  ModifiedOn: string;
  IsActive: boolean;

  constructor(cptypemap?: contracts.IServiceContextPointTypeMapEntity) {
    this.ServiceTypeId = cptypemap.ServiceTypeId;
    this.ContextPointTypeId = cptypemap.ContextPointTypeId;
    this.ContextPointSubTypeId = cptypemap.ContextPointSubTypeId;
    this.ServiceCategoryId = cptypemap.ServiceCategoryId;
    this.SortOrder = cptypemap.SortOrder;
    this.CreatedOn = cptypemap.CreatedOn;
    this.ModifiedBy = cptypemap.ModifiedBy;
    this.ModifiedOn = cptypemap.ModifiedOn;
    this.IsActive = cptypemap.IsActive;
  }
}

export class ServiceContextPointTypeMap extends ServiceContextPointTypeMapEntity
  implements contracts.IServiceContextPointTypeMap {
  ContextPointName: string;

  constructor(cptypemap?: contracts.IServiceContextPointTypeMap) {
    super(cptypemap);
    if (cptypemap) {
      this.ContextPointName = cptypemap.ContextPointName;
    }
  }
}

export class ServiceCategoryAssetClassificationEntity
  implements contracts.IServiceCategoryAssetClassificationEntity {
  ServiceCategoryId: number;
  AssetClassificationName: string;
  AssetClassificationResourceName: string;
  AssetClassificationValueId: number;
  SortOrder: number;
  ModifiedBy: number;
  ModifiedOn: string;
  IsActive: boolean;

  constructor(a?: contracts.IServiceCategoryAssetClassificationEntity) {
    if (a) {
      this.ServiceCategoryId = a.ServiceCategoryId;
      this.AssetClassificationName = a.AssetClassificationName;
      this.AssetClassificationValueId = a.AssetClassificationValueId;
      this.AssetClassificationResourceName = a.AssetClassificationResourceName;
      this.SortOrder = a.SortOrder;
      this.ModifiedOn = a.ModifiedOn;
      this.ModifiedBy = a.ModifiedBy;
      this.IsActive = a.IsActive;
    }
  }
}

export class ServiceCategoryAssetClassification
  extends ServiceCategoryAssetClassificationEntity
  implements contracts.IServiceCategoryAssetClassification {

  constructor(a?: contracts.IServiceCategoryAssetClassification) {
    super(a);
  }
}

export class ServiceCategory extends ServiceCategoryEntity
  implements contracts.IServiceCategory {
  AssetTypes: Array<ServiceContextPointTypeMap> = [];
  AssetClassifications: Array<ServiceCategoryAssetClassification> = [];
  constructor(a?: contracts.IServiceCategory) {
    super(a);
    if (a) {
      a.AssetTypes.forEach((e) => {
        this.AssetTypes.push(new ServiceContextPointTypeMap(e));
      });
      a.AssetClassifications.forEach((ac) => {
        this.AssetClassifications.push(new ServiceCategoryAssetClassification(ac));
      });
    }
  }
}
