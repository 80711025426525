<div class="system-dashboard">
    <nalco-tile [parentInstance]="myParentInstance()"   [headerTitle]="" [errorData]='errorInfo' [showMoreOptions]=false [showInfoIcon]=false class="no-header">
        <div tile-content>
            <form [formGroup]="systemDetailsForm" class="system-tab">
                <div class="row">
                    <div class="col-md-10">
                        <div class="form-group row">
                            <div class="col-sm-3">
                                <label class="col-form-label headingClass" translate>RO_OPTIMIZER.SYSTEM.ASSET</label>
                            </div>
                            <div class="col-sm-8">
                                <select class="form-control w-50" formControlName="Asset" (change)="onAssetChange()">
                                    <option *ngFor="let item of assetsDropdown" [ngValue]="item">
                                        {{item.AssetName}} ({{item.scenarioCount}} Scenarios)
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-sm-3">
                                <label class="col-form-label headingClass"
                                    translate>RO_OPTIMIZER.SYSTEM.SCENARIO_NAME</label>
                            </div>
                            <div class="col-sm-8">
                                <select class="form-control w-50" formControlName="ScenarioName"
                                    (change)="onScenarioChange()" [(ngModel)]="selectedScenario">
                                    <option [ngValue]="null" disabled hidden></option>
                                    <option *ngFor="let item of scenariosDropdown" [ngValue]="item">
                                        {{item.ScenarioName}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-sm-3">
                                <label class="col-form-label headingClass"
                                translate>RO_OPTIMIZER.SYSTEM.PREFERRED_UNIT</label>
                            </div>
                            <div class="col-sm-8">
                                <select class="form-control w-50" formControlName="PreferredUnits"
                                    (change)="onUnitsChange($event)">
                                    <option *ngFor="let item of Units" value="{{item.name}}">
                                        {{item.name}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-sm-3">
                                <button (click)="loadOIP()" [ngbTooltip]="tipContentOIP" class="btn btn-primary" triggers="hover"
                                    translate placement="bottom" [disabled]="disableOIP">RO_OPTIMIZER.SYSTEM.OPEN_LAST_SAVED </button>
                                <ng-template #tipContentOIP>
                                    <div [innerHTML]="lastSavedInfo"></div>
                                </ng-template>
                            </div>
                            <div class="col-sm-3">
                                <button (click)="loadPRO()" [ngbTooltip]="tipContentPRO" class="btn btn-primary"  triggers="hover"
                                    translate placement="bottom" [disabled]="disablePRO">RO_OPTIMIZER.SYSTEM.OPEN_INITIAL_CASE </button>
                                <ng-template #tipContentPRO>
                                    <div [innerHTML]="initialCaseInfo"></div>
                                </ng-template>
                            </div>
                        </div>

                    </div>
                </div>
            </form>
        </div>
    </nalco-tile>
</div>