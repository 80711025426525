

export const pageRefreshErrorCodes = {
    ERROR_408: "408",
    ERROR_500: "500",
    ERROR_502: "502",
    ERROR_503: "503",
}


export const errorCodes = {
    ERROR_400: "400",
    ERROR_401: "401",
    ERROR_403: "403",
    ERROR_404: "404",
    ERROR_408: "408",
    ERROR_417: "417",
    ERROR_500: "500",
    ERROR_501: "500",
    ERROR_502: "502",
    ERROR_503: "503",
}
export const routeErrorCodes = {
    ERROR_401: "401",
    ERROR_403: "403",
    ERROR_404: "404",
}


