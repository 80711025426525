<li [id]='id' tabindex=" 1" [class]="'nav-item slds-nav-vertical__item nav-item-container left-navigation-panel cPRTL_SideNav_MenuItem_TopLevel '+tooltip"
  [ngClass]="subMenu?.length>0 ? 'slds-has-submenu' : ''">
  <ng-template #tooltipContent>
    <p>{{tooltip | translate}}</p>
  </ng-template>
  <a class="slds-nav-vertical__action nav-link text-center primary-blue-color-no-link" (click)="itemClicked()" routerLinkActive="active" placement="right"
    [ngbTooltip]="tooltip.length ? tooltipContent: ''">
    <span *ngIf="icon !=='icon-TrainingIcon'" [class]="'slds-icon_container slds-line-height_reset '+icon"></span>
    <span *ngIf="icon =='icon-TrainingIcon'"  [class]="'slds-icon_container slds-line-height_reset '+icon" >
        <span class="path1"></span>
        <span class="path2"></span>
        <span class="path3"></span>
        <span class="path4"></span>
    </span>
    <span *ngIf="icon =='icon-tools'" [class]="'slds-icon_container slds-line-height_reset'">
      <img class="left-menu-icon-tools" src="../assets/fonts/icon-tools.svg "/>
    </span>
    <!-- <span *ngIf="icon =='my-territory-icon'" [class]="'slds-icon_container slds-line-height_reset'">
      <img class="left-menu-my-territory-icon" src="../../../../../assets/images/icon_my_territory_icon.png "/>
    </span> -->
    <span *ngIf="!icon">{{text | translate}}</span>
    <span *ngIf="icon" class="sr-only d-sm-none d-md-block d-lg-block">{{text | translate}}
    </span>
    <span class="menu-text d-sm-block d-md-none d-lg-none">{{text | translate}}</span>
  </a>
  <div *ngIf="subMenu?.length>0" class="left-sub-nav" [ngClass]="{'showmenu': showMenu}">
    <h3 class="font-light pl-4 pr-2 pt-4 d-sm-none d-md-block d-lg-block">{{heading | translate}}</h3>
    <div class="left-submenu-scroll">
      <nalco-left-menu-submenu-item *ngFor="let subMenuNav of subMenu" [details]="subMenuNav" (submenuItemClick)="subMenuItemClick($event)">
      </nalco-left-menu-submenu-item>
    </div>
  </div>
</li>
