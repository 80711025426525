import { Component, OnInit, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import * as Highcharts from 'highcharts';
import * as highstock from 'highcharts/highstock';
import annotation from 'highcharts-annotations';

import { ChartObject } from 'highcharts';

@Component({
  selector: 'nalco-stock-chart',
  templateUrl: './stock-chart.component.html',
  styleUrls: ['./stock-chart.component.scss']
})
export class StockChartComponent implements OnInit {
  @Input() data: any;
  @Output('zoomChartData') zoomChartData = new EventEmitter<any>();
  chart: ChartObject;
  options: highstock.Options;
  assetColors = ['#007AC9', '#EEAF00', '#E00034', '#f86450', '#e09519', '#efdb2f', '#94e159', '#5eb6e4'];
  lineStyle = ['Solid', 'Dash',
    'ShortDash',
    'ShortDot',
    'ShortDashDot',
    'ShortDashDotDot',
    'Dot',
    'LongDash',
    'DashDot',
    'LongDashDot',
    'LongDashDotDot'
  ];
  annotation: annotation = [{
    labels: [{
      point: 'max',
      text: 'Max'
    }, {
      point: 'min',
      text: 'Min',
      backgroundColor: 'white'
    }]
  }];

  constructor() { }

  ngOnInit() {
    // console.log(" chartData ", this.data)
    const self = this;
    this.options = {

      rangeSelector: {
        enabled: false,
        selected: 4
      },
      navigator: {
        enabled: false
      },

      chart: {
        renderTo: 'container',
        height: 328,
        zoomType: 'x',
        backgroundColor: '#2F2F2F',
        spacing: [10, 0, 10, 30],
        style: {
          fontFamily: 'Interstate-Regular'
        },
      },
      title: {
        text: ''
      },
      plotOptions: {
        line: {
          marker: {
            enabled: false
          }
        }
      },
      tooltip: {
        backgroundColor: '#fff',
        borderColor: '#FFFFFF',
        borderRadius: 4,
        useHTML: true,
        xDateFormat: '%Y-%m-%d',
        formatter: function () {
          const date = new Date(this.x);
          const mnth = ('0' + (date.getMonth() + 1)).slice(-2);
          const day = ('0' + date.getDate()).slice(-2);
          const hours = ('0' + date.getHours()).slice(-2);
          const minutes = ('0' + date.getMinutes()).slice(-2);
          const formattedDate = [day, mnth, date.getFullYear()].join('/');

          return '<div align="center"><span style="font-size:11px;font-family:Interstate-Regular;color:#616365">' 
          + formattedDate + '</span><br/>' +
            // eslint-disable-next-line max-len
            '<span style="font-size:11px;font-family:Interstate-Regular;color:#616365;margin-bottom:10px">' + hours + ':' + minutes + '</span><br/>' +
            '<span style="font-size:14px;font-family:Interstate-Regular;color:#616365">' + this.series.name + '</span><br/>' +
            '<span style="font-size:14px;font-family:Interstate-Regular;color:#616365">' + this.y + '</span></div>';
        }

      },
      yAxis: [{
        maxPadding: 1,
        endOnTick: false,
        gridLineWidth: 1,
        gridLineColor: '#616365',
        title: null,
        labels: {
          align: 'left',
          style: {
            color: '#fff',
          },
          x: -20
        },
        opposite: false
      }, {
        maxPadding: 1,
        endOnTick: false,
        gridLineWidth: 1,
        gridLineColor: '#616365',
        title: null,
        labels: {
          align: 'left',
          style: {
            color: '#fff',
          },
          x: 30
        },
        opposite: false
      }, {
        maxPadding: 1,
        endOnTick: false,
        gridLineWidth: 1,
        gridLineColor: '#616365',
        title: null,
        labels: {
          align: 'left',
          style: {
            color: '#fff',
          },
          x: 60
        },
        opposite: false
      }],
      xAxis: {
        gridLineWidth: 1,
        gridLineColor: '#616365',
        lineColor: '#616365',
        events: {
          afterSetExtremes: function (e) {
            self.zoomChartData.emit(e);
          }
        },
        title: null,
        tickPixelInterval: 200,
        tickmarkPlacement: 'between',
        tickColor: '#616365',
        type: 'datetime',
        labels: {
          style: {
            color: '#fff'
          },
          align: 'center',
          formatter: function () {
            return '<span class="timeline_label">' + highstock.dateFormat('%b %y', this.value) + '</span>';
          }
        }
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false
      }
    };



    this.chart = new highstock.Chart(this.options);
    for (let i = 0; i < this.data.length; i++) {
      const assetData = this.data[i].measurementPlotData;
      for (let j = 0; j < assetData.length; j++) {
        this.chart.addSeries({
          name: assetData[j].measurementName,
          data: assetData[j].series[0].data,
          color: this.assetColors[i],
          dashStyle: this.lineStyle[j],
          yAxis: j
        }, true);
      }
    }
    this.chart.redraw();
  }

}
