import { Component, OnDestroy, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { LocalCacheService } from '../../local-cache/local-cache.service';
import { UserService } from '../../user/user.service';
import { MsalV3Service } from '../../services/msal-V3.service';

@Component({
  selector: 'nalco-logout',
  templateUrl: './logout.component.html',
})
export class LogoutComponent implements OnInit, OnDestroy {
  constructor(
    private authService: MsalService,
    private cacheService: LocalCacheService,
    private userService: UserService,
    private MsalV3Service:MsalV3Service
  ) {
    // Clear MSAL tokens from cache
    this.MsalV3Service.clearMSALCache();
    window.sessionStorage.clear();
  }

  ngOnInit(): void {
    this.userService.setUserInfo(null);
    this.authService.logout();
  }

  ngOnDestroy(): void {
    this.cacheService.storeData('returnUrl','/');
  }

}
