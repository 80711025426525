<div class="multiple-site-popup position-relative">
    <form>
            <div class="modal-header">
                    <h3 class="modal-title">Site Details</h3>
                    <button type="button" class="close" aria-label="Close" (click)="dismissModal()">
                            <span class="ico-exit-round-edge"></span>
                    </button>
            </div>
            <div class="modal-body">
                    <label>This Project is associated with multiple sites.</label>
                    <div class="table-responsive">
                            <table class="table table-bordered">
                                    <thead>
                                            <tr>
                                                    <th *ngFor="let head of headers" scope="col">
                                                            {{head.name}}
                                                    </th>
                                            </tr>
                                    </thead>
                                    <tbody>
                                            <tr *ngFor="let item of siteDetails" >
                                                    <td>{{item.SiteName ? item.SiteName : "COMMON.NOT_AVAILABLE" | translate}}</td>
                                                    <td>{{item.StateOrProvince ? item.StateOrProvince : "COMMON.NOT_AVAILABLE" | translate}}</td>
                                                    <td>{{item.Country ? item.Country : "COMMON.NOT_AVAILABLE" | translate}}</td>
                                             
                                            </tr>
                                            
                                    </tbody>
                            </table>
                    </div>
            </div>
            <div class="modal-footer">
                    <div>
                            <button type="button" class="btn btn-primary"
                                    (click)="dismissModal()">{{'COMMON.CLOSE' | translate}}</button>
                    </div>

            </div>
    </form>
</div>

