import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import * as Highcharts from 'highcharts';
import { ChartObject, ChartOptions, Options } from 'highcharts';
import { IChartData } from '../../contracts/_contracts';
import { BarChartConfig } from './bar-chart.config';

// const noData = require('highcharts/modules/no-data-to-display');
// noData(Highcharts);

@Component({
  selector: 'nalco-bar-chart',
  templateUrl: './bar-chart.component.html',
  styles: []
})
export class BarChartComponent implements OnInit, OnChanges {

  @Input() data: IChartData;
  @ViewChild('barChartcontainer', { static: true }) barChartcontainer: ElementRef;
  @Input() duration?: number;
  @Input() setInterval?= 25;
  @Input() xDateFormat?: string;
  @Input() xFormatText?: boolean;
  @Input() noDataAvailableMsg? : boolean;
  @Input() yAxisTitle: string;
  @Input() pointPadding: number;
  @Input() tooltipInfo: any;

  chart: ChartObject;
  private chartsOptions: ChartOptions;
  private option: Options;

  private reDraw() {
    if (this.data.xAxis !== undefined) {
      const self = this;

      this.chartsOptions = {
        type: 'column',
        height: 323,
        style: {
          fontFamily: 'Interstate-Regular'
        }
      };

      this.option = {
        exporting: {
          enabled: false
        },
        chart: this.chartsOptions,
        title: BarChartConfig.title,
        lang: {
          noData: self.noDataAvailableMsg ? "No Data Available" : null
        },
        xAxis: {
          tickColor: BarChartConfig.tickColor,
          lineColor: BarChartConfig.lineColor,
          categories: this.data.xAxis.categories,
          labels: {
            style: {
              fontSize: '10px'
            },
            formatter: function () {
              if (self.data.series && (self.data.series[0].name === 'Risk Index' || self.data.series[0].name === 'Stress Index')) {
                return this.value;
              }
              if (self.xFormatText) {
                return this.value;
              }
              if (self.xDateFormat != null) {
                return Highcharts.dateFormat(self.xDateFormat, this.value);
              } else {
                if (self.duration > 60) {
                  return Highcharts.dateFormat('%b/%y', this.value);
                } else {
                  return Highcharts.dateFormat('%e/%b/%y', this.value);
                }
              }
            }
          }
        },
        yAxis: {
          min: this.data.yAxis.min,
          max: this.data.yAxis.max,
          tickInterval: this.data.yAxis.tickInterval,
          title: {
            text: this.yAxisTitle
          },
          stackLabels: {
            enabled: false,
          },
          labels: {
            format: this.data.yAxis.labelFormat,
            style: {
              color: '#616365',
              fontSize: '10px'
            },
          },
          plotLines: this.data.yAxis.plotLines
        },
        tooltip: {
          backgroundColor: this.tooltipInfo.backgroundColor,
          style: {
            color: this.tooltipInfo.color
          },
          borderColor: '000000',
          borderRadius: 5,
          useHTML: true,
          formatter: function () {
            let date;
            let point;
            if (this.series.name === 'Same period last year') {
              let d = new Date(this.x);
              date = d.setFullYear(d.getFullYear() - 1);
              point = Highcharts.dateFormat('%e/%b/%y', date);
            } else if (this.series.name === 'Risk Index' || this.series.name === 'Stress Index') {
              return `<p class="tool-tip-header font-14">${this.point.category}</p>
                <p class="mb-0 primary-white-color text-center">${this.series.name}</p>
                <p class="text-center primary-white-color font-32">${this.y + '%'}</p> 
                <p class="font-14 primary-white-color text-center">(${this.point.class})</p>`;
            } else if (self.xFormatText) {
              point = this.x;
            } else {
              date = new Date(this.x);
              point = Highcharts.dateFormat('%e/%b/%y', date);
            }
            return '<span style="font-size:10px">' + 'Series: ' + this.series.name + '<br/>' +
              'Point: ' + point + '<br/>' +
              'Value: ' + this.y.toFixed(2) + '</span>';
          }
        },
        plotOptions: {
          series: this.noDataAvailableMsg ? BarChartConfig.plotOptions.series: {},
          column: {
            dataLabels: BarChartConfig.plotOptions.column.dataLabels,
            pointPadding: this.pointPadding ? this.pointPadding : 0,
            borderWidth: BarChartConfig.plotOptions.column.borderWidth,
            groupPadding: BarChartConfig.plotOptions.column.groupPadding
          },
        },
        credits: BarChartConfig.credits,
      };
    }
  }

  constructor() {

  }

  ngOnInit() {
    this.reDraw();
  }

  ngAfterViewInit() {
    this.reDraw();
  }

  ngOnChanges(changes) {
    if (this.data.xAxis !== undefined) {
      this.reDraw();
      this.chartsOptions.renderTo = this.barChartcontainer.nativeElement;
      this.option['series'] = changes.data.currentValue.series;
      if (this.chart) {
        this.chart.destroy();
      }

      this.chart = new Highcharts.Chart(this.option);

      setTimeout(() => {
        this.chart.reflow();
      }, 100);
    }
  }
}
