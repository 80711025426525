<nalco-loader [loader]="loading" [transparentBG]="true" [fitToScreen]="true"></nalco-loader>
<div class="maximize-popup">

  <div class="modal-header pl-4 pr-4 mb-0">
    <h3 class="modal-title save-txt-icon heading" translate>OMNI.PERFORMANCE.OMNI_DIAGNOSTICS</h3>
    <button type="button" class="close" aria-label="Close"
      (click)="disablebtn? activeModal.close(): cancelAddEditModal();">
      <img src="../../../../assets/images/modal-close-icon.svg" alt="close" />
    </button>
    <div class="row col-lg-6 no-gutters diagnostic-pills">
      <nalco-filter-pills tile-content [componentIdentifier]="'OmniSilderComponent'" [showFilterText]="false"
        [defaultPrimaryFilters]="defaultPrimaryValue" [primaryFilters]="diagnosticPills" [multiSelect]="false"
        (changeInFilterCriteria)="diagnosticPillChanged($event)"></nalco-filter-pills>
    </div>
  </div>

  <div class="mb-0 delete-content">
    <div *ngIf = "!loading">
      <kendo-grid [data]="getFilterSelected === 1 ? activeAlarms : historicalAlarms">
        <kendo-grid-messages noRecords="No Matches Found">
        </kendo-grid-messages>
        <kendo-grid-column field="AlarmDiscription" title="{{'OMNI.PERFORMANCE.ALARM_DESCRIPTION' | translate}}">
        </kendo-grid-column>
        <kendo-grid-column field="OnDateTime" title="{{'OMNI.PERFORMANCE.ON_DATE_TIME' | translate}}">
        </kendo-grid-column>
        <kendo-grid-column *ngIf="getFilterSelected === 2" field="OffDateTime"
          title="{{'OMNI.PERFORMANCE.OFF_DATE_TIME' | translate}}">
        </kendo-grid-column>
      </kendo-grid>
    </div>
  </div>

  <div class="p-0 ">
  </div>

  <div class="modal-footer mt-0">
    <button type="button" class="btn btn-primary" (click)="cancelAddEditModal()" translate>COMMON.CLOSE</button>
  </div>
</div>