import { enableProdMode } from '@angular/core';
// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  environment: 'STG',
  timeoutSession: 3300,
  timeoutIdle: 300,
  enableProdMode:false,
  enableDebug: true,
  userVoiceConfig: {
    forumID: {
      WQ: '924601',
      WSI: '924601',
      AT: '924601',
      DV: '924601',
      FI: '924601',
      OMNI: '924601',
      WFI: '924601',
      AR : '924601',
      AH : '924601',
      SSD : '924601',
      MYDOC : '924601',
      EDATA: '923587',
      IM: '951541'
    }
  }
  ,
  Unilever_WQId: '4081993',
  Unilever_WQDuration: '365',
  Unilever_OMNIId: '4081993',
  Unilever_OMNIDuration: '365',
  CIP_LINK: 'https://3dtrasarcip-stg.ecolab.com/Default.aspx',
  Envision_EData : 'https://stg-automation.nalco.com/envision/mde/',
  Envision_EData_Workaround : 'https://stg-automation.nalco.com/envision/mde?ZTNkaXMx=&cc=0',
  Envision_ERB : 'https://stg-automation.nalco.com/envision/Reporting/',
  Envision_Alarms : 'https://stg-envision.nalco.com/_layouts/envision/WAAlarmsAndNotifications.aspx',
  Envision_Controller : 'https://stg-envision.nalco.com/_layouts/enVision/ControllerManagement.aspx',
  Envision_Configurator : 'https://stg-3dtrasarcontroller.ecolab.com/Configurator/Home/Index',
  Envision_Inventory : 'https://stg-automation.nalco.com/enVision/Inventory/InventoryList',
  Envision_CustomerDataUpload : 'https://stg-automation.nalco.com/envision/PIDataUpload/Index',
  Envision_NextGenUpgrades : 'https://stg-3dtrasarcontroller.nalco.com/Configurator/USBUpgrade',
  Configurators_CoolingWater : 'https://stg-3dtrasarcontroller.nalco.com/Configurator/Home/Index?ContechID=1,2,8',
  Configurators_SecondaryDesinfection : 'https://stg-3dtrasarcontroller.nalco.com/Configurator/Home/Index?ContechID=9',
  Configurators_CanningAndBottling : 'https://stg-3dtrasarcontroller.nalco.com/Configurator/Home/Index?ContechID=26',
  Configurators_WasteWaterOther : 'https://stg-3dtrasarcontroller.nalco.com/Configurator/Home/Index?ContechID=36',
  E_Commerce : 'https://connect.ecolab.com',
  NEW_CONFIGURATION:'https://ing-rhmi-stag-nxcr-ecolab.centralus.cloudapp.azure.com/?target-state=unprovisioned',
  Water_Process_Intelligence: 'https://tcd-envision-webapp-001-s.azurewebsites.net',
  Software_Upgrade_Utility : "https://oip-plt-suu-app-stg.azurewebsites.net/",
  Alarm_Notification : "https://e3d-s.ecolab.com/#/alarm-user-mgmt",
  Envision_Configurator_China : 'https://stg-3dtrasarcontroller.ecolab.com.cn/Configurator/Home/Index',
  CIP_LINK_China: 'https://3dtrasarcip-stg.ecolab.com.cn/Default.aspx'
};
