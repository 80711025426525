<div class="container">
  <div class="snackbar">
    <div class="text-wrapper">
      <div class="identifier"></div>
      <div class="text-container">
        <div class="header">
          <span>{{modalTitle}}</span>
        </div>
        <div class="content">
          <span>
            {{modalText}}
          </span>
          <span>{{modalData}}</span>
          <span>{{modalContent}}</span>
        </div>
      </div>
    </div>
    <div class="button-container">
      <button class="done-btn" (click)="dismissSnackBar()">{{modalBtn}}</button>
    </div>
  </div>
</div>
