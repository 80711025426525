import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ControllerManagementService } from '../../../controller-management/_services/controller-management.service';
import { LocalCacheService } from '../../../core/local-cache/local-cache.service';
import { Subscription } from 'rxjs/Subscription';
import { ErrorHandling } from '../error-handling/error-handling';
import { Router } from '@angular/router';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { XdComponentState } from '../xd-container/xd-component';


@Component({
  selector: 'nalco-hardware-details',
  templateUrl: './hardware-details.component.html',
  styleUrls: ['./hardware-details.component.scss']
})
export class HardwareDetailsComponent extends XdComponentState implements OnInit {
  @Input() SerialNo = '';
  @Input() LastDataReceived;
  @Input() SiteCpId = '';
  @Input() ControllerCpId = '';
  siteCpId: any;
  isLoading: boolean =true;
  subscription: Subscription;
  errorHandling = new ErrorHandling(this.modalService, this.translate );
  errorInfo: any;
  isError = false;
  configurationList =[];
  configurationList2;
  viewData : GridDataResult;
  documentByteStream: any;
  siteName : string;
  constructor(private translate: TranslateService,
     public activeModal: NgbActiveModal,
     public controllerManagementService : ControllerManagementService,
     private localCacheService: LocalCacheService,
     private modalService: NgbModal,
     private router: Router) {super()
  }
  ngOnInit() {
     this.getHardwareDetails(); 
  }

  getHardwareDetails() {
    this.isLoading = true;
    this.controllerManagementService.getHardwareDetails(this.SiteCpId,this.ControllerCpId).subscribe(response => {
      if (response) {
        this.configurationList.push(response);
        this.isLoading = false;
      }
    }, error => {
      this.isLoading = false;
      this.errorInfo = error;
      if (500 <= error.status && error.status < 600) {
      } else if (error.status === 417) {
        this.router.navigate(['/access-denied']);
      } else if (error.status === 404) {
        this.router.navigate(['/**']);
      } else {
        this.errorHandling.showErrorPopup(error);
      }
    });
  }

  }

