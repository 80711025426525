import { Component, HostListener, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import {
    ActivatedRoute,
    Router
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AppEnvConfig } from '../../../app.env.config';
import { AppDynamicsService } from '../../../core/analytics/app-dynamics.service';
import { INavigationMenu } from '../../contracts/_contracts';
import { Navigation } from '../../models/Navigation';
import { User } from '../../models/User';
import { SharedService } from '../../services/shared-services.service';
import { NavigationHandlerService } from './../../../core/nav/navigation-handler.service';
import { FilterNavigation } from './../../../core/pipes/filter-navigation.pipe';
import { UserService } from './../../../core/user/user.service';
import { Response } from './navigation.json';

@Component({
    selector: 'nalco-navigation',
    templateUrl: './navigation.component.html'
})
export class NavigationComponent implements OnInit {

    user: User;
    isNavigationDisabled = false;
    isExternalUser = false;
    navigationMenu: Navigation;
    useExternalNavigation = true;
    useLocalNavigation = true;
    private subscriptions: Subscription[] = [];
    leftNavigationItems: INavigationMenu[];
    isMobile: Boolean = false;
    digitalSupportHTML: String = '<p>Ecolab Digital Support: (866) 851-8670</p';

    constructor(private navService: NavigationHandlerService,
        private router: Router,
        private config: AppEnvConfig,
        private translate: TranslateService,
        private appDynamicsService: AppDynamicsService,
        private sharedService: SharedService, private userService: UserService,
        private activatedRoute: ActivatedRoute,
        private meta: Meta

    ) {
        navService.navToggle$.subscribe(state => {
            this.isNavigationDisabled = state;
        });
        this.useExternalNavigation = false; //  environment.useExternalNavigation;
        this.useLocalNavigation = true; // environment.useLocalNavigation;
    }

    ngOnInit() {
        this.isMobile = (/android|webos|iphone|ipad|ipod|blackberry|windows phone/).test(navigator.userAgent.toLowerCase());
        // this.sharedService.getUsersNavigationMenu(this.user.profile.name)
        //     .subscribe(response => {
        //         if (this.useLocalNavigation) {
        //             this.navigationMenu = Response;
        //         } else {
        //             this.navigationMenu = response;
        //         }
        //     });

        // this.authenticationGuard.canActivate(this.activatedRoute.snapshot, this.router.routerState.snapshot);
        this.getNavigationMenu();
        this.subscriptions.push(this.navService.updateLeftNav$.subscribe(async (update) => {
            this.getNavigationMenu();
        }));

    }
    menuItemClicked(event: any) {

        let navigationLink = '';
        if (this.useExternalNavigation) {
            navigationLink = event.link;
        } else {
            if (event.link.substr(event.link.indexOf('#') > 2)) {
                navigationLink = event.link.substr(event.link.indexOf('#') + 1);
            }
        }
        if (navigationLink.indexOf('settings') > -1) {

            if (event.siteId === undefined || event.siteId === null) {

                return false;
            } else if (event.siteId !== '') {

                this.routeToComponent(navigationLink, { queryParams: { siteId: event.siteId } });
            } else {

                this.routeToComponent(navigationLink);
            }
        } else if (navigationLink.indexOf('feedback') > -1) {
        } else {
            event.siteId = null;
            this.routeToComponent(navigationLink);
        }


    }

    desktopToggle() {
        this.meta.removeTag('name="viewport"');
        this.meta.addTag({ name: 'viewport', content: 'width=1600, , initial-scale=0.1' }, true);
    }


    private routeToComponent(navigationLink: string, queryParams?: any) {

        if (this.useExternalNavigation) {
            if (queryParams !== undefined) {
                window.location.href = navigationLink + '?siteId=' + queryParams.queryParams.siteId;
            } else {
                window.location.href = navigationLink;
            }
        } else {

            this.router.navigate([navigationLink], queryParams);
        }
    }

    filterItemsOfType() {
        if (this.navigationMenu !== undefined) {
            if (this.navigationMenu.leftNavigation !== undefined) {
                this.leftNavigationItems = this.navigationMenu.leftNavigation.filter(x => x.hover !== 'Feedback');
                // hide left nav for E3D China
                const userSettings = this.userService.getCurrentSettings();
                const isE3DChina = (this.config.getEnv("IsE3DChina") === "true") ? true : false;
                if (isE3DChina && (!userSettings.IsInternalUser)) {
                    this.leftNavigationItems = this.navigationMenu.leftNavigation.filter(x => x.name !== 'Training');
                }

            }
        }
    }

    filterByUserRole(role, navigationDetail, linksToExclude: string[]) {
        const userSettings = this.userService.getCurrentSettings();
        const isE3DChina = (this.config.getEnv("IsE3DChina") === "true") ? true : false;
        let navigationMenu = [];
        if (role !== null) {
            const pipe = new FilterNavigation();
            navigationMenu = pipe.transform(role, navigationDetail.leftNavigation, userSettings.IsInternalUser, isE3DChina, linksToExclude);
            return navigationMenu;
        } else {
            return navigationMenu;
        }
    }

    getNavigationMenu() {

        const role = this.userService.getUserRole();
        let userPermissions = [];
        if (role !== null) {
            role.forEach(function (s) {
                userPermissions.push(s.PermissionId.toString());
            })
        }
        let fullMenu = JSON.parse(JSON.stringify(Response));
        this.navigationMenu = fullMenu;
        const linksToExclude = this.getLinksToExclude();
        this.navigationMenu.leftNavigation = this.filterByUserRole(userPermissions, fullMenu, linksToExclude);
        this.filterItemsOfType();
    }

    /**
   * @description Gets list of links to exclude
   * @returns string of links to exclude
   */
  getLinksToExclude(): string[] {
    const isNewFiRefreshEnabled: boolean = this.config.getEnv('isNewFiRefreshEnabled') === "true";
    const excludeLinks = isNewFiRefreshEnabled ? ['/financial-impact/admin'] : [];

    return excludeLinks;
  }

    /************ Analytics Listener ************/
    @HostListener('click', ['$event'])
    onClick(event) {
        const adrumEvent = event;
        const adrumService = 'Home';
        const adrumComponent = this.constructor.name.toString();
        this.appDynamicsService.adrumClickEvent(adrumEvent, adrumService, adrumComponent);
    }
}
