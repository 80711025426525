import { Component, OnInit, ViewEncapsulation,Input } from '@angular/core';
import { IDropdown, IDropdownOption } from '../dropdown/dropdown-model';
import { DateRangeService } from '../../services/date-range.service';
import { DurationOption } from '../../models/DurationOption';
import { TranslateService } from '@ngx-translate/core';
import { DurationSlotService } from '../../../scale-guard-index/_services/duration-slot.service';


@Component({
  selector: 'nalco-date-range-campaign-dropdown',
  templateUrl: './date-range-campaign-dropdown.component.html',
  styleUrls: ['./date-range-campaign-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DateRangeCampaignDropdownComponent implements OnInit {

  @Input() showOneDayDuration?: boolean = false; 
  @Input() isDisabled = false; // Defines the enable/disable value of the dropdown
  @Input() iOptions : IDropdownOption[];
  private duration: DurationOption;
  public value: string;
  dateRanges: IDropdown;   

  constructor(private dateService: DurationSlotService,
     private translate: TranslateService,
     private durationSlotService: DurationSlotService) {
  }

  ngOnInit() {

    //check if IOption is empty and add no -campaign if empty
    if (this.iOptions.length == 0) {
      this.iOptions.push({ text: "No - Campaign", value: '0_0_0' });
    }

    this.dateRanges =
    {
      label: this.translate.instant('COMMON.SORT_OPTIONS.VIEW_CAMPAIGN_BY'),
      name: 'durationDropDown',
      options: this.iOptions
    }
        
    //select latest value from dropdown
    this.value = this.iOptions[0].value;
    this.selectionChanged(this.iOptions[0]);
  }
  
  selectionChanged(data) {
    let dataArray = data.value.split('_');
    this.dateService.setDurationSelected({ Number: Number(dataArray[0]), StartDate: dataArray[1], EndDate: dataArray[2] });
    this.durationSlotService.setDurationSlotSelected(null);
  }

}
