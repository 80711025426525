import * as moment from 'moment';
import * as contracts from '../_contracts/IOperationalSchedule';
export class OperationalSchedule implements contracts.IOperationalSchedule {
    ScheduleTypeId?: string = '';
    SuppressAlarmsWhenOffline?: boolean = false;
    SeasonalDuration?: SeasonalDuration = new SeasonalDuration();
    OperationalSpans?: Array<OperationalSpan> = [];

    constructor(opSchedule?: contracts.IOperationalSchedule) {
        if (opSchedule) {
            this.ScheduleTypeId = opSchedule.ScheduleTypeId;
            this.SuppressAlarmsWhenOffline = opSchedule.SuppressAlarmsWhenOffline;
            this.SeasonalDuration = new SeasonalDuration(opSchedule.SeasonalDuration);
            if (opSchedule.OperationalSpans && opSchedule.OperationalSpans.length > 0) {
                opSchedule.OperationalSpans.forEach(span => {
                    this.OperationalSpans.push(new OperationalSpan(span));
                });
            }
        } else {
            Object.assign(this, opSchedule);
            this.OperationalSpans.push(new OperationalSpan());
        }
    }
}
export class SeasonalDuration implements contracts.ISeasonalDuration {
    IsRecurring?: boolean = null;
    StartDate?: string = '';
    EndDate?: string = '';
    //UI computed
    UIStartDate?: Date = null;
    UIEndDate?: Date = null;

    constructor(seasonalDuration?: contracts.ISeasonalDuration) {
        Object.assign(this, seasonalDuration);
        if (seasonalDuration) {
            if (this.StartDate) {
                this.UIStartDate = moment(this.StartDate).toDate();
            }
            if (this.EndDate) {
                this.UIEndDate = moment(this.EndDate).toDate()
            }
        }
    }
}
export class OperationalSpan implements contracts.IOperationalSpan {
    DayOfWeek?: string = '';
    StartTime?: string = null;
    EndTime?: string = null;
    //UI computed
    UIStartTime?: Date;
    UIEndTime?: Date;
    TimeError?: string = null;
    id?: number = 0;

    constructor(opSpan?: contracts.IOperationalSpan) {
        Object.assign(this, opSpan);
        this.UIStartTime = this.StartTime ? moment(this.StartTime, 'HH:mm:ss').toDate() : null;
        this.UIEndTime = this.EndTime ? moment(this.EndTime, 'HH:mm:ss').toDate() : null;
    }
}
export class OfflineConfigurationDependent implements contracts.IOfflineConfigurationDependent {
    DependentAssetMeasurementInstance?: MeasurementInstanceLite = null;
    IsDeleted?: boolean = false;
    OfflineConfigurationId?: number = 0;
    DependentAssetCpId?: number = 0;
    ModifiedBy?: number = 0;
    ModifiedOn?: string = '';
    constructor(offlineConfigDependent?: contracts.IOfflineConfigurationDependent) {
        Object.assign(this, offlineConfigDependent);
    }
}
export class OfflineConfiguration implements contracts.IOfflineConfiguration {
    IsMasterAsset?: boolean = null;
    AssetCpId?: number = null;
    ThresholdType?: number = null;
    ThresholdValue?: number = null;
    DisplayUnit?: string = '';
    PortOfflineIndicatingMI?: MeasurementInstanceLite = new MeasurementInstanceLite();
    AssetMeasurementInstance?: MeasurementInstanceLite = new MeasurementInstanceLite();
    DependentAssets?: Array<OfflineConfigurationDependent> = [];
    OfflineConfigurationId?: number = 0;
    PortMeasurementInstanceId?: number = 0;
    ModifiedBy?: number = 0;
    ModifiedOn?: string = '';
    IsDeleted?: boolean = null;
    IsServiceEnabled?: boolean = null;
    IsForHistoricalData?: boolean = null;
    //UI computed
    inputUnit?: string = '';
    ThresholdValueUI?: number = null;
    constructor(offlineConfig?: contracts.IOfflineConfiguration) {
        Object.assign(this, offlineConfig);
    }
}
export class MeasurementInstanceLite implements contracts.IMeasurementInstanceLite {
    Measurement?: MeasurementLite = new MeasurementLite();
    MeasurementInstanceId?: number = null;
    ContextPointId?: number = 0;
    MeasurementSource?: string = '';
    StuiTypeId?: number = 0;
    StuiId?: number = 0;
    IncomingInstance?: string = '';
    InstanceId?: number = 0;
    DisplayName?: string = '';
    MeasurementId?: number = 0;
    MeasurementName?: string = '';
    ContextPointTypeId?: number = 0;
    ContextPointSubTypeId?: number = 0;
    SourceId?: number = 0;
    IsCalculated?: boolean;
    CreatedBy?: number = 0;
    constructor(msrInstance?: contracts.IMeasurementInstanceLite) {
        Object.assign(this, msrInstance);
    }
}
export class MeasurementLite implements contracts.IMeasurementLite {
    MeasurementId?: number = 0;
    MeasurementName?: string = '';
    NalcoNumericsUnitOrSpecies?: string = '';
    StuiTypeId?: number = 0;
    NeedsMeasurementContext?: boolean;
}
