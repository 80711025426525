import { IAccountCSVExports } from "../_contracts/IAccountCSVExports";

export class AccountCSVExports implements IAccountCSVExports{
  CorporateAccountName: string;
  SoldToShipTo: string;
  SiteName: string;
  SiteID: number;
  CustomerAreaType: string;
  CustomerAreaName: string;
  CustomerSubAreaType: string;
  CustomerSubAreaName: string;
  SystemType: string;
  SystemName: string;
  AssetType: string;
  AssetName: string;
  SubAssetType: string;
  SubAssetName: string;
  ConfigurationStatus: string;
  OperationalStatus: string;
  ManagedStatus: string;
  AssetPortName: string;
  DataSourceMapped: string;
  MeasurementName: string;
  Units: string;
  LimitType: string;
  Target: number;
  LowerLimit: number;
  HighLimit: number;
  ControllerDataSourceMapped : string;
  EDataDataSourceMapped : string;
  LimsDataSourceMapped : string;
  waterQualityEnable  : string;
  UniqueIdentifier : string;
  LimitEffectiveDate2 : string;
  LastMeasurementDate : string;
  MeasurementValue : string;

  constructor(CSVdata: IAccountCSVExports) {
    this.CorporateAccountName = CSVdata.CorporateAccountName;
    this.SoldToShipTo = CSVdata.SoldToShipTo;
    this.SiteName = CSVdata.SiteName;
    this.SiteID = CSVdata.SiteID;
    this.CustomerAreaType = CSVdata.CustomerAreaType;
    this.CustomerAreaName = CSVdata.CustomerAreaName;
    this.CustomerSubAreaType = CSVdata.CustomerSubAreaType;
    this.CustomerSubAreaName = CSVdata.CustomerSubAreaName;
    this.SystemType = CSVdata.SystemType;
    this.SystemName = CSVdata.SystemName;
    this.AssetType = CSVdata.AssetType;
    this.AssetName = CSVdata.AssetName;
    this.SubAssetType = CSVdata.SubAssetType;
    this.SubAssetName = CSVdata.SubAssetName;
    this.ConfigurationStatus = CSVdata.ConfigurationStatus;
    this.OperationalStatus = CSVdata.OperationalStatus;
    this.ManagedStatus = CSVdata.ManagedStatus;
    this.AssetPortName = CSVdata.AssetPortName;
    this.ControllerDataSourceMapped = CSVdata.ControllerDataSourceMapped;
    this.EDataDataSourceMapped = CSVdata.EDataDataSourceMapped;
    this.LimsDataSourceMapped = CSVdata.LimsDataSourceMapped;
    this.waterQualityEnable = CSVdata.waterQualityEnable;
    this.UniqueIdentifier = CSVdata.UniqueIdentifier
    this.DataSourceMapped = CSVdata.DataSourceMapped;
    this.MeasurementName = CSVdata.MeasurementName;
    this.Units = CSVdata.Units;
    this.LimitType = CSVdata.LimitType;
    this.Target = CSVdata.Target;
    this.LowerLimit = CSVdata.LowerLimit;
    this.HighLimit = CSVdata.HighLimit;
    this.LimitEffectiveDate2 = CSVdata.LimitEffectiveDate2;
    this.LastMeasurementDate = CSVdata.LastMeasurementDate;
    this.MeasurementValue = CSVdata.MeasurementValue;
  }
}
