export const BarChartConfig = {
    chart: {
        type: 'column',
        height: 644,
        style: {
            fontFamily: 'Interstate-Regular'
        }
    },
    title: {
        text: ''
    },
    tickColor: '#e2e2e2',
    lineColor: '#e2e2e2',
    labels: {
        style: {
            color: '#616365',
            fontSize: '10px'
        },
    },
    tooltip: {
        backgroundColor: 'black',
        borderColor: '000000',
        borderRadius: 5,
        useHTML: true,
        headerFormat: '<p class="tool-tip-header">{point.x}</p>',
        pointFormat: '<span class="tooltip-content">{point.y} %</span>'
    },
    plotOptions: {
        series: {
            events: {
                hide: function () {
                    let inVisibleSeries = [];
                    inVisibleSeries = this.chart.series.filter((x) => x.visible == false);
                    if(inVisibleSeries.length == this.chart.series.length) {
                     this.chart.options.lang.noData = 'Please check at least one selection to display bar graph.';
                       this.chart.yAxis[0].update({
                        visible: false
                      });
                      this.chart.update();
                    }
                },
                show: function () {
                    let visibleSeries = [];
                    visibleSeries = this.chart.series.filter((x) => x.visible == true);
                   if(visibleSeries.length > 0) {
                    this.chart.yAxis[0].update({
                        visible: true
                      })
                   }
                }
            }
        },
        column: {
            dataLabels: {
                enabled: false,
            },
            pointPadding: 0,
            borderWidth: 0,
            groupPadding: 0.25
        },
    },
    credits: {
        enabled: false
    }
};
