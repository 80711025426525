import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'nalco-delete-confirmation',
  templateUrl: './delete-confirmation.component.html'

})
export class DeleteConfirmationComponent implements OnInit {

  @Input() modalText: string; // Defines the title of the modal
  @Input() modcontent: string; // Defines the content of the modal
  @Input() disablebtn: boolean; // Defines the enable/disable of the button
  @Output('cancelModal') cancelModal = new EventEmitter<any>(); // Emits the cancel event of the modal
  @Output() deleteModal = new EventEmitter<any>(); // Emits the delete event of the modal
  @Input() localDriverBtn: boolean;
  @Input() showModalBody?: boolean = true;
  public closeIcon: boolean;
  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
  }
  cancelAddEditModal() {
    if (this.disablebtn) {
      this.cancelModal.emit({});
      this.activeModal.close();
    }
    else {
      this.activeModal.close();
    }
  }

  public delete() {
    this.deleteModal.emit({});
    this.activeModal.close();

  }

}


