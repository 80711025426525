import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule, TranslateParser } from '@ngx-translate/core';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from '../shared/shared.module';
import { SharedRoService } from './services/shared-ro.service';
import { SharedRoConfig } from './services/shared-ro.service.config';
import { RoDashboardComponent } from './components/ro-dashboard.component';
import { SystemComponent } from './components/system/system.component';
import { WaterChemistryComponent } from './components/water-chemistry/water-chemistry.component';
import { DosageAndRecommendationComponent } from './components/dosage-and-recommendation/dosage-and-recommendation.component';
import { OperatingParameterComponent } from './components/operating-parameter/operating-parameter.component';
import { ChargeBalanceComponent } from './components/charge-balance/charge-balance.component';
import { DecimalPipe } from '../shared/pipes/decimal.pipe';
import { createTranslateLoader } from '../app.module';
import { HttpClient } from '@angular/common/http';
import { TranslateICUParser } from '@pawelalikowski/ngx-translate-parser-plural-select';

@NgModule({
  declarations: [RoDashboardComponent, SystemComponent, WaterChemistryComponent, DosageAndRecommendationComponent, OperatingParameterComponent,ChargeBalanceComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    SharedModule.forRoot(),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      parser: {
        provide: TranslateParser,
        useClass: TranslateICUParser
      }
    }),
    NgbModule
  ],
  // entryComponents: [RoDashboardComponent, SystemComponent, WaterChemistryComponent, DosageAndRecommendationComponent, OperatingParameterComponent,ChargeBalanceComponent],
  exports: [RoDashboardComponent],
  providers: [NgbActiveModal,
    SharedRoService,
    DecimalPipe,
    SharedRoConfig]
})
export class SharedRoModule {

  static forRoot(): ModuleWithProviders<SharedRoModule> {
    return {
      ngModule: SharedRoModule,
      providers: [],
    };
  }
 }
