import { Component, OnInit, ViewEncapsulation, Input, OnChanges, AfterViewInit, SimpleChange } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { ChartOptions, Options, AxisOptions, LoadingOptions, ChartObject } from 'highcharts';
import { IChartData } from '../../contracts/IChartData';
import { BarLineChartConfig } from './bar-line-chart.config';

@Component({
  selector: 'nalco-bar-line-chart',
  templateUrl: './bar-line-chart.component.html',
  encapsulation: ViewEncapsulation.None
})
export class BarLineChartComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() data: IChartData; // To get the data for the charts
  chartOptions: any;
  chartSeries: any = [];
  chart: Chart;

  constructor() {
  }

  ngOnInit() {
  }
  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
     if (changes.data.firstChange === false) {
        for (let i = 0; i < changes.data.currentValue.series.length; i++) {
        this.chart.ref.series[i].update(changes.data.currentValue.series[i]);
        this.chart.ref.xAxis[0].setCategories(changes.data.currentValue.xAxis.categories);
      }

    }
  }
  ngAfterViewInit() {
    this.reDrawCharts();
  }
  private reDrawCharts() {
    this.assignSeriesType();
    this.chartOptions = {
      chart: BarLineChartConfig.chart,
      title: BarLineChartConfig.title,
      tooltip: BarLineChartConfig.tooltip,
      xAxis: [{
        categories: this.data.xAxis.categories,
        crosshair: false
      }],
      plotOptions: BarLineChartConfig.plotOptions,
      credits: BarLineChartConfig.credits,
      yAxis: BarLineChartConfig.yAxis,
      legend: BarLineChartConfig.legend,
      series: this.chartSeries
    };
    this.chart = new Chart(this.chartOptions);
  }

  private assignSeriesType() {

    for (let i = 0; i < this.data.series.length; i++) {
      if (this.data.series[i].name === 'projectTCO') {
        const columnSeries = {
          name: this.data.series[i].name,
          data: this.data.series[i].data,
          type: 'column',
          color: BarLineChartConfig.projectTCO,
          pointWidth: BarLineChartConfig.pointWidth,
          yAxis: 0
        };
        this.chartSeries.push(columnSeries);
      }
      if (this.data.series[i].name === 'continuousImprovementTCO') {
        const columnSeries = {
          name: this.data.series[i].name,
          data: this.data.series[i].data,
          type: 'column',
          color: BarLineChartConfig.projectTCO,
          yAxis: 0,
          pointWidth: BarLineChartConfig.pointWidths
        };
        this.chartSeries.push(columnSeries);
      }
      if (this.data.series[i].name === 'innovationTCO') {
        const columnSeries = {
          name: this.data.series[i].name,
          data: this.data.series[i].data,
          type: 'column',
          color: BarLineChartConfig.innovation,
          pointWidth: BarLineChartConfig.pointWidths,
          yAxis: 0
        };
        this.chartSeries.push(columnSeries);
      }
      if (this.data.series[i].name === 'projectCount') {
        const lineSeries = {
          name: this.data.series[i].name,
          data: this.data.series[i].data,
          type: 'line',
          yAxis: 1
        };
        this.chartSeries.push(lineSeries);
      }
    }
    return this.chartSeries;
  }
}
