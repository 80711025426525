<div class="config-popup">
    <div class="modal-header">
      <h4 class="modal-title" translate>Data Download</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
        <img src="../../../../assets/images/modal-close-icon.svg" alt="close" />
      </button>
    </div>
    <div class="mb-0 delete-content">
      <div class="account-name modal-header">
        <span> {{'CONTROLLER_MANAGEMENT.SERIAL_NO' | translate}} : {{SerialNo}} </span> <br/>
        <span> {{'CONTROLLER_MANAGEMENT.LAST_FILE_RECEIVED' | translate}} : {{LastDataReceived | date : "yyyy-MM-dd h:mm:ss"}}</span>
      </div>
     </div>
      <div class="select-filter ">
      <div class="filter-accordion">
        <div class="selectFilterContent p-3">
          <form style="width: 67rem !important; margin-top: 1rem;">
            <div class="row form-inline mx-auto">
              <div class="col-lg-6 col-sm-12 custom">
                <!-- <span class="download-class">Download For</span> -->
                <custom-date-range-dropdown (updateDate)='updateDates($event)' [service]="'CM'"
                [isCaching]=false [customLabelValue]="'CONTROLLER_MANAGEMENT.DOWNLOAD_FOR'" [defaultSelection]="'4'">
                </custom-date-range-dropdown>
              </div>
            </div>
            <div class="form-group row">
           
              <div class="col-sm-9 position-relative">
                <label  translate>CONTROLLER_MANAGEMENT.SELECT_DATA</label> <span></span>
                <label translate class="checkbox-labels" ><input type="checkbox"   class="position-relative checkBoxPosition"
                  [checked]="true" (change)="onItemSelect($event, 'Measurements')" ><span></span>
                 CONTROLLER_MANAGEMENT.MEASUREMENTS</label><span></span>
                 <label translate class="checkbox-labels"><input type="checkbox"   
                  class="position-relative checkBoxPosition" (change)="onItemSelect($event, 'Alarms')"><span></span>
                  CONTROLLER_MANAGEMENT.ALARMS</label><span></span>
                  <label translate class="checkbox-labels"><input type="checkbox"   
                    class="position-relative checkBoxPosition" (change)="onItemSelect($event, 'Events')"><span></span>
                  CONTROLLER_MANAGEMENT.EVENTS</label>
                  <label translate class="checkbox-labels"><input type="checkbox"   
                    class="position-relative checkBoxPosition" (change)="onItemSelect($event, '24HrsParameters')"><span></span>
                  CONTROLLER_MANAGEMENT.24HRS_PARAMETERS</label>
                </div>
            </div>
            <nalco-loader [loader]="dataDownloading" [transparentBG]="true" [fitToScreen]="true"></nalco-loader>
            <button type="button"  (click)="GenerateExcelFile()"  class="btn btn-primary button-class">
              {{'CONTROLLER_MANAGEMENT.GENERATE'| translate}}</button>
          </form>
        </div>
    </div>
  </div>
</div>
  