import { Component, OnInit, Input, HostListener, ElementRef, ViewChild, OnChanges, Output, EventEmitter,  NgZone, Inject, SimpleChanges} from '@angular/core';
import {
  MarkerTypeId, IMapOptions, IBox, ILatLong, IMarkerEvent,
  IPoint, MapMarkerDirective, Marker, IMarkerOptions, MapComponent as MapCompPlg, MapService
} from '../../../angular-maps/public_api';
@Component({
  selector: 'nalco-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnChanges {

  private _mapmarkers: Array<IMarkerOptions>;

  @Input() set markers(markers: Array<IMarkerOptions>) {
    this._mapmarkers = markers;
  }
  get markers() {
    return this._mapmarkers;
  }

  @Input() kpiType?: string;
  @Input() infoBoxData: any = [];
  @Input() indicatorType: any = [];
  @Output('getSiteData') getSiteData = new EventEmitter<any>();
  @ViewChild('infoBox',{read: ElementRef,static: false}) public infoBox: ElementRef;
  @Input() infoBoxLink: string;
  @Input() kpiOne: string;
  @Input() kpiTwo: string;
  @Input() kpiThree: string;
  @Input() kpiFour: string;
  @Input() noDataText: string;
  @Input() servicePlanType: string;

  isInfoBoxDataloading = true;
  kpiTypeProp = 'legionellaGrowthStatus';

  MarkerTypeId = MarkerTypeId;

  /*x-marker

  */
  _mapclustering: boolean = false;
  _mapgridSize: number = 150;
  _mapvisible: boolean = true;

  public markerData: Array<any> = new Array<any>();
  latitude = new Array<number>();
  longitude = new Array<number>();
  latLongData = new Array<any>();
  _mapoptions: IMapOptions = {
    disableBirdseye: true,
    disableStreetside: false,
    navigationBarMode: 1,
    zoom: 2.5,
    mapTypeId: 7
  };
  _mapbox: IBox;
  _mapcenter: ILatLong;
  centerLatitude: number;
  centerLongitude: number;
  _mapinfoBoxBeakPosition: number;
  _mapinfoBoxTop: number;
  _mapinfoBoxLeft: number;
  _mapcardLocation: ILatLong;
  _mapshowInfoBox = false;
  _mapinfoBoxMarker: MapMarkerDirective | Marker;
  _mapinfoBoxData: any;
  


  constructor(){
  }
  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.kpiType.toString() === '1') {
      this.kpiTypeProp = 'legionellaGrowthStatus';
    } else if (this.kpiType.toString() === '2') {
      this.kpiTypeProp = 'complianceStatus';
    } else if (this.kpiType.toString() === '3') {
      this.kpiTypeProp = 'performanceStatus';
    } else if (this.kpiType.toString() === '4') {
      this.kpiTypeProp = 'legionellaTestStatus';
    }
    this.setMarkerIcons();
  }

  @HostListener('document:click', ['$event']) clickedOutside($event) {
    if (!$event.hasOwnProperty('primitive')) {
      this.HideCard();
    }
  }

  private setMarkerIcons() {
    let details;
    for (let index = 0, len = this.markers.length; index < len; index++) {
      details = this.markers[index]?.metadata?.get("site");
      if (details[this.kpiTypeProp]) {
        this.markers[index].icon = `/assets/images/3d-${details[this.kpiTypeProp]}.png`;
      }
    }
    this.setIbox();
  }

  private setIbox() {
    if (this.latitude.length > 1) {
      this._mapbox = {
        maxLatitude: Math.max(...this.latitude),
        maxLongitude: Math.max(...this.longitude),
        minLatitude: Math.min(...this.latitude),
        minLongitude: Math.min(...this.longitude),
      };
    } else {
      const offsetLatLong = 1;
      if (this.latitude.length !== 0) {
        this._mapbox = {
          maxLatitude: (this.latitude[0] + offsetLatLong),
          maxLongitude: (this.longitude[0] + offsetLatLong),
          minLatitude: (this.latitude[0] - offsetLatLong),
          minLongitude: (this.longitude[0] - offsetLatLong),
        };
      }
    }
  }

  getRouteURL(siteId) {
    if (this.kpiType === '2') {
      return './' + siteId + '/sitedetails/compliance';
    } else if (this.kpiType === '3') {
      return './' + siteId + '/sitedetails/performance';
    } else if (this.kpiType === '4') {
      return './' + siteId + '/sitedetails/legionellaTest';
    }
    return './' + siteId + '/sitedetails/legionellaGrowthRisk';
  }

  /*************/
  /**
   * Hides the card currently displaying.
   * @private
   * @method
   */
  HideCard(): void {
    this._mapshowInfoBox = false;
    this._mapcardLocation = null;
  }

  /**
   * Repoisitons the card to a new anchor point.
   * @param {IPoint} anchor - New anchor point.
   * @private
   * @method
   */
  PositionCard(anchor: IPoint): void {
    // default positioning
    const div: HTMLDivElement = this.infoBox?.nativeElement;
    this._mapinfoBoxLeft = anchor.x + 12 - div.clientWidth / 2;
    this._mapinfoBoxTop = anchor.y - 185; // div.clientHeight;
    this._mapinfoBoxBeakPosition = 0;

  }

  /**
   * Repositions the card in response to a resize event.
   * @param {Event} e - Event arguments.
   * @private
   * @method
   * @memberOf {GroupMapComponent}
   */
  @HostListener('window:resize', ['$event'])
  RepositionCard(e: any): void {
    if (!this._mapshowInfoBox) { return; }
    if (this._mapcardLocation != null) {
      this.HideCard();
    } else {
      if (e != null) {
        this.HideCard();
        return;
      }
      this._mapinfoBoxMarker['LocationToPixel']().then((point: IPoint) => {
        if (point == null) {
          this.HideCard();
        } else {
          this.PositionCard(point);
        }
      });
    }
  }

  /**
   * Shows the card.
   * @param {IMarkerEvent} e - The marker event used to position the card.
   * @param {any} marker- The marker to display on the card.
   * @private
   * @method
   * @memberOf {GroupMapComponent}
   */
  ShowCard(e: IMarkerEvent): void {
    const markerDetails = e.Marker.Metadata;
    const marker = markerDetails.get('site');
    this._mapinfoBoxData = marker;
    this._mapshowInfoBox = true;
    this._mapinfoBoxMarker = e.Marker as MapMarkerDirective;
    if (e.Pixels != null) {
      this.PositionCard(e.Pixels);
      if (e.Location) { this._mapcardLocation = e.Location; }
    } else {
      e.Marker['LocationToPixel']().then((p: IPoint) => {
        if (p == null) { p = { x: e.Click['point'].x, y: e.Click['point'].y }; }
        this.PositionCard(p);
      });
    }
  }
}
