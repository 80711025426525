import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { selectSubChildOption } from '../app.model';
import { AppEnvConfig } from '../app.env.config';
import { SearchServiceConfig } from './globalsearch.component_service.config';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  inputVal: string;
  private configEnv: any;

  constructor(
    private http: HttpClient,
    private config: AppEnvConfig,
    private SearchServiceConfig: SearchServiceConfig,
    ) { 
      this.configEnv = SearchServiceConfig.getEnvironment();
      this.configEnv.apiServerUrl = config.getEnv('apiServerUrl');
      this.inputVal = "";
  }
  /**
   * @description gets the data fetched from the api
   * @param val Search Query enetered by the user
   * @param siteCount Number of results returned
   * @returns The data fetched from the api
   */
  getData(val: string, siteCount: number) : Observable<selectSubChildOption[]> {
    let url = `${this.configEnv.apiUrl.getSearchQuery}`;
    const formattedApiUrl = url.replace(`{0}`, val).replace(`{1}`, siteCount.toString());
    return this.http.get<selectSubChildOption[]>(`${this.configEnv.apiServerUrl}${formattedApiUrl}`);
  }
}