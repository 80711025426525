import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RecipientInfo } from '../../../shared-my-documents/_models/ReportDocument';

@Component({
  selector: 'nalco-recipient-details-popup',
  templateUrl: './recipient-details-popup.component.html',
  styleUrls: ['./recipient-details-popup.component.scss']
})
export class RecipientDetailsPopupComponent implements OnInit {

  @Input() modalText: string; // Defines the title of the modal
  @Input() closeIcon ? = false; // Defines the close icon
  @Input() recipients?: RecipientInfo[]; // Defines the Recipients
  @Output() cancelModal = new EventEmitter<any>(); // Emits the cancel event of the modal
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {}

  closePopupModal() {
    this.cancelModal.emit(false);
    this.activeModal.close();
  }

}
