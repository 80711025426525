/* eslint-disable @typescript-eslint/consistent-type-imports */
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'nalco-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit {
  currentYear: string;
  @Input() isExpand : boolean;

  constructor() {
  }
  ngOnInit() {
    this.currentYear = new Date().getFullYear().toString();
  }
}
