import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupSystems'
})
export class GroupSystems implements PipeTransform {

  transform(systems: any[], category: any): any {
    let coll = [];
    let mappedLen = 0;
    if (systems) {
        systems.forEach(system => {
          if (system.IsMapped) {
            mappedLen++;
          } 
          if(coll.includes(x => x.DagDescendantCpId === system.DagDescendantCpId)) {
          }
          else {
          coll.push(system);
          }
        });
        if (coll && coll.length >= 1 && coll.length === mappedLen) {
          category.OverAllMappingStatus = true;
        }
    }
    return coll;
  }
  
}
