import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AppDynamicsService } from '../../../core/analytics/app-dynamics.service';
import { LocalCacheService } from '../../../core/local-cache/local-cache.service';
import { ProjectTableColumns } from '../../_enum/constants';
import { ResponsiveService } from '../../services/reponsive.service';

@Component({
  selector: 'nalco-customize-grid-popup-widget',
  templateUrl: './customize-grid-popup-widget.component.html',
  styleUrls: ['./customize-grid-popup-widget.component.scss']
})
export class CustomizeGridPopupWidgetComponent implements OnInit {

  @Input() inputData: any;
  @Input() widgetName: any;
  customizeColumn: Array<any> = [];
  invalidSelection = false;
  @ViewChild('alert', { static: false }) alert: ElementRef;
  showUnsupported = false;

  constructor(public activeModal: NgbActiveModal,
    private translate: TranslateService,
    private localCacheService: LocalCacheService,
    private appDynamicsService: AppDynamicsService,
    private responsiveService: ResponsiveService) { }

  ngOnInit() {
    this.inputData.forEach(item => {
      if (item.defaultActive) {
        this.customizeColumn.push(item.title);
      }
    });
  }
  closeUnsupportedMessage() {
    this.alert.nativeElement.classList.remove('show');
    this.responsiveService.updateUnsupportedMessage(false);
  }
  dimissModal() {
    this.activeModal.close();
  }
  /*************** Common Methods ********************/
  getIndex(arr: Array<string>, str: string): number {
    return arr.indexOf(str);
  }
  /*************** End of Common Methods ********************/

  onChangeItemInPopup(itemId: string, event: Event) {
    const index = this.inputData.findIndex(x => x.columnOrder === itemId);
    const target = event.target as HTMLInputElement;
    if (target.checked) {
      this.customizeColumn.push(this.inputData[index].title);
      this.inputData[index].defaultActive = true;
    } else {
      const i = this.customizeColumn.findIndex(x => x === this.inputData[index].title);
      this.customizeColumn.splice(i, 1);
      this.inputData[index].defaultActive = false;
      // this.storeData('filterData', JSON.stringify(this.pillsData));
    }
  }

  saveFilteredPills() {
    switch (this.widgetName) {
      case 'ActionLogAndRecommendation':
        if (this.customizeColumn.length > 11) {
          this.invalidSelection = true;
        } else {
          this.activeModal.close(this.customizeColumn);
        }
        break;
      case 'ProjectTable':
        if (this.customizeColumn.length > 10) {
          this.invalidSelection = true;
        } else {
          this.activeModal.close(this.customizeColumn);
        }
        break;
      case undefined:
        this.activeModal.close(this.customizeColumn);
        break
      default:
        break;
    }

  }

  clearAllFilters() {
    // const index = this.customizeColumn.findIndex(x => x === this.translate.instant('FINANCIAL_IMPACT.PROJECT_TABLE.SITE_NAME'));
    this.inputData = JSON.parse(JSON.stringify(ProjectTableColumns));
    //  if (index < 0) {
    //  this.inputData.splice(0, 1);
    //  }
    this.customizeColumn = [];
    this.inputData.forEach(item => {
      if (item.defaultActive) {
        this.customizeColumn.push(item.title);
      }
    });
  }
  /************ Analytics Listener ************/
  @HostListener('click', ['$event'])
  onClick(event) {
    const adrumEvent = event;
    const adrumService = 'FinancialImpact';
    const adrumComponent = this.constructor.name.toString();
    this.appDynamicsService.adrumClickEvent(adrumEvent, adrumService, adrumComponent);
  }
}
