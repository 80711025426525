import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ChartObject } from 'highcharts';
import * as Highcharts from 'highcharts/highcharts';

@Component({
  selector: 'nalco-solid-gauge-chart',
  templateUrl: './solid-gauge-chart.component.html',
  styleUrls: ['./solid-gauge-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SolidGaugeChartComponent implements OnInit, AfterViewInit {
  @Input() gaugechart;
  @Input() garphId;
  chartSpeed: ChartObject;
  constructor() { }
  ngOnInit() {
  }
  ngAfterViewInit() {
    setTimeout(() => {
      if (this.gaugechart.targetAchieved != null) {
        const gaugeOptions = {
          chart: {
            type: 'solidgauge'
          },
          title: null,
          pane: {
            center: ['50%', '85%'], // for complete circle 50% , 50%
            size: '80%', //// for complete circle 100%
            startAngle: -90, // for complete circle 0
            endAngle: 90, // for complete circle 360
            background: {
              backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
              innerRadius: '60%',
              outerRadius: '100%',
              shape: 'arc'
            }
          },
          exporting: {
            enabled: false
          },
          tooltip: {
            enabled: false
          },
          yAxis: {
            stops: [
              [0, this.gaugechart.graphColor],
              [1.0, this.gaugechart.graphColor],
            ],
            min: 0,
            max: this.gaugechart.targetAchieved,
            title: {
              text: ''
            },
            lineWidth: 0,
            tickWidth: 0,
            minorTickInterval: null,
            tickAmount: 2,
            labels: {
              y: 16 // below 0 -200 text position
            }
          },
          plotOptions: {
            solidgauge: {
              dataLabels: {
                y: 30, // inside arc text position
                borderWidth: 0,
                useHTML: true
              }
            }
          }
        };
        this.chartSpeed = Highcharts.chart('container-speed' + this.garphId, Highcharts.merge(gaugeOptions, {
          yAxis: {
            min: 0,
            max: this.gaugechart.target,
            tickPositioner: function () {
              return [this.min, this.max];
            }
          },
          credits: {
            enabled: false
          },
          series: [{
            name: '',
            data: [this.gaugechart.targetAchieved],
            dataLabels: {
              format:
                '<div style="text-align:center">' +
                '<span style="font-size:0.725rem">{y}</span><br/>' +
                '<span style="font-size:12px;opacity:0.4">' + this.gaugechart.targetUnit + '</span>' +
                '</div>'
            },
            tooltip: {
              valueSuffix: this.gaugechart.targetUnit
            }
          },
          {
            name: 'Customer Dot',
            isRectanglePoint: false,
            type: 'gauge',
            data: this.gaugechart.targetAchieved > this.gaugechart.target ? [this.gaugechart.target] : [this.gaugechart.targetAchieved],
            dial: {
              // backgroundColor: Highcharts.getOptions().colors[1],
              backgroundColor: '#000', // pin color
              rearLength: '-128%' // pin on graph length 
            },
            dataLabels: {
              enabled: false
            },
            pivot: {
              radius: 0
            }
          }]
        }));
      }
    }, 100);
    
  }
  // updateChartData() {
  //   if (this.chartSpeed) {
  //     this.chartSpeed.update({
  //       series: [{
  //         data: [],
  //       }]
  //     });
  //   }
  // }
}
