import { IXAxis } from '../../shared/contracts/_contracts';

export class XAxis implements IXAxis {
    categories?: string[] ;

    constructor(xAxis?: IXAxis) {
        if (xAxis) {
            this.categories = xAxis.categories;
        }
    }

}
