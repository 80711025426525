import { ISubheaderButton } from '../sub-header/sub-header.modal';

export interface IDropdownOption {
    text: string;
    secondText?: string;
    value: string;
    isSelected?: boolean;
    field?: string;
    isHeader?: boolean;
    isDisabled?: boolean;
    isImage?: boolean;
    contextPointSubTypeId?: number;
    contextPointTypeId?: number;
}

export interface IDropdown {
    label: string;
    name: string;
    image?: string;
    options: IDropdownOption[];
    className?: string;
}

export interface IDropdownEventType {
    name: string;
    value: string;
    text?: string;
    secondText?: string;
    label?: string;
}
export interface IDropdownValue {
    display: string;
    id: number;
    checked: boolean;
    disable?: boolean;
 }
//  export interface IMultiDropdown {
//     display: string;
//     id: number;
//     checked: boolean;
//  }