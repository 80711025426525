// angular modules packages
import type { Routes } from '@angular/router';

// custom modules components
import { MergeSystemNameComponent } from './asset-harvester-merge-system-name/merge-system-name.component';

// routing for admin, enterprise and site level components
export const NOTIFICATION_ROUTES: Routes = [
  {
    path: 'merge-system-name/:systemName/:dagId/:oldDataSourceId/:newDataSourceId',
    component: MergeSystemNameComponent,
    pathMatch: 'full',
  },

];

