import { LocalCacheService } from './../../../../core/local-cache/local-cache.service';
import { Component, OnInit, Input, Output, EventEmitter, ContentChild, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { IDropdown, IDropdownOption } from '../../dropdown/dropdown-model';
import { TranslateService } from '@ngx-translate/core';
import { DropdownComponent } from '../../dropdown/dropdown.component';
import { UserService } from '../../../../core/user/user.service';
import { Router } from '@angular/router';


@Component({
  selector: 'nalco-customer-division-filter',
  templateUrl: './customer-division-filter.component.html',
  styleUrls: ['./customer-division-filter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomerDivisionFilterComponent implements OnInit, AfterViewInit {

  @ContentChild(DropdownComponent, { static: false }) ddComponent: DropdownComponent;
  @Output('filterObjChange') filterObjChange = new EventEmitter<any>();
  @Output('appliedFiltersChange') appliedFiltersChange = new EventEmitter<any>();
  @Output('clearDetails') clearDetails = new EventEmitter<any>();
  @Output('clearAllClicked') clearAllClicked = new EventEmitter(); 
  @Input() serviceEnabledName = '';
  @Output() LoaderforchildWFI: EventEmitter<any> = new EventEmitter();
  @Input('multiselectDropdownFlag') multiselectDropdownFlag: boolean;
  @Input() isClearAllVisible = true;
  @Input() isMyDocument?= false;
  wfiAccountDetailArray = [];
  selectedItems = [];
  itemsChanged: boolean = false;
  evocData: any;
  allowChanges:boolean;
  @Input() set accountDetails(value) {
    if (value !== undefined) {
      this.accountDetailArray = [];
      this.wfiAccountDetailArray = [];
      this.evaluateData(value);
    }
  }

  @Input() set accountDetailsChild(value) {
    if (value !== undefined) {
      this.evaluateData(value);
    }
  }

  @Input() set corporateAccountDetails(value) {
    if (value !== undefined) {
      this.filterObject = value;
      if(this.multiselectDropdownFlag)
        this.getEvocArrayData = this.userService.getFilterDetails();
    }
  }

  public accountDetailArray: any = [];
  public selectedContextPoints = [];
  public ifSSDclearAll = '';
  // loader: boolean;
  private filterObject: any = {};
  getEvocArrayData: any;
  ddItem = <IDropdown>{};

  constructor(private translate: TranslateService,
    private userService: UserService,
    public localCacheservice: LocalCacheService,
    private router: Router) {

  }

  ngOnInit() {
    this.ddItem.options = []; 
    this.filterObject.children = [];
    this.accountDetailArray = [];
    this.wfiAccountDetailArray = [];
    this.selectedItems = [];
    this.filterObjChange.emit(this.filterObject);
    this.getEvocArrayData = this.userService.getFilterDetails();
    this.evocData = this.userService.getFilterDetails();
  }

  ngAfterViewInit() {
    let SSDSitesearch = document.getElementById('SSDCVOCsiteSearch');
    if (SSDSitesearch) {
      this.ifSSDclearAll = 'ifSSDclearAll';
    } else {
      this.ifSSDclearAll = '';
    }
  }

  itemchecked(event,index){
    //to set variable on checkbox check
    this.resetDropdownData(index);
    if (event.length > 0) {
      let childArray = [];
      event.forEach(item => {
        childArray.push({
          'contextPointId': item.id.value,
          'contextPointName': item.id.text
        });
      })
      this.filterObject.children[index]= childArray;
    }
    this.appliedFiltersChange.emit(this.filterObject);
  }
  
  itemSelected(event, index) {
    let childArray = [];
    this.itemsChanged = false;
    if (event != undefined) {
      if (this.selectedItems && this.selectedItems[index]) {
        if (event.length == 0) {
          //all items are unchecked in the dropdown(item(s) was already selected before going back and unchecking)
          if (this.wfiAccountDetailArray[index].filter(item => item.checked == true).length == 0) {
            this.resetDropdownData(index);
            this.getEvocArrayData.filterObject.children.splice(index+1);
          }
        } else {
          //new items are checked or unchecked(item(s) was already selected before going back and checking/unchecking)
          let selectedContextPoints = JSON.stringify(this.selectedItems[index].map((item) => {return item.display}).sort((a, b) => a > b ? 1 : -1));
          let eventContextPoints = JSON.stringify(event.map(item => {return item.display }).sort((a, b) => a > b ? 1 : -1));
          if (selectedContextPoints != eventContextPoints) {
            this.itemsChanged = true;
            this.resetDropdownData(index);
          }
        }
      }
      //checking items for first time  
      if((this.itemsChanged || this.selectedItems.length == index) && event.length != 0) {
        this.resetDropdownData(index);
        event.forEach(item => {
          childArray.push({
            'contextPointId': item.id.value,
            'contextPointName': item.id.text
          });
        })
        this.filterObject.children.push(childArray);
        this.filterObjChange.emit(this.filterObject);
      }
    }
  }

  //to reset the values of multiselect and select dropdwon
  resetDropdownData(index) {
    this.selectedItems.splice(index);
    this.filterObject.children.splice(index);
    this.wfiAccountDetailArray.splice(index+1);
    this.removeData(index);
    this.getEvocArrayData != null ? this.getEvocArrayData.filterObject.children.splice(index+1) : '';
  }

  selectedDropdown(event, index) {
    setTimeout(() => {
      // this.loader = true;
    }, 1000);
    let selectedOprionsDropdown = []
    if (index > 0) {
      if (this.accountDetailArray.length > 0) {
        this.accountDetailArray.map(element => {
          element.options.map(element => {
            if (element.isSelected) {
              selectedOprionsDropdown.push
                ({
                  'contextPointId': element.value,
                  'contextPointName': element.text
                });
            }
          });
        });
      }
      if(!this.multiselectDropdownFlag) 
        this.filterObject.children = selectedOprionsDropdown;
    }
    const childArray = {
      'contextPointId': event.value,
      'contextPointName': event.text
    };
    const checkObj = this.filterArray(event);
    if (checkObj) {
      this.filterObject.children[index] = childArray;
    }
    this.filterObjChange.emit(this.filterObject);
  }



  evaluateData(data: any) {
    if (data.data.length > 0) {
      const ddOption: Array<IDropdownOption> = [];

      //get the array of selected dropdwon from localstorage
      // let getEvocArrayData = this.userService.getFilterDetails();
      if (this.getEvocArrayData && this.getEvocArrayData.filterObject.children) {
        if (!this.multiselectDropdownFlag) {
          this.selectedContextPoints = this.getEvocArrayData.filterObject.children.map(item => item.contextPointName);
        } else {
          this.getEvocArrayData.filterObject.children.forEach((item,index) => {
            if(index < 2 && Array.isArray(item)) {
              item.forEach(element => this.selectedContextPoints.push(element.contextPointName));
            } else 
              this.selectedContextPoints.push(item.contextPointName);  
          });
        }
      } 
      data.data.forEach(eachObj => {

        let isSelected = false;
        if (this.selectedContextPoints) {
          isSelected = this.checkIfSelected(eachObj.ContextPointName);
        }

        const childArray = {
          'text': eachObj.ContextPointName,
          'value': eachObj.ContextPointId,
          'isSelected': isSelected
        };
        ddOption.push(childArray);

      });
      this.selectedContextPoints = [];
      let objArray = [];
      const modal = <IDropdown>{};
      modal.label = this.translate.instant('COMMON.SELECT');
      modal.options = ddOption;
      this.accountDetailArray[data.index] = modal;
      //adding items for multiselect dropdown array
      if (ddOption.length > 0) {
        this.wfiAccountDetailArray.length == 0 ? this.selectedItems = [] : '';
        ddOption.forEach(item => {
          let newObj = {
            id: item,
            display: item.text,
            checked: item.isSelected,
            disable: false
          }
          objArray.push(newObj);
        });
        this.wfiAccountDetailArray[data.index] = objArray;
        this.wfiAccountDetailArray.forEach((item, index) => {
          let obj = [];
          item.forEach(element => {
            if (element.checked == true) 
              obj.push(element);
          })
          obj.length > 0 ? this.selectedItems[index] = obj : '';
        });
      }
      if (data.data[0].ContextPointName) {
        this.LoaderforchildWFI.emit(false);
      }
      //this.loader = false;
    }
    else {
      //this.loader = false;
    }
    if (data.data.length == 0) {
      this.selectedItems = [];
      this.wfiAccountDetailArray.forEach((item, index) => {
        let obj = [];
        item.forEach(element => {
          if (element.checked == true) 
            obj.push(element);
        })
        obj.length > 0 ? this.selectedItems[index] = obj : '';
      });
    }

  }

  checkIfSelected(item) {
    if (this.selectedContextPoints.indexOf(item) >= 0) {
      return true
    } else {
      return false;
    }
  }

  clearFilter() {
    const index = 0;
    if(this.multiselectDropdownFlag) {
      this.getEvocArrayData = null;
      let updateSetting;
      if (this.evocData != null) {
        updateSetting = {
          parentId : this.evocData.filterObject.parentId,
          parentName : this.evocData.filterObject.parentName,
          children: this.evocData.filterObject.children
        }
      } else {
        updateSetting = {
          parentId : '',
          parentName : '',
          children: []
        }
      }
      this.clearAllClicked.emit(updateSetting);
    }
    this.filterObject.children = [];
    this.removeData(index);
    this.accountDetailArray = [];
    this.wfiAccountDetailArray = [];
    this.selectedItems = [];
    if (this.ifSSDclearAll == '') {
      this.filterObject.parentId = '';
      this.filterObject.parentName = '';
    }
    this.filterObjChange.emit(this.filterObject);
    this.clearDetails.emit('');
  }

  SelectedRow(index) {
    this.removeData(index);
  }

  removeData(index) {
    const arrayData = this.accountDetailArray.slice();
    arrayData.forEach((data, i) => {
      if (i > index) {

        const ind = this.accountDetailArray.indexOf(data);
        this.accountDetailArray.splice(ind, 1);
        this.filterObject.children.splice(ind, 1);
      }
    });
  }

  filterArray(event) {
    let checkObj = true;
    // this.filterObject.children.find(function (element, index) {
    //   if (element && (element.contextPointId === event.value)) {
    //     checkObj = false;
    //   }
    // });
    return checkObj;
  }

  clearSelectedFilter(dropdownData) {
    dropdownData.forEach(item => {
      item.isSelected = false;
    });
  }
}
