<div class="water-chemistry-dashboard">
    <nalco-tile [parentInstance]="myParentInstance()"   [headerTitle]="" [errorData]='errorInfo' [showMoreOptions]=false [showInfoIcon]=false class="no-header">
        <div tile-content>
            <form class="wc-tab" [formGroup]="waterChemistryForm" *ngIf="wctdataCount === wcData.length-1">
                <div class="hr-line">
                    <div class="row alignment wat">
                        <div class="align-item col-6">
                            <label class="col-form-label headingClass">
                                {{'RO_OPTIMIZER.WATER_CHEMISTRY.WATER_ANALYSIS_TYPE'| translate}}
                                {{waterAnalysisType}}</label>
                        </div>
                        <div class="align-item charge-balance-label">
                            <a style="color: #007ac9;" (click)="getChargeBalance()" translate>
                                RO_OPTIMIZER.WATER_CHEMISTRY.CHARGE_BALANCE </a>
                        </div>
                    </div>
                    <div class="form-group row" [ngClass]="waterChemistry == 'RawWater' ? 'alignment' : 'concentrateAlignment'">
                        <div class="align-item col-3" style="flex: 0 0 23%" *ngIf="waterChemistry == 'RawWater'">
                            <div class="mr-3">
                                <label class="col-form-label" translate>RO_OPTIMIZER.WATER_CHEMISTRY.PH_CONTROL</label>
                            </div>
                            <div class="w-35" *ngIf = "phControlscreated">
                                <select class="form-control" formControlName="pHControl"
                                    (change)="onpHControlChange($event)">
                                    <option *ngFor="let item of pHControls" [value]="item.text">
                                        {{item.text}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="align-item col-3" *ngIf="waterChemistry == 'RawWater'">
                            <div class="mr-3">
                                <label class="col-form-label" translate>RO_OPTIMIZER.WATER_CHEMISTRY.PH_SETPOINT</label>
                                <span class="required">*</span>
                            </div>
                            <div class="w-25" *ngIf = "phControlscreated">
                                <input class="form-control" type="number" formControlName="pHSetPoint"
                                    (change)="onpHSetpointChange($event)" [ngClass]="setPointError ? 'invalid': ''">
                            </div>
                        </div>
                        <div class="align-item col-6" [ngClass]="waterChemistry == 'RawWater' ? 'charge-balance-calc' : 'charge-balance-calc2'">
                            <div class="ionInfo" *ngIf="!(addedIons?.cleared) || isChargeBalanceCalculated">
                                <div class="row">
                                    <label>{{'RO_OPTIMIZER.WATER_CHEMISTRY.ADDED_ION'| translate}}
                                        {{addedIons?.details?.ShortName}}</label>
                                    <input class="col-3" formControlName="calculatedIons">
                                    <select class="form-control col-5" (change)="subUnitChange($event)"
                                        formControlName="selectedUnit">
                                        <option *ngFor="let unit of subUnits" [value]="unit.key">
                                            {{unit.text}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-11">
                        <div *ngFor="let control of wcData">
                            <nalco-combo-box [data]="control" [formGroup]="waterChemistryForm"
                                (ddChange)="updateDropdown($event)" (tbChange)="updateInput($event)"></nalco-combo-box>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </nalco-tile>
</div>