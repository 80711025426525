import { AbstractControl, ValidatorFn } from "@angular/forms";


export function maxValue(max: Number): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
      const input = control.value,
            isValid = Number(input) >= max;
      if((input != null || input != undefined) && isValid) 
          return { 'max': {max} }
      else 
          return null;
    };
  }
 export  function minValue(min: Number): ValidatorFn {
      return (control: AbstractControl): {[key: string]: any} => {
        const input = control.value,
              isValid = Number(input) <= min;
        if((input != null || input != undefined) && isValid) 
            return { 'min': {min} }
        else 
            return null;
      };
  }