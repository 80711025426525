import { environment } from './../../../../../environments/environment';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { AppEnvConfig } from './../../../../app.env.config';


@Component({
  selector: 'nalco-left-menu-submenu-item',
  templateUrl: './left-menu-submenu-item.component.html'
})
export class LeftMenuSubmenuItemComponent implements OnInit {
  @Input() details: any;
  @Output() submenuItemClick: EventEmitter<any> = new EventEmitter();
  public forumID: any;

  constructor(private config: AppEnvConfig) { }

  ngOnInit() {
    this.forumID = environment.userVoiceConfig.forumID;
  }

  menuItemClick() {

    // Check if the link is for training and uservoice
    if (this.details.link === 'Training-Customers-External') {
      window.open('http://40.70.21.254/lms/');
    } else if (this.details.link === 'Training-Ecolab-Associate') {
      window.open('https://app.powerbi.com/groups/me/apps/65edf861-e5b1-442a-b4bb-9242d4673875/reports/29ef5620-e3d8-4359-ab46-e4d68d58a35d/ReportSectionc390ba6500b873caa59c?ctid=c1eb5112-7946-4c9d-bc57-40040cfe3a91&experience=power-bi');
    } else if (this.details.link === 'Water-Quality-Uservoice') {                                      // check if the link is for Uservoice
      window.open('https://nalcowater.uservoice.com/forums/' + this.forumID.WQ + '-water-quality');
    } else if (this.details.link === 'Water-Safety-Uservoice') {
      window.open('https://nalcowater.uservoice.com/forums/' + this.forumID.WSI + '-water-safety-intelligence');
    } else if (this.details.link === 'financial-impact-Uservoice') {
      window.open('https://nalcowater.uservoice.com/forums/' + this.forumID.FI + '-financial-impact');
    } else if (this.details.link === 'data-Visualization-Uservoice') {
      window.open('https://nalcowater.uservoice.com/forums/' + this.forumID.DV + '-data-visualization');
    } else if (this.details.link === 'water-Flow-intelligence-Uservoice') {
      window.open('https://nalcowater.uservoice.com/forums/' + this.forumID.WFI + '-water-flow-intelligence');
    } else if (this.details.link === 'service-summary-Uservoice') {
      window.open('https://nalcowater.uservoice.com/forums/' + this.forumID.WFI + '-service-summary');
    } else if (this.details && this.details.isExternal && this.details.breadcrumb === 'CIP') {
      const isE3DChina = (this.config.getEnv("IsE3DChina") === "true") ? true : false;
      if(isE3DChina){
        window.open(environment.CIP_LINK_China);
      }else{
        window.open(environment.CIP_LINK);
      }
    } else {
      this.submenuItemClick.emit({ link: this.details.link });
    }
  }
}
