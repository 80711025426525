
import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DASH_STYLES } from '../../../app.config';
import { LocalCacheService } from '../../../core/local-cache/local-cache.service';
import { IIdAndName } from '../../../shared/contracts/_contracts';
import * as models from '../../../shared/models/_models';
import { Pill } from '../../../shared/models/_models';

@Component({
  selector: 'nalco-pills',
  templateUrl: './pills.component.html',
  styleUrls: ['./pills.component.scss']
})
export class PillsComponent implements OnInit {

  pillsData: Array<models.Pill> = [];
  filteredPills: Array<string> = [];
  cachePillsData: Array<models.Pill> = [];
  filteredPillsInPopup: Array<string> = [];
  category: Array<string> = [];
  selectedPill: models.Pill = {
    id: '',
    name: ''
  };
  pillsRemovedInPopup: Array<IIdAndName> = [];
  searchTerm: '';
  @Input() componentIdentifier: string;
  @Input() showFilteredPills? = false;
  @Input() lineBorder? = false;
  @Input() showAll? = false;
  @Input() searchNeeded? = true;
  @Input() showUptoMore? = false;
  @Input() showFilterNote? = true;
  @Input() showPillsbar? = false;
  @Input() canMultiSelect? = false;
  @Input() filterNote?: string;
  @Input() clearFilterText?: string;
  @Input() cancel?: string;
  @Input() save?: string;
  @Input() pillsColumn?: number;
  @Input() isPillDynamic? = false;

  @Input()
  set defaultSelectedPill(p: Pill) {
    if (p) {
      this.selectedPill.id = p.id;
      this.selectedPill.name = p.name;
      this.changeInSelectedPill.emit(p);
    }
  }


  @Input()
  set pills(pills: Array<models.Pill>) {
    this.pillsData = pills;

    if (pills) {
      pills.forEach(pill => {
        if (this.showFilteredPills === true && typeof pill.isFavorite !== 'undefined' && pill.isFavorite === true) {
          if (this.filteredPills) {
            this.filteredPills.push(pill.id);
          }
        }
      });
    }
    if (this.pillsColumn) {
      pills.forEach(pill => {
        this.category.push(pill.categoryType);
      });
      this.category = this.category.filter((v, i, a) => a.indexOf(v) === i);
    }
  }

  @Input() filterPillsModalTitle: string;
  @Input() AllPillsTitle = '';
  @Input() maxFilterPills: number;

  @Output('changeInSelectedPill') changeInSelectedPill = new EventEmitter<models.Pill>();
  @Output('changeInSelectedPills') changeInSelectedPills = new EventEmitter<Pill[]>();
  @Output('changeInFilteredPills') changeInFilteredPills = new EventEmitter<{}>();
  dashStyles = DASH_STYLES;

  constructor(private modalService: NgbModal, private localCacheService: LocalCacheService) {
    // this.checkbox = false;
  }
  ngOnInit() {

  }

  ngOnChanges(changes: { [componentIdentifier: string]: SimpleChange }) {

    if (changes.componentIdentifier) {
      if (this.fetchData('filterData_' + this.componentIdentifier) !== null) {
        // console.log('value is not null');
        // console.log(this.pillsData);
        this.pillsData = JSON.parse(this.fetchData('filterData_' + this.componentIdentifier));
        this.filteredPills = [];
        this.pillsData.forEach(pill => {
          if (this.showFilteredPills === true && typeof pill.isFavorite !== 'undefined' && pill.isFavorite === true) {
            this.filteredPills.push(pill.id);
          }
        });
      } else {
        //console.log('value is null, no filters applied');
      }
    }

    if (!this.isPillDynamic) {
      this.storeData('filterData_' + this.componentIdentifier, JSON.stringify(this.pillsData));
      this.storeData('filteredPillsData_' + this.componentIdentifier, JSON.stringify(this.filteredPills));
    }

    if (changes.componentIdentifier) {
      if (this.fetchData('filteredPillsData_' + this.componentIdentifier) !== null) {
        //console.log(this.filteredPills);
        // console.log(this.pillsData);
        this.filteredPills = JSON.parse(this.fetchData('filteredPillsData_' + this.componentIdentifier));
        //console.log(this.filteredPills);
      } else {
        //console.log('value is null, no filters applied');
      }
    }

  }

  /*************** Common Methods ********************/
  getIndex(arr: Array<string>, str: string): number {
    return arr.indexOf(str);
  }
  /*************** End of Common Methods ********************/

  /*************** Methods for in-page Pills section ********************/
  selectPill(pill: models.Pill) {
    this.selectedPill.id = pill === null ? '':pill.id;
    this.selectedPill.name = pill === null ? '':pill.name;
    if (this.canMultiSelect) {
      if (pill === null) {
        this.pillsData.forEach(item => {
          item.selected = false;
        });
      } else {
        this.pillsData.forEach(item => {
          if (item.id === pill.id) {
            item.selected = (item.selected ? false : true);
          }
        });
      }
    }
    this.changeInSelectedPill.emit(pill);
    this.changeInSelectedPills.emit(this.pillsData);
    if (!this.isPillDynamic) {
      this.storeData('filterData_' + this.componentIdentifier, JSON.stringify(this.pillsData));
    }
  }

  getfilteredPills() {

    let pills = [];

    if (this.pillsData && !this.showAll) {
      // TODO - Start-  check if this code is not breaking any other component
      this.pillsData.forEach(pill => {
        if (this.showFilteredPills === true && typeof pill.isFavorite !== 'undefined' && pill.isFavorite === true) {
          if (this.filteredPills.filter(e => e === pill.id).length === 0) {
            // this.filteredPills.push(pill.id);
          }
        }
      });

      // TODO - End

      pills = this.pillsData.filter((pill: IIdAndName) => {
        return this.getIndex(this.filteredPills, pill.id) > -1;
      });
    } else if (this.pillsData && this.showAll) {
      pills = this.pillsData;
    }
    return pills;


  }

  removeFromFilteredPills(pillId: string) {
    const index = this.getIndex(this.filteredPills, pillId);
    if (index > -1) {
      this.filteredPills.splice(index, 1);
      this.pillsData.forEach(pill => {
        if (this.showFilteredPills === true && typeof pill.isFavorite !== 'undefined' && pill.isFavorite === true) {
          if (this.filteredPills.filter(e => e === pill.id).length === 0) {
            pill.isFavorite = false;
            pill.selected = false;
          }
        }
        if (!this.isPillDynamic) {
          this.storeData('filterData', JSON.stringify(this.pillsData));
          this.storeData('filterData_' + this.componentIdentifier, JSON.stringify(this.pillsData));
          this.storeData('filteredPillsData_' + this.componentIdentifier, JSON.stringify(this.filteredPills));
        }
      });
    }

    const filteredPillData = this.pillsData.filter(function (e) {
      return this.indexOf(e.id) !== -1;
    }, this.filteredPills
    );

    if (this.selectedPill && pillId === this.selectedPill.id) {
      this.selectedPill.id = '';
      this.selectedPill.name = '';

      this.changeInFilteredPills.emit(filteredPillData);
      this.changeInSelectedPill.emit(this.selectedPill);
    } else {

      this.changeInFilteredPills.emit(filteredPillData);
      this.changeInSelectedPill.emit(this.selectedPill);
    }
    this.changeInSelectedPills.emit(this.pillsData);
  }
  isSelectedPill(pill) {
    if (this.canMultiSelect) {
      if (pill.selected === true) {
        return true;
      } else {
        return false;
      }
    } else {
      if (this.selectedPill.id === pill.id) {
        pill.isFavorite = false;
        return true;
      }
      if (pill.isFavorite === true && this.selectedPill.id == "") {
        return true;
      }
    }
    this.filteredPills = JSON.parse(this.fetchData('filteredPillsData_' + this.componentIdentifier));
  }


  /*************** End ofMethods for in-page Pills section ********************/

  /************** Methods for Popup Pills section ****************/
  openFilterPillsModal(filterPillsModal) {
    this.cachePillsData = JSON.parse(JSON.stringify(this.pillsData));
    this.filteredPillsInPopup = this.filteredPills.slice(0);

    this.modalService.open(filterPillsModal, { backdrop: 'static', keyboard: false }).result.then(() => {
      // Handling  save  button
      this.filteredPills = this.filteredPillsInPopup.slice(0);
      const filteredPillData = this.pillsData.filter(function (e) {
        return this.indexOf(e.id) !== -1;
      }, this.filteredPills
      );
      this.changeInFilteredPills.emit(filteredPillData);
      if (this.selectedPill.name !== '' && this.getIndex(this.filteredPills, this.selectedPill.id) < 0) {
        this.selectedPill.id = '';
        this.selectedPill.name = '';
        this.changeInSelectedPill.emit(this.selectedPill);
      }
      this.changeInSelectedPills.emit(this.pillsData);
      if (!this.isPillDynamic) {
        this.storeData('filterData_' + this.componentIdentifier, JSON.stringify(this.pillsData));
        this.storeData('filteredPillsData_' + this.componentIdentifier, JSON.stringify(this.filteredPills));
      }

    }, () => {
      // Handling  cancel button
      this.pillsData = JSON.parse(JSON.stringify(this.cachePillsData));
      this.changeInFilteredPills.emit(this.cachePillsData);
    });
  }

  getFilteredPillsInPopup() {
    let pills = [];
    if (this.pillsData) {
      pills = this.pillsData.filter((pill: IIdAndName) => {
        return this.getIndex(this.filteredPillsInPopup, pill.id) > -1;
      });
    }
    return pills;
  }

  getUnfilteredPillsInPopup() {
    let pills = [];
    if (this.pillsData) {
      pills = this.pillsData.filter((pill: IIdAndName) => {
        return this.getIndex(this.filteredPillsInPopup, pill.id) < 0;
      });
    }
    return pills;
  }

  addToFilteredPillsInPopup(pillId: string) {
    const index = this.getIndex(this.filteredPillsInPopup, pillId);
    if (index < 0) {
      this.filteredPillsInPopup.push(pillId);
    }
  }

  onChangeFilteredPillsInPopup(pillId: string, checked: EventTarget) {
    let isChecked = (checked as HTMLInputElement).checked;
    const index = this.getIndex(this.filteredPillsInPopup, pillId);
    if (index < 0 && isChecked) {
      // debugger;
      this.filteredPillsInPopup.push(pillId);
      this.pillsData.forEach(element => {
        if (pillId === element.id) {
          element.selected = false;
        }
      });
    } else {
      this.filteredPillsInPopup.splice(index, 1);
      this.pillsData.forEach(element => {
        if (pillId === element.id) {
          element.selected = false;
        }
      });
    }
    if (!this.isPillDynamic) {
      this.storeData('filterData', JSON.stringify(this.pillsData));
    }
  }

  removeFilterPillsInPopup(pillId: string) {
    const index = this.getIndex(this.filteredPillsInPopup, pillId);
    if (index > -1) {
      this.filteredPillsInPopup.splice(index, 1);
    }
  }

  clearAllFilters() {
    this.pillsData.forEach(element => {
      element.isFavorite = false;
      element.selected = false;
    });
    this.filteredPillsInPopup = [];
  }
  /************** End of Methods for Popup Pills section ****************/
  /************** Session Storage section ****************/
  private storeData(key: string, value: any) {
    this.localCacheService.storeData(key, value);
    // console.log('filters stored in session');
    //  this.fetchData(key);
  }

  private fetchData(key: string) {
    this.localCacheService.fetchData(key);
    // console.log('Fetch stored data ===> ' + this.localCacheService.fetchData(key));
    return this.localCacheService.fetchData(key);
  }
}
