<div class="combo-box">
    <div [formGroup]="formGroup">
        <div class="form-group row combo-width" *ngIf="data !== undefined && data !== null">
            <div class="col-4 combo-label">
                <label class="col-form-label"  [innerHtml]="data.DisplayName"></label>
                <span class="required" *ngIf = "data.InputParam && data.DropdownParam ? data.InputParam.Required :
                (data.InputParam !== undefined ? data.InputParam.Required: data.DropdownParam.Required)">*</span>
            </div>

            <div class="col-2 padding" [ngClass]="data.Type == 'input' ? 'col-2' : ''"
                *ngIf="data.Type == 'combo-box' || data.Type == 'input'">
                <input class="form-control" type="text"
                    [formControlName]="data.InputParam.FormControlName "
                    [ngClass]="data.Valid == false ? 'invalid': ''" (change)="onInputChange($event,data.InputParam.FormControlName)">
            </div>

            <div class="col-3 padding" [ngClass]="data.Type == 'drop-down' ? 'col-5' : ''"
                *ngIf="data.Type == 'combo-box' || data.Type == 'drop-down'">
                <select class="form-control" (change)="onDropdownChange(data.DropdownParam)"
                   [(ngModel)]="currentValue"
                    formControlName="{{data.DropdownParam.FormControlName}}">
                    <option *ngFor="let item of data.DropdownParam.ddData" [value]="item.key">
                        {{item.text}}
                    </option>
                </select>
            </div>

            <div class="col-5 padding" *ngIf="data.Type == 'label'">
                <label class="col-form-label headingClass">{{data.InputParam.Value}}</label>
            </div>
        </div>
    </div>
</div>