import { Directive, Output } from '@angular/core';
import { ComponentState } from './xd-component-state';
import { Subject } from 'rxjs';

@Directive()
export class XdComponentState {

    // this subject will be used via wrapper to handle state change
    @Output() stateChange$: Subject<any>= new Subject<any>();
    private parentStateServiceUpdater$ = new Subject<boolean>();
    parentStateServiceUpdaterObservable = this.parentStateServiceUpdater$.asObservable();

    // Message to show on error, empty or loading
    protected message: string;

    private _state: ComponentState;

    constructor() {
        this._state = ComponentState.empty;
    }

    public getState(): ComponentState {
        return this._state;
    }

    public setState(value: ComponentState) {
        if (this._state !== value) {
            const oldValue = this._state;
            this._state = value;
            // this.stateChange.emit(value);
            this.stateChange$.next({oldValue: oldValue, value: value});
            this.parentStateServiceUpdater$.next(true);
        }
    }

    get IsLoading() {
        return this._state === ComponentState.loading;
    }

    get IsEmpty() {
        return this._state === ComponentState.empty;
    }

    get IsReady() {
        return this._state === ComponentState.ready;
    }

    get IsError() {
        return this._state === ComponentState.error;
    }

}
