import { constants } from "buffer";
import { DividerOption } from "../../divider/divider.model";

export interface SidePanelOptions {
    headerOptions : HeaderOptions;
}


export interface HeaderOptions {
     headerText : string ;
     icon? : string;
     color? : string;
     bgColor? : string;
     CloseText? : string;
}

export const  DividerCss : DividerOption = {
    color: "#80B5E9",
    maxLength: "100%",
    isHorizontal: true
  }