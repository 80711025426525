import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/delay';
import { IDropdown } from '../../../shared/components/dropdown/dropdown-model';
import { Router } from '@angular/router';
import { LocalCacheService } from '../../../core/local-cache/local-cache.service';
import { TranslateService } from '@ngx-translate/core';
import { SERVICES } from '../../../app.config';
@Component({
  selector: 'nalco-site-navigation-popup',
  templateUrl: './site-navigation-popup.component.html'
})
export class SiteNavigationPopUpComponent implements OnInit {
  @Input() siteId: string;
  @Input() EnabledService: any;
  @Output() cancelModal = new EventEmitter<any>();
  @Output() closeModal = new EventEmitter<any>();
  selectedSite: any;
  servicesDropdown: IDropdown;
  IsServiceEnabled : boolean;

  constructor(private translate: TranslateService,
    private localCacheService: LocalCacheService,
    public activeModal: NgbActiveModal,
    public changeRef: ChangeDetectorRef,
    private router: Router) {
  }

  ngOnInit() {
    this.getServices();
  }

  closeModalPopup() {
    this.closeModal.emit();
    this.activeModal.close();
  }

  serviceChange(event) {
    this.selectedSite = event.value.toString();
  }

  navigateToSite(siteId) {
    let myurl = '';
    if (this.selectedSite === SERVICES.OMNI) {
      myurl = `/omni/${siteId}/sitedetails`;
    } else if (this.selectedSite === SERVICES.WATER_QUALITY) {
      myurl = `/quality/siteView/${siteId}`;
    }
    this.closeModalPopup();
    if (myurl !== '') {
      this.router.navigateByUrl(myurl);
    }
  }
  getServices() {
    this.servicesDropdown = {
      label: this.translate.instant('ACTION_LOG.SERVICE_DROPDOWN_MESSAGE'),
      name: '',
      options: this.optionBuild()
    };
  }

  optionBuild() {
    const services = [];
    let serviceName;
    this.EnabledService.forEach(element => {
      if (element.Name === SERVICES.OMNI) {
        serviceName = this.translate.instant('LEFT_NAV.SUBNAV_OMNI.NAV_LABEL');
      } else if (element.Name === SERVICES.WATER_QUALITY) {
        serviceName = this.translate.instant('LEFT_NAV.SUBNAV_QUALITY.NAV_LABEL');
      }
      services.push({
        value: element.Name,
        text: serviceName
      });
    });
    return services;
  }

}
