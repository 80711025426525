import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, NgZone } from '@angular/core';
import 'bingmaps';

///
/// import module interfaces
///
import { IBox } from './interfaces/ibox';
import { IClusterOptions } from './interfaces/icluster-options';
import { ICustomMapStyle } from './interfaces/icustom-map-style';
import { IInfoWindowAction } from './interfaces/iinfo-window-action';
import { IInfoWindowOptions } from './interfaces/iinfo-window-options';
import { ILabelOptions } from './interfaces/ilabel-options';
import { ILatLong } from './interfaces/ilatlong';
import { ILayerOptions } from './interfaces/ilayer-options';
import { ILineOptions } from './interfaces/iline-options';
import { IMapEventLookup } from './interfaces/imap-event-lookup';
import { IMapOptions } from './interfaces/imap-options';
import { IMarkerEvent } from './interfaces/imarker-event';
import { IMarkerIconInfo } from './interfaces/imarker-icon-info';
import { IMarkerOptions } from './interfaces/imarker-options';
import { IPoint } from './interfaces/ipoint';
import { IPolygonEvent } from './interfaces/ipolygon-event';
import { IPolygonOptions } from './interfaces/ipolygon-options';
import { IPolylineEvent } from './interfaces/ipolyline-event';
import { IPolylineOptions } from './interfaces/ipolyline-options';
import { ISize } from './interfaces/isize';
import { ISpiderClusterOptions } from './interfaces/ispider-cluster-options';

///
/// import module models
///
import { BingCanvasOverlay } from './models/bing/bing-canvas-overlay';
import { BingClusterLayer } from './models/bing/bing-cluster-layer';
import { BingMapEventsLookup } from './models/bing/bing-events-lookup';
import { BingInfoWindow } from './models/bing/bing-info-window';
import { BingLayer } from './models/bing/bing-layer';
import { BingMarker } from './models/bing/bing-marker';
import { BingPolygon } from './models/bing/bing-polygon';
import { BingPolyline } from './models/bing/bing-polyline';
import { BingSpiderClusterMarker } from './models/bing/bing-spider-cluster-marker';
import { CanvasOverlay } from './models/canvas-overlay';
import { ClusterClickAction } from './models/cluster-click-action';
import { ClusterPlacementMode } from './models/cluster-placement-mode';
import { GoogleCanvasOverlay } from './models/google/google-canvas-overlay';
import { GoogleMapEventsLookup } from './models/google/google-events-lookup';
import { GoogleInfoWindow } from './models/google/google-info-window';
import { GoogleMarker } from './models/google/google-marker';
import { GooglePolygon } from './models/google/google-polygon';
import { GooglePolyline } from './models/google/google-polyline';
import { InfoWindow } from './models/info-window';
import { Layer } from './models/layer';
import { MapTypeId } from './models/map-type-id';
import { Marker } from './models/marker';
import { MarkerTypeId } from './models/marker-type-id';
import { Polygon } from './models/polygon';
import { Polyline } from './models/polyline';
import { SpiderClusterMarker } from './models/spider-cluster-marker';

///
/// import module components
///
import { ClusterLayerDirective } from './components/cluster-layer';
import { InfoBoxComponent } from './components/infobox';
import { InfoBoxActionDirective } from './components/infobox-action';
import { MapComponent } from './components/map';
import { MapLayerDirective } from './components/map-layer';
import { MapMarkerDirective } from './components/map-marker';
import { MapMarkerLayerDirective } from './components/map-marker-layer';
import { MapPolygonDirective } from './components/map-polygon';
import { MapPolygonLayerDirective } from './components/map-polygon-layer';
import { MapPolylineDirective } from './components/map-polyline';
import { MapPolylineLayerDirective } from './components/map-polyline-layer';

///
/// import module services
///
import { BingClusterService } from './services/bing/bing-cluster.service';
import { BingInfoBoxService } from './services/bing/bing-infobox.service';
import { BingLayerService } from './services/bing/bing-layer.service';
import { BingMapAPILoader, BingMapAPILoaderConfig } from './services/bing/bing-map.api-loader.service';
import { BingMapService } from './services/bing/bing-map.service';
import {
    BingMapLoaderFactory,
    BingMapServiceFactory,
    BingMapServiceFactoryFactory
} from './services/bing/bing-map.service.factory';
import { BingMarkerService } from './services/bing/bing-marker.service';
import { BingPolygonService } from './services/bing/bing-polygon.service';
import { BingPolylineService } from './services/bing/bing-polyline.service';
import { ClusterService } from './services/cluster.service';
import { GoogleClusterService } from './services/google/google-cluster.service';
import { GoogleInfoBoxService } from './services/google/google-infobox.service';
import { GoogleLayerService } from './services/google/google-layer.service';
import { GoogleMapAPILoader, GoogleMapAPILoaderConfig } from './services/google/google-map-api-loader.service';
import { GoogleMapService } from './services/google/google-map.service';
import {
    GoogleMapLoaderFactory,
    GoogleMapServiceFactory, GoogleMapServiceFactoryFactory
} from './services/google/google-map.service.factory';
import { GoogleMarkerService } from './services/google/google-marker.service';
import { GooglePolygonService } from './services/google/google-polygon.service';
import { GooglePolylineService } from './services/google/google-polyline.service';
import { InfoBoxService } from './services/infobox.service';
import { LayerService } from './services/layer.service';
import { MapService } from './services/map.service';
import { DocumentRef, MapAPILoader, WindowRef } from './services/mapapiloader';
import { MapServiceFactory } from './services/mapservicefactory';
import { MarkerService } from './services/marker.service';
import { PolygonService } from './services/polygon.service';
import { PolylineService } from './services/polyline.service';

///
/// export publics components, models, interfaces etc for external reuse.
///
export { BingCanvasOverlay, BingClusterLayer, BingClusterService, BingInfoBoxService, BingInfoWindow, BingLayer, BingLayerService, BingMapAPILoader, BingMapAPILoaderConfig, BingMapEventsLookup, BingMapService, BingMapServiceFactory, BingMarker, BingMarkerService, BingPolygon, BingPolygonService, BingPolyline, BingPolylineService, BingSpiderClusterMarker, CanvasOverlay, ClusterClickAction, ClusterLayerDirective, ClusterPlacementMode, ClusterService, DocumentRef, GoogleCanvasOverlay, GoogleClusterService, GoogleInfoBoxService, GoogleInfoWindow, GoogleLayerService, GoogleMapAPILoader, GoogleMapAPILoaderConfig, GoogleMapEventsLookup, GoogleMapService, GoogleMapServiceFactory, GoogleMarker, GoogleMarkerService, GooglePolygon, GooglePolygonService, GooglePolyline, GooglePolylineService, IBox, IClusterOptions, ICustomMapStyle, IInfoWindowAction, IInfoWindowOptions, ILabelOptions, ILatLong, ILayerOptions, ILineOptions, IMapEventLookup, IMapOptions, IMarkerEvent, IMarkerIconInfo, IMarkerOptions, IPoint, IPolygonEvent, IPolygonOptions, IPolylineEvent, IPolylineOptions, ISize, ISpiderClusterOptions, InfoBoxActionDirective, InfoBoxComponent, InfoBoxService, InfoWindow, Layer, LayerService, MapAPILoader, MapComponent, MapLayerDirective, MapMarkerDirective, MapMarkerLayerDirective, MapPolygonDirective, MapPolygonLayerDirective, MapPolylineDirective, MapPolylineLayerDirective, MapService, MapServiceFactory, MapTypeId, Marker, MarkerService, MarkerTypeId, Polygon, PolygonService, Polyline, PolylineService, SpiderClusterMarker, WindowRef };

///
/// define module
///
@NgModule({
    declarations: [
        MapLayerDirective,
        MapComponent,
        MapMarkerDirective,
        InfoBoxComponent,
        InfoBoxActionDirective,
        MapPolygonDirective,
        MapPolylineDirective,
        ClusterLayerDirective,
        MapMarkerLayerDirective,
        MapPolygonLayerDirective,
        MapPolylineLayerDirective
    ],
    imports: [CommonModule],
    exports: [
        CommonModule,
        MapComponent,
        MapMarkerDirective,
        MapPolygonDirective,
        MapPolylineDirective,
        InfoBoxComponent,
        InfoBoxActionDirective,
        MapLayerDirective,
        ClusterLayerDirective,
        MapMarkerLayerDirective,
        MapPolygonLayerDirective,
        MapPolylineLayerDirective
    ]
})
export class MapModule {

    static forRoot(mapServiceFactory?: MapServiceFactory, loader?: MapAPILoader): ModuleWithProviders<MapModule> {
        return {
            ngModule: MapModule,
            providers: [
                mapServiceFactory ? { provide: MapServiceFactory, useValue: mapServiceFactory } :
                    { provide: MapServiceFactory, deps: [MapAPILoader, NgZone], useFactory: BingMapServiceFactoryFactory },
                loader ? { provide: MapAPILoader, useValue: loader } : { provide: MapAPILoader, useFactory: BingMapLoaderFactory },
                DocumentRef,
                WindowRef
            ]
        };
    }

    static forRootBing(): ModuleWithProviders<MapModule> {
        return {
            ngModule: MapModule,
            providers: [
                { provide: MapServiceFactory, deps: [MapAPILoader, NgZone], useFactory: BingMapServiceFactoryFactory },
                { provide: MapAPILoader, useFactory: BingMapLoaderFactory },
                DocumentRef,
                WindowRef
            ]
        };
    }

    static forRootGoogle(): ModuleWithProviders<MapModule> {
        return {
            ngModule: MapModule,
            providers: [
                { provide: MapServiceFactory, deps: [MapAPILoader, NgZone], useFactory: GoogleMapServiceFactoryFactory },
                { provide: MapAPILoader, useFactory: GoogleMapLoaderFactory },
                DocumentRef,
                WindowRef
            ]
        };
    }
}