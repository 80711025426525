<div class="config-popup">
    <div class="modal-header">
        <h4 class="modal-title" translate>Alarms</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
            <img src="../../../../assets/images/modal-close-icon.svg" alt="close" />
        </button>
    </div>
    <div class="mb-0 delete-content">
        <nalco-loader [loader]="isLoading" [transparentBG]="true" [fitToScreen]="true"></nalco-loader>
        <div class="account-name modal-header">
            <span> {{'CONTROLLER_MANAGEMENT.SERIAL_NO' | translate}} : {{SerialNo}} </span> <br/>
            <span> {{'CONTROLLER_MANAGEMENT.LAST_FILE_RECEIVED' | translate}} : {{LastDataReceived | date : "yyyy-MM-dd h:mm:ss"}}</span>
        </div>
        <div class="table-wrapper" #alarmList infiniteScroll [infiniteScrollContainer]="alarmList"
            [infiniteScrollDistance]="1"  [infiniteScrollThrottle]="500"   [scrollWindow]="false">
            <kendo-grid [data]="viewData" [sortable]="true">
                <kendo-grid-messages noRecords="{{'COMMON.NO_RECORDS' | translate}}"
                    pagerItemsPerPage="{{'COMMON.ITEMS_PER_PAGE' | translate}}">
                </kendo-grid-messages>
                <kendo-grid-column field="AlarmDate" [title]="'CONTROLLER_MANAGEMENT.ALARM_GENERATED_ON' | translate"
                    [width]="180">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <ng-template #tipContent>
                            <span title="{{dataItem.AlarmDate}}">{{( dataItem.AlarmDate ?
                                (dataItem.AlarmDate) : '-')}}</span>
                        </ng-template>
                        <div class="first-col overflow-ellipsis" #element
                            [ngbTooltip]="element?.offsetWidth+2 < element.scrollWidth ? tipContent: ''"
                            container="body">
                            <span>{{( dataItem.AlarmDate ? (dataItem.AlarmDate) : '-')}}</span>
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="AlarmTitle" [title]="'CONTROLLER_MANAGEMENT.ALARM_TITLE' | translate"
                    [width]="180">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <ng-template #tipContent>
                            <span title="{{dataItem.AlarmTitle}}">{{(dataItem.AlarmTitle
                                ? dataItem.AlarmTitle : '-')}}</span>
                        </ng-template>
                        <div class="first-col overflow-ellipsis" #element
                            [ngbTooltip]="element?.offsetWidth+2 < element.scrollWidth ? tipContent: ''"
                            container="body">
                            <span>{{(dataItem.AlarmTitle ? dataItem.AlarmTitle : '-')}}</span>
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="State" [title]="'CONTROLLER_MANAGEMENT.ALARM_STATE' | translate" [width]="70">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <ng-template #tipContent>
                            <span title="{{dataItem.State}}">
                                {{(dataItem.State ? dataItem.State : '-')}}</span>
                        </ng-template>
                        <div class="first-col overflow-ellipsis" #element
                            [ngbTooltip]="element?.offsetWidth+2 < element.scrollWidth ? tipContent: ''"
                            container="body">
                            <span>{{(dataItem.State ? dataItem.State : '-')}}</span>
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="AlarmValue" [title]="'CONTROLLER_MANAGEMENT.ALARM_VALUE' | translate"
                    [width]="130">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <ng-template #tipContent>
                            <span title="{{dataItem.AlarmValue}}">
                                {{(dataItem.AlarmValue ? dataItem.AlarmValue : '-')}}</span>
                        </ng-template>
                        <div class="first-col overflow-ellipsis" #element
                            [ngbTooltip]="element?.offsetWidth+2 < element.scrollWidth ? tipContent: ''"
                            container="body">
                            <span>{{(dataItem.AlarmValue ? dataItem.AlarmValue : '-')}}</span>
                        </div>
                    </ng-template>
                </kendo-grid-column>
            </kendo-grid>
        </div>
    </div>
</div>