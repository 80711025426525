import { ICalculationConfiguration, IContextPointSubType, IDependentMeasurementInfo, IInputGroup } from "../_contracts/ICalculationConfiguration";

export class CalculationConfiguration implements ICalculationConfiguration {
    Id: string;
    CalculationName: string;
    ImmutableCalculationResourceName: string;
    CalculationTypeId: number;
    CalculationMeasurementId: number;
    CalculationStuiTypeId: number;
    CalculationStuiId: number;
    MappingAllowedAtContextPointTypes: number[];
    ApplicableServiceTypeIds: number[];
    ApplicableContextPointTypes: ContextPointSubType[];
    FormulaTypeId: number;
    Formula: string = "";
    InputGroups: InputGroup[] = [];
    IncomingInstance: string;
    dataSourceMeasurementInstanceID: number;
    //UI Computed
    UIStartDate: Date | null = null;
    StuiId: number;
    StuiTypeId: number;
    displayName: string;
    //calculationCPID

    constructor(calcConfig?: CalculationConfiguration) {
        Object.assign(this, calcConfig);
    }
}

export class ContextPointSubType implements IContextPointSubType {
    ContextPointTypeId: number;
    ContextPointSubTypeId: number;

    constructor(cpSubType?: ContextPointSubType) {
        Object.assign(this, cpSubType);
    }
}

export class InputGroup implements IInputGroup {
    AllowMultipleSelections: boolean;
    InputGroupName: string = "";
    AggregationTypeId: number;
    ImmutableGroupResourceName: string;
    DependentMeasurementInfo: DependentMeasurementInfo;

    //UI computed
    ddValuesList: Array<{ id: assetPortMsrDtls, display: string, checked: boolean }> = [];
    ddValuesAPICallFlag: boolean = false;
    selectedDdValues: Array<any> = [];

    constructor(inputGroup?: InputGroup) {
        Object.assign(this, inputGroup);
    }
}



export class DependentMeasurementInfo implements IDependentMeasurementInfo {
    VariableName: string;
    MeasurementId: number;
    StuiTypeId: number;
    StuiId: number;
    ApplicableContextPointTypes: number[];
    ApplicableMeasurementIds: number[];
    constructor(dependentMsrInfo?: DependentMeasurementInfo) {
        Object.assign(this, dependentMsrInfo);
    }
}

export class assetPortMsrDtls {
    PortName: string;
    DatasourceName: string;
    MeasurementName: string;
    MeasurementInstanceId: number;
    AssetName: string;
    MeasurementId? : number;
    StuiName: string;
    MeasurementSource: string;
    ContextPointTypeId: number;
    ContextPointSubTypeId: number;
    ContextPointId:number;

}

export class measurementInstanceCalculationMap {
    ContextPointId: number = 0;
    DisplayName: string = '';
    FromMeasurementInstanceId: number = 0;
    ToMeasurementInstanceId: number = 0;
    CalculationTypeId: number = 0;
    IsColdPath: boolean = false;
    VariableName: string = '';
    StartOn: string = '';
    EndOn: string = '';
    ModifiedBy: number = 0;
    ModifiedOn: string = '';
    dataSourceMeasurementInstanceID: number;
    // PortName: string;
    // DatasourceName: string;
    // MeasurementName: string;
    // MeasurementInstanceId: number;
    // AssetName: string;
    constructor(msrInsCalcMap?: measurementInstanceCalculationMap) {
        Object.assign(this, msrInsCalcMap);
    }
}