<div class="custom-date-picker row">
  <div class="row startDate col-sm-12 col-lg-12">
    <div class="col-lg-6 col-sm-12 date-field-first">
      <p [ngClass]="{ disabled: isDisabled }" class="start-label" translate>
        COMMON.START_DATE
      </p>
      <span>
        <kendo-datepicker [disabled]="isDisabled" #element3 placement="bottom"
          [format]="dateFormat? dateFormat : 'MM/dd/yyyy'"
          [popupSettings]="popupSettings"
          [bottomView]="bottomView"
          [autoFill]="false"
          [autoCorrectParts]="false"
          [disabledDates]="disabledStartDates" 
          [formatPlaceholder]="{year: 'YYYY',month: 'MM',day: 'DD'}"
          [value]="sDate"
          (valueChange)="onChange($event, 'startDate')">
          <kendo-datepicker-messages today="{{ 'DATA_VISUALIZATION.TIME_RANGE.TODAY' | translate }}">
          </kendo-datepicker-messages>
        </kendo-datepicker>
      </span>
    </div>
    <div class="col-lg-6 col-sm-12 date-field">
      <span>
        <p [ngClass]="{ disabled: isDisabled }" class="start-label" translate>
          COMMON.END_DATE
        </p>
        <kendo-datepicker [disabled]="isDisabled" #element4 placement="bottom"
          [format]="dateFormat? dateFormat : 'MM/dd/yyyy'"
          [bottomView]="bottomView"
          [autoFill]="false"
          [autoCorrectParts]="false"
          [disabledDates]="disabledEndDates" 
          [formatPlaceholder]="{year: 'YYYY',month: 'MM',day: 'DD'}"
          [value]="eDate"
          [popupSettings]="popupSettings" (valueChange)="onChange($event, 'endDate')">
          <kendo-datepicker-messages today="{{ 'DATA_VISUALIZATION.TIME_RANGE.TODAY' | translate }}">
          </kendo-datepicker-messages>
        </kendo-datepicker>
      </span>
    </div>
  </div>
  <div *ngIf="showApplyButton" class="modal-footer col-12" style="border-top: none;">
    <a class="clear date-clear" *ngIf="!isDisabled" (click)="clearFilters()" translate>COMMON.CLEAR</a>
    <nalco-button [buttonData]="buttonPrimary" [disabled] = "disabled" (click)="applySelectedDate()"></nalco-button>
  </div>
</div>