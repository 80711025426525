import * as contracts from "../contracts/_contracts";
import { Unit } from "./Unit";

export class Area implements contracts.IArea {
    id: string;
    name: string;
    sortOrder?: number;
    type: string;
    action: string;
    units: Array<Unit>;
    isdeleted?: boolean;
    isCollapsed? = false;

    constructor(area?: contracts.IArea) {
        if (area) {
            this.id = area.id;
            this.name = area.name;
            this.sortOrder = area.sortOrder;
            this.type = area.type;
            this.action = area.action;
            this.isdeleted = area.isdeleted;
            if (area.units) {
                this.units = new Array<Unit>();
                for (let unit of area.units) {
                    this.units.push(new Unit(unit));
                }
            }
        }
    }

}

