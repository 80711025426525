import * as moment from 'moment';
import {IDropdownOption } from '../../shared/components/dropdown/dropdown-model';

export const FiscalPeriod:Array<IDropdownOption> = [
    {
        text: 'Jan - Dec',
        value: '1'
    },
    {
        text: 'Feb - Jan',
        value: '2'
    },
    {
        text: 'Mar - Feb',
        value: '3'
    },
    {
        text: 'Apr - Mar',
        value: '4'
    },
    {
        text: 'May - Apr',
        value: '5'
    },
    {
        text: 'Jun - May',
        value: '6'
    },
    {
        text: 'Jul - Jun',
        value: '7'
    },
    {
        text: 'Aug - Jul',
        value: '8'
    },
    {
        text: 'Sep - Aug',
        value: '9'
    },
    {
        text: 'Oct - Sep',
        value: '10'
    },
    {
        text: 'Nov - Oct',
        value: '11'
    },
    {
        text: 'Dec - Nov',
        value: '12'
    }

];

export const UnitOfMeasure:Array<IDropdownOption> = [
    {
        text: 'US Standard',
        value: '0'
    },
    {
        text: 'Metric',
        value: '1'
    }

];

export const Months: Array<IDropdownOption> = [
  {
    text: 'Jan',
    value: '1'
  },
  {
    text: 'Feb',
    value: '2'
  },
  {
    text: 'Mar',
    value: '3'
  },
  {
    text: 'Apr',
    value: '4'
  },
  {
    text: 'May',
    value: '5'
  },
  {
    text: 'Jun',
    value: '6'
  },
  {
    text: 'Jul',
    value: '7'
  },
  {
    text: 'Aug',
    value: '8'
  },
  {
    text: 'Sep',
    value: '9'
  },
  {
    text: 'Oct',
    value: '10'
  },
  {
    text: 'Nov',
    value: '11'
  },
  {
    text: 'Dec',
    value: '12'
  }

];

export const CommitementType: Array<IDropdownOption> = [
  {
    text: 'Approved',
    value: '1'
  },{
    text: 'Implemented',
    value: '3'
  },{
    text: 'Final Sign Off',
    value: '4'
  }

];

export const AssignmentTime: Array<IDropdownOption> = [
  {
    text: 'Immediate',
    value: '1'
  },{
    text: 'Phased',
    value: '0'
  }

];

export const AssignmentLength: Array<IDropdownOption> = [
  {
    text: '1 year',
    value: '1'
  },
  {
    text: '2 year',
    value: '2'
  },
  {
    text: '3 year',
    value: '3'
  },
  {
    text: 'Contract Length',
    value: '0'
  }

];


export const IdleTimeoutConfig = {
  //sets an idle timeout in seconds
  setIdleTime: 1620, // 27 minutes of idle time
  // sets a timeout period in seconds. after 10 seconds of inactivity
  setIdleTimeout: 180, // 3 minutes for timeout
  // sets initial idle state
  // sets the ping interval
  setKeepAliveInterval: 15,
};
