<div class="accounts-popup">
  <div class="modal-header">
    <h4 class="modal-title" translate>ASSET_HARVESTER.HEADER.ACCOUNT_DETAILS</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
      <img src="../../../../assets/images/modal-close-icon.svg" alt="close" />
    </button>
  </div>

  <div class="mb-0 delete-content">
    <nalco-loader [loader]="isLoading" [transparentBG]="true" [fitToScreen]="true"></nalco-loader>
    <div class="account-name">
      {{'ASSET_HARVESTER.MESSAGE.ACCOUNT_NAME' | translate}} : {{accountname}}
    </div>
    <div class="site-name">
      {{'ASSET_TREE.MESSAGE.AUDIT_SITE_NAME' | translate}} : {{sitename}}
    </div>
    <diV class="on-boarding" *ngIf="showOnboardingCheckbox">
      <div style="width: 50%; float: left;"> 
        <span>{{'ASSET_TREE.MESSAGE.REGISTERED' | translate}} :</span>
        <input type='checkbox' [(ngModel)]="IsOnboarding" style="margin: 10px;">
      </div>
      <div> 
        <span>{{'ASSET_TREE.MESSAGE.TRIAL' | translate}} :</span>
        <input type='checkbox' [(ngModel)]="IsTrialFlag" style="margin: 10px;">
      </div>
    </diV>
    <div class="table-wrapper" #accountResults infiniteScroll [infiniteScrollContainer]="accountResults"
      [infiniteScrollDistance]="1"  [infiniteScrollThrottle]="500"   [scrollWindow]="false">
      <kendo-grid [data]="accountDetails">
        <kendo-grid-column field="AccountNumber" [title]="'ASSET_HARVESTER.MESSAGE.ACCOUNT_NUMBER' | translate">
        </kendo-grid-column>
        <kendo-grid-column field="CustomerName" [title]="'ASSET_HARVESTER.MESSAGE.ACCOUNT_NAME' | translate">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="overflow-ellipsis" placement='bottom' [ngbTooltip]=" dataItem.CustomerName">
              {{dataItem.CustomerName}}
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="Location" [title]="'ASSET_HARVESTER.MESSAGE.LOCATION' | translate">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="overflow-ellipsis" placement='bottom' [ngbTooltip]=" dataItem.Location">{{dataItem.Location}}
            </div>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </div>
  </div>
  <div class="modal-footer mt-0">
    <button type="button" (click)="saveOnBoarding()" *ngIf="showOnboardingCheckbox"  [disabled]="IsOnboarding === IsOnboardingPersistOldValue && IsTrialFlag === IsTrialFlagPersistOldValue"  class="btn btn-primary">{{'COMMON.SAVE'| translate}}</button>
    <button type="button"  (click)="activeModal.close()"  class="btn btn-secondary">{{'COMMON.CLOSE'| translate}}</button>
  </div>
</div>
