import * as contracts from '../contracts/_contracts';
import { Area } from './Area';

export class AssetTree implements contracts.IAssetTree {
    // Declare all properties coming from Contract
    id: string;
    lockId?: string;
    customer?: string;
    siteName?: string;
    allAssetsAccountedFor: boolean;
    areas: Array<Area>;
    lastEditedBy: string;
    lastEditedOn: string;
    access: string;
    lockStatus: {
        isLocked: boolean;
        lockedBy: string;
    };

    // Populate the model from the contract
    constructor(assetTree?: contracts.IAssetTree) {
        if (assetTree) {
            this.id = assetTree.id;
            this.lockId = assetTree.lockId;
            this.customer = assetTree.customer;
            this.siteName = assetTree.name;
            this.allAssetsAccountedFor = assetTree.allAssetsAccountedFor;
            // Populate any array object from its constructor
            if (assetTree.areas) {
                this.areas = new Array<Area>();
                for (let area of assetTree.areas) {
                    this.areas.push(new Area(area));
                }
            }
        }
    }
}
