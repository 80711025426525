import { Component, OnInit } from '@angular/core';
import { ViewChild, ElementRef } from '@angular/core';
import { Observable, of } from 'rxjs';
import { startWith, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { UserService } from '../core/user/user.service';
import { selectSubChildOption, selectChildOption, selectParentOption, newSearchResult } from '../app.model';
import { FilterNavigation } from '../core/pipes/filter-navigation.pipe';
import { Response } from '../shared-main/components/navigation/navigation.json';
import { INavigationMenu } from '../shared/contracts/INavigation';
import { ApiService } from './globalsearch.component.service';


@Component({
  selector: 'nalco-global-search',
  templateUrl: './globalsearch.component.html',
  styleUrls: ['./globalsearch.component.css']
})



export class SearchComponent implements OnInit {
  searchTerm: string;
  @ViewChild('myInput', {read: ElementRef, static: false }) myInput: ElementRef;
  siteCount: number = 50;
  searchKey: string;      
   baseURL: string = "#";
  apiData:selectSubChildOption[] = [];
  selectParentOption: Observable<selectParentOption[]>;
  selectChildOption: selectChildOption[] = [];
  myControl = new UntypedFormControl('');
  navigationMenu: any = [];
  leftNavigationItems: INavigationMenu[];
  filteredOptions: Observable<newSearchResult[]>;
  newFilteredOptions: newSearchResult[] = [];
  displayData: selectParentOption[];
  constructor(
      private userService: UserService,
      private apiService: ApiService,
      private pipe : FilterNavigation
    ) {
  }
  /**
   * @description help in filtering the search menu dependent on input query 
   */
  private filteringOptions() : void{
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(searchKey => this.getSearchResultsFromApi(searchKey)),
      switchMap(searchResults => {
        this.apiService.inputVal = this.searchKey;
        this.apiData = searchResults;
        //let colonPresent = false;       
          // if(this.searchKey.includes(":")){
          //   colonPresent = true;
          // }   
          //return of(this.returnDataToBeDisplayed(this.searchKey, colonPresent));
          return of(this.newReturnDataToBeDisplayed());
        }
      )
    )
    this.filteredOptions.subscribe(param => {
      if(param && param.length>0 && param[0].SiteId != null){
        this.newFilteredOptions = param.filter((model, index, self) =>
          index === self.findIndex((m) => (
            JSON.stringify(m) === JSON.stringify(model)
          ))
        );
      }else{
        this.newFilteredOptions = [];
      }
    })  
      
  }
  /**
   * @description a method to decide the final data to be displayed under the searchbar
   * @returns the final data to be displayed under the searchbar
   */
  newReturnDataToBeDisplayed() : newSearchResult[]{
    let combinedData = this.apiData;
    let combinedDataCopy = JSON.parse(JSON.stringify(combinedData));
    return combinedDataCopy;
  }
  /**
   * @description retains the search input field
   */
  inputRetentionFunction() : void{
    setTimeout(() => {
      this.searchTerm = this.apiService.inputVal;
    }, 0)
  }
  ngOnInit() {
    this.filteringOptions();
    this.getUserSettings();
  }
  ngAfterViewInit() {
    setTimeout(() => { this.myInput.nativeElement.focus(); this.inputRetentionFunction()}, 0);
  }
  /**
   * @description fetches the data from api
   * @param searchKey the search query that user enters into the UI
   * @returns Data Fetched from api calls
   */
  getSearchResultsFromApi(searchKey: string) : Observable<selectSubChildOption[]>{
    this.searchKey = searchKey;
    if(searchKey.length > 2){
      return this.apiService.getData(searchKey, this.siteCount);
    }
    else{
      const defaultApiDataModel = [new selectSubChildOption()];
      return of(defaultApiDataModel);
    }
  } 


  /**
   * @description a method to decide the final data to be displayed under the searchbar
   * @param val the search query that the user enters into the UI
   * @param colonPresent a boolean representing if a colon is present in the search query
   * @returns the final data to be displayed under the searchbar
   */
  returnDataToBeDisplayed(val: string, colonPresent: boolean) : selectSubChildOption[]{
    this.getUserSettingsForAPIData();
    let combinedData = this.apiData;
    let combinedDataCopy = JSON.parse(JSON.stringify(combinedData));
    let filteredData;
    if(colonPresent){
      return combinedDataCopy;
    }
    else{
      filteredData = this.filter(val, this.displayData);
      combinedDataCopy = filteredData.concat(combinedDataCopy);
    }
    this.apiData = [new selectSubChildOption()];
    return combinedDataCopy;
  }


  /**
   * @description filters the api data according to User permissions
   * @param role set of user permissions
   * @param fullMenu all the available navigation data
   * @returns the api data filtered according to the user permissions
   */
  apiFilterByUserRole(role : string[], fullMenu: selectSubChildOption[]) : selectSubChildOption[] {
    const userSettings = this.userService.getCurrentSettings();
    let navigationMenu = [];
    if (role) {
      //const pipe = new FilterNavigation(); 
      navigationMenu = this.pipe.apitransform(role, fullMenu);
      return navigationMenu;
    } else {
      return navigationMenu;
    }
  }


  /**
   * @description filters the default data according to User permissions
   * @param role set of user permissions
   * @param navigationDetail all the available navigation data
   * @returns the default data filtered according to the user permissions
   */
  filterByUserRole(role, navigationDetail) : selectSubChildOption[]{
    const userSettings = this.userService.getCurrentSettings();
    let navigationMenu = [];
    if (role) {
      //const pipe = new FilterNavigation();
      navigationMenu = this.pipe.transform(role, navigationDetail.leftNavigation, userSettings.IsInternalUser, false, []);
      return navigationMenu;
    } else {
      return navigationMenu;
    }
  }


  /**
   * @description Maps the default data as a parent-child model
   */
  getUserSettings() : void {
    const userSettings = this.userService.getCurrentSettings();
    const role = this.userService.getUserRole();
    const userPermissions = [];
    if (role) {
      role.forEach(function (s) {
        userPermissions.push(s.PermissionId.toString());
      });
    }
    const fullMenu = JSON.parse(JSON.stringify(Response));
    //this.navigationMenu = fullMenu;
    this.navigationMenu.leftNavigation = this.filterByUserRole(userPermissions, fullMenu);
    let distinctParentData = this.navigationMenu.leftNavigation.filter(x => x.flag === "true")
    this.displayData = distinctParentData.map(res => {
      return {
        "Parent": res.searchParent, "URL": res.link, "Child": res.subNavigation.map(response => {
          return { "Service": response.searchName, "ShortName": response.shortName, "URL": response.link }
        })
      }
    })
  }


  /**
   * @description Maps api data as a parent-child model
   */
  getUserSettingsForAPIData() : void {
    const userSettings = this.userService.getCurrentSettings();
  
    const role = this.userService.getUserRole();
    const userPermissions = [];
    if (role) {
      role.forEach(function (s) {
        userPermissions.push(s.PermissionId.toString());
      });
    } 
  
    const fullMenu = JSON.parse(JSON.stringify(this.apiData));
    //this.navigationMenu = fullMenu;
    this.navigationMenu.leftNavigation = this.apiFilterByUserRole(userPermissions, fullMenu);
    
    const mergedData = this.navigationMenu.leftNavigation.reduce((acc, curr) => {
      const existingParent = acc.find(item => item.Parent === curr.serviceName);
      
      if (existingParent) {
        existingParent.Child.push({
          "Service": curr.SearchDisplayName,
          "URL": curr.DeeplinkUrl,
          "SiteName": curr.SiteName,
          "CorpAcctId": curr.CorpAcctId,
          "PermissionId": curr.PermissionId,
          "ShortName": curr.ShortName
        });
      } else {
        acc.push({
          "Parent": curr.serviceName,
          "Child": [{
            "Service": curr.SearchDisplayName,
            "URL": curr.DeeplinkUrl,
            "SiteName": curr.SiteName,
            "CorpAcctId": curr.CorpAcctId,
            "PermissionId": curr.PermissionId,
            "ShortName": curr.ShortName
          }]
        });
      }
      
      return acc;
    }, []);
  
    this.apiData = mergedData;
  }
  
  
  /**
   * @description filters the default data using substring-match
   * @param val the search query that user enters into the UI
   * @param displayData the default data
   * @returns the data filtered based on the search query
   */
  filter(val: string, displayData: selectParentOption[]) : selectParentOption[]{
    val = val ? val.toLowerCase() : "";
    val = val.replace(/\s+/g, ' ');
    let displayDataCopy = JSON.parse(JSON.stringify(displayData));
    if (val == " " || val == "") {
      return displayDataCopy;
    }
    if (val.length != 1 && val[0] == " ") {
      val = val.substring(1);
    }
    let valLength = val.length;
    if (val.length != 1 && val[valLength - 1] == " ") {
      val = val.substring(0, valLength - 1);
    }
    let data = displayDataCopy.map((response: selectParentOption) => {
      let f: selectChildOption[]
      if (response.Parent.toLowerCase().includes(val) ) {
        return response;
      }
      else {
        f = response.Child.filter((option: selectChildOption) => {
          return option?.Service?.toLowerCase().includes(val) || (('ShortName' in option) ? option?.ShortName?.toLowerCase().includes(val) : false);
        });
        response.Child = f;
        return response;
      }
    }).filter((response: selectParentOption) => {
      return (response.Parent.toLowerCase().includes(val) || response.Child.length > 0);
    });
    return data;
  }
}