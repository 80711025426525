<div class="parameters-popup">
  <div class="modal-header">
    <h3 class="modal-title">{{'QUALITY.HEADER_LABEL.PARAMETERS_KPI_POPUP'| translate}}&nbsp;{{assetName}}</h3>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
      <span class="ico-exit-round-edge"></span>
    </button>
  </div>
  <div class="modal-body scrollbar-border kpi-modal-body">
    <div *ngIf="isLoading; else wq_kpi_measurements">
      <div class="loader"></div>
    </div>
    <ng-template #wq_kpi_measurements>
      <ng-container *ngIf="measurements.length > 0; else empty_kpi">
        <div *ngFor="let item of measurements; let i = index">
          <div class="measurement-name"> {{ item.MappingLocationName }} </div>
          <div class="dashed-line"></div>
          <div class="row mb-2">
            <div class="cell-spacing col-xl-4 col-md-4 col-sm-12" *ngFor="let measurementName of item.ParameterMeasurementNames">
              {{measurementName}}
            </div>
          </div>
        </div>
      </ng-container>
    </ng-template>
    <ng-template #empty_kpi>
      <ng-container>{{'COMMON.NO_PARAMETER'| translate}}</ng-container>
    </ng-template>
  </div>
  <div class="modal-footer">
    <button type="button"  (click)="activeModal.close()"  class="btn btn-primary">{{'COMMON.CLOSE'| translate}}</button>
  </div>
</div>