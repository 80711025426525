<ul class="filtertab-list float-left">
  <span [hidden]="multiFilter == true">
    <ng-container *ngIf="isPrintPreview">
      <div *ngFor="let item of primaryFilters; let index = index">
      <li  [ngClass]="{'active': isSelectedFilter('primary', item.id), 'iconClass': item.iconClass != '' && item.iconClass != undefined}" (click)="changePrimaryFilter($event, item)" *ngIf="item.id === 0">
        <span class="required-chinese-font" #element [ngbTooltip]="element.scrollWidth > element.offsetWidth + 1 ? item.name : ''">{{ item.name }}</span>
        <span class="{{ Icons[item.iconClass] }}"></span>
      </li>
    </div>
    </ng-container>
    <ng-container  *ngIf="!isPrintPreview">
      <ng-container *ngFor="let item of primaryFilters; let index = index">
        <li [ngClass]="{'active': isSelectedFilter('primary', item.id), 'iconClass': item.iconClass != '' && item.iconClass != undefined}" (click)="changePrimaryFilter($event, item)">
          <span class="required-chinese-font" #element [ngbTooltip]="element.scrollWidth > element.offsetWidth + 1 ? item.name : ''">{{ item.name }}</span>
          <span class="{{ Icons[item.iconClass] }}"></span>
        </li>
      </ng-container>
    </ng-container>
  </span>
  <span [hidden]="multiFilter !== true">
    <li *ngFor="let item of primaryFilters"
      [ngClass]="{'active': isSelectedFilter('primary', item.id), 'iconClass': item.iconClass != ''}"
      (click)="changePrimaryFilter($event, item)">
      <span class="filters-label-span required-chinese-font" #element [ngbTooltip]="element.scrollWidth > element.offsetWidth+1 ? item.name : ''"> {{item.name}} </span>
      <span class="{{Icons[item.iconClass]}}"></span>
    </li>
  </span>
  <span *ngIf="showInfoIcon" class="sites-view">
      <span class="ico-info-thin" placement="bottom" [ngbTooltip]="tooltipContentt"></span>
      <ng-template #tooltipContentt>
        <div [innerHTML]="htmlContent"></div>
      </ng-template>
    </span>
</ul>
<ul class="filtertab-checkbox float-right" *ngIf="secondaryFilters.length">
  <!-- <ng-container *ngFor="let it of secondaryFilters; let i=index">
    <li [ngClass]="{'active': isSelectedFilter('secondary', secondaryFilters[i].id)}" (click)="toggleSecondaryFilter($event, secondaryFilters[i])">
      <span [ngClass]="secondaryFilters[i].iconClass"></span><span>{{secondaryFilters[i].name}}</span>
    </li>
  </ng-container> -->
  <span *ngIf="showFilterText" class="filter-text">{{secondaryFilterText}}: </span>
  <li [ngClass]="{'active': isSelectedFilter('secondary', secondaryFilters[0].id)}" 
   (click)="toggleSecondaryFilter($event, secondaryFilters[0])">
    <span [ngClass]="secondaryFilters[0].iconClass"></span>{{secondaryFilters[0].name}}
  </li>
  <li [ngClass]="{'active': isSelectedFilter('secondary', secondaryFilters[1].id)}"
   (click)="toggleSecondaryFilter($event, secondaryFilters[1])">
    <span [ngClass]="secondaryFilters[1].iconClass"></span>{{secondaryFilters[1].name}}
  </li>
  <li [ngClass]="{'active': isSelectedFilter('secondary', secondaryFilters[2].id)}"
   (click)="toggleSecondaryFilter($event, secondaryFilters[2])">
    <span [ngClass]="secondaryFilters[2].iconClass"></span>{{secondaryFilters[2].name}}
  </li>
</ul>
