import { Injectable } from '@angular/core';
import { LocalCacheService } from './../../core/local-cache/local-cache.service';
import { VULNERABLE_CHAR } from '../../../app/app.config';
// import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv'

@Injectable()
export class CsvExportService {

  constructor(private storage: LocalCacheService) { }

  private data: { [key: string]: any } = {};
  private line: any = '';
  private headerContent = [];

  // create custom Header content.
  public generateHeaderData(headerData): any {

    this.line = '';
    this.headerContent = [];
    // Header Data in csv format
    headerData.forEach(element => {
      const key = Object.keys(element)[0];
      const value = Object.values(element)[0];

      this.line += key + ',' + value;
      this.line += ', \n \n ';
    });
    this.headerContent.push(this.line);
    // this.headerContent.join('\n');

    return this.headerContent;
  }

  // remove content from Json Object
  public removeObjectKey(exportData, arrDeleteKey): void {

    exportData.forEach(element => {
      arrDeleteKey.forEach(innerelement => {
        delete element[innerelement];
      });
    });
    return exportData;
  }

  // remove content from Json Object
  public generateCSV(exportData, csvTitle, options, moreoptions, headerData): any {
    // remove undefined content from json Object

    for (const propertyKey in exportData) {
      if (exportData.hasOwnProperty(propertyKey)) {
        // eslint-disable-next-line guard-for-in
        for (const i in exportData[propertyKey]) {
          if (typeof (exportData[propertyKey][i]) === 'undefined' || (exportData[propertyKey][i]) === null) {
            exportData[propertyKey][i] = '';
          }
          if (typeof (exportData[propertyKey][i]) === 'string') {
            exportData[propertyKey][i] = exportData[propertyKey][i].replace(/,/g, '-');
          }
          if (typeof (exportData[propertyKey][i]) === 'object') {
            exportData[propertyKey][i] = exportData[propertyKey][i]['Value'];
            if (typeof (exportData[propertyKey][i]) === 'undefined' || (exportData[propertyKey][i]) === null) {
              exportData[propertyKey][i] = '-';
            }
          }
        }
      }

    }
    if (moreoptions.length > 0) {
      this.removeObjectKey(exportData, moreoptions);
    }
    options.title = this.generateHeaderData(headerData);

    // const AllDetails = [];
    // AllDetails.push(exportData);
    // AllDetails.push(csvTitle);
    // AllDetails.push(options);


    return {
      exportData: exportData,
      csvTitle: csvTitle,
      options: options
    };

  }

  public getSiteFilterDetails(): Array<string> {
    const filteredString = [];
    const siteLevelFilter = JSON.parse(this.storage.fetchData('appliedfilterDetails', 'local'));
    if (siteLevelFilter && siteLevelFilter.contextPointId != null) {
      if (siteLevelFilter.filterObject && siteLevelFilter.filterObject.parentId !== '') {
        filteredString.push('CVOC');
        filteredString.push(siteLevelFilter.filterObject.parentName + this.getChildrenDetails(siteLevelFilter.filterObject.children));
      } else if (this.getChildrenDetails(siteLevelFilter.filterObject.children)) {        
        filteredString.push('EVOC');
        filteredString.push((this.getChildrenDetails(siteLevelFilter.filterObject.children)).substr(3));
      }
    }
    return filteredString;
  }

  private getChildrenDetails(childrens): string {
    let childString = '';
    childrens.forEach(child => {
      childString += ' / ' + child.contextPointName;
    });
    return childString;
  }

  /*
  * getCorporatNameOrEvocLastNode method returns the
  * first node for CVOC or last node for EVOC will be picked from the applied filter.
  */
  public getCorporatNameOrEvocLastNode(hostView?) {
    if (hostView && hostView !== 'actionlog') {
      const appliedFilter = JSON.parse(this.storage.fetchData('appliedfilterDetails', 'local'));
      if (appliedFilter && appliedFilter.filterObject) {
        const fobj = appliedFilter.filterObject;
        if (fobj.parentId !== '') {
          return fobj.parentName + '-';
        } else {
          if (fobj.children.length) {
            return fobj.children[fobj.children.length - 1].contextPointName + '-';
          }
        }
      }
    }
    return '';
  }

  public removeVulnerables(exportData: any) {
    for (const propertyKey in exportData) {
      if (exportData.hasOwnProperty(propertyKey)) {
        for (const i in exportData[propertyKey]) {
          if (typeof (exportData[propertyKey][i]) === "string") {
            if (VULNERABLE_CHAR.find(it => it === (((exportData[propertyKey][i])).substr(0, 1)))) {
              exportData[propertyKey][i] = "'" + (exportData[propertyKey][i]);
            }
          }
        }
      }
    }
    return exportData;
  }

  // will be called on event
  public ExportAsCSV(data: any, filename: string, option: any) {
    const exportData = this.removeVulnerables(data);
    
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    // new Angular2Csv(exportData, filename, option);
    new AngularCsv(exportData, filename, option);
  }

}
