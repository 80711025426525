import { IDataSourceDetail } from '../_contracts/IDataSourceDetail';

export class DataSourceDetail implements IDataSourceDetail {
    DataSourceContextPointId: number;
    DataSourceType: string;
    DataSourceName: string;
    LatestMeasurementTakenDate: string;
    AssetName: string;
    PortName: string;
    MappedMeasurementCount: string
  constructor(details?: IDataSourceDetail) {
    if(details){
      this.DataSourceContextPointId = details.DataSourceContextPointId;
      this.DataSourceType = details.DataSourceType;
      this.DataSourceName = details.DataSourceName;
      this.LatestMeasurementTakenDate = details.LatestMeasurementTakenDate;
      this.AssetName = details.AssetName;
      this.PortName = details.PortName;
      this.MappedMeasurementCount = details.MappedMeasurementCount;
    }
  }
}

