/* eslint-disable @typescript-eslint/consistent-type-imports */
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationHandlerService } from '../core/nav/navigation-handler.service';
import { UserService } from '../core/user/user.service';
import { MenuItems, SelectedMenus } from './contracts/menu';
import { MenusResponse } from './menus.json';
import { MenusValues } from './models/menu-values';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AppEnvConfig } from '../app.env.config';
import { AppDynamicsService } from '../core/analytics/app-dynamics.service';
import { FilterNavigation } from '../core/pipes/filter-navigation.pipe';
import { User } from '../shared/models/_models';
import { MenuService } from './menu.service';
import { DividerOption } from '../shared-ui-refresh/divider/divider.model';

@Component({
    selector: 'nalco-menu',
    templateUrl: './menu.component.html',
})
export class MenuComponent implements OnInit , OnDestroy {

    user: User;
    isNavigationDisabled = false;
    isExternalUser = false;
    menuList: MenusValues;
    useExternalNavigation = true;
    useLocalNavigation = true;
    private subscriptions: Subscription[] = [];
    leftTopNavigationItems: MenuItems[];
    leftBottomNavigationItems: MenuItems[];
    leftMidNavigationItems: MenuItems[];
    dividerCss: DividerOption
    isMobile = false;
    public isEnvisionMenuHide: boolean;
    isCredentialMenuHide: boolean;
    isClimateIntelligenceNavigation: string;
    isCrudeFlexEnabled: string;
    isFSQSEnabled: string;
    isExpand : boolean;
    isNewUIEnabled : boolean;

    constructor(
        private navService: NavigationHandlerService,
        private router: Router,
        private config: AppEnvConfig,
        private translate: TranslateService,
        private appDynamicsService: AppDynamicsService,
        private userService: UserService,
        private meta: Meta,
        private menuService: MenuService
    ) {
        this.isEnvisionMenuHide = JSON.parse(config.getEnv('isEnvisionMenuHide').toLowerCase());
        this.isCredentialMenuHide = config.getEnv('isCredentialMenuHide');
        this.isClimateIntelligenceNavigation = config.getEnv('isClimateIntelligenceNavigation').toString();
        this.isCrudeFlexEnabled = config.getEnv('isCrudeFlexEnabled').toString();
        this.isFSQSEnabled = config.getEnv('isFSQSEnabled');
        navService.navToggle$.subscribe(state => {
            this.isNavigationDisabled = state;
        });
        this.useExternalNavigation = false; //  environment.useExternalNavigation;
        this.useLocalNavigation = true; // environment.useLocalNavigation;
    }

    ngOnInit() {
        this.isMobile = (/android|webos|iphone|ipad|ipod|blackberry|windows phone/).test(navigator.userAgent.toLowerCase());
        this.getNavigationMenu();
        this.isNewUIEnabled = JSON.parse(this.config.getEnv('isNewUIEnabled').toString());
        this.subscriptions.push(this.navService.updateLeftNav$.subscribe(async (update) => {
            this.getNavigationMenu();
        }));
        this.subscriptions.push(this.menuService.navExpand.subscribe(value =>
            this.isExpand = value
        ));
        this.dividerCss = {
            color: "#bac7d8",
            minLength: "30px",
            maxLength: "170px",
            isHorizontal: true
        }
    }
    private hideLeftSubMenuLinks() {
        if (this.isEnvisionMenuHide) {
            this.leftMidNavigationItems.forEach((s, index) => {
                if (s.label === 'LEFT_NAV.MAIN_NAV_TOOLS') {
                    this.leftMidNavigationItems.splice(index, 1);
                }
            });
        }
        if (!this.isCredentialMenuHide) {
            this.leftMidNavigationItems.forEach((s, index) => {
                if (s.label === 'LEFT_NAV.ADMIN_NAV_LABEL') {
                    s.subNavigation.forEach((d, i) => {
                        if (d.label === 'LEFT_NAV.SUBNAV_CREDENTIAL_MANAGEMENT.NAV_LABEL') {
                            this.leftMidNavigationItems[index].subNavigation.splice(i, 1)
                        }
                    })
                }
            });
        }
        if (this.isClimateIntelligenceNavigation == "false") {
            this.menuList.leftMidNavigation.forEach((s, index) => {
                if (s.label === 'LEFT_NAV.MAIN_NAV_LABEL') {
                    s.subNavigation.forEach((d, i) => {
                        if (d.label === 'LEFT_NAV.SUBNAV_CLIMATE.NAV_LABEL') {
                            this.menuList.leftMidNavigation[index].subNavigation.splice(i, 1)
                        }
                    })
                }
            });
        }
        if (this.isCrudeFlexEnabled === "false") {
            this.menuList.leftTopNavigation.forEach((s, index) => {
                if (s.label === 'LEFT_NAV.MAIN_NAV_LABEL') {
                    s.subNavigation.forEach((d, i) => {
                        if (d.label === 'LEFT_NAV.SUBNAV_CRUDE_FLEX_CORPORATION.NAV_LABEL') {
                            this.menuList.leftTopNavigation[index].subNavigation.splice(i, 1)
                        }
                    })
                }
            });
        }
        if (this.isFSQSEnabled === "false") {
            this.menuList.leftTopNavigation.forEach((s, index) => {
                if (s.label === 'LEFT_NAV.MAIN_NAV_LABEL') {
                    s.subNavigation.forEach((d, i) => {
                        if (d.label === 'LEFT_NAV.SUBNAV_FSQS.NAV_LABEL') {
                            this.menuList.leftTopNavigation[index].subNavigation.splice(i, 1)
                        }
                    })
                }
            });
        }
        if(this.config.getEnv("IsE3DChina") === "true"){
            this.menuList.leftMidNavigation.forEach((s, index) => {
              if (s.label === 'LEFT_NAV.MAIN_NAV_TOOLS') {
                s.subNavigation.forEach((d, i) => {
                  if (d.label === 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATOR') {
                    this.menuList.leftMidNavigation[index].
                        subNavigation[i].link = 'Envision_Configurator_China';
                  }
                })
              }
            });
        }
    }

    /**
      * @description Navigate to the functions based on the link availabitliy
      * @param SelectedMenus
      */


    menuItemClicked(event: SelectedMenus) {
        if (event.link) {
            this.navigateToLinks(event);
            // To check if the new UI for the expand is enabled or not 
            this.menuService.navExpand.next(JSON.parse(this.config.getEnv('isNewUIEnabled').toString()) && this.isExpand? this.isExpand : false);
        } else {
            this.highlightMenus(event);
        }
    }

    /**
   * @description Navigate to the links based on the menus.json.ts
   * @param SelectedMenus
   */


    navigateToLinks(event: SelectedMenus) {
        if (event.link == '/admin-alarm') {
            sessionStorage.removeItem('UserLibSelection');
            sessionStorage.setItem('navigatedFromComponent', 'DashboardMenu');
            if (this.router.url == '/admin-alarm') {
                window.location.reload();
                sessionStorage.removeItem('UserLibSelection');
            }
        }
        if (event.link == '/WFI-alarm') {
            sessionStorage.removeItem('UserLibSelection');
            sessionStorage.setItem('navigatedFromComponent', 'DashboardMenuAlarm');
            if (this.router.url == '/WFI-alarm') {
                window.location.reload();
                sessionStorage.removeItem('UserLibSelection');
            }
        }
        let navigationLink = '';
        if (this.useExternalNavigation) {
            navigationLink = event.link;
        } else {
            // Need to discuss on this more on why this is used
            // if (event.link.substr(event.link.indexOf('#') > 2)) {
            //     navigationLink = event.link.substr(event.link.indexOf('#') + 1);
            // }
            navigationLink = event.link;
        }
        if (navigationLink.indexOf('settings') > -1) {

            if (event.siteId === undefined || event.siteId === null) {

                return false;
            } else if (event.siteId !== '') {

                this.routeToComponent(navigationLink, { queryParams: { siteId: event.siteId } });
            } else {

                this.routeToComponent(navigationLink);
            }
        } else {
            event.siteId = null;

            this.routeToComponent(navigationLink);
        }
        this.highlightMenus(event);
    }

    /**
   * @description Gets enabled Menus after the selections
   * @param SelectedMenus
   * @returns all the menus as per the roles
   */

    highlightMenus(event: SelectedMenus) {
        this.leftTopNavigationItems = this.menuService.enableSelection(event.text, this.menuList.leftTopNavigation);
        this.leftBottomNavigationItems = this.menuService.enableSelection(event.text, this.menuList.leftBottomNavigation);
        this.leftMidNavigationItems = this.menuService.enableSelection(event.text, this.menuList.leftMidNavigation);
    }




    desktopToggle() {
        this.meta.removeTag('name="viewport"');
        this.meta.addTag({ name: 'viewport', content: 'width=1600, , initial-scale=0.1' }, true);
    }


    private routeToComponent(navigationLink: string, queryParams?: any) {

        if (this.useExternalNavigation) {
            if (queryParams !== undefined) {
                window.location.href = navigationLink + '?siteId=' + queryParams.queryParams.siteId;
            } else {
                window.location.href = navigationLink;
            }
        } else {

            this.router.navigate([navigationLink], queryParams);
        }
    }


    filterItemsOfType() {
        this.leftTopNavigationItems = this.menuList.leftTopNavigation;
        this.leftBottomNavigationItems = this.menuList.leftBottomNavigation;
        this.leftMidNavigationItems = this.menuList.leftMidNavigation;
        // hide left nav for E3D China
        const userSettings = this.userService.getCurrentSettings();
        const isE3DChina = (this.config.getEnv("IsE3DChina") === "true") ? true : false;
        if (isE3DChina && (!userSettings.IsInternalUser)) {
            this.leftBottomNavigationItems = this.menuList.leftBottomNavigation.filter(x => x.name !== 'Training');
        }
        this.hideLeftSubMenuLinks();
    }

    filterByUserRole(role, navigationDetail, linksToExclude: string[]) {
        const userSettings = this.userService.getCurrentSettings();
        const isE3DChina = (this.config.getEnv("IsE3DChina") === "true") ? true : false;
        let navigationMenu = [];
        if (role !== null) {
            const pipe = new FilterNavigation();
            const IsInternalUser = userSettings ? userSettings.IsInternalUser : true;
            const UserRegion = userSettings ? userSettings.UserPreference.UserRegion : null;
            navigationMenu = pipe.transform(role, navigationDetail.leftNavigation, IsInternalUser, isE3DChina, linksToExclude, UserRegion);
            return navigationMenu;
        } else {
            return navigationMenu;
        }
    }

    menuFilterByUserRole(role, navigationDetail, linksToExclude: string[]) {
        const userSettings = this.userService.getCurrentSettings();
        const isE3DChina = (this.config.getEnv("IsE3DChina") === "true") ? true : false;
        let navigationMenu = [];
        if (role !== null) {
            const pipe = new FilterNavigation();
            const IsInternalUser = userSettings ? userSettings.IsInternalUser : true;
            const UserRegion = userSettings ? userSettings.UserPreference.UserRegion : null;
            navigationMenu = pipe.transform(role, navigationDetail, IsInternalUser, isE3DChina, linksToExclude, UserRegion);
            return navigationMenu;
        } else {
            return navigationMenu;
        }
    }

    getNavigationMenu() {

        const role = this.userService.getUserRole();
        const userPermissions = [];
        if (role !== null) {
            role.forEach(function (s) {
                userPermissions.push(s.PermissionId.toString());
            })
        }
        const MenuItemsArray = JSON.parse(JSON.stringify(MenusResponse))
        this.menuList = MenuItemsArray;
        const linksToExclude = this.getLinksToExclude();
        this.menuList.leftTopNavigation = this.menuFilterByUserRole(userPermissions, MenuItemsArray.leftTopNavigation, linksToExclude)
        this.menuList.leftMidNavigation = this.menuFilterByUserRole(userPermissions, MenuItemsArray.leftMidNavigation, linksToExclude)
        this.menuList.leftBottomNavigation = this.menuFilterByUserRole(userPermissions, MenuItemsArray.leftBottomNavigation, linksToExclude)
        this.filterItemsOfType();
    }

    /**
   * @description Gets list of links to exclude
   * @returns string of links to exclude
   */
    getLinksToExclude(): string[] {
        const isNewFiRefreshEnabled: boolean = this.config.getEnv('isNewFiRefreshEnabled') === "true";
        const excludeLinks = isNewFiRefreshEnabled ? ['/financial-impact/admin'] : [];

        return excludeLinks;
    }

    /************ Analytics Listener ************/
    @HostListener('click', ['$event'])
    onClick(event) {
        const adrumEvent = event;
        const adrumService = 'Home';
        const adrumComponent = this.constructor.name.toString();
        this.appDynamicsService.adrumClickEvent(adrumEvent, adrumService, adrumComponent);
        if (event.target.className.indexOf !== undefined && this.isMobile
            && (event.target.className.indexOf('submenu-margin') > 0 ||
            event.target.className.indexOf('singlemenu') > -1))  {
            this.isExpand = false;
          }
    }

    /**
  * @description It Will expand the left navigation bar 
  * Passing values with the help of behaviour subject
  */

    expandNavBar(): void {
        this.isExpand = !this.isExpand
        this.menuService.navExpand.next(this.isExpand);
    }


    ngOnDestroy() {
        // prevent memory leak when component is destroyed
        this.subscriptions.forEach(s => s.unsubscribe());
    }



}
