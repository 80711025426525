import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ErrorHandling } from '../../../shared/components/error-handling/error-handling';
import { XdComponentState } from '../../../shared/components/xd-container/xd-component';
import { ComponentState } from '../../../shared/components/xd-container/xd-component-state';
import { CalculatedParameters, ROTabs, RecoveryUnits, TemperatureUnits, WaterChemistryTypes } from '../../_enum/constants';
import { maxValue, minValue } from '../../_enum/validators';
import { UnitConversion } from '../../models/UnitConversion';
import { SharedRoService } from '../../services/shared-ro.service';

@Component({
  selector: 'nalco-operating-parameter',
  templateUrl: './operating-parameter.component.html',
  styleUrls: ['./operating-parameter.component.scss']
})
export class OperatingParameterComponent extends XdComponentState implements OnInit, OnChanges, OnDestroy {

  operatingParameterForm: UntypedFormGroup;
  @Input() opData;
  @Input() dimensionalUsageKeys;
  @Output() errorTabStatus = new EventEmitter<any>();
  @Output() calcLoaderStatus = new EventEmitter<boolean>();
  optdataCount: number = 0;
  subscription: Subscription;
  unitConversion: UnitConversion;
  paramsSubscription: Subscription;
  errorInfo: any;
  errorHandling = new ErrorHandling(this.modalService, this.translate);
  paramData = new Array<any>();
  commonParams = {};
  waterChemistry: string;
  calculatedParameters: Subscription;

  constructor(private formBuilder: UntypedFormBuilder,
    private roService: SharedRoService,
    private router: Router,
    private translate: TranslateService,
    private modalService: NgbModal,

  ) {
    super();
  }

  myParentInstance(): OperatingParameterComponent {
    return this;
  }

  ngOnInit() {
    this.setState(ComponentState.ready);
    this.operatingParameterForm = this.formBuilder.group({
    });
    this.subscription = this.roService.operatingParameterTab.subscribe(x => {
      this.createForm();
    });
    this.paramsSubscription = this.roService.commonFormParams.subscribe(x => {
      this.commonParams = x;
      if (this.commonParams !== null && this.commonParams['component'] !== ROTabs[0]) {
        Object.keys(this.commonParams).forEach(control => {
          if (this.commonParams[control].updated == true) {
            let opcontrol = control.replace(/\s/g, "");
            if (Object.keys(this.operatingParameterForm.controls).length > 0) {
              this.operatingParameterForm.controls[opcontrol.concat('tb')].setValue(this.commonParams[control].InputValue);
              this.operatingParameterForm.controls[opcontrol.concat('dd')].setValue(this.commonParams[control].DropdownValue);
            }
            this.opData.filter(op => {
              if (op.DisplayName == control) {
                op.DropdownParam.Value = this.commonParams[control].DropdownValue;
                op.InputParam.Value = this.commonParams[control].InputValue;
              }
            });
            this.opData = JSON.parse(JSON.stringify(this.opData));
            this.commonParams[control].updated = false;
            this.setValidators();
          }
        });
      }
    });
    this.calculatedParameters = this.roService.calculatedParams.subscribe(cp => {
      if (cp.isSolve == false && cp.response != null) {
        let data = [];
        CalculatedParameters.find(wc => wc.wcType == this.waterChemistry).Parameters.forEach(param => {
          let value = cp.response.OperatingParameters.filter(x => x.Name == param)[0].Value;
          data.push(this.inputParam('VolumeRate', null, this.operatingParameterForm.controls[param.concat('dd')].value, value, param, 'SI'));
        });
        this.getConvertedUnits(data, true, cp.isrecDRTab);
      }
    });
  }

  createForm() {
    if (Object.keys(this.operatingParameterForm.controls).length == 0) {
      this.opData.forEach((element, index) => {
        if (element.Type == 'combo-box') {
          this.operatingParameterForm.addControl(element.InputParam.FormControlName, new UntypedFormControl(''));
          this.operatingParameterForm.addControl(element.DropdownParam.FormControlName, new UntypedFormControl(''));
        } else if (element.Type === 'drop-down') {
          this.operatingParameterForm.addControl(element.DropdownParam.FormControlName, new UntypedFormControl(''));
        } else if (element.Type === 'input') {
          this.operatingParameterForm.addControl(element.InputParam.FormControlName, new UntypedFormControl(''));
        }
        this.optdataCount = index;
      });
      this.setValues();
      this.setValidators();
    }
  }

  setValues() {
    if (this.opData.length > 0) {
      this.opData.forEach(x => {
        if (x.Type == 'combo-box' || x.Type == 'input') {
          this.operatingParameterForm.controls[x.InputParam.FormControlName].setValue(this.isFractionalDecimal(parseFloat(x.InputParam.Value)) ? Number(parseFloat(x.InputParam.Value)).toFixed(6) : x.InputParam.Value);
        }
      })
    }
  }


  isFractionalDecimal(input) {
    input = Math.round(input);
    const [integerPart, fractionalPart] = input.toString().split('.');
    // Regular expression to match fractional decimal numbers// Allows for an optional leading sign, followed by a decimal point and one or more digitsconst ;
    if (fractionalPart?.toString().length > 6) {
      const fractionalDecimalRegex = /^-?\d*\.\d+$/;
      return fractionalDecimalRegex.test(input);
    }

  }

  setValidators() {
    if (this.opData.length > 0) {
      this.roService.waterChemistryTypeChangeEvnt.subscribe(x => this.waterChemistry = x);
      let recUnit = this.opData.filter(x => x.DisplayName == 'Recovery')[0].DropdownParam.Value;
      if (recUnit == RecoveryUnits[0]) {
        this.operatingParameterForm.controls['Recoverytb'].setValidators([Validators.required, minValue(0), maxValue(100)]);
      } else if (recUnit == RecoveryUnits[1]) {
        this.operatingParameterForm.controls['Recoverytb'].setValidators([Validators.required, minValue(0), maxValue(1)]);
      }
      let tempUnit = this.opData.filter(x => x.DisplayName == 'Temperature')[0].DropdownParam.Value;
      if (tempUnit == TemperatureUnits[0]) {
        this.operatingParameterForm.controls['Temperaturetb'].setValidators([Validators.required, minValue(273), maxValue(373)]);
      } else if (tempUnit == TemperatureUnits[1]) {
        this.operatingParameterForm.controls['Temperaturetb'].setValidators([Validators.required, minValue(32), maxValue(212)]);
      } else if (tempUnit == TemperatureUnits[2]) {
        this.operatingParameterForm.controls['Temperaturetb'].setValidators([Validators.required, minValue(0), maxValue(100)]);
      }
      if (this.waterChemistry == WaterChemistryTypes[0]) {
        this.operatingParameterForm.controls['PermeateFlowtb'].disable();
        this.operatingParameterForm.controls['PermeateFlowdd'].disable();
        this.operatingParameterForm.controls['ConcentrateFlowtb'].disable();
        this.operatingParameterForm.controls['ConcentrateFlowdd'].disable();
        this.operatingParameterForm.controls['AverageSaltPassagetb'].enable();
        this.operatingParameterForm.controls['AverageSaltPassagedd'].enable();
        this.operatingParameterForm.controls['RawWaterFlowtb'].enable();
        this.operatingParameterForm.controls['RawWaterFlowdd'].enable()
        this.opData.filter(x => x.DisplayName == 'Raw Water Flow')[0].InputParam.Required = true;
        this.opData.filter(x => x.DisplayName == 'Concentrate Flow')[0].InputParam.Required = false;
        this.operatingParameterForm.controls['RawWaterFlowtb'].setValidators([Validators.required, minValue(0)]);
        this.operatingParameterForm.controls['AverageSaltPassagetb'].setValidators([Validators.required, minValue(0), maxValue(100)]);
      } else if (this.waterChemistry == WaterChemistryTypes[1]) {
        this.operatingParameterForm.controls['PermeateFlowtb'].disable();
        this.operatingParameterForm.controls['PermeateFlowdd'].disable();
        this.operatingParameterForm.controls['RawWaterFlowtb'].disable();
        this.operatingParameterForm.controls['RawWaterFlowdd'].disable();
        this.operatingParameterForm.controls['AverageSaltPassagetb'].disable();
        this.operatingParameterForm.controls['AverageSaltPassagedd'].disable();
        this.operatingParameterForm.controls['ConcentrateFlowtb'].enable();
        this.operatingParameterForm.controls['ConcentrateFlowdd'].enable();
        this.opData.filter(x => x.DisplayName == 'Concentrate Flow')[0].InputParam.Required = true;
        this.opData.filter(x => x.DisplayName == 'Raw Water Flow')[0].InputParam.Required = false;
        this.operatingParameterForm.controls['ConcentrateFlowtb'].setValidators([Validators.required]);
      }
      this.operatingParameterForm.controls['Days/Yeartb'].setValidators([minValue(0), Validators.max(365)]);
      this.operatingParameterForm.controls['Hours/Daytb'].setValidators([minValue(0), Validators.max(24)]);
    }
    Object.keys(this.operatingParameterForm.controls).forEach(key => {
      this.operatingParameterForm.get(key).updateValueAndValidity();
    });
    this.operatingParameterForm = Object.create(this.operatingParameterForm);
  }

  ngOnChanges() { }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.paramsSubscription.unsubscribe();
    this.calculatedParameters.unsubscribe();
  }

  inputParam(unitType, dropdownValue, conversion, value, name, source) {
    return {
      UnitType: unitType,
      FromUnit: dropdownValue,
      ToUnit: conversion,
      OriginalValue: value,
      ParameterName: name,
      ConversionSource: source
    }
  }

  getConvertedUnits(value, multiple?, callRoSolve?) {
    if (multiple) {
      this.paramData = value;
    } else {
      let control = value.FormControlName.substring(0, value.FormControlName.length - 2);
      let tbvalue = this.operatingParameterForm.get(control.concat('tb')).value;
      let key = this.dimensionalUsageKeys.configurations.filter(x => x.dimensionalUsageKey == 'Roo_' + control)[0];
      this.paramData.push(this.inputParam(key.UnitType, value.previousValue, value.Value, tbvalue, control, key.ConversionSource));
    }
    this.roService.GetConversionByUnitType(this.paramData).subscribe(
      response => {
        this.paramData = [];
        if (response) {
          if (multiple) {
            response.forEach(x => {
              this.operatingParameterForm.get(x.ParameterName.concat('tb')).setValue(x.ConvertedValue);
              this.opData.filter(y => y.InputParam.FormControlName == x.ParameterName.concat('tb'))[0].InputParam.Value = x.ConvertedValue;
            });
            this.opData = JSON.parse(JSON.stringify(this.opData));
            this.calcLoaderStatus.emit(false);
          } else {
            this.unitConversion = response[0];
            let name = this.unitConversion.ParameterName;
            this.operatingParameterForm.get(name.concat('tb')).setValue(this.unitConversion.ConvertedValue);
            this.opData.forEach(x => {
              let condition = x.InputParam.FormControlName;
              if (condition == name.concat('tb')) {
                x.Valid = this.operatingParameterForm.controls[name.concat('tb')].errors == null ? true : false;
                x.InputParam.Value = this.unitConversion.ConvertedValue;
              }
            })
            if (name == 'Recovery' || name == 'Temperature' || name == 'RawWaterFlow') {
              let commonParamsControl = name == 'RawWaterFlow' ? 'Raw Water Flow' : name;
              this.commonParams[commonParamsControl].InputValue = this.operatingParameterForm.controls[name.concat('tb')].value;
              this.commonParams[commonParamsControl].DropdownValue = this.operatingParameterForm.controls[name.concat('dd')].value;
              this.commonParams[commonParamsControl].updated = true;
              this.commonParams['component'] = ROTabs[0]
              this.roService.setCommonParams(this.commonParams);
            }
          }
          if (callRoSolve) {
            this.roService.setDataForCalculation({ isSolve: true, response: null, isrecDRTab: callRoSolve });
          } else {
            this.roService.setForms({ 'operatingParameters': this.operatingParameterForm });
          }
          this.setState(ComponentState.ready);
        } else {
          this.setState(ComponentState.empty);
        }
        this.setValidators();
      }, error => {
        this.errorInfo = error;
        this.setState(ComponentState.ready);
        if (500 <= error.status && error.status < 600) {
          this.setState(ComponentState.error);
        } else if (error.status === 417) {
          this.router.navigate(['/access-denied']);
        } else if (error.status === 404) {
          this.router.navigate(['/**']);
        } else {
          this.setState(ComponentState.error);
          this.errorHandling.showErrorPopup(error);
        }
      }
    );
  }

  updateDropdown(event) {
    this.setState(ComponentState.loading);
    this.getConvertedUnits(event);
  }

  updateInput(event) {
    const error = this.operatingParameterForm.controls[event.name].errors;
    this.opData.forEach(x => {
      if (x.InputParam.FormControlName == event.name) {
        if (error == null) {
          x.Valid = true;
          let tabStatus = {};
          tabStatus['status'] = false;
          tabStatus['tab'] = ROTabs[0];
          this.errorTabStatus.emit(tabStatus);
        } else {
          x.Valid = false;
        }
        x.InputParam.Value = event.updatedValue;
      }
    });
    if (event.name == 'Recoverytb' || event.name == 'Temperaturetb' || event.name == 'RawWaterFlowtb') {
      let control = event.name.substring(0, event.name.length - 2) == 'RawWaterFlow' ? 'Raw Water Flow' : event.name.substring(0, event.name.length - 2);
      this.commonParams[control].InputValue = event.updatedValue;
      this.commonParams[control].updated = true;
      this.commonParams['component'] = ROTabs[0];
      this.commonParams[control].chargeBalupdated = event.name != 'Recoverytb' ? this.commonParams[control].updated : this.commonParams['updated'];
      this.roService.setCommonParams(this.commonParams);
    }
    this.roService.setForms({ 'operatingParameters': this.operatingParameterForm });
    if ((event.name == 'Recoverytb' || event.name == 'RawWaterFlowtb' || event.name == 'ConcentrateFlowtb') && this.operatingParameterForm.valid) {
      this.setState(ComponentState.loading);
      this.calcLoaderStatus.emit(true);
      this.roService.setDataForCalculation({ isSolve: true, response: null });
    }
    this.setValidators();
  }
}
