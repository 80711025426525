import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'distinctAssets'
})
export class DistinctAssetsPipe implements PipeTransform {

  transform(assets: any[]): any {
    let coll = [];
    if (assets && assets.length > 0) {
      assets.filter((asset, i, assetArr) => {
        if(!coll || coll.length === 0){
          coll.push(asset);
        }else if (coll && coll.length && (coll.indexOf(coll.find(asset1 => asset1.AssetCpId === asset.AssetCpId)) === -1)) {
          coll.push(asset);
        }
      })
    }
    return coll;
  }

}
