import { Component, OnInit, Input, ViewChild, ElementRef, OnChanges, AfterViewInit, ContentChild } from '@angular/core';
import { StackChartConfig } from './stack-chart.config';
import { ChartOptions, Options, AxisOptions, LoadingOptions, ChartObject } from 'highcharts';
import * as Highcharts from 'highcharts';
import { IChartData, IChartDataSeries } from '../../contracts/_contracts';
import * as moment from 'moment';
import { IfStmt } from '@angular/compiler';
import { TranslateService } from '@ngx-translate/core';
import { IAssetCountData } from '../../contracts/IAssetCount';

@Component({
  selector: 'nalco-stack-chart',
  templateUrl: './stack-chart.component.html'
})
export class StackChartComponent implements OnInit, OnChanges {

  @Input() data: IChartData; // To get the data of the chart
  @Input() assetsCount?:IAssetCountData[];
  @Input() showComparison?:boolean;
  @ViewChild('stackChartcontainer',{static: true}) stackChartcontainer: ElementRef;
  @Input() duration?: number;
  @Input() format?: string;
  @Input() xfontSize?: string = '10px';
  @Input() yfontSize?: string = '10px';
  @Input() setInterval?= 25;
  @Input() xDateFormat?: string;
  @Input() xAxisInDateFormat?: boolean = true;
  @Input() isComparisionChart?: boolean = false;
  @Input() isPrintPreview?: boolean = false;

  chart: ChartObject;
  private chartsOptions: ChartOptions;
  private option: Options;

  private reDraw() {
    if (this.data.xAxis !== undefined) {
      const self = this;

      this.chartsOptions = {
        type: 'column',
        height: 323,
        style: {
          fontFamily: 'Interstate-Regular'
        }
      };
      if (this.isComparisionChart) {
        this.chartsOptions['scrollablePlotArea'] = {
          minWidth: this.isPrintPreview ? 800 : 1100,
          scrollPositionX: 0
        }
      }
      this.option = {
        exporting: {
          enabled: false
        },
        chart: this.chartsOptions,
        title: StackChartConfig.title,
        xAxis: {
          tickColor: StackChartConfig.tickColor,
          lineColor: StackChartConfig.lineColor,
          // type: 'datetime',
          // tickInterval: this.getTickInterval(),
          categories: this.data.xAxis.categories,
          labels: {
            autoRotationLimit: 0,
            style: {
              fontSize: this.xfontSize,
              fontWeight: this.isComparisionChart ? '700' : '400'
            },
            formatter: function () {
              if (self.xAxisInDateFormat) {
                if (self.xDateFormat != null) {
                  return Highcharts.dateFormat(self.xDateFormat, this.value);
                } else {
                  if (self.setInterval === 25) {
                    if (self.duration > 60) {
                      return Highcharts.dateFormat('%b/%y', this.value);
                    } else {
                      return Highcharts.dateFormat('%e/%b/%y', this.value);
                    }
                  }
                  if (self.setInterval === 20) {
                    return Highcharts.dateFormat('%b %y', this.value);
                  }
                }
              } else {
                  const values = this.value.split('-');
                  let xLabel = '';
                  values.forEach((value, index) => {
                    if (index == 0) {
                      xLabel = xLabel + `<p>${value}</p>`;
                    } else {
                      if (this.chart.plotSizeX >= 1150) {
                        xLabel = xLabel + ` - <p>${value}</p>`;
                      } else {
                        xLabel = xLabel + `- <br/> ` + `<p>${value}</p>`;
                      }
                    }
                  })
                  return xLabel;
              }
            }
          }
        },
        yAxis: {
          tickInterval: this.setInterval,
          min: 0,
          max: 100,
          title: {
            text: ''
          },
          stackLabels: {
            enabled: false,       
          },
          labels: {
            format: '{value}%',
            style: {
              color: '#616365',
              fontSize: this.yfontSize,
              fontWeight: this.isComparisionChart ? '700' : '400'
            },
          }
        },
        tooltip: {
          enabled : !this.isPrintPreview ? true : false,
          backgroundColor: 'black',
          borderColor: '000000',
          borderRadius: 5,
          useHTML: true,
          // headerFormat: '<p class="tool-tip-header">{point.x}</p>',
          // pointFormat: '<span class="tooltip-content">{point.y}%</span>'
          formatter: function () {
            const date = new Date(this.x);
            const month = date.getMonth();
            const mnth = ('0' + (date.getMonth() + 1)).slice(-2);
            const day = ('0' + date.getDate()).slice(-2);
            const year = date.getFullYear();
            const hours = ('0' + date.getHours()).slice(-2);
            const minutes = ('0' + date.getMinutes()).slice(-2);
            const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
              'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            const FullMonthnames = ['January', 'February', 'March', 'April', 'May', 'June',
              'July', 'August', 'September', 'October', 'November', 'December'];
            const mnthName = ('0' + (date.getMonth() + 1)).slice(-2);
            const formattedDate = [day, mnth, date.getFullYear()].join('/');
            let toolTipContent = '';
            let headerContent = '';
            if (self.isComparisionChart) {
                headerContent = '<span class="tooltip-content">' + Math.round(this.y * 100) / 100  + '%' + '</span>';
                toolTipContent = '<p class="tool-tip-header"> ' + self.getNumberofAssets(self.assetsCount, this.series.userOptions, this.point.index + 1) + ' </p>';
              if (this.point && this.point.index == 12) {
                toolTipContent = toolTipContent+ '<p class="tool-tip-header"> The data shown is month to date </p>';
              }
            } else {
              toolTipContent = '<span class="tooltip-content">' + Math.round(this.y * 100) / 100  + '%' + '</span>';
              // const headerContent = '<p class="tool-tip-header">' + FullMonthnames[month]
              // + '&nbsp;' + day + ', ' + year + '</span></p>';
              headerContent = '<p class="tool-tip-header"> ' + this.series.userOptions.name + ' </p>';
            }

            return headerContent + toolTipContent;
          }
        },
        legend: StackChartConfig.legend,
        plotOptions: StackChartConfig.plotOptions,
        credits: StackChartConfig.credits,
        colors: StackChartConfig.colors,
      };
      if (this.isComparisionChart) {
        this.option.plotOptions.column.pointWidth= this.isPrintPreview ? 19 : 24;
        this.option.plotOptions.column.groupPadding = 0.21;
        this.option.chart.backgroundColor = '#fff';
        this.option.plotOptions.column.dataLabels = {
          enabled: true,
          defer: false,
          inside: true,
          align: 'center',
          style: {'textOutline': 'none','position': 'absolute','opacity': this.isPrintPreview ? 1 : 0.9, 'whiteSpace':'no-wrap','padding':0, 'fontSize': this.isPrintPreview ? '8px' : '11px'},
          formatter: function() {
            return this.y >= 5 ? '<span>' +  this.y + '</span>' : '';
          }
        }
        const series = this.option.series ? this.option.series : [];
        series['groupMargin'] = 0.05;
        this.option.series = [...series];
      }
    }
  }

  getNumberofAssets(assetsTotal:IAssetCountData[], userOptions:IChartDataSeries, index:number) : string {
    let i = userOptions.index;
    if(this.showComparison){
      i = userOptions.stack === 'Previous' ? i : i + 3;
    }
    let assetText = assetsTotal[i].assets[index].count === 1? this.translate.instant('COMMON.ASSET') : this.translate.instant('COMMON.ASSETS');
    let assetTextTotal = assetsTotal[i].assets[index].total === 1? this.translate.instant('COMMON.ASSET') : this.translate.instant('COMMON.ASSETS');
    let message = assetsTotal[i].assets[index].count + ' ' + this.getPerformanceColor(userOptions.name) + ' ' +
    assetText + '<br/>'+ this.translate.instant('COMMON.OUT_OF') + ' ' + assetsTotal[i].assets[index].total + ' ' + assetTextTotal;
    return message;
  }
  getPerformanceColor(performanceLabel: string) : string {
    switch ( performanceLabel) {
      case this.translate.instant('COMMON.HIGH'): return this.translate.instant('QUALITY.ADMIN.GREEN');
      case this.translate.instant('COMMON.MEDIUM'): return this.translate.instant('QUALITY.ADMIN.YELLOW');
      case this.translate.instant('COMMON.LOW'): return this.translate.instant('QUALITY.ADMIN.RED');
      default: break;
    }
  }

  constructor(private translate: TranslateService) {
  }
  ngOnInit() {
    this.reDraw();
  }

  // eslint-disable-next-line
  ngAfterViewInit() {
    this.reDraw();
  }
  ngOnChanges(changes) {
    if (this.data.xAxis !== undefined) {
      this.reDraw();
      this.chartsOptions.renderTo = this.stackChartcontainer.nativeElement;
        this.option['series'] = changes.data.currentValue.series;
      // this.stackedChartOptions.xAxis['categories'] = changes.data.currentValue.xAxis.categories;
      if (this.chart) {
        this.chart.destroy();
      }

      this.chart = new Highcharts.Chart(this.option);

      setTimeout(() => {
        this.chart.reflow();
      }, 100);
    }
  }


  getTickInterval() {
    const self = this;
    if (self.duration > 60) {
      return 3600 * 24 * 30 * 1000;
    } else {
      return null;
    }
  }

}
