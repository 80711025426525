import { IROModel } from "../contracts/IROMeasures";
import { ISelectedProduct } from "../contracts/ISelectedProduct";

export class SelectedProduct implements ISelectedProduct {
    DosageInConcentrate: ProductModel;
    DosageInFeed: ProductModel;
    Name: string;

    constructor(obj?: ISelectedProduct) {
       if(obj) {
          this.DosageInConcentrate = obj.DosageInConcentrate != null ? obj.DosageInConcentrate : new ProductModel(null); 
          this.DosageInFeed = obj.DosageInFeed != null ? obj.DosageInFeed : new ProductModel(null);
          this.Name = obj.Name;
       }
       else {
           this.DosageInConcentrate = new ProductModel(null);
           this.DosageInFeed = new ProductModel(null);
           this.Name = ''
       }
    }
}

export class ProductModel implements IROModel {
    Name: string;
    Type: string;
    Value: any;

    constructor(obj?: IROModel) {
        if (obj) {
            this.Name = obj.Name;
            this.Type = obj.Type;
            this.Value = obj.Value;
        } else {
            this.Name = null;
            this.Type = null;
            this.Value = null;
        }
    }
}