import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupPortsByAsset'
})
export class GroupPortsByAssetPipe implements PipeTransform {

  transform(assets: any, ...args: any[]): any {
    let coll = [];
    if (assets && assets.length > 0) {
      assets.filter((asset, i, assetArr) => {
        if (asset.MeasurementCount !== 0) { //When asset is mapped to any Measurement
          asset.showAssetDtlsFlag = true;
          if (coll && coll.length > 0 && (coll.indexOf(coll.find(asset1 => asset1.AssetCpId === asset.AssetCpId)) > -1)) {
            asset.showAssetDtlsFlag = false; // Hide asset name, system Name & area name details for more than 1 record of an asset
          }
          if(!asset.showAssetDtlsFlag && coll.some(asset2 => (asset.AssetCpId === asset2.AssetCpId) && asset.SubCategoryId !== asset2.SubCategoryId)){ // Don't push multiple records of ports if an asset has different SUbCategories 
          } else{
            coll.push(asset);
          }
        } else if (asset.MeasurementCount === 0) {
          if ((coll && coll.length > 0 && (coll.indexOf(coll.find(asset1 => asset1.AssetCpId === asset.AssetCpId)) == -1)) ||
            !coll || coll.length == 0) {
            let count = assetArr.some(asset2 => (asset.AssetCpId === asset2.AssetCpId) && asset2.MeasurementCount != 0);
            if (!count) { // Add atleast one record of that Asset, if all ports have no measurements
              asset.showAssetDtlsFlag = true;
              asset.PortCpId = null;
              asset.PortName = null;
              asset.MeasurementPointCpId = null;
              asset.MeasurementPointName = null;
              coll.push(asset);
            }
          }
        }
      })
    }
    return coll;
  }
}
