<div class="sidebar"  [ngClass]="[isMobile? isExpand? 'open overflow-menu' : 'display' :  isExpand? 'open' : '' , isNewUIEnabled? 'transitions-menus' : '' ]">
  <ul class="nav-list" >
    <nalco-menu-item *ngFor="let leftnav of leftTopNavigationItems" [id]="leftnav.hover" [heading]="leftnav.label"
      [text]="leftnav.text" [tooltip]="leftnav.hover" [icon]="leftnav.icon" [link]="leftnav.link" [href]="leftnav.href"
      (menuItemClick)="menuItemClicked($event)" [subMenu]="leftnav.subNavigation" [selected]="leftnav.isSelected"
      [arrow] ="!isMobile ? 'arrow-caret-right' : leftnav.isSelected ? 'arrow-caret-up' : 'arrow-down' ">
    </nalco-menu-item>
  </ul>
  <div>
    <nalco-divider [color]="dividerCss.color" [length]="isExpand ? dividerCss.maxLength : dividerCss.minLength"
      [isHorizontal]="dividerCss.isHorizontal" [customClasses]="isNewUIEnabled? 'transitions-menus' : ''">
    </nalco-divider>
  </div>
  <ul class="nav-list middle-nav" >
    <nalco-menu-item *ngFor="let leftnav of leftMidNavigationItems" [id]="leftnav.hover" [heading]="leftnav.label"
      [text]="leftnav.text" [tooltip]="leftnav.hover" [icon]="leftnav.icon" [link]="leftnav.link" [href]="leftnav.href"
      (menuItemClick)="menuItemClicked($event)" [subMenu]="leftnav.subNavigation" [selected]="leftnav.isSelected"
      [arrow] ="!isMobile ? 'arrow-caret-right' : leftnav.isSelected ? 'arrow-caret-up' : 'arrow-down' ">
    </nalco-menu-item>
  </ul>
  <div *ngIf="isMobile">
    <nalco-divider [color]="dividerCss.color" [length]="isExpand ? dividerCss.maxLength : dividerCss.minLength"
      [isHorizontal]="dividerCss.isHorizontal">
    </nalco-divider>
  </div>
  <ul class="nav-list bottom-nav">
    <nalco-menu-item *ngFor="let leftnav of leftBottomNavigationItems" [id]="leftnav.hover" [heading]="leftnav.label"
      [text]="leftnav.text" [tooltip]="leftnav.hover" [icon]="leftnav.icon" [link]="leftnav.link" [href]="leftnav.href"
      (menuItemClick)="menuItemClicked($event)" [subMenu]="leftnav.subNavigation" [selected]="leftnav.isSelected"
      [arrow] ="!isMobile ? 'arrow-caret-right' : leftnav.isSelected ? 'arrow-caret-up' : 'arrow-down' ">
    </nalco-menu-item>
  </ul>
</div>