import { ContextPointType } from '../../shared/_enum/constants';
import * as contracts from '../_contracts/ICategory';

export class Category implements contracts.ICategory {
    CategoryId: number;
    CategoryName: string;
    CategoryTypeId: number;
    CategoryResourceName: string;
    IsVisible: boolean;
    IsVisibleInHarvester: boolean;
    CategoryOrder: number;
    CategoryIconFileName?: any;
    SubCategories: Array<SubCategory> = [];
    CategoryAreas: Array<CategoryArea> = [];
    CategoryContextPointSubTypeMaps: Array<CategoryContextPointSubTypeMap> = [];
    SubCategoryId?: number;
    SubCategoryName?: string;
    ContextPointTypeCount?: number;
    MappedContextPointTypeCount?: number;
    AllContextPointTypeMapped?: boolean;
    // Computed at UI
    AssetsContextPointSubTypeId: Array<any> = [];
    OverAllMappingStatus = false;

    constructor(category?: contracts.ICategory) {
        if (category) {
            this.CategoryId = category.CategoryId;
            this.CategoryName = category.CategoryName;
            this.CategoryTypeId = category.CategoryTypeId;
            this.CategoryResourceName = category.CategoryResourceName;
            this.IsVisible = category.IsVisible;
            this.IsVisibleInHarvester = category.IsVisibleInHarvester;
            this.CategoryOrder = category.CategoryOrder;
            this.CategoryIconFileName = category.CategoryIconFileName;
            if (category.SubCategoryId)
                this.SubCategoryId = category.SubCategoryId;
            if (category.SubCategoryName)
                this.SubCategoryName = category.SubCategoryName;
            if (category.ContextPointTypeCount !== null && typeof (category.ContextPointTypeCount) !== 'undefined')
                this.ContextPointTypeCount = category.ContextPointTypeCount;
            if (category.MappedContextPointTypeCount)
                this.MappedContextPointTypeCount = category.MappedContextPointTypeCount;
            if (category.AllContextPointTypeMapped)
                this.AllContextPointTypeMapped = category.AllContextPointTypeMapped;
            if (category.SubCategories) {
                category.SubCategories.forEach(subC => {
                    this.SubCategories.push(new SubCategory(subC));
                });
            }
            if (category.CategoryAreas) {
                category.CategoryAreas.forEach(catArea => {
                    this.CategoryAreas.push(new CategoryArea(catArea));
                });
            }
            if (category.CategoryContextPointSubTypeMaps) {
                let subTypesIds = [];
                category.CategoryContextPointSubTypeMaps.forEach(subtypeMap => {
                    if (subtypeMap.ContextPointTypeId === ContextPointType.Asset) {
                        subTypesIds.push(subtypeMap.ContextPointSubTypeId);
                    }
                    this.CategoryContextPointSubTypeMaps.push(new CategoryContextPointSubTypeMap(subtypeMap));
                });
                this.AssetsContextPointSubTypeId = subTypesIds;
            }
        }
    }
}

export class SubCategory implements contracts.ISubCategory {
    CategoryId: number;
    SubCategoryId: number;
    SubCategoryName: string;
    SubCategoryResourceName: string;
    IsVisible: boolean;
    IsVisibleInHarvester: boolean;

    constructor(sC?: contracts.ISubCategory) {
        if (sC) {
            this.CategoryId = sC.CategoryId;
            this.SubCategoryId = sC.SubCategoryId;
            this.SubCategoryName = sC.SubCategoryName;
            this.SubCategoryResourceName = sC.SubCategoryResourceName;
            this.IsVisible = sC.IsVisible;
            this.IsVisibleInHarvester = sC.IsVisibleInHarvester;
        }
    }
}

export class CategoryArea implements contracts.ICategoryArea {
    CategoryId: number;
    SubCategoryId: number;
    AreaOrGroupTypeId: number;
    AreaTypeName: string;
    AreaTypeResourceName: string;
    AreaName: string;
    AreaNameResourceName: string;

    constructor(ca?: contracts.ICategoryArea) {
        if (ca) {
            this.CategoryId = ca.CategoryId;
            this.SubCategoryId = ca.SubCategoryId;
            this.AreaOrGroupTypeId = ca.AreaOrGroupTypeId;
            this.AreaTypeName = ca.AreaTypeName;
            this.AreaTypeResourceName = ca.AreaTypeResourceName;
            this.AreaName = ca.AreaName;
            this.AreaNameResourceName = ca.AreaNameResourceName;
        }
    }
}

export class CategoryContextPointSubTypeMap implements contracts.ICategoryContextPointSubTypeMap {
    CategoryContextPointSubTypeMapId: number;
    CategoryId: number;
    SubCategoryId: number;
    ContextPointTypeId: number;
    ContextPointSubTypeId: number;
    ContextPointSubTypeProperties: Array<ContextPointSubTypeProperty> = [];
    ContextPointSubTypeName: string;
    ContextPointId: number;


    constructor(subTypeMap?: contracts.ICategoryContextPointSubTypeMap) {
        if (subTypeMap) {
            this.CategoryContextPointSubTypeMapId = subTypeMap.CategoryContextPointSubTypeMapId;
            this.CategoryId = subTypeMap.CategoryId;
            this.SubCategoryId = subTypeMap.SubCategoryId;
            this.ContextPointTypeId = subTypeMap.ContextPointTypeId;
            this.ContextPointSubTypeId = subTypeMap.ContextPointSubTypeId;
            this.ContextPointSubTypeName = subTypeMap.ContextPointSubTypeName;
            this.ContextPointId = subTypeMap.ContextPointId;
            subTypeMap.ContextPointSubTypeProperties.forEach(cptp => {
                this.ContextPointSubTypeProperties.push(new ContextPointSubTypeProperty(cptp));
            });
        }
    }
}

export class ContextPointSubTypeProperty implements contracts.IContextPointSubTypeProperty {
    PropertyId: number;
    ContextPointTypeId: number;
    ContextPointSubTypeId: number;
    PropertyName: string;

    PropertyTranslatedName: string;
    PropertyResourceName: string;
    PropertyTypeId: number;
    PossibleValues: Array<ContextPointSubTypePossibleValue> = [];
    IsMandatory: boolean;
    IsMandatoryQues?: boolean;
    convertedValue?: string;
    inputValue?: string;
    selectedValue?: string | Date;
    dropDown?: any;
    numericValue?: any;

    constructor(subtypeProp?: contracts.IContextPointSubTypeProperty) {
        if (subtypeProp) {
            this.PropertyId = subtypeProp.PropertyId;
            this.ContextPointTypeId = subtypeProp.ContextPointTypeId;
            this.ContextPointSubTypeId = subtypeProp.ContextPointSubTypeId;
            this.PropertyName = subtypeProp.PropertyName
            this.PropertyTranslatedName = subtypeProp.PropertyTranslatedName
            this.PropertyResourceName = subtypeProp.PropertyResourceName;
            this.PropertyTypeId = subtypeProp.PropertyTypeId;
            subtypeProp.PossibleValues.forEach(cptp => {
                this.PossibleValues.push(new ContextPointSubTypePossibleValue(cptp));
            });
            this.IsMandatory = subtypeProp.IsMandatory;
        }
    }
}

export class ContextPointSubTypePossibleValue implements contracts.IContextPointSubTypePossibleValue {
    DimensionalUnit?: ContextPointSubTypePossibleValuesDimensionalUnit = new ContextPointSubTypePossibleValuesDimensionalUnit();
    PropertyId: number;
    ValueId: number;
    ValueName: string;
    ValueResourceName: string;
    ValueMin?: any;
    ValueMax?: any;
    ValueUnitUsageId?: any;

    constructor(PV?: contracts.IContextPointSubTypePossibleValue) {
        if (PV) {
            this.PropertyId = PV.PropertyId
            this.ValueId = PV.ValueId;
            this.ValueName = PV.ValueName;
            this.ValueResourceName = PV.ValueResourceName;
            this.ValueMin = PV.ValueMin;
            this.ValueMax = PV.ValueMax;
            this.ValueUnitUsageId = PV.ValueUnitUsageId;
            this.DimensionalUnit = PV.DimensionalUnit;
        }
    }
}

export class PossibleValuesDimensionalUnitSubUnits implements contracts.IPossibleValuesDimensionalUnitSubUnits {
    UnitName?: string;
    SignificantDigits?: number;
    Symbol?: string;
    IsBaseUnit?: boolean;

    constructor(sU?: contracts.IPossibleValuesDimensionalUnitSubUnits) {
        if (sU) {
            this.UnitName = sU.UnitName;
            this.SignificantDigits = sU.SignificantDigits;
            this.Symbol = sU.Symbol;
            this.IsBaseUnit = sU.IsBaseUnit;
        }
    }
}

export class ContextPointSubTypePossibleValuesDimensionalUnit implements contracts.IContextPointSubTypePossibleValuesDimensionalUnit {
    UnitType?: string;
    ConversionSource?: string;
    SubUnits?: Array<PossibleValuesDimensionalUnitSubUnits> = [];

    constructor(dU?: contracts.IContextPointSubTypePossibleValuesDimensionalUnit) {
        if (dU) {
            this.UnitType = dU.UnitType;
            this.ConversionSource = dU.ConversionSource;
            if (dU.SubUnits) {
                dU.SubUnits.forEach(subUnit => {
                    this.SubUnits.push(new PossibleValuesDimensionalUnitSubUnits(subUnit));
                });
            }

        }
    }
}
