import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';

@Injectable()
export class ResponsiveService {
  private breakpoint = new Subject<string>();
  private mobileViewEvent = new Subject<boolean>();
  private browserUnsupported = new Subject<string>();

  breakpointChangeEvent$ = this.breakpoint.asObservable();
  mobileViewChangeEvent$ = this.mobileViewEvent.asObservable();
  browserUnsupportedEvent$ = this.browserUnsupported.asObservable();

  public currentBreakpoint: string;
  public mobileView = false;
  public isIos: boolean;
  public isIe11: boolean;
  public isIeEdge: boolean;

  constructor() {
    this.updateBreakpoint(document.body.clientWidth);
    this.isIos = (this.mobileView === true && (/iP(hone|od|ad)/.test(navigator.platform)) ? true : false);
    this.isIe11 = (/Trident/.test(navigator.userAgent) ? true : false);
    this.isIeEdge = (/Edge/.test(navigator.userAgent) ? true : false);
  }

  updateBreakpoint(viewportWidth) {
    if (viewportWidth <= 1024) {
      this.currentBreakpoint = 'small';
      this.mobileView = true;
      // } else if (viewportWidth <= 1200) {
      //     this.currentBreakpoint = 'medium';
    } else {
      this.currentBreakpoint = 'large';
      this.mobileView = false;
    }

    // console.log('breakpoint=>', viewportWidth, this.currentBreakpoint);
    this.breakpoint.next(this.currentBreakpoint);
    this.mobileViewEvent.next(this.mobileView);
  }

  fullScreenModal(modalRef: any) {
    if (this.mobileView && modalRef !== undefined) {
      setTimeout(() => {
        const element = document.querySelector('ngb-modal-window');
        const _modal_dialog = element.querySelector('.modal-dialog');
        const _modal_header = element.querySelector('.modal-header');
        const _modal_body = element.querySelector('.modal-body');
        const _body_height = _modal_dialog.clientHeight - (_modal_header.clientHeight + 65);
        _modal_body.setAttribute('style', 'height: ' + _body_height + 'px;');
      }, 100);
    }
  }
  updateUnsupportedMessage(browserObj: any) {
    this.browserUnsupported.next(browserObj);
  }
}
