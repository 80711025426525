import { IPlotLines, IYAxis } from '../../shared/contracts/IYAxis';

export class YAxis implements IYAxis {
        min: number;
        max: number;
        tickInterval: number;
        plotLines : Array<IPlotLines>;
        labelFormat : string;

    constructor(IYAxis?: IYAxis) {
        if (IYAxis) {
            this.min =IYAxis.min;
            this.max = IYAxis.max;
            this.tickInterval = IYAxis.tickInterval;
            this.plotLines = IYAxis.plotLines;
            this.labelFormat = IYAxis.labelFormat;
        } else {
            this.min = null;
            this.max = null;
            this.tickInterval = null;
            this.plotLines = null;
            this.labelFormat = null;
        }
    }
}
