import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { LocalCacheService } from '../../../../core/local-cache/local-cache.service';
import { IDropdown, IDropdownOption } from '../../../../shared/components/dropdown/dropdown-model';
import { ErrorHandling } from '../../../../shared/components/error-handling/error-handling';
import { XdComponentState } from '../../../../shared/components/xd-container/xd-component';
import { ComponentState } from '../../../../shared/components/xd-container/xd-component-state';
import { MeasureTypeList, UnitUsageType } from '../../_enum/constants';
import { DefaultFlowMeter, FlowMeterInfo, MappingData, SensorMappingFormAllData } from '../../_models/SensorMappingFormAllData';
import { SensorMappingFormData } from '../../_models/SensorMappingFormData';
import { MeasurementInstanceScales, SensorMappingFormDataSave } from '../../_models/SensorMappingFormDataSave';
import { SiteCurrentData } from '../../_models/SiteMappedData';
import { WaterFlowIntelligenceAdminService } from '../../_services/water-flow-intelligence-admin.service';
import { CustomPopupComponent } from '../custom-popup/custom-popup.component';
import { SharedService } from '../../../../shared/services/shared-services.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'nalco-sensor-mapping-form',
  templateUrl: './sensor-mapping-form.component.html',
  styleUrls: ['./sensor-mapping-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SensorMappingFormComponent extends XdComponentState implements OnInit, OnChanges {

  errorHandling = new ErrorHandling(this.modalService, this.translate);
  Rssi = '';
  constructor(private translate: TranslateService,
    private modalService: NgbModal,
    private waterFlowIntelligenceAdminService: WaterFlowIntelligenceAdminService,
    private localCacheService: LocalCacheService,
    private router: Router,
    private datePipe: DatePipe,
    private sharedService: SharedService) {
    super();
  }

  channelView: Array<IDropdownOption> = [];
  selectChannelView: IDropdown = {
    label: this.translate.instant('WATER_FLOW_INTELLIGENCE.SENSOR_MAPPING.CHANNEL'),
    name: '',
    options: this.channelView
  };
  MeasureTypeView: Array<IDropdownOption> = [];
  selectMeasureTypeView: IDropdown = {
    label: 'Measure Type',
    name: '',
    options: this.MeasureTypeView
  };
  sensorTypeView: Array<IDropdownOption> = [];

  selectSensorTypeView: IDropdown = {
    name: '',
    label: this.translate.instant('WATER_FLOW_INTELLIGENCE.SENSOR_MAPPING.SENSOR_CONFIGURATION.SENSOR_TYPE'),
    options: this.sensorTypeView
  };
  KFactorView: Array<IDropdownOption> = [];

  selectKFactorView: IDropdown = {
    label: 'K Factor',
    name: '',
    options: this.KFactorView
  };
  pipeSizeView: Array<IDropdownOption> = [];

  selectpipeSizeView: IDropdown = {
    label: this.translate.instant('WATER_FLOW_INTELLIGENCE.SENSOR_MAPPING.PIPE_SIZE_CODE'),
    name: '',
    options: this.pipeSizeView
  };
  assetNameView: Array<IDropdownOption> = [{ text: 'Select', value: 'Select', isSelected: true }];

  selectAssetNameView: IDropdown = {
    label: 'Asset Name',
    name: '',
    options: this.assetNameView
  };
  portNameView: Array<IDropdownOption> = [];
  selectPortNameView: IDropdown = {
    label: 'Port',
    name: '',
    options: this.portNameView
  };
  unitsFlowDropdown = {
    label: this.translate.instant('WATER_FLOW_INTELLIGENCE.SITE_PERFORMANCE_LIST.UNIT_OF_FLOW'), name: 'flowUnits', options: []
  };
  unitsVolumeDropdown = {
    label: 'Volume/Pulse', name: 'volumeUnits', options: []
  };
  prevFlowUnit;
  prevVolumeUnit;
  UnitUsageSubUnitId = null;
  unitUsageSubUnits;
  selectedFlow;
  disabled = true;
  nodeData: any;
  scaleOffsetFlag = true;
  keyenceScaleLowFlag = true;
  errorMsgScaleFlag = false;
  errorMsgInputFlag = false;
  errorTwiceMeasuretoAPort = '';
  AllDataSourceGroupBy: any;
  SensorMappingFormData: Array<SensorMappingFormData> = [];
  SensorSaveFormData: SensorMappingFormDataSave;
  SensorFormData: MappingData;
  initialNodeValue = '';
  channelValue: boolean = false;
  NodeValue = '';
  @Input() senesorMappingGridData: any;
  @Input() selectedRow: any;
  @Input() portData: Array<SiteCurrentData> = [];
  @Input() assetData: Array<SiteCurrentData> = [];
  @Input() selectedSiteID: any;
  @Input() isAdmin = true;
  @Output() savedSensorDataValue = new EventEmitter();
  @ViewChild('rssi', { static: false }) rssi: ElementRef;
  @ViewChild('scaleLow', { static: false }) scaleLow: ElementRef;
  conversionUnitData: any;
  assetDetails = [];
  AllDataSource: Array<SensorMappingFormAllData> = [];
  sensorMappedData: SensorMappingFormAllData;
  earliersensorMappedData: SensorMappingFormAllData;
  enablePort = true;
  isSensorSelected = false;
  isAlreadyMapped = false;
  successImageFlag = 'noimage';
  initialValuesReset: any;
  errorHighLow = false;
  KFactorValue = '';
  avoidMesurementMappingFlag = false;
  disableMeasureType = false;
  flagPortDiffMAC = false;
  detectFormChange = false;
  errorMsgPortDiffMAC = '';
  portNameData = '';
  currentPortContextPointId;
  assetNameData = '';
  scaleUnit = '';
  disableToggle = true;
  isSourcesTurnedOn: boolean;
  errorMsgUsedChannel = '';
  errorMsgChannelAlreadyMapped = '';
  selectMeasureTypeUnit: any;
  unitTempFlag: boolean;
  showUnitToggleFlag = false;
  invalidSiteDetails = [];
  lastDate: Date;
  lastEndDate: Date;
  earlierDate = new Date().setMonth(new Date().getMonth() + -6);
  ErrorMsgStartDate = '';
  initMeaurementType;
  currentMeaurementType;
  stopmappingFlag = false;
  startDateChanged = false;
  loadingUnits = false;
  calculationDays = 180;
  hideIfKeyenceisSelected: boolean = false;
  hideIfCustomerisSelected: boolean = false;
  CustomPipeSize: boolean = false;
  errorInfo: any;
  disableTimeZoneDd: boolean = false;
  public timeZoneDdData : IDropdown = {
    name: 'TimeZoneDropdown',
    label: 'Device Time Zone',
    options: [{
      isSelected: true,
      value: null,
      text: 'Select'
    }]
  };
  public showDeviceTimeZoneDd = false;
  @Output() contextPointSubTypeId = new EventEmitter<number>();
  ngOnChanges(changes: SimpleChanges) {

    this.setState(ComponentState.loading);
    const userSettings = JSON.parse(this.localCacheService.fetchData('UserSettings'));
    this.detectFormChange = false;

    if (userSettings.Culture === 'en-US') {
      this.isSourcesTurnedOn = true;
    } else {
      this.isSourcesTurnedOn = false;
    }
    if (!this.isAdmin) {
      this.stopmappingFlag = true;
    } else {
      this.stopmappingFlag = false;
    }

    this.errorTwiceMeasuretoAPort = '';
    this.enablePort = true;
    this.disabled = true;
    this.isSensorSelected = false;
    this.isAlreadyMapped = false;
    this.avoidMesurementMappingFlag = false;
    this.disableMeasureType = false;
    this.errorMsgUsedChannel = '';
    this.errorMsgChannelAlreadyMapped = '';
    this.unitTempFlag = false;
    this.flagPortDiffMAC = false;
    this.errorMsgPortDiffMAC = '';
    this.selectpipeSizeView.options.forEach(pipe => { pipe.isSelected = false; });
    this.SensorFormData = new MappingData();
    this.selectChannelView.options.forEach(item => item.isSelected = false);
    this.selectSensorTypeView.options.forEach(item => item.isSelected = false);

    this.SensorFormData.SiteContextPointId = parseInt(this.selectedSiteID, 10);

    this.waterFlowIntelligenceAdminService.getFlowMetersData().subscribe(
      response => {

        response.forEach(element => {
          if (element.FlowMeterInfos.length > 0) {
            element.FlowMeterInfos.forEach(x => {
              this.SensorFormData.DefaultFlowMeter.push(new DefaultFlowMeter(element.FlowMeterId,
                element.Manufacturer, element.ModelNumber, x));
            });
          } else {
            this.SensorFormData.DefaultFlowMeter.push(new DefaultFlowMeter(element.FlowMeterId,
              element.Manufacturer, element.ModelNumber));
          }
        });

        if (!this.selectedRow.MACAddress) {

          this.setState(ComponentState.ready);
        }

        this.assetNameView.length = 0;
        this.assetData.forEach(element => {
          this.assetNameView.push({
            text: element.ContextPointName,
            value: element.ContextPointId.toString(),
            isSelected: false
          });
        });
        this.selectAssetNameView.options = this.assetNameView;

        if (changes.selectedRow && this.selectedRow) {
          this.NodeValue = '';
          this.initialNodeValue = '';
          this.initialValuesReset = changes;
          this.successImageFlag = 'noimage';
          this.selectedAssetPort(this.selectedRow.AssetContextPointId, this.selectedRow.PortContextPointId);
          if (this.selectedRow.MACAddress) {
            this.NodeValue = this.selectedRow.MACAddress;
            this.initialNodeValue = this.selectedRow.MACAddress;
            this.validateNode();
          } else {
            this.selectChannelView.options.length = 0;
            this.disabled = true;
            this.startDateChanged = true;
            this.channelValue = true;
            this.UnitUsageSubUnitId = null;
            this.showDeviceTimeZoneDd = false;
          }
        }
        this.setState(ComponentState.ready);
      },
      error => {
        this.errorInfo = error;
        if (500 <= error.status && error.status < 600) {
        } else if (error.status === 417) {
          this.router.navigate(['/access-denied']);
        } else if (error.status === 404) {
          this.router.navigate(['/**']);
        } else {
          this.errorHandling.showErrorPopup(error);
        }
        this.setState(ComponentState.error);
      });
  }

  myParentInstance(): SensorMappingFormComponent{
    return this;
  }

  ngOnInit() {
    this.getCalculationDays();
  }

  getTooltip(element: HTMLElement) {
    if (element) {
      return element.offsetWidth + 1 < element.scrollWidth ? element.innerText : '';
    }
    else return '';
  }


  selectedAssetPort(AssetContextPointId, PortContextPointId) {

    if (this.selectAssetNameView.options.length) {
      this.selectAssetNameView.options.forEach(asset => {
        if (parseInt(asset.value, 10) === AssetContextPointId) {
          asset.isSelected = true;
          this.assetNameData = asset.text;
        } else {
          asset.isSelected = false;
        }
      });
      const selectedAsset = this.selectAssetNameView.options.find(x => x.isSelected);
      this.updateAssetView(selectedAsset);

      if (this.selectPortNameView.options.length) {
        const portIndex = this.selectPortNameView.options.findIndex(port => parseInt(port.value, 10) === PortContextPointId);
        if (portIndex > -1) {
          this.selectPortNameView.options[portIndex].isSelected = true;
          this.portNameData = this.selectPortNameView.options[portIndex].text;
          this.currentPortContextPointId = this.selectPortNameView.options[portIndex].value;
        }
      }
    }
  }

  detectChange() {
    this.detectFormChange = true;
  }

  // loading sensor dropdown data
  loadSensorData() {
    // this.SensorFormData.MeasureType.label = this.translate.instant(this.SensorFormData.MeasureType.label)
    const measureId = this.SensorFormData.MeasureType.options.find(item => item.isSelected === true);
    let FlowMeterMeasureType = 0;

    MeasureTypeList.forEach(item => {
      if (item.EventCode === this.SensorFormData.EventCode) {
        item.MeasureType.forEach(ele => {
          if (ele.MeasureId === parseInt(measureId.value, 10)) {
            FlowMeterMeasureType = ele.FlowMeterType;
          }
        });
      }
    });

    this.sensorTypeView = [];
    this.SensorFormData.DefaultFlowMeter.forEach(element => {
      if (FlowMeterMeasureType === element.FlowMeterType || element.FlowMeterType === 0) {
        if (this.sensorTypeView.findIndex(x => element.SensorName === x.text) < 0) {
          this.sensorTypeView.push({
            text: element.SensorName,
            value: element.FlowMeterId.toString(),
            isSelected: false
          });
        }
      }
    });
    this.selectSensorTypeView.options = this.sensorTypeView;

    let selectedInputMode = this.SensorFormData.Inputmode;
    if (this.selectSensorTypeView.options) {
      if (this.selectSensorTypeView.options[0].text.indexOf('Keyence') >= 0 && selectedInputMode === "Digital Count") {
        this.hideIfKeyenceisSelected = true;
      }
      else {
        this.hideIfKeyenceisSelected = false;
      }
    }


  }

  // inital sensor values without node selection
  assignSensorData(event) {
    this.CustomPipeSize = false;
    let selectedInputMode = this.SensorFormData.Inputmode;
    if (event.text.indexOf('Keyence') >= 0 && selectedInputMode === "Digital Count") {
      this.hideIfKeyenceisSelected = true;
    } else {
      this.hideIfKeyenceisSelected = false;
    }

    if (event.text.indexOf('Customer') >= 0 && (selectedInputMode === "Digital Count" || selectedInputMode === "Analog")) {
      this.hideIfCustomerisSelected = true;
    } else {
      this.hideIfCustomerisSelected = false;
    }
    this.errorHighLow = false;
    this.isSensorSelected = true;
    this.selectSensorTypeView.options.forEach(x => {
      if (x.text === event.text) {
        x.isSelected = true;
      } else {
        x.isSelected = false;
      }
    });
    this.scaleOffsetFlag = true;
    this.keyenceScaleLowFlag = true;
    const measureId = this.SensorFormData.MeasureType.options.findIndex(sensor => sensor.isSelected === true && sensor.value === '7');
    const showScale = this.selectSensorTypeView.options.findIndex(item => item.isSelected === true && item.value === '1');
    if (showScale > -1) {
      this.scaleOffsetFlag = false;
      this.keyenceScaleLowFlag = false;
    } else {
      if (measureId > -1) {
        this.keyenceScaleLowFlag = false;
      } else {
        this.keyenceScaleLowFlag = true;
      }
    }
    this.assignDefaultPipe(event);
  }

  updateDropdownDetails(data) {

  }

  formatDate(date) {
    let now = new Date(date);
    let month = now.getMonth() + 1;
    let formatted = month + '/' + now.getDate() + '/' +
      now.getFullYear() + ' ' + now.getHours() + ':' + now.getMinutes() + ':' + now.getSeconds();
    return formatted
  }

  // validate Mac address
  validateNode(isNewMACEntered = false) {
    this.disabled = false;
    this.AllDataSourceGroupBy = [];
    const letters = /^[a-zA-Z0-9]+$/;
    this.successImageFlag = 'noimage';
    this.disableMeasureType = false;
    this.flagPortDiffMAC = false;
    this.errorMsgUsedChannel = '';
    this.errorMsgPortDiffMAC = '';
    this.errorMsgChannelAlreadyMapped = '';
    this.ErrorMsgStartDate = '';
    this.Rssi = '';
    this.unitTempFlag = false;
    this.showDeviceTimeZoneDd = false;
    if (this.NodeValue.match(letters)) {
      if (isNewMACEntered) {
        this.isSensorSelected = false;
        this.selectSensorTypeView.options.forEach(sensor => { sensor.isSelected = false; });
        this.selectpipeSizeView.options.forEach(pipe => { pipe.isSelected = false; });
      }

      this.waterFlowIntelligenceAdminService.getSensorMappingData(this.NodeValue.toString()).subscribe(
        response => {
          if (response.length > 0) {
            this.successImageFlag = 'checked';
            this.channelView.length = 0;
            this.AllDataSource.length = 0;
            if (response[0].RssiMeasureValue) {
              this.Rssi = response[0].RssiMeasureValue.toString();
            } else {
              this.Rssi = response[0].RssiMeasureValue;
            }
            this.invalidSiteDetails.length = 0;
            this.sensorMappedData = new SensorMappingFormAllData(response[0].MeasurementInstances);
            if (this.selectedRow.MACAddress !== '' && this.selectedRow.MACAddress === this.NodeValue) {
              this.earliersensorMappedData = new SensorMappingFormAllData(response[0].MeasurementInstances);
            }
            this.sensorMappedData.channelMeasurementMapping.forEach(item => {
              if (item.Site && item.Site.ContextPointId !== this.selectedSiteID) {
                let assetName = '';
                let portName = '';
                item.ActiveMappedMeasurementContextPoint.forEach(element => {
                  if (element.ContextPointType === 9000) {
                    assetName = element.ContextPointName;
                  }
                  if (element.ContextPointType === 11000) {
                    portName = element.ContextPointName;
                  }
                });
                this.invalidSiteDetails.push({ site: item, assetName: assetName, portName: portName });
              }
            });

            if (this.invalidSiteDetails.length > 0) {
              this.showInvalidSitePopup(this.invalidSiteDetails);
            }
            this.sensorMappedData.channelMeasurementMapping.sort(function (a, b) {
              const x = a.Channel;
              const y = b.Channel;
              return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            });

            let defaultUnmappedChannel = -1;
            let selectChannel = '';
            let selectedChannelAndInputMode = [];
            if (!this.initialNodeValue) {
              defaultUnmappedChannel = this.sensorMappedData.channelMeasurementMapping.findIndex(x =>
                x.ActiveMappedMeasurementContextPoint.length === 0);
              selectChannel = defaultUnmappedChannel > -1 ?
                this.sensorMappedData.channelMeasurementMapping[defaultUnmappedChannel].Channel
                : this.sensorMappedData.channelMeasurementMapping[0].Channel;
            } else {
              selectedChannelAndInputMode = this.sensorMappedData.channelMeasurementMapping.filter(x =>
                x.ActiveMappedMeasurementContextPoint.length > 0 && x.Channel === this.selectedRow.Channel.toString() &&
                x.Inputmode.indexOf(this.selectedRow.Inputmode.toString()) > -1 &&
                Math.max.apply(x.ReadingMeasurementDate));
              if (selectedChannelAndInputMode.length) {
                selectChannel = selectedChannelAndInputMode[0].ReadingMeasurementDate ?
                  selectedChannelAndInputMode[0].Channel + ' - ' + this.formatDate(selectedChannelAndInputMode[0].ReadingMeasurementDate)
                  : selectedChannelAndInputMode[0].Channel;
              } else {
                selectedChannelAndInputMode = this.sensorMappedData.channelMeasurementMapping.filter(x =>
                  x.ActiveMappedMeasurementContextPoint.length > 0 && x.Channel === this.selectedRow.Channel.toString() &&
                  Math.max.apply(x.ReadingMeasurementDate));
                if (selectedChannelAndInputMode.length) {
                  selectChannel = selectedChannelAndInputMode[0].ReadingMeasurementDate ?
                    selectedChannelAndInputMode[0].Channel + ' - ' + this.formatDate(selectedChannelAndInputMode[0].ReadingMeasurementDate)
                    : selectedChannelAndInputMode[0].Channel;
                } else {
                  selectChannel = this.sensorMappedData.channelMeasurementMapping[0].Channel;
                }
              }
            }

            let ifSelected = false;
            let checkifReadingMeasurementData;
            this.sensorMappedData.channelMeasurementMapping.forEach(element => {
              let valueWithDate = '';
              if (element.ReadingMeasurementDate) {
                element.ReadingMeasurementDate = new Date(element.ReadingMeasurementDate);
                let measurementDate = this.formatDate(element.ReadingMeasurementDate);
                valueWithDate = element.Channel + ' - ' + measurementDate;
                checkifReadingMeasurementData = true
              } else {
                valueWithDate = element.Channel;
              }

              this.channelView.push({
                text: valueWithDate,
                value: element.IncomingInstance,
                isSelected: selectChannel === valueWithDate ? true : false
              });
            });

            //check if chennelView has multiple isSelected in dropdown
            if (checkifReadingMeasurementData && this.channelView.length > 0) {
              this.selectChannelView.label = 'Channel - LAST READING DATE'
              this.channelView.forEach(element => {
                if (element.isSelected === true && ifSelected === false) {
                  ifSelected = true;
                } else {
                  element.isSelected = false;
                }
              })
            } else {
              this.selectChannelView.label = this.translate.instant('WATER_FLOW_INTELLIGENCE.SENSOR_MAPPING.CHANNEL');
            }

            this.contextPointSubTypeId.emit(response[0].ContextPointSubTypeId);
            //for fetching Device timezone data if KomodoEndNode
            if (response[0].ContextPointSubTypeId == 9) {
              this.timeZoneDdData.options = [{
                isSelected: true,
                value: null,
                text: 'Select'
              }];
              this.showDeviceTimeZoneDd = true;
              this.getDeviceTimeZoneDdData(response[0].ContextPointTypePrettyName, response[0].ContextPointSubTypeName, response[0].ContextPointName);
            }
            // this.selectChannelView.options = this.channelView;
            this.updateSelectedFormData(true);
            this.setState(ComponentState.ready);
          } else {
            this.disabled = true;
            this.selectChannelView.options.forEach(item => item.isSelected = false);
            this.SensorFormData.MeasureType.options.forEach(item => item.isSelected = false);
            this.SensorFormData.Inputmode = '';
            this.SensorFormData.SelectedFlowData.LowValue = 0;
            this.SensorFormData.SelectedFlowData.HighValue = 0;
            this.SensorFormData.SelectedFlowData.InputMin = 0;
            this.SensorFormData.SelectedFlowData.InputMax = 0;
            this.SensorFormData.SelectedFlowData.Offset = 0;
            this.SensorFormData.StartDate = new Date();
            this.successImageFlag = 'unchecked';
            this.showDeviceTimeZoneDd = false;
            this.SensorFormData.DeviceTimeZoneId = null;
            this.contextPointSubTypeId.emit(0);
          }
          this.setState(ComponentState.ready);
        },
        error => {
          this.disabled = true;
          this.successImageFlag = 'unchecked';

          this.errorInfo = error;
          if (500 <= error.status && error.status < 600) {
          } else if (error.status === 417) {
            this.router.navigate(['/access-denied']);
          } else if (error.status === 404) {
            this.router.navigate(['/**']);
          } else {
            this.errorHandling.showErrorPopup(error);
          }
          this.setState(ComponentState.error);
        }
      );
    } else {
      this.disabled = true;
      this.successImageFlag = 'unchecked';
      this.contextPointSubTypeId.emit(0);
    }
  }

  // Mac address from Different site validation popup
  showInvalidSitePopup(invalidSiteDetails) {
    this.disabled = true;
    this.successImageFlag = 'unchecked';
    let rows = '';
    invalidSiteDetails.forEach(element => {
      rows += '<tr><td class="p-2 text-center">'
        + element.site.Site.SiteName + '</td><td class="p-2 text-center">'
        + element.assetName + '</td><td class="p-2 text-center">'
        + element.portName + '</td><td class="p-2 text-right">'
        + element.site.Channel + '</td></tr>';
    });
    const modalRef = this.modalService.open(CustomPopupComponent, { windowClass: 'saveExit-modal' });
    modalRef.componentInstance.modalText = 'Mac Address already mapped !';
    // eslint-disable-next-line max-len
    modalRef.componentInstance.modcontent = `This Mac Address [` + this.NodeValue.toString() + `] is currently mapped to a different site,
      <br /> Please select another Mac Address or delete [soft/hard] the following mapping,<br /><br />
      <table style="width:100%" class='custom-popup'>
        <tr>
          <th class="p-2 text-center">Site Name</th>
          <th class="p-2 text-center">Asset</th>
          <th class="p-2 text-center">Port</th>
          <th class="p-2 text-center">Channel</th>
        </tr>` + rows +
      `</table>`;
    modalRef.componentInstance.disablebtn = true;
    modalRef.componentInstance.showBtn = false;
    modalRef.componentInstance.confirmBtn = this.translate.instant('COMMON.OK');
    modalRef.componentInstance.closeBtn = this.translate.instant('COMMON.CANCEL');
    modalRef.componentInstance.closeIcon = true;
    modalRef.componentInstance.deleteModal.subscribe(($event) => {
      modalRef.componentInstance.activeModal.close(true);
    });
    modalRef.componentInstance.stayModalPopUp.subscribe(($event) => {
      modalRef.componentInstance.activeModal.close(false);
    });
    modalRef.componentInstance.changeRef.markForCheck();
    return modalRef.result;

  }

  updateSelectedFormData(isinit) {

    this.isAlreadyMapped = false;
    this.errorHighLow = false;
    const channelSelected = this.selectChannelView.options.findIndex(x => x.isSelected);
    let selectedChannel = null;
    this.sensorMappedData.channelMeasurementMapping.forEach(item => {
      if (item.IncomingInstance === this.selectChannelView.options[channelSelected].value) {
        item.IsSelected = true;
        selectedChannel = item;
      } else {
        item.IsSelected = false;
      }

      if (this.selectChannelView.options[channelSelected].value === '3-9') {
        this.channelValue = false;
      } else {
        this.channelValue = true;
      }
    });

    this.SensorFormData.ActiveMappedMeasurementContextPoint = selectedChannel.ActiveMappedMeasurementContextPoint;
    this.SensorFormData.MeasureType = selectedChannel.MeasureType;
    this.SensorFormData.MeasureTypeUnits = selectedChannel.MeasureTypeUnits;
    this.SensorFormData.MeasureType.options.forEach(sensor => { sensor.isSelected = false; });
    if (this.SensorFormData.ActiveMappedMeasurementContextPoint.length > 0) {
      // TODO Temporaryfix so that user cannot update the data for already mapped sensors in this release
      this.isAlreadyMapped = true;
      const AssetData = this.SensorFormData.ActiveMappedMeasurementContextPoint.find(x => x.ContextPointType === 9000);
      const indexMeasureType = this.SensorFormData.MeasureType.options.findIndex(item => item.value === AssetData.MeasurementId.toString());
      this.SensorFormData.MeasureType.options[indexMeasureType].isSelected = true;
    } else {
      this.SensorFormData.MeasureType.options[0].isSelected = true;
    }
    if (isinit == true) {
      const measureId = this.SensorFormData.MeasureType.options.find(sensor => sensor.isSelected === true);
      this.initMeaurementType = measureId;
    }
    this.currentMeaurementType = this.SensorFormData.MeasureType.options.find(item => item.isSelected);
    this.SensorFormData.Channel = selectedChannel.Channel;
    this.SensorFormData.ContextPointId = selectedChannel.ContextPointId;
    this.SensorFormData.FlowMeterInfo = selectedChannel.FlowMeterInfo;
    this.SensorFormData.IncomingInstance = selectedChannel.IncomingInstance;
    this.SensorFormData.Inputmode = selectedChannel.Inputmode;
    this.SensorFormData.InstanceId = selectedChannel.InstanceId;
    this.SensorFormData.IsSelected = selectedChannel.IsSelected;
    this.SensorFormData.EventCode = selectedChannel.EventCode;
    this.SensorFormData.MeasurementId = selectedChannel.MeasurementId;
    this.SensorFormData.MeasurementInstanceId = selectedChannel.MeasurementInstanceId;
    this.SensorFormData.MeasurementInstanceScales = selectedChannel.MeasurementInstanceScales;
    this.SensorFormData.StuiName = selectedChannel.StuiName;

    this.SensorFormData.StartDate = new Date();
    this.SensorFormData.EarliestAvailableMappingDateTime = selectedChannel.EarliestAvailableMappingDateTime;
    this.SensorFormData.SelectedFlowData = new FlowMeterInfo();
    const measureId = this.SensorFormData.MeasureType.options.find(sensor => sensor.isSelected === true);
    this.selectMeasureTypeUnit = this.SensorFormData.MeasureTypeUnits.find(item => item.value.toString() === measureId.value.toString());
    this.loadSensorData();
    if (this.SensorFormData.MeasurementInstanceScales.length > 0) {
      this.SensorFormData.StartDate = new Date(this.SensorFormData.MeasurementInstanceScales[0].StartDate);
      // eslint-disable-next-line max-len
      const mappedSensor = this.SensorFormData.FlowMeterInfo.ModelNumber ? (this.SensorFormData.FlowMeterInfo.Manufacturer + ' - ' + this.SensorFormData.FlowMeterInfo.ModelNumber) : this.SensorFormData.FlowMeterInfo.Manufacturer;
      const selectSensor = this.selectSensorTypeView.options.find(x => x.text === mappedSensor);
      this.assignSensorData(selectSensor);
    }
    this.getUnitList(parseInt(measureId.value, 10));
    if (!(this.invalidSiteDetails.length > 0)) {
      this.avoidMesurementMapping();
    }
    this.isMappedPortMeasureType();
  }

  UpdatesSensorPipeSize(event) {
    this.currentMeaurementType = event;
    this.UnitUsageSubUnitId = null;
    this.getUnitList(parseInt(event.value, 10));
    if (!this.isMappedPortMeasureType()) {
      this.selectSensorTypeView.options.forEach(sensor => { sensor.isSelected = false; });
      this.selectpipeSizeView.options.forEach(pipe => { pipe.isSelected = false; });
      this.loadSensorData();
    }
  }

  isMappedPortMeasureType() {
    let match = 0;
    this.senesorMappingGridData.forEach(element => {
      if (element.PortContextPointId == this.currentPortContextPointId
        && element.MACAddress != this.NodeValue
        && ((element.MeasurementId == 7 && this.currentMeaurementType.value == 7)
          || ((element.MeasurementId == 5 || element.MeasurementId == 25)
            && (this.currentMeaurementType.value == 5 || this.currentMeaurementType.value == 25)))
        // && element.MeasurementId != this.initMeaurementType.value
        && ((element.MeasurementId == 7 && this.initMeaurementType.value != 7)
          || ((element.MeasurementId == 5 || element.MeasurementId == 25)
            && (this.initMeaurementType.value != 5 && this.initMeaurementType.value != 25)))
      ) {
        match++;
      }
    });
    if (match > 0) {
      this.errorTwiceMeasuretoAPort = 'Port already mapped to ' + this.currentMeaurementType.text;
      return true;
    } else {
      this.errorTwiceMeasuretoAPort = '';
      return false;
    }
  }

  checkPortCurrentEarlierMapping(measureId, portContextID, dataSource) {

    dataSource.channelMeasurementMapping.forEach(item => {
      const MeasureTypeValue = item.MeasureType.options.find(val => val.isSelected === true);
      item.ActiveMappedMeasurementContextPoint.forEach(element => {
        if (parseInt(measureId.value, 10) === 25 && element.MeasurementId === 5
          && element.ContextPointTypePrettyName === 'Port' && element.ContextPointId.toString() === portContextID[0].value
        ) {
          this.avoidMesurementMappingFlag = true;
          this.disableMeasureType = false;
          this.errorMsgUsedChannel = 'Channel ' + item.Channel + ' is used to measure flow/volume';
        } else if (parseInt(measureId.value, 10) === 5 && element.MeasurementId === 25
          && element.ContextPointTypePrettyName === 'Port' && element.ContextPointId.toString() === portContextID[0].value
        ) {
          this.avoidMesurementMappingFlag = true;
          this.disableMeasureType = false;
          this.errorMsgUsedChannel = 'Channel ' + item.Channel + ' is used to measure flow/volume';
        } else if (item.IncomingInstance !== this.SensorFormData.IncomingInstance
          && parseInt(measureId.value, 10) === 7 && element.MeasurementId === 7
          && element.ContextPointTypePrettyName === 'Port' && element.ContextPointId.toString() === portContextID[0].value
        ) {
          this.errorMsgUsedChannel = 'Channel ' + item.Channel + ' is used to measure temperature';
          this.avoidMesurementMappingFlag = true;
          this.disableMeasureType = false;
        } else if (parseInt(measureId.value, 10) === 5 && element.MeasurementId === 5 &&
          item.IncomingInstance !== this.SensorFormData.IncomingInstance
          && element.ContextPointTypePrettyName === 'Port' && element.ContextPointId.toString() === portContextID[0].value
        ) {
          this.errorMsgUsedChannel = 'Channel ' + item.Channel + ' is used to measure flow/volume';
          this.avoidMesurementMappingFlag = true;
          this.disableMeasureType = false;
        }

      });
    });
  }

  compareIncomingInstance(portContextID, dataSource, currentNode) {
    dataSource.channelMeasurementMapping.forEach(item => {
      const MeasureTypeValue = item.MeasureType.options.find(val => val.isSelected === true);
      item.ActiveMappedMeasurementContextPoint.forEach(element => {
        if (item.IncomingInstance === this.SensorFormData.IncomingInstance) {
          this.avoidMesurementMappingFlag = false;
          if (this.SensorFormData.ActiveMappedMeasurementContextPoint.length > 0
            && this.SensorFormData.ActiveMappedMeasurementContextPoint[1].ContextPointId.toString() !== portContextID[0].value) {
            this.errorMsgChannelAlreadyMapped = 'Mapped to ( ' + this.SensorFormData.ActiveMappedMeasurementContextPoint[0].ContextPointName
              + ', ' + this.SensorFormData.ActiveMappedMeasurementContextPoint[1].ContextPointName + ' ) ';
            this.avoidMesurementMappingFlag = true;
          }
          if (!currentNode) {
            this.errorMsgChannelAlreadyMapped = 'Mapped to ( ' + item.ActiveMappedMeasurementContextPoint[0].ContextPointName
              + ', ' + item.ActiveMappedMeasurementContextPoint[1].ContextPointName + ' ) ';
            this.disableMeasureType = false;
          } else {
            this.disableMeasureType = true;
          }
        }
      });
    });
  }

  avoidMesurementMapping() {
    this.avoidMesurementMappingFlag = false;
    this.errorMsgUsedChannel = '';
    this.errorMsgChannelAlreadyMapped = '';
    this.disableMeasureType = false;
    this.scaleUnit = '';
    this.flagPortDiffMAC = false;
    this.errorMsgPortDiffMAC = '';
    this.ErrorMsgStartDate = '';
    const measureId = this.SensorFormData.MeasureType.options.find(item => item.isSelected === true);
    const measureunit = this.SensorFormData.MeasureTypeUnits.find(item => item.value.toString() === measureId.value.toString());
    this.selectMeasureTypeUnit = measureunit;
    this.scaleUnit = measureunit.unit;
    const portContextID = this.selectPortNameView.options.filter(item => item.isSelected);

    // current MAC validation.
    this.checkPortCurrentEarlierMapping(measureId, portContextID, this.sensorMappedData);
    this.compareIncomingInstance(portContextID, this.sensorMappedData, true);
    // earlier MAC validation.
    if (this.selectedRow.MACAddress !== '' && this.selectedRow.MACAddress !== this.NodeValue) {
      this.compareIncomingInstance(portContextID, this.sensorMappedData, true);
      this.validatePortMACChannel(portContextID, measureId);
    }

    this.validateScaleInputValues();
    this.ErrorMsgStartDate = '';
    if (this.SensorFormData.StartDate < new Date(this.earlierDate)) {
      this.ErrorMsgStartDate = 'Please contact  customer support to edit any data';
    }

  }

  validateScaleInputValues() {

    this.errorMsgScaleFlag = false;
    this.errorMsgInputFlag = false;
    let LowValue = this.SensorFormData.SelectedFlowData.LowValue;
    this.SensorFormData.SelectedFlowData.LowValue = LowValue.toString().includes('-') ? 0 : LowValue;
    let HighValue = this.SensorFormData.SelectedFlowData.HighValue;
    this.SensorFormData.SelectedFlowData.HighValue = HighValue.toString().includes('-') ? 0 : HighValue;
    if (parseFloat(this.SensorFormData.SelectedFlowData.LowValue.toString()) >
      parseFloat(this.SensorFormData.SelectedFlowData.HighValue.toString())) {
      this.errorMsgScaleFlag = true;
    }
    if (parseFloat(this.SensorFormData.SelectedFlowData.InputMin.toString()) >
      parseFloat(this.SensorFormData.SelectedFlowData.InputMax.toString())) {
      this.errorMsgInputFlag = true;
    }
  }

  validatePortMACChannel(currentPort, measureId) {
    this.flagPortDiffMAC = false;
    this.errorMsgPortDiffMAC = '';
    this.senesorMappingGridData.forEach(element => {
      if (element.MACAddress !== '' && element.PortContextPointId.toString() === currentPort[0].value.toString()) {
        // eslint-disable-next-line max-len
        if (element.MesurementId && element.MeasurementId.toString() === measureId.value && !this.disableMeasureType && !this.avoidMesurementMappingFlag) {
          this.flagPortDiffMAC = true;
          this.avoidMesurementMappingFlag = false;
          this.errorMsgPortDiffMAC = measureId.text + ' is currently mapped to ( ' + element.MACAddress + ' )';
        } else if (parseInt(measureId.value, 10) === 5 && parseInt(element.MeasurementId, 10) === 25 ||
          parseInt(measureId.value, 10) === 25 && parseInt(element.MeasurementId, 10) === 5) {
          this.flagPortDiffMAC = true;
          this.avoidMesurementMappingFlag = false;
          this.errorMsgPortDiffMAC = measureId.text + ' is currently mapped to ( ' + element.MACAddress + ' )';
        }
      }
    });
  }

  // on channel change update channel and it's respective details
  updateDefaultChannel(event) {
    this.UnitUsageSubUnitId = null;
    this.showUnitToggleFlag = false;
    this.selectChannelView.options.forEach(item => {
      if (item.value === event.value) {
        item.isSelected = true;
      } else {
        item.isSelected = false;
      }
    });

    this.isSensorSelected = false;
    this.selectSensorTypeView.options.forEach(x => {
      x.isSelected = false;
    });
    this.selectpipeSizeView.options.forEach(x => {
      x.isSelected = false;
    });
    this.updateSelectedFormData(false);

  }

  // assign pipe size Kfactor if available from selected channel and sensor type
  assignDefaultPipe(event) {
    const selectedSensor = this.selectSensorTypeView.options.find(x => x.isSelected);

    this.pipeSizeView.length = 0;
    this.KFactorView.length = 0;

    const measureId = this.SensorFormData.MeasureType.options.find(item => item.isSelected === true);
    let FlowMeterMeasureType = 0;

    // need to remove the measure type utilize select channel  FlowMeterType
    MeasureTypeList.forEach(item => {
      if (item.EventCode === this.SensorFormData.EventCode) {
        item.MeasureType.forEach(ele => {
          if (ele.MeasureId === parseInt(measureId.value, 10)) {
            FlowMeterMeasureType = ele.FlowMeterType;
          }
        });
      }
    });
    this.SensorFormData.DefaultFlowMeter.forEach(element => {

      if (element.SensorName === selectedSensor.text && element.FlowMeterId.toString() === selectedSensor.value) {

        if (FlowMeterMeasureType === element.FlowMeterType
          || element.FlowMeterType === 0) {
          this.pipeSizeView.push({
            text: element.DisplayName,
            value: element.Code + '|' + element.FlowMeterInfoId,
            isSelected: false
          });

          if (element.KfactorEditType === 1) {
            this.KFactorView.length = 0;
            if (element.KfactorCsvRange) {
              const KfactorCsvRange = element.KfactorCsvRange.split(',');
              KfactorCsvRange.forEach(item => {
                this.KFactorView.push({
                  text: item,
                  value: item,
                  isSelected: false
                });
              });
            }
          }
        }
      }
    });
    this.getScalingData(event);
  }

  // on change of pipe size update scaling information.
  updatePipeSizeView(event) {
    if ((event.text.indexOf('Custom') >= 0 || event.text.indexOf('custom') >= 0) && this.hideIfKeyenceisSelected) {
      this.CustomPipeSize = true;
    } else {
      this.CustomPipeSize = false;
    }
    this.showUnitToggleFlag = false;
    this.selectpipeSizeView.options.forEach(x => {
      if (x.value === event.value) {
        const SelectedFlowMeterInfoId = event.value.split('|');
        if (SelectedFlowMeterInfoId[1] &&
          (parseInt(SelectedFlowMeterInfoId[1], 10) === 1 || parseInt(SelectedFlowMeterInfoId[1], 10) === 196)) {
          this.showUnitToggleFlag = true;
        }
        x.isSelected = true;
      } else {
        x.isSelected = false;
      }
    });
    this.errorHighLow = false;
    this.KFactorView.forEach(item => item.isSelected = false);
    let defaultPipeSize = -1;
    if (this.SensorFormData.MeasurementInstanceScales.length > 0) {
      const pipeSizevalue = this.SensorFormData.FlowMeterInfo.Code + '|' + this.SensorFormData.FlowMeterInfo.FlowMeterInfoId;
      defaultPipeSize = this.pipeSizeView.findIndex(x => event.value === pipeSizevalue);
    }

    if (defaultPipeSize < 0) {
      const selectedSensor = this.selectSensorTypeView.options.find(x => x.isSelected);
      const index = this.SensorFormData.DefaultFlowMeter.findIndex(x => (x.Code + '|' + x.FlowMeterInfoId) === event.value
        && x.SensorName === selectedSensor.text
        && x.FlowMeterId.toString() === selectedSensor.value);
      this.SensorFormData.DefaultFlowMeter[index].IsSelected = true;
      this.SensorFormData.SelectedFlowData = new FlowMeterInfo(this.SensorFormData.DefaultFlowMeter[index]);
    } else {
      this.updateDerivedScalingData();
    }
    this.KFactorView.forEach(element => {
      element.isSelected = false;
      if (element.value === Math.round(this.SensorFormData.SelectedFlowData.HighValue).toString()) {
        element.isSelected = true;
      }
    });

    //update UnitUsageSubUnitId while changing the dropdownValue of pipeSizeView
    let getSelectedFlowMetersData = this.SensorFormData.DefaultFlowMeter.filter(x => {
      if (event.text == x.DisplayName) {
        return x.UnitSymbol
      }
    });

    if (getSelectedFlowMetersData) {
      let unitSymbol = getSelectedFlowMetersData[0].UnitSymbol;
      let units = JSON.parse(JSON.stringify(this.waterFlowIntelligenceAdminService.volumeUnits.UnitUsageSubUnits));
      let selectedUnit = units.filter(element => {
        if (element.Symbol == unitSymbol) {
          return element
        }
      });
      this.UnitUsageSubUnitId = selectedUnit[0].UnitUsageSubUnitId;
    }

  }

  // update scaling data for selected sensor and pipesize.
  getScalingData(event) {
    this.showUnitToggleFlag = false;
    if (this.SensorFormData.MeasurementInstanceScales.length === 0) {
      this.pipeSizeView[0].isSelected = true;
      if (parseInt(event.value, 10) === 1) {
        this.showUnitToggleFlag = true;
      }
      const index = this.SensorFormData.DefaultFlowMeter.findIndex(x => (x.Code
        + '|' + x.FlowMeterInfoId) === this.pipeSizeView[0].value
        && x.SensorName === event.text);
      this.SensorFormData.DefaultFlowMeter[index].IsSelected = true;

      this.SensorFormData.SelectedFlowData = new FlowMeterInfo(this.SensorFormData.DefaultFlowMeter[index]);

    } else {
      const pipeSizevalue = this.SensorFormData.FlowMeterInfo.Code + '|' + this.SensorFormData.FlowMeterInfo.FlowMeterInfoId;
      const defaultPipeSize = this.pipeSizeView.findIndex(x => x.value === pipeSizevalue);
      if (defaultPipeSize > -1) {
        if (parseInt(event.value, 10) === 1) {
          this.showUnitToggleFlag = true;
        }
        this.pipeSizeView[defaultPipeSize].isSelected = true;
      }
      this.updateDerivedScalingData();
    }
    this.KFactorView.forEach(element => {
      element.isSelected = false;
      if (element.value === Math.round(this.SensorFormData.SelectedFlowData.HighValue).toString()) {
        element.isSelected = true;
      }
    });

    let selectedPipesizeIndex = this.pipeSizeView.findIndex(x => x.isSelected === true);
    let selectedPipeSize = this.pipeSizeView[selectedPipesizeIndex].text;

    if (selectedPipeSize.indexOf('Custom') >= 0 && this.hideIfKeyenceisSelected) {
      this.CustomPipeSize = true;
    } else {
      this.CustomPipeSize = false;
    }
  }

  // assign selected scalling values for derived channel.
  updateDerivedScalingData() {
    this.SensorFormData.SelectedFlowData = new FlowMeterInfo();
    this.SensorFormData.SelectedFlowData.PipeSize = this.SensorFormData.FlowMeterInfo.PipeSize;
    this.SensorFormData.SelectedFlowData.Code = this.SensorFormData.FlowMeterInfo.Code;
    this.SensorFormData.SelectedFlowData.FlowMeterId = this.SensorFormData.FlowMeterInfo.FlowMeterId;
    this.SensorFormData.SelectedFlowData.HighValue = this.SensorFormData.MeasurementInstanceScales[0].HighValue;
    this.SensorFormData.SelectedFlowData.LowValue = this.SensorFormData.MeasurementInstanceScales[0].LowValue;
    this.SensorFormData.SelectedFlowData.InputMin = this.SensorFormData.MeasurementInstanceScales[0].InputMin;
    this.SensorFormData.SelectedFlowData.InputMax = this.SensorFormData.MeasurementInstanceScales[0].InputMax;
    this.SensorFormData.SelectedFlowData.Offset = this.SensorFormData.MeasurementInstanceScales[0].Offset;
    this.SensorFormData.SelectedFlowData.FlowMeterInfoId = this.SensorFormData.FlowMeterInfo.FlowMeterInfoId;
    this.SensorFormData.SelectedFlowData.KfactorEditType = this.SensorFormData.FlowMeterInfo.KfactorEditType;
    this.SensorFormData.SelectedFlowData.HighValueMin = this.SensorFormData.FlowMeterInfo.HighValueMin;
    this.SensorFormData.SelectedFlowData.HighValueMax = this.SensorFormData.FlowMeterInfo.HighValueMax;
    this.SensorFormData.SelectedFlowData.UnitSymbol = this.SensorFormData.FlowMeterInfo.UnitSymbol;
    this.SensorFormData.SelectedFlowData.KfactorUnit = this.SensorFormData.FlowMeterInfo.KfactorUnit;
    this.UnitUsageSubUnitId = this.SensorFormData.MeasurementInstanceScales[0].UnitUsageSubUnitId;
  }

  updateAssetView($event) {
    this.portNameView.length = 0;
    this.enablePort = false;

    this.portData.forEach(element => {
      if (element.ContextPointIdParent === parseInt($event.value, 10)) {
        this.portNameView.push({
          text: element.ContextPointName,
          value: element.ContextPointId.toString(),
          isSelected: false
        });
      }
    });
    this.selectPortNameView.options = this.portNameView;
  }

  getCalculationDays() {
    this.waterFlowIntelligenceAdminService.getCalculationDays().subscribe(
      Response => {
        this.calculationDays = Response.recalculation[0].value;
        this.setState(ComponentState.ready);
      },
      error => {
        this.errorInfo = error;
        if (500 <= error.status && error.status < 600) {
        } else if (error.status === 417) {
          this.router.navigate(['/access-denied']);
        } else if (error.status === 404) {
          this.router.navigate(['/**']);
        } else {
          this.errorHandling.showErrorPopup(error);
        }
        this.setState(ComponentState.error);
      });
  }
  // create object for save sensor form
  SaveSensorForm($event) {
    const startDate = new Date($event.StartDate);
    const today = new Date();
    const Difference_In_Days = (today.getTime() - startDate.getTime()) / (1000 * 3600 * 24);
    if (Difference_In_Days > this.calculationDays && !$event.EndDate) {
      let six_month_ago = new Date().setDate(today.getDate() - this.calculationDays);
      const modalRef = this.modalService.open(CustomPopupComponent, { windowClass: 'saveExit-modal' });
      modalRef.componentInstance.modalText = 'Only the last ' + this.calculationDays + ' days will be re-calculated';
      // tslint:dSaveSeisable-next-line: max-line-length
      modalRef.componentInstance.modcontent = 'The Start date will be changed to ' + moment(six_month_ago).format('DD-MMM-YYYY');
      modalRef.componentInstance.showRadio = false;
      modalRef.componentInstance.isEnable = false;
      modalRef.componentInstance.disablebtn = true;
      modalRef.componentInstance.showBtn = false;
      modalRef.componentInstance.cancelBtn = true;
      modalRef.componentInstance.confirmBtn = 'Yes';
      modalRef.componentInstance.closeBtn = this.translate.instant('COMMON.CANCEL');
      modalRef.componentInstance.closeIcon = true;
      modalRef.componentInstance.deleteModal.subscribe(() => {
        modalRef.componentInstance.activeModal.close(true);
        this.saveFormDataProcced($event);
      });
      modalRef.componentInstance.stayModalPopUp.subscribe(() => {
        modalRef.componentInstance.activeModal.close(false);
      });
      modalRef.componentInstance.changeRef.markForCheck();
      return modalRef.result;
    } else {
      this.saveFormDataProcced($event);
    }
  }

  saveFormDataProcced($event) {
    $event.PortContextPointId = this.selectPortNameView.options.filter(item => item.isSelected);
    if (this.SensorFormData.MeasurementInstanceScales.length === 0) {
      this.SensorFormData.MeasurementInstanceScales.push(new MeasurementInstanceScales());
      this.SensorFormData.MeasurementInstanceScales[0].MeasurementInstanceScaleId = 0;
    }

    this.SensorFormData.MeasurementInstanceScales[0].MeasurementInstanceId = this.SensorFormData.MeasurementInstanceId;
    // eslint-disable-next-line max-len
    this.SensorFormData.MeasurementInstanceScales[0].StartDate = this.datePipe.transform(this.SensorFormData.StartDate, 'yyyy-MM-dd');
    this.SensorFormData.MeasurementInstanceScales[0].InputMin = this.SensorFormData.SelectedFlowData.InputMin;
    this.SensorFormData.MeasurementInstanceScales[0].InputMax = this.SensorFormData.SelectedFlowData.InputMax;
    this.SensorFormData.MeasurementInstanceScales[0].Offset = this.SensorFormData.SelectedFlowData.Offset;
    this.SensorFormData.MeasurementInstanceScales[0].LowValue = this.SensorFormData.SelectedFlowData.LowValue;
    this.SensorFormData.MeasurementInstanceScales[0].HighValue = this.SensorFormData.SelectedFlowData.HighValue;
    this.saveObjectService($event);
  }

  // delete and save sensor form service call
  saveDeleteMapping(type, data) {
    const SaveData = [];
    this.setState(ComponentState.loading);
    const selectedPortData = this.portData.find(pData => pData.ContextPointId === this.selectedRow.PortContextPointId);
    this.SensorSaveFormData.ParentContextPointId = selectedPortData.ContextPointIdParent;
    this.SensorSaveFormData.PortContextPointName = selectedPortData.ContextPointName;
    this.SensorSaveFormData.PortContextTypeId = selectedPortData.ContextPointType;
    this.SensorSaveFormData.PortContextSubTypeId = selectedPortData.ContextPointSubTypeId;
    this.SensorSaveFormData.MeasurementInstance.MeasurementInstanceScales[0].UnitUsageSubUnitId = this.UnitUsageSubUnitId;
    SaveData.push(this.SensorSaveFormData);
    this.waterFlowIntelligenceAdminService.saveSensorMappingForm(SaveData).subscribe(
      Response => {
        this.setState(ComponentState.ready);
        this.savedSensorDataValue.emit({ type: type, data: data });
        this.setState(ComponentState.ready);
      },
      error => {
        this.errorInfo = error;
        if (500 <= error.status && error.status < 600) {
        } else if (error.status === 417) {
          this.router.navigate(['/access-denied']);
        } else if (error.status === 404) {
          this.router.navigate(['/**']);
        } else {
          this.errorHandling.showErrorPopup(error);
        }
        this.setState(ComponentState.error);
      });
  }

  // on click of cancel get previous value and populate in sensor mapping
  CancelSensorForm() {
    this.NodeValue = this.initialNodeValue;
    this.isSensorSelected = false;
    this.selectSensorTypeView.options.forEach(sensor => { sensor.isSelected = false; });
    this.selectpipeSizeView.options.forEach(pipe => { pipe.isSelected = false; });
    this.stopmappingFlag = false;
    this.detectFormChange = false;
    if (this.NodeValue) {
      this.validateNode();
    } else {
      this.ngOnChanges(this.initialValuesReset);
      this.selectChannelView.options.length = 0;
      this.disabled = true;
      this.showDeviceTimeZoneDd = false;
    }
  }

  // only floating number validation on scale change
  validationNumberNegative(e) {

    const pattern = /^[0-9]*$/;
    const inputChar = String.fromCharCode(e.charCode);
    if (e.charCode === 45 && e.target.value.length === 0 && !pattern.test(inputChar)) {
      if (!pattern.test(inputChar) && e.charCode !== 45) {
        e.preventDefault();
      }
    } else if (e.charCode === 46 && e.target.value.length > 0) {
      if (!e.target.value.includes('.')) {
        if (!pattern.test(inputChar) && e.charCode !== 46) {
          e.preventDefault();
        }
      } else {
        e.preventDefault();
      }
    } else {
      if (!pattern.test(inputChar)) {
        e.preventDefault();
      }
    }
  }

  // only floating number validation on K factor change
  validationNumberRange(e, currentData) {
    const pattern = /^[0-9]*$/;
    const inputChar = String.fromCharCode(e.charCode);
    if (e.charCode === 46 && e.target.value.length > 0) {
      if (!e.target.value.includes('.')) {
        if (!pattern.test(inputChar) && e.charCode !== 46) {
          e.preventDefault();
        }
      } else {
        e.preventDefault();
      }
    } else {
      if (!pattern.test(inputChar)) {
        e.preventDefault();
      }
    }
  }

  kfactorInputValidation(e, currentData) {
    const target = e.target as HTMLInputElement;
    e = target.value;
    this.errorHighLow = false;
    if ((parseFloat(e) < currentData.HighValueMin) || (parseFloat(e) > currentData.HighValueMax) || e === '') {
      this.errorHighLow = true;
    } else {
      this.SensorFormData.SelectedFlowData.HighValue = e;
    }
  }

  // Open delete mapping dialog box
  deleteMapping(type) {
    const modalRef = this.modalService.open(CustomPopupComponent, { windowClass: 'saveExit-modal' });
    modalRef.componentInstance.modalText = (type == 'delete') ? 'Delete the Current Sensor Mapping?' : 'Stop the Current Sensor Mapping?';
    // eslint-disable-next-line max-len
    modalRef.componentInstance.modcontent = (type == 'delete') ? '' : 'To Stop Mapping, please select End Date and click Submit on the screen.';
    modalRef.componentInstance.showRadio = false;
    modalRef.componentInstance.isEnable = false;
    modalRef.componentInstance.disablebtn = true;
    modalRef.componentInstance.showBtn = false;
    modalRef.componentInstance.cancelBtn = true;
    modalRef.componentInstance.confirmBtn = 'Yes';
    modalRef.componentInstance.closeBtn = this.translate.instant('COMMON.CANCEL');
    modalRef.componentInstance.closeIcon = true;
    modalRef.componentInstance.deleteModal.subscribe(($event) => {
      this.CancelSensorForm();
      if (this.SensorFormData.MeasurementInstanceScales.length === 0) {
        this.SensorFormData.MeasurementInstanceScales.push(new MeasurementInstanceScales());
        this.SensorFormData.MeasurementInstanceScales[0].MeasurementInstanceScaleId = 0;
      }
      this.SensorFormData.MeasurementInstanceScales[0].MeasurementInstanceId = this.SensorFormData.MeasurementInstanceId;
      this.SensorFormData.MeasurementInstanceScales[0].StartDate = this.SensorFormData.StartDate;
      this.SensorFormData.MeasurementInstanceScales[0].LowValue = this.SensorFormData.SelectedFlowData.LowValue;
      this.SensorFormData.MeasurementInstanceScales[0].HighValue = this.SensorFormData.SelectedFlowData.HighValue;
      this.SensorFormData.MeasurementInstanceScales[0].InputMin = this.SensorFormData.SelectedFlowData.InputMin;
      this.SensorFormData.MeasurementInstanceScales[0].InputMax = this.SensorFormData.SelectedFlowData.InputMax;
      this.SensorFormData.MeasurementInstanceScales[0].Offset = this.SensorFormData.SelectedFlowData.Offset;
      const deleteData = JSON.parse(JSON.stringify(this.SensorFormData));
      deleteData.PortContextPointId = this.selectPortNameView.options.filter(item => item.isSelected);
      this.SensorSaveFormData = new SensorMappingFormDataSave(deleteData);
      this.SensorSaveFormData.IsDeleted = true;
      this.SensorSaveFormData.DeleteType = (type == 'delete') ? '1' : '2';
      if (type == 'delete') {
        this.saveDeleteMapping('delete', this.selectedRow);
        this.stopmappingFlag = false;
      } else {
        this.stopmappingFlag = true;
        this.detectFormChange = true;
      }
      modalRef.componentInstance.activeModal.close(true);
    });
    modalRef.componentInstance.stayModalPopUp.subscribe(($event) => {
      modalRef.componentInstance.activeModal.close(false);
    });
    modalRef.componentInstance.changeRef.markForCheck();
    return modalRef.result;

  }

  // update K factor to High value
  updateKFactorView($event) {

    this.SensorFormData.SelectedFlowData.HighValue = $event.value;
  }

  saveObjectService($event) {
    this.SensorSaveFormData = new SensorMappingFormDataSave($event);
    if (this.stopmappingFlag) {
      this.SensorSaveFormData.IsDeleted = true;
      this.SensorSaveFormData.DeleteType = '2';
      // let newEDate = moment(new Date(moment(this.SensorFormData.EndDate).format('MM/DD/YYYY'))).format().replace('+05:30', 'Z');
      // eslint-disable-next-line max-len
      this.SensorSaveFormData.MeasurementInstance.MeasurementInstanceScales[0].EndDate = this.datePipe.transform(this.SensorFormData.EndDate, 'yyyy-MM-dd');
      this.saveDeleteMapping('delete', this.selectedRow);
    } else {
      this.SensorSaveFormData.IsDeleted = false;
      let data = { MACAddress: this.NodeValue, PortContextPointId: $event.PortContextPointId, Channel: $event.Channel };
      this.saveDeleteMapping('save', data);
    }
  }

  // object creation for unit conversion service call.
  objectCreation(unitType: string, fromUnit: string, toUnit: string, originalValue) {
    const obj = {
      'UnitType': unitType,
      'FromUnit': fromUnit,
      'ToUnit': toUnit,
      'OriginalValue': originalValue,
      'ConvertedValue': null,
      'ErrorMessage': null,
      'ParameterName': null,
      'ConversionSource': 'si'
    };
    return obj;
  }

  isDateDisabled(date: Date) {
    return (date < new Date(this.SensorFormData.EarliestAvailableMappingDateTime) || date >= new Date());
    //  return  false;
  }

  isEndDateDisabled(date: Date) {
    return (date < new Date(this.SensorFormData.StartDate) || date >= new Date());
  }

  public onValueChange(date: Date) {
    this.startDateChanged = true;
    if (this.isDateDisabled(date)) {

      setTimeout(() => this.SensorFormData.StartDate = this.lastDate, 0);
    } else {
      this.lastDate = date;
    }
  }
  onEndDateChange(date: Date) {
    if (this.isEndDateDisabled(date)) {
      setTimeout(() => this.SensorFormData.EndDate = this.lastEndDate, 0);
    } else {
      this.lastEndDate = date;
    }
  }
  changeScaleValue(event) {
    if (event) {
      return Math.abs(event.replace(/,/g, ''));
    } else {
      return 0;
    }
  }

  getVolumeUnitList() {
    this.unitsVolumeDropdown.options = [];
    if (this.waterFlowIntelligenceAdminService.volumeUnits
      && this.waterFlowIntelligenceAdminService.volumeUnits.UnitUsageSubUnits.length > 0) {
      this.updateVolumeUnitsDropdown();
    } else {
      this.waterFlowIntelligenceAdminService.getUnitUsageById(UnitUsageType.Volume).subscribe((data) => {
        this.updateVolumeUnitsDropdown();
      });
    }
  }

  updateVolumeUnitsDropdown() {
    const units = JSON.parse(JSON.stringify(this.waterFlowIntelligenceAdminService.volumeUnits.UnitUsageSubUnits));
    if (this.UnitUsageSubUnitId) {
      units.forEach(element => {
        if (this.UnitUsageSubUnitId === element.UnitUsageSubUnitId) {
          element.IsSelected = true;
        } else {
          element.IsSelected = false;
        }
      });
    }
    units.forEach(element => {
      let item = {
        'value': element.SubUnit,
        'text': element.Symbol,
        'isSelected': element.IsSelected,
        'isDisabled': false,
        'IsDefault': element.IsBaseUnit,
        'DefaultSubUnitType': element.DefaultSubUnitType
      };

      this.unitsVolumeDropdown.options.push(item);
      if (element.IsSelected) {
        this.prevVolumeUnit = item;
        this.UnitUsageSubUnitId = element.UnitUsageSubUnitId;
      }
    });
  }

  getFlowUnitList() {
    this.unitsFlowDropdown.options = [];
    if (this.waterFlowIntelligenceAdminService.flowUnits
      && this.waterFlowIntelligenceAdminService.flowUnits.UnitUsageSubUnits.length > 0) {
      this.updateFlowUnitsDropdown();
    } else {
      this.waterFlowIntelligenceAdminService.getUnitUsageById(UnitUsageType.Flow).subscribe((data) => {
        this.updateFlowUnitsDropdown();
      });
    }
  }

  updateFlowUnitsDropdown() {
    const units = JSON.parse(JSON.stringify(this.waterFlowIntelligenceAdminService.flowUnits.UnitUsageSubUnits));
    if (this.UnitUsageSubUnitId) {
      units.forEach(element => {
        if (this.UnitUsageSubUnitId === element.UnitUsageSubUnitId) {
          element.IsSelected = true;
        } else {
          element.IsSelected = false;
        }
      });
    }
    units.forEach(element => {
      let item = {
        'value': element.SubUnit,
        'text': element.Symbol,
        'isSelected': element.IsSelected,
        'isDisabled': false,
        'IsDefault': element.IsBaseUnit,
        'DefaultSubUnitType': element.DefaultSubUnitType
      };
      this.unitsFlowDropdown.options.push(item);
      if (element.IsSelected) {
        this.prevFlowUnit = item;
        this.UnitUsageSubUnitId = element.UnitUsageSubUnitId;
      }
    });
  }

  getTemperatureUnitList() {
    if (this.waterFlowIntelligenceAdminService.temperatureUnits
      && this.waterFlowIntelligenceAdminService.temperatureUnits.UnitUsageSubUnits.length > 0) {
      this.updateTemperatureUnitsDropdown();
    } else {
      this.waterFlowIntelligenceAdminService.getUnitUsageById(UnitUsageType.Temperature).subscribe((data) => {
        this.updateTemperatureUnitsDropdown();
      });
    }
  }

  updateTemperatureUnitsDropdown() {
    let units = JSON.parse(JSON.stringify(this.waterFlowIntelligenceAdminService.temperatureUnits.UnitUsageSubUnits));
    if (this.UnitUsageSubUnitId) {
      units.forEach(element => {
        if (this.UnitUsageSubUnitId === element.UnitUsageSubUnitId) {
          element.IsSelected = true;
          this.UnitUsageSubUnitId = element.UnitUsageSubUnitId;
        } else {
          element.IsSelected = false;
        }
      });
    }
    units.forEach(element => {
      if (element.IsSelected) {
        this.UnitUsageSubUnitId = element.UnitUsageSubUnitId;
      }
    });
  }

  getUnitList(MeasurementId) {
    if (MeasurementId === 5) {
      this.getFlowUnitList();
    } else if (MeasurementId === 25) {
      this.getVolumeUnitList();
    } else if (MeasurementId === 7) {
      this.getTemperatureUnitList();
    }
    this.loadingUnits = false;
  }

  unitChange(event, SelectedData) {
    const collectionOfUnits = [];
    this.loadingUnits = true;
    if (event.name === 'flowUnits') {
      collectionOfUnits.push(this.objectCreation('VolumeRate', this.prevFlowUnit.value, event.value, SelectedData.LowValue));
      collectionOfUnits.push(this.objectCreation('VolumeRate', this.prevFlowUnit.value, event.value, SelectedData.HighValue));
    } else if (event.name === 'volumeUnits') {
      collectionOfUnits.push(this.objectCreation('Volume', this.prevVolumeUnit.value, event.value, SelectedData.LowValue));
      collectionOfUnits.push(this.objectCreation('Volume', this.prevVolumeUnit.value, event.value, SelectedData.HighValue));
    }

    this.waterFlowIntelligenceAdminService.postConversionByUnitType(collectionOfUnits).subscribe(
      response => {
        if (response) {
          this.SensorFormData.SelectedFlowData.LowValue = response[0].ConvertedValue;
          this.SensorFormData.SelectedFlowData.HighValue = response[1].ConvertedValue;
          if (event.name === 'flowUnits') {
            // eslint-disable-next-line max-len
            const activeUnit = this.waterFlowIntelligenceAdminService.flowUnits.UnitUsageSubUnits.find(element => element.SubUnit === event.value);
            this.UnitUsageSubUnitId = activeUnit.UnitUsageSubUnitId;
            this.prevFlowUnit = event;
          } else if (event.name === 'volumeUnits') {
            // eslint-disable-next-line max-len
            const activeUnit = this.waterFlowIntelligenceAdminService.volumeUnits.UnitUsageSubUnits.find(element => element.SubUnit === event.value);
            this.UnitUsageSubUnitId = activeUnit.UnitUsageSubUnitId;
            this.prevVolumeUnit = event;
          }
        }
        this.loadingUnits = false;
        this.setState(ComponentState.ready);
      },
      error => {
        this.errorInfo = error;
        if (500 <= error.status && error.status < 600) {
        } else if (error.status === 417) {
          this.router.navigate(['/access-denied']);
        } else if (error.status === 404) {
          this.router.navigate(['/**']);
        } else {
          this.errorHandling.showErrorPopup(error);
        }
        this.setState(ComponentState.error);
      }
    );
  }

  updateTimeZone(event) {
    if (event.value != null) {
      this.SensorFormData.DeviceTimeZoneId = parseInt(event.value);
      this.detectChange();
    } else {
      this.SensorFormData.DeviceTimeZoneId = null;
      this.detectFormChange = false;
    }
  }

  getDeviceTimeZoneDdData(contextPointType, contextPointCommonSubType, contextPointName) {
    this.invalidSiteDetails.length > 0 ? this.disableTimeZoneDd = true : this.disableTimeZoneDd = false;
    let timeZoneId = null;
    const getTimeZone = this.sharedService.getTimeZone();
    const getDataSourceContextPoints = this.sharedService.getDataSourceContextPoints(contextPointCommonSubType, contextPointType, contextPointCommonSubType, contextPointName);
    let forkJoinsInputs = [getDataSourceContextPoints, getTimeZone];
    forkJoin(forkJoinsInputs).subscribe(response => {
      timeZoneId = response[0][0]['DeviceTimeZoneId'];
      response[1].forEach(timezone => {
        let zone = {
          isSelected: false,
          value: timezone.TimeZoneId.toString(),
          text: timezone.Unix
        };
        this.timeZoneDdData.options.push(zone);
      });
      if (timeZoneId != null) {
        this.SensorFormData.DeviceTimeZoneId = timeZoneId;
        this.disableTimeZoneDd = false;
        this.timeZoneDdData.options[0].isSelected = false;
        this.timeZoneDdData.options.forEach(option => {
          if (option.value == timeZoneId) {
            option.isSelected = true;
            this.disableTimeZoneDd = true;
          }
        });
      }
    });
  }
  disableSubmitBtn():boolean{
    let result = false;
    if(this.loadingUnits){
      result = true;
    }else if(this.errorTwiceMeasuretoAPort !== '' || this.flagPortDiffMAC || this.errorHighLow || this.avoidMesurementMappingFlag ||  !this.isSensorSelected || this.enablePort) {
      result = true;
    // }else if(
      /* commented code need to re-written post Business confirmation  concerned team to takecare */
    //   (this.SensorFormData.SelectedFlowData.LowValue === null && this.SensorFormData.SelectedFlowData.LowValue !== 0)
    // || (this.SensorFormData.SelectedFlowData.HighValue === null && this.SensorFormData.SelectedFlowData.HighValue !== 0)
    // || (this.SensorFormData.SelectedFlowData.InputMin === null && this.SensorFormData.SelectedFlowData.InputMin !==0)
    // || (this.SensorFormData.SelectedFlowData.InputMax === null && this.SensorFormData.SelectedFlowData.InputMax !==0)
    // || (this.SensorFormData.SelectedFlowData.Offset === null && this.SensorFormData.SelectedFlowData.Offset !==0)){
    //   result = true;
    }else if(this.errorMsgInputFlag || this.errorMsgScaleFlag || this.errorMsgUsedChannel !== '' || this.errorMsgPortDiffMAC !== '' || this.errorMsgChannelAlreadyMapped || !this.detectFormChange){
      result = true;
    }else if((this.showDeviceTimeZoneDd && this.SensorFormData.DeviceTimeZoneId === null)){
      result = true;
    }
    return result;
  }
}
