<nalco-tile [parentInstance]="myParentInstance()"   [headerTitle]="" [errorData]='errorInfo' [showMoreOptions]=false [showInfoIcon]=false class="no-header">
<div tile-content>
<div class="data-merge-breadcrump">
        <nalco-breadcrumb [data]="data"></nalco-breadcrumb>
      </div>
      <div class="table-container">
        <div class="heading-box">
            <h2 class="heading">{{'NOTIFICATIONS.DATA_CONTINUITY' | translate}}</h2>
        </div>
        <div class="table-content">
            <table class="table layout-fixed">
                <thead>
                  <tr>
                    <th class="table-heading"><span>{{'NOTIFICATIONS.DETAILS' | translate}}</span></th>
                    <th class="table-heading"><span>{{'NOTIFICATIONS.OLD_DATA_SOURCE' | translate}}</span></th>
                    <th class="table-heading"><span>{{'NOTIFICATIONS.NEW_DATA_SOURCE' | translate}}</span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><span class="title">{{'NOTIFICATIONS.CONTROLLER_SERIAL_NUMBER' | translate}}</span></td>
                    <td><span>{{(oldDataSource && oldDataSource.SerialNumber) ? oldDataSource.SerialNumber : ''}}</span></td>
                    <td><span>{{(newDataSource && newDataSource.SerialNumber) ? newDataSource.SerialNumber : ''}}</span></td>
                  </tr>
                  <tr>
                    <td><span class="title">{{'NOTIFICATIONS.SOLD_TO_SHIP_TO' | translate}}</span></td>
                    <td><span>{{(oldDataSource && oldDataSource.DatasourceSoldToShipTo) ? oldDataSource.DatasourceSoldToShipTo : ''}}</span></td>
                    <td><span>{{(newDataSource && newDataSource.DatasourceSoldToShipTo) ? newDataSource.DatasourceSoldToShipTo : ''}}</span></td>
                  </tr>
                  <tr>
                    <td><span class="title">{{'NOTIFICATIONS.SYSTEM_NAME' | translate}}</span></td>
                    <td><span>{{(oldDataSource && oldDataSource.DatasourceSystemName) ? oldDataSource.DatasourceSystemName : ''}}</span></td>
                    <td><span>{{(newDataSource && newDataSource.DatasourceSystemName) ? newDataSource.DatasourceSystemName : ''}}</span></td>
                  </tr>
                  <tr>
                    <td><span class="title">{{'NOTIFICATIONS.ASSET_NAME' | translate}}</span></td>
                    <td><span>{{oldDSAssetName}}</span></td>
                    <td><span>{{newDSAssetName}}</span></td>
                  </tr>
                  <tr>
                    <td><span class="title">{{'NOTIFICATIONS.DATA_SOURCE_TYPE' | translate}}</span></td>
                    <td><span>{{(oldDataSource && oldDataSource.DeviceType) ? oldDataSource.DeviceType : ''}}</span></td>
                    <td><span>{{(newDataSource && newDataSource.DeviceType) ? newDataSource.DeviceType : ''}}</span></td>
                  </tr>
                  <tr>
                    <td><span class="title">{{'NOTIFICATIONS.MEASUREMENTS_MAPPED' | translate}}</span></td>
                    <td><span>{{(oldDataSource && oldDataSource.MappedAsset && oldDataSource.MappedAsset.length) ? 'Yes' : 'No' }}</span></td>
                    <td><span>{{(newDataSource && newDataSource.MappedAsset && newDataSource.MappedAsset.length) ? 'Yes' : 'No' }}</span></td>
                  </tr>
                </tbody>
                <div class= "table-footer">
                    <div class="table-footer-container">
                        <div class="buttons" [ngClass]="writeAlso ? 'with-map-button' : 'without-map-button'">
                            <div class="secondary" (click)="onCancel()">
                              <button class="button-label2">{{'COMMON.CANCEL' | translate}}</button>
                            </div>
                            <div class="primary" (click)="mapDataSource()" *ngIf="writeAlso">
                              <button class="button-label">{{'COMMON.MAP' | translate}}</button>
                            </div>
                        </div>
                        <small class="errorMsg" *ngIf="isCopyMappingFailed">{{'NOTIFICATIONS.SAVE_DATASOURCE_ERROR_MSG' | translate}}</small>
                    </div>
                    </div>

              </table>
        </div>
      </div>
  </div>
</nalco-tile>
