import { ErrorHandler,Injector,Injectable} from '@angular/core';  
import { ApplicationInsightsService } from '../analytics/logger.service';  
import { LocalCacheService } from '../../core/local-cache/local-cache.service';
@Injectable()  
export class ApplicationInsightsErrorHandler implements ErrorHandler {  
    constructor(private injector: Injector,
        private localCacheService: LocalCacheService) {}  
    handleError(error: any): void { 
        console.error(error);
        this.injector.get < ApplicationInsightsService > (ApplicationInsightsService).setUserId(this.localCacheService.fetchData('username')); 
        this.injector.get < ApplicationInsightsService > (ApplicationInsightsService).logException(error);  
    }  
}  