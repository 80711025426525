import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import { environment } from '../../../environments/environment';
import * as sharedContracts from '../../shared/contracts/_contracts';
import * as sharedModels from '../../shared/models/_models';
import { LocalCacheService } from '../../core/local-cache/local-cache.service';
import { AppEnvConfig } from '../../app.env.config';
import { SharedOmniConfig } from './shared-omni.service.config';
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { GridDataResult } from "@progress/kendo-angular-grid";
import { HttpClient } from '@angular/common/http';


@Injectable()
export class SharedOmniService extends BehaviorSubject<GridDataResult>{
    private configEnv: any;
    private usersnavigationmenu: sharedModels.Navigation;
    private contextPointDropdown = new BehaviorSubject<string>(null);
    csDropdownChangeEvnt = this.contextPointDropdown.asObservable();
    private filterPill = new BehaviorSubject<any>(null);
    filterPillChangeEvnt = this.filterPill.asObservable();

    constructor(
        private sharedServiceConfig: SharedOmniConfig,
        private httpClient: HttpClient,
        private config: AppEnvConfig,
        private localCacheService: LocalCacheService) {
            super(null);
        this.configEnv = sharedServiceConfig.getEnvironment();
        this.configEnv.apiServerUrl = config.getEnv('apiServerUrl');
        this.configEnv.jsonServerUrl = config.getEnv('jsonServerUrl');
        this.configEnv.apiWaterServerUrl = config.getEnv('apiWaterServerUrl');
        
    }

    changeDropdown(message){
        this.contextPointDropdown.next(message);
      }

    changeFilterPill(message) {
        this.filterPill.next(message);
      }

    private parse(url: string, params: string[] = []): string {
        let result: string = url;
        if (params.length > 0) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            result = url.replace(/\{(\d+)\}/g, function (match: string, current: string): string {
                return params[parseInt(current, 10)] !== undefined ? params[parseInt(current, 10)] : '';
            });
        }
        return result;
    }


    public getSiteDetails(siteId: string): Observable<any> {
        const params: any = {
            dateRange: '',
            indicatorType: ''
        };

        const url: string = this.parse(this.configEnv.jsonServerUrl + this.configEnv.apiUrl.getSiteCompliance, [siteId]);
        return this.httpClient.get(url, params).map(response => {
            return response['result'];
        });
    }

    getAssetMeasurementsToCompare(siteId: string, assetIds: Array<string>): Observable<Array<sharedModels.MeasurementToCompare>> {
        const params: any = {};

        params.assetIds = assetIds.join(',');
        params.measurementCategoryId = '';

        const url = this.configEnv.jsonServerUrl + this.parse(this.configEnv.apiUrl.getMeasurementsToCompare, [siteId]);

        return this.httpClient.get(url, { params: params }).map( (response: any) => {
            const measurementsResponse = response;
            const measurements = new Array<sharedModels.MeasurementToCompare>();

            if (measurementsResponse.result && measurementsResponse.result.length > 0) {
                measurementsResponse.result.forEach(measurement => {
                    measurements.push(new sharedModels.MeasurementToCompare(measurement));
                });
            }
            return measurements;
        });
    }

    public getUsersNavigationMenu(useremailaddress: string): Observable<sharedModels.Navigation> {
        if (this.usersnavigationmenu) {
            return Observable.of(this.usersnavigationmenu);
        } else {
            const fileTypesFromStorage = this.localCacheService.fetchJson('usersNavigationMenu', 7, 'local');
            if (fileTypesFromStorage) {
                this.usersnavigationmenu = fileTypesFromStorage;
                return Observable.of(this.usersnavigationmenu);
            }
        }

        const params: any = {};
        const url = this.configEnv.jsonServerUrl + this.parse(this.configEnv.apiUrl.getUsersNavigationMenu, [useremailaddress]);
        return this.httpClient.get(url, params).map(response => {
            this.usersnavigationmenu = response['result'];
            this.localCacheService.storeJson('usersNavigationMenu', this.usersnavigationmenu, 'local');
            return this.usersnavigationmenu;
        });
    }

    public getCustomerDivisionData(iD: number, filter: string): Observable<any> {
        const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getDagAccountChildren}${filter}/${iD}`;
        return this.httpClient.get(url).map(response => {
            try {
                return response;
            } catch (e) {
                return response;
            }
        });
    }   

    public getCustomerDivisionChild(iD: number, filter: string): Observable<any> {
        const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getDagParentEdgeChildren}${filter}/${iD}`;
        return this.httpClient.get(url).map(response => {
            try {
                return response;
            } catch (e) {
                return response;
            }
        });
    }

    public getSalerRegionDistrictTerritories(): Observable<any> {
        // const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getDagSalesRegionDistrictTerritories, ['NalcoWater']);
        // const url = this.parse(this.configEnv.apiUrl.getDagSalesRegionDistrictTerritories);
        const url = this.configEnv.apiServerUrl + this.configEnv.apiUrl.getDagSalesRegionDistrictTerritories;
        return this.httpClient.get(url).map(response => {
            try {
                return response;
            } catch (e) {
                return response;
            }
        });
    }

    public getRegionCountries(): Observable<any> {
        // const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getRegionCountries, ['NalcoWater']);
        // const url = this.parse(this.configEnv.apiUrl.getDagRegionCountries);
        const url = this.configEnv.apiServerUrl + this.configEnv.apiUrl.getDagRegionCountries;
        return this.httpClient.get(url).map(response => {
            try {
                return response;
            } catch (e) {
                return response;
            }
        });
    }

    public getProjectOverview(projectID: string, unitsOfMeasure: string): Observable<any> {

      const url = `${this.configEnv.apiWaterServerUrl}${this.configEnv.apiUrl.getProjectOverview}${projectID}?unitStandard=${unitsOfMeasure}`;
      return this.httpClient.get(url)
        .map((response: any) => response);
    }

    public getCorporateSetting(cpId: string): Observable<any> {
      const url = `${this.configEnv.apiWaterServerUrl}${this.configEnv.apiUrl.getCorporateSetting}${cpId}`;
      return this.httpClient.get(url)
        .map((response: any) => response);
    }

    public getSiteData(aPI: any): Observable<any> {  
        const url = `./assets/data/${aPI}`;   
        return this.httpClient.get(url).map(response => {
            try {         
                return response;
            } catch (e) {         
                return response;
            }
        });
    }

    public getsitesByCorporate(corporateIds: Array<number>): Observable<any> {
        const url = this.configEnv.apiServerUrl + this.configEnv.apiUrl.getSitesForCorporates;
        const body = JSON.stringify(corporateIds);
        return this.httpClient.post(url, body).map(response => {
          try {
            return response;
          } catch (e) {
            return response;
          }
        });
      }

    // eslint-disable-next-line max-len
    public getDashboardOverview(contextPointId: string, parentContextPointId: String, unitOfMeasure: String, businessUnit: string = 'All'): Observable<any> {
        // eslint-disable-next-line max-len
        let url = `${this.configEnv.apiWaterServerUrl}${this.configEnv.apiUrl.getDashboardOverview}${contextPointId}?unitStandard=${unitOfMeasure}&corporateCpId=${parentContextPointId}&dagType=${businessUnit}`;
        return this.httpClient.get(url)
            .map((response: any) => response);
    }

    
    public ContextPointDataService(params: any): Observable<any> {  
        
        let url = `${this.configEnv.apiWaterServerUrl}${this.configEnv.apiUrl.getContextPointStatus}`;

        return this.httpClient.post(url, params).map(response => {
            return response;
        });
    }

    public MACService(params: any): Observable<any> {  
        
        let url = `${this.configEnv.apiWaterServerUrl}${this.configEnv.apiUrl.getMACMeasurements}`;

        return this.httpClient.post(url, params).map(response => {
            return response;
        });
    }
    
    public GraphService(params: any): Observable<any> {  
        
        let url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getGraphData}`;

        return this.httpClient.post(url, params).map(response => {
            return response;
        });
    }

    public MeasurementIdService(params: any): Observable<any> {  
        
        let url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getMeasurementId}`;

        return this.httpClient.post(url, params).map(response => {
            return response;
        });
    }

    public DiagnosticService(params: any, active: boolean): Observable<any> {
        let url = `${this.configEnv.apiWaterServerUrl}${this.configEnv.apiUrl.getDiagnostic + '?active=' + active}`;
        return this.httpClient.post(url, params).map(response => {
           return response;
        });
      }

    public ContextPointGridService(params: any): Observable<any> {  
        
        let url = `${this.configEnv.apiWaterServerUrl}${this.configEnv.apiUrl.getContextPointGrid}`;

        return this.httpClient.post(url, params).map(response => {
            return response;
        });
    }

    public MeasurementDataService(params: any): Observable<any> {  
        
        let url = `${this.configEnv.apiWaterServerUrl}${this.configEnv.apiUrl.getMeasurementStatus}`;

        return this.httpClient.post(url, params).map(response => {
            return response;
        });
    }

    public GetTopBottomContextPoints(params: any): Observable<any> {  
        let url = `${this.configEnv.apiWaterServerUrl}${this.configEnv.apiUrl.getTopBottomContextPoints}`;
        return this.httpClient.post(url, params).map(response => {
            return response;
        });
    }
    public ContextPointsService(params: any): Observable<any> {  
        
        let url = `${this.configEnv.apiWaterServerUrl}${this.configEnv.apiUrl.getContextPoints}`;

        return this.httpClient.post(url, params).map(response => {
            return response;
        });
    }

    public ContextPointKpisService(params: any): Observable<any> {  
         
        let url = `${this.configEnv.apiWaterServerUrl}${this.configEnv.apiUrl.getContextPointKpis}`;
        return this.httpClient.post(url, params).map(response => {
            return response;
        });
    }

    public SitePerformanceService(params: any, groupCpId?: any): Observable<any> {  
        
        let groupContextPointId = null;
      
        if (groupCpId != '' && groupCpId != null && groupCpId != undefined && groupCpId != 0)
        {
            groupContextPointId = groupCpId;
        } 
        let url = `${this.configEnv.apiWaterServerUrl}${this.configEnv.apiUrl.getSiteAssetPerformance}`;
        if(groupContextPointId !== undefined && groupContextPointId !== null){
            url = `${this.configEnv.apiWaterServerUrl}${this.configEnv.apiUrl.getSiteAssetPerformance  + '?groupCpId=' + groupContextPointId}`;
        }

        return this.httpClient.post(url, params).map(response => {
            return response;
        });
    }
    public ContextPointKPIEnterprise(params: any): Observable<any> {  
        
        let url = `${this.configEnv.apiWaterServerUrl}${this.configEnv.apiUrl.getContextPointKPIEnterprise}`;

        return this.httpClient.post(url, params).map(response => {
            return response;
        });
    }

    public getEvocCvocCpID(FilterEvent: any){
        let contextPointId = '-1';
        if ((FilterEvent.event.parentId === '' || FilterEvent.event.parentId == null || FilterEvent.event.parentId === undefined) 
          && (FilterEvent.event.children === undefined || FilterEvent.event.children === '' || FilterEvent.event.children == null 
          || FilterEvent.event.children.length === 0)) {
              contextPointId = '-1';
        } else if (FilterEvent.event.parentId === '' || FilterEvent.event.parentId == null || FilterEvent.event.parentId === undefined) {
              const pos = FilterEvent.event.children.length - 1;
              contextPointId = FilterEvent.event.children[pos].contextPointId;
        } else {
          const accountId = FilterEvent.event.parentId;
          if (FilterEvent.event.children.length > 0) {
              const pos = FilterEvent.event.children.length - 1;
              contextPointId = FilterEvent.event.children[pos].contextPointId;
          } else {
              contextPointId = accountId;
          }
        }
        return contextPointId;    
  }

}
