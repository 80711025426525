import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { Subscription } from "rxjs/Subscription";
import { UserService } from '../../../core/user/user.service';
import { AccountSearch } from '../../models/AccountSearch';

@Component({
  selector: 'nalco-account-search',
  templateUrl: './account-search.component.html',
  styleUrls: ['./account-search.component.scss'],
  providers: [UserService]
})

export class AccountSearchComponent implements OnInit, OnDestroy {
  @ViewChild('autocomplete', { static: false }) autocomplete: AutoCompleteComponent;
  @Output() accountSelected = new EventEmitter<any>();
  @Output() accountCleared = new EventEmitter();
  @Input() placeholder: string;
  @Input() isQueryParams?: boolean = false;
  @Input() callClear: boolean;
  @Input() isEVOCFilter?: string;
  @Input() serviceEnabledName?: string = '';
  private _flag = true;

  @Input() set dataInput(value: any) {
    if (value !== undefined) {
      this.data = value;
      this.source = value;
      this._flag = false;
      this.isEVOCFilter && this.serviceEnabledName == 'WFI' ? this.valueChange(this.isEVOCFilter) : '';
      if (this.isQueryParams === true) {
        this.queryParamsValidation();     // need to call this only when query params is present 
      }
    }
  }


  queryParamsValidation() {
    if (this.data && this.data.length === 1) {
      this.initialAccountName = this.data[0].AccountName;
      this.source = this.queryParamsUsed;
      this._flag = true;
    }
  }

  @Input() set clearData(value: boolean) {

    if (this.autocomplete && value && value !== undefined) {
      this.autocomplete.reset();
      this.autocomplete.value = '';
    }
  }

  private subscriptions: Subscription[] = [];
  public data: Array<AccountSearch> = [];
  private source: Array<AccountSearch> = [];
  private queryParamsUsed: Array<AccountSearch> = [];
  private accDetails = {};
  @Input() initialAccountName: string;
  public companyName: string = this.translate.instant('WATER_SAFETY.COMPANY_NAME');
  @Input() resetOnClear: boolean = false;

  constructor(@Inject(UserService) private dataService: UserService, private translate: TranslateService) {
  }


  debounce(callback, delay) {
    let timeout;
    return function () {
      clearTimeout(timeout);
      timeout = setTimeout(callback, delay);
    }
  }

  handleFilter(value) {
    this.debounce(this.getdropdown(value), 1000);
    if (value == '' && this.resetOnClear) {
      this.autocomplete.reset();
      this.accountCleared.emit();
    }
  }

  getdropdown(value) {
    this.autocomplete.toggle(false);
    if (value.length >= 3) {
      this.data = this.source.filter((s) => s.AccountName.toLowerCase().indexOf(value.toLowerCase()) !== -1);
      if (this.data.length > 0) {
        this.autocomplete.toggle(true);
      } else {
        setTimeout(() => {
          this.autocomplete.toggle(true);
        }, 1000);
      }
    }
  }

  ngOnInit() {
    if (this._flag) {
      this.subscriptions.push(this.dataService.getUserSettings().subscribe(
        (data) => {
          this.source = data.Accounts;
          this.queryParamsUsed = data.Accounts; // to keep a copy of the original Array
          this.data = data.Accounts;
          if (this.autocomplete) {
            this.autocomplete.toggle(false);
          }
          if (data.Accounts.length === 1) {
            let account = data.Accounts[0];
            this.initialAccountName = account.AccountName;
            this.valueChange(this.initialAccountName);
            // this.autocomplete.readonly = true;
          }
          if (this.isEVOCFilter) {
            setTimeout(() => {
              this.initialAccountName = this.isEVOCFilter;
              this.valueChange(this.isEVOCFilter);
            }, 300);
          }
        }
      ));
    }
  }

  ngOnChanges(change: SimpleChanges) {
    if (change.callClear) {
      if (this.autocomplete) {
        this.autocomplete.reset();
        this.autocomplete.value = '';
      }
    }
  }

  ngOnDestroy() {
    // prevent memory leak when component is destroyed
    this.subscriptions.forEach(s => s.unsubscribe());
  }






  public valueChange(value: any): void {
    const account: AccountSearch = this.data.find((s) => s.AccountName.toLowerCase() === value.toLowerCase());
    if (account !== undefined) {

      this.accDetails['accId'] = account.AccountId;
      this.accDetails['accName'] = value;
      this.accountSelected.emit(this.accDetails);
    } else {
      // User clear selection emit clearAccount event
      // console.log('account cleared');
      this.accountCleared.emit();
    }

  }

}
