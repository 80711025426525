import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class headerHandlerService {
    private btnClickSource = new Subject<string>();


    btnclickhead(): Observable<any> {
        return this.btnClickSource.asObservable();
     }

     btnclickhead$(event) {
        this.btnClickSource.next(event);
     }
}

