<div class="customize-grid-modal">
  <div *ngIf="showUnsupported" #alert class="alert alert-dismissible fade show" role="alert">
          <p translate>COMMON.UNSUPPORTED_MESSAGE</p>
          <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="closeUnsupportedMessage()">
            <span aria-hidden="true" class="ico-exit-round-edge"></span>
          </button>
        </div>
<div class="modal-header">
  <h3 class="modal-title" translate> FINANCIAL_IMPACT.CUSTOMIZE_TABLE </h3>
  <span class="upto-more" *ngIf="widgetName === 'ProjectTable'" translate> FINANCIAL_IMPACT.SELECT_UPTO_TEN </span>
  <span class="upto-more" *ngIf="widgetName === 'ActionLogAndRecommendation'" translate> ACTION_LOG.SELECT_UPTO_ELEVEN </span>
  <button type="button" class="close" aria-label="Close" (click)="dimissModal()">
      <span class="ico-exit-round-edge"></span>
  </button>
</div>
<div class="filter-note">
  <span translate> FINANCIAL_IMPACT.CUSTOMIZE_POPUP_NOTE </span>
  <p class="validation-message" *ngIf="invalidSelection && widgetName === 'ProjectTable'" translate> FINANCIAL_IMPACT.CUSTOMIZE_POP_ERROR_MSG </p>
  <p class="validation-message" *ngIf="invalidSelection && widgetName === 'ActionLogAndRecommendation'" translate> ACTION_LOG.CUSTOMIZE_POP_ERROR_MSG </p>
</div>
<div class="modal-body">
  <ul class="assets-list">
      <li class="custom-control custom-checkbox asset-row clearfix" *ngFor="let item of inputData">
          <input type="checkbox" [disabled]="item.isLocked" class="custom-control-input ng-dirty ng-touched" id="{{item.title}}" [checked]="item.defaultActive"
              [(ngModel)]="item.defaultActive" (change)="onChangeItemInPopup(item.columnOrder, $event)" />
          <label class="custom-control-label" for="{{item.title}}" translate>{{item.title}}</label>
      </li>
  </ul>
</div>

<div class="modal-footer">
  <div>
          <a href="javascript:void(0)" *ngIf="widgetName != undefined && widgetName != 'ActionLogAndRecommendation'" class="restoreButton" (click)="clearAllFilters()" translate> COMMON.RESTORE_DEFAULT </a>
  </div>
  <div>
  <button type="button" class="btn btn-secondary" (click)="dimissModal()" translate> COMMON.CANCEL</button>
  <button type="button" class="btn btn-primary" (click)="saveFilteredPills()" translate>COMMON.SAVE </button>
</div>
</div>
</div>