<nav aria-label="breadcrumb" role="navigation" class="ml-2">
    <ol class="breadcrumb">
        <!-- Check if data length is greater than 3 -->
        <ng-container *ngIf="data?.length > 3">
            <!-- Display first element -->
            <li class="breadcrumb-item" [ngClass]="{
                'ssdBreadcrumb': (ssd && data[0]?.text?.length > 52),
  
                'omniSurveysBreadcrumb': (surveys && data[0]?.text?.length > 30)
  
            }">
                <a *ngIf="data[0]?.link" [href]="'#/' + data[0]?.link" 
                   [ngbTooltip]="(ssd && data[0]?.text?.length > 52) ? data[0]?.text : ''" 
  
                   [title]="(surveys && data[0]?.text?.length > 30) ? data[0]?.text : ''" 
                   placement="bottom">
                   {{data[0]?.text}}
                </a>
                <span class="no-print" *ngIf="data[0]?.link === ''" 
                      [ngbTooltip]="(ssd && data[0]?.text?.length > 52) ? data[0]?.text : ''" 
                      [title]="(surveys && data[0]?.text?.length > 30) ? data[0]?.text : ''" 
                      placement="bottom">
                      {{data[0]?.text}}
                </span>
            </li>
            <!-- Display "..." -->
            <li *ngFor="let x of newData" class="breadcrumb-dots" (click)="handleClick($event, data)" placement="bottom" style="cursor: pointer;">
              <a *ngIf= "x.link !==''" href="#/{{x?.link}}" [ngbTooltip]="ssd && x?.text?.length>52 && last? x?.text : ''" [title]="surveys && x?.text?.length>30 ? x?.text : ''" placement="bottom">{{x.text}}</a>
  
              <span *ngIf = "x.link === ''" [ngbTooltip]="ssd  && x?.text?.length>52? x?.text : ''" [title]="surveys && x?.text?.length>30 ? x?.text : ''" placement="bottom">{{x.text}}</span>
            </li>
          
            <!-- Display last element -->
            <li class="breadcrumb-item" [ngClass]="{
                'ssdBreadcrumb': (ssd && data[data?.length - 1]?.text?.length > 52),
                'omniSurveysBreadcrumb': (surveys && data[data?.length - 1]?.text?.length > 30)
            }">
                <a *ngIf="data[data?.length - 1]?.link" [href]="'#/' + data[data?.length - 1]?.link" 
                   [ngbTooltip]="(ssd && data[data?.length - 1]?.text?.length > 52) ? data[data?.length - 1]?.text : ''" 
                   [title]="(surveys && data[data?.length - 1]?.text?.length > 30) ? data[data?.length - 1]?.text : ''" 
                   placement="bottom">
                   {{data[data?.length - 1]?.text}}
                </a>
                <span class="no-print" *ngIf="data[data?.length - 1]?.link === ''" 
                      [ngbTooltip]="(ssd && data[data?.length - 1]?.text?.length > 52) ? data[data?.length - 1]?.text : ''" 
  
                      [title]="(surveys && data[data?.length - 1]?.text?.length > 30) ? data[data?.length - 1]?.text : ''" 
  
                      placement="bottom">
                      {{data[data?.length - 1]?.text}}
                </span>
            </li>
        </ng-container>
        <!-- Display individual elements if data length is less than or equal to 3 -->
        <ng-container *ngIf="data?.length <= 3">
            <li class="breadcrumb-item" *ngFor="let x of data; let last = last" [ngClass]="{
                'ssdBreadcrumb': (ssd && x?.text?.length > 52),
  
                'omniSurveysBreadcrumb': (surveys && x?.text?.length > 30)
            }">
                <a *ngIf="x?.link" [href]="'#/' + x?.link" 
  
                   [ngbTooltip]="(ssd && x?.text?.length > 52 && last) ? x?.text : ''" 
                   [title]="(surveys && x?.text?.length > 30 && last) ? x?.text : ''" 
                   placement="bottom">
                   {{x?.text}}
                </a>
                <span class="no-print" *ngIf="x?.link === ''" 
                      [ngbTooltip]="(ssd && x?.text?.length > 52 && last) ? x?.text : ''" 
                      [title]="(surveys && x?.text?.length > 30 && last) ? x?.text : ''" 
                      placement="bottom">
                      {{x?.text}}
                </span>
            </li>
        </ng-container>
    </ol>
  </nav>