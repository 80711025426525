export const MeasureTypeList = [
    {
        EventCode: 6,
        MeasureType: [
            {
                MeasureId: 5,
                MeasureName: 'Flow',
                MeasureUnit: 'Amp',
                FlowMeterType: 1,
                PipeSizeUnit: [
                    {
                        text: 'Gallon',
                        symbol: 'gal'
                      },
                      {
                        text: 'Liter',
                        symbol: 'L'
                      },
                ],
                disable: 'Sensor, Pipesize'
            }, {
                MeasureId: 7,
                MeasureName: 'Temperature',
                FlowMeterType: 2,
                MeasureUnit: '°C',
                PipeSizeUnit: [
                    {
                        text: 'Fahrenheit',
                        symbol: 'F'
                      },
                    {
                        text: 'Celsius',
                        symbol: '°C'
                      }
                ],
                disable: ''
            }
        ]

    }, {
        EventCode: 9,
        MeasureType: [
            {
                MeasureId: 25,
                MeasureName: 'Volume',
                MeasureUnit: 'Amp',
                FlowMeterType: 4,
                PipeSizeUnit: [
                    {
                        text: 'Gallon',
                        symbol: 'gal'
                      },
                      {
                        text: 'Liter',
                        symbol: 'L'
                      },
                ],
                disable: ''
            }
        ]
    }, {
        EventCode: 7,
        MeasureType: [
            {
                MeasureId: 7,
                MeasureName: 'Temperature',
                FlowMeterType: 8,
                MeasureUnit: '',
                PipeSizeUnit: [
                    {
                        text: 'Fahrenheit',
                        symbol: 'F'
                      },
                    {
                        text: 'Celsius',
                        symbol: '°C'
                      }
                ],
                disable: ''
            }
        ]
    }];

export const ConversionUnit = [
    {
        unit: 'Volume',
        baseValue: 'cubic_meter',
        targetValue: 'gallon',
        value: 1,
        conversionfactor: 'si'
    },
    {
        unit: 'Volume',
        baseValue: 'cubic_meter',
        targetValue: 'liter',
        value: 1,
        conversionfactor: 'si'
    },
    {
        unit: 'Temperature',
        baseValue: 'kelvin',
        targetValue: 'Fahrenheit',
        value: 1,
        conversionfactor: 'si'
    },
    {
        unit: 'Temperature',
        baseValue: 'kelvin',
        targetValue: 'Celsius',
        value: 1,
        conversionfactor: 'si'
    },
];

export enum UnitUsageType {
    None = 0,
    Flow = 960,
    Volume = 962,
    Temperature = 964
}
