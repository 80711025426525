<li (click)="itemClicked()"  class="nav-item slds-nav-vertical__item nav-item-container  cPRTL_SideNav_MenuItem_TopLevel '+tooltip" [ngClass]="[showSubMenu ? 'slds-has-submenu' : '', isExpand ? 'left-navigation-panel':'']" (mouseover)="mouseover()">
  <!-- [ngClass]="selected ? 'active': ''" To be added once highlight user story created  --> 
  <a class="sidebar-hover" 
  routerLinkActive="active" placement="right" [ngbTooltip]="isExpand ? '' : heading | translate" container="body"  tooltipClass="custom-tooltips">
    <div class="img-class bx show-menu" >
    <img class="icon-css img-icon-mr show-menu" src="./../../../assets/images/menus/{{icon}}.svg">
    </div>
    <span class="links_name" [ngClass]="subMenu.length > 0 ? '' : 'singlemenu'" *ngIf="isExpand" 
      [ngStyle]="{'white-space': (text | translate).length > 20 ? text === 'LEFT_NAV.MAIN_NAV_LABEL' ? 'nowrap' : 'normal' : 
      (text === 'LEFT_NAV.MAIN_NAV_FEEDBACKFORUMS' || text === 'ACTION_LOG.HEADER.ACTION_LOG_REC') && !isE3DChina ? 'normal' : 'nowrap'}">{{text |
      translate}}</span>
      <div class="img-class bx opacity-nav show-menu" *ngIf="subMenu.length > 0">
        <img class="icon-css img-icon-mr show-menu" [ngStyle]="{'margin-right': (text | translate).length > 15 ? text === 'LEFT_NAV.MAIN_NAV_LABEL' ? '35px' : '0px' : '0px'}" src='./../../../assets/images/menus/arrows/{{arrow}}.svg'>
      </div>
  </a>
    <div *ngIf="showSubMenu" class="left-sub-nav" [ngClass]="[(text === 'LEFT_NAV.MAIN_NAV_TOOLS') ?  'tools-nav': text === 'LEFT_NAV.MAIN_NAV_LABEL' ? 'top-nav-item': 'bottom-leftsub-nav' , 
      !isExpand && subMenu.length > 0 && selected ? 'show-sub-nav-bar' : '', text === 'LEFT_NAV.ADMIN_NAV_LABEL'  ?  subMenu.length < 3 ? 'settings-nav': 'tools-nav' : '']"
      [ngStyle]="{'left': isExpand ? '190px' : '64px', display : selected && isMobile ? 'block' : ''  }"> 
      <h3 class="font-light pl-4 pr-2 pt-4 title-small">{{heading | translate}}</h3>
      <nalco-divider *ngIf="!isMobile" [color]="dividerCss.color" [length]="isExpand ? dividerCss.maxLength : dividerCss.minLength" [isHorizontal]="dividerCss.isHorizontal">
      </nalco-divider>
      <ul class="submenu-ul" [ngClass]="!showDropDown && isMobile ? 'submenu-ul-hide' : '' " [ngStyle]="{'columns': isMobile ? 'initial': subMenu?.length > 6 ? '3' : 'auto'}">
      <nalco-submenu-item *ngFor="let subMenuNav of subMenu" [details]="subMenuNav"
        (submenuItemClick)="subMenuItemClick($event)">
      </nalco-submenu-item>
    </ul>
    </div>
</li>
