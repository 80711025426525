<div class="overlay-side-panel-drawer">

</div>

<div class="side-panel-drawer" *ngIf="sidePanelValue">
    <div class="side-card-header">
        <div class="card-header">
            <span class="header-icon"><img src="{{headerDetails.headerOptions.icon}}"></span>
            <span class="card-title" [ngStyle]="{'color': headerDetails.headerOptions.color ? headerDetails.headerOptions.color  : ''}">{{headerDetails.headerOptions?.headerText}}</span>
            <span class="close-side-panel float-right" (click)="closeSidePanel()"><img
                    src="./../../../assets/images/filter-icons/close-button.svg"></span>
                    <nalco-divider *ngIf="showDevider" [color]="dividerCss.color"  [length]="dividerCss.maxLength" [isHorizontal]="dividerCss.isHorizontal"
                    [customClasses]="'custom-devider'">
                    </nalco-divider>
        </div>

    </div>

    <div class="side-card-body">
        <ng-content select="[side-panel-body]"></ng-content>
    </div>
    <div class="side-card-footer">
        <ng-content select="[side-panel-footer]"></ng-content>
    </div>
</div>