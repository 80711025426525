<div class="dd-container select-dropdown" [ngClass]="isDisable ? 'disable' : 'enable'">
	<div class="dd-control-container" (click)="toggle()" tabindex="0" [ngClass]="isListHide ? 'hidden' : 'shown'"
		#displayLabel>
		<span *ngIf="!editable"  class="dd-display-item overflow-ellipsis text-transform-none" role="textbox" #element2 placement='bottom'
			[title]="(element2.offsetWidth+1 < element2.scrollWidth) ? display : '' ">
			<div for="floatField" class = "heading" *ngIf="headerValue !== undefined">{{headerValue}}<span class="required">*</span></div>
			{{display | translate}}<span class="required" *ngIf="isRequired"> *</span>
		</span>
		<input *ngIf="editable" class="col-sm-12 drop-input editable-view dd-display-item overflow-ellipsis text-transform-none" placeholder="{{'COMMON.Add' | translate}}" maxlength="250"
			type="text" [(ngModel)]="display" (blur)="focusOutFunction()" (keyup)="onEditableKeyUp(display)" #test  role="textbox" placement='top'
			[title]="(test.offsetWidth+1 < test.scrollWidth) ? display : '' " #EditInput>
	</div>
	<div class="dd-list-container" [ngClass]="{'ah-asset-specific' : hideClearSelection === true}" [hidden]="isListHide">
		<div *ngIf="showFilter" class="search-box"
			[ngClass]="showLoader || (items.length === 0 && !showLoader && searchText === '' && SearchType !== 'server') ? 'disable' : 'enable'">
			<span class="ico-nav-search mb-0">
			</span>
			<input placeholder="{{'COMMON.SEARCH' | translate}}"
				[disabled]="showLoader || (items.length === 0 && !showLoader && searchText === '' && SearchType !== 'server')"
				type="text" class="drop-input" [(ngModel)]="searchText" (keyup)="onKeyUp(searchText)"
				(keypress)="onKeyPress($event)" #filterInput>
		</div>
		<div class="list-items">
			<div class="filterByCategory" *ngIf="showFilterBycategory">
				<div ngbDropdown>
					<button class="btn btn-outline-primary drop-btn" id="dropdownConfig" ngbDropdownToggle>
						{{'COMMON.FILTER_BY_CATEGORY' | translate}}
					</button>
					<div ngbDropdownMenu aria-labelledby="dropdownConfig">
						<button class="dropdown-item" *ngIf="categoryList.length === 0"
							[innerHTML]="noMatchingCategory"></button>
						<span *ngFor="let option of categoryList" class="d-block">
							<label class="form-check-label radio-items overflow-ellipsis" container="body" #element3
								placement='bottom'
								[title]="(element3.offsetWidth+1 < element3.scrollWidth) ? option.text : '' ">
								<input type='checkbox' [checked]="option.checked" class="form-check-input"
									(change)="onCategoryChange(option)">
								<span></span>
								{{option.text}}
							</label>
						</span>
					</div>
				</div>
			</div>
			<ng-container *ngIf="showAdd">
				<button class="float-right btn btn-primary btn-sm add-button" type="button" (click)="addItemClicked()" translate>COMMON.ADD</button>
			</ng-container>
			<ng-container *ngIf="showPlaceHolder">
				<div *ngIf="showLoader || (items.length !== 0 && SearchType === 'server') || (SearchType === 'list' && (items | textSearch:searchText)?.length != 0)"
				[attr.data-dd-value]="{id:'', display:'Select'}" class="dd-select-list">{{placeholder}}</div>
			</ng-container>

			<div #scroll *ngIf="SearchType === 'server'" class="dd-items-container" [ngClass]="(isFromAHInventory && items.length == 0) ? 'dd-default-height' : ''" infiniteScroll
				[infiniteScrollContainer]="scroll" [infiniteScrollDistance]="1" [infiniteScrollThrottle]="500"
				[scrollWindow]=false (scrolled)="fetchMore()">
				<div #listItems [ngClass]=" { 'dd-active dd-hightlight-item' :isSelected(item)} "
					class="dd-list-item overflow-ellipsis text-transform-none" *ngFor="let item of items; let i=index "
					#element1 placement='bottom'
					[title]="(element1.offsetWidth+1 < element1.scrollWidth) ? item?.display : '' "
					[attr.data-dd-value]="stringify(item)" (mouseover)="onHover($event) " (mouseout)="onHover($event) ">
					<label class="form-check-label radio-items" *ngIf="!multiSelection">
						<input type='checkbox' class="form-check-input" [checked]="item.checked"
							(change)="onItemSelect(item,$event)">
						<span></span>
						<img *ngIf="item?.isImage && imgBeforeLabel" [ngClass]="imgBeforeLabel ? 'beforeLabel' : ''"
							[src]="'/assets/images/3d-' + item.isImage +'.png'">
						{{item?.display | translate}}


					</label>
					<label class="form-check-label checkbox-items" *ngIf="multiSelection">
						<input type='checkbox' (change)="onItemSelect(item, $event)">
						<span class="checkmark"></span>

						{{item?.display | translate}}

					</label>
					<img *ngIf="item?.isImage && !imgBeforeLabel" [src]="'/assets/images/3d-' + item.isImage +'.png'">

				</div>
				<div class="dd-select-list" *ngIf="items.length === 0 && !showLoader">
					{{noRecoredsLabel}}
				</div>
				<span class="loader" *ngIf="showLoader"></span>
			</div>
			<div #scroll *ngIf="SearchType === 'list'" class="dd-items-container" infiniteScroll
				[infiniteScrollContainer]="scroll" [infiniteScrollDistance]="1" [infiniteScrollThrottle]="500"
				[scrollWindow]=false (scrolled)="fetchMore()">
				<div #listItems [ngClass]=" { 'dd-active dd-hightlight-item' :isSelected(item)} "
					class="dd-list-item overflow-ellipsis text-transform-none" *ngFor="let item
				 of items | textSearch :searchText; let i=index; let last = last" #element2 placement='bottom'
					[title]="(element2.offsetWidth+1 < element2.scrollWidth) ? item?.display : '' "
					[attr.data-dd-value]="stringify(item)" (mouseover)="onHover($event) " (mouseout)="onHover($event) ">
					<label class="form-check-label radio-items" *ngIf="!multiSelection">
						<input [disabled]="isDefaultSelected && i === 0 ? item.checked : false" type='checkbox'
							class="form-check-input" [checked]="item.checked" (change)="onItemSelect(item,$event)">
						<span></span>
            <img *ngIf="item?.isImage && imgBeforeLabel" [ngClass]="imgBeforeLabel ? 'beforeLabel' : ''"
              [src]="'/assets/images/3d-' + item.isImage +'.png'">
						{{item?.display | translate}}

					</label>
					<label class="form-check-label checkbox-items" *ngIf="multiSelection">
						<input type='checkbox' (change)="onItemSelect(item , $event)">
						<span class="checkmark"></span>
						{{item?.display | translate}}
						<span *ngIf="item?.isMapped" class="float-right legend-text"></span>
					</label>
					<img *ngIf="item?.isImage && !imgBeforeLabel" [src]="'/assets/images/3d-' + item.isImage +'.png'">
				</div>
				<div class="dd-select-list"
					*ngIf="(items | textSearch:searchText)?.length === 0 && !showLoader && searchText !== ''">
					{{noRecoredsLabel}}
				</div>
				<div class="dd-select-list" *ngIf="items.length === 0 && !showLoader && searchText === ''">
					{{noMatchingRecoredsLabel}}
				</div>
				<span class="loader" *ngIf="showLoader"></span>
			</div>
			<div [ngClass]="{'disabled': disableClearSelection}">
				<div [attr.data-dd-value]="{id:'', display:'Select'}" [ngClass]="{'hide-clear-list': hideClearSelection === true || isFromOmniSurveys }" class="dd-clear-list" (click)="onClear()">
					{{'COMMON.CLEAR_SELECTION' | translate}} </div>
			</div>

		</div>

		<!-- <div><p class="drop-input">Clear Selection</p></div> -->
	</div>

</div>
