<!-- Measurement Comment Modal pop up -->
<div *ngIf="modalText == 'measurement-comments'">
  <div class="modal-header">
    <h4 class="modal-title" *ngIf="!dataFetched">{{'Edata.ADD_COMMENT' | translate}}</h4>
    <h4 class="modal-title" *ngIf="dataFetched">{{'Edata.COMMENT_BUTTON' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeCommentModal(measurementCommentForm)">
      <span class="ico-exit-round-edge"></span>
    </button>
  </div>
  <div class="displayLabels">
    <label class="pl-4 pb-1 pt-2 mb-0">
      <span *ngIf="displayData.contextPoint.ParentName !== null && displayData.contextPoint.ParentName !== ''">
        {{displayData.contextPoint.ParentName}} | </span>
      {{displayData?.contextPoint?.ContextPointName}}
    </label>
    <label class="pl-4 pb-1 mb-0">
      <span
        *ngIf="displayData?.measurementDetails?.DisplayName && displayData?.measurementDetails?.DisplayName !== ''">{{displayData?.measurementDetails?.DisplayName}}</span>
      <span
        *ngIf="displayData?.measurementDetails?.StuiName && displayData?.measurementDetails?.StuiName != 'N/A' && displayData?.measurementDetails?.StuiName != ''"> | {{displayData?.measurementDetails?.StuiName}}</span>
      <span
        *ngIf="displayData?.measurementDetails?.MeasurementSource && displayData?.measurementDetails?.MeasurementSource !== 'Default'"> | {{displayData?.measurementDetails?.MeasurementSource}}</span>
    </label>
    <label class="pl-4 pb-1">
      <span *ngIf="displayData?.measurementLimit?.LowerLimit || displayData?.measurementLimit?.HigherLimit">
        {{displayData?.measurementLimit?.LowerLimit}}<span *ngIf="displayData?.measurementLimit?.LowerLimit"> -</span>
        {{displayData?.measurementLimit?.HigherLimit}}<span *ngIf="displayData?.measurementLimit?.HigherLimit"></span>
      </span>
      <span *ngIf="!displayData?.measurementLimit?.LowerLimit && !displayData?.measurementLimit?.HigherLimit">No
        Limits</span>
      <span *ngIf="displayData?.dataCaptureUnit"> ({{displayData?.dataCaptureUnit}})</span>
    </label>
    <label class="text-center">{{'Edata.READING_VALUE' | translate}}
      <span *ngIf="displayData?.measurementCellValue"> {{displayData?.measurementCellValue}}</span>
    </label>
  </div>
  <form [formGroup]="measurementCommentForm" (submit)="submitMeasurementForm(measurementCommentForm)">
    <div class="modal-body">
      <div class="commentInput">
        <label class="text-uppercase">{{'Edata.COMMENT_BUTTON' | translate}}</label>
        <label>{{'Edata.MEASUREMENT_MAX_LIMIT' | translate}}</label>
        <textarea type="text" class="w-100" placeholder="Enter Comment" formControlName="comment"
          maxlength="3000"></textarea>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" (click)="closeCommentModal(measurementCommentForm)"
        class="btn btn-primary">{{'COMMON.CANCEL'| translate}}</button>
      <button type="submit" class="btn btn-primary" [disabled]="isButtonDisabled">{{'COMMON.SAVE'| translate}}</button>
    </div>
  </form>
</div>

<div *ngIf="commentLoader"
  [ngClass]="modalText == modals.DELETE_DATASET_CONFIRMATION ? 'deleteLoaderWrapper' : 'commentLoaderWrapper'">
  <span class="loader"></span>
</div>
<!-- Retry save readings modal pop up -->
<div *ngIf="modalText == modals.RETRY_SAVE_READINGS">
  <div class="modal-header pt-4 pl-4 mb-0 border-0">
    <h3 class="modal-title">
      <span><img src="../../../../assets/images/alert-red.svg"></span>
      <span class="m-2">{{'Edata.ERROR' | translate}}</span>
    </h3>
  </div>
  <div class="modal-body">
    <p class="confirm_msg mb-0">{{'Edata.UNSAVED_DATA_MSG' | translate}}</p>
  </div>
  <div class="modal-footer mt-0">
    <button type="button" (click)="closeRetryModal()" class="btn btn-primary">{{'COMMON.CANCEL'| translate}}</button>
    <button type="button" (click)="submitSavedReadings()" class="btn btn-primary">{{'Edata.SUBMIT_READINGS'|
      translate}}</button>
    <button type="button" (click)="retryAutosave()" class="btn btn-primary">{{'Edata.RETRY'| translate}}</button>
  </div>
</div>
<div *ngIf="modalText == modals.CALCULATED_MSR_POPUP">
  <div class="modal-header">
    <h4 class="modal-title">{{'Edata.CALCULATED_MEASUREMENT' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeMeasurementModal()">
      <span class="ico-exit-round-edge"></span>
    </button>
  </div>
  <div class="min-dimentions" *ngIf="showLoader">
  </div>
  <div *ngIf="!showLoader">
    <div>
      <label class="pl-3 pb-1 pt-2 mb-0">
        {{'Edata.SAMPLE_POINT' | translate}} -
        <span
          *ngIf="selectedCalcMsrDetails.samplePointRow.ParentName !== null && selectedCalcMsrDetails.samplePointRow.ParentName !== ''">
          {{selectedCalcMsrDetails.samplePointRow.ParentName}} | </span>
        {{selectedCalcMsrDetails.samplePoint}}
      </label>
    </div>
    <div>
      <label class="pl-3 pb-1 mb-0">
        {{'Edata.CALCULATION_NAME' | translate}} - {{selectedCalcMsrDetails.calculationName}}
      </label>
    </div>
    <div>
      <label class="pl-3 pb-2 mb-0">
        {{'Edata.FORMULA' | translate}} - {{selectedCalcMsrDetails.formula}}
      </label>
    </div>
    <table>
      <tr>
        <th>{{'Edata.VARIABLE' | translate}}</th>
        <th>{{'Edata.DESCRIPTION' | translate}}</th>
        <th>{{'Edata.SAMPLE_POINT' | translate}}</th>
        <th>{{'Edata.MEASUREMENT' | translate}}</th>
        <th>{{'Edata.UoM' | translate}}</th>
      </tr>
      <tr *ngFor="let msr of selectedCalcMsrDetails.gridData">
        <td>{{msr.variable}}</td>
        <td>{{msr.desc}}</td>
        <td>{{msr.samplePoint}}</td>
        <td>{{msr.msrName}}</td>
        <td>{{msr.UoM}}</td>
      </tr>
    </table>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="closeMeasurementModal()" class="btn btn-primary">{{'COMMON.CLOSE'|
      translate}}</button>
  </div>
</div>
<div *ngIf="showLoader">
  <span class="loader"></span>
</div>

<!-- SiteSelection Confirmation popup -->
<div *ngIf="modalText == modals.SITE_CHANGE_CONFIRMATION || modalText == modals.SUBCONFIG_CHANGE_CONFIRMATION">
  <div class="modal-header pt-4 pl-4 mb-0 border-0">
    <h3 class="modal-title">
      <span><img src="../../../../assets/images/alert-yellow.svg"></span>
      <span>{{'Edata.ATTENTION' | translate}}</span>
    </h3>
    <button type="button" class="close" aria-label="Close" (click)="closeSiteModal()">
      <span class="ico-exit-round-edge"></span>
    </button>
  </div>
  <p class="confirm_msg mb-0">{{'Edata.SITE_CHANGE_CONFIRMATION' | translate}}</p>
  <div class="modal-footer mt-0">
    <button type="button" (click)="closeSiteModal()" class="btn btn-primary">{{'COMMON.CANCEL'| translate}}</button>
    <button type="button" (click)="allowSiteChange()" class="btn btn-primary">{{'COMMON.OK'| translate}}</button>
  </div>
</div>

<!-- Delete Confirmation popup -->
<div *ngIf="modalText == modals.DELETE_DATASET_CONFIRMATION">
  <div class="modal-header pt-4 pl-4 mb-0 border-0">
    <h3 class="modal-title">
      <span><img src="../../../../assets/images/alert-red.svg"></span>
      <span>{{'Edata.DELETE' | translate}}</span>
    </h3>
    <button type="button" class="close" aria-label="Close" (click)="closeMeasurementModal()">
      <span class="ico-exit-round-edge"></span>
    </button>
  </div>
  <p class="confirm_msg mb-0">{{'Edata.DELETE_CONFIRMATION' | translate}}</p>
  <p class="confirm_msg mb-0">{{'Edata.DELETE_MSG' | translate}}</p>
  <div class="modal-footer mt-0">
    <button type="button" (click)="deleteDataSet()" class="btn btn-primary">{{'Edata.DELETE'| translate}}</button>
    <button type="button" (click)="closeMeasurementModal()" class="btn btn-primary">{{'COMMON.CANCEL'|
      translate}}</button>
  </div>
</div>