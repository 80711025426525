import { Component, OnInit } from '@angular/core';
import { SubheaderHandlerService } from '../../../core/nav/subheader-handler.service';

@Component({
  selector: 'nalco-fnb',
  templateUrl: './fnb.component.html',
  styleUrls: ['./fnb.component.scss']
})
export class FnbComponent implements OnInit {

  breadcrumbData = {
    type: 'title',
    data: 'F n B',
    className: 'col-lg-6'
  }

  constructor(private subHeaderService: SubheaderHandlerService) {}

  ngOnInit() {
    this.subHeaderService.updateLeftSection(this.breadcrumbData);
  }

}
