<div class="config-popup">
  <div class="modal-header">
    <h4 class="modal-title" translate>CONTROLLER_MANAGEMENT.HARDWARE_DETAILS.HARDWARE_DETAILS</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
      <img src="../../../../assets/images/modal-close-icon.svg" alt="close" />
    </button>
  </div>

  <div class="mb-0 delete-content">
    <nalco-loader [loader]="isLoading" [transparentBG]="true" [fitToScreen]="true"></nalco-loader>
    <div class="account-name modal-header">
      <span> {{'CONTROLLER_MANAGEMENT.SERIAL_NO' | translate}} : {{SerialNo}} </span> <br/>
      <span> {{'CONTROLLER_MANAGEMENT.LAST_FILE_RECEIVED' | translate}} : {{LastDataReceived | date : "yyyy-MM-dd h:mm:ss"}}</span>
    </div>
   <div class="table-wrapper" #accountResults infiniteScroll [infiniteScrollContainer]="accountResults"
      [infiniteScrollDistance]="1"  [infiniteScrollThrottle]="500"   [scrollWindow]="false">
      <div class="table-responsive">
        <table class="table savings-table">
          <tbody *ngIf="!isLoading">
            <tr>
              <th>
                <span>{{'CONTROLLER_MANAGEMENT.HARDWARE_DETAILS.NAME' | translate}}</span>             
              </th>
              <th>
                <span>{{'CONTROLLER_MANAGEMENT.HARDWARE_DETAILS.VALUE' | translate}}</span>        
              </th>
            </tr>
            <tr>
              <td>
                <span>{{'CONTROLLER_MANAGEMENT.HARDWARE_DETAILS.SERIAL_NO' | translate}}</span>             
              </td>
              <td>
                <span>{{this.configurationList[0]? this.configurationList[0].SerialNumber : '-'}}</span>        
              </td>
            </tr>
            <tr>
              <td>
                <span>{{'CONTROLLER_MANAGEMENT.HARDWARE_DETAILS.APPLICATION_TYPE' | translate}}</span>             
              </td>
              <td>
                <span>{{this.configurationList[0]? this.configurationList[0].ApplicationType : '-'}}</span>        
              </td>
            </tr>
            <tr>
              <td>
                <span>{{'CONTROLLER_MANAGEMENT.HARDWARE_DETAILS.CUSTOMER' | translate}}</span>             
              </td>
              <td>
                <span>{{this.configurationList[0]? this.configurationList[0].Customer : '-'}}</span>        
              </td>
            </tr>
            <tr>
              <td>
                <span>{{'CONTROLLER_MANAGEMENT.HARDWARE_DETAILS.LOCATION' | translate}}</span>             
              </td>
              <td>
                <span>{{this.configurationList[0]? this.configurationList[0].Location : '-'}}</span>        
              </td>
            </tr>
            <tr>
              <td>
                <span>{{'CONTROLLER_MANAGEMENT.HARDWARE_DETAILS.SYSTEM_NAME' | translate}}</span>             
              </td>
              <td>
                <span>{{this.configurationList[0]? this.configurationList[0].SystemName : '-'}}</span>        
              </td>
            </tr>
            <tr>
              <td>
                <span>{{'CONTROLLER_MANAGEMENT.HARDWARE_DETAILS.SOLD_TO' | translate}}</span>             
              </td>
              <td>
                <span>{{this.configurationList[0]?this.configurationList[0].SoldTo : '-'}}</span>        
              </td>
            </tr>
            <tr>
              <td>
                <span>{{'CONTROLLER_MANAGEMENT.HARDWARE_DETAILS.SHIP_TO' | translate}}</span>             
              </td>
              <td>
                <span>{{this.configurationList[0]? this.configurationList[0].ShipTo : '-'}}</span>        
              </td>
            </tr>

            <tr>
              <td>
                <span>{{'CONTROLLER_MANAGEMENT.HARDWARE_DETAILS.SOFTWARE_VERSION' | translate}}</span>             
              </td>
              <td>
                <span>{{this.configurationList[0]? this.configurationList[0].SoftwareVersion : '-'}}</span>        
              </td>
            </tr>
            <tr>
              <td>
                <span>{{'CONTROLLER_MANAGEMENT.HARDWARE_DETAILS.UPGRADE_STATUS' | translate}}</span>             
              </td>
              <td>
                <span>{{this.configurationList[0]? this.configurationList[0].UpgradeStatus : '-'}}</span>        
              </td>
            </tr>
            <tr>
              <td>
                <span>{{'CONTROLLER_MANAGEMENT.HARDWARE_DETAILS.UPGRADE_STATE' | translate}}</span>             
              </td>
              <td>
                <span>{{this.configurationList[0]? this.configurationList[0].UpgradeState : '-'}}</span>        
              </td>
            </tr>
            <tr>
              <td>
                <span>{{'CONTROLLER_MANAGEMENT.HARDWARE_DETAILS.UPGRADE_FAILURE_REASON' | translate}}</span>             
              </td>
              <td>
                <span>{{this.configurationList[0]? this.configurationList[0].UpgradeFailureReason : '-'}}</span>        
              </td>
            </tr>

          </tbody>
        </table>
    </div>
    </div> 
  </div>
</div>
