import { IChartData } from '../../shared/contracts/IChartData';
import { XAxis } from './XAxis';
import { ChartDataSeries } from './ChartDataSeries';
import { YAxis } from './YAxis';

export class ChartData implements IChartData {
    xAxis: XAxis;
    series: Array<ChartDataSeries>;
    yAxis?: YAxis;
}
