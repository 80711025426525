import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { AssetMeasurements } from '../../service-summary/_models/AssetMeasurements';

@Injectable()
export class SSDSharedService { 
  private printViewFlag = true;

  constructor() {}

  // Observable  source
  private _filterSource = new BehaviorSubject(this.printViewFlag);
  private _updateAssetDetailsWidget = new Subject();
  private _globalAssetDetailsWidgetMeasurements = [];
  // Observable stream
  public printView = this._filterSource.asObservable();
  public removeDVWidget = new BehaviorSubject(null);
  public activeTabSSD = new BehaviorSubject('trendOverview');
  public favList = new BehaviorSubject(false);
  public pdfGenerationStatus = new BehaviorSubject(null);

  public getPrintViewStaus() {
    return this._filterSource;
  }

  public getAssetDetailsWidgetStatus() {
    return this._updateAssetDetailsWidget;
  }

  public setPrintViewStatus(view) {
    this._filterSource.next(view);
  }

  public getRemoveDVWidget(event){
    this.removeDVWidget.next(event);
  }

  public getActiveTabDV(event){
    this.activeTabSSD.next(event);
  }

  public openFavList(event){
    this.favList.next(event);
  }

  public publishAssetDetailsWidgetUpdateEvent(config: AssetMeasurements[]) {
    this._updateAssetDetailsWidget.next(config);
  }

  public publishGlobalAssetDetailsWidgetUpdateEvent(config) {
    this._globalAssetDetailsWidgetMeasurements = config;
  }

  public getGlobalAssetDetailsWidgetConfig(): AssetMeasurements[] {
    return this._globalAssetDetailsWidgetMeasurements;
  }

  public clearGlobalAssetDetailsWidgetConfig() {
    this._globalAssetDetailsWidgetMeasurements = [];
  }

  public notifyPDFGenerationStatus(isPDFGenerating) {
    this.pdfGenerationStatus.next(isPDFGenerating);
  }
}
