
export const ActionStatusType = [
  {
    display: 'ACTION_LOG.ALL',
    id: -1,
    value: 'All',
    checked: false
  },
  {
    display: 'ACTION_LOG.DEFERRED',
    id: 1,
    value: 'Deferred',
    checked: false
  },
  {
    display: 'ACTION_LOG.OPEN',
    id: 2,
    value: 'Open',
    checked: false
  },
  {
    display: 'ACTION_LOG.CLOSED',
    id: 4,
    value: 'Closed',
    checked: false
  }];

export const ActionImpactType = [
  {
    display: 'ACTION_LOG.ALL',
    id: -1,
    value: 'all',
    checked: false
  },
  {
    display: 'ACTION_LOG.LOW',
    id: 0,
    value: 'low',
    checked: false
  },
  {
    display: 'ACTION_LOG.MEDIUM',
    id: 1,
    value: 'medium',
    checked: false
  },
  {
    display: 'ACTION_LOG.HIGH',
    id: 2,
    value: 'high',
    checked: false
  },
  {
    display: 'ACTION_LOG.SAFETY',
    id: 3,
    value: 'safety',
    checked: false
  }];

export const AssetStatusType = [
  {
    display: 'All ',
    id: -1,
    value: 'all',
    checked: false
  },
  {
    display: 'Red',
    id: 0,
    value: 'red',
    checked: false,
    isImage: 'red'
  },
  {
    display: 'Amber',
    id: 1,
    value: 'yellow',
    checked: false,
    isImage: 'yellow'
  },
  {
    display: 'Green',
    id: 2,
    value: 'green',
    checked: false,
    isImage: 'green'
  },
  {
    display: 'Grey',
    id: 3,
    value: 'grey',
    checked: false,
    isImage: 'grey'
  }];

export const enum ContextPointType {
  None = 0,
  Root = 1000,
  EcolabViewOfCustomer = 2000,
  EcolabGeography = 3000,
  CustomerViewOfCustomer = 4000,
  Site = 5000,
  Account = 6000,
  Group = 7000,
  System = 8000,
  Asset = 9000,
  SubAsset = 10000,
  Port = 11000,
  AutomaticDatasource = 12000,
  ManualDatasource = 13000,
}


export enum SourceApplicationTypeId {
  None = -1,
  ECOLAB3D = 0,
  NalcoCRM = 1,
  AlarmManagerApp = 2,
  TcdCRM = 3,
  AI = 4,
}

export enum TVDSourceApplication {
  CMV = 1,
  Recommendation = 2,
  AnalyticalSampling = 3,
  EGIC = 4,
  ITC = 5,
  OOS = 6
}

export const ActionLogTableColumns = [

  {
    'title': 'ASSET_TREE.SITE_NAME',
    'defaultActive': true,
    'columnOrder': 1,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'SiteName',
    'width': '200'
  },
  {
    'title': 'ASSET_TREE.ASSET',
    'defaultActive': true,
    'columnOrder': 2,
    'displayFormat': 'Col',
    'isLocked': true,
    'field': 'Asset',
    'width': '200'
  },
  {
    'title': 'QUALITY.COLUMN_TITLE.SOURCE',
    'defaultActive': false,
    'columnOrder': 3,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'SourceApplicationType',
    'width': '200'
  },
  {
    'title': 'ACTION_LOG.HEADER.ACTION_STATUS',
    'defaultActive': true,
    'columnOrder': 4,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'ActionStatusTypeName',
    'width': '100'
  },
  {
    'title': 'ACTION_LOG.HEADER.IMPACT',
    'defaultActive': true,
    'columnOrder': 5,
    'displayFormat': 'Col',
    'isLocked': true,
    'field': 'Impact',
    'width': '100'
  },
  {
    'title': 'ACTION_LOG.HEADER.RECOMMENDATION_DATE',
    'defaultActive': true,
    'columnOrder': 6,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'RecommendationDate',
    'width': '100'
  },
  {
    'title': 'ACTION_LOG.HEADER.EXPECTED_RESOLUTION_DATE',
    'defaultActive': true,
    'columnOrder': 7,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'ExpectedResolutionDate',
    'width': '100'
  },
  {
    'title': 'ACTION_LOG.HEADER.RESPONSIBLE_PERSON',
    'defaultActive': true,
    'columnOrder': 8,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'ResponsiblePerson',
    'width': '100'
  },
  {
    'title': 'ACTION_LOG.HEADER.ACTION_CREATED_BY',
    'defaultActive': true,
    'columnOrder': 9,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'ActionCreated',
    'width': '100'
  },
  {
    'title': 'ACTION_LOG.HEADER.PROBLEM',
    'defaultActive': true,
    'columnOrder': 10,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'Problem',
    'width': '100'
  },
  {
    'title': 'ACTION_LOG.HEADER.RECOMMENDED_ACTION',
    'defaultActive': true,
    'columnOrder': 11,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'Recommendation',
    'width': '100'
  },
  {
    'title': 'ACTION_LOG.HEADER.MOST_RECENT_ACTION',
    'defaultActive': true,
    'columnOrder': 12,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'ActionsTaken'
  },
  {
    'title': 'ACTION_LOG.HEADER.MOST_RECENT_ACTION_DATE',
    'defaultActive': true,
    'columnOrder': 13,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'DateOfAction'
  },
  {
    'title': 'ACTION_LOG.HEADER.EROI_CATEGORY',
    'defaultActive': true,
    'columnOrder': 14,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'EroiValueType'
  },
  {
    'title': 'ACTION_LOG.HEADER.ANNUAL_SAVINGS_TYPE',
    'defaultActive': true,
    'columnOrder': 15,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'SavingType'
  },

  {
    'title': 'ACTION_LOG.HEADER.TOTAL_AMOUNT',
    'defaultActive': true,
    'columnOrder': 16,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'Amount'
  },
  {
    'title': 'ACTION_LOG.HEADER.TOTAL_SAVINGS',
    'defaultActive': true,
    'columnOrder': 17,
    'displayFormat': 'Col',
    'isLocked': false,
    'field': 'AnnualSavings'
  },


]

export const unalignedAccountName = 'UNALIGNED';