/* eslint-disable @typescript-eslint/consistent-type-imports */
import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuItems, SelectedMenus } from '../contracts/menu';
import { MenuService } from '../menu.service';
import { DividerOption } from './../../shared-ui-refresh/divider/divider.model'
import { AppEnvConfig } from '../../app.env.config';
import { CommanClass  } from '../_enum/constants';

@Component({
  selector: 'nalco-menu-item',
  templateUrl: './menu-item.component.html',
  encapsulation: ViewEncapsulation.None
})
export class MenuItemComponent implements OnInit {

  showMenu = true;
  @Input() id: number;
  @Input() text: string;
  @Input() heading: string;
  @Input() link: string;
  @Input() href: string;
  @Input() icon: string;
  @Input() tooltip: string;
  @Input() selected: boolean;
  @Input() subMenu: MenuItems[];
  @Input() arrow: string;
  isExpand: boolean;
  dividerCss: DividerOption;
  showDropDown = false;
  // additional property to set any additional feature for menu
  // for RK it is for feedback floating down
  @Input() itemContainerClass: string;
  @Output() menuItemClick: EventEmitter<MenuItems> = new EventEmitter();
  siteId: string;
  toggle = false;
  isMobile = false;
  showSubMenu = false;
  isE3DChina = (this.config.getEnv("IsE3DChina") === "true") ? true : false;
  constructor(private translate: TranslateService,
    private menuService: MenuService,
    private config: AppEnvConfig) { }

  ngOnInit() {
    this.isMobile = (/android|webos|iphone|ipad|ipod|blackberry|windows phone/).test(navigator.userAgent.toLowerCase());
    this.showSubMenu = this.subMenu.length > 0 ? true : false;
    this.menuService.navExpand.subscribe((value) => {
      this.isExpand = value,
        this.unSelectMenu();
        this.showArrowByViewMode();
    }
    )
    this.dividerCss = {
      color: "#bac7d8",
      maxLength: "auto",
      isHorizontal: true
    }
  }

  @HostListener('document:click', ['$event']) clickedOutside(event) {
    if (event.target.className.indexOf !== undefined
      && event.target.className.indexOf('menu-text') < 0) {
      this.showMenu = false;
    }
    if (!this.isMobile && (event.target.className.indexOf !== undefined &&
      event.target.className.includes(CommanClass.Submenu_Class) || 
      !event.target.className.includes(CommanClass.ShowMenu_Class) )) {
        this.showSubMenu = false;  
        this.selected = false;    
    }
      if(event.target.className.includes(CommanClass.SidebarOpen_Class) && 
      this.isMobile && this.isExpand ){
        this.arrow = 'arrow-down';
      }
  }

  /**
   * @description Check for the navigation open /close and emit the link of the menu clicked
   */

  public itemClicked(): void {
    this.showSubMenu = this.subMenu.length>0 ? true:false;
    if (this.subMenu !== undefined && this.subMenu.length === 0) {
      this.menuItemClick.emit({ link: this.link, text: this.text });
    } else {
      this.showDropDownMenu();
      this.showArrowByViewMode();
      this.menuItemClick.emit({ text: this.text });

    }
  }


  /**
   * @description Check for the submenu selection and emit the item data clicked
   * @param event 
   */
  public subMenuItemClick(event: SelectedMenus): void {
    if (window.location.href.search('/sites/') !== -1) {
      const splitUrl = window.location.href.split('/');
      if (splitUrl.length > 5) {
        this.siteId = splitUrl[5];
      }
    } else {
      this.siteId = null;
    }

    this.menuItemClick.emit({ link: event.link, siteId: this.siteId });
  }

  /**
  * @description this function is used to unselect the menus selected via expand and collapse conditions
  */

  unSelectMenu() : void {
    if (this.subMenu.length > 0 && this.selected) {
      this.selected = false;
    }
  }



  /**
   * @description used to assign the arrow for the left menu as per user click.
   */

  showArrowByViewMode() {
    if (this.isMobile) {
      this.arrow = this.selected ? 'arrow-caret-up' : 'arrow-down';
    }
    else {
      this.arrow = 'arrow-caret-right';
    }
  }

  /**
 * @description Hide and show the dropdown menus according to the user selection
 */

  showDropDownMenu() {
    if (this.subMenu.length > 0 && this.selected) {
      this.showDropDown = false;
      this.selected = false;
    } else {
      this.showDropDown = true;
      this.selected = true;
    }

  }

   /**
 * @description Hide and show the dropdown menus on mouse hover for expand view
 */
  mouseover()
  {
    if(this.isExpand){
    this.showSubMenu = this.subMenu.length>0 ? true:false;
    }
  }
}
