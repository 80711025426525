import { IWidget, IAddRte } from "../_contracts/IWidget";

export class Widget implements IWidget {
    WidgetId: number;
    WidgetName: string;
    WidgetOrder: number;
    ServiceTypeId: number;
    WidgetDisplayName: string;
    WidgetConfiguration: {
        GeneralConfig: {
            Size: number,
            ShowInfo: boolean
        },
        SpecificConfig?: any;
    }
    RemovedWidget?: number;

    constructor(widget?: Widget) {
        if (widget) {
            this.WidgetId = widget.WidgetId;
            this.WidgetName = widget.WidgetName;
            this.WidgetOrder = widget.WidgetOrder;
            this.ServiceTypeId = widget.ServiceTypeId;
            this.WidgetDisplayName = widget.WidgetDisplayName;
            this.WidgetConfiguration = widget.WidgetConfiguration;
            this.RemovedWidget = widget.RemovedWidget;
        }
    }
}

export class AddRte implements IAddRte {
    Id: string = '';
    Headline: string= '';
    Comments: string = '';

    constructor(addRte?: AddRte) {
        if (addRte) {
            this.Id = addRte.Id;
            this.Headline = addRte.Headline;
            this.Comments = addRte.Comments;
        }
    }
}