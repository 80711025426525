<div class="asset-container mr-3 assetCount" [ngClass]="assetHarvesterTab !== 'manageMeasurements' ? 'asset-count-color' : ''" >
  <label translate>ASSET_TREE.TOTAL_ASSETS</label>: 
  <label translate>ASSET_HARVESTER.HEADER.ASSETS_PLANNED <span class="required">* </span></label> <span>{{ totalAssets }}</span>
  <span *ngIf="assetHarvesterService.writeAlso" class="drag-edit editIcon ml-3"
     (click)="showAssetCountModal(assetCount)" [ngbTooltip]="editNotificationContent" placement="top" container="body">
           <ng-template #editNotificationContent><span translate>ASSET_HARVESTER.HEADER.EDIT_TOTAL_ASSETS_PLANNED</span></ng-template>
  </span>
   <label class="mr-2" translate>ASSET_HARVESTER.HEADER.ASSETS_CREATED</label><span>{{ createdAssets }}</span>
</div>

<!--Assets Count Modal pop up -->
<ng-template #assetCount let-modal>
<div class="modal-header">
  <h4 class="modal-title"><label class="mr-1">{{'ASSET_HARVESTER.HEADER.ASSETS_PLANNED' | translate}}</label> <label> {{ 'ASSET_TREE.ASSETS' | translate}}</label></h4>
  <button *ngIf="totalAssets && totalAssets>0" type="button" class="close" aria-label="Close" (click)="closeTotalAssetsModal();">
  <img src="../../../../assets/images/modal-close-icon.svg" alt="close" />
</button>
</div>
<div class="modal-body asset-modal-body">
  <div class="clearfix upper-section">
      <div>
          <label class="astrik m-3">{{'ASSET_HARVESTER.HEADER.TOTAL_ASSETS_PLANNED' | translate }}</label>
          <input type="number" class="m-3 text-box" placeholder="" [ngModel]="totalAssets" 
          name="plannedAssets" #plannedAssets required/>
      </div>
  </div>
</div>
<div class="modal-footer popup-footer-section">
  <button *ngIf="totalAssets && totalAssets>0" type="button"  (click)="closeTotalAssetsModal()" class="btn btn-secondary" translate>COMMON.CANCEL</button>
  <button [disabled]="!plannedAssets.value || plannedAssets.value <= 0 || (totalAssets == plannedAssets.value) || disableSave" type="button" class="btn btn-primary" (click)="saveTotalAssetsPlanned(plannedAssets.value)"
   translate>COMMON.SAVE</button>
</div>
</ng-template>
<!--ENd of Asset count pop up-->