import { Injectable } from '@angular/core';
import { ENVIRONMENTS, APP_CONFIG } from '../../app.config';

@Injectable()
export class SharedFIRefreshConfig {

    private configMock: any = { // MOCK - local json settings
        environment: ENVIRONMENTS.mock,
        baseUrl: '',
        apiUrl: {
            getProjects: 'financialimpact/api/shared/dashboard/Projects',
            getActionLogRecommendation: 'api/v1/Recommendations/RecommendationSets',
            getSavingCategoryTypes: 'api/v1/FinancialImpact/SavingCategoryTypes',
            getEroiTypes: `api/v1/FinancialImpact/ERoiTypes`,
            getSoldTosBySiteContextPointId: `api/v1/Accounts/SoldTosBySiteContextPointId`,
            getProjectDetailOverview: 'financialimpact/api/projects/',
            getCurrencies: 'Currencies',
            getDivisions: 'api/v1/FinancialImpact/DivisionWithBusinessUnitCode',
            getAggregatedValueDelivered: 'financialimpact/api/shared/dashboard/AggregatedValueDelivered',
            getAdminSettings: 'api/v1/AdminConfiguration',
            getAllValueChannels: 'api/v1/FinancialImpact/ValueChannels',
            getValues: 'api/v1/Value/GetValues',
            getDivisionalAccounts: 'api/v1/FinancialImpact/DivisionWithBusinessUnitCode',
            getProjectsByPermission: 'api/v1/FinancialProject/GetProjectsByPermission',
            getProjectById: 'api/v1/FinancialProject/projects',
            getAutomatedTVDValueList: 'api/v1/AutomatedTVD/GetAutomatedTVDValueList'
        }
    };
    private configProd: any = { // PRODUCTION - PRODUCTION ENVIRONMENT SETTINGS
        environment: ENVIRONMENTS.prod,
        baseUrl: '',
        apiUrl: {
            getProjects: 'financialimpact/api/shared/dashboard/Projects',
            getActionLogRecommendation: 'api/v1/Recommendations/RecommendationSets',
            getSavingCategoryTypes: 'api/v1/FinancialImpact/SavingCategoryTypes',
            getEroiTypes: `api/v1/FinancialImpact/ERoiTypes`,
            getSoldTosBySiteContextPointId: `api/v1/Accounts/SoldTosBySiteContextPointId`,
            getProjectDetailOverview: 'financialimpact/api/projects/',
            getCurrencies: 'Currencies',
            getDivisions: 'api/v1/FinancialImpact/DivisionWithBusinessUnitCode',
            getAggregatedValueDelivered: 'financialimpact/api/shared/dashboard/AggregatedValueDelivered',
            getAllValueChannels: 'api/v1/FinancialImpact/ValueChannels',
            getAdminSettings: 'api/v1/AdminConfiguration',
            getValues: 'api/v1/Value/GetValues',
            getDivisionalAccounts: 'api/v1/FinancialImpact/DivisionWithBusinessUnitCode',
            getProjectsByPermission: 'api/v1/FinancialProject/GetProjectsByPermission',
            getProjectById: 'api/v1/FinancialProject/projects',
            getAutomatedTVDValueList: 'api/v1/AutomatedTVD/GetAutomatedTVDValueList'
        }
    };

    private _environment: string = APP_CONFIG.ENVIRONMENT;

    constructor() {
        // void
    }

    public getEnvironment(): any {
        switch (this._environment) {
            case ENVIRONMENTS.mock:
                return this.configMock;
            case ENVIRONMENTS.dev:
                return this.configProd;
            case ENVIRONMENTS.test:
                return this.configProd;
            case ENVIRONMENTS.stg:
                return this.configProd;
            case ENVIRONMENTS.prod:
                return this.configProd;
            default:
                return null;
        }
    }

    public setEnvironment(environment: string): any {
        this._environment = environment;
    }

}
