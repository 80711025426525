import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable()
export class EvocCvocFilterService {
  private defaultSetting = {
    contextPointId: '',
    filterObject: {
      parentId: '',
      parentName: '',
      children: []
    },
    filterObjectFlag: false
  }
  private defaultCorporateAccount = 0;
  constructor() {}

  // Observable  source
  private _filterSource = new BehaviorSubject(this.defaultSetting);
  // Observable stream
  public fiilterData$ = this._filterSource.asObservable();

  public getFilterData() {
    return this._filterSource;
  }

  public setFilterData(settings) {
    this._filterSource.next(settings);
  }
}
