import { environment } from './../../../../../environments/environment';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { AppEnvConfig } from '../../../../app.env.config';
import { Router } from '@angular/router';
import { USERROLES } from '../../../../app.config';;

@Component({
  selector: 'nalco-left-menu-submenu-item',
  templateUrl: './left-menu-submenu-item.component.html'
})
export class LeftMenuSubmenuItemComponent implements OnInit {
  @Input() details: any;
  @Output() submenuItemClick: EventEmitter<any> = new EventEmitter();
  public forumID: any;
  public isB2CEnable: string;
  public environment: string;
  isEDataEntryNavigation: any;
  userType: any;
  permissionPresent: boolean = false;

  constructor(
    private config: AppEnvConfig,
    private router: Router
  ) {
    this.isB2CEnable = config.getEnv('IsB2CEnable').toString();
    this.environment = config.getEnv('environment').toString();
    this.isEDataEntryNavigation = config.getEnv('isEDataEntryNavigation').toString();
  }

  ngOnInit() {
    this.forumID = environment.userVoiceConfig.forumID;
    this.checkAlarmPermission();
  }

  menuItemClick($event) {

    // Check if the link is for training and uservoice
    if (this.details.link === 'Training-Customers-External') {
      if (this.environment === 'STG' && this.isB2CEnable === 'true') {
        window.open('https://learning.cds.ecolab.com/staging/auth/azureb2c/');
      } else if (this.environment === 'prod' && this.isB2CEnable === 'true') {
        window.open('https://learning.cds.ecolab.com/lms/auth/azureb2c/');
      } else {
        window.open('http://40.70.21.254/lms/');
      }
    } else if (this.details.link === 'Training-Ecolab-Associate') {
      window.open('https://app.powerbi.com/groups/me/apps/65edf861-e5b1-442a-b4bb-9242d4673875/reports/29ef5620-e3d8-4359-ab46-e4d68d58a35d/ReportSectionc390ba6500b873caa59c?ctid=c1eb5112-7946-4c9d-bc57-40040cfe3a91&experience=power-bi');
    } else if (this.details.link === 'Water-Quality-Uservoice') {                                      // check if the link is for Uservoice
      window.open('https://nalcowater.uservoice.com/forums/' + this.forumID.WQ + '-water-quality');
    } else if (this.details.link === 'Water-Safety-Uservoice') {
      window.open('https://nalcowater.uservoice.com/forums/' + this.forumID.WSI + '-water-safety-intelligence');
    } else if (this.details.link === 'E-Data-Uservoice') {
      window.open('https://nalcowater.uservoice.com/forums/' + this.forumID.EDATA + '-e-data');
    } else if (this.details.link === 'financial-impact-Uservoice') {
      window.open('https://nalcowater.uservoice.com/forums/' + this.forumID.FI + '-financial-impact');
    } else if (this.details.link === 'Inventory-Management-Uservoice') {
      window.open('https://nalcowater.uservoice.com/forums/' + this.forumID.IM + '-inventory-management');
    } else if (this.details.link === 'data-Visualization-Uservoice') {
      window.open('https://nalcowater.uservoice.com/forums/' + this.forumID.DV + '-data-visualization');
    } else if (this.details.link === 'water-Flow-intelligence-Uservoice') {
      window.open('https://nalcowater.uservoice.com/forums/' + this.forumID.WFI + '-water-flow-intelligence');
    } else if (this.details.link === 'service-summary-Uservoice') {
      window.open('https://nalcowater.uservoice.com/forums/' + this.forumID.SSD + '-service-summary-dashboard');
    } else if (this.details.link === 'Action-Recommendation-Uservoice'){
      window.open('https://nalcowater.uservoice.com/forums/' + this.forumID.AR+ '-ecolab3d-actions-recommendations');
    } else if(this.details.link === 'Asset-Harvester-Uservoice'){
      window.open('https://nalcowater.uservoice.com/forums/' + this.forumID.AH+ '-asset-harvester');
    } else if(this.details.link === 'Omni-Uservoice'){
      window.open('https://nalcowater.uservoice.com/forums/' + this.forumID.OMNI+ '-omni');
    } else if (this.details.link === 'my-documents-Uservoice') {
      window.open('https://nalcowater.uservoice.com/forums/' + this.forumID.MYDOC+ '-my-documents');
    } else if (this.details && this.details.isExternal && this.details.breadcrumb === 'CIP') {
      const isE3DChina = (this.config.getEnv("IsE3DChina") === "true") ? true : false;
      if(isE3DChina){
        window.open(environment.CIP_LINK_China);
      }else{
        window.open(environment.CIP_LINK);
      }
    } else if (this.details && this.details.isExternal && this.details.breadcrumb === 'eCommerce') {
      window.open(environment.E_Commerce);
    } else if (this.details && this.details.breadcrumb === 'Email') {
      $event.preventDefault();
      window.open('mailto:DGSN@ecolab.com?subject=New', '_blank');
    } else if(this.details && this.details.breadcrumb === 'Onboarding Support: Digital Contacts'){
      window.open('https://ecolab.sharepoint.com.mcas.ms/sites/CORE-Digital/SitePages/Digital%20Contacts.aspx', '_blank');
    }else if (this.details && this.details.isExternal && this.details.breadcrumb === 'E-data-workaround') {
      // Adding this condition to navigate to new E-Data Entry page only on QA.
      if(this.isEDataEntryNavigation == "true"){
        this.router.navigate(['/e-data-entry']);
      } else {
        window.open(environment[this.details.link]);
      }
    } else if (this.details && this.details.isExternal && this.details.breadcrumb === 'Wash Process Intelligence') {

      window.open(environment.Water_Process_Intelligence);

    } else if(this.details.link ===  'SITE Creation (Emily Synal) (formsite.com)'){
      window.open('https://fs27.formsite.com/NalcoWaterNA/koyf5xivvl/index');
    }else if(this.details.breadcrumb === 'Report Issue'){
      $event.preventDefault();
      window.open(this.details.href,this.details.target);
    }
    else if (this.details.isExternal) {
      window.open(environment[this.details.link]);
    }
    else {
      this.submenuItemClick.emit({ link: this.details.link });
    }
  }

  checkAlarmPermission(){
    this.userType = JSON.parse(sessionStorage.getItem('ecolab.envision_msalUserInfo')).UserType;
    let permissions = JSON.parse(sessionStorage.getItem('ecolab.envision_UserSettings')).Permissions;
    this.permissionPresent = false;
    permissions.forEach(permission => {
      if (permission.PermissionId.toString() === USERROLES.NOTIFICATIONS_ADMIN_OWN || permission.PermissionId.toString() === USERROLES.NOTIFICATIONS_ADMIN_INTERNAL || permission.PermissionId.toString() === USERROLES.NOTIFICATIONS_CUSTOMER_FULL
      || permission.PermissionId.toString() === USERROLES.NOTIFICATIONS_ADMIN) {
        this.permissionPresent = true;
      }
    });
  }
}
