<div class="component-container" [style.minHeight]="'100%'">

  <ng-content *ngIf="parent.IsReady" [@stateChangeAnimation]></ng-content>
  <!-- LOADING -->
  <ng-container *ngTemplateOutlet="parent.IsLoading ? loadingTemplate ? loadingTemplate : defaultLoadingTemplate : null"></ng-container>
  <!-- default loading template -->
  <ng-template #defaultLoadingTemplate>
    <div class="state-wrapper loading" [@stateChangeAnimation]>
      <span class="state-icon">
        <i [class]="loadingIconClass"></i>
      </span>
      <span class="state-description">
        <span [class]="loadingTitleClass">{{loadingTitleText}}</span>
        <span [class]="loadingMessageClass">{{loadingMessageText}}</span>
      </span>
    </div>
  </ng-template>
  <!-- ERROR -->
  <ng-container *ngTemplateOutlet="parent.IsError ? errorTemplate ? errorTemplate : defaultErrorTemplate : null"></ng-container>
  <!-- default loading template -->
  <ng-template #defaultErrorTemplate>
    <div class="state-wrapper error" [@stateChangeAnimation]>
      <span class="state-icon">
        <i [class]="errorIconClass"></i>
      </span>
      <span class="state-description">
        <span [class]="errorTitleClass">{{errorTitleText}}</span>
        <span [class]="errorMessageClass">{{errorMessageText}}</span>
      </span>
    </div>
  </ng-template>
  <!-- EMPTY -->
  <ng-container *ngTemplateOutlet="parent.IsEmpty ? emptyTemplate ? emptyTemplate : defaultEmptyTemplate : null"></ng-container>
  <!-- default loading template -->
  <ng-template #defaultEmptyTemplate>
    <div class="state-wrapper" [@stateChangeAnimation]>
      <span class="state-icon">
        <i [class]="emptyIconClass"></i>
      </span>
      <span class="state-description">
        <span [class]="emptyTitleClass">{{emptyTitleText}}</span>
        <span [class]="emptyMessageClass">{{emptyMessageText}}</span>
      </span>
    </div>

  </ng-template>
</div>
