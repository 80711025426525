<div ngbDropdown [container]="'body'" class="dropdown" [ngClass]="{'disabled': (isDisabled && isDisabled===true), dropdownClassName: dropdownClassName !== ''}">
  <div class="btn-outline-primary drop-btn" id="dropdownBasic1" ngbDropdownToggle [ngClass]="{'disabled': (isDisabled && isDisabled===true)}">
    <span [ngClass]="iconClass!==''? iconClass:'' " class="{{(isDisabled && isDisabled===true) ?'disabled' :''}}" ></span>
    <span *ngIf="(!showSelect && !hideLabelIfSelected) || (hideLabelIfSelected && selectedOptionText()==='')" class="label">{{ddData.label}}</span>
    <span *ngIf="selectedOptionText()==='' && showSelect" class="label">{{ddData.label}}</span>
    <span class="selected-text" *ngIf="ddData.options.length!==0 && selectedOptionText().trim()!==''" [ngClass]="{'disabled': (isDisabled && isDisabled===true), 'noLabel': hideLabelIfSelected}"
      [innerHTML]="selectedOptionText()" placement="bottom" #element1 [ngbTooltip]="element1.offsetWidth+1 < element1.scrollWidth ? selectedOptionText(): ''"
      container="body"></span>
    <span class="c-three-dots-loader" *ngIf="isDisabled===false && ddData.options.length===0 && selectedOptionText().trim()===''"></span>
  </div>

  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
    <span *ngFor="let option of ddData.options">

      <button class="dropdown-header" [hidden]="!option.isHeader">{{option.text}}</button>
      <button class="dropdown-item overflow-ellipsis"  placement="bottom" #element [ngbTooltip]="element.offsetWidth+1 < element.scrollWidth ? option.text: ''" *ngIf="!option.isHeader" [ngClass]="{selected: option.isSelected,'disabled': option.isDisabled}"
        value="{{option.value}}" (click)="changeInDropdown(ddData.name, option.value, option.text)" [innerHTML]="option.text"
        [disabled]="option.isDisabled"></button>
    </span>
  </div>
</div>
