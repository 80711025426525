<div ngbDropdown class="d-inline-block dropdown notification" #myDrop="ngbDropdown">
  <div class="dropdown-content">
    <span #emptyNotification ngbDropdownToggle class="btn drop-btn" id="dropdownBasic1" *ngIf="!allNotification.length">
      <img class="mt-0 width24 notification-icon icon-cursor-style" alt="Notification Icon" src="../../../assets/images/notification_icon.png">
    </span>
    <span #nonEmptyNotification ngbDropdownToggle class="btn drop-btn" id="dropdownBasic1" *ngIf="allNotification.length">
      <img class="mt-0 width26 notification-icon icon-cursor-style" alt="Active Notification Icon" src="../../../assets/images/notification_icon_active.png">
    </span>

    <div class="arrow-img">
      <img alt="Polygon Icon" src="../../../assets/images/polygon.png">
    </div>

    <div class="notification-list" [ngClass]="allNotification.length ? 'notification-active' : 'no-notification'"
      ngbDropdownMenu aria-labelledby="dropdownBasic1">
      <div class="content-wrapper">
        <ng-container *ngIf="!allNotification.length">
          <div class="no-notification-container">
            <div class="no-notification-img-container">
              <img class="no-notification-img" alt="No Notification Icon" src="../../../assets/images/no_notifications.png">
              <label class="no-notification-label">{{'NOTIFICATIONS.NO_NOTIFICATIONS' | translate}}</label>
            </div>
          </div>
        </ng-container>
    
        <ng-container *ngIf="allNotification.length">
          <div class="accordion">
            <ul class="notification-row-container" [ngClass]="{'accordion-height': showAccordion}">
              <li *ngFor="let notification  of allNotification.slice(0, showAccordion ? allNotification.length : 5)"
                class="notification-row" (click)="onNotificationClick(notification, myDrop)">
                <div class="notification-image">
                  <img alt="Risk Reduction Icon" src="../../../assets/images/risk-reduction.png">
                </div>
                <div class="notification-text">
                  <div class="notification-heading">{{ getNotificationDetails(notification).title }}</div>
                  <div class="notification-message">{{getNotificationDetails(notification).message}}
                    <span *ngIf="notification.NotificationCategoryTypeId == 'DuplicateUnmappedDatasources'" class="notification-message-bold">{{notification.id}}</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div>
            <ng-container *ngIf="allNotification.length > 5 && !showAccordion">
              <button class="view-all-button" (click)="toggleAccordion()">{{'COMMON.VIEW_ALL' | translate}}
                {{totalNotificationCount}}</button>
            </ng-container>
            <ng-container *ngIf="showAccordion">
              <button class="view-less-button" (click)="toggleAccordion()">{{'COMMON.VIEW_LESS' | translate}}</button>
            </ng-container>
          </div>
        </ng-container>
    
      </div>
    </div>
  </div>
</div>