import { Component, Input, Output, EventEmitter, ElementRef, HostListener, ViewChild, OnInit } from '@angular/core';
import { SelectionRange, MultiViewCalendarComponent } from '@progress/kendo-angular-dateinputs';
import { TranslateService } from '@ngx-translate/core';
import { DateRangeService } from '../../services/date-range.service';
import {DateRange} from './custom-date-multiview-calendar.model'
import {DateOption} from './../../_enum/constants'
import * as moment from 'moment';


@Component({
  selector: 'nalco-custom-date-multiview-calendar',
  templateUrl: './custom-date-multiview-calendar.component.html',
  styleUrls: ['./custom-date-multiview-calendar.component.scss']
})
export class CustomDateMultiviewCalendarComponent implements OnInit {
  customDate: SelectionRange;

  constructor(private translate: TranslateService, private dateService: DateRangeService,
    private elementRef: ElementRef) { }

  @ViewChild('anchor', { static: true }) public anchorRef: ElementRef;
  @ViewChild('popup', { static: true }) public popupRef: ElementRef;
  @ViewChild('multiviewCalendar') private multiviewCalendarRef: MultiViewCalendarComponent;

  @Output() filterValChanged = new EventEmitter<any>();
  @Input() startDate; // Start Date Value whch is present in queryparams/SSD
  @Input() endDate; // End Date Value whch is present in queryparams/SSD
  @Input() filterDays?: number; // takes the number of days passed in SSD filter
  @Input() isOmni?: boolean = false; //to restrict years selection
  closePopUp = false;
  range: SelectionRange;
  hideCalendar = false;
  isSelectedMoreThanYear = false;
  focusedDate: Date;
  currentDate = new Date();
  selectedEndDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate());
  selectedValue;
  disabledDates;
  isLoaded = false;
  private isCustomSelected = false;
  public show = false;
  views = 2;
  prevSelectedValues;
  dateRanges : DateRange;
    

  public get formattedValue(): string {
    if (this.selectedValue.text === this.dateRanges.options[8].text) {
      if (!(this.range && this.range.start && this.range.end)) {
        return 'Select Date';
      }
      return this.range.start.toLocaleDateString() + '-' + this.range.end.toLocaleDateString()
    } else {
      return this.selectedValue.text
    }
  }

  public myrange = {
    start: new Date(2021, 5, 10),
    end: new Date(2021, 5, 20)
  };
  public myfocusedDate: Date = new Date(2021, 2, 10);


  @HostListener('document:keydown', ['$event'])
  public keydown(event: KeyboardEvent): void {
    if (event.code === 'Escape') {
      this.toggle(false);
    }
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: KeyboardEvent): void {
    if (!this.contains(event.target)) {
      if (!this.isCustomSelected) {
        this.toggle(false);
      }
      this.isCustomSelected = false;
    }
  }

  @HostListener('click', ['$event'])
  public popUpClick(event: KeyboardEvent): void {
    if (!this.contains(event.target) && this.selectedValue.text === this.dateRanges.options[8].text && !this.closePopUp) {
      this.isCustomSelected = true;
      this.customDate = this.range;
      this.toggle(true);
    }
  }

  ngOnInit() {
    this.dateRanges =  this.getDateRangeOptions(DateOption);
    this.range = {
      start: this.getStartDate(0),
      end: this.selectedEndDate
    };
    this.focusedDate = new Date();
    if (this.filterDays > 0) {
      this.checkSelectedDateRange();
    } else {
      this.selectedValue = this.dateRanges.options[0];
    }
    this.getDisableDates(this.dateRanges.options[0].value);
    this.checkSelectedDateRange();
    this.isLoaded = true;
  }

  ngOnChanges() {
    this.checkSelectedDateRange();
  }

/**
 * @description Fetch the date range selection and assign it to the this.range
 */

  onDateSelection() {
    this.range = this.multiviewCalendarRef.selectionRange
  }

  /**
 * @description Translating the text value language based on the culture
 * @returns the updated date range options
 */

  getDateRangeOptions(value:DateRange){
    for(const item of value.options){
      item.text = this.translate.instant(item.text);
    }
    return value
  }


  cancelButton(event) {
    if (this.prevSelectedValues) {
      this.selectedValue = this.prevSelectedValues;
      if (this.selectedValue.text === this.dateRanges.options[8].text) {
        this.range = this.customDate;
        this.getDisableDates(this.selectedValue.value);
      }
      this.checkSelectedDateRange();
    }
  }
  checkSelectedDateRange() {
    if (this.startDate) {
      this.getDisableDates(this.dateRanges.options[8].value);
      this.selectedValue = this.dateRanges.options[8];
      this.range = {
        start: this.getPreselectedDates(this.startDate),
        end: this.getPreselectedDates(this.endDate)
      }
    }
    else {
      this.getDisableDates(this.dateRanges.options[0].value);
      this.dateRanges.options.forEach(days => {
        if (days.value == 'last' + this.filterDays) {
          this.selectedValue = days;
        }
        this.range = {
          start: this.getStartDate(Number(this.filterDays)),
          end: this.selectedEndDate
        }
      });
    }
    this.setDuration(this.selectedValue);
    this.toggleItem(this.selectedValue);
    
  }

  getStartDate(days) {
    const currentDate = new Date();
    return new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - days);
  }

  changeDates($event) {
    this.isSelectedMoreThanYear = false;
    // this.prevSelectedValues = this.dateRanges.options.filter( ele => ele.isSelected == true)[0];/
    this.toggleItem($event);
    this.getDisableDates($event.value);
    this.selectedValue = $event;
    this.filterValChanged.emit($event);
    if ($event.value === this.dateRanges.options[8].value) {
      this.getDisableDates($event.value);
    } else {
      this.range = {
        start: this.getStartDate(Number($event.value.substring(4))),
        end: this.selectedEndDate
      }
      this.setDuration($event);

    }
  }

  getPreselectedDates(date) {
    const currentDate = new Date(date);
    return new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
  }

  getDisableDates(value) {
    if (value === this.dateRanges.options[8].value) {
      if (this.isOmni) {
        this.disabledDates = (date: Date): boolean => {
          if (date.getFullYear() === this.currentDate.getFullYear() && date.getMonth() === this.currentDate.getMonth()
            && this.currentDate.getDate() < date.getDate()) {
            return true;
          }
          if (this.currentDate.getFullYear() < date.getFullYear()) {
            return true;
          }
          if (date.getFullYear() > this.currentDate.getFullYear() - 50) {
            return false;
          }
          else {
            return true;
          }
        }
      }
      else {
        this.disabledDates = (date: Date): boolean => {
          return date.getDate() % 1 != 0;
        }
      }
    } else {
      this.disabledDates = (date: Date): boolean => {
        return date.getDate() % 1 === 0;
      }
    }
  }

  setDuration($event) {
    let selectedValue = $event.value;
    let durationNumber: number;
    if (selectedValue.indexOf('last') === 0) {
      durationNumber = Number(selectedValue.substring(4));
    }
    else {
      durationNumber = 0;
    }
    this.dateService.setDuration({ Number: durationNumber, Value: selectedValue, StartDate: durationNumber ? null : moment(this.range.start).format('yyyy-MM-DDT00:00:00'), EndDate: durationNumber ? null : moment(this.range.end).format('yyyy-MM-DDT23:59:59') });
    this.filterValChanged.emit($event);
  }

  validateCustomDateSelection() {
    var selectedDays = moment(this.range.end).diff(moment(this.range.start), 'days');
    if (selectedDays > 365) {
      this.isSelectedMoreThanYear = true;
    } else {
      this.isSelectedMoreThanYear = false;
      this.applyFilter();
    }
  }

  applyFilter() {
    this.setDuration({ text: this.dateRanges.options[8].text, value: this.dateRanges.options[8].value });
    this.closePopUp = true;
    this.toggle(false);
  }


  toggleItem(elem) {
    for (let index = 0; index < this.dateRanges.options.length; index++) {
      if (elem.value == this.dateRanges.options[index].value) {
        this.dateRanges.options[index].isSelected = true;
      }
      else {
        this.dateRanges.options[index].isSelected = false;
      }
    }
  }

  public toggle(show?: boolean): void {
    this.show = show !== undefined ? show : !this.show;
    if (this.show && this.closePopUp) {
      this.closePopUp = false;
    }
  }

  private contains(target: EventTarget): boolean {
    return (
      this.anchorRef.nativeElement.contains(target) ||
      (this.popupRef ? this.popupRef.nativeElement.contains(target) : false)
    );
  }

}






