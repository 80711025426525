import * as contracts from '../contracts/_contracts';
import { UserPreference } from '../models/User';

export class Persmissions implements contracts.IPermissions {
  PermissionId: number;
  PermissionName: string;
  constructor(permission?: contracts.IPermissions) {
    if (permission) {
      this.PermissionId = permission.PermissionId;
      this.PermissionName = permission.PermissionName;
    }
  }
}

export class ActionLogUser implements contracts.IActionLogUser {
  UserPreference: UserPreference = new UserPreference();
  UserId: number;
  FederationIdentifier: string;
  LastName: string = '';
  FirstName: string = '';
  MiddleName: string = '';
  EmailAddress: string = '';
  UserTypeName: string;
  UserStatusTypeName: string;
  Phone: string;
  Mobile: string;
  UserCountry: number;
  TimezoneId: number;
  Culture: string;
  CurrencyCode: string;
  UserLogin: string;
  IsInternalUser: boolean;
  Permissions: Array<Persmissions> = [];
  constructor(actionLogUser?: contracts.IActionLogUser) {
    if (actionLogUser) {
      this.UserPreference = actionLogUser.UserPreference;
      this.UserId = actionLogUser.UserId;
      this.FederationIdentifier = actionLogUser.FederationIdentifier;
      this.LastName = actionLogUser.LastName;
      this.FirstName = actionLogUser.FirstName;
      this.MiddleName = actionLogUser.MiddleName;
      this.EmailAddress = actionLogUser.EmailAddress;
      this.UserTypeName = actionLogUser.UserTypeName;
      this.UserStatusTypeName = actionLogUser.UserStatusTypeName;
      this.Phone = actionLogUser.Phone;
      this.Mobile = actionLogUser.Mobile;
      this.UserCountry = actionLogUser.UserCountry;
      this.TimezoneId = actionLogUser.TimezoneId;
      this.Culture = actionLogUser.Culture;
      this.CurrencyCode = actionLogUser.CurrencyCode;
      this.UserLogin = actionLogUser.UserLogin;
      this.IsInternalUser = actionLogUser.IsInternalUser;
      if (actionLogUser.Permissions && actionLogUser.Permissions.length) {
        actionLogUser.Permissions.forEach(permission => {
          this.Permissions.push(new Persmissions(permission));
        });
      }
    }
  }
}

export class FileAttachments implements contracts.IFileAttachments {
  FileContent: Array<number> = [];
  FileAttachmentId: number = 0;
  RecommendationId: number;
  AttachmentSortOrder: number;
  AttachmentName: string;
  StoragePath: string;
  ModifiedBy: number;
  ModifiedOn: string;
  constructor(fileAttachments?: contracts.IFileAttachments) {
    if (fileAttachments) {
      this.FileContent = fileAttachments.FileContent;
      this.FileAttachmentId = fileAttachments.FileAttachmentId;
      this.RecommendationId = fileAttachments.RecommendationId;
      this.AttachmentSortOrder = fileAttachments.AttachmentSortOrder;
      this.AttachmentName = fileAttachments.AttachmentName;
      this.StoragePath = fileAttachments.StoragePath;
      this.ModifiedBy = fileAttachments.ModifiedBy;
      this.ModifiedOn = fileAttachments.ModifiedOn;
    }
  }
}

export class ActionsTaken implements contracts.IActionsTaken {
  ActionTakenBy: ActionLogUser = new ActionLogUser();
  ActionTakenId: number;
  RecommendationId: number;
  ActionDescription: string;
  ActionTakenById: number;
  ActionTakenOn: string;
  ModifiedBy: number;
  ModifiedOn: string;
  constructor(actionsTaken?: contracts.IActionsTaken) {
    if (actionsTaken) {
      this.ActionTakenBy = new ActionLogUser(actionsTaken.ActionTakenBy);
      this.ActionTakenId = actionsTaken.ActionTakenId;
      this.RecommendationId = actionsTaken.RecommendationId;
      this.ActionDescription = actionsTaken.ActionDescription;
      this.ActionTakenById = actionsTaken.ActionTakenById;
      this.ActionTakenOn = actionsTaken.ActionTakenOn;
      this.ModifiedBy = actionsTaken.ModifiedBy;
      this.ModifiedOn = actionsTaken.ModifiedOn;
    }
  }
}

export class EnabledService implements contracts.IEnabledService {
  Id: number;
  Name: string;
  constructor(enabledService?: contracts.IEnabledService) {
    if (enabledService) {
      this.Id = enabledService.Id;
      this.Name = enabledService.Name;
    }
  }
}

export class ActionLog implements contracts.IActionLog {
  ContextPointName: string = '';
  ContextPointTypeId: number = 0;
  ContextPointStatus: string = '';
  SiteId: number;
  SiteName: string;
  ActionStatusTypeName: string;
  ActionImpactTypeName: string;
  ResponsiblePerson: ActionLogUser = new ActionLogUser();
  FileAttachments: Array<FileAttachments> = [];
  ActionsTaken: Array<ActionsTaken> = [];
  EnabledServices: Array<EnabledService> = [];
  RecommendationId: number;
  ContextPointId: number = 0;
  ActionImpactTypeId: number;
  ActionStatusTypeId: number;
  Problem: string;
  Recommendation: string;
  RecommendedOn: string;
  ResponsiblePersonId: number;
  ModifiedBy: number;
  ModifiedOn: string;
  EmailSentDate: string;

  constructor(actionLog?: contracts.IActionLog) {
    if (actionLog) {
      this.ContextPointName = actionLog.ContextPointName;
      this.ContextPointTypeId = actionLog.ContextPointTypeId;
      this.ContextPointStatus = actionLog.ContextPointStatus;
      this.SiteId = actionLog.SiteId;
      this.SiteName = actionLog.SiteName;
      this.ActionStatusTypeName = actionLog.ActionStatusTypeName;
      this.ActionImpactTypeName = actionLog.ActionImpactTypeName;
      this.ResponsiblePerson = new ActionLogUser(actionLog.ResponsiblePerson);
      if (actionLog.FileAttachments && actionLog.FileAttachments.length) {
        actionLog.FileAttachments.forEach(attachment => {
          this.FileAttachments.push(new FileAttachments(attachment));
        });
      }
      if (actionLog.ActionsTaken && actionLog.ActionsTaken.length) {
        actionLog.ActionsTaken.forEach(actions => {
          this.ActionsTaken.push(new ActionsTaken(actions));
        });
      }
      if (actionLog.EnabledServices && actionLog.EnabledServices.length) {
        actionLog.EnabledServices.forEach(enabledService => {
          this.EnabledServices.push(new EnabledService(enabledService));
        });
      }
      this.RecommendationId = actionLog.RecommendationId;
      this.ContextPointId = actionLog.ContextPointId;
      this.ActionImpactTypeId = actionLog.ActionImpactTypeId;
      this.ActionStatusTypeId = actionLog.ActionStatusTypeId;
      this.Problem = actionLog.Problem;
      this.Recommendation = actionLog.Recommendation;
      this.RecommendedOn = actionLog.RecommendedOn;
      this.ResponsiblePersonId = actionLog.ResponsiblePersonId;
      this.ModifiedBy = actionLog.ModifiedBy;
      this.ModifiedOn = actionLog.ModifiedOn;
      this.EmailSentDate = actionLog.EmailSentDate;
    }
  }
}

export class ActionLogRecommendations implements contracts.IActionLogRecommendations {
  ContextPointName: string;
  ContextPointId: number;
  ContextPointTypeId: number;
  TotalSitesWithActions: number;
  TotalActions: number;
  TotalSafetyImpactActions: number;
  TotalHighImpactActions: number;
  TotalMediumImpactActions: number;
  TotalLowImpactActions: number;
  TotalOpenActions: number;
  TotalClosedActions: number;
  Recommendations: Array<ActionLog> = [];

  constructor(actionLogRecommendation?: contracts.IActionLogRecommendations) {
    if (actionLogRecommendation) {
      this.ContextPointName = actionLogRecommendation.ContextPointName;
      this.ContextPointId = actionLogRecommendation.ContextPointId;
      this.ContextPointTypeId = actionLogRecommendation.ContextPointTypeId;
      this.TotalSitesWithActions = actionLogRecommendation.TotalSitesWithActions;
      this.TotalActions = actionLogRecommendation.TotalActions;
      this.TotalSafetyImpactActions = actionLogRecommendation.TotalSafetyImpactActions;
      this.TotalHighImpactActions = actionLogRecommendation.TotalHighImpactActions;
      this.TotalMediumImpactActions = actionLogRecommendation.TotalMediumImpactActions;
      this.TotalLowImpactActions = actionLogRecommendation.TotalLowImpactActions;
      this.TotalOpenActions = actionLogRecommendation.TotalOpenActions;
      this.TotalClosedActions = actionLogRecommendation.TotalClosedActions;
      if (actionLogRecommendation.Recommendations && actionLogRecommendation.Recommendations.length) {
        actionLogRecommendation.Recommendations.forEach(actionlog => {
          this.Recommendations.push(new ActionLog(actionlog));
        });
      }
    }
  }
}

export class FilterActionLog implements contracts.IFilterActionLog {
  isServerCall: boolean = false;
  contextpointId: number = -1;
  siteId: number = -1;
  startDate: string = '';
  endDate: string = '';
  actionlogStatus: string = '';
  actionLogStatusId: number;
  impactStatus: string = '';
  impactStatusId: number = -1;
  assetId: number = -1;
  assetStatus: string = '';
  assetStatusId: number = -1;
  constructor(fal?: contracts.IFilterActionLog) {
    if (fal) {
      this.isServerCall = fal.isServerCall;
      this.contextpointId = fal.contextpointId;
      this.siteId = fal.siteId;
      this.startDate = fal.startDate;
      this.endDate = fal.endDate;
      this.actionlogStatus = fal.actionlogStatus;
      this.actionLogStatusId = fal.actionLogStatusId;
      this.impactStatus = fal.impactStatus;
      this.impactStatusId = fal.impactStatusId;
      this.assetId = fal.assetId;
      this.assetStatus = fal.assetStatus;
      this.assetStatusId = fal.assetStatusId;
    }
  }
}
