<div class="dateRangeButton activeButton" #anchor id='dateRangeButtonID' (click)="toggle()"
[ngbTooltip]="formattedValue" placement="bottom" container="body">
  <span class="custom-calendar-icon icon-calendar-icon float-left">
  </span><a href="javascript:void(0)"></a>
  <p class="filterHeading">
    {{'COMMON.SORT_OPTIONS.VIEW_SITES_BY' | translate}}
  </p>
  <ng-container>
    <p class="filterDays">
      {{formattedValue}}
    </p>
  </ng-container>
</div>
<kendo-popup #popup [anchor]="anchorRef"  *ngIf="show">
  <div class="flex-container" id="days-content">
    <div class="date-range-options">
      <h6 class="link" id="{{item.value}}" [ngClass]="{active: item.isSelected}" *ngFor="let item of dateRanges?.options;let i = index"
        (click)="changeDates(item)" data-item="i"><a>{{item.text}}</a></h6>
    </div>
    <div class="date-range-calendar" id="calendar-box">
      <form #form="ngForm">
  <kendo-multiviewcalendar
    kendoDateRangeSelection
    #multiviewCalendar
    [showOtherMonthDays]="false"
    [selectionRange]="range"
    [disabledDates]="disabledDates"
    [focusedDate]="range.start || range.end"
    (click)="onDateSelection()">
  </kendo-multiviewcalendar>
   <div [hidden]="!isSelectedMoreThanYear" class="k-invalid"> {{'COMMON.CUSTOM_DATE_RANGE_INVALID' | translate}}</div>
   </form>
  </div>
   <button class="btn btn-primary custom-position float-right" [disabled]="((selectedValue?.text != ('COMMON.SORT_OPTIONS.CUSTOM' | translate)) ? true : false)" (click)="validateCustomDateSelection()">Apply</button>
   <button class="btn btn-secondary custom-position custom-cancel-style float-right" (click)="cancelButton($event);closePopUp = true">Cancel</button>
  </div>
 </kendo-popup>
