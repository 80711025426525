import { Injectable, Output } from '@angular/core';
import { UserService } from '../../user/user.service';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  ActivatedRoute,
  Router,
  CanActivateChild
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthorizationService } from './authorization.service';
import { ErrorManagementService } from '../../services/error-management/error-management.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class AuthorizationGuard implements CanActivate, CanActivateChild {

  constructor(
    private authorizationService: AuthorizationService,
    private router: Router, private userService: UserService,
    private errorManagementService : ErrorManagementService
  ) {}

  canActivate(

    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authorizationCheck(next);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authorizationCheck(next);
  }

  authorizationCheck(next: ActivatedRouteSnapshot) {
    const allowedRoles = next.data.roles;
    const isAuthorized = this.authorizationService.isAuthorized(allowedRoles);
    if (!isAuthorized) {
      this.router.navigate(['/error-page'], {
        queryParams: { 'status': 403 },
        skipLocationChange: true
      });
      const error = new HttpErrorResponse({ error: "Unauthorised", status: 403, url: window.location.href, statusText: "Access Denied" });
      this.errorManagementService._logCustomEvents(error);
    }
    else if (next.routeConfig.path === '**') {
      this.router.navigate(['/error-page'], {
        queryParams: { 'status': 404 },
        skipLocationChange: true
      });
      const error = new HttpErrorResponse({ error: "Unable to process request", status: 404, url: window.location.href, statusText: "Page Not Found" });
      this.errorManagementService._logCustomEvents(error);
    }

    return isAuthorized;
  }
}
