import { Component, OnInit, Input, ViewChild, OnChanges } from '@angular/core';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { XdComponentState } from '../xd-container/xd-component';
import { FinancialImpactExportCSVService } from '../../../financial-impact/_services/financial-impact-export-csv.service';
import { DateRangeService } from '../../services/date-range.service';
import { MaximizeDialogComponent } from '../maximize-dialog/maximize-dialog.component';
import { ArrayHeader } from '../../_enum/constants';

@Component({
  selector: 'nalco-diagnostic-widget',
  templateUrl: './diagnostic-widget.component.html',
  styleUrls: ['./diagnostic-widget.component.scss']
})
export class DiagnosticWidgetComponent  extends XdComponentState {
  @Input() set stateProxy(componentState: any) {
    this.setState(componentState);
  }
  @Input() set activeData(value) {
    if (value !== undefined && value !== null) {
      this.activeAlarm = value;
    }
  }
  @Input() set historicalData(value) {
    if (value !== undefined && value !== null) {
      this.historicalAlarm = value;
    }
  }
  @Input() errorInfo?: any;
  @Input() siteName;
  @Input() filterData: any;
  @ViewChild('moreoptions',{static: false}) vmore: any;
  activeAlarm: Array<any>;
  historicalAlarm: Array<any>;
  modalRef: any;
  constructor(private translate: TranslateService,
    private FIExportCSVService: FinancialImpactExportCSVService,
    private dateService: DateRangeService,
    private modalService: NgbModal) { 
      super();
    }

    myParentInstance(): DiagnosticWidgetComponent{
      return this;
    }

  public exportToExcel(): void {
    const d = new Date();
    const date = moment(d).format('YYYY/MM/DD');
    let headerData: Array<any> = [
      {
        [this.translate.instant('WATER_SAFETY.DATE')]: this.translate.instant('WATER_SAFETY.DATE_EXPORT_RUN') + ' ('
          + date + ')'
      },
      { ["Site Name"]: this.siteName }];
    headerData = headerData.concat([
      {
        [this.translate.instant('WATER_SAFETY.TIME_RANGE_TEXT')]: this.translate.instant('WATER_SAFETY.LAST') + ' '
          + this.dateService.getDuration().Number + ' ' + this.translate.instant('COMMON.DAYS')
      }]);

    headerData = headerData.concat([
      {
        [this.translate.instant('WATER_SAFETY.TABLE_TITLE')]: this.translate.instant('OMNI.PERFORMANCE.OMNI_DIAGNOSTICS') 
      },
    ]);
    const arrDeleteKey = [];

    let arrHeader: Array<any>;
    let AllCSVData: any;
    if (this.filterData === 1) {
      AllCSVData = this.activeAlarm;
    } else if (this.filterData === 2) {
      AllCSVData = this.historicalAlarm;
    }
    arrHeader = ArrayHeader[ArrayHeader.findIndex(data => data.value === this.filterData)].text;
     
    const csvTitle = this.translate.instant('OMNI.PERFORMANCE.OMNI_DIAGNOSTICS') + '_' + this.FIExportCSVService.getCorporatNameOrEvocLastNode() + date;
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      headers: arrHeader,
      useBom: false
    };

    const AllData = this.FIExportCSVService.generateCSV(AllCSVData, csvTitle, options, arrDeleteKey, headerData);
    this.vmore.ExportAsCSVWater(AllData.exportData, AllData.csvTitle, AllData.options);
  }

  maximizeDialog() {
    this.modalRef = this.modalService.open(MaximizeDialogComponent, { windowClass: 'audit-modal', backdrop: 'static', keyboard: false }).componentInstance;
  }
}
