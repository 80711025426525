import { Injectable } from '@angular/core';
import { AppEnvConfig } from '../../app.env.config';
import { Observable } from 'rxjs/Observable';
import { LocalCacheService } from '../../core/local-cache/local-cache.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class LooperService {
  constructor(
    private httpClient: HttpClient, private localCache: LocalCacheService,
    private config: AppEnvConfig) { }

  get(): Observable<any> {
    const url = this.config.getEnv('apiServerUrl');
    const api = `${url}Users/Token`;
    return this.httpClient
      .get(api)
      .map(res => res);
  }

  public checkVersion(source = false) {
    return this.httpClient.get('version.json', {}).subscribe(
      (envResponse) => {
        if (this.localCache.fetchData('localVersionId') !== envResponse['build']) {
          this.localCache.storeData('localVersionId', envResponse['build'])
          if (!source) {
             window.location.reload(); //this is deprecated with force reload parameter
          }
        }
      },
      error => {
        setTimeout(() => {
          window.location.reload();
        }, 10000);
      }
    );
  }
}
