import { Pipe, PipeTransform } from '@angular/core';
import * as contracts from '../../shared/contracts/_contracts';
import { DecimalPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'numberFormat'
})

export class NumberFormatPipe implements PipeTransform {
    constructor( private translate: TranslateService){}
    transform(num: number, isStringFormat: boolean = false, locale: string = 'en-US', specificFormat?: string, isGraphAxis: boolean = false,  currencySymbol: string = '$', translateLang?: string): string {
        let pipe = new DecimalPipe(locale);
        num = Math.round(num);
        if (!isStringFormat) {
            return pipe.transform(num);
        }
        
        else {
            if (specificFormat != null && specificFormat != undefined && specificFormat != "") {
                if (specificFormat == this.translate.instant('FINANCIAL_IMPACT.CURRENCY_FORMAT.THOUSAND_SHORT')) {
                    let value = Math.round(num / 1000);
                    return pipe.transform(value, '1.0-2') + this.translate.instant('FINANCIAL_IMPACT.CURRENCY_FORMAT.THOUSAND_SHORT');
                }
                else if (specificFormat == this.translate.instant('FINANCIAL_IMPACT.CURRENCY_FORMAT.MILLION_SHORT')) {
                    if (isGraphAxis) {
                        return pipe.transform((num / 1000000), '1.1-1') + this.translate.instant('FINANCIAL_IMPACT.CURRENCY_FORMAT.MILLION_SHORT');
                    }
                    else {
                        return pipe.transform((num / 1000000), '1.2-2') + this.translate.instant('FINANCIAL_IMPACT.CURRENCY_FORMAT.MILLION_SHORT');
                    }
                }
                else {
                    if (num !== undefined) {
                        return num.toString();
                    }
                    else {
                        return '';
                    }
                }
            }
            else {
                if (num <= 999) {
                    return pipe.transform(num);
                }

                // thousands
                else if (num >= 1000 && num <= 999000) {
                    let value = Math.round(num / 1000);
                    return pipe.transform(value, '1.0-2') + this.translate.instant('FINANCIAL_IMPACT.CURRENCY_FORMAT.THOUSAND_SHORT');
                }
                // millions
                else if (num >= 999001) {
                    if (isGraphAxis) {
                        return pipe.transform((num / 1000000), '1.1-1') + this.translate.instant('FINANCIAL_IMPACT.CURRENCY_FORMAT.MILLION_SHORT');
                    }
                    else {
                       return pipe.transform((num / 1000000), '1.2-2') + this.translate.instant('FINANCIAL_IMPACT.CURRENCY_FORMAT.MILLION_SHORT');
                    }
                }

                else {
                    if (num !== undefined) {
                        return num.toString();
                    }
                    else {
                        return '';
                    }
                }
            }
        }

    }
}


