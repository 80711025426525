// angular modules packages
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

// custom modules components
import { AppEnvConfig } from '../../app.env.config';
import { ControllerManagementServiceConfig } from './controller-management.service.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class ControllerManagementService extends BehaviorSubject<GridDataResult> {
  // declare all the variables
  private configEnv: any;
  public url : string;
  public contextPoint : number;
  public jsonServerUrl : string;
  public dagType : string;
  public checkMaxDags : string;

  // constructor
  constructor(
    private controllerManagementServiceConfig: ControllerManagementServiceConfig,
    private httpClient: HttpClient,
    private config: AppEnvConfig
  ) {
    super(null);
    this.configEnv = controllerManagementServiceConfig.getEnvironment();
    this.configEnv.apiServerUrl = config.getEnv('apiServerUrl');
    this.configEnv.jsonServerUrl = config.getEnv('jsonServerUrl');
    this.configEnv.apiServerUrlAuth = config.getEnv('apiServerUrl');
    this.configEnv.contextPoint = 0;
    this.dagType = 'NalcoWater/';
    this.checkMaxDags = '/true/';
  }

  public getsites(dropDownPaging): Observable<any> {
    const url = this.configEnv.apiServerUrl + this.configEnv.apiUrl.getsites;
    const body = JSON.stringify(dropDownPaging);
    return this.httpClient.post(url, body).map((response) => {
      try{
        return response;
      } catch (e){
        return response;
      }
    });
  }

  public getProvisionedControllers(apiUrl) {
    const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getProvisionedControllers + apiUrl}`;
    return this.httpClient.get(url).map((response) => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }

   public getProvisionedControllersBySerialNumber(SerialNumber) {
    const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getProvisionedControllersBySerialNumber + '/' + SerialNumber}`;
    return this.httpClient.get(url).map((response) => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }

  public getUnProvisionedControllers(SiteCpID) {
    const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getUnProvisionedControllers + '/' + SiteCpID}`;
    return this.httpClient.get(url).map((response) => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }
  public downloadConfiguration(documentDetails: any): Observable<any> {
    const url= this.configEnv.apiServerUrl + this.configEnv.apiUrl.downloadConfiguration;
    const body= JSON.stringify(documentDetails);
    return this.httpClient.post(url, body).map((response) => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }

  public getControllerTypes(SiteCpID) {
    const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getControllerTypesBySiteId + '/' + SiteCpID}`;
    return this.httpClient.get(url).map((response) => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }
  public getRHMIUri(siteCpId,serialNumber) {
    const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getRHMIUri + '/' + siteCpId + '/' + serialNumber}`;
    return this.httpClient.get(url).map((response) => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }
  public getGeneratedPassword(passwordOfTheDayData: any): Observable<any> {
    const url= this.configEnv.apiServerUrl + this.configEnv.apiUrl.getGeneratedPassword;
    return this.httpClient.post(url, passwordOfTheDayData).map((response) => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }
  public getFileConfigurations(siteCpId,serialNumber,siteName) {
    const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getFileConfigurations + '/' + siteCpId+ '/' + serialNumber + '/' + siteName}`;
    return this.httpClient.get(url).map((response) => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }
  public downloadProvisionedConfigurationFile(documentDetails: any): Observable<any> {
    const url= this.configEnv.apiServerUrl + this.configEnv.apiUrl.downloadProvisionedConfigurationFile;
    const body= JSON.stringify(documentDetails);
    return this.httpClient.post(url, body).map((response) => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }
  public getControllerSoftwareVersions() {
    const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getControllerSoftwareVersions}`;
    return this.httpClient.get(url).map((response) => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }
  public getActiveControllerList() {
    const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getActiveControllerList + '/' + 12000 + '/' + 6 }`;
    return this.httpClient.get(url).map((response) => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }
  public getTargetVersionList() {
    const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getTargetVersionList}`;
    return this.httpClient.get(url).map((response) => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }
  public downloadControllerSoftwareVersionsFile(documentDetails: any): Observable<any> {
    const url= `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.downloadControllerSoftwareVersionsFile}`;
    const body= JSON.stringify(documentDetails)
    return this.httpClient.post(url,body).map((response) => {
      try{
        return response;
      } catch(e){
        return response;
      }
    });
  }
  public upgradeControllers(selectedControllerList: any): Observable<any> {
    const url= this.configEnv.apiServerUrl + this.configEnv.apiUrl.upgradeController;
    const body= JSON.stringify(selectedControllerList)
    return this.httpClient.post(url, body).map((response) => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }

  public getDataAggregatorReport(): Observable<any> {
    const url= this.configEnv.apiServerUrl + this.configEnv.apiUrl.getDataAggregatorReport;
    return this.httpClient.get(url).map((response) => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }

  public GetSoftwareUpgradeReport(listOfSerialNumbers: any): Observable<any> {
    const url= this.configEnv.apiServerUrl + this.configEnv.apiUrl.getSoftwareUpgradeReport;
    const body= JSON.stringify(listOfSerialNumbers)
    return this.httpClient.post(url, body).map((response) => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }
  public getSystemHealthParameters(ControllerCpId){
    const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getSystemHealthParameters + '/' + ControllerCpId}`;
    return this.httpClient.get(url).map((response) => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }
  public getParameterTrendData(graphParam: any): Observable<any> {
    const url = this.configEnv.apiServerUrl + this.configEnv.apiUrl.getParameterTrendData;
    return this.httpClient.post(url, graphParam).map(response => {
        try {
            return response;
        } catch (e) {
            return response;
        }
    });
}

  public getGatewayStatisticsParameters(siteCpId,serialNumber){
    const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getGatewayStatisticsParameters + '/' + siteCpId + '/' + serialNumber}`;
    return this.httpClient.get(url).map((response) => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }

  public getDataDownloadForContextPoint(data): Observable<any> {
    const url = this.configEnv.apiServerUrl + this.configEnv.apiUrl.getDataDownloadForContextPoint;
    const headers = new HttpHeaders({
        Accept: 'text/csv',
    });
    const options = { headers, responseType: 'text' as any };
    return this.httpClient.post(url,data,options).map(response => {
        try {
            return response;
        } catch (e) {
            return response;
        }
    });
  }

    public getHardwareDetails(siteCpId,controllerCpId) {
      const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getHardwareDetails + '/' + siteCpId+ '/' + controllerCpId}`;
      return this.httpClient.get(url).map((response) => {
        try {
          return response;
        } catch (e) {
          return response;
        }
      });
    }

  public getAlarmLists(controllerCpId){
    const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getAlarmLists + '/' + controllerCpId}`;
    return this.httpClient.get(url).map((response) => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }
  
  public GetDataSourceSerialNumbers(){
    const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getDataSourceSerialNumbers}`;
    return this.httpClient.get(url).map((response) => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }

  public downloadReleaseNoteForSoftwareVersion(documentDetails: any): Observable<any> {
    const url= `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.downloadReleaseNoteForSoftwareVersion + '?softwareVersion=' + documentDetails}`;
    return this.httpClient.post(url,null).map((response) => {
      try{
        return response;
      } catch(e){
        return response;
      }
    });
  }
  
  public getCassadyVersionMapping() {
    const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getCassadyVersionMapping}`;
    return this.httpClient.get(url).map((response) => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }
}
