import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Observable, Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { AppEnvConfig } from '../../app.env.config';
import { SharedRoConfig } from './shared-ro.service.config';

@Injectable()
export class SharedRoService extends BehaviorSubject<GridDataResult>{

  private configEnv: any;
  private preferredUnit = new Subject<string>();
  preferredUnitChangeEvnt = this.preferredUnit.asObservable();
  private waterChemistryType = new BehaviorSubject<string>(null);
  waterChemistryTypeChangeEvnt = this.waterChemistryType.asObservable();
  private waterAnalysisType = new BehaviorSubject<string>(null);
  waterAnalysisTypeChangeEvnt = this.waterAnalysisType.asObservable();
  private opTab = new BehaviorSubject<boolean>(null);
  operatingParameterTab = this.opTab.asObservable();
  private wcTab = new BehaviorSubject<boolean>(null);
  waterChemistryTab = this.wcTab.asObservable();
  private drTab = new BehaviorSubject<boolean>(null);
  dosageAndRecommendationTab = this.drTab.asObservable();
  private forms = new BehaviorSubject<any>(null);
  tabForms = this.forms.asObservable();
  private pHControlProduct = new BehaviorSubject<string>(null);
  pHControl = this.pHControlProduct.asObservable();
  private setPoint = new BehaviorSubject<string>(null);
  pHSetPoint = this.setPoint.asObservable();
  private commonParams = new BehaviorSubject<any>(null);
  commonFormParams = this.commonParams.asObservable();
  private calcParam = new BehaviorSubject<any>({});
  calculatedParams = this.calcParam.asObservable();

  constructor(
    private sharedServiceConfig: SharedRoConfig,
    private httpClient: HttpClient,
    private config: AppEnvConfig) {
    super(null);
    this.configEnv = sharedServiceConfig.getEnvironment();
    this.configEnv.apiServerUrl = config.getEnv('apiServerUrl');
    this.configEnv.jsonServerUrl = config.getEnv('jsonServerUrl');
    this.configEnv.apiWaterServerUrl = config.getEnv('apiWaterServerUrl');

  }

  changePreferredUnit(message){
    this.preferredUnit.next(message);
  }

  changeWaterChemistry(message){
    this.waterChemistryType.next(message);
  }

  changeWaterAnalysisType(message){
    this.waterAnalysisType.next(message);
  }

  setOperatingParamData(message){
    this.opTab.next(message);
  }

  setWaterChemistryData(message){
    this.wcTab.next(message);
  }

  setDosageAndRecommendationData(message){
    this.drTab.next(message);
  }

  setForms(data){
    this.forms.next(data);
  }

  changepHControlProduct(message){
    this.pHControlProduct.next(message);
  }

  changepHSetPoint(message){
    this.setPoint.next(message);
  }

  setCommonParams(object){
    this.commonParams.next(object);
  }

  setDataForCalculation(object){
    this.calcParam.next(object);
  }

  public GetRoOptimizerAssets(siteId): Observable<any> {

    let url = `${this.configEnv.apiWaterServerUrl}${this.configEnv.apiUrl.getRoOptimizerAssets}?siteId=${siteId}`;

    return this.httpClient.get(url).map(response => {
      return response;
    });
  }

  public GetRoOptimizerScenarioInfo(scenarioId,assetId): Observable<any> {

    let url = `${this.configEnv.apiWaterServerUrl}${this.configEnv.apiUrl.getRoOptimizerScenarioInfo}?scenarioId=${scenarioId}&AssetId=${assetId}`;

    return this.httpClient.get(url).map(response => {
      return response;
    });
  }

  public GetDimensionalUsageKeys(): Observable<any> {

    let url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getDimensionalUsageKeys}`;

    return this.httpClient.get(url).map(response => {
      return response;
    });
  }

  public getROMappingJson(): Observable<any> {
    let url = 'assets/data/RO_Data_mapping.json';
    return this.httpClient.get(url).map((response: any) => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }

  public GetUnitsByDimensionalKey(params: any): Observable<any> {

    let url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getUnitsByDimensionalKey}`;

    return this.httpClient.post(url,params).map(response => {
      return response;
    });
  }

  public GetConversionByUnitType(params: any): Observable<any> {

    let url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getConversionByUnitType}`;

    return this.httpClient.post(url,params).map(response => {
      return response;
    });
  }

  public GetChargeBalance(object): Observable<any> {

    let url = `${this.configEnv.apiWaterServerUrl}${this.configEnv.apiUrl.getChargeBalance}`;

    return this.httpClient.post(url,object).map(response => {
      return response;
    });
  }

  public GetDosageAndRecommendationInfo(object): Observable<any> {

    let url = `${this.configEnv.apiWaterServerUrl}${this.configEnv.apiUrl.getDosageAndRecommendationInfo}`;

    return this.httpClient.post(url,object).map(response => {
      return response;
    });
  }

  public SaveScenarioInfo(object): Observable<any> {

    let url = `${this.configEnv.apiWaterServerUrl}${this.configEnv.apiUrl.saveScenario}`;

    return this.httpClient.post(url,object).map(response => {
      return response;
    });
  }

}
