import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';


@Injectable()
export class NavigationHandlerService {
    private navToggleSource = new Subject<boolean>();
    private leftPermission = new Subject<boolean>();

    navToggle$ = this.navToggleSource.asObservable();
    updateLeftNav$ = this.leftPermission.asObservable();

    disableNavigation() {
        this.navToggleSource.next(true);
    }

    enableNavigation() {
        this.navToggleSource.next(false);
    }
    updateLeftPanel(flgPermissionUpdated: boolean) {
        this.leftPermission.next(!this.leftPermission);
    }
}
