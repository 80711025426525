import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ControllerManagementService } from '../../../controller-management/_services/controller-management.service';
import { LocalCacheService } from '../../../core/local-cache/local-cache.service';
import { SharedService } from '../../services/shared-services.service';
import { Subscription } from 'rxjs/Subscription';
import { ErrorHandling } from '../error-handling/error-handling';
import { Router } from '@angular/router';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { ComponentState } from '../xd-container/xd-component-state';
import { XdComponentState } from '../xd-container/xd-component';


@Component({
  selector: 'nalco-download-configuration',
  templateUrl: './download-configuration.component.html',
  styleUrls: ['./download-configuration.component.scss']
})
export class DownloadConfigurationComponent extends XdComponentState implements OnInit {
  @Input() SerialNo;
  @Input() LastDataReceived
  siteCpId: any;
  isLoading: boolean =false;
  subscription: Subscription;
  errorHandling = new ErrorHandling(this.modalService, this.translate );
  errorInfo: any;
  isError = false;
  configurationList =[];
  viewData : GridDataResult;
  documentByteStream: any;
  siteName : string;
  constructor(private translate: TranslateService,
     public activeModal: NgbActiveModal,
     public controllerManagementService : ControllerManagementService,
     private localCacheService: LocalCacheService,
     private modalService: NgbModal,
     private router: Router) {super()
  }
  ngOnInit() {
     this.getConfigurationDetails(); 
  }

  getConfigurationDetails() {
    this.isLoading = true;
    this.siteCpId = this.localCacheService.fetchData('SiteCpId');
    this.siteName = this.localCacheService.fetchData('SiteName');
    this.controllerManagementService.getFileConfigurations(this.siteCpId,this.SerialNo, this.siteName).subscribe(response => {
      if (response) {
        this.configurationList.push(response);
        this.viewData = {
          data: this.configurationList[0],
          total: this.configurationList[0].length,
        };
        this.isLoading = false;
      }
    }, error => {
      this.isLoading = false;
      this.errorInfo = error;
      if (500 <= error.status && error.status < 600) {
      } else if (error.status === 417) {
        this.router.navigate(['/access-denied']);
      } else if (error.status === 404) {
        this.router.navigate(['/**']);
      } else {
        this.errorHandling.showErrorPopup(error);
      }
    });
  }

  base64ToArrayBuffer(base64: string) {
    const binaryString = window.atob(base64);
    const bytes = new Uint8Array(binaryString.length);
    return bytes.map((byte, i) => binaryString.charCodeAt(i));
  }

  downloadConfiguration($event) {
    let selectedConfiguration = {};
      selectedConfiguration = {     
          "FileName": $event.FileName,
          "FilePath":  $event.FilePath,
          "ModifiedDate":  $event.ModifiedDate,
          "CreatedBy":  $event.CreatedBy
      };
    
    this.controllerManagementService
      .downloadProvisionedConfigurationFile(selectedConfiguration)
      .subscribe(
        (response) => {
          if (response && response.length > 0) {
            this.documentByteStream = response;
            const data = this.documentByteStream;
            const arrayBuffer = this.base64ToArrayBuffer(data);
            this.createAndDownloadBlobFile(arrayBuffer, $event.FileName);
            this.setState(ComponentState.ready);
          }
        },
        (error) => {
          this.errorInfo = error;
          if (500 <= error.status && error.status < 600) {
          } else if (error.status === 417) {
            this.router.navigate(['/access-denied']);
          } else if (error.status === 404) {
            this.router.navigate(['/**']);
          } else {
            this.errorHandling.showErrorPopup(error);
          }
          this.setState(ComponentState.error);
        }
      );
  }
  createAndDownloadBlobFile(body, blobFileName, extension = 'pdf') {
    const blob = new Blob([body]);
    let fileName = '';
    fileName = `${blobFileName}`;
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement('a');
      // Browsers that support HTML5 download attribute
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  }

