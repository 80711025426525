import { Component, Input, Output, EventEmitter, forwardRef, OnInit, OnChanges,ChangeDetectorRef } from '@angular/core';
import { IDropdown, IDropdownEventType } from './dropdown-model';
import { TranslateService } from '@ngx-translate/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule, UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'nalco-dropdown',
    templateUrl: './dropdown.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DropdownComponent),
            multi: true
        }
    ]
})

export class DropdownComponent implements ControlValueAccessor, OnChanges {

    @Input() ddData: IDropdown; // To get the data for the dropdown
    @Input() dropdownClassName = '';
    @Input() isDisabled = false; // Defines the enable/disable value of the dropdown
    @Input() iconClass: string = ''; // Defines the icon requirement on the dropdown
    @Input() showSelect: boolean; // Defines the select requirement on the dropdown
    @Input() hideLabelIfSelected: boolean; // Defines the select requirement on the dropdown
    @Input() formControlObject: UntypedFormControl;
    @Input() label: string; // Defines the Label value of the dropdown
    @Input() name: string;  // Defines the sub-name of the dropdown
    @Input() options; //  Defines the options of the dropdown
    @Input() value: string = '';  // Binds the selected option
    @Input() isFilterValuePassed: boolean;
    @Input() isFromEdataWidget : boolean;
    @Input() fromInventory : boolean;
    @Input() showDate : boolean;
    @Output() ddChange = new EventEmitter<IDropdownEventType>(); // Emits the events when the dropdown options are selected
    selectedOptionTextVal:string;
    tooltipText = [];
    tooltipSecondText = [];
    constructor(private cdRef:ChangeDetectorRef,private translate : TranslateService){} 

    changeInDropdown(name, value, text,secondText?) {
        let label = this.ddData.label;
        this.ddData.options.forEach(option => {
            if (option.value === value) {
                option.isSelected = true;
            } else {
                option.isSelected = false;
            }
        });
        this.ddChange.emit({
            name: name,
            value: value,
            label: label,
            text: text,
            secondText: secondText
        });
        this._onChange(value);
        this._onTouched();
    }

    selectedOptionText() {
        let text = '';
        this.ddData.options.forEach(option => {
             if (option.isSelected) {
                text = option.text? option.text: option.secondText;
            }
            if(this.isFromEdataWidget && option.isSelected && option.secondText) {
                text = (option.text? option.text + '  ': '') + option.secondText;
            }
        });
        return text;
    }

    ngOnInit() {
        this.selectedOptionTextVal='';
        this.tooltipText = [];
        this.tooltipSecondText = [];
        if (!this.value) { return; }
        this.ddData.options.forEach(option => {
            if (option.value === this.value) {
                option.isSelected = true;
            } else {
                option.isSelected = false;
            }
        });
    }
    // ngAfterViewInit() {
    //     this.cdRef.detectChanges();
    // }
    ngAfterContentChecked() : void {
        this.cdRef.detectChanges();
    }
         
    ngOnChanges(changes) {
        if (changes.ddData !== undefined && !changes.ddData.firstChange && changes.ddData.currentValue === undefined) {
            this.ddData = changes.ddData.previousValue;
        }
        if (this.isFilterValuePassed) {
            if (!this.value) { return; }
            this.ddData.options.forEach(option => {
                if (option.value === this.value) {
                    option.isSelected = true;
                } else {
                    option.isSelected = false;
                }
            });
        }
    }
    getselectedOptionText(element: HTMLElement): void{
        this.selectedOptionTextVal = element.offsetWidth+1 <element.scrollWidth ? this.selectedOptionText(): '';
    }
    getTooltip(element:HTMLElement,i:number,type:string):void{ 
        this.tooltipText = [];
        this.tooltipSecondText = [];
        if(element.offsetWidth+1 < element.scrollWidth){
            if(type == 'text1'){
            this.tooltipText[i] = this.ddData.options[i].text;
            }else if(type == 'text2'){
            this.tooltipSecondText[i] = this.ddData.options[i].secondText;
            }
        }
    }
    

    // ControlValueAccessor implementation
    // ====================================

    // call if value was changed inside our component
    private _onChange = (_: any) => { };
    // call if input was "touched" .. !
    private _onTouched = () => { };

    // incoming change..
    public writeValue(val: any) {
        this.value = val;
    }

    changeLabel(label) {
        if (this.fromInventory) return this.translate.instant('COMMON.TIME_RANGE_TEXT');
        else return label;
    }

    public registerOnChange(fn: (_: any) => void): void { this._onChange = fn; }
    public registerOnTouched(fn: () => void): void { this._onTouched = fn; }

}
