import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {CommonModule} from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'nalco-search',
    templateUrl: './search.component.html'
})
export class SearchComponent implements OnInit {

    @Input() border: string; // Defines the requirement of the border
    @Input() placeholder: string; // Defines the placeholder for the search
    @Input() disableSearch= false; // Defines the requirement of search component
    @Input() bindModelData: any; // Binds the data of the search component
    @Input() searchIcon: string=''; // if user want to pass search Icon
    @Input() isSearchIconVisible=false; // show search icon
    @Output() bindModelDataChange: EventEmitter<any>= new EventEmitter(); // Emits the event of data change

    updateData(event) {
      this.bindModelData = event;
      this.bindModelDataChange.emit(event);
    }

    constructor() { }

    ngOnInit() {
    }

}
