import { IBaseline, IBaselineConfig, IBaselineCycles, IMakeUpAndBlowDownWaterMeter, IMeasurementReadings, IOperational, IOperationalConfiguration, IOperationalProp, IPropertyBag, IReadings, ISaveTvdConfig, ITarget, ITvdDetails } from "../_contracts/ITvdDetails";

export class TvdDetails implements ITvdDetails {
    baselineCycles: BaselineCycles = new BaselineCycles();
    MakeUpAndBlowDownWaterMeter: MakeUpAndBlowDownWaterMeter = new MakeUpAndBlowDownWaterMeter();
    cost: OperationalConfiguration;
    blowdownCost: OperationalConfiguration;
    measurementReadings: Readings = new Readings();
    constructor(tvdDetails?: ITvdDetails) {
        this.baselineCycles = tvdDetails?.baselineCycles || new BaselineCycles();
        this.MakeUpAndBlowDownWaterMeter = tvdDetails?.MakeUpAndBlowDownWaterMeter || new MakeUpAndBlowDownWaterMeter();
        this.cost = tvdDetails?.cost || new OperationalConfiguration(-1, -1, 0, 0, 0, -1, -1);
        this.blowdownCost = tvdDetails?.blowdownCost || new OperationalConfiguration(-1, -1, 0, 0, 0, -1, -1);
        this.measurementReadings = tvdDetails?.measurementReadings || new Readings();
    }
}

export class BaselineCycles implements IBaselineCycles {
    effectiveDate: Date;
    baseline: number;
    target: number;
    constructor(baselineCycles?: IBaselineCycles) {
        this.effectiveDate = baselineCycles?.effectiveDate;
        this.baseline = baselineCycles?.baseline;
        this.target = baselineCycles?.target;
    }
}

export class MakeUpAndBlowDownWaterMeter implements IMakeUpAndBlowDownWaterMeter {

    hasBlowdownWaterMeter?: boolean;
    hasMakeUpWaterMeter: boolean;
    makeUpWaterMeterLocation?: IOperationalConfiguration;
    blowDownWaterMeterLocation?: IOperationalConfiguration;
    towerConductivitySource?: IOperationalConfiguration;
    makeUpConductivitySource?: IOperationalConfiguration;

    constructor(makeUpAndBlowDownWaterMeterSettings?: IMakeUpAndBlowDownWaterMeter) {
        this.hasMakeUpWaterMeter = makeUpAndBlowDownWaterMeterSettings?.hasMakeUpWaterMeter;
        this.hasBlowdownWaterMeter = makeUpAndBlowDownWaterMeterSettings?.hasBlowdownWaterMeter;
        this.makeUpWaterMeterLocation = makeUpAndBlowDownWaterMeterSettings?.makeUpWaterMeterLocation;
        this.blowDownWaterMeterLocation = makeUpAndBlowDownWaterMeterSettings?.blowDownWaterMeterLocation;
        this.towerConductivitySource = makeUpAndBlowDownWaterMeterSettings?.towerConductivitySource;
        this.makeUpConductivitySource = makeUpAndBlowDownWaterMeterSettings?.makeUpConductivitySource;
    }
}

export class OperationalConfiguration {
    cpId: number = -1;
    measurementId: number = 0;
    stuiId: number = 0;
    stuiTypeId: number = 0;
    value = null;
    measurementSource: number = -1;
    originType: number = -1;
    measurementInstanceId: number = -1;

    constructor(cpId: number, measurementInstanceId: number, measuermentId: number, stuiId: number, stuiTypeId: number, measurementSource: number, originType: number) {
        this.cpId = cpId;
        this.measurementId = measuermentId;
        this.stuiId = stuiId;
        this.stuiTypeId = stuiTypeId;
        this.value = null;
        this.measurementSource = measurementSource;
        this.originType = originType;
        this.measurementInstanceId = measurementInstanceId;
    }
}


export class Readings implements IReadings {
    entryDate: Date;
    entryTime: Date;
    msrReadings: MeasurementReadings[] = [];
    constructor(readings?: IReadings) {
        this.entryDate = readings?.entryDate;
        this.entryTime = readings?.entryTime;
        if (readings?.msrReadings) {
            readings?.msrReadings.forEach(msrReading => {
                this.msrReadings.push(new MeasurementReadings(msrReading));
            });
        }
    }
}

export class MeasurementReadings implements IMeasurementReadings {

    measurementName: string;
    displayName: string;
    unit: string;
    value: number;
    measurementId: number;
    measurementDate?: Date;
    sourceType?: number;
    toNalcoNumericsUnitOrSpecies?: string;
    waterMeterInstalled?: boolean;
    unitSymbol?: string;
    measurementInstanceId: number;
    constructor(msrReadings?: IMeasurementReadings) {
        this.measurementName = msrReadings?.measurementName;
        this.displayName = msrReadings?.displayName;
        this.unit = msrReadings?.unit;
        this.value = msrReadings?.value;
        this.measurementId = msrReadings?.measurementId;
        if (msrReadings.measurementDate) {
            this.measurementDate = msrReadings.measurementDate;
        }
        if (msrReadings.sourceType) {
            this.sourceType = msrReadings.sourceType;
        }
        this.toNalcoNumericsUnitOrSpecies = msrReadings?.toNalcoNumericsUnitOrSpecies;
        this.waterMeterInstalled = msrReadings?.waterMeterInstalled;
        this.unitSymbol = msrReadings?.unitSymbol;
        this.measurementInstanceId = msrReadings?.measurementInstanceId;
    }
}

export class PropertyBag implements IPropertyBag {
    cycles: string;

    constructor(propertyBagData?: IPropertyBag) {
        this.cycles = propertyBagData?.cycles;
    }
}

export class OperationalProp implements IOperationalProp {
    contextPointId: number;
    measurementId: number;
    stuiTypeId: number;
    stuiId: number;
    measurementSource: number;
    originType: number;
    measurementInstanceId = null;

    constructor(contextPointId, measurementInstanceId, measurementId, stuiTypeId, stuiId, measurementSource, originType) {
        this.contextPointId = contextPointId;
        this.measurementId = measurementId;
        this.stuiTypeId = stuiTypeId;
        this.stuiId = stuiId;
        this.measurementSource = measurementSource;
        this.originType = originType;
        this.measurementInstanceId = measurementInstanceId;
    }
}

export class Operational implements IOperational {
    makeupMeterFlow?: IOperationalProp;
    blowdownMeterFlow?: IOperationalProp;
    makeupWaterConductivity?: IOperationalProp;
    towerConductivity?: IOperationalProp;
    recirculationRate?: IOperationalProp;
    supplyTemperature?: IOperationalProp;
    returnTemperature?: IOperationalProp;
    evaporationEfficiency?: IOperationalProp;
    makeupWaterCost?: IOperationalProp;
    sewerTreatmentCost?: IOperationalProp

    constructor(operationalData?: IOperational) {
        if (this.makeupMeterFlow) {
            this.makeupMeterFlow = operationalData?.makeupMeterFlow;
        }
        if (this.blowdownMeterFlow) {
            this.blowdownMeterFlow = operationalData?.blowdownMeterFlow;
        }
        if (this.makeupWaterConductivity) {
            this.makeupWaterConductivity = operationalData?.makeupWaterConductivity;
        }
        if (this.towerConductivity) {
            this.towerConductivity = operationalData?.towerConductivity;
        }
        if (this.recirculationRate) {
            this.recirculationRate = operationalData?.recirculationRate;
        }
        if (this.supplyTemperature) {
            this.supplyTemperature = operationalData?.supplyTemperature;
        }
        if (this.returnTemperature) {
            this.returnTemperature = operationalData?.returnTemperature;
        }
        if (this.evaporationEfficiency) {
            this.evaporationEfficiency = operationalData?.evaporationEfficiency;
        }
        if (this.makeupWaterCost) {
            this.makeupWaterCost = operationalData?.makeupWaterCost;
        }
        if (this.sewerTreatmentCost) {
            this.sewerTreatmentCost = operationalData?.sewerTreatmentCost;
        }
    }
}


export class Baseline implements IBaseline {
    effectiveDate: Date;
    propertyBag: IPropertyBag;

    constructor(baselineData?: IBaseline) {
        this.effectiveDate = baselineData?.effectiveDate;
        this.propertyBag = baselineData?.propertyBag;
    }
}

export class Target implements ITarget {
    propertyBag: IPropertyBag;

    constructor(targetData?: ITarget) {
        this.propertyBag = targetData?.propertyBag;
    }
}

export class BaselineConfig implements IBaselineConfig {
    contextPointId: number;
    measurementId: number;
    baseline: IBaseline;
    target: ITarget;
    constructor(contextPointId: number, measurementId: number, baselineData?: IBaseline, target?: ITarget) {
        this.contextPointId = contextPointId;
        this.measurementId = measurementId;
        this.baseline = baselineData;
        this.target = target;
    }
}

export class SaveTvdConfig implements ISaveTvdConfig {
    id: string;
    workIdentifier: string;
    partitionKey: string;
    contextPointId: number;
    contextPointTypeId: number;
    contextPointSubTypeId: number;
    createdBy: number;
    modifiedBy: number;
    createdOn: Date;
    modifiedOn: Date;
    baselineConfig: IBaselineConfig;
    operational: IOperational;
    isScheduled: boolean;
    updatedBaseline: boolean;

    constructor(tvdConfigData?: ISaveTvdConfig) {
        this.id = tvdConfigData?.id;
        this.workIdentifier = tvdConfigData?.workIdentifier;
        this.partitionKey = tvdConfigData?.partitionKey;
        this.contextPointId = tvdConfigData?.contextPointId;
        this.contextPointTypeId = tvdConfigData?.contextPointTypeId;
        this.contextPointSubTypeId = tvdConfigData?.contextPointSubTypeId;
        this.createdBy = tvdConfigData?.createdBy;
        this.modifiedBy = tvdConfigData?.modifiedBy;
        this.createdOn = tvdConfigData?.createdOn;
        this.modifiedOn = tvdConfigData?.modifiedOn;
        this.baselineConfig = tvdConfigData?.baselineConfig;
        this.operational = tvdConfigData?.operational ? tvdConfigData?.operational : {};
        this.isScheduled = tvdConfigData?.isScheduled;
        this.updatedBaseline = tvdConfigData?.updatedBaseline;
    }
}
