import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'noComma'
})
export class NoCommaPipe implements PipeTransform {
  
  transform(value: any): any {
    if (value != null && value != undefined && value != "") {
      return value.toString().replace(/,/g, "");
    } else {
      return "";
    }
  }
}