import { Component, OnInit, Input, Output, ViewContainerRef, ViewChild, TemplateRef } from '@angular/core';
import { XdComponentState } from './xd-component';
//import { OnChanges } from '@angular/core/src/metadata/lifecycle_hooks';
import { ComponentState } from './xd-component-state';
//import { ElementRef } from '@angular/core/src/linker/element_ref';
import { trigger, transition, style, animate } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { DGSNSupport } from '../../../../library/sharedDVLibrary/angular-shared-component/projects/angular-v15/src/lib/shared/enum/constants';

export interface XdContainerConfig {
  emptyIconClass: string;
  loadingIconClass: string;
  errorIconClass: string;

  emptyTitleClass: string;
  loadingTitleClass: string;
  errorTitleClass: string;

  emptyMessageClass: string;
  loadingMessageClass: string;
  errorMessageClass: string;

  errorTemplate: TemplateRef<any>;
  loadingTemplate: TemplateRef<any>;
  emptyTemplate: TemplateRef<any>;

  emptyTitleText: string;
  loadingTitleText: string;
  errorTitleText: string;

  emptyMessageText: string;
  loadingMessageText: string;
  errorMessageText: string;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'xd-container',
  templateUrl: './xd-container.component.html',
  styleUrls: ['./xd-container.component.scss', './xd-container-customizable-classes.scss'],
  providers: [XdComponentState],
  animations: [
    trigger(
      'stateChangeAnimation',
      [
        transition(
          ':enter', [
            style({ 'opacity': 0 }),
            animate('700ms', style({ 'opacity': 1 }))
          ]
        ),
        transition(
          ':leave', [
            style({ 'opacity': 1 }),
            animate('700ms', style({ 'opacity': 0 }))
          ]
        )]
    ),
  ],
})
export class XdContainerComponent implements OnInit {

  // protected property for internal use
  // this will be automatically set via super class
  state: ComponentState;
  parent: XdComponentState;
  minHeight: '100%';

  private emptyText = this.translate.instant('COMMON.EMPTY_TITLE_TEXT');
  private loadingText = this.translate.instant('COMMON.LOADING_TITLE_TEXT');
  private errorText: string = this.translate.instant('COMMON.ERROR_TITLE_TEXT', { supportEmail: DGSNSupport.Email });
  private emptyMsgText = this.translate.instant('COMMON.EMPTY_MESSAGE_TEXT');
  private loadingMsgText = this.translate.instant('COMMON.LOADING_MESSAGE_TEXT');
  private errorMsgText = this.translate.instant('COMMON.ERROR_MESSAGE_TEXT');

  // commenting these lines as we are not using font awesome
  // @Input('empty-icon-class') emptyIconClass: string = 'fa fa-info-circle';
  // @Input('loading-icon-class') loadingIconClass: string = 'fa fa-spinner fa-spin';
  // @Input('error-icon-class') errorIconClass: string = 'fa fa-exclamation-triangle';

  @Input('empty-icon-class') emptyIconClass: string = '';
  @Input('loading-icon-class') loadingIconClass: string = '';
  @Input('error-icon-class') errorIconClass: string = '';

  @Input('empty-title-class') emptyTitleClass: string = 'state-title';
  @Input('loading-title-class') loadingTitleClass: string = 'state-title';
  @Input('error-title-class') errorTitleClass: string = 'state-title';

  @Input('empty-title-text') emptyTitleText = this.emptyText;
  @Input('loading-title-text') loadingTitleText = this.loadingMsgText;
  @Input('error-title-text') errorTitleText = this.errorText;

  @Input('empty-message-class') emptyMessageClass: string = 'state-message ';
  @Input('loading-message-class') loadingMessageClass: string = 'state-message';
  @Input('error-message-class') errorMessageClass: string = 'state-message ';

  @Input('empty-message-text') emptyMessageText = this.emptyMsgText;
  @Input('loading-message-text') loadingMessageText = this.loadingText;
  @Input('error-message-text') errorMessageText = this.errorMsgText;

  @Input('error-template') errorTemplate: TemplateRef<any>;
  @Input('loading-template') loadingTemplate: TemplateRef<any>;
  @Input('empty-template') emptyTemplate: TemplateRef<any>;


  constructor(private viewContainerRef: ViewContainerRef, private translate: TranslateService) {
    if (this.viewContainerRef['_data'].componentView.parent.component) {
      this.parent = this.viewContainerRef['_data'].componentView.parent.component;
    }
  }

  ngOnInit() {
      // commenting these lines as we are not using font awesome
    // this.emptyIconClass = this.emptyIconClass && this.emptyIconClass ? this.emptyIconClass : 'fa fa-info-circle';
    // this.loadingIconClass = this.loadingIconClass && this.loadingIconClass ? this.loadingIconClass : 'fa fa-spinner fa-spin';
    // this.errorIconClass = this.errorIconClass && this.errorIconClass ? this.errorIconClass : 'fa fa-exclamation-triangle';

    this.emptyIconClass = this.emptyIconClass ? this.emptyIconClass : '';
    this.loadingIconClass = this.loadingIconClass ? this.loadingIconClass : '';
    this.errorIconClass =  this.errorIconClass ? this.errorIconClass : '';

    this.emptyTitleClass =  this.emptyTitleClass ? this.emptyTitleClass : 'state-title';
    this.loadingTitleClass =  this.loadingTitleClass ? this.loadingTitleClass : 'state-title';
    this.errorTitleClass =  this.errorTitleClass ? this.errorTitleClass : 'state-title';

    this.emptyMessageClass =  this.emptyMessageClass ? this.emptyMessageClass : 'state-message';
    this.loadingMessageClass =  this.loadingMessageClass ? this.loadingMessageClass : 'state-message';
    this.errorMessageClass =  this.errorMessageClass ? this.errorMessageClass : 'state-message';

    this.emptyTitleText =  this.emptyTitleText ? this.emptyTitleText : this.emptyText;
    this.loadingTitleText =  this.loadingTitleText ? this.loadingTitleText : this.loadingMsgText;
    this.errorTitleText =  this.errorTitleText ? this.errorTitleText : this.errorText;

    // eslint-disable-next-line max-len
    this.emptyMessageText =  this.emptyMessageText ? this.emptyMessageText : this.emptyMsgText;
    this.loadingMessageText =  this.loadingMessageText ? this.loadingMessageText : this.loadingText;
    // eslint-disable-next-line max-len
    this.errorMessageText =  this.errorMessageText ? this.errorMessageText : this.errorMsgText;
  }

}

