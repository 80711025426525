<form [formGroup]="assetDetailsForm" #f="ngForm" class="actionLogGridDetails"
  [ngClass]="isTVDEnabled ? 'actionValueTabDetails' : ''">
  <ng-container *ngIf="isTVDEnabled; else actionTabOutlet">
    <div class="admin-button-spacing">
      <button type="button" class="float-right btn btn-primary" *ngIf="isFIScreen"
        (click)="cancelRowChanges(true)"><span translate>COMMON.CLOSE</span>
      </button>
      <button type="button" class="float-right btn btn-secondary" *ngIf="!isFIScreen"
        (click)="cancelRowChanges(true)"><span translate>COMMON.CANCEL</span>
      </button><!--change cancel to close for FI screen-->
      <button type="button" *ngIf="mode === 'add'"
      [disabled]="!assetDetailsForm.valid || !valueDetailsForm.valid || !isSaveButtonEnabled"
      (click)="saveChanges(true)" class="float-right adminbtn btn btn-primary" translate>COMMON.SAVE</button>
      <button type="button" *ngIf="mode === 'edit' && !isFIScreen"
        [disabled]="!isActionTakenPresent || !assetDetailsForm.valid || !valueDetailsForm.valid || !isSaveButtonEnabled"
        (click)="saveChanges(true)" class="float-right adminbtn btn btn-primary" translate>COMMON.SAVE</button>
    </div>
    <ngb-tabset [destroyOnHide]="false" #tabset="ngbTabset" class="site-nav col-md-12 tabs"
      (tabChange)="tabChanged($event)" [activeId]="activeTabId">
      <ngb-tab id="action-details" ngbTabTitle title="{{'ACTION_LOG.ACTION_DETAILS' | translate}}">
        <ng-template ngbTabContent>
          <ng-container *ngTemplateOutlet="actionTabOutlet"></ng-container>
        </ng-template>
      </ngb-tab>
      <ngb-tab id="value-details" ngbTabTitle title="{{'ACTION_LOG.ACTION_LOG_FINANCIAL' | translate}}">
        <ng-template ngbTabContent>
          <div class="row">
            <div class="col-lg-8">
              <div class="container" [formGroup]="valueDetailsForm">
                <button class="add-remove-eroi-button btn"
                  [disabled]="savings.controls.length >= 5 || !tvdWritePermission || isFIScreen"
                  (click)="addSavingRow()">{{'ACTION_LOG.ADD_MORE_CATEGORIES' |
                  translate}}</button>
                <div class="row mb-3">
                  <div class="col-lg-3 col-sm-12">
                    <label class="col-form-label mr-2" translate>ACTION_LOG.HEADER.TOTAL_SAVINGS<span>:</span></label>
                    <ng-container *ngIf="getTotalSavings !== ''">
                      <span style="display:inline-block">{{selectedDisplayCurrency?.Symbol}}</span>
                      <span>{{getTotalSavings}} {{selectedDisplayCurrency?.CurrencyCodeId}}</span>
                    </ng-container>
                  </div>
                  <div class="col-lg-5 col-sm-12">
                    <div class="row adjust">
                      <div class="col-sm-6">
                        <label for="eroiCategoryType" class="col-form-label eroiCategory"
                          translate>ACTION_LOG.ACCOUNT_DETAILS<span class="required"
                            *ngIf="this.selectedEroi.length > 0">*</span></label>
                        <span class="ico-info-thin" placement="bottom" data-html="true"
                           [ngbTooltip]='tipContent'></span>
                        <ng-template #tipContent>
                          <div [innerHTML]="'ACTION_LOG.INFO.ACCOUNT_DETAIL_INFO' | translate"></div>
                        </ng-template>
                      </div>
                      <div class="col-sm-6">
                        <select class="form-control eroifields" formControlName="AccountDetails"
                          [ngClass]="{'disable-eroi-fields': isFIScreen || !tvdWritePermission}">
                          <option value="null" disabled selected>Select</option>
                          <option *ngFor="let item of accountDetails" value="{{item.ContextPointId}}">
                            {{item.ContextPointName}}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-12">
                    <div class="row">
                      <div class="col-sm-5 pr-2">
                        <label class="col-form-label" translate>ACTION_LOG.CURRENCY<span class="required"
                            *ngIf="this.selectedEroi.length > 0">*</span></label>
                      </div>
                      <div [ngClass]="fromEdata? 'col-sm-7': 'col-sm-5'">
                        <select class="form-control eroifields" formControlName="DisplayCurrency"
                          [ngClass]="{'disable-eroi-fields': isFIScreen || !tvdWritePermission}">
                          <option *ngFor="let item of currencyData" value="{{item.CurrencyCodeId}}">
                            {{item.CurrencyCodeId}}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="row">
                  <div class="form-group col-lg-3 col-sm-12">
                    <label for="eroiCategoryType" class="col-form-label eroiCategory"
                      translate>ACTION_LOG.HEADER.EROI_CATEGORY</label>
                    <span class="ico-info-thin" placement="bottom" data-html="true" [ngbTooltip]='tipContent1'
                      container="body"></span>
                    <ng-template #tipContent1>
                      <div [innerHTML]="'ACTION_LOG.INFO.EROI_CATEGORY_INFO' | translate"></div>
                    </ng-template>
                  </div>
                  <div class="form-group col-lg-3 col-sm-12">
                    <label for="savingsType" class="col-form-label headingClass"
                      translate>ACTION_LOG.HEADER.ANNUAL_SAVINGS_TYPE<span class="required"
                        *ngIf="this.selectedEroi.length > 0">*</span></label>
                    <span class="ico-info-thin" placement="bottom" data-html="true" [ngbTooltip]='tipContent2'
                      container="body"></span>
                    <ng-template #tipContent2>
                      <div [innerHTML]="'ACTION_LOG.INFO.SAVING_TYPE_INFO' | translate"></div>
                    </ng-template>
                    <!-- translate>ACTION_LOG.SAVINGS<span class="required" *ngIf="this.selectedEroi.length > 0">*</span></label> -->
                  </div>
                  <div class="form-group col-lg-3 col-sm-12">
                    <label for="totalAmountType" class="col-form-label headingClass" translate>ACTION_LOG.AMOUNT</label>
                  </div>
                  <div class="form-group col-lg-3 col-sm-12">
                    <label for="totalSavingsType" class="col-form-label headingClass" translate>ACTION_LOG.SAVINGS<span
                        class="required" *ngIf="this.selectedEroi.length > 0">*</span></label>
                  </div>
                </div>
                <ng-container formArrayName="Savings">
                  <ng-container *ngFor="let saving of savings.controls; let savingIndex=index">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="row" [formGroupName]="savingIndex">
                          <div class="form-group col-lg-3 col-sm-12">
                            <select class="form-control eroifields ellipsis" formControlName="ERoiTypeId"
                              placement='bottom' [attr.disabled]="isFIScreen"
                              [ngClass]="{'disable-background-color': isFIScreen, 'disable-eroi-fields': !tvdWritePermission}"
                              (change)="eroiCategoryChanged(savingIndex)">
                              <option *ngFor="let item of eroiTypes" value="{{item.ERoiTypeId}}"
                                [disabled]="isEroiCategorySelected(item.ERoiTypeId)">{{item.ERoiTypeName}}
                              </option>
                            </select>
                          </div>
                          <div class="form-group col-lg-3 col-sm-12">
                            <select class="form-control eroifields" formControlName="CategoryTypeId"
                              [ngClass]="{'disable-eroi-fields': isFIScreen || !tvdWritePermission}">
                              <option *ngFor="let item of savingCategoryTypesData"
                                value="{{item.SavingCategoryTypeId}}">
                                {{item.Description}}
                              </option>
                            </select>
                          </div>

                          <div class="form-group col-lg-3 col-sm-12">
                            <div class="row">
                              <div class="col-sm-7 pr-1">
                                <input type="text" class="form-control eroifields" formControlName="AmountInDisplayUnit"
                                  (input)="validateDecimalPrecision($event, savingIndex)"
                                  [ngClass]="{'disable-eroi-fields': isFIScreen || !tvdWritePermission}">
                              </div>
                              <div class="col-sm-5 pl-1">
                                <select class="form-control eroifields" formControlName="DisplayUnit"
                                  [ngClass]="{'disable-eroi-fields': isFIScreen || !tvdWritePermission}"
                                  (change)="enableUnitAmountField($event.target.value,saving)">
                                  <option *ngFor="let unit of selectedEroi[savingIndex]?.eroiUnitMappings"
                                    value="{{ unit.displayUnit }}">{{unit.unitSymbol? unit.unitSymbol : '-'}}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="form-group col-lg-2 col-sm-12">
                            <div class="row">
                              <div class="col-sm-12 pr-1">
                                <input type="text" (input)="validateWholeNumbers($event, savingIndex)" min="0"
                                  class="form-control eroifields" formControlName="MonetaryAmountInDisplayUnit"
                                  [ngClass]="{'disable-eroi-fields': isFIScreen || !tvdWritePermission}">
                              </div>
                            </div>
                          </div>
                          <div class="form-group col-lg-1 col-sm-12">
                            <button class="btn add-remove-eroi-button" (click)="removeSavingRow(savingIndex)"
                              [disabled]="isFIScreen || !tvdWritePermission" translate>
                              <img alt="delete" class="remove-saving-icon" src="../../../assets/images/delete-icon.svg">
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
            <div *ngIf="!isFIScreen && tvdWritePermission" class="col-lg-4">
              <div class="row">
                <div class="industry-norms" translate (click)="showIndustryNorms = true">
                  ACTION_LOG.INDUSTRY_NORMS.QUICK_REFERENCE
                  <span class="ico-info-thin industry-norms-info-icon" placement="bottom" data-html="true"
                    [ngbTooltip]='tipContent3' container="body"></span>
                </div>

                <ng-template #tipContent3>
                  <table class="assumptions-table">
                    <tr>
                      <th colspan="2" translate>ACTION_LOG.INDUSTRY_NORMS.ASSUMPTIONS</th>
                    </tr>
                    <tr>
                      <th translate>ACTION_LOG.INDUSTRY_NORMS.WATER_IN_OUT</th>
                      <td>10.18 USD per 1000 gal</td>
                    </tr>
                    <tr>
                      <th translate>ACTION_LOG.INDUSTRY_NORMS.AMBIENT_WATER_TEMP</th>
                      <td>70 deg F</td>
                    </tr>
                    <tr>
                      <th translate>ACTION_LOG.INDUSTRY_NORMS.HOT_WATER_TEMP</th>
                      <td>200 deg F</td>
                    </tr>
                    <tr>
                      <th translate>ACTION_LOG.INDUSTRY_NORMS.CHILLED_WATER_TEMP</th>
                      <td>45 deg F</td>
                    </tr>
                    <tr>
                      <th translate>ACTION_LOG.INDUSTRY_NORMS.FUEL_SOURCE</th>
                      <td translate>ACTION_LOG.INDUSTRY_NORMS.NATURAL_GAS</td>
                    </tr>
                    <tr>
                      <th translate>ACTION_LOG.INDUSTRY_NORMS.HEATER_EFFICIENCY</th>
                      <td>80%</td>
                    </tr>
                    <tr>
                      <th translate>ACTION_LOG.INDUSTRY_NORMS.ELECTRICITY_COST</th>
                      <td>0.08 USD/kWh</td>
                    </tr>
                    <tr>
                      <th translate>ACTION_LOG.INDUSTRY_NORMS.THERMAL_COST</th>
                      <td>7.21 USD per MMBtu</td>
                    </tr>
                    <tr>
                      <th>CO2</th>
                      <td>0.06 tons CO2 per MMBtu<br>0.0054 tons CO per kWH</td>
                    </tr>
                    <tr>
                      <th colspan="2"><span translate>ACTION_LOG.INDUSTRY_NORMS.FINANCE_CONVERSION</span> : 1 USD = 0.95
                        Euro</th>
                    </tr>
                  </table>
                </ng-template>
              </div>
              <table class="monetization-table" *ngIf="showIndustryNorms">
                <tr>
                  <th colspan="2" translate>ACTION_LOG.INDUSTRY_NORMS.ANNUALIZED_SAVINGS</th>
                  <th colspan="2" translate>ACTION_LOG.INDUSTRY_NORMS.HOT_WATER_LEAK</th>
                  <th colspan="2" translate>ACTION_LOG.INDUSTRY_NORMS.CHILLED_WATER_LEAK</th>
                </tr>
                <tr>
                  <td rowspan="3">1.0 gal/min</td>
                  <td translate>ACTION_LOG.WATER</td>
                  <td>$ 5,350</td>
                  <td>526,000 gal</td>
                  <td>$ 5,350</td>
                  <td>526,000 gal</td>
                </tr>
                <tr>
                  <td translate>ACTION_LOG.THERMAL_OR_ELECTRICAL_ENERGY</td>
                  <td>$ 5,141</td>
                  <td>713 MMBtu</td>
                  <td>$ 511</td>
                  <td>32,100 kWh</td>
                </tr>
                <tr>
                  <td>CO2</td>
                  <td colspan="2">41 tons</td>
                  <td colspan="2"> 17.3 tons</td>
                </tr>
                <tr>
                  <td rowspan="3">1.0 m3/hr</td>
                  <td translate>ACTION_LOG.WATER</td>
                  <td>€ 24,842</td>
                  <td>8,760 m3</td>
                  <td>€ 24,842</td>
                  <td>8,760 m3</td>
                </tr>
                <tr>
                  <td translate>ACTION_LOG.THERMAL_OR_ELECTRICAL_ENERGY</td>
                  <td>€ 23,789</td>
                  <td>2646 GJ/yr</td>
                  <td>€ 2,138</td>
                  <td>141,000 kWh</td>
                </tr>
                <tr>
                  <td>CO2</td>
                  <td colspan="2">182 tons</td>
                  <td colspan="2"> 76.3 tons</td>
                </tr>
              </table>
              <div class="row" style="margin: 1rem 0;" *ngIf="showIndustryNorms">
                <div class="col-lg-8 col-sm-6"><a target="_blank"
                    href="https://ecolab.sharepoint.com/sites/CORE-CoreWater/Shared%20Documents/Forms/KCLibrary.aspx?id=%2fsites%2fCORE-CoreWater%2fShared%20Documents%2fBk%20300%20Industry%20Norms.pdf&parent=%2fsites%2fCORE-CoreWater%2fShared%20Documents">Industry
                    Norms Link Bk-300</a></div>
                <div class="col-lg-4 col-sm-6">
                  <button type="button" class="float-right btn btn-primary" (click)="showIndustryNorms = false"><span
                      translate>COMMON.CLOSE</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ngb-tab>
    </ngb-tabset>
  </ng-container>

  <ng-template #actionTabOutlet>
    <div class="container">
      <div class="row" [ngClass]="fromEdata? 'adj':''">
        <div class="col-md-3 first-column">
          <div class="form-group row">
            <label for="site" class="col-sm-4 col-form-label headingClass" translate>ACTION_LOG.HEADER.SITE<span
                class="required">*</span></label>
            <div class="col-sm-8"
              *ngIf="mode == 'add' && ((hostView === 'actionlog' && filterCriteria.siteName == '')|| (hostView === 'SSD' && !isSiteView))">
              <nalco-select-dropdown [SearchType]="'server'" [list]="SiteDropdownData"
                placeholder="{{'DATA_VISUALIZATION.SELECT_1_SITE' | translate}}" [showLoader]="sitesLoading"
                [multiSelection]="false" [noRecoredsLabel]="'DATA_VISUALIZATION.NO_MATCHING_SITES' | translate"
                [displayOnInit]="selectedSiteOnInit" (itemSelected)="loadAsset($event)"
                (getSelectedItems)="selectedSite()" (filterText)="filterSiteText($event)" (lazyLoading)="fetchMore()">
              </nalco-select-dropdown>
            </div>
            <div class="col-sm-8" *ngIf="mode == 'edit'">
              <ng-template #tipContent>
                {{actionLog.SiteName}}
              </ng-template>
              <span class="form-control" [ngbTooltip]="actionLog.SiteName.length > 0 ? tipContent : '' "
                #tt="ngbTooltip" triggers="manual" (mouseover)="tt.open()" (mouseleave)="tt.close()" container="body"
                (click)="tt.open()">{{actionLog.SiteName}}</span>
            </div>
            <div class="col-sm-8"
              *ngIf="mode == 'add' && (hostView === 'siteView' || hostView === 'inventory' || (hostView === 'SSD' && isSiteView) || hostView === 'systemView')">
              <ng-template #tipContent>
                {{contextPointData.siteName}}
              </ng-template>
              <span class="form-control siteName" [ngbTooltip]="contextPointData.siteName.length > 0 ? tipContent : '' "
                #tt="ngbTooltip" triggers="manual" (mouseover)="tt.open()" (mouseleave)="tt.close()" container="body"
                (click)="tt.open()">{{contextPointData.siteName}}</span>
              <!-- <input value="{{contextPointData.siteName}}" class="form-control" readonly /> -->
            </div>
            <div class="col-sm-8" *ngIf="mode == 'add' && (hostView === 'actionlog' && filterCriteria.siteName !== '')">
              <ng-template #tipContent>
                {{filterCriteria.siteName}}
              </ng-template>
              <span class="form-control siteName" [ngbTooltip]="filterCriteria.siteName.length > 0 ? tipContent : '' "
                #tt="ngbTooltip" triggers="manual" (mouseover)="tt.open()" (mouseleave)="tt.close()" container="body"
                (click)="tt.open()">{{filterCriteria.siteName}}</span>
            </div>
          </div>
          <div class="form-group row">
            <label for="asset" class="col-sm-4 col-form-label headingClass" translate>ASSET_TREE.ASSET<span
                class="required">*</span></label>
            <div class="col-sm-8" *ngIf="mode == 'add' && !fromEdata">
              <nalco-select-dropdown formControlName="assetName" class="assetClass" [isDisable]="isDisable"
                [showLoader]="assetsLoading" [displayOnInit]="selectedAssetOnInit"
                (itemSelected)="onAssetSelection($event)" (getSelectedItems)="selectedAsset()"
                [noMatchingRecoredsLabel]="'DATA_VISUALIZATION.NO_ASSET_AVAILABLE' | translate"
                [noRecoredsLabel]="'DATA_VISUALIZATION.NO_MATCHING_ASSETS' | translate" [multiSelection]="false"
                [list]="assetDataDropdown" placeholder="{{'DATA_VISUALIZATION.SELECT_1_ASSET' | translate}}">
              </nalco-select-dropdown>
            </div>
            <div class="col-sm-8" *ngIf="mode == 'edit'">
              <ng-template #tipContent>
                {{actionLog.ContextPointName}}
              </ng-template>
              <span class="form-control" [ngbTooltip]="actionLog.ContextPointName.length > 0 ? tipContent : '' "
                #tt="ngbTooltip" triggers="manual" (mouseover)="tt.open()" (mouseleave)="tt.close()" container="body"
                (click)="tt.open()">{{actionLog.ContextPointName}}</span>
              <!-- <input value="{{actionLog.ContextPointName}}" class="form-control" readonly /> -->
            </div>
            <div class="col-sm-8"
              *ngIf="mode == 'add' && (hostView === 'siteView' || hostView === 'inventory' || (hostView === 'SSD' && isSiteView) || hostView === 'systemView') && fromEdata">
              <ng-template #tipContent>
                {{defaultAssetname}}
              </ng-template>
              <form [formGroup]="assetDetailsForm" *ngIf="fromEdata">
                <input type="text" formControlName="assetName" [hidden]="true" readonly>
              </form>
              <span class="form-control siteName" [ngbTooltip]="contextPointData.siteName.length > 0 ? tipContent : '' "
                #tt="ngbTooltip" triggers="manual" (mouseover)="tt.open()" (mouseleave)="tt.close()" container="body"
                (click)="tt.open()">{{defaultAssetname}}</span>
              <!-- <input value="{{contextPointData.siteName}}" class="form-control" readonly /> -->
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label headingClass" translate>QUALITY.COLUMN_TITLE.SOURCE<span
                class="required">*</span></label>
            <div class="col-sm-8" *ngIf="mode == 'edit' || mode == 'add'">
              <span class="form-control " [ngClass]="{'disable-background-color': isFIScreen }"
                readonly>{{getSourceApplication(actionLog.SourceApplicationTypeId)}}</span>
            </div>
          </div>
          <div class="form-group row">
            <label for="problem" class="col-sm-4 col-form-label headingClass" translate>ACTION_LOG.HEADER.PROBLEM<span
                class="required">*</span></label>
            <div class="col-sm-8">
              <ng-template #tipContent1>
                {{actionLog.Problem}}
              </ng-template>
              <textarea *ngIf="mode == 'edit'" rows="4" class="form-control "
                [ngClass]="{'disable-background-color': isFIScreen }"
                [ngbTooltip]="actionLog.Problem.length > 0 ? tipContent1 : '' " #tt="ngbTooltip" triggers="manual"
                (mouseover)="tt.open()" maxlength="512" (mouseleave)="tt.close()" container="body" (click)="tt.open()"
                readonly>{{actionLog.Problem}}</textarea>
              <!-- <input [value]="actionLog.Problem" formControlName="Problem" *ngIf="mode == 'edit'" class="form-control"
                  readonly /> -->
              <textarea formControlName="Problem" *ngIf="mode == 'add'" rows="4" maxlength="512" class="form-control"
                id="problem" rows="3"></textarea>
            </div>
          </div>
          <div class="form-group row">
            <label for="recAction" class="col-sm-4 col-form-label headingClass"
              translate>ACTION_LOG.HEADER.RECOMMENDED_ACTION</label>
            <div class="col-sm-8">
              <ng-template #tipContent>
                {{actionLog.Recommendation}}
              </ng-template>
              <textarea formControlName="RecommendedAction" [value]="actionLog.Recommendation" rows="4" maxlength="512"
                [ngbTooltip]=" actionLog.Recommendation && (actionLog.Recommendation.length  > 0) ? tipContent : '' "
                #tt="ngbTooltip" triggers="manual" (mouseover)="tt.open()" (mouseleave)="tt.close()" container="body"
                (click)="tt.open()" *ngIf="mode == 'edit'" class="form-control"
                [readonly]="isFIScreen || isAIgeneratedRecommendation"
                [ngClass]="{'disable-background-color': isFIScreen }">
              </textarea>
              <textarea formControlName="RecommendedAction" *ngIf="mode == 'add'" rows="4" class="form-control"
                maxlength="512" id="recAction" rows="3"></textarea>
            </div>
          </div>
          <div class="form-group row">
            <label for="actionStatus" class="col-sm-4 col-form-label headingClass"
              translate>ACTION_LOG.HEADER.ACTION_STATUS</label>
            <div class="col-sm-8" *ngIf="mode == 'add'">
              <select class="form-control" formControlName="actionStatus">
                <option *ngFor="let item of actionStatusName" value="{{item.value}}">{{item.label | translate}}
                </option>
              </select>
            </div>
            <div class="col-sm-8" *ngIf="mode == 'edit'">
              <select class="form-control" [value]="actionLog.ActionStatusTypeName" formControlName="actionStatus"
                [attr.disabled]="isFIScreen" [ngClass]="{'disable-background-color': isFIScreen }">
                <option *ngFor="let item of actionStatusName" value="{{item.value}}">{{item.label | translate}}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row" *ngIf="!fromEdata">
            <label for="currentassetstatus" class="col-sm-4 col-form-label headingClass"
              translate>OMNI.PERFORMANCE.ASSET_STATUS</label>
            <div class="col-sm-8">
              <span *ngIf="mode == 'add'" [class]="getIconClass(selectedAssetDetails?.AssetPerformanceColor)">
              </span>
              <span *ngIf="mode == 'edit'" [class]="getIconClass(actionLog?.ContextPointStatus)"></span>
            </div>          
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group row">
            <label for="impact" class="col-sm-4 col-form-label headingClass" translate>ACTION_LOG.HEADER.IMPACT<span
                class="required">*</span></label>
            <div class="col-sm-8">
              <select class="form-control" [value]="actionLog.ActionImpactTypeName" formControlName="impact"
                [attr.disabled]="isFIScreen" [ngClass]="{'disable-background-color': isFIScreen }">
                <option *ngFor="let item of impactName" value="{{item.value}}">{{item.label | translate}}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label for="createdDate" class="col-sm-4 col-form-label headingClass"
              translate>ACTION_LOG.HEADER.RECOMMENDATION_DATE <span class="required">*</span></label>
            <div class="col-sm-8">
              <span *ngIf="mode === 'edit'" class="form-control">
                {{actionLog.RecommendedOn | dateFormat:true : false}}</span>
              <!-- <input formControlName="createdDate" *ngIf="mode === 'edit'"  class="form-control"
                value="{{actionLog.RecommendedOn | date:'dd/MM/yyyy'}}" readonly> -->
              <!-- <input formControlName="createdDate" *ngIf="mode === 'add'" type="date" class="form-control"> -->
              <kendo-datepicker class="w-100 customdatepicker" formControlName="createdDate"  [format]="dateFormat"
                *ngIf="mode === 'add'" [formatPlaceholder]="{
                  year: 'yyyy', month: 'mm', day: 'dd'
                }">
              </kendo-datepicker>
            </div>
          </div>
          <!-- added expected resolution date column -->
          <div class="form-group row">
            <label for="expectedResDate" class="col-sm-4 col-form-label headingClass"
              translate>ACTION_LOG.HEADER.EXPECTED_RESOLUTION_DATE</label>
            <div class="col-sm-8">
              <!-- <input formControlName="expectedResDate" type="date" class="form-control"> -->
              <kendo-datepicker class="w-100 customdatepicker" formControlName="expectedResDate"  [format]="dateFormat"
                [formatPlaceholder]="{
                  year: 'yyyy', month: 'mm', day: 'dd'
                }" [attr.disabled]="isFIScreen" [ngClass]="{'disable-background-color': isFIScreen }">
              </kendo-datepicker>
            </div>
            <span
              *ngIf="assetDetailsForm.get('expectedResDate').dirty && assetDetailsForm.errors && assetDetailsForm.errors?.dates "
              class="dateSelectionError" translate>
              ACTION_LOG.HEADER.DATE_CHECK_MESSAGE</span>
          </div>
          <div class="form-group row">
            <label for="resPerson" class="col-sm-4 col-form-label headingClass"
              translate>ACTION_LOG.HEADER.RESPONSIBLE_PERSON<span class="required">*</span></label>
            <div class="col-sm-8 search-user position-relative">
              <div class="search-icon"></div>
              <kendo-autocomplete *ngIf="mode == 'edit'" class="borderClass w-100" formControlName="responsiblePerson"
                [value]="parentUser?.FirstName + ' ' + parentUser?.LastName" [data]="usersList" [filterable]="true"
                [valueField]="'EmailAddress'" [placeholder]="'NOTIFICATION.FILTER.SEARCH_USER' | translate"
                (valueChange)="searchValueChange($event, 'parent')" [loading]="loadingUserName"
                (filterChange)="searchfilterChange($event, 'parent')"
                [ngbTooltip]="parentUser?.FirstName?.length > 0 ? tipContent : '' " #tt="ngbTooltip" triggers="manual"
                (mouseover)="tt.open()" (mouseleave)="tt.close()" container="body" (click)="tt.open()"
                [attr.disabled]="isFIScreen" [ngClass]="{'disable-background-color': isFIScreen }">
                <ng-template #tipContent>
                  {{parentUser.EmailAddress}}
                </ng-template>
                <ng-template kendoAutoCompleteItemTemplate let-dataItem>
                  <div>
                    <span>{{dataItem?.FirstName}} {{dataItem?.LastName}} </span>
                    <span>{{dataItem?.EmailAddress}}</span>
                  </div>
                </ng-template>
                <ng-template kendoAutoCompleteNoDataTemplate>
                  <h3>{{'COMMON.NO_MATCH_FOUND' | translate}}</h3>
                </ng-template>
              </kendo-autocomplete>
              <kendo-autocomplete *ngIf="mode == 'add'" formControlName="responsiblePerson" [data]="usersList"
                class="borderClass w-100" [filterable]="true"
                [disabled]="(hostView === 'actionlog' || hostView === 'SSD') && !selectedSiteDetails?.SiteID"
                [valueField]="'EmailAddress'" [placeholder]="'NOTIFICATION.FILTER.SEARCH_USER' | translate"
                (valueChange)="searchValueChange($event, 'parent')" [loading]="loadingUserName"
                (filterChange)="searchfilterChange($event, 'parent')">
                <ng-template kendoAutoCompleteItemTemplate let-dataItem>
                  <div>
                    <span>{{dataItem.FirstName}} {{dataItem.LastName}} </span>
                    <span>{{dataItem.EmailAddress}}</span>
                  </div>
                </ng-template>
                <ng-template kendoAutoCompleteNoDataTemplate>
                  <h3>{{'COMMON.NO_MATCH_FOUND' | translate}}</h3>
                </ng-template>
              </kendo-autocomplete>
            </div>
          </div>
          <div class="form-group row" *ngIf="mode == 'edit'">
            <label class="col-sm-4 col-form-label headingClass" translate>ACTION_LOG.HEADER.ACTION_CREATED_BY</label>
            <div class="col-sm-8 search-user position-relative">
              <!-- <div class="search-icon"></div> -->
                <span *ngIf = "!isAIGenerated" class="actionLog-CreatedBy" [ngbTooltip]="actionLog.CreatedByUser?.FirstName?.length > 0 ? tipContent : '' ">
                  {{actionLog.CreatedByUser.FirstName}} {{actionLog.CreatedByUser.LastName}}
                </span>
              <span  *ngIf = "isAIGenerated" class="iconwater-AiGenerated"></span>
              <ng-template #tipContent>
                {{actionLog.CreatedByUser.EmailAddress}}
              </ng-template>

            </div>
          </div>
          <div class="form-group row">
            <label for="resPerson" class="col-sm-4 col-form-label headingClass"
              translate>ACTION_LOG.HEADER.NOTIFY_WITH_EMAIL</label>
            <div class="col-sm-8 search-user position-relative">
              <input *ngIf="!isFIScreen" type="checkbox" formControlName="NotifyEmail"
                class="position-relative checkBoxPosition" [checked]="actionLog.EmailSentDate != null ? true : false"
                (change)="isNotifyChecked($event)" [ngClass]="{'disable-background-color': isFIScreen }">
              <input *ngIf="isFIScreen" type="checkbox" formControlName="NotifyEmail"
                class="position-relative checkBoxPosition" [checked]="actionLog.EmailSentDate != null ? true : false"
                (change)="isNotifyChecked($event)" [attr.disabled]="isFIScreen"
                [ngClass]="{'disable-background-color': isFIScreen }">
            </div>
          </div>
          <div class="form-group row">
            <label for="ccPersons" class="col-sm-4 col-form-label headingClass"
              translate>ACTION_LOG.HEADER.EMAIL_CC</label>

            <div class="col-sm-8 search-user position-relative">
              <kendo-multiselect #ccUserListBox [data]="listItems" formControlName="ccPersons"
                [valueField]="'EmailAddress'" [textField]="'EmailAddress'"
                [placeholder]="'NOTIFICATION.FILTER.SEARCH_USERS' | translate" [disabled]="!isChecked || isFIScreen"
                [ngClass]="{'disable-background-color': isFIScreen }" [filterable]="true"
                (valueChange)="ccSearchValueChange($event, 'parent')" [loading]="loadingCcUserName"
                (filterChange)="ccSearchfilterChange($event, 'parent')" (focus)="focus()" (focusout)="listItems = []">
                <ng-template kendoMultiSelectNoDataTemplate>
                  {{noDataFound}}
                </ng-template>
                <ng-template kendoAutoCompleteItemTemplate let-dataItem>
                  <div>
                    <span>{{dataItem.FirstName}} {{dataItem.LastName}} </span>
                    <span>{{dataItem.EmailAddress}}</span>
                  </div>
                </ng-template>
                <ng-template kendoAutoCompleteNoDataTemplate>
                  <h3>{{'COMMON.NO_MATCH_FOUND' | translate}}</h3>
                </ng-template>
              </kendo-multiselect>
            </div>
          </div>
          <div class="form-group row">
            <label for="createdDate" class="col-sm-4 col-form-label headingClass"
              translate>ACTION_LOG.HEADER.ATTACHMENTS</label>
            <kendo-upload [autoUpload]=false #upload class="col-sm-8 w-100" (select)="onFileSelect($event)"
              (remove)="onFileRemove($event)" [restrictions]="fileTypeRestrictions" [multiple]="false"
              [attr.disabled]="isFIScreen" [ngClass]="{'disable-background-color': isFIScreen }">
              <kendo-upload-messages [select]="'ACTION_LOG.BROWSE' | translate"
                [invalidMaxFileSize]="'ACTION_LOG.FILE_SIZE_ERROR_MESSAGE' | translate"
                [invalidFileExtension]="'ACTION_LOG.FILE_TYPE_ERROR_MESSAGE' | translate">
              </kendo-upload-messages>
            </kendo-upload>
          </div>
          <div class="form-group row attchmentListGrid" *ngIf="actionLog?.FileAttachments?.length">
            <!-- <label class="col-sm-4"></label> -->
            <ul class="pl-1">
              <li *ngFor="let file of actionLog?.FileAttachments" class="p-1">
                <ng-template #tipContent>
                  {{file.AttachmentName}}
                </ng-template>
                <a href="javascript: void(0);" [ngbTooltip]="file.AttachmentName.length > 0 ? tipContent : '' "
                  #tt="ngbTooltip" triggers="manual" (mouseover)="tt.open()" (mouseleave)="tt.close()" container="body"
                  (click)="tt.open()" (click)="isFIScreen ? '' : downloadFile(file)"
                  [ngClass]="{'disable-text-color': isFIScreen }">{{file.AttachmentName}} </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-5 actionTakenSection" *ngIf="!fromEdata">
          <div class="row" *ngIf="!isTVDEnabled">
            <div class=" col-md-12 admin-button-spacing ">
              <button type="button" class="float-right btn btn-primary" *ngIf="isFIScreen"
                (click)="cancelRowChanges(true)"><span translate>COMMON.CLOSE</span></button>
              <button type="button" class="float-right btn btn-secondary" *ngIf="!isFIScreen"
                (click)="cancelRowChanges(true)"><span translate>COMMON.CANCEL</span></button>
              <button type="button" *ngIf="mode === 'add'" [disabled]="!assetDetailsForm.valid"
                (click)="saveChanges(true)" class="float-right adminbtn btn btn-primary" translate>COMMON.SAVE</button>
              <button type="button" *ngIf="mode === 'edit' && !isFIScreen"
                [disabled]=" !isActionTakenPresent || !assetDetailsForm.valid" (click)="saveChanges(true)"
                class="float-right adminbtn btn btn-primary" translate>COMMON.SAVE</button>
            </div>
          </div>
          <div class="form-group row mb-0">
            <label for="actionTaken" *ngIf="mode === 'add'" class="mr-2 ml-3 col-form-label headingClass"
              translate>ACTION_LOG.HEADER.ACTIONS_TAKEN</label>
            <label for="actionTaken" *ngIf="mode === 'edit'" class="mr-2 ml-3 col-form-label headingClass"
              translate>ACTION_LOG.HEADER.ACTIONS_TAKEN<span class="required">*</span></label>
            <button data-toggle="collapse" (click)="addNewdata()" class="pillClass"><span
                class="pills {{isExpand}}-pills" *ngIf="!isFIScreen">
              </span></button>
          </div>
          <div *ngIf="tog" class="form-group row">
            <div class="divClass col-lg-4 col-md-3 col-sm-3">
              <label class="headingGrid" translate>ACTION_LOG.HEADER.RESPONSIBLE_PERSON</label>
              <kendo-autocomplete [disabled]="mode === 'add' && !selectedSiteDetails?.SiteID" [data]="usersList"
                class="borderClass w-100 heightClass" [filterable]="true" [valueField]="'EmailAddress'"
                [placeholder]="'NOTIFICATION.FILTER.SEARCH_USER' | translate"
                (valueChange)="searchValueChange($event, 'subgrid')" [loading]="loadingUserNameSubgrid"
                (filterChange)="searchfilterChange($event, 'subgrid')" required [formControl]="subGridUserEmail">
                <ng-template kendoAutoCompleteItemTemplate let-dataItem>
                  <div>
                    <span>{{dataItem.FirstName}} {{dataItem.LastName}} </span>
                    <span>{{dataItem.EmailAddress}}</span>
                  </div>
                </ng-template>
                <ng-template kendoAutoCompleteNoDataTemplate>
                  <h3>{{'COMMON.NO_MATCH_FOUND' | translate}}</h3>
                </ng-template>
              </kendo-autocomplete>
            </div>
            <div class="divClass col-lg-4 col-md-3 col-sm-3">
              <label class="headingGrid" translate>ACTION_LOG.HEADER.DATE_OF_ACTION</label>
              <kendo-datepicker class="w-100 customdatepicker heightClass" (valueChange)="dateOfActionChanged($event)"
                [formControl]="actionTakenDate"  [format]="dateFormat" [formatPlaceholder]="{
                year: 'yyyy', month: 'mm', day: 'dd'
              }">
                <kendo-datepicker-messages today="{{'DATA_VISUALIZATION.TIME_RANGE.TODAY' | translate}}">
                </kendo-datepicker-messages>
              </kendo-datepicker>
            </div>
            <div class="divClass col-lg-4 col-md-6 col-sm-6">
              <label class="headingGrid" translate>ACTION_LOG.HEADER.ACTION_TAKEN</label>
              <input type="text" name="country" maxlength="512" [formControl]="actionDesc" class="heightClass">
            </div>
            <div class="col-12 buttonClass">
              <button class="mr-1 btnDisabledClass" type="button" [disabled]="disableAdd"
                (click)="subGridSaveHandler(actionDesc)" translate>COMMON.ADD</button>
              <button class="cancelClass" type="button" (click)="cancel()" translate>COMMON.CANCEL</button>
            </div>
          </div>

          <div class="form-group row">
            <kendo-grid [data]="actionLog.ActionsTaken" (dataStateChange)="onStateChange($event)" class="kendoWidth">
              <kendo-grid-messages [noRecords]="'COMMON.NO_RECORDS_AVAILABLE' | translate">
              </kendo-grid-messages>
              <kendo-grid-column field="ActionTakenBy" [title]="'ACTION_LOG.HEADER.RESPONSIBLE_PERSON'| translate"
                [width]="140">
                <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                  <span title="{{dataItem.ActionTakenBy.FirstName}} {{dataItem.ActionTakenBy.LastName}}">
                    {{dataItem.ActionTakenBy.FirstName}} {{dataItem.ActionTakenBy.LastName}}</span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="ActionTakenOn" [title]="'ACTION_LOG.HEADER.DATE_OF_ACTION'| translate"
                format="{0:MM/dd/yyyy}" [width]="130">
                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                  <kendo-datepicker [value]="dataItem.ActionTakenOn" (valueChange)="dateOfActionChanged($event)"
                    name="ActionTakenOn"  [format]="'MM/dd/yyyy'">
                  </kendo-datepicker>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                  <span>{{dataItem.ActionTakenOn | dateFormat: true : false}}</span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="ActionDescription" [title]="'ACTION_LOG.HEADER.ACTION_TAKEN'| translate">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <div class="first-col" #element title="{{dataItem.ActionDescription}}">
                    <span>{{(dataItem.ActionDescription ? (dataItem.ActionDescription) : '-')}}</span>
                  </div>
                </ng-template>
              </kendo-grid-column>
            </kendo-grid>
          </div>
          <div *ngIf="mode === 'edit' && !isFIScreen">
            <span class="redClassForNote" translate>ACTION_LOG.NOTE </span><span class="actionLogNote"
              translate>ACTION_LOG.MANDATORY_NOTE</span>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</form>
