<div class="financial-project">
  <div class="section project-overview">
    <div>
      <div class="mr-auto" >
        <h1 class="mb-0" translate> FINANCIAL_IMPACT.PROJECT_OVERVIEW
          <span class="ico-info-thin currentStatus-tooltip" placement="bottom" data-html="true"
          [ngbTooltip]='tipContent1' onclick="void(0)" 
          container="body">
        </span>
          <ng-template #tipContent1>
            <div [innerHTML]="'FINANCIAL_IMPACT.PROJECT_INFO' | translate"></div>
          </ng-template>
        </h1>

      </div>
      <!--filters-->
    </div>
  </div>
  <div class="section waste-treatment">
    <div>
      <div class="clearfix mr-auto mw-100 ">
          <h1  class="float-left overflow-ellipsis" container="body" #tt="ngbTooltip" #element2 placement='bottom' triggers="manual" (mouseenter)="toggleTooltip($event,tt,true)"
          (mouseleave)="toggleTooltip($event,tt,false)" (click)="(element2.offsetWidth+1 < element2.scrollWidth) ? tt.open() : ''" 
          [ngbTooltip]="tipContent2">{{ projectOverviewData?.ProjectName}}</h1>
      <ng-template #tipContent2>
          <span>{{ projectOverviewData?.ProjectName}}</span>
      </ng-template>
        <label class="float-right"> {{'FINANCIAL_IMPACT.LAST_UPDATED' | translate}}:
          <span> {{ projectOverviewData?.ModifiedOn ? (getFormatedDate(projectOverviewData?.ModifiedOn)) : "COMMON.NOT_AVAILABLE"| translate }} </span>
        </label>
      </div>
      <!--filters-->
    </div>
  </div>
  <div class="waste-section">
    <nalco-tile [parentInstance]="myParentInstance()"   [showMoreOptions]=false [errorData]='errorInfo' onclick="void(0)" [showExportAsCsv]=false [showInfoIcon]=false [headerTitle]="'FINANCIAL_IMPACT.CATEGORY_SAVINGS' | translate" class="CategorySavings">
      <div tile-content>
        <div class="row no-gutters project-category">
          <div class="col-lg-4 col-xl-3 table-responsive scroll-in-mobile  px-0 total-saving">
            <table class="table ">
              <thead>
                <tr>
                  <th>
                 <span class="overflow-ellipsis"  #element6 placement='bottom' 
                 [ngbTooltip]="(element6.offsetWidth+1 < element6.scrollWidth) ? tipContent6 : ''">
                 {{'FINANCIAL_IMPACT.TOTAL_ANNUAL_SAVINGS' | translate}} ({{currencySymbol}})</span></th>
                  <ng-template #tipContent6>
                     {{'FINANCIAL_IMPACT.TOTAL_ANNUAL_SAVINGS' | translate}} ({{currencySymbol}})
                    </ng-template>
                   
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <h3><span style="display:inline-block">{{currencySymbol}}</span>{{totalAnnualSaving |  fiCurrencyFormat : true: 'en-US' : currencySymbol :conversionFactor}}</h3>
                    <p translate>FINANCIAL_IMPACT.OF_FINANCIAL_IMPACT</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-lg-8 col-xl-9 table-responsive scroll-in-mobile  px-0 total-categories">
              <table class="table savings-table">
                <thead>
                  <tr>
                    <th *ngFor="let col of CategorySavingsData">
                      {{col.name}}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="category">
                    <td *ngFor="let char of CategorySavingsData">
                      <span ngClass="{{char.iconClass}}"></span>
                      <p><span style="display:inline-block">{{currencySymbol}}</span>{{char.value  |  fiCurrencyFormat : true: 'en-US' : currencySymbol :conversionFactor : currencySpecificFormat }} </p>
                      <p *ngIf="char.Hide === false && char.UnitAmount !== null"> {{char.UnitAmount |  fiNumberFormat : true : 'en-US'}}<span>{{char?.UnitSymbol ? (char?.UnitSymbol): ''}}</span></p>
                      <!-- <p *ngIf="char.Hide === false && char.UnitAmount === null" > {{'COMMON.UNKNOWN' | translate}}<span>{{char?.UnitSymbol ? (char?.UnitSymbol): ''}}</span></p> -->
                    </td>
                  </tr>
                </tbody>
              </table>
          </div>
        </div>
      </div>
    </nalco-tile>
  </div>
  <div class="summary-section">
    <nalco-tile [parentInstance]="myParentInstance()"   [showMoreOptions]=false [errorData]='errorInfo' onclick="void(0)" [showExportAsCsv]=false [showInfoIcon]=false [headerTitle]="'CMV_PROJECTS.SUMMARY' | translate">
      <div tile-content>
        <div class=" row row-right">
          <div class="col-lg-8">
            <!-- left section -->
            <div class="row row-right">
              <div class="col-lg-6 ">
                <div class="card tile sub-tile" id="">
                  <div class="card-header">
                    <h3 class="card-title" translate>FINANCIAL_IMPACT.PROJECT_BENEFITS</h3>
                  </div>
                  <div class="tile-content-wrap">
                    <div class="summary-content">
                      <p class="mb-0">{{getText(projectOverviewData?.ProjectBenefits)}}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="card tile sub-tile" id="">
                  <div class="card-header">
                    <h3 class="card-title" translate>FINANCIAL_IMPACT.FINANCIALS</h3>
                  </div>
                  <div class="tile-content-wrap">
                    <div class="summary-content">
                      <table class="table table-borderless mb-0">
                        <thead>
                          <tr>
                            <th translate>FINANCIAL_IMPACT.ANNUAL_SAVING</th>
                            <th translate>FINANCIAL_IMPACT.VALUE_TYPE</th>
                          </tr>
                        </thead>
                        <tbody>
                          <!-- | orderBy: 'financial.AnnualSavings':true -->
                          <tr *ngFor="let financial of sortedFinancials" >
                            
                                <td  *ngIf='financial.AnnualSavings !== 0'><span style="display:inline-block">{{currencySymbol}}</span>{{financial.AnnualSavings ? ( financial.AnnualSavings |  fiCurrencyFormat : false: 'en-US' : currencySymbol :conversionFactor ) : '-'}} </td>
                                <td  *ngIf='financial.AnnualSavings !== 0'>{{financial.name ? (financial.name) : '-'}} </td>
                             
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="Status-Updates">
              <h3 translate>FINANCIAL_IMPACT.STATUS_UPDATES</h3>
            </div>
            <div class="row row-right">
              <div class="col-lg-6 ">
                <div class="card tile sub-tile" id="">
                  <div class="card-header">
                    <h3 class="card-title" translate>FINANCIAL_IMPACT.CURRENT_STATUS</h3>
                    <span class="ico-info-thin tooltip-info-icon currentStatus-tooltip"  placement="bottom"  data-html="true"  [ngbTooltip]='tipContent'>
                      </span>
                      <ng-template #tipContent>
                        <div [innerHTML]="'FINANCIAL_IMPACT.INFO.CURRENT_STATUS_INFO' | translate"></div>
                      </ng-template>

                  </div>
                  <div class="tile-content-wrap">
                    <div class="summary-content">
                      <p class="mb-0">{{getText(projectOverviewData?.CurrentStatus)}}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="card tile sub-tile" id="">
                  <div class="card-header">
                    <h3 class="card-title" translate>FINANCIAL_IMPACT.RESOURCES_NEEDED</h3>
                  </div>
                  <div class="tile-content-wrap">
                    <div class="summary-content">
                      <p class="mb-0"> {{getText(projectOverviewData?.ResourcesNeeded)}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Right section -->
          <div class="col-lg-4 right-project">
            <label class="project-focus mt-0" translate>FINANCIAL_IMPACT.PROJECT_FOCUS</label>
            <h3 class="mb-0">{{projectOverviewData?.ProjectType ? (projectOverviewData?.ProjectType) : "COMMON.NOT_AVAILABLE"
              | translate}}</h3>
            <label translate>FINANCIAL_IMPACT.PROJECT_STAGE</label>
            <h3 class="final-stage mb-0">{{projectOverviewData?.ProjectStage ? (projectOverviewData?.ProjectStage) : "COMMON.NOT_AVAILABLE"
              | translate}}</h3>
            <label translate>FINANCIAL_IMPACT.PROJECT_DELIVERABLE</label>
            <h3 class="innovation mb-0">{{projectOverviewData?.Deliverable ? (projectOverviewData?.Deliverable) : "COMMON.NOT_AVAILABLE"
              | translate}} </h3>
            <label translate>FINANCIAL_IMPACT.NALCO_CONTACT </label>
            <p>{{getContact(projectOverviewData?.RepFirstName , projectOverviewData?.RepLastName
              , projectOverviewData?.RepEmail)}}</p>
            <!-- <p>{{projectOverviewData?.RepFirstName}} {{projectOverviewData?.RepLastName}}-{{projectOverviewData?.RepEmail}}</p> -->
            <h3 class="mb-2" translate>FINANCIAL_IMPACT.CUSTOMER_CONTACT</h3>
            <p class="mb-3"> {{getContact(projectOverviewData?.ResCustomerFirstName, projectOverviewData?.ResCustomerLastName
              , projectOverviewData?.ResCustomerEmail)}}
            </p>
            <h3 class="mb-2" translate>FINANCIAL_IMPACT.PROJECT_LOCATION</h3>
            <div *ngIf = "!isMultipleSite">
            <div class="detail clearfix" >
              <span translate class="overflow-ellipsis" 
              container="body" #element4 placement='bottom' data-html="true"
              [ngbTooltip]="(element4.offsetWidth+1 < element4.scrollWidth) ? tipContent7 : ''">
              FINANCIAL_IMPACT.SITE_NAME
            </span>
              <ng-template #tipContent7>
                  <div [innerHTML]="'FINANCIAL_IMPACT.SITE_NAME' | translate"></div>
              </ng-template>
             
              <span>
                <p class="mb-0 address-text" #element1 placement = 'bottom' [ngbTooltip]='tipContent3' #tt1="ngbTooltip" triggers="manual" (mouseenter)="toggleTooltip($event,tt1,true)"
                (mouseleave)="toggleTooltip($event,tt1,false)" (click)="(element1.offsetWidth+1 < element1.scrollWidth) ? tt1.open() : ''" >
                {{projectOverviewData?.Sites[0].SiteName ? (projectOverviewData?.Sites[0].SiteName): "COMMON.NOT_AVAILABLE" | translate}} </p>
                <ng-template #tipContent3>
                    {{projectOverviewData?.Sites[0].SiteName ? (projectOverviewData?.Sites[0].SiteName): "COMMON.NOT_AVAILABLE" | translate}} 
                </ng-template>
              </span>
            </div>
            <div class="detail clearfix" *ngIf="projectOverviewData?.StateProvince">
              <span translate class="overflow-ellipsis" 
              container="body" #element5 placement='bottom' data-html="true"
              [ngbTooltip]="(element5.offsetWidth+1 < element5.scrollWidth) ? tipContent8 : ''">
              FINANCIAL_IMPACT.STATE_PROVINCE</span>
              <ng-template #tipContent8>
                <div [innerHTML]="'FINANCIAL_IMPACT.SITE_NAME' | translate"></div>
            </ng-template>
              <span>
                <p class="mb-0 address-text" #element2 placement = 'bottom' [ngbTooltip]='tipContent4' #tt2="ngbTooltip" triggers="manual" (mouseenter)="toggleTooltip($event,tt2,true)"
                (mouseleave)="toggleTooltip($event,tt2,false)" (click)= "(element2.offsetWidth+1 < element2.scrollWidth) ? tt2.open() : ''" >
                {{projectOverviewData?.Sites[0].StateProvince ? (projectOverviewData?.Sites[0].StateProvince)
                  : "COMMON.NOT_AVAILABLE" | translate}} </p>
                  <ng-template #tipContent4>
                      {{projectOverviewData?.Sites[0].StateProvince ? (projectOverviewData?.Sites[0].StateProvince)
                        : "COMMON.NOT_AVAILABLE" | translate}}
                  </ng-template>
              </span>
            </div>
            <div class="detail clearfix">
              <span translate class="overflow-ellipsis" 
              container="body" #element6 placement='bottom' data-html="true"
              [ngbTooltip]="(element6.offsetWidth+1 < element6.scrollWidth) ? tipContent9 : ''">
              FINANCIAL_IMPACT.COUNTRY</span>
              <ng-template #tipContent9>
                <div [innerHTML]="' FINANCIAL_IMPACT.COUNTRY' | translate"></div>
            </ng-template>
              <span>
                <p class="mb-0 address-text" #element3 placement = 'bottom' [ngbTooltip]='tipContent5' #tt3="ngbTooltip" triggers="manual" (mouseenter)="toggleTooltip($event,tt3,true)"
                (mouseleave)="toggleTooltip($event,tt3,false)" (click)= "(element3.offsetWidth+1 < element3.scrollWidth) ? tt3.open() : ''">
                {{projectOverviewData?.Sites[0].Country ? (projectOverviewData?.Sites[0].Country) : "COMMON.NOT_AVAILABLE" | translate}} </p>
                <ng-template #tipContent5>
                    {{projectOverviewData?.Sites[0].Country ? (projectOverviewData?.Sites[0].Country) : "COMMON.NOT_AVAILABLE" | translate}}
                </ng-template>
              </span>
            </div>
          </div>
            <div class="detail clearfix" *ngIf = "isMultipleSite">
              <span >Site Details</span>
              <span>
                <p class="multipleSite" (click) ="multipleSitePopup()">Multiple</p>
              </span>
            </div>
          </div>

        </div>
      </div>
    </nalco-tile>
  </div>
</div>
