import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { UserService } from '../../core/user/user.service';
@Pipe({
    name: 'dateFormat'
})

export class DateTimePipe implements PipeTransform {
    constructor(private userService: UserService) { }
    transform(value: any, isDate: boolean, isTime: boolean, isTimeWithoutSecs?: boolean) {
        if (!value) {
            return value;
        }
        //uses the value of `LOCALE_ID`, which is `en-US` by default. 
        let pipe = new DatePipe('en-US');
        let isUSuserCulture: boolean;
        //Conversion to utc time to avoid DST issue.
        let utcValue = moment.utc(value).format('MM/DD/YYYY HH:mm:ss');
        const userSettings = this.userService.getCurrentSettings();
        if (userSettings) {
            isUSuserCulture = userSettings.Culture == 'en-US' ? true : false;
        }
        if (isUSuserCulture) {
            if (isDate && isTime) {
                return pipe.transform(utcValue, 'MM/dd/yyyy HH:mm:ss')
            } else if (isDate && !isTime) {
                return pipe.transform(utcValue, 'MM/dd/yyyy')
            } else if ((!isDate) && isTime) {
                return pipe.transform(utcValue, 'HH:mm:ss')
            }
            else if ((!isDate) && isTimeWithoutSecs) {
                return pipe.transform(utcValue, 'HH:mm')
            }

        } else {
            if (isDate && isTime) {
                return pipe.transform(utcValue, 'dd/MM/yyyy HH:mm:ss')
            } else if (isDate && !isTime) {
                return pipe.transform(utcValue, 'dd/MM/yyyy')
            } else if ((!isDate) && isTime) {
                return pipe.transform(utcValue, 'HH:mm:ss')
            }
            else if ((!isDate) && isTimeWithoutSecs) {
                return pipe.transform(utcValue, 'HH:mm')
            }

        }
    }
}