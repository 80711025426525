import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'parameters-popup',
  templateUrl: './parameters-popup.component.html',
  styleUrls: ['./parameters-popup.component.scss']
})

export class ParametersPopupComponent implements OnInit {
  @Input() measurements: any = [];
  @Input() isLoading: boolean = true;
  @Input() assetName: string;
  constructor(private translate: TranslateService, public activeModal: NgbActiveModal) { }
  ngOnInit() { }
}
