export class IChargeBalance {
    TotalAnionsCaCO3: number;
    TotalCationsCaCO3: number;
    DifferenceCaCO3 : number;
    IonBalanceTypes : Array<IBalanceTypes>
}

export class IBalanceTypes {
    AddedIon : number;
    Name : string;
    ShortName: string;
    Type: string;
}

export class IAddedIons {
    cleared: boolean;
    details: IBalanceTypes;
    value: number;
    selectedIon: object;
}