<div class="sub-menu-hover" *ngIf="details.label !== 'LEFT_NAV.SUBNAV_ENVISION.EDATA_AND_INVENTORY_ALARMS'; else alarmContent">
  <span class="sub-menu-span">
    <img class="icon-css" src="./../../../assets/images/menus/arrows/arrow-right.svg"></span>
  <span class="sub-menu-span">
    <li [id]="details.label">
      <a (click)="menuItemClick($event)" class="title-small font-bold submenu-margin"
        [attr.href]="details?.href ? details?.href : null" [attr.target]="details?.target ? details?.target : null">
        {{details.label | translate}}</a>
    </li>
  </span>
</div>
<ng-template #alarmContent>
  <div *ngIf="(userType === 1 || userType === 2) && permissionPresent">
    <div class="sub-menu-hover">
    <span class="sub-menu-span">
      <img class="icon-css" src="./../../../assets/images/menus/arrows/arrow-right.svg"></span>
    <span class="sub-menu-span">
      <li [id]="details.label">
        <a (click)="menuItemClick($event)" class="title-small font-bold submenu-margin"
          [attr.href]="details?.href ? details?.href : null" [attr.target]="details?.target ? details?.target : null">
          <span>{{details.label | translate}}</span></a>
      </li>
    </span>
  </div>
  </div>
</ng-template>