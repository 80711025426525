import * as contracts from "../_contracts/IAssetLimits";
import * as enums from "../_enums/constants";
import { IndicatorType } from "../_enums/constants";


export class AssetLimits implements contracts.IAssetLimits {
    AssetContextPointId: number;
    AssetContextPointTypeId: number;
    AssetContextPointSubTypeId: number;
    ContextPointLimits: Array<ContextPointLimit> = [];
    ContextPointMeasurementData?: Array<ContextPointMeasurementData>;

    constructor(l?: contracts.IAssetLimits) {
        if (l) {
            this.AssetContextPointId = l.AssetContextPointId;
            this.AssetContextPointTypeId = l.AssetContextPointTypeId;
            this.AssetContextPointSubTypeId = l.AssetContextPointSubTypeId;
            l.ContextPointLimits.forEach((a, index) => {
                this.ContextPointLimits.push(new ContextPointLimit(a, index));
            });
            if (l.ContextPointMeasurementData && l.ContextPointMeasurementData.length > 0) {
                this.ContextPointMeasurementData = [];
                l.ContextPointMeasurementData.forEach(data => {
                    this.ContextPointMeasurementData.push(new ContextPointMeasurementData(data));
                });
            }
        }
    }
}

export class ContextPointLimit implements contracts.IContextPointLimit {
    ContextPoint: ContextPointLite;
    Measurement: Measurement;
    Stui: Stui;
    EntrySubunit: string;
    Symbol: string;
    MeasurementLimits: Array<MeasurementLimit> = [];
    // DecimalPlaces: number

    // calculated at UI
    groupByData: string;
    IndicatorTypeId?: IndicatorType;
    IsUserSpecific?: boolean;
    UomDD?: Array<any>;
    DisablePushToCL?: boolean;
    IsWQEnabled?: boolean;
    isCalculatedMsr?: boolean;

    constructor(cp?: contracts.IContextPointLimit, index = 0) {
        if (cp) {
            this.ContextPoint = new ContextPointLite(cp.ContextPoint);
            this.Measurement = new Measurement(cp.Measurement);
            this.Stui = new Stui(cp.Stui);
            this.EntrySubunit = cp.EntrySubunit;
            this.Symbol = cp.Symbol;
            cp.MeasurementLimits.forEach(ml => {
                if (ml.DatasourceContextPoint === null)
                    this.IsWQEnabled = ml.IsWQEnabled;
                this.MeasurementLimits.push(new MeasurementLimit(ml));
            });
            let portName = cp.ContextPoint ? cp.ContextPoint.ContextPointName : index;
            this.groupByData = this.Measurement.MeasurementName + "/" + portName + "/" + this.Measurement.MeasurementId;

            this.IndicatorTypeId = cp.IndicatorTypeId;
            this.IsUserSpecific = cp.IsUserSpecific;
            if (cp.UomDD && cp.UomDD.length > 0) {
                this.UomDD = [];
                cp.UomDD.forEach(unit =>
                    this.UomDD.push({ text: unit.Symbol, value: unit.Symbol, unitName: unit.UnitName, baseUnit: unit.IsBaseUnit })
                )
            }
            this.DisablePushToCL = cp.DisablePushToCL;
            this.isCalculatedMsr = cp.isCalculatedMsr;
            // this.DecimalPlaces = cp.DecimalPlaces;
        }
    }
}

export class ContextPointMeasurementData implements contracts.IContextPointMeasurementData {
    ContextPointId: number;
    ContextPointSubTypeId: number;
    MeasurementId: number;
    StuiId: number;
    StuiTypeId: number;
    IndicatorTypeId: IndicatorType;
    IsUserSpecific: boolean;
    DimensionalSubUnit: Array<DimensionalSubUnit> = [];

    constructor(cp?: contracts.IContextPointMeasurementData) {
        if (cp) {
            this.ContextPointId = cp.ContextPointId;
            this.ContextPointSubTypeId = cp.ContextPointSubTypeId;
            this.MeasurementId = cp.MeasurementId;
            this.StuiId = cp.StuiId;
            this.StuiTypeId = cp.StuiTypeId;
            this.IndicatorTypeId = cp.IndicatorTypeId;
            this.IsUserSpecific = cp.IsUserSpecific;
            if (cp.DimensionalSubUnit && cp.DimensionalSubUnit.length > 0) {
                cp.DimensionalSubUnit.forEach(unit => {
                    this.DimensionalSubUnit.push(new DimensionalSubUnit(unit));
                })
            }
        }
    }
}

export class DimensionalSubUnit implements contracts.IDimensionalSubUnit {
    UnitName?: string;
    SignificantDigits?: number;
    Symbol?: string;
    IsBaseUnit?: boolean;

    constructor(dSU?: contracts.IDimensionalSubUnit) {
        if (dSU) {
            this.UnitName = dSU.UnitName;
            this.SignificantDigits = dSU.SignificantDigits;
            this.Symbol = dSU.Symbol;
            this.IsBaseUnit = dSU.IsBaseUnit;
        }
    }
}

export class ContextPointLiteEntity implements contracts.IContextPointLiteEntity {
    ContextPointId: number;
    ContextPointName: string;
    ContextPointStatusType: enums.ContextPointStatusType;
    ContextPointStatusTypePrettyName: string;
    ContextPointType: enums.ContextPointType;
    ContextPointTypePrettyName: string;
    ContextPointSubTypeId: number;
    ContextPointSubTypeName: string;

    constructor(cpl: contracts.IContextPointLiteEntity) {
        if (cpl) {
            this.ContextPointId = cpl.ContextPointId;
            this.ContextPointName = cpl.ContextPointName;
            this.ContextPointStatusType = cpl.ContextPointStatusType;
            this.ContextPointStatusTypePrettyName = cpl.ContextPointStatusTypePrettyName;
            this.ContextPointType = cpl.ContextPointType;
            this.ContextPointTypePrettyName = cpl.ContextPointTypePrettyName;
            this.ContextPointSubTypeId = cpl.ContextPointSubTypeId;
            this.ContextPointSubTypeName = cpl.ContextPointSubTypeName;
        }
    }
}

export class ContextPointLite extends ContextPointLiteEntity implements contracts.IContextPointLite {
    ParentId: number;
    ParentName: string;
    ParentType: enums.ContextPointType;
    ParentTypePrettyName: string;
    ParentSubTypeId: number;
    ParentSubTypeName: string;

    constructor(lite?: contracts.IContextPointLite) {
        super(lite);
        if (lite) {
            this.ParentId = lite.ParentId;
            this.ParentName = lite.ParentName;
            this.ParentType = lite.ParentType;
            this.ParentTypePrettyName = lite.ParentTypePrettyName;
            this.ParentSubTypeId = lite.ParentSubTypeId;
            this.ParentSubTypeName = lite.ParentSubTypeName;
        }
    }
}

export class MeasurementEntity implements contracts.IMeasurementEntity {
    MeasurementId: number;
    MeasurementName: string;
    ImmutableName: string;
    NalcoNumericsUnitOrSpecies: string;
    Description: string;
    CreatedOn: string;
    CreatedBy: number;
    ModifiedOn: string;
    ModifiedBy: number;
    OptionGroupId: number;

    constructor(a?: contracts.IMeasurementEntity) {
        if (a) {
            this.MeasurementId = a.MeasurementId;
            this.MeasurementName = a.MeasurementName;
            this.ImmutableName = a.ImmutableName;
            this.NalcoNumericsUnitOrSpecies = a.NalcoNumericsUnitOrSpecies;
            this.Description = a.Description;
            this.CreatedOn = a.CreatedOn;
            this.CreatedBy = a.CreatedBy;
            this.ModifiedOn = a.ModifiedOn;
            this.ModifiedBy = a.ModifiedBy;
            this.OptionGroupId = a.OptionGroupId;
        }
    }
}


export class Measurement extends MeasurementEntity implements contracts.IMeasurement {
    constructor(m?: contracts.IMeasurement) {
        super(m);
    }
}

export class StuiEntity implements contracts.IStuiEntity {
    StuiId?: number;
    StuiTypeId?: number;
    StuiName: string;
    ResourceName: string;
    CreatedBy: number;
    CreatedOn: string;
    ModifiedBy: number;
    ModifiedOn: string;
    StuiType: StuiType;

    constructor(se?: contracts.IStuiEntity) {
        this.StuiId = se.StuiId;
        this.StuiTypeId = se.StuiTypeId;
        this.StuiName = se.StuiName;
        this.ResourceName = se.ResourceName;
        this.CreatedBy = se.CreatedBy;
        this.CreatedOn = se.CreatedOn;
        this.ModifiedBy = se.ModifiedBy;
        this.ModifiedOn = se.ModifiedOn;
        this.StuiType = se.StuiType;
    }
}

export class StuiTypeEntity implements contracts.IStuiTypeEntity {
    StuiTypeId: number;
    StuiTypeName: string;
    DisplayFormat: string;
    DisplayFormatSecondary: string;
    IsRequired: boolean;
    CreatedBy: number;
    CreatedOn: string;
    ModifiedBy: Number;
    ModifiedOn: string;

    constructor(ste?: contracts.IStuiTypeEntity) {
        if (ste) {
            this.StuiTypeId = ste.StuiTypeId;
            this.StuiTypeName = ste.StuiTypeName;
            this.DisplayFormat = ste.DisplayFormat;
            this.DisplayFormatSecondary = this.DisplayFormatSecondary;
            this.IsRequired = this.IsRequired;
            this.CreatedBy = this.CreatedBy;
            this.CreatedOn = this.CreatedOn;
            this.ModifiedBy = this.ModifiedBy;
            this.ModifiedOn = this.ModifiedOn;
        }
    }
}

export class StuiType extends StuiTypeEntity implements contracts.IStuiType {
    constructor(a?: contracts.IStuiType) {
        super(a);
    }
}

export class Stui extends StuiEntity implements contracts.IStui {
    StuiType: StuiType;

    constructor(s?: contracts.IStui) {
        super(s);
        if (s) {
            this.StuiType = new StuiType(s.StuiType);
        }
    }
}

export class MeasurementLimit implements contracts.IMeasurementLimit {
    getShortName(thresholdTypeId: enums.LimitThresholdType): string {
        switch (thresholdTypeId) {
            case 1:
                return "Abs";
            case 2:
                return "Rel %";
            case 3:
                return "Rel #";
        }
    }
    DatasourceContextPoint: ContextPointLite = null;
    SourceType: enums.SourceType;
    SourceTypePrettyName: string;
    MeasurementLimitType: enums.MeasurementLimitType;
    LimitTypePrettyName: string;
    LimitThresholdType: enums.LimitThresholdType;
    LimitThresholdTypePrettyName: string;
    MeasurementLimitSequenceType: enums.MeasurementLimitSequenceType;
    LowLowLowLimit?: number;
    LowLowLimit?: number;
    LowLimit?: number;
    TargetValue?: number;
    HighLimit?: number;
    HighHighLimit?: number;
    HighHighHighLimit?: number;
    LowLimitPercentage?: number;
    HighLimitPercentage?: number;
    LimitPrettyName?: string;
    LimitThresholdTypeShortName?: string;
    ModifiedBy?: number;
    ControlRange?: number;
    readOnly?= true;
    isParentRow?= false;
    validationRule?: number = 1;
    ControlMethodType?: number;
    ControlMethodTypePrettyName?: string;
    LimitDefinitionId?: string;
    isLimitsEdited: boolean;
    StartOn?: string;
    EndOn?: string;
    oldLimitsEffectiveDate: string;
    ledToolTip: boolean;
    IsLimitInSync?: boolean;
    RequestGuid?: string;
    disableLED?: boolean;
    IsWQEnabled?: boolean;
    AssetLevelWqAllowed?: boolean;
    IsWqEnabledStateChanged?: boolean;

    constructor(m?: MeasurementLimit) {
        if (m) {
            this.DatasourceContextPoint = m.DatasourceContextPoint ? new ContextPointLite(m.DatasourceContextPoint) : null;
            this.SourceType = m.SourceType;
            this.SourceTypePrettyName = m.SourceTypePrettyName;
            this.MeasurementLimitType = m.MeasurementLimitType;
            this.LimitTypePrettyName = m.LimitTypePrettyName;
            this.LimitThresholdType = m.LimitThresholdType;
            this.LimitThresholdTypePrettyName = m.LimitThresholdTypePrettyName;
            this.MeasurementLimitSequenceType = m.MeasurementLimitSequenceType;
            this.LowLowLowLimit = m.LowLowLowLimit;
            this.LowLowLimit = m.LowLowLimit;
            this.LowLimit = m.LowLimit;
            this.TargetValue = m.TargetValue;
            this.HighLimit = m.HighLimit;
            this.HighHighLimit = m.HighHighLimit;
            this.HighHighHighLimit = m.HighHighHighLimit;
            this.LowLimitPercentage = m.LowLimitPercentage;
            this.HighLimitPercentage = m.HighLimitPercentage;
            this.LimitPrettyName = m.LimitPrettyName;
            this.ModifiedBy = m.ModifiedBy;
            this.ControlRange = m.ControlRange;
            this.readOnly = true;
            this.isParentRow = m.DatasourceContextPoint ? false : true;
            this.validationRule = m.DatasourceContextPoint ? 0 : 1;
            this.LimitThresholdTypeShortName = this.getShortName(m.LimitThresholdType);
            this.ControlMethodType = m.ControlMethodType;
            this.ControlMethodTypePrettyName = m.ControlMethodTypePrettyName;
            this.LimitDefinitionId = m.LimitDefinitionId;
            this.isLimitsEdited = m.isLimitsEdited;
            this.StartOn = m.StartOn === '0001-01-01T00:00:00' || m.DatasourceContextPoint ? null : m.StartOn; // Added Condition for considering min SQL date as NULL
            this.EndOn = m.DatasourceContextPoint ? null : m.EndOn;
            this.oldLimitsEffectiveDate = null;
            this.ledToolTip = false;
            this.IsLimitInSync = m.IsLimitInSync;
            this.RequestGuid = m.RequestGuid;
            this.disableLED = m.disableLED
            this.IsWQEnabled = m.IsWQEnabled;
            this.AssetLevelWqAllowed = m.AssetLevelWqAllowed;
            this.IsWqEnabledStateChanged = m.IsWqEnabledStateChanged;
        }
    }
}

export class LimitColumn {
    field: string;
    title: string;
    format?: string;
    type: 'text' | 'numeric' | 'boolean' | 'date' | 'textbox' | 'dropdown';
    width?: number;
    minWidth?: number;
    class?: string;
    tooltip?: string;
    showTooltipAlways?: boolean = false // this is for always showing the tooltip
    id?: string;
    icon?: string;
    iconpercent?: string;
}
