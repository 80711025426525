import { Component, OnInit, Input, Output, EventEmitter, HostListener, OnChanges, SimpleChanges, NgModule } from '@angular/core';
import { User } from '../../models/_models';
import { headerHandlerService } from './../../../core/nav/header-handler.service';
import { LocalCacheService } from '../../../core/local-cache/local-cache.service';
import { Router } from '@angular/router';

@Component({
  selector: 'nalco-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  @Input() user: User; // Defines the user Authentication
  @Input() hideSubHeader? : boolean = false;
  @Output() onFilter = new EventEmitter();
  userInfo: User;
  profileDdToggle = false;
  Initials = '';
  collapsed = true;
  toggleCollapsed(): void {
    this.collapsed = !this.collapsed;
  }

  constructor(private headerService: headerHandlerService, 
    private cache: LocalCacheService, 
    private router: Router) {
  
  }

  @HostListener('document:click', ['$event']) clickedOutside(event) {
    if  ((event.target.className.indexOf  !==  undefined
       &&  event.target.className.indexOf('LeftNavigation-link')  >  0)
      ||  (event.target.innerText  !==  undefined  &&  event.target.innerText  ===  'HOME') ||
      (event.target.className.indexOf  !==  undefined && event.target.className.indexOf('overlay')  >=  0)) {
      this.collapsed  =  true;
    }
  }

  ngOnInit() {
    //  this.isMobile = (/android|webos|iphone|ipad|ipod|blackberry|windows phone/).test(navigator.userAgent.toLowerCase());
    let initial = '';
    this.userInfo = JSON.parse(this.cache.fetchData('msalUserInfo'));
    const givenName = this.checkUserName(this.userInfo.userPrincipalName || this.userInfo.EmailAddress);
    if (givenName) {
      initial = givenName.substring(0, 1);
    }
    if (this.userInfo.surname || this.userInfo.LastName) {
      let surname = this.userInfo.surname || this.userInfo.LastName;
      if (surname) {
        initial += surname.substring(0, 1);
      } else {
        // fallback to get from given_name, if family name is null
        let firstName = this.userInfo.FirstName || this.userInfo.displayName;
        initial += firstName.split(' ')[1].substring(0, 1);
      }
    }
    this.Initials = initial.toUpperCase();
  }

  logout() {
    this.router.navigate(['/logout']);
  }

  clickBtn() {
    this.headerService.btnclickhead$(event);
  }

  showProfileSubMenu() {
    this.profileDdToggle = !this.profileDdToggle;
  }

  // Checking given_name is undefined
  checkUserName(name) {
    if (name !== undefined) {
      return name;
    } else {
      return this.userInfo.FirstName;
    }
  }
}
