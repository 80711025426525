import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { SessionStorageService } from 'ngx-webstorage';
import { Subscription } from 'rxjs';
import { ASSETTREECONSTANTS } from '../../../app.config';
import { AssetHarvesterService } from '../../../asset-harvester/_services/asset-harvester.service';
import { SharedService } from '../../services/shared-services.service';
import { CancelpopupComponent } from '../cancel-popup/cancel-popup.component';
import { ComponentState } from '../xd-container/xd-component-state';

@Component({
  selector: 'nalco-asset-count',
  templateUrl: './asset-count.component.html',
  styleUrls: ['./asset-count.component.scss']
})
export class AssetCountComponent implements OnInit, OnDestroy {
  public assetsGridStatus: ComponentState;

  modalRef: any;
  popUpWindow: NgbModalRef;
  totalAssets: number;
  createdAssets: number;
  dagId: any;
  errorInfo: any;
  disableSave = false;
  subscription: Subscription;
  onBoardingFlag: boolean;
  isTrialFlag: boolean;

  @ViewChild('assetCount', { read: ElementRef }) assetCount: ElementRef;
  assetHarvesterTab: string = '';
  plannedAssetsValue: number;
  // siteDetails: any;

  constructor(
    private route: ActivatedRoute,
    public assetHarvesterService: AssetHarvesterService,
    private sharedService: SharedService,
    private translate: TranslateService,
    private modalService: NgbModal,
    private storage: SessionStorageService) {
    if (this.route.snapshot.url.length > 0) {
      this.assetHarvesterTab = this.route.snapshot.url[1].path;
    }
  }
  // ngAfterViewInit(): void {
  //   this.plannedAssetsValue = parseInt(this.plannedAssetsRef.value, 10);
  // }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.dagId = params['siteid'];
      this.getAssetCount(this.dagId);
    });
    // this.getAssetCount(this.dagId);
    // if (this.storage.retrieve(ASSETTREECONSTANTS.SELECTEDSITE)) {
    //   this.siteDetails = this.storage.retrieve(ASSETTREECONSTANTS.SELECTEDSITE);
    // }
    // this.getAssetCount(this.siteDetails.SiteId);
  }

  showAssetCountModal(assetCount) {
    document.body.classList.add('asset-count-modal-open');
    this.popUpWindow = this.modalService.open(assetCount,
      {
        backdrop: 'static',
        keyboard: false,
        windowClass: 'asset-count-modal'
      });
  }

  saveTotalAssetsPlanned(plannedAssets) {
    this.disableSave = true;
    this.sharedService.savePlannedAssets(this.dagId, plannedAssets).subscribe(res => {
      if (res) {
        const msg = this.translate.instant('COMMON.SAVE_MESSAGE_TEXT');
        this.showConfirmationPopup(msg);
        this.getAssetCount(this.dagId);
        this.closeTotalAssetsModal();
      }
      this.disableSave = false;
    },
      error => {
        this.disableSave = false;
      });
  }

  //Gets count of both Planned/Created Assets
  getAssetCount(dagId) {
    this.subscription = this.sharedService.getPlannedAssets(dagId, true).subscribe(response => {
      if (response) {
        response.TotalAssetsPlanned ? this.totalAssets = response.TotalAssetsPlanned : this.totalAssets = 0;
        response.TotalAssetsCurent ? this.createdAssets = response.TotalAssetsCurent : this.createdAssets = 0;
        this.onBoardingFlag = response.IsOnboarding;
        this.isTrialFlag = response.IsTrialEnabled;
      }

      if (this.totalAssets == 0 && this.assetHarvesterService.writeAlso) {
        this.showAssetCountModal(this.assetCount);
      }
    }, error => {
      this.totalAssets = 0;
    });
  }
  closeTotalAssetsModal() {
    document.body.classList.remove('asset-count-modal-open');
    this.popUpWindow.close();
  }

  //Shows Confirmation pop up after Save
  showConfirmationPopup(text: string) {
    this.modalRef = this.modalService.open(CancelpopupComponent, {
      backdrop: 'static',
      keyboard: false,
      windowClass: 'saveExit-modal',
      size: 'sm'
    }).componentInstance;
    this.modalRef.modalsaveText = text;
    this.modalRef.showWarning = true;
    this.modalRef.savedPopup = true;
    this.modalRef.actionBtn = this.translate.instant('COMMON.OK');
  }
  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
    if (this.popUpWindow && typeof (this.popUpWindow) != 'undefined') {
      this.closeTotalAssetsModal();
    }
  }
}
