import { UserPreference } from "../../shared/models/User";
import { IActionLogUser, IActionLogCCUser, IPermissions, IFileAttachments, IActionsTaken, IEnabledService, IActionLog, IActionLogRecommendations, IFilterActionLog, INotificationRecipient, ISavings, ISavingCategoryTypes, IEroiTypes, IUnitDetail, IAccountDetail } from "../contracts/IActionLog";

export class Persmissions implements IPermissions {
  PermissionId: number;
  PermissionName: string;
  constructor(permission?: IPermissions) {
    if (permission) {
      this.PermissionId = permission.PermissionId;
      this.PermissionName = permission.PermissionName;
    }
  }
}

export class ActionLogCCUser implements IActionLogCCUser{
  UserId: number;
  FirstName: string = '';
  LastName: string = '';
  EmailAddress: string = '';
  UserTypeId: number;
  constructor(actionLogCCUser?: IActionLogCCUser) {
    if (actionLogCCUser) {
      this.UserId = actionLogCCUser.UserId;
      this.FirstName = actionLogCCUser.FirstName;
      this.LastName = actionLogCCUser.LastName;
      this.EmailAddress = actionLogCCUser.EmailAddress;
      this.UserTypeId = actionLogCCUser.UserTypeId;
    }
  }
}

export class ActionLogUser implements IActionLogUser {
  UserPreference: UserPreference = new UserPreference();
  UserId: number;
  FederationIdentifier: string;
  LastName: string = '';
  FirstName: string = '';
  MiddleName: string = '';
  EmailAddress: string = '';
  UserTypeName: string;
  UserStatusTypeName: string;
  Phone: string;
  Mobile: string;
  UserCountry: number;
  TimezoneId: number;
  Culture: string;
  CurrencyCode: string;
  UserLogin: string;
  IsInternalUser: boolean;
  Permissions: Array<Persmissions> = [];
  HasNalcoAccesUser: boolean;
  UserStatusType: number;
  UserType: number;
  dmUserNo: number;
  constructor(actionLogUser?: IActionLogUser) {
    if (actionLogUser) {
      this.UserPreference = actionLogUser.UserPreference;
      this.UserId = actionLogUser.UserId;
      this.FederationIdentifier = actionLogUser.FederationIdentifier;
      this.LastName = actionLogUser.LastName;
      this.FirstName = actionLogUser.FirstName;
      this.MiddleName = actionLogUser.MiddleName;
      this.EmailAddress = actionLogUser.EmailAddress;
      this.UserTypeName = actionLogUser.UserTypeName;
      this.UserStatusTypeName = actionLogUser.UserStatusTypeName;
      this.Phone = actionLogUser.Phone;
      this.Mobile = actionLogUser.Mobile;
      this.UserCountry = actionLogUser.UserCountry;
      this.TimezoneId = actionLogUser.TimezoneId;
      this.Culture = actionLogUser.Culture;
      this.CurrencyCode = actionLogUser.CurrencyCode;
      this.UserLogin = actionLogUser.UserLogin;
      this.IsInternalUser = actionLogUser.IsInternalUser;
      this.HasNalcoAccesUser = actionLogUser.HasNalcoAccesUser;
      this.UserStatusType = actionLogUser.UserStatusType;
      this.UserType = actionLogUser.UserType;
      this.dmUserNo = actionLogUser.dmUserNo;
      if (actionLogUser.Permissions && actionLogUser.Permissions.length) {
        actionLogUser.Permissions.forEach(permission => {
          this.Permissions.push(new Persmissions(permission));
        });
      }
    }
  }
}

export class FileAttachments implements IFileAttachments {
  FileContent: string;
  FileAttachmentId: number = 0;
  RecommendationId: number;
  AttachmentSortOrder: number;
  AttachmentName: string;
  StoragePath: string;
  ModifiedBy: number;
  ModifiedOn: string;
  SourceApplicationTypeId : number;
  constructor(fileAttachments?: IFileAttachments) {
    if (fileAttachments) {
      this.FileContent = fileAttachments.FileContent;
      this.FileAttachmentId = fileAttachments.FileAttachmentId;
      this.RecommendationId = fileAttachments.RecommendationId;
      this.AttachmentSortOrder = fileAttachments.AttachmentSortOrder;
      this.AttachmentName = fileAttachments.AttachmentName;
      this.StoragePath = fileAttachments.StoragePath;
      this.ModifiedBy = fileAttachments.ModifiedBy;
      this.ModifiedOn = fileAttachments.ModifiedOn;
      this.SourceApplicationTypeId = fileAttachments.SourceApplicationTypeId;
    }
  }
}

export class ActionsTaken implements IActionsTaken {
  ActionTakenBy: ActionLogUser = new ActionLogUser();
  ActionTakenId: number;
  RecommendationId: number;
  ActionDescription: string;
  ActionTakenById: number;
  ActionTakenOn: string;
  ModifiedBy: number;
  ModifiedOn: string;
  SourceApplicationTypeId: number;
  constructor(actionsTaken?: IActionsTaken) {
    if (actionsTaken) {
      this.ActionTakenBy = new ActionLogUser(actionsTaken.ActionTakenBy);
      this.ActionTakenId = (actionsTaken.ActionTakenId != null) ? actionsTaken.ActionTakenId : 0;
      this.RecommendationId = (actionsTaken.RecommendationId != null) ? actionsTaken.RecommendationId : 0;
      this.ActionDescription = actionsTaken.ActionDescription;
      this.ActionTakenById = actionsTaken.ActionTakenById;
      this.ActionTakenOn = actionsTaken.ActionTakenOn;
      this.ModifiedBy = actionsTaken.ModifiedBy;
      this.ModifiedOn = actionsTaken.ModifiedOn;
      this.SourceApplicationTypeId = actionsTaken.SourceApplicationTypeId;
    }
  }
}

export class EnabledService implements IEnabledService {
  Id?: number;
  Name: string;
  Deeplink?: string;
  constructor(enabledService?: IEnabledService) {
    if (enabledService) {
      this.Id = enabledService.Id;
      this.Name = enabledService.Name;
      this.Deeplink = enabledService.Deeplink;
    }
  }
}

export class NotificationRecipient implements INotificationRecipient {
  NotificationRecipient: ActionLogCCUser = new ActionLogCCUser();
  constructor(notificationRecipient?: INotificationRecipient) {
    if (notificationRecipient) {
      this.NotificationRecipient = new ActionLogCCUser (notificationRecipient.NotificationRecipient) ;
    }
  }
}


export class Savings implements ISavings {
  ERoiTypeId: number;
  MonetaryAmountInDisplayUnit: number;
  AmountInDisplayUnit: number;
  CategoryTypeId: number;
  DisplayUnit: string;
  DisplayCurrency: string;
  AmountBase?: number;
  CreatedOn?: string;
  ERoiValueId?: number;
  EndDate?: string;
  MonetaryAmountBase?: number;
  RecommendationId?: number;
  SourceApplication?: number;
  StartDate?: string;
  UnitValueId?: number;
  EroiValueTypeText? : string;

  constructor(savings?: ISavings) {
    if (savings) {
      Object.assign(this, savings);
    }
  }
}

export class ActionLog implements IActionLog {
  ContextPointName: string = '';
  ContextPointTypeId: number = 0;
  ContextPointStatus: string = '';
  SiteId: number;
  SiteName: string;
  ActionStatusTypeName: string;
  ActionImpactTypeName: string;
  ResponsiblePerson: ActionLogUser = new ActionLogUser();
  FileAttachments: Array<FileAttachments> = [];
  ActionsTaken: Array<ActionsTaken> = [];
  EnabledServices: Array<EnabledService> = [];
  RecommendationId: number;
  ContextPointId: number = 0;
  ActionImpactTypeId: number;
  ActionStatusTypeId: number;
  Problem: string;
  Recommendation: string;
  RecommendedOn: string;
  ExpectedResolutionDate: string;
  ResponsiblePersonId: number;
  ModifiedBy: number;
  ModifiedOn: string;
  EmailSentDate: string;
  SourceApplicationTypeId: number = 0;
  ETag: string;
  Message: string;
  Escalate: boolean;
  TimesRecommended : number;
  CreatedBy: number;
  CreatedOn : string;
  CreatedByUser : ActionLogUser = new ActionLogUser();
  RecommendationNotificationRecipientsList : Array<NotificationRecipient> = [];
  SavingList: Array<Savings> = [];
  IsSavingAvailable: boolean;
  AccountContextPointId: number;
  AccountNumber: string;
  CorporateName?: string;
  SystemName?: string;
  CorporateCpId?: number;
  IsSiteActive: boolean;
  IsAssetActive: boolean;


  // for csv exports
  csvResponsiblePerson: string;
  csvActionCreatedBy: string;
  csvEmailSentDate: string;
  csvActionsTaken: string = '';
  csvRecommendedOn: string = '';
  csvExpectedResolutionDate: string = '';
  gridSavingDetails?: { eroiValueTypeText: string; AnnualSavings: number; UnitForAmount: string; savingCategoryTypeText: string; };

  constructor(actionLog?: IActionLog) {
    if (actionLog) {
      this.ContextPointName = actionLog.ContextPointName;
      this.ContextPointTypeId = actionLog.ContextPointTypeId;
      this.ContextPointStatus = actionLog.ContextPointStatus;
      this.SiteId = actionLog.SiteId;
      this.SiteName = actionLog.SiteName;
      this.ActionStatusTypeName = actionLog.ActionStatusTypeName;
      this.ActionImpactTypeName = actionLog.ActionImpactTypeName;
      this.AccountNumber = actionLog.AccountNumber;
      this.IsSiteActive = actionLog.IsSiteActive;
      this.IsAssetActive = actionLog.IsAssetActive;
      this.ResponsiblePerson = new ActionLogUser(actionLog.ResponsiblePerson);
      this.CreatedByUser = new ActionLogUser(actionLog.CreatedByUser);
      if (actionLog.SavingList && actionLog.SavingList.length) {
        actionLog.SavingList.forEach(saving => {
          if(saving.ERoiTypeId) {
            this.SavingList.push(new Savings(saving));
          }
        });
      }

      if (actionLog.RecommendationNotificationRecipientsList && actionLog.RecommendationNotificationRecipientsList.length) {
        actionLog.RecommendationNotificationRecipientsList.forEach(recipient => {
          this.RecommendationNotificationRecipientsList.push(new NotificationRecipient(recipient));
        });
      }

      if (actionLog.FileAttachments && actionLog.FileAttachments.length) {
        actionLog.FileAttachments.forEach(attachment => {
          this.FileAttachments.push(new FileAttachments(attachment));
        });
      }
      if (actionLog.ActionsTaken && actionLog.ActionsTaken.length) {
        actionLog.ActionsTaken.forEach(actions => {
          this.ActionsTaken.push(new ActionsTaken(actions));
          this.csvActionsTaken += this.getActionTakenString(actions);
        });
      }
      if (actionLog.EnabledServices && actionLog.EnabledServices.length) {
        actionLog.EnabledServices.forEach(enabledService => {
          this.EnabledServices.push(new EnabledService(enabledService));
        });
      }
      this.RecommendationId = actionLog.RecommendationId;
      this.ContextPointId = actionLog.ContextPointId;
      this.ActionImpactTypeId = actionLog.ActionImpactTypeId;
      this.ActionStatusTypeId = actionLog.ActionStatusTypeId;
      this.Problem = actionLog.Problem;
      this.Recommendation = actionLog.Recommendation;
      this.RecommendedOn = actionLog.RecommendedOn;
      this.ExpectedResolutionDate = actionLog.ExpectedResolutionDate;
      this.ResponsiblePersonId = actionLog.ResponsiblePersonId;
      this.ModifiedBy = actionLog.ModifiedBy;
      this.ModifiedOn = actionLog.ModifiedOn;
      this.EmailSentDate = actionLog.EmailSentDate;
      this.SourceApplicationTypeId = actionLog.SourceApplicationTypeId;
      this.ETag = actionLog.ETag;
      this.Message = actionLog.Message;
      this.Escalate = actionLog.Escalate;
      this.TimesRecommended = actionLog.TimesRecommended;
      this.CreatedBy = actionLog.CreatedBy;
      this.CreatedOn = actionLog.CreatedOn;
      this.AccountContextPointId = actionLog.AccountContextPointId || null;
      this.csvResponsiblePerson = this.ResponsiblePerson.FirstName + ' ' + this.ResponsiblePerson.LastName;
      this.csvActionCreatedBy = this.CreatedByUser.FirstName + ' ' + this.CreatedByUser.LastName;
      this.csvEmailSentDate = (this.EmailSentDate ? 'Yes' : 'No');
      this.csvRecommendedOn = this.getShortDate(this.RecommendedOn);
      this.csvExpectedResolutionDate = this.ExpectedResolutionDate !== null ? this.getShortDate(this.ExpectedResolutionDate) : null;
      this.CorporateName = actionLog.CorporateName;
      this.SystemName = actionLog.SystemName;
      this.CorporateCpId = actionLog.CorporateCpId;
    }
  }
  private getActionTakenString(at: ActionsTaken): string{
    let a = '';
    a += at.ActionTakenBy.FirstName + ' '+ at.ActionTakenBy.LastName + ' || ';
    a += this.getShortDate(at.ActionTakenOn) + ' || ';
    a += at.ActionDescription + "\n"
    return a;
  }

  private getShortDate(d: string): string {
    let dstr = new Date(d);
    return (dstr ? ((dstr.getMonth() + 1) + '/'+ dstr.getDate() + '/' + dstr.getFullYear()) : '' );
  }
}

export class ActionLogRecommendations implements IActionLogRecommendations {
  ContextPointName: string;
  ContextPointId: number;
  ContextPointTypeId: number;
  TotalSitesWithActions: number;
  TotalActions: number;
  TotalSafetyImpactActions: number;
  TotalHighImpactActions: number;
  TotalMediumImpactActions: number;
  TotalLowImpactActions: number;
  TotalOpenActions: number;
  TotalClosedActions: number;
  Recommendations: Array<ActionLog> = [];

  constructor(actionLogRecommendation?: IActionLogRecommendations) {
    if (actionLogRecommendation) {
      this.ContextPointName = actionLogRecommendation.ContextPointName;
      this.ContextPointId = actionLogRecommendation.ContextPointId;
      this.ContextPointTypeId = actionLogRecommendation.ContextPointTypeId;
      this.TotalSitesWithActions = actionLogRecommendation.TotalSitesWithActions;
      this.TotalActions = actionLogRecommendation.TotalActions;
      this.TotalSafetyImpactActions = actionLogRecommendation.TotalSafetyImpactActions;
      this.TotalHighImpactActions = actionLogRecommendation.TotalHighImpactActions;
      this.TotalMediumImpactActions = actionLogRecommendation.TotalMediumImpactActions;
      this.TotalLowImpactActions = actionLogRecommendation.TotalLowImpactActions;
      this.TotalOpenActions = actionLogRecommendation.TotalOpenActions;
      this.TotalClosedActions = actionLogRecommendation.TotalClosedActions;
      if (actionLogRecommendation.Recommendations && actionLogRecommendation.Recommendations.length) {
        actionLogRecommendation.Recommendations.forEach(actionlog => {
          this.Recommendations.push(new ActionLog(actionlog));
        });
      }
    }
  }
}

export class FilterActionLog implements IFilterActionLog {
  isServerCall: boolean = false;
  contextpointId: number = -1;
  siteId: number = -1;
  startDate: string = '';
  endDate: string = '';
  actionlogStatus: string = '';
  actionLogStatusId: number;
  impactStatus: string = '';
  impactStatusId: number = -1;
  assetId: number = -1;
  assetStatus: string = '';
  assetStatusId: number = -1;
  showAll: boolean = true;
  contextpointName: string = '';
  siteName: string = '';
  assetName: string = '';
  gridIndex: number = -1;
  isNavigatedFromWQ: boolean = false;
  recommendationsWithSavingsOnly: boolean;
  selectedBusinessUnit: string;
  constructor(fal?: IFilterActionLog) {
    if (fal) {
      this.isServerCall = fal.isServerCall;
      this.contextpointId = fal.contextpointId;
      this.siteId = fal.siteId;
      this.startDate = fal.startDate;
      this.endDate = fal.endDate;
      this.actionlogStatus = fal.actionlogStatus;
      this.actionLogStatusId = fal.actionLogStatusId;
      this.impactStatus = fal.impactStatus;
      this.impactStatusId = fal.impactStatusId;
      this.assetId = fal.assetId;
      this.assetStatus = fal.assetStatus;
      this.assetStatusId = fal.assetStatusId;
      this.showAll = fal.showAll;
      this.contextpointName = fal.contextpointName;
      this.siteName = fal.siteName;
      this.assetName = fal.assetName;
      this.gridIndex = fal.gridIndex;
      this.recommendationsWithSavingsOnly = fal.recommendationsWithSavingsOnly;
      this.selectedBusinessUnit = fal.selectedBusinessUnit;
    }
  }
}
export class SavingCategoryTypes implements ISavingCategoryTypes{
  SavingCategoryTypeId: number;
  Description: string;
  constructor(category?: ISavingCategoryTypes) {
    if (category) {
      Object.assign(this, category);
    }
  }
}
export class EroiTypes implements IEroiTypes{
  ERoiTypeId: number;
  ERoiValueType: string;
  ERoiTypeName: string;
  ERoiValueId: number;
  IsAmountEnabled: boolean;
  NalcoNumericsUnitOrSpecies: string;
  DefaultSubUnitUS: string;
  DefaultSubUnitMetric: string;
  ResourceKey: string;
  SubUnits: Array<UnitDetail>;
  constructor(eroiType?: IEroiTypes) {
    if (eroiType) {
      Object.assign(this, eroiType);
    }
  }
}
export class UnitDetail implements IUnitDetail {
  IsBaseUnit: boolean;
  SignificantDigits: number;
  Symbol: string;
  UnitName: string;
  constructor(unitDetail: IUnitDetail) {
    if(unitDetail) {
      Object.assign(this, unitDetail);
    }
  }
}
export class AccountDetail implements IAccountDetail{
  ContextPointId: number;
  ContextPointName: string;
  constructor(accountDetail?: IAccountDetail) {
    if (accountDetail) {
      Object.assign(this, accountDetail);
    }
  }
}


export class RecommendationSets {
  RecommendationCounts?: RecommendationCounts;
  Recommendations?: ActionLog[] = []// need a model -- create it at your own risk

  constructor(recommendationSets?: RecommendationSets) {
      if(!recommendationSets) { return; }
      this.RecommendationCounts = recommendationSets.RecommendationCounts
      if (recommendationSets.Recommendations) {
          recommendationSets.Recommendations.forEach(item => {
              this.Recommendations?.push(item)
          })
      }
  }
}

export class RecommendationCounts {
  TotalActions: number = 0;
  TotalSafetyImpactActions: number = 0;
  TotalHighImpactActions: number = 0;
  TotalMediumImpactActions: number = 0;
  TotalLowImpactActions: number = 0;
  TotalOpenActions: number = 0;
  TotalClosedActions: number = 0;
  TotalDeferredActions: number = 0

  constructor(recommendationCounts?: RecommendationCounts) {
      if(!recommendationCounts) { return; }
      Object.assign(this, recommendationCounts)
  }
}
