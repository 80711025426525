import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbTabChangeEvent, NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { LocalCacheService } from '../../core/local-cache/local-cache.service';
import { ConfirmationPopupComponent } from '../../shared/components/confirmation-popup/confirmation-popup.component';
import { ErrorHandling } from '../../shared/components/error-handling/error-handling';
import { XdComponentState } from '../../shared/components/xd-container/xd-component';
import { ComponentState } from '../../shared/components/xd-container/xd-component-state';
import { ConversionSource, ROTabs, Units, WaterAnalysisType, WaterChemistry, WaterChemistryTypes, pHAcids, pHBases } from '../_enum/constants';
import { IDropdownData } from '../contracts/IDropdownData';
import { DataProperties } from '../models/Data';
import { ROMeasures } from '../models/ROMeasures';
import { SubUnit } from '../models/SubUnit';
import { UnitConversion } from '../models/UnitConversion';
import { SharedRoService } from '../services/shared-ro.service';
import { DosageAndRecommendationComponent } from './dosage-and-recommendation/dosage-and-recommendation.component';
import { OperatingParameterComponent } from './operating-parameter/operating-parameter.component';
import { SystemComponent } from './system/system.component';
import { WaterChemistryComponent } from './water-chemistry/water-chemistry.component';

@Component({
  selector: 'nalco-ro-dashboard',
  templateUrl: './ro-dashboard.component.html',
  styleUrls: ['./ro-dashboard.component.scss']
})
export class RoDashboardComponent extends XdComponentState implements OnInit, OnDestroy {
  isError: any;
  @ViewChild('tabset', { static: false }) private tabset: NgbTabset;
  @ViewChild(OperatingParameterComponent, { static: false }) private opTabForm: OperatingParameterComponent;
  @ViewChild(WaterChemistryComponent, { static: false }) private wcTabForm: WaterChemistryComponent;
  @ViewChild(DosageAndRecommendationComponent, { static: false }) private drTabForm: DosageAndRecommendationComponent;
  @ViewChild(SystemComponent, { static: false }) private system: SystemComponent;
  @Input() disableST;
  @Input() componentReady;
  @Input() siteId;
  @Output() siteChanged = new EventEmitter<any>();
  @Output() siteCleared = new EventEmitter<any>();
  @Output() leaveEvent = new EventEmitter<boolean>();
  modalRef;
  disableTabs = true;
  disableAllTabs = false;
  scenarioData: ROMeasures;
  dimensionalUsageKeys: any;
  roMappingData: any;
  errorInfo: any;
  paramData = new Array<any>();
  data = new Array<any>();
  convertedValues: Array<UnitConversion>;
  subUnits: Array<SubUnit>;
  dropdownValue: string;
  inputValue: number;
  dropdownData: Array<IDropdownData>;
  opTabData: Array<DataProperties> = new Array<DataProperties>();
  wcTabData: Array<DataProperties> = new Array<DataProperties>();
  drTabData: Array<DataProperties> = new Array<DataProperties>();
  renderData: DataProperties = new DataProperties();
  subscription: Subscription;
  pHControls: Array<any> = [
    { key: 'None', text: 'None' },
    { key: 'HCl (31%)', text: 'HCl (31%)' },
    { key: 'NaOH (50%)', text: 'NaOH (50%)' },
    { key: 'H2SO4 (98%)', text: 'H2SO4 (98%)' },
  ];
  errorHandling = new ErrorHandling(this.modalService, this.translate);
  headerDetails: any = {
    'mainTitle': 'system'
  };
  formErrors: any[];
  commonParams = {};
  opErrorTab: boolean = false;
  wcErrorTab: boolean = false;
  drErrorTab: boolean = false;
  siteEvent: boolean = false;
  isScenarioAtOIP: boolean;
  chargeBalance = {};
  navigateToOpTab: boolean;
  iscbFormPristine: boolean = true;
  scenarioEmitted: boolean = false;
  isSaved: boolean = false;
  initialData: any;
  siteDetails: any;
  unitSelected: string;

  constructor(private roService: SharedRoService,
    private router: Router,
    private translate: TranslateService,
    private modalService: NgbModal,
    private localCacheService: LocalCacheService,
    ) {
    super();
  }

  ngOnInit() {
    this.setState(ComponentState.ready);
    this.getDimesionalUsageKeys();
    this.getMappingJson();
    this.roService.tabForms.subscribe(form => {
      if (form) {
        this.iscbFormPristine = form['chargeBalance'] ? false : true;
      }
    });
  }

  myParentInstance(): RoDashboardComponent{
    return this;
  }

  getDimesionalUsageKeys() {
    this.roService.GetDimensionalUsageKeys().subscribe(
      response => {
        if (response) {
          this.dimensionalUsageKeys = response;
        }
      }, error => {
        this.isError = true;
        this.errorInfo = error;
        this.setState(ComponentState.ready);
        if (500 <= error.status && error.status < 600) {
          this.setState(ComponentState.error);
        } else if (error.status === 417) {
          this.router.navigate(['/access-denied']);
        } else if (error.status === 404) {
          this.router.navigate(['/**']);
        } else {
          this.setState(ComponentState.error);
          this.errorHandling.showErrorPopup(error);
        }
      }
    );
  }

  getMappingJson() {
    this.roService.getROMappingJson().subscribe(
      response => {
        this.roMappingData = response;
      });
  }

  getFormValidationErrors(form, data, tabName) {
    this.formErrors = [];
    if (form !== null && form !== undefined) {
      Object.keys(form.controls).forEach(key => {
        const controlErrors: ValidationErrors = form.get(key).errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach(keyError => {
            const errTyp = controlErrors[keyError];
            if (key == 'pHSetPoint') {
              this.wcTabForm.setPointError = true;
            } else {
              data.forEach(x => {
                if (x.DisplayName == 'Raw pH' && x.InputParam.FormControlName == key) {
                  x.Valid = false;
                }
                else if (x.DisplayName.concat('tb') == key) {
                  x.Valid = false;
                }
              });
            }
            let control = key == 'pHSetPoint' ? key : key.substring(0, (key.length - 2));
            if (keyError === 'required') {
              this.formErrors.push('<p>' + control + ' is ' + keyError + '</p>')
            } else {
              if (control == 'pHSetPoint') {
                let phControl = tabName == ROTabs[1] ? form.controls['pHControl'].value : form.controls['pHControlProductNamedd'].value;
                let ph = this.wcTabForm.waterChemistryForm.controls['pHtb'] ? this.wcTabForm.waterChemistryForm.controls['pHtb'].value : this.scenarioData.InputStream.filter(x => x.Name == 'pH')[0].Value;
                let phSetPoint = tabName == ROTabs[1] ? form.controls['pHSetPoint'].value : form.controls['pHSetPointtb'].value;
                if ((Number(phSetPoint) < 6.5 || Number(phSetPoint) > 12) && ((tabName == ROTabs[1] && this.wcTabForm.waterChemistryForm.controls['pHtb'].errors == null) || tabName == ROTabs[2])) {
                  this.formErrors.push('<p>' + this.translate.instant('RO_OPTIMIZER.DASHBOARD.PH_SETPOINT_RANGE') + '</p>');
                }
                else {
                  if ((tabName == ROTabs[1] && this.wcTabForm.waterChemistryForm.controls['pHtb'].errors == null) || tabName == ROTabs[2]) {
                    if (pHAcids.includes(phControl)) {
                      this.formErrors.push('<p>' + this.translate.instant('RO_OPTIMIZER.DASHBOARD.PH_ACID_ERROR_MESSAGE').concat(ph) + '</p>');
                    } else if (pHBases.includes(phControl)) {
                      this.formErrors.push('<p>' + this.translate.instant('RO_OPTIMIZER.DASHBOARD.PH_BASE_ERROR_MESSAGE').concat(ph) + '</p>');
                    }
                  }
                }
              } else {
                if (keyError == 'max') {
                  if (control == 'Days/Year' || control == 'Hours/Day' || control == 'pH') {
                    this.formErrors.push('<p>' + control + ' should be less than or equal to' + ' ' + errTyp[keyError] + '</p>');
                  }
                  else {
                    this.formErrors.push('<p>' + control + ' should be less than' + ' ' + errTyp[keyError] + '</p>');
                  }
                }
                else if (keyError == 'min') {
                  if (control == 'pH') {
                    this.formErrors.push('<p>' + control + ' should be more than or equal to' + ' ' + errTyp[keyError] + '</p>');
                  }
                  else {
                    this.formErrors.push('<p>' + control + ' should be more than' + ' ' + errTyp[keyError] + '</p>');
                  }
                }
              }
            }
          });
        }
      });
      this.formErrors = this.formErrors.filter((item, index, errors) => {
        return errors.indexOf(item) == index;
      });
      return this.formErrors.length > 0 ? true : false;
    }
  }


  onTabChange(event: NgbTabChangeEvent) {
    let tabForm;
    let data;
    let tabName;
    if (event.activeId == 'operatingParameter') {
      tabForm = this.opTabForm.operatingParameterForm;
      data = this.opTabData;
      tabName = ROTabs[0];
    } else if (event.activeId == 'waterChemistry') {
      tabForm = this.wcTabForm.waterChemistryForm;
      data = this.wcTabData;
      tabName = ROTabs[1];
    } else if (event.activeId == 'dosageAndRecommendation') {
      tabForm = this.drTabForm.dosageAndRecommendationForm;
      data = this.drTabData;
      tabName = ROTabs[2];
    } else {
      tabForm = null;
      data = [];
      tabName = null;
    }

    if (this.getFormValidationErrors(tabForm, data, tabName) && !this.siteEvent) {
      if (event.activeId == 'operatingParameter') {
        this.opErrorTab = true;
      } else if (event.activeId == 'waterChemistry') {
        this.wcErrorTab = true;
      } else if (event.activeId == 'dosageAndRecommendation') {
        this.drErrorTab = true;
      }
      this.showErrorModal();
      event.preventDefault();
    } else {
      this.opErrorTab = false;
      this.wcErrorTab = false;
      this.drErrorTab = false;
    }
    this.headerDetails.mainTitle = event.nextId;
    if (event.nextId == 'operatingParameter') {
      this.roService.setOperatingParamData(true);
    } else if (event.nextId == 'waterChemistry') {
      this.roService.setWaterChemistryData(true);
    } else if (event.nextId == 'dosageAndRecommendation') {
      if (this.opErrorTab == false && this.wcErrorTab == false) {
        this.roService.setDosageAndRecommendationData(true);
        this.drTabForm.getData();
      }
    }
  }

  getScenarioData($event) {
    this.navigateToOpTab = $event !== undefined && $event !== null ? true : false;
    this.scenarioData = new ROMeasures();
    this.resetValidations(this.opTabForm.operatingParameterForm);
    this.resetValidations(this.wcTabForm.waterChemistryForm);
    this.resetValidations(this.drTabForm.dosageAndRecommendationForm);
    this.opTabForm.optdataCount = 0;
    this.wcTabForm.wctdataCount = 0;
    this.drTabForm.drtdataCount = 0;
    this.isScenarioAtOIP = this.system.isScenarioAtOIP;
    this.componentReady = false;
    this.setState(ComponentState.loading);
    this.scenarioData = $event;
    this.initialData = JSON.stringify(this.scenarioData);
    this.scenarioEmitted = true;
    this.isSaved = false;
    this.chargeBalance['ionDetails'] = this.scenarioData.ChargeBalanceAdded;
    this.chargeBalance['updated'] = true;
    this.drTabForm.isSaveDisabled = true;
    this.roService.changeWaterChemistry(this.scenarioData.OperatingParameters.filter(x => x.Name == WaterChemistry)[0].Value);
    this.resetValidations(this.wcTabForm.waterChemistryForm);
    this.roService.changeWaterAnalysisType(this.scenarioData.OperatingParameters.filter(x => x.Name == WaterAnalysisType)[0].Value);
    this.roService.changepHControlProduct(this.scenarioData.OperatingParameters.filter(x => x.Name == 'pHControlProductName')[0].Value);
    this.roService.changepHSetPoint(this.scenarioData.OperatingParameters.filter(x => x.Name == 'pHSetPoint')[0].Value);
    this.getSubUnits();
  }

  getTabStatus($event) {
    this.disableTabs = $event;
    this.opTabData = [];
    this.wcTabData = [];
    this.drTabData = [];
  }

  getLoaderStatus(event) {
    this.disableAllTabs = event == true ? true : false;
  }

  getSubUnits() {

    ROTabs.forEach(tab => {
      this.roMappingData[tab].forEach(element => {
        if (element.Type == 'combo-box') {
          this.paramData.push({ DimensionalKey: 'Roo_' + element.Key });
        }
      });
    });

    this.roService.GetUnitsByDimensionalKey(this.paramData).subscribe(
      response => {
        if (response) {
          this.subUnits = response;
          if (this.scenarioEmitted) {
            this.getConvertedValues(this.scenarioData.ScenarioUnit);
          }
          this.subscription = this.roService.preferredUnitChangeEvnt.subscribe(unit => {
            this.unitSelected = unit;
            if (!this.scenarioEmitted) {
              this.resetValidations(this.opTabForm.operatingParameterForm);
              this.resetValidations(this.wcTabForm.waterChemistryForm);
              this.resetValidations(this.drTabForm.dosageAndRecommendationForm);
              this.opTabForm.optdataCount = 0;
              this.wcTabForm.wctdataCount = 0;
              this.drTabForm.drtdataCount = 0;
              this.convertDataToScenario(unit, 'unitChange');
            }
          });
        } else {
          this.setState(ComponentState.empty);
        }
      }, error => {
        this.errorInfo = error;
        this.setState(ComponentState.ready);
        if (500 <= error.status && error.status < 600) {
          this.setState(ComponentState.error);
        } else if (error.status === 417) {
          this.router.navigate(['/access-denied']);
        } else if (error.status === 404) {
          this.router.navigate(['/**']);
        } else {
          this.setState(ComponentState.error);
          this.errorHandling.showErrorPopup(error);
        }
      }
    );
  }
  convertDataToScenario(unit, eventType, siteDetails?) {
    if (unit == null && eventType == 'siteChangeEvent') {
      this.siteDetails = siteDetails;
    }
    let data = [];
    let allData = [...this.opTabForm.opData, ...this.wcTabForm.wcData, ...this.drTabForm.drData];
    if (this.isSaved && !this.checkFormPristine()) {
      this.isSaved = false;
    }
    if (allData.length > 0 && !this.isSaved) {
      allData.forEach(y => {
        if (y.Type == 'combo-box') {
          let control = y.InputParam.FormControlName.substring(0, y.InputParam.FormControlName.length - 2);
          let key = this.dimensionalUsageKeys.configurations.filter(x => x.dimensionalUsageKey == 'Roo_' + control)[0];
          let toUnit = unit == Units[0] ? key.DefaultSubUnitMetric : key.DefaultSubUnitUS;
          let fromUnit = y.DropdownParam.Value;
          let input = y.InputParam.Value;
          data.push({
            UnitType: key.UnitType,
            FromUnit: fromUnit,
            ToUnit: eventType == 'unitChange' ? toUnit : '',
            OriginalValue: input,
            ParameterName: control,
            ConversionSource: key.ConversionSource
          });
        } else if (y.DisplayName == 'pH' || y.DisplayName == 'Raw pH' || y.DisplayName == 'Concentrate pH') {
          this.scenarioData.InputStream.filter(x => x.Name == 'pH')[0].Value = y.InputParam.Value;
        } else if (y.DisplayName != 'Water Chemistry') {
          let name = y.DisplayName == 'pH Control' ? 'pHControlProductName' : (y.DisplayName == 'pH Setpoint' ? 'pHSetPoint' : y.DisplayName);
          this.scenarioData.OperatingParameters.filter(x => x.Name == name)[0].Value = y.DisplayName == 'pH Control' ? (y.DropdownParam.Value == 'None' ? null : y.DropdownParam.Value) : y.InputParam.Value;
          if (y.DisplayName == 'pH Setpoint') {
            this.scenarioData.ReferenceCondition.pHSetPoint.Value = y.InputParam.Value;
          }
        }
      });
      this.scenarioData.ChargeBalanceAdded = this.wcTabForm.chargeBalanceInfo.ionDetails ? this.wcTabForm.chargeBalanceInfo.ionDetails : this.scenarioData.ChargeBalanceAdded;
      this.chargeBalance['ionDetails'] = this.scenarioData.ChargeBalanceAdded;
      this.chargeBalance['updated'] = true;
      this.scenarioData.ScenarioUnit = this.unitSelected !== undefined ? this.unitSelected : this.scenarioData.ScenarioUnit;
      this.getConvertedValues(unit, data, eventType);
    } else {
      this.leaveEvent.emit(true);
    }
  }

  mapTabMeasurements(unit) {
    let wat = this.scenarioData.OperatingParameters.filter(x => x.Name == WaterAnalysisType)[0];
    ROTabs.forEach(tab => {
      this.roMappingData[tab].forEach(element => {
        this.dropdownData = [];
        this.dropdownValue = null;
        this.inputValue = null;
        this.renderData = new DataProperties();
        if (tab == 'WaterChemistry' && !element.WAT.includes(wat.Value)) {
          return;
        }
        let obj = this.scenarioData[element.Source].filter(x => x.Name === element.Key)[0];

        this.renderData.DisplayName = element.DisplayName;
        this.renderData.Type = element.Type;
        this.renderData.Valid = true;

        if (element.Type !== 'drop-down' && element.Type !== 'label') {
          if (element.Type == 'combo-box') {
            this.inputValue = this.convertedValues.filter(x => x.ParameterName == element.Key)[0].ConvertedValue;
          } else {
            this.inputValue = obj !== null && obj !== undefined ? obj.Value : '';
          }
          this.renderData.InputParam = {
            Value: this.inputValue,
            Required: element.Required,
            FormControlName: element.Key + 'tb'
          }
        }
        if (element.Type !== 'input' && element.Type !== 'label') {
          if (element.Key == 'pHControlProductName') {
            this.dropdownData = this.pHControls;
            this.dropdownValue = (obj !== null && obj !== undefined) ? (obj.Value == null ? this.pHControls[0].key : obj.Value) : '';
          } else {
            let units = this.subUnits.filter(u => u.DimensionalKey == 'Roo_' + element.Key)[0];
            units.SubUnits.forEach(x => this.dropdownData.push({ key: x.UnitName, text: x.Symbol }));
            let key = this.dimensionalUsageKeys.configurations.filter(x => x.dimensionalUsageKey == 'Roo_' + element.Key)[0];
            this.dropdownValue = unit == Units[0] ? key.DefaultSubUnitMetric : key.DefaultSubUnitUS;
          }
          this.renderData.DropdownParam = {
            ddData: this.dropdownData,
            Value: this.dropdownValue,
            Required: element.Required,
            FormControlName: element.Key + 'dd'
          }
        }

        if (element.Type == 'label') {
          this.renderData.InputParam = {
            Value: (obj !== null && obj !== undefined) ? (obj.Value == WaterChemistryTypes[0] ? 'Raw Water' : 'Concentrate') : '',
            Required: element.Required,
            FormControlName: ''
          }
        }

        if (tab == ROTabs[0]) {
          this.opTabData.push(this.renderData);
        } else if (tab == ROTabs[1]) {
          this.wcTabData.push(this.renderData);
        } else {
          this.drTabData.push(this.renderData);
        }
      });
      this.opTabData.forEach(key => {
        // key.InputParam.Value = key.InputParam.Value % 1 != 0 ? Math.round(key.InputParam.Value).toFixed(6) : key.InputParam.Value // For the round off the decimal values 
        if (key.DisplayName == "Temperature" || key.DisplayName == "Raw Water Flow" || key.DisplayName == "Recovery") {
          let obj = {};
          obj['DropdownValue'] = key.DropdownParam.Value;
          obj['InputValue'] = key.InputParam.Value;
          obj['FormControlName'] = key.InputParam.FormControlName;
          obj['updated'] = false;
          this.commonParams[key.DisplayName] = obj;
        }
      });
      
      this.commonParams['updated'] = true;
      this.roService.setCommonParams(this.commonParams);
      this.setState(ComponentState.ready);
      this.componentReady = true;
      this.disableTabs = false;
    });
  }

  

  inputParam(unit) {
    this.data = [];
    let value;
    let fromUnit;
    ROTabs.forEach(tab => {
      this.roMappingData[tab].forEach(element => {
        if (element.Type == 'combo-box') {
          let key = this.dimensionalUsageKeys.configurations.filter(x => x.dimensionalUsageKey == 'Roo_' + element.Key)[0];
          let conversion = unit == Units[0] ? key.DefaultSubUnitMetric : key.DefaultSubUnitUS;
          if (this.scenarioData.ScenarioUnit == unit) {
            let obj = this.scenarioData[element.Source].filter(x => x.Name === element.Key)[0];
            value = obj !== null && obj !== undefined ? obj.Value : '';
            fromUnit = null;
          } else {
            let obj;
            if (tab == ROTabs[0]) {
              obj = this.opTabData.filter(x => x.DisplayName == element.DisplayName)[0];
            } else if (tab == ROTabs[1]) {
              obj = this.wcTabData.filter(x => x.DisplayName == element.DisplayName)[0];
            } else {
              obj = this.drTabData.filter(x => x.DisplayName == element.DisplayName)[0];
            }
            value = (obj != undefined && obj != null) ? obj.InputParam.Value : obj;
            fromUnit = this.scenarioData.ScenarioUnit == Units[0] ? key.DefaultSubUnitMetric : key.DefaultSubUnitUS;
          }
          this.data.push({
            UnitType: key.UnitType,
            FromUnit: fromUnit,
            ToUnit: conversion,
            OriginalValue: value,
            ParameterName: element.Key,
            ConversionSource: key.ConversionSource
          });
        }
      });
    });
  }

  checkForm(response, eventType) {
    response.forEach(res => {
      if (res.ConversionSource == ConversionSource) {
        if (this.scenarioData.InputStream.filter(y => y.Name == res.ParameterName)[0]) {
          this.scenarioData.InputStream.filter(y => y.Name == res.ParameterName)[0].Value = res.ConvertedValue;
        } else if (res.ParameterName != 'pH') {
          let type = ('Ecolab.Modeling.Species.').concat(res.ParameterName);
          this.scenarioData.InputStream.push({ Name: res.ParameterName, Type: type, Value: res.ConvertedValue });
        }
      } else {
        this.scenarioData.OperatingParameters.filter(y => y.Name == res.ParameterName)[0].Value = res.ConvertedValue;
        this.scenarioData.ReferenceCondition.Recovery.Value = ('Recovery' === res.ParameterName) ? res.ConvertedValue : this.scenarioData.ReferenceCondition.Recovery.Value;
        this.scenarioData.ReferenceCondition.Temperature.Value = ('Temperature' === res.ParameterName) ? res.ConvertedValue : this.scenarioData.ReferenceCondition.Temperature.Value;
      }
    });
    this.initialData = JSON.parse(this.initialData);
    this.initialData.InputStream = this.drTabForm.sortStream(this.initialData.InputStream);
    let updatedData = this.scenarioData;
    updatedData.InputStream = updatedData.InputStream.filter(x => x.Value != null)
    updatedData.InputStream = this.drTabForm.sortStream(updatedData.InputStream);
    this.initialData = JSON.stringify(this.initialData);
    if (eventType == 'siteChangeEvent') {
      if (this.initialData != JSON.stringify(updatedData)) {
        this.siteDetails['formStatus'] = true;
        this.siteChanged.emit(this.siteDetails);
      } else {
        this.siteDetails['formStatus'] = false;
        this.wcTabForm.waterChemistryForm.markAsPristine();
        this.opTabForm.operatingParameterForm.markAsPristine();
        this.drTabForm.dosageAndRecommendationForm.markAsPristine();
        this.siteChanged.emit(this.siteDetails);
      }
    } else if (eventType == 'leaveEvent') {
      if (this.initialData != JSON.stringify(updatedData)) {
        this.leaveEvent.emit(false);
      } else {
        this.leaveEvent.emit(true);
      }
    }
  }

  getConvertedValues(unit, toScenario?, eventType?) {
    if (toScenario) {
      this.data = toScenario;
    } else {
      this.inputParam(unit);
    }
    this.roService.GetConversionByUnitType(this.data).subscribe(
      response => {
        if (response) {
          this.convertedValues = response;
          if (!toScenario) {
            this.scenarioEmitted = false;
            if (this.navigateToOpTab == true) {
              setTimeout(() => {
                this.tabset.select("operatingParameter");
              }, 1)
              this.navigateToOpTab = false;
            }
          }
          if (unit) {
            this.getTabStatus(false);
            this.mapTabMeasurements(unit);
          } else {
            this.checkForm(response, eventType);
          }
        } else {
          this.setState(ComponentState.empty);
        }
      }, error => {
        this.errorInfo = error;
        this.setState(ComponentState.ready);
        if (500 <= error.status && error.status < 600) {
          this.setState(ComponentState.error);
        } else if (error.status === 417) {
          this.router.navigate(['/access-denied']);
        } else if (error.status === 404) {
          this.router.navigate(['/**']);
        } else {
          this.setState(ComponentState.error);
          this.errorHandling.showErrorPopup(error);
        }
      }
    );
  }


  showErrorModal(event?, type?, data?): boolean {
    const errorMessage = this.formErrors.join('');
    this.formErrors = [];
    this.modalRef = this.modalService.open(ConfirmationPopupComponent, {
      backdrop: 'static',
      keyboard: false,
      windowClass: 'saveExit-modal error-modal'
    }).componentInstance;
    this.modalRef.modalText = this.translate.instant('ASSET_HARVESTER.MESSAGE.ATTENTION');
    this.modalRef.modcontent = errorMessage;
    this.modalRef.localDriverBtn = true;
    this.modalRef.disablebtn = true;
    this.modalRef.closeAsCancel = true;
    this.modalRef.closeIcon = true;
    this.modalRef.actionBtn = this.translate.instant('COMMON.OK');
    this.modalRef.submitModal.subscribe(($event) => {
      return false;
    }, error => {
      this.translate.get('COMMON.GENERIC_ERROR_MESSAGE').subscribe(
        res => {
        }
      );
    });
    return false;
  }

  getErrorTabStatus(event) {
    if (event.tab == ROTabs[0]) {
      this.opErrorTab = event.status;
    } else if (event.tab == ROTabs[1]) {
      this.wcErrorTab = event.status;
    } else {
      this.drErrorTab = event.status;
    }
  }

  getsaveStatus(event) {
    if (event.status == true) {
      this.showModal(event);
    }
  }

  showModal(event?, type?, data?): boolean {
    let saveModal = event.status !== undefined ? event.status : false;
    this.modalRef = this.modalService.open(ConfirmationPopupComponent, {
      backdrop: 'static',
      keyboard: false,
      windowClass: 'saveExit-modal'
    }).componentInstance;
    this.modalRef.alertIcon = saveModal ? false : true;
    this.modalRef.modalText = saveModal ? '' : this.translate.instant('ASSET_HARVESTER.MESSAGE.ATTENTION');
    this.modalRef.modcontent = saveModal ? this.translate.instant('RO_OPTIMIZER.DASHBOARD.SAVE_SUCCESS') : this.translate.instant('ASSET_HARVESTER.MESSAGE.DISCARD_MESSAGE');
    this.modalRef.localDriverBtn = saveModal ? true : false;
    this.modalRef.disablebtn = true;
    this.modalRef.closeAsCancel = true;
    this.modalRef.closeIcon = saveModal ? false : true;
    this.modalRef.actionBtn = this.translate.instant('COMMON.OK');
    this.modalRef.cancelModal.subscribe(($event) => {
      this.siteCleared.emit(true);
      return false;
    }, error => {
      this.translate.get('COMMON.GENERIC_ERROR_MESSAGE').subscribe(
        res => {
        }
      );
    });
    this.modalRef.submitModal.subscribe(($event) => {
      this.opTabForm.operatingParameterForm.markAsPristine();
      this.wcTabForm.waterChemistryForm.markAsPristine();
      this.drTabForm.dosageAndRecommendationForm.markAsPristine();
      if (!saveModal) {
        this.siteEvent = true;
        this.resetForm();
        event['formStatus'] = false;
        this.siteChanged.emit(event);
      } else {
        let id = JSON.parse(this.localCacheService.fetchData('msalUserInfo')).EmailAddress;
        this.isSaved = true;
        this.drTabForm.allForms = null;
        this.system.isScenarioAtOIP = true;
        this.system.disableOIP = false;
        this.system.oipScenario.rOOptimizerScenarioInfo = event.scenario;
        this.system.lastSavedInfo = this.translate.instant('RO_OPTIMIZER.SYSTEM.LAST_SAVED_INFO') + id + ' on ' + moment(new Date).format('YYYY/MM/DD HH:mm');
      }
      return false;
    }, error => {
      this.translate.get('COMMON.GENERIC_ERROR_MESSAGE').subscribe(
        res => {
        }
      );
    });
    return false;
  }

  Validation(event) {
    if (event.component == ROTabs[1]) {
      this.formErrors.push(event.message);
    } else if (event.component == ROTabs[2]) {
      this.getFormValidationErrors(this.drTabForm.dosageAndRecommendationForm, this.drTabData, ROTabs[2]);
    }
    this.showErrorModal();
  }

  resetForm() {
    this.tabset.select('system');
    if (this.system) {
      this.system.scenariosDropdown = [];
      this.getTabStatus(true);
      this.siteEvent = false;
      this.system.systemDetailsForm.reset();
      this.system.disableOIP = true;
      this.system.disablePRO = true;
      this.opTabForm.operatingParameterForm.reset();
      this.wcTabForm.waterChemistryForm.reset();
      this.drTabForm.dosageAndRecommendationForm.reset();
      this.drTabForm.isSaveDisabled = true;
    }
  }

  resetValidations(form) {
    Object.keys(form.controls).forEach(key => {
      form.removeControl(key);
    });
    Object.keys(form.controls).forEach(key => {
      form.get(key).clearValidators();
    });
    Object.keys(form.controls).forEach(key => {
      form.get(key).setErrors(null);
    });
  }

  checkFormPristine() {
    return this.opTabForm.operatingParameterForm.pristine && this.wcTabForm.waterChemistryForm.pristine && this.drTabForm.dosageAndRecommendationForm.pristine;
  }


  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
