import { ContextPoint } from './../../../../asset-harvester/_models/Asset';
import { SharedService } from './../../../services/shared-services.service';
import { Component, OnInit, OnChanges, Input, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { IDropdown, IDropdownOption } from '../../dropdown/dropdown-model';
import * as contracts from '../../../contracts/_contracts';
import { TranslateService } from '../../../../../../node_modules/@ngx-translate/core';
import { Subscription } from 'rxjs/Subscription';
import { LocalCacheService } from '../../../../core/local-cache/local-cache.service';
import { UserService } from '../../../../core/user/user.service';

@Component({
  selector: 'nalco-geo-sales-region-filter',
  templateUrl: './geo-sales-region-filter.component.html',
  styleUrls: ['./geo-sales-region-filter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GeoSalesRegionFilterComponent implements OnInit {
  @Input() set countryData(value) {
    if (value.length !== 0) {
      this.regionCountryData = value;
      this.getRegions();
    }
  }
  @Input() set territoriesData(value) {
    if (value.length !== 0) {
      this.salesRegionDistrictTerritoriesData = value;
      this.getSalesRegion();
    }
  }
  @Output('filterObjChange') filterObjChange = new EventEmitter<any>();

  regionCountryData: Array<contracts.IRegionCountries>;
  salesRegionDistrictTerritoriesData: Array<contracts.ISalesRegionDistrictTerritories>;
  regionDData: Array<IDropdownOption> = [];
  countryDData: Array<IDropdownOption> = [];
  salesRegionDData: Array<IDropdownOption> = [];
  salesDistrictDData: Array<IDropdownOption> = [];
  salesTerritoryDData: Array<IDropdownOption> = [];
  selectedRegion: string;
  showRegions = false;
  showCountries = true;
  showSalesRegions = false;
  showSalesDistricts = true;
  public StoredRegion = {
    'contextPointName': '',
    'contextPointId': 0
  }

  private subscriptions: Subscription[] = [];
  showSalesTerritories = true;

  selectGeoRegion: IDropdown = {
    label: this.translate.instant('COMMON.SELECT_REGION'),
    name: '',
    options: this.regionDData
  };

  selectGeoCountry: IDropdown = {
    label: this.translate.instant('COMMON.SELECT_COUNTRY'),
    name: '',
    options: this.countryDData
  };

  selectSalesRegion: IDropdown = {
    label: this.translate.instant('COMMON.SELECT_SALES_REGION'),
    name: '',
    options: this.salesRegionDData
  };

  selectSalesDistrict: IDropdown = {
    label: this.translate.instant('COMMON.SELECT_SALES_DISTRICT'),
    name: '',
    options: this.salesDistrictDData
  };

  selectSalesTerritory: IDropdown = {
    label: this.translate.instant('COMMON.SELECT_SALES_TERRITORY'),
    name: '',
    options: this.salesTerritoryDData
  };

  private filterObject: any = {
    'parentId': '',
    'parentName': '',
    'children': []
  };

  constructor(private translate: TranslateService,
    private localCacheService: LocalCacheService,
    private userService: UserService,
    private sharedService: SharedService) {
  }

  ngOnInit() {
    this.filterObjChange.emit(this.filterObject);
    this.getRegionCountriesData();
    this.getSalesRegionDistrictTerritoriesData();
  }

  getRegionCountriesData() {
    let fetchData = this.localCacheService.fetchData('RegionCountriesData');
    if (fetchData) {
      this.countryData = JSON.parse(fetchData);
    } else {
      this.subscriptions.push(this.sharedService.getRegionCountries().subscribe(
        response => {
          this.countryData = response;
          this.localCacheService.storeData('RegionCountriesData', JSON.stringify(response));
        }
      ));
    }
  }

  getSalesRegionDistrictTerritoriesData() {
    let fetchData = this.localCacheService.fetchData('SalerRegionDistrictTerritories');
    if (fetchData) {
      this.territoriesData = JSON.parse(fetchData);
    } else {
      this.subscriptions.push(this.sharedService.getSalerRegionDistrictTerritories().subscribe(
        response => {
          this.territoriesData = response;
          this.localCacheService.storeData('SalerRegionDistrictTerritories', JSON.stringify(response));
        }
      ));
    }
  }


  getSelectedData(searchText) {
    let details = this.userService.getFilterDetails();
    let selectedData = {
      'contextPointName': '',
      'contextPointId': 0
    }

    if (details) {
      let SelectedfilterObject = details.filterObject;
      if (SelectedfilterObject && SelectedfilterObject.children && SelectedfilterObject.parentId == '' && SelectedfilterObject.parentName == '') {
        let getLabel = SelectedfilterObject.children.map(item => item.label)
        let selectedTextIndex = getLabel.indexOf(searchText);
        if (selectedTextIndex >= 0 && SelectedfilterObject.children[selectedTextIndex].label) {
          selectedData.contextPointName = SelectedfilterObject.children[selectedTextIndex].contextPointName
          selectedData.contextPointId = SelectedfilterObject.children[selectedTextIndex].contextPointId;
          return selectedData;
        }
      }
    }
  }

  getUniqueRegions(regionCountries: contracts.IRegionCountries[], key: string): any {
    const regionsObj = regionCountries.reduce((regionCountryObj, region) => {
      let keyString = region[key];
      if (regionCountryObj[keyString] == undefined) {
        regionCountryObj[keyString] = region;
      }
      return regionCountryObj;
    }, {});
    return Object.values(regionsObj);
  }

  getRegions() {
    const uniqRegion = this.getUniqueRegions(this.regionCountryData, 'RegionId');

    this.StoredRegion = this.getSelectedData('Select Region');

    uniqRegion.forEach(item => {
      let isSelecteddata = false
      if (this.StoredRegion && (item.RegionId == this.StoredRegion.contextPointId)) {
        isSelecteddata = true
      }
      if (isSelecteddata) {
        setTimeout(() => {
          this.populateRegions({ name: "", value: item.RegionId, label: "Select Region", text: item.RegionName })
        }, 100);
      }
      this.regionDData.push({
        text: item.RegionName,
        value: item.RegionId,
        isSelected: isSelecteddata
      });
    });
  }

  populateRegions(event) {
    if (event.value) {
      this.showCountries = false;
      this.showSalesRegions = true;
      this.countryDData.length = 0;
      this.emitData(event);
      this.updateCountries(event.value);
    }
  }

  populateCountries(event) {
    if (event.value) {
      this.emitData(event);
    }
  }

  updateCountries(currentRegion) {

    let SelectedContries = this.getSelectedData('Select Country');


    this.regionCountryData.forEach(item => {
      if (item.RegionId === currentRegion) {
        let isSelected = false;
        if (SelectedContries && item.CountryName == SelectedContries.contextPointName) {
          isSelected = true;
        }
        this.countryDData.push({
          text: item.CountryName,
          value: item.CountryId.toString(),
          isSelected: isSelected
        });
      }
    });
  }

  getSalesRegion() {

    let SelectedSalesRegion = this.getSelectedData('Select Sales Region');

    this.salesRegionDistrictTerritoriesData.forEach(item => {
      if (item.ContextPointSubTypeName === 'SalesRegion') {
        let isSelected = false;
        if (SelectedSalesRegion && (SelectedSalesRegion.contextPointName == item.ContextPointName)) {
          isSelected = true
          setTimeout(() => {
            this.populateSalesRegions({ name: "", value: item.ContextPointId, label: "Select Sales Region", text: item.ContextPointName });
          }, 100);
        }
        this.salesRegionDData.push({
          text: item.ContextPointName,
          value: item.ContextPointId.toString(),
          isSelected: isSelected
        });
      }
    });
  }

  populateSalesRegions(event) {
    if (event.value) {
      this.showRegions = true;
      this.showSalesDistricts = false;
      this.showSalesTerritories = true;
      this.clearSelectedFilter(this.salesTerritoryDData);
      this.emitData(event);
      this.updateDistrict(event, this.salesDistrictDData, this.salesRegionDistrictTerritoriesData, 'SalesRegion');
    }
  }

  populateSalesDistricts(event) {
    if (event.value) {
      this.showSalesTerritories = false;
      this.emitData(event);
      this.updateDistrict(event, this.salesTerritoryDData, this.salesRegionDistrictTerritoriesData, 'District');
    }
  }

  populateTerritories(data) {
    if (data.value) {
      this.emitData(data);
    }
  }

  updateDistrict(currentEventData, dropdownData, salesCurrentData, regionType) {
    let selectedData;
    let territoryData;
    if (regionType === 'SalesRegion') {
      selectedData = this.getSelectedData('Select Sales District');
    }
    else {
      selectedData = this.getSelectedData('Select Sales Territory');
    }
    let parentID = '';
    dropdownData.length = 0;
    salesCurrentData.forEach(it => {
      if (it.ContextPointName === currentEventData.text && it.ContextPointSubTypeName === regionType) {
        parentID = it.ContextPointId;
      }
    });
    salesCurrentData.forEach(item => {
      if (item.ParentId === parentID) {
        let isSelected = false;
        if (selectedData && (item.ContextPointName == selectedData.contextPointName) && (item.ContextPointId == selectedData.contextPointId)) {
          isSelected = true
        }
        dropdownData.push({
          text: item.ContextPointName,
          value: item.ContextPointId.toString(),
          isSelected: isSelected
        });
      }
    });
    setTimeout(() => {
      territoryData = this.getSelectedData('Select Sales Territory');
      let selectedDataItem = dropdownData.filter(vendor => vendor.isSelected === true);
      if (territoryData && regionType === 'SalesRegion' && selectedDataItem.length > 0) {
        this.populateSalesDistricts({ name: "", value: selectedData.contextPointId, label: "Select Sales District", text: selectedData.contextPointName });
      }
    }, 100);
  }

  clearFilter() {
    this.showRegions = false;
    this.showCountries = true;
    this.showSalesRegions = false;
    this.showSalesDistricts = true;
    this.showSalesTerritories = true;
    this.clearSelectedFilter(this.regionDData);
    this.clearSelectedFilter(this.countryDData);
    this.clearSelectedFilter(this.salesRegionDData);
    this.clearSelectedFilter(this.salesDistrictDData);
    this.clearSelectedFilter(this.salesTerritoryDData);
    this.filterObject.children = [];
    // We call filterObjChange.emit directly instead of emitData because we do not want to push childArray with undefined values
    this.filterObjChange.emit(this.filterObject);
  }

  clearSelectedFilter(dropdownData) {
    dropdownData.forEach(item => {
      item.isSelected = false;
    });
  }

  emitData(event) {
    if (!event || !event.value) { return false; }

    const childArray = {
      'contextPointId': event.value,
      'contextPointName': event.text,
      'label': event.label
    };

    let checkObj = true;
    let Id = null;
    this.filterObject.children.find(function (element, index) {
      if (element.contextPointId === event.value) { checkObj = false; }
      if (element.label === event.label) {
        Id = index;
      }
    });

    if (checkObj) {
      if (Id !== null) {
        if (Id !== 0) { this.filterObject.children.splice(Id, 1); } else { this.filterObject.children = []; }
      }
      this.filterObject.children.push(childArray);
      this.filterObjChange.emit(this.filterObject);
    }
  }

}
