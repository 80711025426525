<ngb-tabset #tabset="ngbTabset" class="site-nav transparent tabs" (tabChange)="onTabChange($event)" [destroyOnHide]="false">
    <ngb-tab [disabled]="disableST || disableAllTabs" ngbTabTitle title="{{'RO_OPTIMIZER.DASHBOARD.SYSTEM' | translate}}"
     id="system">
      <ng-template ngbTabContent *ngIf="!disableST">
        <nalco-system [siteId]= "siteId" (scenarioData)="getScenarioData($event)" (tab)="getTabStatus($event)" [componentReady]="componentReady" [saveSuccess] ="isSaved"></nalco-system>
      </ng-template>
    </ngb-tab>
    <ngb-tab [disabled]="disableTabs || disableAllTabs" id="operatingParameter">
      <ng-template ngbTabTitle>
        <span [ngClass]="opErrorTab == true ? 'error-tab': ''">{{'RO_OPTIMIZER.DASHBOARD.OPERATING_PARAMETER' | translate}}</span>
      </ng-template>
      <ng-template ngbTabContent>
       <nalco-operating-parameter [opData] ="opTabData" [dimensionalUsageKeys]="dimensionalUsageKeys" (errorTabStatus)="getErrorTabStatus($event)" (calcLoaderStatus)="getLoaderStatus($event)"></nalco-operating-parameter>
      </ng-template>
    </ngb-tab>
    <ngb-tab [disabled]="disableTabs || disableAllTabs" id="waterChemistry">
      <ng-template ngbTabTitle>
        <span [ngClass]="wcErrorTab == true ? 'error-tab': ''">{{'RO_OPTIMIZER.DASHBOARD.WATER_CHEMISTRY' | translate}}</span>
      </ng-template>
      <ng-template ngbTabContent>
       <nalco-water-chemistry [wcData] ="wcTabData" [dimensionalUsageKeys]="dimensionalUsageKeys" [pHControls]="pHControls" (errorTabStatus)="getErrorTabStatus($event)" (chargeBalanceValidation)="Validation($event)" [chargeBalanceInfo]="chargeBalance" (cbLoaderStatus)="getLoaderStatus($event)"></nalco-water-chemistry>
      </ng-template>
    </ngb-tab>
    <ngb-tab [disabled]="disableTabs || disableAllTabs" id="dosageAndRecommendation">
      <ng-template ngbTabTitle>
        <span [ngClass]="drErrorTab == true ? 'error-tab': ''">{{'RO_OPTIMIZER.DASHBOARD.DOSAGE_AND_RECOMMENDATION' | translate}}</span>
      </ng-template>
      <ng-template ngbTabContent>
       <nalco-dosage-and-recommendation [scenarioData]="scenarioData" [drData] ="drTabData" [dimensionalUsageKeys]="dimensionalUsageKeys" (errorTabStatus)="getErrorTabStatus($event)" (saveStatus)="getsaveStatus($event)" [oipScenario] ="isScenarioAtOIP" (phTableValidation)="Validation($event)" (saveLoaderStatus)="getLoaderStatus($event)"></nalco-dosage-and-recommendation>
      </ng-template>
    </ngb-tab>
  </ngb-tabset>
  <div *ngIf="isError">
    <nalco-tile [parentInstance]="myParentInstance()"   [headerTitle]="" [errorData]='errorInfo' [showMoreOptions]=false [showInfoIcon]=false
      class="no-header">
      <div tile-content></div>
    </nalco-tile>
  </div>
