<!--tsLint:disable-->
<div class="pills-container clearfix">
    <button id="clearPill" *ngIf="AllPillsTitle  !=''" class="pills all-app" (click)="selectPill(null)">
        {{AllPillsTitle}}
        <span class="line" *ngIf='lineBorder'></span>
    </button>

    <div class="pills" *ngFor="let pill of getfilteredPills() ,let lineIndex=index" [ngClass]="{'active': isSelectedPill(pill)}" (click)="selectPill(pill)">
        <span *ngIf="!showAll" class="ico-exit-in-circle" (click)="removeFromFilteredPills(pill.id)"></span>
        <a>
            <span class="pills-text" [id]="pill.property+pill.id">{{pill.name}}</span>
            <span class="dash-style {{dashStyles[lineIndex]}}" *ngIf='lineBorder' [class.white]="selectedPill.id==pill.id"></span>
        </a>
    </div>
    <button class="pills add-pills" *ngIf="!showAll && pillsData && pillsData.length > 0" (click)="openFilterPillsModal(filterPillsModal)"></button>
</div>
<ng-template #filterPillsModal let-saveFilteredPills="close" let-dimissFilterPillsModal="dismiss">
    <div class="add-asset-to-comp-modal">
        <div class="modal-header">
            <h3 class="modal-title">{{filterPillsModalTitle}}</h3>
            <span *ngIf='showUptoMore' class="upto-more">up to {{maxFilterPills-filteredPillsInPopup.length}} more</span>
            <button type="button" class="close" aria-label="Close" (click)="dimissFilterPillsModal();">
                <span class="ico-exit-round-edge"></span>
            </button>
        </div>
        <div *ngIf="showFilterNote" class="filter-note">
            <span> {{filterNote}} </span>
        </div>
        <div class="modal-body">
            <div *ngIf="showPillsbar" class="pills-bar clearfix">
                <div class="pills" *ngFor="let pill of getFilteredPillsInPopup()">
                    <span class="ico-exit-in-circle" (click)="removeFilterPillsInPopup(pill.id)"></span>
                    <a>
                        <span>{{pill.name}}</span>
                    </a>
                </div>
            </div>

            <div class="search-bar" *ngIf='searchNeeded'>
                <nalco-search [placeholder]="'Search'" [(bindModelData)]="searchTerm"></nalco-search>
            </div>
            <div *ngIf='!pillsColumn'>
                <ul class="assets-list">
                <!-- <li class="asset-row clearfix" *ngFor="let pill of getUnfilteredPillsInPopup()|filterByTermPipe:searchTerm">
                    <a>
                        <span>{{pill.name}}</span>
                    </a>
                    <button class="btn btn-primary" [disabled]="filteredPillsInPopup.length === maxFilterPills" (click)="addToFilteredPillsInPopup(pill.id)">Add</button>
                </li> -->
                    <li class="custom-control custom-checkbox asset-row clearfix" *ngFor="let pill of pillsData">
                       <input type="checkbox" class="custom-control-input ng-dirty ng-touched" value="{{pill.name}}"  id="{{pill.name}}" [(ngModel)]="pill.isFavorite"  (change)="onChangeFilteredPillsInPopup(pill.id, $event.target)" />
                       <label class="custom-control-label" for="{{pill.name}}">{{pill.name}}</label>
                    </li>
            </ul>
            </div>
            <div class="pill-container" *ngIf='pillsColumn' >
                <div class="pill-2-col"  *ngFor="let categoryType of category">
                        <h3>{{categoryType}}</h3>
                        <ul class="assets-list">
                            <div  *ngFor="let pill of pillsData">
                                <li class="custom-control custom-checkbox asset-row clearfix"  *ngIf= "pill.categoryType === categoryType">
                                   <input type="checkbox" class="custom-control-input ng-dirty ng-touched" value="{{pill.name}}"  id="{{pill.name}}" [(ngModel)]="pill.isFavorite"  (change)="onChangeFilteredPillsInPopup(pill.id, $event.target)" />
                                   <label class="custom-control-label" for="{{pill.name}}">{{pill.name}}</label>
                                </li>
                            </div>
                        </ul>
                </div>
            </div>
        </div>

        <div class="modal-footer">
            <a href="javascript:void(0)" class="clearButton"  (click)="clearAllFilters()" >{{clearFilterText}}</a>
            <button type="button" class="btn btn-secondary" (click)="dimissFilterPillsModal()" >{{cancel}}</button>
            <button type="button" class="btn btn-primary" (click)="saveFilteredPills()" >{{save}}</button>
        </div>
    </div>
</ng-template>
