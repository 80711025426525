import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { APP_CONFIG, ENVIRONMENTS } from '../../app.config';

export interface ILogMessage {
    object?: any;
    message: string;
}

@Injectable()
export class LoggerService {

    private logInfoMessage = new Subject<ILogMessage>();
    public logInfoMessage$ = this.logInfoMessage.asObservable();

    private logSuccessMessage = new Subject<ILogMessage>();
    public logSuccessMessage$ = this.logSuccessMessage.asObservable();

    private logWarningMessage = new Subject<ILogMessage>();
    public logWarningMessage$ = this.logWarningMessage.asObservable();

    private logErrorMessage = new Subject<ILogMessage>();
    public logErrorMessage$ = this.logErrorMessage.asObservable();

    /*
    toasterClicked(mission: string): Observable<ILogMessage> {
        
    }*/

    private logToConsole(message:ILogMessage, consoleIt:boolean) {
        // check for Environment Mock and DEV
        if(consoleIt && (APP_CONFIG.ENVIRONMENT === ENVIRONMENTS.mock || APP_CONFIG.ENVIRONMENT === ENVIRONMENTS.dev)) {
            //console.log(message.message, message.object || '');
        }
    }
     
    public info(message:ILogMessage, toastIt?:boolean, consoleIt?:boolean ) {
        this.logToConsole(message, consoleIt);
        if(toastIt) this.logInfoMessage.next(message);
    }

    public success(message:ILogMessage, toastIt?:boolean, consoleIt?:boolean) {
        this.logToConsole(message, consoleIt);        
        if(toastIt) this.logSuccessMessage.next(message);
    }

    public warning(message:ILogMessage, toastIt?:boolean, consoleIt?:boolean) {
        this.logToConsole(message, consoleIt);        
        if(toastIt) this.logWarningMessage.next(message);
    }

    public error(message:ILogMessage, toastIt?:boolean, consoleIt?:boolean) {
        this.logToConsole(message, consoleIt);        
        if(toastIt) this.logErrorMessage.next(message);
    }

}