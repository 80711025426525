import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'nalco-loader',
    templateUrl: './loader.component.html'
})
export class LoaderComponent implements OnInit {

    @Input()
    loader: boolean; // Defeines the requirment of the loader

    @Input()
    transparentBG: boolean; // For transparent background
    
    @Input()
    fitToScreen: boolean; // For fit the background

    constructor() { }

    ngOnInit() {
    }


}
